import { Section } from "components/Section";
import { lg } from "assets/translations";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "components/Link";
import { AboutPageTabKeys } from "pages";
import { routes, setRouteParams } from "routes";

export const ConstructionSites = () => {
  const [t] = useTranslation();

  return (
    <>
      <Section title={t(lg.aboutPage.tabs.constructionSites.about.title)}>
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary font-medium whitespace-pre-line">
              {t(lg.aboutPage.tabs.constructionSites.about.description1)}
            </div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.constructionSites.about.description2)}
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.constructionSites.output.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              <Trans i18nKey={lg.aboutPage.tabs.constructionSites.output.description1}>
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.Documentation })} />
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.ThreeDModels })} />
              </Trans>
            </div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              <Trans i18nKey={lg.aboutPage.tabs.constructionSites.output.description2}>
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.Sample })} />
              </Trans>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
