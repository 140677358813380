import { useCallback, useMemo } from "react";
import { resolveError, useOrganizationCancelInviteMutation } from "api";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { lg } from "assets/translations";
import { useAlert } from "hooks";

export const useOrganizationCancelInvite = () => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const [cancel, { loading: loadingCancelInvite }] = useOrganizationCancelInviteMutation();

  const loading = useMemo(() => loadingCancelInvite, [loadingCancelInvite]);

  /* Handlers - API */
  const handleSuccess = useCallback(() => {
    message.info(t(lg.organization.messages.inviteCanceled));
  }, [t]);

  const handleErrors = useCallback((e: any) => resolveError(e, {}, showAlert), [showAlert]);

  /* API */
  const cancelInvite = useCallback(
    async (inviteId: string) => {
      try {
        await cancel({
          variables: {
            input: {
              invite: inviteId
            }
          }
        });
        handleSuccess();
      } catch (e) {
        handleErrors(e);
      }
    },
    [cancel, handleSuccess, handleErrors]
  );

  return {
    loading,
    cancelInvite
  };
};
