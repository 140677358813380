import { cloneElement, ComponentPropsWithoutRef, useCallback, useMemo } from "react";
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { ModalSharingThreeDModel } from "components/ThreeDModel/ModalSharingThreeDModel";
import { Link } from "components/Link";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Delete } from "components/ThreeDModel/Delete";
import { UseCaseEvent } from "api";
import { routes, setRouteParams } from "routes";
import { useDownloadThreeDModel, useLogEvent, usePermissions } from "hooks";
import { openInNewTab } from "helpers";
import { ModalDownloadThreeDModel } from "components/ThreeDModel/ModalDownloadThreeDModel";
import { Tooltip } from "antd";

type ThreeDModelTableActionsProps = {
  modelId: string;
  isShared: boolean;
  hasFilesForDownload: boolean;

  disabled?: boolean;
  className?: string;
} & Partial<Pick<ActionElementProps, "variant">>;

export const ThreeDModelTableActions = ({
  modelId,
  isShared,
  hasFilesForDownload,
  variant = "text",
  disabled = false,
  className
}: ThreeDModelTableActionsProps) => {
  const { t } = useTranslation();

  const { logEvent } = useLogEvent();
  const { canShareThreeDModel, canDeleteThreeDModel } = usePermissions({});

  const { isDisabled: isDownloadDisabled } = useDownloadThreeDModel();
  const canDownloadThreeDModel: boolean = useMemo(
    () => !isDownloadDisabled && hasFilesForDownload,
    [isDownloadDisabled, hasFilesForDownload]
  );

  const handleClickViewThreeDModel = useCallback(async () => {
    await logEvent(UseCaseEvent.ThreeDModelView);
    openInNewTab(setRouteParams(routes.threeDViewer, { id: modelId }));
  }, [modelId, logEvent]);

  return (
    <div className={clsx("flex", className)} data-cy={"actions-sharing3DModel"}>
      <Tooltip title={t(lg.table.threeDModelActions.show)}>
        <ActionElement
          variant={variant}
          icon={<EyeOutlined />}
          onClick={handleClickViewThreeDModel}
          disabled={disabled}
        >
          {t(lg.table.actions.show)}
        </ActionElement>
      </Tooltip>

      {canShareThreeDModel && (
        <>
          <Divider variant={variant} />

          <div className={clsx("flex items-center gap-1", variant === "icon" && "flex-row-reverse")}>
            {isShared && <UsergroupAddOutlined className="text-base" data-cy={"indicator-3DModel-isShared"} />}
            <ModalSharingThreeDModel modelId={modelId}>
              <Tooltip title={t(lg.table.threeDModelActions.share)}>
                <ActionElement
                  variant={variant}
                  icon={<ShareAltOutlined />}
                  className="whitespace-nowrap"
                  disabled={disabled}
                  data-cy={"action-open-Share3DModel"}
                >
                  {t(lg.table.actions.share)}
                </ActionElement>
              </Tooltip>
            </ModalSharingThreeDModel>
          </div>
        </>
      )}

      {canDownloadThreeDModel && (
        <>
          <Divider variant={variant} />
          <ModalDownloadThreeDModel modelId={modelId}>
            <Tooltip title={t(lg.table.threeDModelActions.download)}>
              <ActionElement
                variant={variant}
                icon={<DownloadOutlined />}
                className="whitespace-no-wrap"
                disabled={disabled}
                data-cy={"action-open-Share3DModel"}
              >
                {t(lg.table.actions.download)}
              </ActionElement>
            </Tooltip>
          </ModalDownloadThreeDModel>
        </>
      )}

      {canDeleteThreeDModel && (
        <>
          <Divider variant={variant} />

          <Delete id={modelId}>
            <Tooltip title={t(lg.table.threeDModelActions.delete)}>
              <ActionElement
                variant={variant}
                icon={<DeleteOutlined className={"text-red-600"} />}
                className="whitespace-nowrap"
                disabled={disabled}
              >
                {t(lg.table.actions.delete)}
              </ActionElement>
            </Tooltip>
          </Delete>
        </>
      )}
    </div>
  );
};

/*
---------------------------------------------------------------------
DIVIDER
---------------------------------------------------------------------
 */
const Divider = ({ variant, className }: { className?: string } & Pick<ActionElementProps, "variant">) => (
  <div className={clsx("px-1", variant === "icon" && "opacity-0", className)}>|</div>
);

/*
---------------------------------------------------------------------
ACTION ELEMENT
---------------------------------------------------------------------
 */
type LinkProps = ComponentPropsWithoutRef<typeof Link>;
type ActionElementProps = LinkProps & { variant: "text" | "icon" };
const ActionElement = ({ icon, variant, children, className, ...rest }: ActionElementProps) => (
  <Link
    {...rest}
    icon={
      icon && variant === "icon" ? cloneElement(icon, { className: clsx("text-2xl", icon.props.className) }) : undefined
    }
    className={clsx(className)}
  >
    {variant === "text" ? children : null}
  </Link>
);
