import { useCallback, useMemo, useState } from "react";
import { ConstructionSiteDeleteMutation, resolveError, useConstructionSiteDeleteMutation } from "api";
import { useAlert, useMe } from "hooks";

export const useConstructionSiteDelete = (id: string = "") => {
  const { showAlert } = useAlert();
  const { refetch: refetchMe } = useMe();
  const [deleteSite, { loading: loadingDeleteMutation, called }] = useConstructionSiteDeleteMutation();

  /** STATE */
  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const [mutationResult, setMutationResult] = useState<ConstructionSiteDeleteMutation>();

  /** DATA */
  const deleteResultData = useMemo(() => mutationResult?.constructionSite?.delete, [mutationResult]);
  const failedPhases = useMemo(() => sanitizeArray(deleteResultData?.failedPhases), [deleteResultData]);
  const failedObjects = useMemo(() => sanitizeArray(deleteResultData?.failedObjects), [deleteResultData]);

  const failedData = useMemo(() => {
    if (failedPhases.length === 0 && failedObjects.length === 0) return;

    return {
      failedPhases,
      failedObjects
    };
  }, [failedPhases, failedObjects]);

  const sending = useMemo(() => loadingDeleteMutation || isRefetch, [loadingDeleteMutation, isRefetch]);
  const wasCompletelyDeleted = useMemo(() => called && deleteResultData?.archived, [called, deleteResultData]);

  /** HANDLERS */
  const handleDelete = useCallback(async () => {
    try {
      const result = await deleteSite({ variables: { input: { constructionSite: id } } });
      setIsRefetch(true);
      await refetchMe();
      setMutationResult(result.data);
    } catch (e) {
      resolveError(e, {}, showAlert);
    } finally {
      setIsRefetch(false);
    }
  }, [deleteSite, refetchMe, id, showAlert]);

  const handleClear = useCallback(() => setMutationResult(undefined), []);

  return {
    data: {
      sending,
      called,
      wasCompletelyDeleted,
      failedData
    },
    handlers: {
      handleDelete,
      handleClear
    }
  };
};

const sanitizeArray = (data?: any[]) => data?.flatMap(item => (item ? [item] : [])) || [];
