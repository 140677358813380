import { FunctionComponent, useMemo } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { Content } from "components/LayoutDashboard";
import { routes } from "routes";
import { PlusOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import { SubheaderOptions, useMe, usePermissions, useSubheader } from "hooks";
import { ConstructionSitesTable } from "components/ConstructionSites/ConstructionSitesTable";
import { Illustration } from "components/Illustration";

export const ConstructionSites: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();

  const { organization, loading } = useMe();
  const { canCreateConstructionSite } = usePermissions({});

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: t(lg.constructionSites.header.title),
      breadcrumb: [{ value: t(lg.constructionSites.header.title) }],
      buttons: canCreateConstructionSite
        ? [
            <Button
              key="create-new-site"
              type={"primary"}
              size={"large"}
              onClick={() => navigate(routes.constructionSiteCreate)}
            >
              <PlusOutlined />
              {t(lg.constructionSites.header.buttonPrimaryText)}
            </Button>
          ]
        : undefined
    }),
    [canCreateConstructionSite, t]
  );
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      <ConstructionSitesTable
        organizationId={organization.id}
        noDataContent={
          <div className="flex flex-col items-center min-h-full">
            <p className="text-center whitespace-pre-line text-base text-blue-700">
              {canCreateConstructionSite
                ? t(lg.constructionSites.noData.default)
                : t(lg.constructionSites.noData.readOnly)}
            </p>

            <div className="flex-grow flex flex-col w-full p-5 md:p-20">
              <Illustration type="ill-02" className="flex-grow h-0" title={t(lg.constructionSites.noData.readOnly)} />
            </div>
          </div>
        }
      />
    </Content>
  );
};
