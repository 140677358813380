import { cloneElement, PropsWithChildren, ReactElement, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, message, Modal, Radio, Spin } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import { resolveError, useThreeDModelForShareQuery, useThreeDModelRevokeSharesMutation } from "api";
import { useAlert, usePermissions } from "hooks";
import { lg } from "assets/translations/lg";
import { ShareModelForm } from "./ShareModelForm";

type ModalSharingThreeDModelProps = {
  modelId: string;
  children: ReactElement;
};

enum Options {
  NotShare = "not-share",
  Share = "share"
}

export const ModalSharingThreeDModel = ({ modelId, children }: PropsWithChildren<ModalSharingThreeDModelProps>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [visible, setVisible] = useState(false);
  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);

  const [activeOption, setActiveOption] = useState<Options>(Options.NotShare);
  const [shareCode, setShareCode] = useState<string>();

  const { canShareThreeDModel } = usePermissions({});
  const skipQuery = useMemo(() => !visible || !canShareThreeDModel, [canShareThreeDModel, visible]);

  const {
    data: dataThreeDModel,
    loading: loadingThreeDModel,
    refetch: refetchThreeDModel
  } = useThreeDModelForShareQuery({
    skip: skipQuery,
    variables: { id: modelId },
    onCompleted: data => {
      if (!data) return;
      if (data.threeDModel?.isShared) setActiveOption(Options.Share);
      setInitialized(true);
    },
    onError: e => resolveError(e, undefined, showAlert),
    fetchPolicy: "cache-and-network"
  });

  const [initialized, setInitialized] = useState<boolean>(false);
  const initializing = useMemo<boolean>(() => loadingThreeDModel && !initialized, [loadingThreeDModel, initialized]);

  const isModelShared = useMemo<boolean>(() => !!dataThreeDModel?.threeDModel?.isShared, [dataThreeDModel]);
  const canRevokeShares = useMemo(
    () => isModelShared && activeOption === Options.NotShare,
    [activeOption, isModelShared]
  );

  const [revokeShares, { loading: sendingRevokeShares }] = useThreeDModelRevokeSharesMutation({
    variables: { input: { threeDModel: modelId } },
    onCompleted: () => {
      message.success(t(lg.threeDViewer.modals.shareThreeDModel.messages.successfullyUnshared));
      close();
    },
    onError: e => resolveError(e, undefined, showAlert)
  });

  const handleAfterShare = useCallback(
    (modelShareCode?: string) => {
      setShareCode(modelShareCode);
      return refetchThreeDModel();
    },
    [refetchThreeDModel]
  );

  const handleOk = useCallback(
    () => (canRevokeShares ? revokeShares() : close()),
    [canRevokeShares, revokeShares, close]
  );

  const disabledOkForNotShare = useMemo(
    () => (activeOption === Options.NotShare && !isModelShared) || sendingRevokeShares,
    [activeOption, isModelShared, sendingRevokeShares]
  );
  const disabledOkForShare = useMemo<boolean>(
    () => activeOption === Options.Share && !shareCode,
    [activeOption, shareCode]
  );
  const disabledOk = useMemo(
    () => disabledOkForNotShare || disabledOkForShare,
    [disabledOkForNotShare, disabledOkForShare]
  );

  if (!canShareThreeDModel) return null;

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center">
            <ShareAltOutlined className="text-lg text-purple-600" />
            <span className="ml-4">{t(lg.threeDViewer.modals.shareThreeDModel.title)}</span>
          </div>
        }
        open={visible}
        footer={
          /* custom footer 'cause Cancel button is not need and can't be disable by prop*/
          <Button
            type={"primary"}
            loading={sendingRevokeShares}
            disabled={disabledOk}
            onClick={handleOk}
            data-cy={"action-modal-ok"}
          >
            {t(lg.threeDViewer.modals.shareThreeDModel.buttonOkText)}
          </Button>
        }
        onCancel={close} // set for the close cross
        closable
        destroyOnClose
      >
        {initializing ? (
          <div className="flex justify-center">
            <Spin data-cy={"loading-options-sharing3DModel"} />
          </div>
        ) : (
          <>
            <div className="flex flex-col" data-cy={"options-sharing3DModel"}>
              <Radio
                name={Options.NotShare}
                value={Options.NotShare}
                checked={activeOption === Options.NotShare}
                disabled={sendingRevokeShares}
                onChange={() => setActiveOption(Options.NotShare)}
                data-cy={"option-dont-share-3DModel"}
              >
                {t(lg.threeDViewer.modals.shareThreeDModel.options.notShareModel)}
              </Radio>
              {isModelShared && (
                <div className="text-red-600 ml-6" data-cy={"notice-3DModel-isShared"}>
                  {t(lg.threeDViewer.modals.shareThreeDModel.notShareModel.notice)}
                </div>
              )}

              <Radio
                className="mt-3"
                name={Options.Share}
                value={Options.Share}
                checked={activeOption === Options.Share}
                disabled={sendingRevokeShares}
                onChange={() => setActiveOption(Options.Share)}
                data-cy={"option-share-3DModel"}
              >
                {t(lg.threeDViewer.modals.shareThreeDModel.options.shareModel)}
              </Radio>
            </div>

            <ShareModelForm
              modelId={modelId}
              onAfterShare={handleAfterShare}
              disabled={activeOption !== Options.Share}
              className="ml-6"
            />
          </>
        )}
      </Modal>
    </>
  );
};
