import { PropsWithChildren, useCallback, useState } from "react";
import { Form, Input, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import { SelectProps } from "antd/es/select";
import { FieldData } from "rc-field-form/es/interface";
import { DocumentationRecordType, Role, UserFragment } from "api";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";
import { SelectConstructionPhase } from "components/SelectConstructionPhase";

export enum GeneralInfoFormItemName {
  name = "name",
  description = "description",
  phaseId = "phaseId",
  accountableManagerId = "accountableManagerId",
  accountableOperatorId = "accountableOperatorId",
  accountableSurveyorId = "accountableSurveyorId"
}

export type GeneralInfoFormData = Partial<{ [key in keyof typeof GeneralInfoFormItemName]: string }>;

type Props = {
  form: FormInstance;
  initialValues?: GeneralInfoFormData;
  constructionSiteId: string;
  documentationRecordType: DocumentationRecordType;
  users: UserFragment[];
  disabled?: boolean;
  showPhaseSelect: boolean;
  onChange: (changedFields: FieldData[], allFields: FieldData[]) => any;
  onRoleChange?: (type: "manager" | "operator" | "surveyor", userId: string) => any;
  onSubmit: (values: GeneralInfoFormData) => any;
};

export const GeneralInfoForm = ({
  form,
  initialValues,
  constructionSiteId,
  documentationRecordType,
  users,
  disabled = false,
  showPhaseSelect = true,
  onChange,
  onRoleChange = () => {},
  onSubmit
}: PropsWithChildren<Props>) => {
  const [t] = useTranslation();
  const [phaseId, setPhaseId] = useState<string>();

  /** Submit handler */
  const handleFinish = useCallback(
    (values: GeneralInfoFormData | any) => {
      const { accountableOperatorId, accountableSurveyorId } = values;
      onSubmit({
        ...values,
        accountableOperatorId: accountableOperatorId || null,
        accountableSurveyorId: accountableSurveyorId || null
      });
    },
    [onSubmit]
  );

  const handleChangeManager = useCallback((userId: string) => onRoleChange("manager", userId), [onRoleChange]);
  const handleChangeOperator = useCallback((userId: string) => onRoleChange("operator", userId), [onRoleChange]);
  const handleChangeSurveyor = useCallback((userId: string) => onRoleChange("surveyor", userId), [onRoleChange]);

  return (
    <Form
      form={form}
      name="general-info"
      requiredMark={false}
      layout={"vertical"}
      initialValues={initialValues}
      onFinish={handleFinish}
      onFieldsChange={onChange}
    >
      <div className="c-grid">
        <div className="c-grid-column">
          <Form.Item
            label={
              <span>
                {t(lg.documentationRecord.forms.generalInfo.name.label)}{" "}
                <span className="text-secondary">({t(lg.form.required)})</span>
              </span>
            }
            name={GeneralInfoFormItemName.name}
            rules={[{ required: true, message: t(lg.documentationRecord.forms.generalInfo.validations.nameRequired) }]}
          >
            <Input size={"large"} disabled={disabled} />
          </Form.Item>

          <Form.Item
            label={t(lg.documentationRecord.forms.generalInfo.description.label)}
            name={GeneralInfoFormItemName.description}
          >
            <Input.TextArea
              placeholder={t(lg.documentationRecord.forms.generalInfo.description.placeholder)}
              rows={6}
              disabled={disabled}
            />
          </Form.Item>

          {showPhaseSelect && (
            <Form.Item
              label={t(lg.documentationRecord.forms.generalInfo.phase.label)}
              name={GeneralInfoFormItemName.phaseId}
            >
              <SelectConstructionPhase
                constructionSiteId={constructionSiteId}
                value={phaseId}
                onChange={setPhaseId}
                disabled={disabled}
                allowClear={false}
              />
            </Form.Item>
          )}
        </div>

        <div className="c-grid-column">
          <Form.Item
            label={t(lg.documentationRecord.forms.generalInfo.accountableManager.label)}
            name={GeneralInfoFormItemName.accountableManagerId}
          >
            <Select {...selectFilterProps} disabled={disabled} onChange={handleChangeManager}>
              {users
                .filter(({ role }) => role === Role.Manager)
                .map(({ id, firstName, lastName, email }) => (
                  <Select.Option key={id} value={id}>
                    {firstName} {lastName} {email ? `(${email})` : null}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(lg.documentationRecord.forms.generalInfo.accountableOperator.label)}
            name={GeneralInfoFormItemName.accountableOperatorId}
          >
            <Select {...selectFilterProps} disabled={disabled} onChange={handleChangeOperator}>
              <Select.Option value={""}>{t(lg.form.defaultValues.noSelected)}</Select.Option>
              {users
                .filter(({ role }) => role === Role.Operator || role === Role.Manager)
                .map(({ id, firstName, lastName, email }) => (
                  <Select.Option key={id} value={id}>
                    {firstName} {lastName} {email ? `(${email})` : null}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          {documentationRecordType === DocumentationRecordType.ThreeDScale && (
            <Form.Item
              label={t(lg.documentationRecord.forms.generalInfo.accountableSurveyor.label)}
              name={GeneralInfoFormItemName.accountableSurveyorId}
            >
              <Select {...selectFilterProps} disabled={disabled} onChange={handleChangeSurveyor}>
                <Select.Option value={""}>{t(lg.form.defaultValues.noSelected)}</Select.Option>
                {users
                  .filter(({ role }) => role === Role.Surveyor || role === Role.Manager)
                  .map(({ id, firstName, lastName, email }) => (
                    <Select.Option key={id} value={id}>
                      {firstName} {lastName} {email ? `(${email})` : null}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          )}

          <div className="flex items-center text-purple-600">
            <Tooltip
              placement="bottomLeft"
              title={t(lg.documentationRecord.forms.generalInfo.tooltips.needAddNewUser.content)}
              className="mr-2"
              arrowPointAtCenter
            >
              <InfoCircleOutlined className="text-xl cursor-pointer" />
            </Tooltip>
            <span className="text-xs">{t(lg.documentationRecord.forms.generalInfo.tooltips.needAddNewUser.text)}</span>
          </div>
        </div>
      </div>
    </Form>
  );
};

const selectFilterProps: Partial<SelectProps<string>> = {
  showSearch: true,
  optionFilterProp: "children",
  filterOption: (input, option) => {
    if (!option || !option.children) return false;

    return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
};
