import { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { SubheaderOptions, useMe, useSubheader } from "hooks";
import { Content } from "components/LayoutDashboard";
import { TodoManager, TodoSurveyor, TodoThreeDOperator } from "pages";
import { isManager, isOperator, isReader, isSurveyor, isThreeDOperator } from "helpers";
import { lg } from "assets/translations/lg";

export const Todo = (props: RouteComponentProps) => {
  const { t } = useTranslation();

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({ heading: t(lg.todo.header.title), breadcrumb: [{ value: t(lg.todo.header.title) }] }),
    [t]
  );
  useSubheader(subheaderOptions);

  const { me, loading } = useMe();

  return (
    <Content loading={loading} data-cy={"page-todo"}>
      {(isManager(me) || isReader(me) || isOperator(me)) && <TodoManager {...props} />}
      {isSurveyor(me) && <TodoSurveyor {...props} />}
      {isThreeDOperator(me) && <TodoThreeDOperator {...props} />}
    </Content>
  );
};
