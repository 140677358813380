import { FunctionComponent, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { Section } from "components/Section";
import { Link } from "components/Link";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { Table, TableConfig } from "components/Table";
import { Organization } from "api";
import { useMe } from "hooks";
import { isManager } from "helpers";
import { routes, setRouteParams } from "routes";
import { ModalLeave } from "components/Organization/ModalLeave";

type TableItem = Pick<Organization, "name" | "id">;

export const Organizations: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { me, organizations, loading } = useMe();

  const subheaderOptions = useMemo<SubheaderOptions>(() => ({ heading: t(lg.organizations.header.title) }), [t]);
  useSubheader(subheaderOptions);

  const tableColumns: TableConfig<TableItem> = [
    {
      titleKey: lg.organizations.memberAtOrganizations.table.header.organization,
      dataIndex: "name",
      key: "name",
      render: (text: string, { id }) =>
        isManager(me) ? <Link href={setRouteParams(routes.organization, { id })}>{text}</Link> : text,
      sort: true,
      ellipsis: true,
      className: "w-4/5",
      filter: organizations.map(({ name }) => ({ text: name, value: name }))
    },
    {
      titleKey: lg.organizations.memberAtOrganizations.table.header.actions,
      key: "actions",
      render: (data: undefined, record) => (
        <ModalLeave organizationId={record.id}>
          <Link>{t(lg.organizations.memberAtOrganizations.table.cell.leaveText)}</Link>
        </ModalLeave>
      )
    }
  ];

  return (
    <Content loading={loading}>
      {/*
       * Member at company
       */}
      <Section title={t(lg.organizations.memberAtOrganizations.title)}>
        <div className="mt-5">
          <Table<TableItem> config={tableColumns} data={organizations} rowKey={"id"} />
        </div>
      </Section>
    </Content>
  );
};
