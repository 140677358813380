import { useMemo } from "react";

type TableDataStatus = {
  hasData: boolean;
  hasFilters: boolean;
};

export const useTableDataStatus = (
  items: number | undefined,
  filters?: (boolean | string | string[])[]
): TableDataStatus => {
  const hasFilters = useMemo<boolean>(
    () =>
      !!filters?.find(filter => {
        if (typeof filter === "boolean") return filter;
        return filter.length > 0;
      }),
    [filters]
  );

  return {
    hasData: !!items,
    hasFilters
  };
};
