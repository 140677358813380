import { useMemo } from "react";
import { DiskUsageStats, MediaKind, StorageClass } from "api";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";
import { useFormat } from "hooks/useFormat";
import { useCatalog } from "hooks/useCatalog";
import { mediaKinds } from "api/enums";

export const useStorageClass = (_diskUsageStats: DiskUsageStats = { total: 0, byKind: [] }) => {
  const [t] = useTranslation();
  const { convertGibToB } = useFormat();

  const { catalog, loading } = useCatalog();

  const diskUsageStats = useMemo(() => {
    return {
      total: _diskUsageStats.total,
      byKind: _diskUsageStats.byKind
        // remove unassigned space
        .filter(byKind => byKind?.kind !== MediaKind.Unassigned)
        // remap data
        .map(byKind => {
          const unresolvedName = byKind?.kind || "-";
          const i18nKey: string | undefined = byKind ? mediaKinds[byKind.kind]?.legendI18nKey : undefined;
          return {
            name: i18nKey ? t(i18nKey) : unresolvedName,
            total: byKind ? byKind.total : 0
          };
        })
    };
  }, [_diskUsageStats, t]);

  const storageClass = useMemo<StorageClass>(
    () =>
      catalog.storageClassPrices
        .sort((a, b) => (a.maxGb > b.maxGb ? 1 : -1))
        .find(item => convertGibToB(item.maxGb) > diskUsageStats.total) || {
        maxGb: 0,
        price: 0
      },
    [catalog, diskUsageStats.total, convertGibToB]
  );

  const storageName = useMemo<string>(
    () => (storageClass.price > 0 ? t(lg.storage.planVariant.paidCredits) : t(lg.storage.planVariant.free)),
    [storageClass, t]
  );

  return {
    loading,

    diskUsageStats,
    storageClass,
    storageName
  };
};
