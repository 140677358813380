import useStore, { createStore } from "global-hook-store";
import { ApiCompatibility } from "api/enums";

type ApiCompatibilityStore = {
  compatibility: ApiCompatibility | null;
};

type ApiCompatibilityReducers = {
  set: (store: ApiCompatibilityStore, compatibility: ApiCompatibility) => ApiCompatibilityStore;
};

export const apiCompatibilityStore = createStore<ApiCompatibilityStore, ApiCompatibilityReducers>(
  {
    compatibility: null
  },
  {
    set: (store, compatibility) => ({ compatibility })
  }
);

export const useApiCompatibilityStore = () => {
  const { state, actions } = useStore(apiCompatibilityStore);

  return { ...state, ...actions };
};
