import { CSSProperties } from "react";
import { ReactComponent as Illustration01 } from "assets/images/ill-01.svg";
import { ReactComponent as Illustration02 } from "assets/images/ill-02.svg";
import { ReactComponent as Illustration03 } from "assets/images/ill-03.svg";
import { ReactComponent as Illustration04 } from "assets/images/ill-04.svg";
import { ReactComponent as Illustration403 } from "assets/images/ill-403.svg";
import { ReactComponent as Illustration404 } from "assets/images/ill-404.svg";
import { ReactComponent as Illustration500 } from "assets/images/ill-500.svg";
import { ReactComponent as IllustrationFail } from "assets/images/ill-fail.svg";
import { ReactComponent as IllustrationFail2 } from "assets/images/ill-fail_2.svg";
import { ReactComponent as IllustrationSupport } from "assets/images/ill-support.svg";

import clsx from "clsx";

const illustration = {
  "ill-01": Illustration01,
  "ill-02": Illustration02,
  "ill-03": Illustration03,
  "ill-04": Illustration04,
  "ill-403": Illustration403,
  "ill-404": Illustration404,
  "ill-500": Illustration500,
  "ill-fail": IllustrationFail,
  "ill-fail2": IllustrationFail2,
  "ill-support": IllustrationSupport
};

export type IllustrationType = keyof typeof illustration;

type Props = {
  type: IllustrationType;
  className?: string;
  style?: CSSProperties;
  title?: string;
};

export const Illustration = ({ type, className, style, title }: Props) => {
  const Element = illustration[type];
  return <Element className={clsx(className)} style={style} title={title} />;
};
