import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { WalletOutlined } from "@ant-design/icons";
import { RadioCard } from "components/RadioCard";
import { CustomIcon, CustomIconType } from "components/icons/CustomIcon";
import { DocumentationRecordType, DocumentationRecordVideoType } from "api";
import { useCatalog, useFormat } from "hooks";
import { getCost } from "helpers";
import { lg } from "assets/translations";
import { documentationRecordVideoTypes } from "api/enums";

// null = video type hasn't been selected yet
export type VideoTypeValue = DocumentationRecordVideoType | null;

type Props = {
  documentationRecordType: DocumentationRecordType;
  videoType: VideoTypeValue;
  disabled?: boolean;
  onSelect: (videoType: VideoTypeValue) => any;
};

export const VideoTypeRadioCards = ({
  documentationRecordType,
  videoType,
  disabled = false,
  onSelect
}: PropsWithChildren<Props>) => {
  const { catalog, loading: loadingCatalog } = useCatalog();

  const allowedTypes = useMemo(() => getAllowedVideoTypes(documentationRecordType), [documentationRecordType]);

  if (
    loadingCatalog ||
    !catalog ||
    videoType === undefined ||
    documentationRecordType === DocumentationRecordType.NoThreeDNoVideo
  ) {
    return null;
  }

  return (
    <div className="mt-10">
      <div className="flex flex-wrap -mx-3 -my-5">
        {allowedTypes.map(type => (
          <Card
            key={type || "default"}
            type={type}
            cost={getCost(type, catalog)}
            selected={videoType === type}
            disabled={disabled}
            onSelect={onSelect}
          />
        ))}
      </div>
    </div>
  );
};

type CardProps = {
  type: DocumentationRecordVideoType | null;
  cost: number;
  selected: boolean;
  disabled?: boolean;
  onSelect: (type: DocumentationRecordVideoType | null) => any;
};

const Card = ({ type, cost, selected, disabled = false, onSelect }: CardProps) => {
  const { t } = useTranslation();
  const { creditCost } = useFormat();

  const credits = useMemo(() => creditCost(cost), [cost, creditCost]);

  return (
    <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3 py-5 xl:mb-0">
      <RadioCard<DocumentationRecordVideoType | null>
        title={
          <div className="flex items-center">
            <CustomIcon type={getIconType(type, selected)} />
            <span className="ml-2">
              {t(
                type
                  ? documentationRecordVideoTypes[type].nameI18nKey
                  : documentationRecordVideoTypes.default.nameI18nKey
              )}
            </span>
          </div>
        }
        value={type}
        checked={selected}
        disabled={disabled}
        onClick={() => onSelect(type)}
        className="h-full"
      >
        <p className="whitespace-pre-line mb-auto">{t(getDescriptionI18nKey(type))}</p>

        {cost > 0 && (
          <div className="mt-10">
            <p className="text-xs">{t(lg.documentationRecord.pageDetail.videoType.costInfo)}</p>
            <div className="flex items-center w-full mt-2">
              <WalletOutlined className="text-lg" />
              <span className="font-medium ml-2">{credits}</span>
            </div>
          </div>
        )}
      </RadioCard>
    </div>
  );
};

/*
 * TODO: move to the enums file
 * */

const getAllowedVideoTypes = (recordType: DocumentationRecordType): (DocumentationRecordVideoType | null)[] => {
  switch (recordType) {
    case DocumentationRecordType.NoThreeDNoVideo:
      return [];
    case DocumentationRecordType.NoThreeDVideo:
      return [null, DocumentationRecordVideoType.NoScale, DocumentationRecordVideoType.Scale];
    case DocumentationRecordType.ThreeDNoScale:
      return [null, DocumentationRecordVideoType.NoScale, DocumentationRecordVideoType.Scale];
    case DocumentationRecordType.ThreeDScale:
      return [
        null,
        DocumentationRecordVideoType.Maps,
        DocumentationRecordVideoType.Spray,
        DocumentationRecordVideoType.QrCodes
      ];
  }
};

const getIconType = (type: DocumentationRecordVideoType | null, selected: boolean): CustomIconType => {
  switch (type) {
    case null:
      return selected ? "camera-question-mark-white" : "camera-question-mark";
    case DocumentationRecordVideoType.NoScale:
      return selected ? "camera-nothing-white" : "camera-nothing";
    case DocumentationRecordVideoType.Scale:
      return selected ? "camera-ruler-white" : "camera-ruler";
    case DocumentationRecordVideoType.Maps:
      return selected ? "camera-map-white" : "camera-map";
    case DocumentationRecordVideoType.Spray:
      return selected ? "camera-spray-white" : "camera-spray";
    case DocumentationRecordVideoType.QrCodes:
      return selected ? "camera-qr-white" : "camera-qr";
  }
};

const getDescriptionI18nKey = (type: DocumentationRecordVideoType | null): string => {
  switch (type) {
    case null:
      return lg.documentationRecord.pageDetail.videoType.description.default;
    case DocumentationRecordVideoType.NoScale:
      return lg.documentationRecord.pageDetail.videoType.description.noScale;
    case DocumentationRecordVideoType.Scale:
      return lg.documentationRecord.pageDetail.videoType.description.scale;
    case DocumentationRecordVideoType.Maps:
      return lg.documentationRecord.pageDetail.videoType.description.maps;
    case DocumentationRecordVideoType.Spray:
      return lg.documentationRecord.pageDetail.videoType.description.spray;
    case DocumentationRecordVideoType.QrCodes:
      return lg.documentationRecord.pageDetail.videoType.description.qrCodes;
  }
};
