import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { lg } from "assets/translations/lg";
import clsx from "clsx";
import { useConfig } from "hooks";
import { SimpleLoader } from "components/SimpleLoader";

const currentYear = moment().year();

type Props = { type?: "default" | "light"; height?: 20 | 24; className?: string };

export const Footer = ({ type = "default", height = 20, className }: Props) => {
  const [t] = useTranslation();
  const { config, loading } = useConfig();

  const heightClass = useMemo(() => {
    if (height === 20) return "h-20";
    if (height === 24) return "h-24";
  }, [height]);

  return (
    <footer className={clsx("flex flex-shrink-0 items-center justify-center", heightClass, className)}>
      {loading ? (
        <SimpleLoader />
      ) : (
        <p className="text-center text-xs text-gray-700 m-0">
          {t(lg.footer.prefix)}{" "}
          <a
            href={config.externalBaseUrl}
            title={config.externalBaseName || ""}
            target="_blank"
            rel="noopener noreferrer"
            className={type === "light" ? "c-link-light" : "c-link"}
          >
            {config.externalBaseName}
          </a>
          {` / © ${currentYear} `}
          <a
            href={config.providerUrl}
            title={config.providerName || ""}
            target="_blank"
            rel="noopener noreferrer"
            className={type === "light" ? "c-link-light" : "c-link"}
          >
            {config.providerName}
          </a>
        </p>
      )}
    </footer>
  );
};
