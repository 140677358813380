import { Coords, MapOptions } from "google-map-react";
import Coordinates from "coordinate-parser";

export const calculateMapHeight = (width: number): number => (!width ? 280 : Math.ceil(width * 0.54));

export const createMapOptions = (): MapOptions => ({
  mapTypeControl: true,
  scrollwheel: false,
  fullscreenControl: false
});

export const handleApiLoaded = (map: any, maps: any, constructionSite: Coords[], constructionObject: Coords[]): any => {
  const constructionSitePolygon = new maps.Polygon({
    path: constructionSite,
    strokeColor: "#a05f96",
    strokeOpacity: 1,
    strokeWeight: 1,
    strokePosition: 1,
    fillColor: "#a05f96"
  });
  constructionSitePolygon.setMap(map);

  const constructionObjectPolygon = new maps.Polygon({
    path: constructionObject,
    strokeColor: "#6c8bcb",
    strokeOpacity: 1,
    strokeWeight: 1,
    strokePosition: 1,
    fillColor: "#6c8bcb"
  });
  constructionObjectPolygon.setMap(map);

  recalculateMapCenter(map, maps, constructionSite);

  return { constructionSitePolygon, constructionObjectPolygon };
};

export const updatePolygon = (polygon: any, points: Coords[]) => polygon && polygon.setPath(points);

export const recalculateMapCenter = (map: any, maps: any, points: Coords[]) => {
  if (!map || !maps || !points.length) return;
  map.fitBounds(getMapBounds(map, maps, points));
};

const getMapBounds = (map: any, maps: any, points: Coords[]) => {
  const bounds = new maps.LatLngBounds();
  points.forEach(point => bounds.extend(new maps.LatLng(point.lat, point.lng)));
  return bounds;
};

export const roundCoord = (x: number, n: number = 7) =>
  parseFloat((Math.round(x * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n));

export const parseCoords = (value: string): Coords | undefined => {
  try {
    const position = new Coordinates(value);
    return { lat: position.getLatitude(), lng: position.getLongitude() };
  } catch (e) {
    return undefined;
  }
};

export const coordsToString = (coords: Coords): string => `${coords.lat}, ${coords.lng}`;

export const coordsEqual = (a?: Coords, b?: Coords): boolean => a?.lat === b?.lat && a?.lng === b?.lng;
