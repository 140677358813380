import { PropsWithChildren, useMemo } from "react";
import { lg } from "assets/translations/lg";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useFormat } from "hooks";

type ProgressBarStorageItem = {
  name: string;
  total: number;
};

type Props = {
  items?: ProgressBarStorageItem[];
  totalSpace: number;
  className?: string;
};

const itemColor: [string, string] = ["bg-purple-600", "bg-blue-400"];

export const CustomStorageBar = ({ items = [], totalSpace, className }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { formatBytes } = useFormat();

  const usedSpace = useMemo(
    (): number => items.map(item => item.total).reduce((total, itemTotal) => total + itemTotal, 0),
    [items]
  );

  return (
    <div className={className}>
      <div className="text-secondary text-right">
        {t(lg.storage.usedStorage, {
          used_storage: formatBytes(usedSpace),
          total_storage: formatBytes(totalSpace)
        })}
      </div>

      <div className="flex w-full h-2 bg-gray-300 overflow-hidden mt-3">
        {items.map((item, index) => {
          const width = `${(100 * item.total) / totalSpace}%`;
          return (
            <div
              key={`item-${item.name}-${index}`}
              className={clsx("h-full", itemColor[index])}
              style={{ width: width }}
            />
          );
        })}
      </div>

      {/*
       * Legend
       */}
      {items.length > 0 && (
        <div className="flex -m-2 mt-5">
          {items.map((item, index) => {
            return (
              <div className="p-2" key={`legend-${item.name}-${index}`}>
                <div className="flex items-center">
                  <span className={clsx("block w-2 h-2", itemColor[index] || "bg-purple-900")} />
                  <span className="text-xs text-secondary ml-1">{`${item.name} (${formatBytes(item.total)})`}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
