import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { GroundControlPoint } from "components/Task/GroundControlPoints";
import { useAlert } from "hooks";
import { File } from "types/media";
import { lg } from "assets/translations";

type GroundControlPointForZip = Pick<GroundControlPoint, "id" | "description" | "photos" | "order">;
type SimpleGroundControlPointForZip = Pick<GroundControlPoint, "id" | "description" | "photos">;

/*
 * TODO: HRD003-873 - replace console.error by method sending errors to the Sentry
 * */
export const useZip = () => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [loading, setLoading] = useState(false);

  const fetchFile = useCallback(async (url: string) => {
    const response = await fetch(url, {
      method: "GET",
      mode: "no-cors",
      cache: "no-cache",
      headers: { Origin: window.location.origin }
    });
    return await response.blob();
  }, []);

  const downloadFiles = useCallback(
    async (
      fileName: string,
      points: GroundControlPointForZip[],
      simplePoint?: SimpleGroundControlPointForZip,
      geodeticSurvey?: File,
      geodeticSurveyAdditional?: File[]
    ) => {
      setLoading(true);

      try {
        const zip = new JSZip();
        const rootFolder = zip.folder(fileName);

        if (!rootFolder) {
          console.error("No root folder:", fileName);
          return;
        }

        const pointsFolderName = t(lg.zipDownload.pointsFolderName);
        const pointsFolder = rootFolder.folder(pointsFolderName);

        if (!pointsFolder) {
          console.error("No points folder:", pointsFolderName);
          return;
        }

        const mappedPoints = simplePoint
          ? [
              {
                ...simplePoint,
                order: 1
              }
            ]
          : points;

        for (const point of mappedPoints) {
          const pointFolderName = point.order.toString();
          const pointFolder = pointsFolder.folder(pointFolderName);

          if (!pointFolder) {
            console.error("No point folder:", pointFolderName);
            continue;
          }

          for (const file of point.photos) {
            pointFolder.file(file.name, await fetchFile(file.url));
          }
        }

        if (geodeticSurvey || (geodeticSurveyAdditional && geodeticSurveyAdditional.length > 0)) {
          const surveyFolderName = t(lg.zipDownload.surveyFolderName);
          const surveyFolder = rootFolder.folder(surveyFolderName);

          if (!surveyFolder) {
            console.error("No survey folder:", surveyFolderName);
            return;
          }

          if (geodeticSurvey) {
            surveyFolder.file(geodeticSurvey.fileName, await fetchFile(geodeticSurvey.url));
          }

          if (geodeticSurveyAdditional) {
            for (const file of geodeticSurveyAdditional) {
              surveyFolder.file(file.fileName, await fetchFile(file.url));
            }
          }
        }

        zip.generateAsync({ type: "blob" }).then(content => saveAs(content, `${fileName}.zip`));
      } catch (e) {
        await showAlert();
      }

      setLoading(false);
    },
    [fetchFile, showAlert, t]
  );

  return {
    loading,
    downloadFiles
  };
};
