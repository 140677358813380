/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// issue: https://github.com/facebook/create-react-app/issues/9906
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import App from "./App";
import TagManager from "react-gtm-module";
import "./styles/index.css";
import { getEnv } from "helpers";
import { ServiceWorkerProvider } from "hooks";

const tagManagerArgs = {
  gtmId: getEnv("REACT_APP_GOOGLE_TAG_MANAGER_ID")
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: getEnv("REACT_APP_SENTRY_DSN"),
  enabled: getEnv("NODE_ENV") === "production",
  release: getEnv("REACT_APP_SENTRY_RELEASE"),
  ignoreErrors: [
    // TODO: https://argo22.atlassian.net/browse/HRD005-28
    // antd issue: https://github.com/ant-design/ant-design/issues/26621
    "ResizeObserver loop limit exceeded",
    // issue: https://github.com/juggle/resize-observer/issues/115
    "ResizeObserver loop completed with undelivered notifications."
  ],
  autoSessionTracking: true,
  environment: getEnv("REACT_APP_SENTRY_ENVIRONMENT")
});

document.body.classList.add("font-body");

// createRoot(container!) if you use TypeScript
// @source https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis

const Source = () => (
  <ServiceWorkerProvider>
    <App />
  </ServiceWorkerProvider>
);

const rootElement = document.getElementById("root");

const root = rootElement?.hasChildNodes() ? hydrateRoot(rootElement, <Source />) : createRoot(rootElement!);
root.render(<Source />);
