const baseRoutes = {
  root: "/",
  login: "/login",
  error: "/error/:error",

  invitation: "/invitation/:id",
  purchase: "/purchase/:id",

  dashboard: "/dashboard"
};

/**
 * READ BEFORE CHANGES:
 * The routes below could be used on the backend too.
 * Consult changes with the backend developer that is responsible for the Mawis backend.
 */
const dashboardRoutes = {
  todo: baseRoutes.dashboard + "/tasks",
  todoAll: baseRoutes.dashboard + "/tasks-all",

  taskOperator: baseRoutes.dashboard + "/tasks/operator/:id",
  taskSurveyor: baseRoutes.dashboard + "/tasks/surveyor/:id",
  taskThreeDOperator: baseRoutes.dashboard + "/tasks/3d-operator/:id",
  taskThreeDOperatorFromAllTodoPage: baseRoutes.dashboard + "/tasks-all/3d-operator/:id",

  organizations: baseRoutes.dashboard + "/organizations",
  organization: baseRoutes.dashboard + "/organizations/:id",
  organizationResources: baseRoutes.dashboard + "/organizations/:id/resources",

  constructionSites: baseRoutes.dashboard + "/sites",
  constructionSiteCreate: baseRoutes.dashboard + "/sites/create",
  constructionSiteDetail: baseRoutes.dashboard + "/sites/:id/detail",
  constructionSiteEdit: baseRoutes.dashboard + "/sites/:id/edit",

  constructionObjectCreate: baseRoutes.dashboard + "/sites/:constructionSiteId/object/create",
  constructionObjectDetail: baseRoutes.dashboard + "/sites/:constructionSiteId/object/:id/detail",
  constructionObjectEdit: baseRoutes.dashboard + "/sites/:constructionSiteId/object/:id/edit",

  constructionPhase: baseRoutes.dashboard + "/sites/phase/:id",

  documentationRecords: baseRoutes.dashboard + "/documentations",
  documentationRecordCreateSimplified: baseRoutes.dashboard + "/sites/create-documentation",
  documentationRecordCreateSimplifiedWithSiteId:
    baseRoutes.dashboard + "/sites/create-documentation/:constructionSiteId",

  documentationRecordCreateType:
    baseRoutes.dashboard + "/sites/:constructionSiteId/object/:constructionObjectId/record/create/type",
  documentationRecordCreate:
    baseRoutes.dashboard + "/sites/:constructionSiteId/object/:constructionObjectId/record/create/:type",
  documentationRecordDetail: baseRoutes.dashboard + "/documentations/:id/detail",
  documentationRecordEdit: baseRoutes.dashboard + "/documentations/:id/edit",

  threeDModels: baseRoutes.dashboard + "/3d-models",
  threeDModelsCompleted: baseRoutes.dashboard + "/3d-models-completed",

  threeDViewer: baseRoutes.dashboard + "/3d-viewer/:id",

  support: baseRoutes.dashboard + "/support",
  pricing: baseRoutes.dashboard + "/pricing",

  additionalServices: baseRoutes.dashboard + "/additional-services",

  additionalServicesReview: baseRoutes.dashboard + "/additional-services-review",
  additionalServicesReviewActiveTab: baseRoutes.dashboard + "/additional-services-review/:activeTab",

  inquiry: baseRoutes.dashboard + "/additional-services/inquiry/:id",
  inquiryReview: baseRoutes.dashboard + "/additional-services-review/inquiry/:id",

  about: baseRoutes.dashboard + "/about",
  aboutActiveTab: baseRoutes.dashboard + "/about/:activeTab"
};

export const routes = {
  ...baseRoutes,
  ...dashboardRoutes
};

export const setRouteParams = (route: string, params: { [key: string]: string | number }): string => {
  let url = route;
  for (let key of Object.keys(params)) {
    url = url.replace(`:${key}`, params[key].toString());
  }

  return url;
};

export const getConstructionSiteDetailRoute = (siteId: string) =>
  setRouteParams(routes.constructionSiteDetail, { id: siteId });

export const getConstructionObjectDetailRoute = (objectId: string, siteId: string) =>
  setRouteParams(routes.constructionObjectDetail, { id: objectId, constructionSiteId: siteId });

export const getDocumentationRecordDetailRoute = (recordId: string) =>
  setRouteParams(routes.documentationRecordDetail, { id: recordId });
