import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { Form, message } from "antd";
import { GeneralInfoFormData, GeneralInfoFormItemName } from "components/DocumentationRecord/GeneralInfoForm";
import { useAlert, useDocumentationRecord, useMe } from "hooks";
import {
  DocumentationRecordState,
  DocumentationRecordType,
  DocumentationRecordUpdateInput,
  DocumentationRecordVideoType,
  Maybe,
  resolveError,
  Triggers,
  useDocumentationRecordTriggerMutation,
  useDocumentationRecordUpdateMutation
} from "api";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations";
import { FieldData } from "rc-field-form/es/interface";

export const useDocumentationRecordEdit = (id: string) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const [update, { loading: loadingUpdate }] = useDocumentationRecordUpdateMutation();
  const [callTrigger, { loading: loadingTriggerMutation }] = useDocumentationRecordTriggerMutation();

  /** STATE */
  const [isFormValid, setIsFormValid] = useState(true);
  const [sendToOperator, setSendToOperator] = useState(false);
  const [operatorNote, setOperatorNote] = useState<string | undefined>(undefined);
  const [type, setType] = useState<DocumentationRecordType>(DocumentationRecordType.NoThreeDNoVideo);
  const [videoType, setVideoType] = useState<Maybe<DocumentationRecordVideoType>>(null);
  const [isModalSubmitToOperatorVisible, setIsModalSubmitToOperatorVisible] = useState(false);
  const [canSendToOperator, setCanSendToOperator] = useState(false);

  /** REFS */
  const [form] = Form.useForm();

  /** DATA */
  const { data, loading: loadingRecord } = useDocumentationRecord(id);
  const { organization } = useMe();

  const users = useMemo(() => organization?.users, [organization]);
  const constructionSiteId = data?.site?.id || "";
  const constructionObjectId = data?.object?.id || "";
  const recordState = data.record?.state;

  const formDefaultValues = useMemo<GeneralInfoFormData>(
    () => ({
      name: data.record?.name,
      description: data.record?.description || "",
      phaseId: data.phase?.id,
      accountableManagerId: data.record?.accountableManager?.id || "",
      accountableOperatorId: data.record?.accountableOperator?.id || "",
      accountableSurveyorId: data.record?.accountableSurveyor?.id || ""
    }),
    [data.record, data.phase]
  );

  const loading = loadingRecord;

  const inProgressSave: boolean = loadingUpdate;
  const inProgressSendToOperator: boolean = loadingTriggerMutation;
  const disabled: boolean = loadingRecord || inProgressSave || inProgressSendToOperator;

  const isRequiredVideoType =
    type !== DocumentationRecordType.NoThreeDNoVideo && recordState !== DocumentationRecordState.Created;
  const isValidVideoType = isRequiredVideoType ? !!videoType : true;

  const canEditType =
    recordState === DocumentationRecordState.Created || recordState === DocumentationRecordState.RequiresOperator;

  const isDeleteVisible =
    recordState !== DocumentationRecordState.Archived &&
    recordState !== DocumentationRecordState.RequiresThreeDOperator;
  const isSaveDisabled = !isFormValid || !isValidVideoType;
  const isSaveAndSendVisible = recordState === DocumentationRecordState.Created;
  const isSaveAndSendDisabled = isSaveDisabled || !canSendToOperator;

  /** CALLBACKS */
  const redirectToObjectDetail = useCallback(
    () => navigate(setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id: constructionObjectId })),
    [constructionSiteId, constructionObjectId]
  );
  const redirectToRecordDetail = useCallback(
    () => navigate(setRouteParams(routes.documentationRecordDetail, { id })),
    [id]
  );

  const checkIfCanSendToOperator = useCallback(() => {
    const isOperatorSelected = !!form.getFieldValue(GeneralInfoFormItemName.accountableOperatorId);
    setCanSendToOperator(isOperatorSelected);
  }, [form]);

  /** HANDLERS */
  const handleClickBack = useCallback(() => navigate(setRouteParams(routes.documentationRecordDetail, { id })), [id]);

  const handleChangeDocumentationType = useCallback((type: DocumentationRecordType) => {
    setType(type);
    setVideoType(null);
  }, []);

  const handleChangeVideoType = useCallback((type: DocumentationRecordVideoType | null) => setVideoType(type), []);

  const handleChange = useCallback(
    (changedFields: FieldData[], allFields: FieldData[]) => {
      // check if form is valid to enable external submit button
      setIsFormValid(!allFields.filter(field => field.errors && field.errors.length > 0).length);
      checkIfCanSendToOperator();
    },
    [checkIfCanSendToOperator]
  );

  const updateRecord = useCallback(
    async (values: GeneralInfoFormData) => {
      const input: DocumentationRecordUpdateInput = { documentationRecord: id };

      if (data.record?.name !== values.name) input.name = values.name;
      if (data.record?.description !== values.description) input.description = values.description;
      if (data.record?.accountableManager?.id !== values.accountableManagerId) {
        input.accountableManager = values.accountableManagerId;
      }
      if ((data.record?.accountableOperator?.id || null) !== values.accountableOperatorId) {
        input.accountableOperator = values.accountableOperatorId;
      }
      if ((data.record?.accountableSurveyor?.id || null) !== values.accountableSurveyorId) {
        input.accountableSurveyor = values.accountableSurveyorId;
      }
      if (data.record?.type !== type) input.type = type;
      if (data.record?.documentationRecordVideo?.type !== videoType) {
        input.documentationRecordVideo = {
          type: videoType
        };
      }

      // TODO if new type is NoThreeDNoVideo, set video to null ??? - https://hrdlicka.atlassian.net/browse/HRD003-443

      try {
        await update({ variables: { input } });
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [id, data, type, videoType, update, showAlert]
  );

  const save = useCallback(
    async (values: GeneralInfoFormData) => {
      await updateRecord(values);
      message.success(t(lg.documentationRecord.pageEdit.messages.documentationSaved), 5);
      return redirectToRecordDetail();
    },
    [redirectToRecordDetail, t, updateRecord]
  );

  const saveAndSend = useCallback(
    async (values: GeneralInfoFormData) => {
      try {
        await updateRecord(values);
        await callTrigger({
          variables: { input: { documentationRecord: id, trigger: Triggers.SubmitOperator, comment: operatorNote } }
        });

        message.success(t(lg.documentationRecord.pageEdit.messages.documentationSavedAndSent), 5);
        return redirectToObjectDetail();
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [updateRecord, callTrigger, id, operatorNote, t, showAlert, redirectToObjectDetail]
  );

  const handleSubmit = useCallback(
    async (values: GeneralInfoFormData) => {
      sendToOperator ? await saveAndSend(values) : await save(values);
    },
    [sendToOperator, save, saveAndSend]
  );

  const handleClickSave = useCallback(() => {
    setSendToOperator(false);
    form.submit();
  }, [form]);

  const handleClickSaveAndSend = useCallback(() => {
    setSendToOperator(true);
    setIsModalSubmitToOperatorVisible(true);
  }, []);

  const handleDeleteSuccess = useCallback(async () => {
    message.success(t(lg.documentationRecord.pageDetail.messages.delete), 5);

    if (!data?.site || !data?.object) return;

    await navigate(
      setRouteParams(routes.constructionObjectDetail, {
        constructionSiteId: data.site.id,
        id: data.object.id
      })
    );
  }, [data, t]);

  const handleModalSubmitToOperatorOk = useCallback(
    (comment?: string) => {
      setIsModalSubmitToOperatorVisible(false);
      setOperatorNote(comment);
      setSendToOperator(true);
      form.submit();
    },
    [form]
  );

  const handleModalSubmitToOperatorCancel = useCallback(() => setIsModalSubmitToOperatorVisible(false), []);

  /** EFFECTS */
  /* init loaded data to the state */
  useEffect(() => {
    if (!data.record) return;

    setType(data.record.type);
    setVideoType(data.record?.documentationRecordVideo?.type || null);
    form.setFieldsValue(formDefaultValues);
  }, [form, data.record, formDefaultValues]);

  useEffect(() => {
    if (loading || !formDefaultValues) return;
    checkIfCanSendToOperator();
  }, [checkIfCanSendToOperator, formDefaultValues, loading]);

  return {
    loading,
    disabled,
    inProgressSave,
    inProgressSendToOperator,
    form,
    formDefaultValues,
    data: {
      ...data,
      users,

      canEditType,
      canSendToOperator,

      isDeleteVisible,
      isSaveDisabled,
      isSaveAndSendVisible,
      isSaveAndSendDisabled,
      isModalSubmitToOperatorVisible,

      isValidVideoType
    },
    state: {
      type,
      videoType
    },
    handlers: {
      handleClickBack,
      handleChangeDocumentationType,
      handleChangeVideoType,
      handleChange,
      handleSubmit,
      handleClickSave,
      handleClickSaveAndSend,
      handleModalSubmitToOperatorOk,
      handleModalSubmitToOperatorCancel,
      handleDeleteSuccess
    }
  };
};
