import { RefObject, useCallback, useMemo, useRef, useState } from "react";
import { Button, Input, InputRef } from "antd";
import { lg } from "assets/translations";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { TablePaginationConfig } from "antd/es/table";

export const useColumnSearchProps = () => {
  const searchInputRef: RefObject<InputRef> = useRef(null);
  const { t } = useTranslation();

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div className="p-2">
        <Input
          ref={searchInputRef}
          placeholder={t(lg.table.search.placeholder)}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
          style={{ display: "block" }}
        />
        <div className="flex justify-between w-48 mt-2">
          <Button type="primary" onClick={() => confirm()} icon={<SearchOutlined />} size="small" className="mr-2">
            {t(lg.table.search.buttonSearch)}
          </Button>
          <Button onClick={() => clearFilters()} size="small">
            {t(lg.table.search.buttonReset)}
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInputRef.current?.select());
      }
    }
  };
};

type PaginationArgs = {
  total: number | undefined | null;
  defaultPage?: number;
  pageSize?: number;
};

export const usePagination = ({ defaultPage = 1, total, pageSize = 10 }: PaginationArgs) => {
  const [page, setPage] = useState(defaultPage);
  const pagination: TablePaginationConfig = useMemo(
    () => ({
      current: page,
      total: total || undefined,
      pageSize: pageSize,
      showSizeChanger: false
    }),
    [page, total, pageSize]
  );

  const handleUpdatePage = useCallback((value: number) => setPage(value > 0 ? value : defaultPage), [defaultPage]);

  return {
    pagination,
    setPage: handleUpdatePage,
    skip: (page - 1) * pageSize
  };
};
