import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/Section";
import { lg } from "assets/translations";

import { Video } from "components/Task/VideoUpload";
import { VideoMediaCard } from "components/Task/VideoMediaCard";

type Props = {
  video?: Video;
  captureLocation?: ComponentPropsWithoutRef<typeof VideoMediaCard>["captureLocation"];
  coordinates?: {
    x: number;
    y: number;
  };
  className?: string;
};

export const VideoUploadOverview = ({ video, captureLocation, className }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Section title={t(lg.documentationRecordDetail.videoUploadOverview.title)} className={className}>
      {!video ? (
        <div className="text-xs text-gray-700 text-center whitespace-pre-line mt-10">
          {t(lg.documentationRecord.pageDetail.videoImport.videoDocumentation.noData)}
        </div>
      ) : (
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <VideoMediaCard
              captureLocation={captureLocation}
              fileName={video.fileName}
              fileUrl={video.url}
              fileId={video.id}
            />
          </div>
        </div>
      )}
    </Section>
  );
};
