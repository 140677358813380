import { PropsWithChildren, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { SubheaderOptions, useMe, useSubheader } from "hooks";
import { Content } from "components/LayoutDashboard";
import { TodoAllThreeDOperator } from "./TodoAllThreeDOperator";
import { isThreeDOperator } from "helpers";
import { lg } from "assets/translations/lg";

export const TodoAll = (props: PropsWithChildren<RouteComponentProps>) => {
  const { t } = useTranslation();

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({ heading: t(lg.todoAll.header.title), breadcrumb: [{ value: t(lg.todoAll.header.title) }] }),
    [t]
  );
  useSubheader(subheaderOptions);

  const { me, loading } = useMe();

  return (
    <Content loading={loading} data-cy={"page-todo-all"}>
      {isThreeDOperator(me) && <TodoAllThreeDOperator {...props} />}
    </Content>
  );
};
