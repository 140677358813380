import { TFunction } from "i18next";
import { InputRef, Table as AntTable } from "antd";
import { TableSearchDropdown } from "./TableSearchDropdown";
import { useTranslation } from "react-i18next";
import { useTable } from "hooks/useTable";
import { SearchOutlined } from "@ant-design/icons";
import { ColumnFilterItem, ColumnType, FilterDropdownProps } from "antd/lib/table/interface";
import { ObjectKeys } from "helpers";
import { Key, ReactNode, RefObject } from "react";

interface TableRowConfig<T> extends ColumnType<T> {
  dataIndex?: ObjectKeys<T>;
  key: ObjectKeys<T> | "actions" | string;
  titleKey: string;
  render?: (data: any, record: T, index: number) => ReactNode;
  sort?: boolean;
  filter?: ColumnFilterItem[];
  search?: boolean;
  ellipsis?: boolean;
}

type BaseType = { [key: string]: any };

export type TableConfig<T> = TableRowConfig<T>[];
export type TableHandleSearch = (keys: Key[], after: any) => any;
export type TableHandleReset = (data?: any) => any;

type Props<T extends BaseType> = {
  data: T[];
  config: TableConfig<T>;
  rowKey: ObjectKeys<T>;
};

export const Table = <T extends BaseType>({ data, config, rowKey }: Props<T>) => {
  const { t } = useTranslation();
  const { sortedInfo, filteredInfo, searchInputRef, handleChange, handleSearch, handleReset } = useTable();

  return (
    <AntTable<T>
      dataSource={data}
      columns={getColumnsConfig<T>(t, config, sortedInfo, filteredInfo, searchInputRef, handleSearch, handleReset)}
      onChange={handleChange}
      rowKey={rowKey}
      pagination={{ pageSize: 10, showSizeChanger: false }}
      showSorterTooltip={false}
      scroll={{ x: true }}
    />
  );
};

const getColumnsConfig = <T extends BaseType>(
  t: TFunction,
  config: TableConfig<T>,
  sortedInfo: any,
  filteredInfo: any,
  searchInputRef: RefObject<InputRef>,
  handleSearch: TableHandleSearch,
  handleReset: any
) =>
  config.map(column => ({
    key: column.key,
    dataIndex: column.dataIndex,
    title: t(column.titleKey),
    className: column.className,
    render: column.render,
    ...(!column.sort
      ? {}
      : {
          sorter: (a: T, b: T): number => {
            if (typeof column.dataIndex === "undefined") {
              console.warn("DataIndex is required for sorting");
              return 0;
            }
            return a[column.dataIndex]?.toString().localeCompare(b[column.dataIndex]?.toString());
          },
          sortOrder: sortedInfo && sortedInfo.columnKey === column.key && sortedInfo.order
        }),
    ...(!column.filter && !column.search
      ? {}
      : {
          filters: column.filter,
          filteredValue: (filteredInfo && filteredInfo[column.key]) || null,
          onFilter: (value: any, record: T) => {
            return record[column.key] === value;
          }
        }),
    ...(column.search && !column.filter && column.dataIndex
      ? getColumnSearchProps<T>(column.dataIndex, searchInputRef, handleSearch, handleReset)
      : {})
  }));

const getColumnSearchProps = <T extends BaseType>(
  dataIndex: ObjectKeys<T>,
  searchInputRef: RefObject<InputRef>,
  handleSearch: TableHandleSearch,
  handleReset: TableHandleReset
) => ({
  filterDropdown: (filterDropdownProps: FilterDropdownProps) => (
    <TableSearchDropdown
      {...filterDropdownProps}
      searchInputRef={searchInputRef}
      handleSearch={handleSearch}
      handleReset={handleReset}
    />
  ),
  filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
  onFilter: (value: any, record: T) => {
    return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
  },
  onFilterDropdownVisibleChange: (visible: any) => {
    if (visible) {
      setTimeout(() => searchInputRef.current?.select());
    }
  }
});
