import { Modal } from "antd";

import { PropsWithChildren } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { lg } from "../../assets/translations/lg";

type Props = {
  visible: boolean;
  onOk: () => any;
  onCancel: () => any;
};

export const ModalUnsavedMap = ({ visible, onOk, onCancel }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={visible}
      title={
        <div className="flex items-center text-blue-600">
          <InfoCircleOutlined className="text-lg" />
          <span className="text-black ml-2">{t(lg.modalUnsavedMap.title)}</span>
        </div>
      }
      cancelText={t(lg.modalUnsavedMap.buttonCancelText)}
      okText={t(lg.modalUnsavedMap.buttonOkText)}
      closable={false}
      onCancel={onCancel}
      onOk={onOk}
    >
      <p className="text-base">{t(lg.modalUnsavedMap.description)}</p>
    </Modal>
  );
};
