import {
  cloneElement,
  ComponentPropsWithoutRef,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState
} from "react";
import { Input, Modal, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { SaveOutlined } from "@ant-design/icons";
import { useAlert, useConfig } from "hooks";
import { resolveError, useConstructionPhaseUpdateMutation } from "api";
import { lg } from "assets/translations/lg";

type RadioGroupProps = ComponentPropsWithoutRef<typeof Radio.Group>;
type InputProps = ComponentPropsWithoutRef<typeof Input>;

type Props = {
  id: string;
  name: string;
  order: number;
  children: ReactElement;
  onSuccess?: (name: string) => any;
  onError?: (name: string) => any;
};

export const PhaseUpdate = ({
  id,
  name: currentName,
  order,
  children,
  onSuccess = () => {},
  onError = () => {}
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { config } = useConfig();
  const [update, { loading: loadingPhaseUpdateMutation }] = useConstructionPhaseUpdateMutation();

  const [visible, setVisible] = useState(false);
  const [name, setName] = useState<string>("");
  const [customName, setCustomName] = useState<string>("");

  const sending = useMemo<boolean>(() => loadingPhaseUpdateMutation, [loadingPhaseUpdateMutation]);
  const disabled = useMemo<boolean>(() => sending, [sending]);
  const open = useCallback(() => {
    setName(currentName);
    setCustomName(config.defaultConstructionPhases.includes(currentName) ? "" : currentName);
    setVisible(true);
  }, [currentName, config.defaultConstructionPhases]);

  const handleRadioChangePhase: Required<RadioGroupProps>["onChange"] = useCallback(({ target: { value } }) => {
    setName(value);
  }, []);

  const handleChangeInput: Required<InputProps>["onChange"] = useCallback(({ target: { value } }) => {
    setName(value);
    setCustomName(value);
  }, []);

  const handleOk = useCallback(async () => {
    if (!name) return;

    setVisible(false);

    try {
      await update({ variables: { input: { constructionPhase: id, name, order } } });
      onSuccess(name);
    } catch (e) {
      resolveError(e, {}, showAlert);
      onError(name);
    }
  }, [id, name, order, update, onSuccess, onError, showAlert]);

  const handleCancel = useCallback(() => setVisible(false), []);

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={t(lg.constructionSite.pageDetail.phases.modalUpdate.titleUpdate)}
        open={visible}
        okText={t(lg.constructionSite.pageDetail.phases.modalUpdate.buttonOkText)}
        cancelText={t(lg.constructionSite.pageDetail.phases.modalUpdate.buttonCancelText)}
        okButtonProps={{ type: "primary", icon: <SaveOutlined />, disabled: !name || name === currentName || disabled }}
        cancelButtonProps={{ disabled }}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        confirmLoading={sending}
      >
        <div className="mb-2">{t(lg.constructionSite.pageDetail.phases.modalUpdate.form.phaseNameLabel)}</div>
        <Radio.Group onChange={handleRadioChangePhase} value={name} disabled={disabled}>
          {config.defaultConstructionPhases.map((phaseName, index) => (
            <Radio className="flex items-center h-8" value={phaseName} key={`${phaseName}${index}`}>
              <span className="text-secondary leading-none">{phaseName}</span>
            </Radio>
          ))}

          <Radio className="flex items-center h-8" value={customName}>
            <Input
              style={{ width: 260 }}
              placeholder={t(lg.constructionSite.pageDetail.phases.modalUpdate.form.phaseNameCustomInputPlaceholder)}
              value={customName}
              onChange={handleChangeInput}
            />
          </Radio>
        </Radio.Group>
      </Modal>
    </>
  );
};
