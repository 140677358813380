import { PropsWithChildren, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TypeIndicator } from "components/Indicators/Type/TypeIndicator";
import { CustomIconType } from "components/icons/CustomIcon";
import { TypeIndicatorProps } from "components/Indicators/Type/index";
import { ThreeDModelType, threeDModelTypes } from "api/enums";

type Props = {
  type?: ThreeDModelType;
} & TypeIndicatorProps;

export const TypeIndicatorThreeDModel = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const icon = useMemo((): CustomIconType => {
    if (props.type) return threeDModelTypes[props.type].icon;
    return "read-question-mark-orange";
  }, [props.type]);

  const typeName = useMemo<string>(() => {
    if (props.type) return t(threeDModelTypes[props.type].nameI18nKey);
    return "-";
  }, [t, props.type]);

  const label = useMemo<ReactNode>(() => (props.label !== undefined ? props.label : typeName), [props.label, typeName]);

  return <TypeIndicator {...props} icon={icon} tooltipTitle={typeName} label={label} />;
};
