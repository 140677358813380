import { FunctionComponent, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Content } from "components/LayoutDashboard";
import { GeneralInfoForm } from "components/ConstructionSites/GeneralInfoForm";
import { Map } from "components/Map";
import { ModalUnsavedMap } from "components/ConstructionSites/ModalUnsavedMap";
import { ControlSettingsRadioCard } from "components/ConstructionSites/ControlSettingsRadioCards";
import { Section } from "components/Section";
import { SubheaderOptions, useConstructionSite, useSubheader } from "hooks";
import { routes } from "routes";
import { lg } from "assets/translations/lg";
import { StateToggle } from "components/ConstructionSiteEdit/StateToggle";

export const ConstructionSiteCreate: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();

  const { form, loading, sending, disabled, state, data, handlers } = useConstructionSite("create");

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: t(lg.constructionSite.pageCreate.header.title),
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        { value: t(lg.constructionSite.pageCreate.header.title) }
      ],
      buttons: [
        <Button
          key="abort"
          type={"default"}
          size={"large"}
          disabled={loading || disabled}
          onClick={handlers.handleClickAbort}
        >
          {t(lg.constructionSite.pageCreate.header.buttonSecondaryText)}
        </Button>,
        <Button
          key="save"
          type={"primary"}
          size={"large"}
          icon={loading || sending ? <LoadingOutlined /> : <SaveOutlined />}
          disabled={!state.isFormValid || !data.hasValidCoords || disabled}
          onClick={handlers.handleClickSave}
        >
          {t(!loading ? lg.constructionSite.pageCreate.header.buttonPrimaryText : lg.saving)}
        </Button>
      ]
    }),
    [
      loading,
      sending,
      disabled,
      state.isFormValid,
      data.hasValidCoords,
      handlers.handleClickAbort,
      handlers.handleClickSave,
      t
    ]
  );
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      {/*
      State toggle
      */}
      <StateToggle
        className="justify-end"
        isActive={data.isSiteActive}
        disabled={disabled}
        onChange={handlers.handleChangeState}
        description={
          data.isSiteActive
            ? t(lg.constructionSite.pageCreate.actions.statusActive)
            : t(lg.constructionSite.pageCreate.actions.statusInactive)
        }
      />

      {/*
      Form section

      */}
      <Section title={t(lg.constructionSite.pageCreate.baseInformation.title)} className="mt-10">
        <div className="mt-10">
          <GeneralInfoForm
            formRef={form}
            disabled={disabled}
            onChange={handlers.handleFormChange}
            onFinish={handlers.handleSubmit}
            users={data.myOrganization.users}
          />
        </div>
      </Section>

      {/*
      Map section
      */}
      <Section title={t(lg.constructionSite.pageCreate.map.title)} className="mt-16">
        <div className="mt-10">
          <Map
            searchZoom={17}
            editable={true}
            saveDisabled={loading || data.hasSavedCoords}
            hasSearch={true}
            hasValidCoords={data.hasValidCoords}
            onChange={handlers.handleMapChange}
            onSave={handlers.handleMapSave}
          />
        </div>
      </Section>

      <Section title={t(lg.constructionSite.pageCreate.controlSettings.title)} className="mt-16">
        <div className="flex flex-col mt-10">
          <div>{t(lg.constructionSite.pageCreate.controlSettings.description)}</div>

          <div className="mt-10">
            <ControlSettingsRadioCard
              defaultValue="manually"
              disabled={disabled}
              onChange={handlers.handleChangeControlSettings}
            />
          </div>
        </div>
      </Section>

      {/*
      Modals
      */}
      {/* warning before save - unsaved map */}
      <ModalUnsavedMap
        visible={data.isVisibleModalCoords}
        onOk={handlers.handleClickCoordsModalOk}
        onCancel={handlers.handleClickCoordsModalCancel}
      />
    </Content>
  );
};
