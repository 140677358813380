import { useMemo } from "react";
import { isUploaded, mediaToFile } from "helpers";
import {
  ConstructionElementFragment,
  resolveError,
  SimpleGroundControlPointFragment,
  TriggerFragment,
  useDocumentationRecordQuery
} from "api";
import { File } from "types/media";
import { useAlert } from "hooks/useAlert";

/**
 * Hook for api data mapping - used in useTask and useDocumentationRecordDetail hooks
 * @param id DocumentationRecord ID
 */
export const useDocumentationRecord = (id: string) => {
  const { showAlert } = useAlert();
  /** DATA */
  const { data, loading, refetch } = useDocumentationRecordQuery({
    variables: { id },
    fetchPolicy: "cache-and-network",
    skip: !id,
    onError: e => resolveError(e, undefined, showAlert)
  });

  const record = useMemo(() => {
    return data?.documentationRecord;
  }, [data]);

  const object = useMemo(() => {
    return record?.constructionObject;
  }, [record]);

  const site = useMemo(() => {
    return object?.constructionSite;
  }, [object]);

  const phase = useMemo(() => {
    return record?.constructionPhase;
  }, [record]);

  const organization = useMemo(() => {
    return site?.organization;
  }, [site]);

  const triggers = useMemo<TriggerFragment[]>(() => {
    return (record?.triggers || []) as TriggerFragment[];
  }, [record]);

  const simpleGroundControlPointCount = record?.simpleGroundControlPointCount || undefined;
  const simpleGroundControlPoint = useMemo<SimpleGroundControlPointFragment | undefined>(
    () => record?.simpleGroundControlPoint || undefined,
    [record]
  );

  const groundControlPoints = useMemo(() => {
    return (
      record?.groundControlPointDocumentations.edges
        .filter(({ node }) => node.archivedAt === null)
        .map(({ node }) => node) || []
    );
  }, [record]);

  const constructionElements = useMemo<ConstructionElementFragment[]>(
    () =>
      record?.constructionElements.edges.map(({ node }) => node).filter(element => isUploaded(element.photo.state)) ||
      [],
    [record]
  );

  const recordVideo = useMemo(() => record?.documentationRecordVideo, [record]);
  const recordVideoMedia = useMemo(() => recordVideo?.video, [recordVideo]);

  const video = useMemo<File | undefined>(() => {
    if (!recordVideoMedia || !isUploaded(recordVideoMedia.state)) return;
    return mediaToFile(recordVideoMedia);
  }, [recordVideoMedia]);

  const geodeticSurvey = useMemo<File | undefined>(() => {
    return mediaToFile(record?.geodeticSurvey?.txtSurvey);
  }, [record]);

  const geodeticSurveyAdditional = useMemo<File[]>(() => {
    return (record?.geodeticSurvey?.files.edges
      .map(({ node }) => mediaToFile(node))
      .filter(file => file !== undefined) || []) as File[];
  }, [record]);

  const threeDModel = useMemo(() => {
    return record?.threeDModel;
  }, [record]);

  const comments = useMemo(() => {
    return record?.stateChanges.edges.filter(({ node }) => !!node.comment).map(({ node }) => node) || [];
  }, [record]);

  const outputExtensions = useMemo(() => record?.outputExtensions, [record]);

  const firstVersion = useMemo(() => record?.firstVersion.edges[0]?.node, [record]);
  const currentVersion = useMemo(() => record?.currentVersion.edges[0]?.node, [record]);

  return {
    loading,
    refetch,
    data: {
      record,
      object,
      site,
      phase,
      organization,
      triggers,
      simpleGroundControlPointCount,
      simpleGroundControlPoint,
      groundControlPoints,
      constructionElements,
      recordVideo,
      video,
      geodeticSurvey,
      geodeticSurveyAdditional,
      threeDModel,
      comments,
      outputExtensions,
      firstVersion,
      currentVersion
    }
  };
};
