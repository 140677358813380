import { routes, setRouteParams } from "routes";
import { Link } from "components/Link";
import { useMemo } from "react";
import clsx from "clsx";

type ConstructionBreadcrumbProps = {
  constructionSite: {
    id: string;
    name: string;
  };
  constructionObject?: {
    id: string;
    name: string;
  };
  documentationRecord?: {
    id: string;
    name: string;
  };
  disabled?: boolean;
};

export const ConstructionBreadcrumb = ({
  constructionSite,
  constructionObject,
  documentationRecord,
  disabled = false
}: ConstructionBreadcrumbProps) =>
  useMemo(
    () => (
      <div className="flex flex-wrap">
        <Crumb
          route={setRouteParams(routes.constructionSiteDetail, { id: constructionSite.id })}
          label={constructionSite.name}
          hasNext={!!constructionObject}
          disabled={disabled}
        />
        {constructionObject && (
          <Crumb
            route={setRouteParams(routes.constructionObjectDetail, {
              constructionSiteId: constructionSite.id,
              id: constructionObject.id
            })}
            label={constructionObject.name}
            hasNext={!!documentationRecord}
            disabled={disabled}
          />
        )}
        {documentationRecord && (
          <Crumb
            route={setRouteParams(routes.documentationRecordDetail, { id: documentationRecord.id })}
            label={documentationRecord.name}
            hasNext={false}
            disabled={disabled}
          />
        )}
      </div>
    ),
    [constructionSite, constructionObject, documentationRecord, disabled]
  );

type CrumbProps = {
  route: string;
  label: string;
  hasNext: boolean;
  disabled: boolean;
};

const Crumb = ({ route, label, hasNext, disabled }: CrumbProps) => {
  const color = disabled ? "text-gray-700" : "text-purple-600";
  return (
    <div className="flex flex-nowrap">
      {disabled && <div className={color}>{label}</div>}
      {!disabled && <Link href={route}>{label}</Link>}
      {hasNext && <span className={clsx("font-medium mx-1", color)}> / </span>}
    </div>
  );
};
