import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { CoordinateSystem, resolveError, useDocumentationRecordUpdateMutation } from "api";
import { lg } from "assets/translations";
import { useAlert } from "hooks";
import { coordinateSystems } from "api/enums";
import { enumToValues } from "helpers";

type Props = {
  documentationRecordId: string;
  value: CoordinateSystem;
  disabled?: boolean;
  className?: string;
};

type CoordinateSystemOption = {
  value: CoordinateSystem;
  label: string;
};

export const CoordinateSystemSelect = ({
  documentationRecordId,
  value,
  disabled = false,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [update, { loading }] = useDocumentationRecordUpdateMutation();

  /**
   * Coordinate system values alphabetically sorted by label
   */
  const coordinateSystemOptions: CoordinateSystemOption[] = useMemo(
    () =>
      enumToValues<CoordinateSystem>(CoordinateSystem)
        .map(value => ({
          value: value,
          label: t(coordinateSystems[value].nameI18nKey)
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t]
  );

  const handleChange = useCallback(
    async (value: CoordinateSystem) => {
      try {
        await update({
          variables: { input: { documentationRecord: documentationRecordId, threeDModel: { coordinateSystem: value } } }
        });
      } catch (e) {
        resolveError(e, undefined, showAlert);
      }
    },
    [documentationRecordId, update, showAlert]
  );

  return (
    <div className={className}>
      <div className="text-blue-700 text-base font-medium">
        {t(lg.taskThreeDOperator.modelUpload.coordinateSystemSelect.title)}
      </div>
      <label htmlFor="coordinateSystem" className="block mt-2">
        {t(lg.taskThreeDOperator.modelUpload.coordinateSystemSelect.label)}
      </label>
      <Select
        id="coordinateSystem"
        value={value}
        disabled={loading || disabled}
        className="w-full mt-1"
        onChange={handleChange}
      >
        {coordinateSystemOptions.map(({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};
