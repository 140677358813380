import { TypeIndicatorThreeDModel } from "components/Indicators/Type";

import { useFormat } from "hooks";
import { coordinateSystems, ThreeDModelType } from "api/enums";
import { ThreeDModel } from "api";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";

type ThreeDModelInfoProps = Pick<ThreeDModel, "createdAt" | "coordinateSystem"> & {
  totalSize: number;
  type: ThreeDModelType;
  className?: string;
};

export const ThreeDModelInfo = ({ type, createdAt, totalSize, coordinateSystem, className }: ThreeDModelInfoProps) => {
  const { t } = useTranslation();
  const { formatDate, formatBytes } = useFormat();
  return (
    <div className={className}>
      <div className="text-blue-700 text-base font-medium">{t(lg.taskThreeDOperator.threeDModelInfo.title)}</div>

      <div className="c-grid mt-5">
        <div className="c-grid-column">
          <div className="font-medium">{t(lg.taskThreeDOperator.threeDModelInfo.type)}</div>
          <TypeIndicatorThreeDModel type={type} className="mt-2" />
        </div>

        <div className="c-grid-column">
          <div className="font-medium">{t(lg.taskThreeDOperator.threeDModelInfo.createdAt)}</div>
          {formatDate(createdAt, "human")}
        </div>
      </div>

      <div className="c-grid mt-4">
        <div className="c-grid-column">
          <div className="font-medium">{t(lg.taskThreeDOperator.threeDModelInfo.size)}</div>
          {formatBytes(totalSize)}
        </div>
        {coordinateSystem ? (
          <div className="c-grid-column">
            <div className="font-medium">{t(lg.taskThreeDOperator.threeDModelInfo.coordinateSystemType)}</div>
            {t(coordinateSystems[coordinateSystem].nameI18nKey)}
          </div>
        ) : null}
      </div>
    </div>
  );
};
