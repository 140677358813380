import { Language } from "api/generated";

const STORAGE_KEY_PREFIX = "@m4d-webapp";

/**
 *
 * User
 *
 * */
export const LS_AUTH_TOKEN = STORAGE_KEY_PREFIX + "/authToken";
export const LS_REFRESH_TOKEN = STORAGE_KEY_PREFIX + "/refreshToken";

export const LS_SKIPPED_INVITATIONS: string = STORAGE_KEY_PREFIX + "/skippedInvitations";
export const LS_SKIPPED_PURCHASES: string = STORAGE_KEY_PREFIX + "/skippedPurchases";

/*
 *  Authorization Token
 * */
export const getAuthToken = (): string | null => {
  try {
    const token = window.localStorage.getItem(LS_AUTH_TOKEN);
    return typeof token === "string" && token.length ? JSON.parse(token) : null;
  } catch (e) {
    return null;
  }
};

export const setAuthToken = (value: string): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, JSON.stringify(value));
  } catch (e) {}
};

export const clearAuthToken = (): void => {
  try {
    window.localStorage.setItem(LS_AUTH_TOKEN, "");
  } catch (e) {}
};

/*
 * Refresh token
 * */
export const getRefreshToken = (): string | null => {
  try {
    const token = window.localStorage.getItem(LS_REFRESH_TOKEN);
    return typeof token === "string" && token.length ? JSON.parse(token) : null;
  } catch (e) {
    return null;
  }
};

export const setRefreshToken = (value: string): void => {
  try {
    window.localStorage.setItem(LS_REFRESH_TOKEN, JSON.stringify(value));
  } catch (e) {}
};

export const clearRefreshToken = (): void => {
  try {
    window.localStorage.setItem(LS_REFRESH_TOKEN, "");
  } catch (e) {}
};

/*
 * User Skipped Invitations
 * */
export const getSkippedInvitations = (): string[] | null => {
  try {
    const invitations = window.localStorage.getItem(LS_SKIPPED_INVITATIONS);
    return typeof invitations === "string" && invitations.length ? JSON.parse(invitations) : null;
  } catch (e) {
    return null;
  }
};

export const setSkippedInvitations = (value: string[]): void => {
  try {
    window.localStorage.setItem(LS_SKIPPED_INVITATIONS, JSON.stringify(value));
  } catch (e) {}
};

export const clearSkippedInvitations = (): void => {
  try {
    window.localStorage.setItem(LS_SKIPPED_INVITATIONS, JSON.stringify([]));
  } catch (e) {}
};

/*
 * User Skipped Purchases
 * */
export const getSkippedPurchases = (): string[] | null => {
  try {
    const purchases = window.localStorage.getItem(LS_SKIPPED_PURCHASES);
    return typeof purchases === "string" && purchases.length ? JSON.parse(purchases) : null;
  } catch (e) {
    return null;
  }
};

export const setSkippedPurchases = (value: string[]): void => {
  try {
    window.localStorage.setItem(LS_SKIPPED_PURCHASES, JSON.stringify(value));
  } catch (e) {}
};

export const clearSkippedPurchases = (): void => {
  try {
    window.localStorage.setItem(LS_SKIPPED_PURCHASES, JSON.stringify([]));
  } catch (e) {}
};

/**
 *
 * App
 *
 * */
export const SS_TOKEN_ERROR_NOTIFY = STORAGE_KEY_PREFIX + "/tokenErrorNotify";
export const SS_ACTIVE_LANGUAGE = STORAGE_KEY_PREFIX + "/i18n.lng";

/*
 * Token Error Notification
 * */
export const getTokenErrorNotify = (): boolean | null => {
  try {
    const notify = window.sessionStorage.getItem(SS_TOKEN_ERROR_NOTIFY);
    return typeof notify === "string" && notify.length ? JSON.parse(notify) : null;
  } catch (e) {
    return null;
  }
};

export const setTokenErrorNotify = (value: boolean): void => {
  try {
    window.sessionStorage.setItem(SS_TOKEN_ERROR_NOTIFY, JSON.stringify(value));
  } catch (e) {}
};

export const clearTokenErrorNotify = (): void => {
  try {
    window.sessionStorage.setItem(SS_TOKEN_ERROR_NOTIFY, JSON.stringify(false));
  } catch (e) {}
};

/*
 * Language
 * */
export const getLngFromStorage = (): Language | null => {
  try {
    const lng = window.sessionStorage.getItem(SS_ACTIVE_LANGUAGE) as Language | undefined;
    return typeof lng === "string" && lng.length ? JSON.parse(lng) : null;
  } catch (e) {
    return null;
  }
};

export const setLngToStorage = (lng: Language): void => {
  try {
    window.sessionStorage.setItem(SS_ACTIVE_LANGUAGE, JSON.stringify(lng));
  } catch (e) {}
};

export const clearLngStorage = (): void => {
  try {
    window.sessionStorage.setItem(SS_ACTIVE_LANGUAGE, "");
  } catch (e) {}
};
