import { ReactNode, useCallback } from "react";
import { LogoIcon } from "components/icons/LogoIcon";
import { UserMenu } from "components/UserMenu";
import { routes } from "routes";
import { LanguageSwitcher } from "components/LanguageSwitcher";
import { HOMEPAGE_ANCHOR_ID } from "pages";
import { Link, navigate, useNavigate } from "@reach/router";
import { UserOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";
import { isReactSnap } from "helpers";
import clsx from "clsx";

type Props = { simple?: boolean; className?: string };

export const Header = ({ simple, className }: Props) => {
  const navigate = useNavigate();
  const handleClickLogoSimple = useCallback(() => navigate(routes.root), [navigate]);

  if (simple) {
    return (
      <header className={clsx("flex items-center", className)}>
        <div className="cursor-pointer" onClick={handleClickLogoSimple}>
          <LogoIcon className="w-36 sm:w-44" />
        </div>

        <div className="flex items-center ml-auto">
          {isReactSnap ? null : <Menu className="hidden lg:block" />}
          <LoginButton className="lg:ml-2.5" />
          <LanguageSwitcher className="ml-5 sm:ml-10" />
        </div>
      </header>
    );
  }

  return (
    <header
      className={clsx(
        "flex items-center px-6 py-5 border-t-4 border-purple-500 bg-blue-900 shadow-decent-down z-30",
        className
      )}
    >
      <LogoIcon className="w-36 mr-2" />
      <div className="flex ml-auto items-center">
        <UserMenu />
        <LanguageSwitcher className="ml-4" />
      </div>
    </header>
  );
};

const MenuAnchorItem = ({ id, children }: { id: string; children: ReactNode }) => {
  const {
    i18n: { language }
  } = useTranslation();

  const href = `${routes.root}${language}#${id}`;
  const handleClick = () => navigate(href);

  return (
    <a className="text-gray-100 text-sm p-2.5" href={href} onClick={handleClick}>
      {children}
    </a>
  );
};

type MenuProps = { className?: string };
const Menu = ({ className }: MenuProps) => {
  const { t } = useTranslation();

  return (
    <nav className={className}>
      <MenuAnchorItem id={HOMEPAGE_ANCHOR_ID.WHY}>{t(lg.header.menu.whyMawisPhoto)}</MenuAnchorItem>
      <MenuAnchorItem id={HOMEPAGE_ANCHOR_ID.HOW_IT_WORKS}>{t(lg.header.menu.howItWorks)}</MenuAnchorItem>
      <MenuAnchorItem id={HOMEPAGE_ANCHOR_ID.BUY}>{t(lg.header.menu.buy)}</MenuAnchorItem>
    </nav>
  );
};

type LoginButtonProps = { className?: string };
const LoginButton = ({ className }: LoginButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Link
        to={routes.login}
        className={clsx("text-gray-100 text-lg sm:hidden", className)}
        title={t(lg.header.menu.login)}
      >
        <UserOutlined />
      </Link>
      <Button
        type={"primary"}
        onClick={e => {
          e.preventDefault();
          return navigate(routes.login);
        }}
        href={routes.login}
        className={clsx("hidden sm:block", className)}
        title={t(lg.header.menu.login)}
      >
        {t(lg.header.menu.login)}
      </Button>
    </>
  );
};
