import { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useLocalStorage, useMe } from "hooks";
import { routes, setRouteParams } from "routes";
import { Spin } from "antd";
import { isThreeDOperator } from "helpers";
import { navigate } from "@reach/router";
import { AccessErrors } from "api/enums";
import { useAuth } from "api/auth";
import { LS_SKIPPED_INVITATIONS, LS_SKIPPED_PURCHASES } from "api/storage";

export const AccessResolver = ({ children }: PropsWithChildren<{}>) => {
  const { me, organization, receivedInvitations, purchases, loading: loadingMe } = useMe();
  const { isLoggedIn } = useAuth();

  const [canRender, setCanRender] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  const loading = useMemo(() => loadingMe || isChecking, [isChecking, loadingMe]);

  const [skippedInvitations] = useLocalStorage<string[]>(LS_SKIPPED_INVITATIONS, []);
  const [skippedPurchases] = useLocalStorage<string[]>(LS_SKIPPED_PURCHASES, []);

  const meWasLoaded = useMemo<boolean>(() => !!me.id, [me.id]);

  const availableInvitations = useMemo(
    () => receivedInvitations.filter(invitation => !skippedInvitations.includes(invitation.id)),
    [receivedInvitations, skippedInvitations]
  );
  const availablePurchases = useMemo(
    () => purchases.filter(invitation => !skippedPurchases.includes(invitation.id)),
    [purchases, skippedPurchases]
  );

  const pendingInvitations = useMemo(() => availableInvitations.length > 0, [availableInvitations]);
  const pendingPurchases = useMemo(() => availablePurchases.length > 0, [availablePurchases]);
  const noTeamOrLicence = useMemo(() => !organization.id, [organization]);

  const checkMe = useCallback(async () => {
    setIsChecking(true);

    if (pendingInvitations) {
      return navigate(setRouteParams(routes.invitation, { id: availableInvitations[0].id }));
    }
    if (pendingPurchases) {
      return navigate(setRouteParams(routes.purchase, { id: availablePurchases[0].id }));
    }
    if (noTeamOrLicence && !isThreeDOperator(me)) {
      return navigate(setRouteParams(routes.error, { error: AccessErrors.NoTeamOrLicence }));
    }
    setIsChecking(false);
  }, [pendingInvitations, pendingPurchases, availableInvitations, availablePurchases, noTeamOrLicence, me]);

  /* handle user logged in status */
  useEffect(() => {
    return setCanRender(isLoggedIn() && meWasLoaded);
  }, [isLoggedIn, meWasLoaded]);

  /* add extra info to the Sentry */
  useLayoutEffect(() => {
    if (!isLoggedIn()) return;

    Sentry.setUser({
      id: me.id,
      email: me.email,
      username: `${me.firstName} ${me.lastName}`,
      role: me.role
    });
  }, [isLoggedIn, me.id, me.email, me.firstName, me.lastName, me.role]);

  useEffect(() => {
    if (loading || !meWasLoaded) return;

    (async function check() {
      await checkMe();
    })();
  }, [loading, checkMe, meWasLoaded]);

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen bg-blue-100">
        <Spin />
      </div>
    );
  }

  if (!canRender) return null;

  return <>{children}</>;
};
