import { setContext } from "apollo-link-context";
import { ApolloLink } from "apollo-link/lib/link";
import { apiCompatibilityStore } from "hooks";
import { client } from "api/index";
import { ApiCompatibility } from "api/enums";

const setApiVersionLink: ApolloLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      // compatible with specified API version
      "X-mawisphoto-Target-Version": "1.0.0"
    }
  };
});

const getApiCompatibilityLink: ApolloLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();
    const headers = context?.response.headers;

    if (headers) {
      const compatibility = headers.get("X-mawisphoto-API-Compatibility");
      // set status from request header to the globalStore
      apiCompatibilityStore.actions.set(compatibility);

      if (compatibility === ApiCompatibility.Incompatible) {
        // stop all API request
        client.stop();
        client.clearStore();
      }
    }
    return response;
  });
});

export const setApiCompatibilityLink: ApolloLink = setApiVersionLink.concat(getApiCompatibilityLink);
