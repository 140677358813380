import { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Button } from "antd";
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Content } from "components/LayoutDashboard";
import { Section } from "components/Section";
import { ProgressSteps } from "components/DocumentationRecord/ProgressSteps";
import { SubheaderOptions, useDocumentationRecordDetail, useFormat, usePermissions, useSubheader } from "hooks";
import { routes, setRouteParams } from "routes";
import { GeneralInfo } from "components/DocumentationRecordDetail/GeneralInfo";
import { PhotoDocumentationList } from "components/DocumentationRecordDetail/PhotoDocumentationList";
import { Details } from "components/DocumentationRecordDetail/Details";
import { Comments } from "components/DocumentationRecordDetail/Comments";
import { lg } from "assets/translations/lg";
import { useTranslation } from "react-i18next";
import { DocumentationRecordState, DocumentationRecordType } from "api";
import * as Actions from "components/DocumentationRecord/Actions";
import { Delete } from "components/DocumentationRecordDetail/Delete";
import {
  GroundControlPoint,
  GroundControlPointsOverview,
  SimpleGroundControlPoint
} from "components/Task/GroundControlPoints";
import { GeodeticSurveyUploadOverview, RecordTypeInfo, VideoTypeInfo, VideoUploadOverview } from "components/Task";
import { ThreeDModel } from "components/DocumentationRecordDetail/ThreeDModel";
import { getCoords, getThreeDModelType } from "helpers";
import { OutputExtensions } from "components/DocumentationRecordDetail/OutputExtensions";

/**
 * Documentation Record detail
 * For Manager role only - serves also as task for manager
 * For other roles, see Task page
 * */
export const DocumentationRecordDetail = ({ id }: RouteComponentProps<{ id: string }>) => {
  if (!id) {
    throw new Error("Missing documentation record ID.");
  }

  const { t } = useTranslation();
  const { arrayToCoords } = useFormat();

  const { loading, data, handlers, refetch } = useDocumentationRecordDetail(id);

  const state = useMemo(() => data?.record?.state, [data]);
  const type = useMemo(() => data?.record?.type, [data]);
  const hasGroundControlPoints = useMemo(() => type === DocumentationRecordType.ThreeDScale, [type]);
  const hasGeodeticSurvey = useMemo(() => type === DocumentationRecordType.ThreeDScale, [type]);
  const hasVideo = useMemo(() => type !== DocumentationRecordType.NoThreeDNoVideo, [type]);
  const hasOutputExtensions: boolean = (data.outputExtensions?.totalCount || 0) > 0;
  const hasModel = useMemo(
    () => type !== DocumentationRecordType.NoThreeDNoVideo && type !== DocumentationRecordType.NoThreeDVideo,
    [type]
  );
  const hasActionsSection = useMemo(() => {
    return (
      !!state &&
      state !== DocumentationRecordState.Created &&
      state !== DocumentationRecordState.RequiresOperator &&
      state !== DocumentationRecordState.RequiresThreeDOperator
    );
  }, [state]);
  const hasDeleteAction = useMemo(() => {
    return (
      !!state &&
      state !== DocumentationRecordState.Archived &&
      state !== DocumentationRecordState.RequiresThreeDOperator
    );
  }, [state]);

  const accountableManager = useMemo(() => data.record?.accountableManager, [data.record]);

  const { canEditDocumentationRecord, canDeleteDocumentationRecord } = usePermissions({
    documentationRecord: { managerId: accountableManager?.id }
  });

  const groundControlPoints = useMemo<GroundControlPoint[]>(
    () =>
      data.groundControlPoints.map(point => ({
        id: point.id,
        order: point.order,
        coords: getCoords(point.location || []),
        description: point.description || "",
        photos: point.files.edges.map(({ node }) => ({
          id: node.id,
          url: node.url || "",
          name: node.fileName || ""
        }))
      })),
    [data.groundControlPoints]
  );

  const simpleGroundControlPoint = useMemo<SimpleGroundControlPoint | undefined>(
    () =>
      data.simpleGroundControlPoint
        ? {
            id: data.simpleGroundControlPoint.id,
            order: 1,
            description: data.simpleGroundControlPoint.description || "",
            photos: data.simpleGroundControlPoint.files.edges.map(({ node }) => ({
              id: node.id,
              url: node.url || "",
              name: node.fileName || ""
            }))
          }
        : undefined,
    [data.simpleGroundControlPoint]
  );

  const { record, site, object, video, recordVideo, geodeticSurvey, geodeticSurveyAdditional, threeDModel, triggers } =
    data;

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const recordName = record?.name || "...";
    const objectName = object?.name || "...";
    const siteName = site?.name || "...";

    return {
      heading: `${t(lg.documentationRecord.pageDetail.header.title)}: ${recordName}`,
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        {
          value: siteName,
          route: site ? setRouteParams(routes.constructionSiteDetail, { id: site.id }) : undefined
        },
        {
          value: objectName,
          route:
            site && object
              ? setRouteParams(routes.constructionObjectDetail, {
                  constructionSiteId: site.id,
                  id: object.id
                })
              : undefined
        },
        { value: recordName }
      ],
      buttons:
        record?.state !== DocumentationRecordState.RequiresOperator
          ? [
              <Actions.MainAction
                type={"manager"}
                documentationRecordId={id}
                triggers={triggers}
                onAfterTrigger={handlers.handleOnAfterTrigger}
              />
            ]
          : []
    };
  }, [id, record, object, site, triggers, handlers.handleOnAfterTrigger, t]);
  useSubheader(subheaderOptions);

  return (
    <>
      <Content loading={!record && loading} flexGrow={false}>
        {record && (
          <ProgressSteps
            documentationRecordType={record.type}
            documentationRecordState={record.state}
            autoRequestThreeD={site?.autoRequestThreeD || false}
          />
        )}
      </Content>
      <Content loading={!record && loading}>
        {record && (
          <>
            {/**
             * Actions
             */}
            <section className="flex flex-wrap md:flex-nowrap justify-between">
              <Button size={"large"} onClick={handlers.handleClickBack} icon={<ArrowLeftOutlined />}>
                {t(lg.documentationRecord.pageDetail.actions.buttonBackText)}
              </Button>

              <div className="mt-5 md:mt-0 md:ml-5 space-y-2">
                <Actions.ValidationMessages triggers={data.triggers} />
                <div className="flex flex-wrap items-end justify-end gap-2">
                  {data.updateAllowed && canEditDocumentationRecord && (
                    <Button
                      size={"large"}
                      onClick={handlers.handleClickEdit}
                      icon={<EditOutlined />}
                      disabled={data.updateDisabled}
                    >
                      {t(lg.documentationRecord.pageDetail.actions.buttonEditText)}
                    </Button>
                  )}
                  {data.taskOperator && (
                    <Button size={"large"} type={"primary"} onClick={handlers.handleClickTaskOperator}>
                      {t(lg.documentationRecord.pageDetail.actions.buttonTaskOperatorText)}
                    </Button>
                  )}
                </div>
              </div>
            </section>

            <RecordTypeInfo type={record.type} className="mt-10" />

            {/**
             * Base information
             */}
            <Section title={t(lg.documentationRecord.pageDetail.baseInformation.title)} className="mt-16">
              <div className="mt-5 md:mt-10">
                <GeneralInfo
                  description={record.description || ""}
                  phase={record.constructionPhase}
                  state={record.state}
                  accountableManager={record.accountableManager}
                  accountableOperator={record.accountableOperator}
                  accountableSurveyor={record.accountableSurveyor}
                  hasAccountableSurveyor={record.type === DocumentationRecordType.ThreeDScale}
                />
              </div>
            </Section>

            {hasVideo && <VideoTypeInfo type={record.documentationRecordVideo?.type || null} className="mt-16" />}

            {hasGroundControlPoints && (
              <GroundControlPointsOverview
                constructionSiteCoords={arrayToCoords(site?.location || [])}
                constructionObjectCoords={arrayToCoords(object?.location || [])}
                simpleGroundControlPointCount={data.simpleGroundControlPointCount}
                simpleGroundControlPoint={simpleGroundControlPoint}
                groundControlPoints={groundControlPoints}
                hasDownloadAndPrint={false}
                className="mt-16"
              />
            )}

            {hasVideo && (
              <VideoUploadOverview video={video} captureLocation={recordVideo?.captureLocation} className="mt-16" />
            )}

            {hasGeodeticSurvey && (
              <GeodeticSurveyUploadOverview
                geodeticSurvey={geodeticSurvey}
                geodeticSurveyAdditional={geodeticSurveyAdditional}
                className="mt-16"
              />
            )}

            {/**
             * Photo documentation
             */}
            <Section title={t(lg.documentationRecord.pageDetail.photos.title)} className="mt-16">
              <div className="mt-10">
                <PhotoDocumentationList
                  items={data.constructionElements.map(({ id, description, photo }) => ({
                    id: id,
                    description: description || "",
                    url: photo.url || "",
                    fileName: photo.fileName || ""
                  }))}
                />
              </div>
            </Section>

            {hasModel && (
              <ThreeDModel
                isFinished={record.state === DocumentationRecordState.Finished}
                type={getThreeDModelType(record)}
                model={threeDModel || undefined}
                className="mt-16"
                onDeleteSuccess={refetch}
              />
            )}

            {hasOutputExtensions && (
              <OutputExtensions
                documentationRecordId={id}
                documentationRecordState={record.state}
                className="mt-16"
                onDeleteSuccess={refetch}
              />
            )}

            <Comments
              items={data.comments}
              accountableUsers={{
                managerId: record?.accountableManager?.id,
                operatorId: record?.accountableOperator?.id,
                surveyorId: record?.accountableSurveyor?.id,
                threeDOperatorId: record?.accountableThreeDOperator?.id
              }}
              className="mt-16"
            />

            <Details
              createdAt={data.firstVersion?.createdAt}
              author={data.firstVersion?.user || undefined}
              lastEditedAt={data.currentVersion?.createdAt}
              lastEditedBy={data.currentVersion?.user || undefined}
              className="mt-16"
            />

            {/**
             * Actions
             */}
            {hasDeleteAction && canDeleteDocumentationRecord && (
              <section className="flex justify-end mt-16">
                <Delete
                  id={record.id}
                  name={record.name}
                  accountableManagerId={accountableManager?.id || ""}
                  threeDModel={record.threeDModel}
                  onSuccess={handlers.handleDeleteSuccess}
                >
                  <Button icon={<DeleteOutlined />} danger type="primary" size="large">
                    {t(lg.documentationRecord.pageDetail.actions2.deleteButtonText)}
                  </Button>
                </Delete>
              </section>
            )}
          </>
        )}
      </Content>
      {hasActionsSection && (
        <Actions.ActionsSection
          documentationRecordId={id}
          triggers={data.triggers}
          onAfterTrigger={handlers.handleOnAfterTrigger}
        />
      )}
    </>
  );
};
