import { ComponentPropsWithoutRef, PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { Section } from "components/Section";
import { MediaUploadSuccessHandler, useAlert, useConfig, useFormat, useMedia } from "hooks";
import { DocumentationRecordVideoType, resolveError, useDocumentationRecordUpdateMutation } from "api";
import { lg } from "assets/translations";
import { ButtonUpload } from "components/ButtonUpload";
import { VideoMediaCard } from "components/Task/VideoMediaCard";

export type Video = {
  id: string;
  url: string;
  fileName: string;
};

type Props = {
  organizationId: string;
  documentationRecordId: string;
  type: DocumentationRecordVideoType | null; // null = Type is not selected yet, upload is disabled
  video?: Video;
  captureLocation?: ComponentPropsWithoutRef<typeof VideoMediaCard>["captureLocation"];
  hasGroundControlPoints?: boolean;
  className?: string;
};

export const VideoUpload = ({
  organizationId,
  documentationRecordId,
  type,
  video,
  captureLocation,
  hasGroundControlPoints,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { config } = useConfig();
  const { allowedFileTypesToString, allowedFileTypesToReadableString } = useFormat();

  const allowedFileTypes = useMemo(() => config.allowedVideoFileTypes, [config.allowedVideoFileTypes]);
  const uploadAccept = useMemo(
    () => allowedFileTypesToString(allowedFileTypes),
    [allowedFileTypesToString, allowedFileTypes]
  );

  const [updateDocumentationRecord, { loading: updating }] = useDocumentationRecordUpdateMutation();
  const [removeDocumentationRecord, { loading: removing }] = useDocumentationRecordUpdateMutation();

  const handleMediaUploadSuccess = useCallback<MediaUploadSuccessHandler>(
    async media => {
      try {
        await updateDocumentationRecord({
          variables: {
            input: {
              documentationRecord: documentationRecordId,
              documentationRecordVideo: {
                video: media.id,
                type: type
              }
            }
          }
        });
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [documentationRecordId, type, updateDocumentationRecord, showAlert]
  );

  const { upload, uploading, uploadedPercents } = useMedia(organizationId, handleMediaUploadSuccess);
  const inUploadingProcess = useMemo(() => uploading || updating, [uploading, updating]);

  const disabledUpload = useMemo(
    () => inUploadingProcess || removing || type === null || !!video,
    [inUploadingProcess, removing, type, video]
  );

  const handleBeforeUpload = useCallback(
    async (file: RcFile): Promise<void> => upload(file, allowedFileTypes),
    [upload, allowedFileTypes]
  );

  const handleRemove = useCallback(async () => {
    if (removing) return;

    try {
      await removeDocumentationRecord({
        variables: {
          input: {
            documentationRecord: documentationRecordId,
            documentationRecordVideo: {
              video: null
            }
          }
        }
      });
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [removing, documentationRecordId, removeDocumentationRecord, showAlert]);

  return (
    <Section title={t(lg.taskOperator.videoUpload.title)} className={className}>
      <div className="c-grid mt-10">
        <div className="c-grid-column">
          <div>
            {t(
              hasGroundControlPoints
                ? lg.taskOperator.videoUpload.descriptionPoints
                : lg.taskOperator.videoUpload.description,
              { allowed: allowedFileTypesToReadableString(allowedFileTypes) }
            )}
          </div>
        </div>
      </div>

      <div className="c-grid mt-10">
        {!!video && (
          <div key={video.id} className="c-grid-column">
            <VideoMediaCard
              captureLocation={captureLocation}
              fileName={video.fileName}
              fileUrl={video.url}
              fileId={video.id}
              onDelete={handleRemove}
            />
          </div>
        )}
      </div>

      <Upload
        type={"select"}
        fileList={[]}
        accept={uploadAccept}
        beforeUpload={handleBeforeUpload}
        disabled={disabledUpload}
      >
        <ButtonUpload
          size="large"
          className="mt-5"
          disabled={disabledUpload}
          progress={uploadedPercents}
          uploading={inUploadingProcess}
        >
          {t(lg.uploadButton.description.video)}
        </ButtonUpload>
        {type === null && (
          <div className="mt-2 text-xs text-red-600">
            {t(lg.taskOperator.videoUpload.errorNoType, { type: t(lg.type.documentationRecordVideo.default) })}
          </div>
        )}
      </Upload>
    </Section>
  );
};
