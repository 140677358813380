import { FunctionComponent, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { lg } from "assets/translations/lg";
import { Section } from "components/Section";
import { useConfig, useFormat } from "hooks";
import { Illustration } from "components/Illustration";

export const Support: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { formatPhoneNumber } = useFormat();
  const { loading, config } = useConfig();

  const subheaderOptions = useMemo<SubheaderOptions>(() => ({ heading: t(lg.support.header.title) }), [t]);
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      <Section title={t(lg.support.contacts.title)} className="flex flex-col min-h-full">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <span className="block font-medium">{t(lg.support.contacts.phone.label)}</span>
            {config.phoneContact ? (
              <a href={`tel:${config.phoneContact}`} className="c-link">
                {formatPhoneNumber(config.phoneContact)}
              </a>
            ) : (
              "-"
            )}
          </div>
          <div className="c-grid-column">
            <span className="block font-medium">{t(lg.support.contacts.email.label)}</span>
            {config.emailContact ? (
              <a href={`mailto:${config.emailContact}`} className="c-link">
                {config.emailContact}
              </a>
            ) : (
              "-"
            )}
          </div>
        </div>

        <div className="flex-grow flex flex-col w-full p-5 md:p-10 lg:p-20" style={{ minHeight: 300 }}>
          {/* h-0 trick don't work at Safari */}
          <Illustration type="ill-support" className="flex-grow h-0" title={t(lg.support.contacts.title)} />
        </div>
      </Section>
    </Content>
  );
};
