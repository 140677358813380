import { ComponentPropsWithRef, useCallback, useEffect, useState } from "react";
import { Form, Input } from "antd";
import { useConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery } from "api";

/*
---------------------------------------------------------------------

---------------------------------------------------------------------
 */
const FORM_ITEM_VALUE = { assign: "assign", create: "create" } as const;
const FORM_ITEM_NAME = { site: "site", object: "object", phase: "phase" } as const;

type FormValue = keyof typeof FORM_ITEM_VALUE;

type FormData = {
  [FORM_ITEM_NAME.site]: FormValue;
  [FORM_ITEM_NAME.object]: FormValue;
  [FORM_ITEM_NAME.phase]: FormValue;
};

/*
---------------------------------------------------------------------
useFieldset (helper)
---------------------------------------------------------------------
 */
const useFieldset = ({
  state,
  initAssignValue,
  initCreateValue
}: {
  state: FormValue;
  initAssignValue?: string;
  initCreateValue?: string;
}) => {
  const [assignValue, setAssignValue] = useState<string | undefined>(initAssignValue);
  const [createValue, setCreateValue] = useState<string | undefined>(initCreateValue);

  const isFilled =
    state === FORM_ITEM_VALUE.assign ? !!assignValue : state === FORM_ITEM_VALUE.create ? !!createValue : false;

  const isAssign = state === FORM_ITEM_VALUE.assign;
  const isCreate = state === FORM_ITEM_VALUE.create;

  return {
    assignValue,
    createValue,
    isFilled,
    isAssign,
    isCreate,
    setAssignValue,
    setCreateValue
  };
};

/*
---------------------------------------------------------------------

---------------------------------------------------------------------
 */
type InputProps = ComponentPropsWithRef<typeof Input>;

const initFormData: FormData = {
  [FORM_ITEM_NAME.site]: FORM_ITEM_VALUE.assign,
  [FORM_ITEM_NAME.object]: FORM_ITEM_VALUE.assign,
  [FORM_ITEM_NAME.phase]: FORM_ITEM_VALUE.assign
};

export const useDocumentationRecordCreateSimplified = ({
  disabled,
  initSiteId,
  userId
}: {
  disabled?: boolean;
  initSiteId?: string;
  userId?: string;
}) => {
  // form handle create/assign states
  const [form] = Form.useForm<FormData>();
  // used to reload page during form changes
  const [formState, setFormState] = useState<FormData>(initFormData);
  const [isPhaseTouched, setIsPhaseTouched] = useState(false);

  // update form state, required for correct form functionality
  const updateFormState = useCallback(() => {
    const updatedFormState = form.getFieldsValue();
    setFormState(updatedFormState);
  }, [form]);

  const handleFormChange = useCallback(() => {
    const { site } = form.getFieldsValue();
    if (site === FORM_ITEM_VALUE.create) {
      // reset fields, Object and Phase can't be created without Construction Site
      form.setFields([{ name: FORM_ITEM_NAME.object, value: FORM_ITEM_VALUE.create }]);
      form.setFields([{ name: FORM_ITEM_NAME.phase, value: FORM_ITEM_VALUE.create }]);
    }

    updateFormState();
  }, [form, updateFormState]);

  /*
   * Selectable fields handlers
   * */
  const handleSiteSelectChange = (id?: string) =>
    setAssignSiteId(prevId => {
      // reset construction object and phase if construction site ID changed
      if (prevId !== id) {
        setAssignObjectId(undefined);
        setAssignPhaseId(undefined);
      }
      return id;
    });
  const handleObjectSelect = (id?: string) => setAssignObjectId(id);
  const handlePhaseSelect = (id?: string) => setAssignPhaseId(id);

  /*
   * Creatable fields handlers
   * */
  const handleSiteInputChange: InputProps["onChange"] = e => setNewSiteName(e.currentTarget.value);
  const handleObjectInputChange: InputProps["onChange"] = e => setNewObjectName(e.currentTarget.value);
  const handlePhaseInputChange: InputProps["onChange"] = e => {
    setIsPhaseTouched(true);
    setNewPhaseName(e.currentTarget.value);
  };
  /* select input value on element focus */
  const handlePhaseInputFocus: InputProps["onFocus"] = e => e.currentTarget.select();

  const {
    assignValue: assignSiteId,
    createValue: newSiteName,
    setAssignValue: setAssignSiteId,
    setCreateValue: setNewSiteName,
    isFilled: isSiteFilled,
    isAssign: isSiteAssign,
    isCreate: isSiteCreate
  } = useFieldset({ state: formState[FORM_ITEM_NAME.site], initAssignValue: initSiteId });
  const {
    assignValue: assignObjectId,
    createValue: newObjectName,
    setAssignValue: setAssignObjectId,
    setCreateValue: setNewObjectName,
    isFilled: isObjectFilled,
    isAssign: isObjectAssign,
    isCreate: isObjectCreate
  } = useFieldset({ state: formState[FORM_ITEM_NAME.object] });
  const {
    assignValue: assignPhaseId,
    createValue: newPhaseName,
    setAssignValue: setAssignPhaseId,
    setCreateValue: setNewPhaseName,
    isFilled: isPhaseFilled,
    isAssign: isPhaseAssign,
    isCreate: isPhaseCreate
  } = useFieldset({ state: formState[FORM_ITEM_NAME.phase] });

  /* get details of the selected Construction Site */
  const { data: assignSiteData } = useConstructionSiteForDocumentationRecordCreateSimplifiedPageQuery({
    variables: { id: assignSiteId || "" },
    skip: !assignSiteId
  });
  const assignSite = assignSiteId ? assignSiteData?.constructionSite : undefined;

  const hasNoPermissionsToCreateObject: boolean = assignSiteId ? assignSite?.accountableManagerId !== userId : false;
  const showNoPermissionsToCreateObjectError = isSiteAssign && hasNoPermissionsToCreateObject;

  const hasNoPermissionsToCreatePhase = hasNoPermissionsToCreateObject;
  const showNoPermissionsToCreatePhaseError = isSiteAssign && isObjectAssign && hasNoPermissionsToCreatePhase;

  // Set Construction Object fieldset to assign state if user hasn't permissions to creat new Construction Object.
  useEffect(() => {
    if (!hasNoPermissionsToCreateObject) return;
    form.setFields([{ name: FORM_ITEM_NAME.object, value: FORM_ITEM_VALUE.assign }]);
    updateFormState();
  }, [hasNoPermissionsToCreateObject, form, updateFormState]);

  // Set Construction Phase fieldset to assign state if user hasn't permissions to creat new Phase.
  useEffect(() => {
    if (!hasNoPermissionsToCreatePhase) return;
    form.setFields([{ name: FORM_ITEM_NAME.phase, value: FORM_ITEM_VALUE.assign }]);
    updateFormState();
  }, [hasNoPermissionsToCreatePhase, form, updateFormState]);

  const isNewPhaseNameEmpty: boolean = !newPhaseName;

  /**
   * Statuses
   */
  const disableSiteAssignOption = disabled;
  const disableObjectAssignOption = !assignSiteId || isSiteCreate || disabled;
  const disablePhaseAssignOption = !assignSiteId || disabled;

  const disableSiteCreateOption = disabled;
  const disableObjectCreateOption = showNoPermissionsToCreateObjectError || disabled;
  const disablePhaseCreateOption = showNoPermissionsToCreatePhaseError || disabled;

  const disableSiteSelect = isSiteCreate || disabled;
  const disabledObjectSelect = isObjectCreate || !assignSiteId || disabled;
  const disablePhaseSelect = isPhaseCreate || !assignSiteId || disabled;

  const disableSiteInput = isSiteAssign || disabled;
  const disabledObjectInput = isObjectAssign || showNoPermissionsToCreateObjectError || disabled;
  const disablePhaseInput = isPhaseAssign || showNoPermissionsToCreatePhaseError || disabled;

  return {
    form,
    initFormData,
    FORM_ITEM_VALUE,
    FORM_ITEM_NAME,
    handleFormChange,
    states: {
      assignSiteId,
      assignObjectId,
      assignPhaseId,

      newSiteName,
      newObjectName,
      newPhaseName,

      isSiteFilled,
      isSiteAssign,
      isSiteCreate,

      isObjectFilled,
      isObjectAssign,
      isObjectCreate,

      isPhaseFilled,
      isPhaseAssign,
      isPhaseCreate,

      disableSiteAssignOption,
      disableObjectAssignOption,
      disablePhaseAssignOption,

      disableSiteCreateOption,
      disableObjectCreateOption,
      disablePhaseCreateOption,

      isNewPhaseNameEmpty,
      isPhaseTouched,
      showNoPermissionsToCreateObjectError,
      showNoPermissionsToCreatePhaseError
    },
    setNewPhaseName,
    selectSiteProps: {
      value: assignSiteId,
      disabled: disableSiteSelect,
      onChange: handleSiteSelectChange
    },
    inputSiteProps: {
      value: newSiteName,
      disabled: disableSiteInput,
      onChange: handleSiteInputChange
    },
    selectObjectProps: {
      value: assignObjectId,
      disabled: disabledObjectSelect,
      onChange: handleObjectSelect
    },
    inputObjectProps: {
      value: newObjectName,
      disabled: disabledObjectInput,
      onChange: handleObjectInputChange
    },
    selectPhaseProps: {
      value: assignPhaseId,
      disabled: disablePhaseSelect,
      onChange: handlePhaseSelect
    },
    inputPhaseProps: {
      value: newPhaseName,
      disabled: disablePhaseInput,
      onChange: handlePhaseInputChange,
      onFocus: handlePhaseInputFocus
    }
  };
};
