import { Language, resolveError, useSetLanguageMutation } from "api";
import { useCallback } from "react";
import { useAlert } from "hooks/useAlert";

export const useSetLanguage = () => {
  const { showAlert } = useAlert();
  const [mutation, { loading }] = useSetLanguageMutation({ onError: e => resolveError(e, {}, showAlert) });

  const setUserLanguage = useCallback(
    async (language: Language) => {
      try {
        await mutation({
          variables: {
            input: {
              language
            }
          }
        });
      } catch (e) {
        console.log("SetLanguage error", language, "error:", e);
      }
    },
    [mutation]
  );

  return {
    loading,
    setUserLanguage
  };
};
