import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { Section } from "components/Section";
import { MediaCard } from "components/MediaCard";
import { MediaUploadSuccessHandler, useAlert, useConfig, useFormat, useMedia } from "hooks";
import { resolveError, useDocumentationRecordUpdateMutation } from "api";
import { lg } from "assets/translations";
import { ButtonUpload } from "components/ButtonUpload";

export type GeodeticSurvey = {
  id: string;
  url: string;
  fileName: string;
};

type Props = {
  organizationId: string;
  documentationRecordId: string;
  geodeticSurvey?: GeodeticSurvey;
  className?: string;
  disabled?: boolean;
};

export const GeodeticSurveyUpload = ({
  organizationId,
  documentationRecordId,
  geodeticSurvey,
  className,
  disabled = false
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { allowedFileTypesToString, allowedFileTypesToReadableString } = useFormat();
  const { showAlert } = useAlert();
  const { config } = useConfig();

  const [updateDocumentationRecord, { loading: updating }] = useDocumentationRecordUpdateMutation();
  const [removeDocumentationRecord, { loading: removing }] = useDocumentationRecordUpdateMutation();

  const allowedFileTypes = useMemo(
    () => config.allowedGeodeticSurveyFileTypes,
    [config.allowedGeodeticSurveyFileTypes]
  );
  const uploadAccept = useMemo(
    () => allowedFileTypesToString(allowedFileTypes),
    [allowedFileTypes, allowedFileTypesToString]
  );

  const handleMediaUploadSuccess = useCallback<MediaUploadSuccessHandler>(
    async media => {
      try {
        await updateDocumentationRecord({
          variables: {
            input: {
              documentationRecord: documentationRecordId,
              geodeticSurvey: { txtSurvey: media.id }
            }
          }
        });
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [documentationRecordId, updateDocumentationRecord, showAlert]
  );

  const { upload, uploading, uploadedPercents } = useMedia(organizationId, handleMediaUploadSuccess);
  const inUploadingProcess = useMemo(() => uploading || updating, [uploading, updating]);

  const _disabled = useMemo(() => disabled || inUploadingProcess || removing, [disabled, inUploadingProcess, removing]);
  const disabledUpload = useMemo(() => _disabled || !!geodeticSurvey, [_disabled, geodeticSurvey]);

  const handleBeforeUpload = useCallback(
    async (file: RcFile): Promise<void> => upload(file, allowedFileTypes),
    [upload, allowedFileTypes]
  );

  const handleRemove = useCallback(async () => {
    try {
      await removeDocumentationRecord({
        variables: {
          input: {
            documentationRecord: documentationRecordId,
            geodeticSurvey: { txtSurvey: null }
          }
        }
      });
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [documentationRecordId, removeDocumentationRecord, showAlert]);

  return (
    <Section title={t(lg.taskSurveyor.geodeticSurveyUpload.title)} className={className}>
      <div className="c-grid mt-10">
        <div className="c-grid-column whitespace-pre-line">
          <div>
            {t(lg.taskSurveyor.geodeticSurveyUpload.description, {
              allowed: allowedFileTypesToReadableString(allowedFileTypes)
            })}
          </div>
        </div>
      </div>

      <div className="c-grid mt-5">
        <div className="c-grid-column">
          <Upload
            type={"select"}
            fileList={[]}
            accept={uploadAccept}
            beforeUpload={handleBeforeUpload}
            disabled={disabledUpload}
          >
            <ButtonUpload
              size="large"
              disabled={disabledUpload}
              progress={uploadedPercents}
              uploading={inUploadingProcess}
            >
              {t(lg.uploadButton.description.file)}
            </ButtonUpload>
          </Upload>
        </div>
        <div className="c-grid-column">
          {!!geodeticSurvey && (
            <div key={geodeticSurvey.id} className="c-grid-column">
              <MediaCard
                fileName={geodeticSurvey.fileName}
                fileUrl={geodeticSurvey.url}
                fileId={geodeticSurvey.id}
                onDelete={_disabled ? undefined : handleRemove}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};
