import { PropsWithChildren, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { ActionProps } from "components/DocumentationRecord/Actions/index";
import {
  ChangeNoScaleHandler,
  ModalChangeNoScale
} from "components/DocumentationRecord/Actions/modals/ModalChangeNoScale";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import { Triggers } from "api";
import { lg } from "assets/translations";
import { documentationRecordTriggers } from "api/enums";

/** For Trigger CHANGE_NO_SCALE */
export const ChangeNoScale = ({
  documentationRecordId,
  triggers,
  disabled = false,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {}
}: PropsWithChildren<ActionProps>) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.ChangeNoScale,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    onAfterTrigger
  );

  const handleClick = useCallback(() => setModalVisible(true), []);
  const handleCancel = useCallback(() => setModalVisible(false), []);
  const handleSubmit = useCallback<ChangeNoScaleHandler>(() => callTrigger(), [callTrigger]);

  if (!available) return null;

  return (
    <>
      <Button type="default" onClick={handleClick} size="large" disabled={disabled || !conditions || loading}>
        {t(!loading ? documentationRecordTriggers[Triggers.ChangeNoScale].buttonI18nKey : lg.saving)}
        {!loading ? <ArrowRightOutlined /> : <LoadingOutlined />}
      </Button>
      <ModalChangeNoScale visible={modalVisible} loading={loading} onSubmit={handleSubmit} onCancel={handleCancel} />
    </>
  );
};
