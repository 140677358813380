import { PropsWithChildren } from "react";

type Props = {
  title: string;
  className?: string;
};

export const Section = ({ title, className, children }: PropsWithChildren<Props>) => (
  <div className={className}>
    <h2 className="text-blue-500 text-xl font-medium print_text-black print_text-base">{title}</h2>
    <hr className="border-gray-400 mt-1 print_hidden" />

    {children}
  </div>
);
