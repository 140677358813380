import { cloneElement, PropsWithChildren, ReactElement, useCallback, useMemo } from "react";
import { Form, Modal, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { ParseMD } from "helpers/ParseMD";
import { RemoveUserFormFields, useOrganizationRemoveUser } from "hooks/useOrganizationRemoveUser";
import { OrganizationUser } from "hooks";
import { getUserFullName, isReader } from "helpers";

type Props = {
  children: ReactElement;
  user: OrganizationUser;
  organizationId: string;
};

export const OrganizationRemoveUser = ({ user, children, organizationId }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const {
    loading,
    canSend,
    visible,
    form,
    availableAccountableManagers,

    open,
    removeUser,

    handleOk,
    handleCancel,
    handleChange,
    handleAfterClose
  } = useOrganizationRemoveUser(user.membershipId, organizationId);

  const canForceRemove = useMemo(() => isReader(user), [user]);

  const handleClickChildren = useCallback(async () => {
    if (!canForceRemove) return open();

    return await removeUser({
      accountableUser: "",
      membership: user.membershipId || ""
    });
  }, [canForceRemove, open, removeUser, user.membershipId]);

  return (
    <>
      {cloneElement(children, { onClick: handleClickChildren })}

      <Modal
        title={
          <div className="flex items-center">
            <CloseCircleOutlined className="text-lg text-red-600" />
            <span className="ml-4">{t(lg.organization.modals.removeUser.title)}</span>
          </div>
        }
        open={visible}
        okText={t(lg.organization.modals.removeUser.buttonOkText)}
        cancelText={t(lg.organization.modals.removeUser.buttonCancelText)}
        okButtonProps={{ danger: true, disabled: !canSend || loading }}
        cancelButtonProps={{ disabled: loading }}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        confirmLoading={loading}
        afterClose={handleAfterClose}
        destroyOnClose
      >
        <ParseMD>{t(lg.organization.modals.removeUser.description, { user: getUserFullName(user) })}</ParseMD>

        <Form form={form} onFieldsChange={handleChange} className="mt-4">
          <Form.Item name={RemoveUserFormFields.accountableUser}>
            <Radio.Group>
              {availableAccountableManagers?.map((user, index) => (
                <Radio key={user.id} value={user.id} className={clsx("block", index > 0 ? "mt-3" : "")}>
                  {user.firstName} {user.lastName}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </Form>

        <div className="flex items-center mt-2">
          <InfoCircleOutlined className="text-red-600 text-lg" />
          <span className="font-medium ml-2">{t(lg.organization.modals.removeUser.warning)}</span>
        </div>
      </Modal>
    </>
  );
};
