import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { message } from "antd";
import { useDocumentationRecord, useMe } from "hooks";
import { DocumentationRecordState, Triggers } from "api";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations";
import { AfterTriggerHandler } from "components/DocumentationRecord/Actions";
import { documentationRecordTriggers } from "api/enums";

export const useDocumentationRecordDetail = (id: string) => {
  const { t } = useTranslation();
  const { me, refetch: refetchMe } = useMe();

  /** DATA */
  const { data, loading, refetch } = useDocumentationRecord(id);

  const { record, object, site } = data;

  const updateAllowed = useMemo(() => record?.state !== DocumentationRecordState.Finished || false, [record]);

  const updateDisabled = useMemo(
    () =>
      record?.state === DocumentationRecordState.Archived ||
      record?.state === DocumentationRecordState.Rejected ||
      false,
    [record]
  );

  const taskOperator = useMemo(
    () => record?.state === DocumentationRecordState.RequiresOperator && record?.accountableOperator?.id === me.id,
    [record, me.id]
  );

  /** STATE */

  /** HANDLERS */
  const handleClickBack = useCallback(async () => {
    if (!site || !object) return;

    await navigate(
      setRouteParams(routes.constructionObjectDetail, {
        constructionSiteId: site.id,
        id: object.id
      })
    );
  }, [site, object]);

  const handleClickEdit = useCallback(() => navigate(setRouteParams(routes.documentationRecordEdit, { id })), [id]);

  const handleClickTaskOperator = useCallback(() => navigate(setRouteParams(routes.taskOperator, { id })), [id]);

  const handleDeleteSuccess = useCallback(async () => {
    message.success(t(lg.documentationRecord.pageDetail.messages.delete), 5);

    if (!site || !object) return;

    await navigate(
      setRouteParams(routes.constructionObjectDetail, {
        constructionSiteId: site.id,
        id: object.id
      })
    );
  }, [site, object, t]);

  const handleOnAfterTrigger = useCallback<AfterTriggerHandler>(
    async trigger => {
      // TODO what about Archive,Submit and Back triggers? - https://hrdlicka.atlassian.net/browse/HRD003-483

      if (trigger === Triggers.SubmitThreeDOperator || trigger === Triggers.ChangeToThreeD) {
        await refetchMe();
      }

      message.success(t(documentationRecordTriggers[trigger].messageSuccessI18nKey), 5);
    },
    [refetchMe, t]
  );

  return {
    loading,
    refetch,
    data: {
      ...data,
      updateAllowed,
      updateDisabled,
      taskOperator
    },
    handlers: {
      handleClickBack,
      handleClickEdit,
      handleClickTaskOperator,
      handleDeleteSuccess,
      handleOnAfterTrigger
    }
  };
};
