import { useCallback, useMemo } from "react";
import { resolveError, useConstructionPhaseDeleteMutation } from "api";
import { useAlert } from "hooks";

export const useConstructionPhaseDelete = (id: string = "", onSuccess?: () => void, onClose?: () => void) => {
  const { showAlert } = useAlert();

  const [deleteMutation, { loading: sending, data }] = useConstructionPhaseDeleteMutation({
    variables: { input: { constructionPhase: id } },
    onError: e => resolveError(e, {}, showAlert),
    onCompleted: data => {
      const wasSuccessfullyDeleted: boolean = !!data?.constructionPhase?.delete?.archived;
      wasSuccessfullyDeleted && onClose && onClose();
    }
  });

  /** DATA */
  const deleteData = useMemo(() => data?.constructionPhase?.delete, [data]);
  const failedRecords = useMemo(() => deleteData?.failedRecords.flatMap(item => (item ? [item] : [])), [deleteData]);

  const hasFailedRecord = useMemo(() => failedRecords && failedRecords.length > 0, [failedRecords]);
  const wasSuccessfullyDeleted = useMemo(() => deleteData?.archived, [deleteData]);

  /* HANDLERS */
  const handleClickOk = useCallback(async () => await deleteMutation(), [deleteMutation]);

  // call onSuccess after close modal
  const handleAfterClose = useCallback(
    () => wasSuccessfullyDeleted && onSuccess && onSuccess(),
    [wasSuccessfullyDeleted, onSuccess]
  );

  return {
    data: {
      sending,
      failedRecords,

      hasFailedRecord
    },
    handlers: {
      handleClickOk,
      handleAfterClose
    }
  };
};
