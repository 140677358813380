import { lg } from "assets/translations";
import { HTMLAttributes } from "react";
import clsx from "clsx";
import { getEnv } from "helpers";
import { useTranslation } from "react-i18next";

const registrationUrl = getEnv("REACT_APP_REGISTRATION_URL");
export const RegisterAppendix = ({ className, children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <div {...rest} className={clsx("text-purple-200 text-center md:text-left", className)}>
      {t(lg.login.descriptionText)}

      <a href={registrationUrl} target="_blank" rel="noopener noreferrer" className="block c-link-light">
        {t(lg.login.descriptionLinkText)}
      </a>

      {children}
    </div>
  );
};
