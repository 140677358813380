import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { message } from "antd";
import { AfterTriggerHandler } from "components/DocumentationRecord/Actions";
import { useCatalog, useDocumentationRecord, useMe } from "hooks";
import { getCost } from "helpers";
import { routes } from "routes";
import { Triggers } from "api";
import { documentationRecordTriggers } from "api/enums";

export const useTask = (id: string) => {
  const { t } = useTranslation();
  const { refetch: refetchMe } = useMe();
  const { catalog } = useCatalog();

  /** DATA */
  const { data, loading, refetch } = useDocumentationRecord(id);
  const cost = useMemo(() => getCost(data.record?.documentationRecordVideo?.type || null, catalog), [data, catalog]);

  /** STATE */

  /** HANDLERS */
  const handleOnAfterTrigger = useCallback<AfterTriggerHandler>(
    async trigger => {
      // TODO what about Archive,Submit and Back triggers? - https://hrdlicka.atlassian.net/browse/HRD003-483

      if (trigger === Triggers.SubmitThreeDOperator) {
        await refetchMe();
      }

      message.success(t(documentationRecordTriggers[trigger].messageSuccessI18nKey), 5);
      await navigate(routes.todo);
    },
    [refetchMe, t]
  );

  return {
    loading,
    refetch,
    data: { ...data, cost },
    handlers: {
      handleOnAfterTrigger
    }
  };
};
