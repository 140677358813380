import { ComponentPropsWithoutRef, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Input, InputNumber, message } from "antd";
import { Photos } from "components/Task/GroundControlPoints/Photos";
import { SimpleGroundControlPointFragment, useSetSimplifiedGroundControlPointMutation } from "api";
import { CheckOutlined } from "@ant-design/icons";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";

export * from "./GroundControlPointsOverview";

type Props = {
  organizationId: string;
  simpleGroundControlPointCount?: number;
  simpleGroundControlPoint?: SimpleGroundControlPointFragment;
  documentationRecordId: string;
  minimalPoints: number;
  onCancel: () => void;
  className?: string;
};

type FormValues = {
  countOfPoints?: number;
  description?: string;
};

const FORM_ID = "AddSimpleGroundControlPointsForm";

export const AddSimpleGroundControlPoints = ({
  organizationId,
  documentationRecordId,
  simpleGroundControlPointCount,
  simpleGroundControlPoint,
  minimalPoints,
  onCancel,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [mutate, { loading: saving }] = useSetSimplifiedGroundControlPointMutation({
    onError: () => message.error(t(lg.taskOperator.simplifiedGroundControlPoint.form.messages.submitError)),
    onCompleted: () => message.success(t(lg.taskOperator.simplifiedGroundControlPoint.form.messages.submitSuccess))
  });
  const [photos, setPhotos] = useState<string[]>([]);

  const [form] = Form.useForm<FormValues>();
  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  const initialValues = useMemo<FormValues>(
    () => ({
      countOfPoints: simpleGroundControlPointCount,
      description: simpleGroundControlPoint?.description || undefined
    }),
    [simpleGroundControlPoint?.description, simpleGroundControlPointCount]
  );

  const savedPhotos = useMemo(
    () => simpleGroundControlPoint?.files.edges.flatMap(({ node }) => (node ? [node.id] : [])) || [],
    [simpleGroundControlPoint?.files.edges]
  );

  /**
   * handle changes of the Point photos
   */
  useEffect(() => {
    setPhotos(prevPhotos => [...new Set([...prevPhotos, ...savedPhotos])]);
  }, [savedPhotos]);

  const disableCancel = saving || !!simpleGroundControlPoint;
  const disableSubmit = saving || !formIsValid;

  const handleAddPhoto = useCallback<ComponentPropsWithoutRef<typeof Photos>["onAdd"]>(
    id => setPhotos(prevState => [...prevState, id]),
    []
  );
  const handleRemovePhoto = useCallback<ComponentPropsWithoutRef<typeof Photos>["onRemove"]>(
    id => setPhotos(prevState => prevState.filter(_id => _id !== id)),
    []
  );

  const handleFormFinish = useCallback(
    async (values: FormValues) => {
      const { countOfPoints, description } = values;
      await mutate({
        variables: {
          input: {
            documentationRecord: documentationRecordId,
            groundControlPointCount: countOfPoints,
            description,
            files: photos
          }
        }
      });
    },
    [photos, documentationRecordId, mutate]
  );

  const validateForm = () => {
    const hasError = !!form.getFieldsError().filter(({ errors }) => errors.length).length;
    setFormIsValid(!hasError);
  };

  return (
    <div className={className}>
      <div className="text-blue-700 text-base font-medium mt-10">
        {t(lg.taskOperator.simplifiedGroundControlPoint.form.title)}
      </div>
      <p>{t(lg.taskOperator.simplifiedGroundControlPoint.form.description)}</p>

      <div className={"c-grid mt-5"}>
        <div className={"c-grid-column"}>
          <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={handleFormFinish}
            onFieldsChange={validateForm}
            id={FORM_ID}
          >
            <Form.Item
              shouldUpdate
              name={"countOfPoints"}
              rules={[
                {
                  type: "number",
                  min: minimalPoints,
                  required: true,
                  message: t(lg.taskOperator.simplifiedGroundControlPoint.form.fields.countOfPoints.validation, {
                    min: minimalPoints
                  })
                }
              ]}
              label={t(lg.taskOperator.simplifiedGroundControlPoint.form.fields.countOfPoints.label, {
                min: minimalPoints
              })}
            >
              <InputNumber size={"large"} placeholder={`${minimalPoints}`} className="w-full" />
            </Form.Item>

            <Form.Item
              name={"description"}
              label={t(lg.taskOperator.simplifiedGroundControlPoint.form.fields.description.label)}
              required={false}
            >
              <Input.TextArea
                size={"large"}
                rows={3}
                placeholder={t(lg.taskOperator.simplifiedGroundControlPoint.form.fields.description.placeholder)}
              />
            </Form.Item>
          </Form>
        </div>
        <div className={"c-grid-column"}>
          <Photos organizationId={organizationId} photos={photos} onAdd={handleAddPhoto} onRemove={handleRemovePhoto} />
        </div>
      </div>

      <div className="flex justify-end mt-15">
        <Button size="large" type="ghost" disabled={disableCancel} className="mr-5" onClick={onCancel}>
          {t(lg.taskOperator.simplifiedGroundControlPoint.form.buttonCancel)}
        </Button>
        <Button
          icon={<CheckOutlined />}
          size="large"
          type="primary"
          form={FORM_ID}
          htmlType="submit"
          disabled={disableSubmit}
          loading={saving}
        >
          {t(lg.taskOperator.simplifiedGroundControlPoint.form.buttonSave)}
        </Button>
      </div>
    </div>
  );
};
