import { RouteComponentProps } from "@reach/router";
import { LayoutSimple } from "components/LayoutSimple";
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  MailOutlined,
  PhoneOutlined,
  ShoppingCartOutlined
} from "@ant-design/icons";
import { Illustration } from "components/Illustration";
import { useTranslation } from "react-i18next";
import { Language, lg } from "assets/translations";
import Glider from "react-glider";
import "glider-js/glider.min.css";
import { useConfig } from "hooks";
import clsx from "clsx";
import { classes } from "components/Homepage/classes";
import { CarouselItem } from "components/Homepage/CarouselItem";
import { ContactCard } from "components/Homepage/ContactCard";
import { HowItWorksCard } from "components/Homepage/HowItWorksCard";
import { DiagramSection } from "components/Homepage/DiagramSection";
import { ComponentProps, useEffect } from "react";

type GliderProps = ComponentProps<typeof Glider>;

export const HOMEPAGE_ANCHOR_ID = {
  WHY: "why",
  HOW_IT_WORKS: "how-it-works",
  BUY: "buy"
};

const gliderConfig: Omit<GliderProps, "children"> = {
  slidesToShow: 1,
  scrollLock: true,
  className: "c-glider c-glider--homepage",
  hasArrows: false,
  responsive: [
    {
      // screens greater than >= 640px (sm breakpoint specified at the tailwind.config.js
      breakpoint: 640,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1
      }
    }
  ],
  hasDots: true,
  draggable: false,

  /*
   * Smooth scroll enabled by css is not working without disabled "resizeLock".
   * There's some issue between reach-router and glider-js.
   * */
  resizeLock: false
};

export const Homepage = (props: RouteComponentProps) => {
  const { t, i18n } = useTranslation();
  const { config, loading } = useConfig();

  const hash = props.location?.hash || "";

  const canShowEshopCard = i18n.language === Language.Cs;
  const canShowXingCard = i18n.language === Language.De;

  useEffect(() => {
    const id = hash.replace("#", "");
    const element = document.getElementById(id);
    if (!element) return;
    element.scrollIntoView();
  }, [hash]);

  return (
    <LayoutSimple
      childLeft={
        <Illustration
          type={"ill-04"}
          className="md:ml-auto max-w-full"
          style={{
            width: "27.5rem" // 44Opx
          }}
        />
      }
      childRight={
        <div className="md:py-20">
          <h1 className={clsx(classes.h1, "text-purple-200 mt-10")}>{t(lg.homepage.welcomeBlock.title)}</h1>
          <div className="text-base text-purple-200 mt-5">{t(lg.homepage.welcomeBlock.description)}</div>
          <a
            href={`#${HOMEPAGE_ANCHOR_ID.WHY}`}
            className="group text-purple-600 hover:text-purple-200 flex items-center mt-10 transition-all"
          >
            <div className="flex items-center justify-center rounded-full border border-purple-600 group-hover:border-purple-200 w-6 h-6 p-1.5">
              <ArrowDownOutlined />
            </div>
            <span className="ml-2">{t(lg.homepage.welcomeBlock.buttonMore)}</span>
          </a>
        </div>
      }
      data-cy={"page-homepage"}
    >
      <div id={HOMEPAGE_ANCHOR_ID.WHY} className="relative pt-10 pb-20 md:py-20">
        <div className="bg-blue-1000 absolute bottom-0 left-0 right-0 top-1/2 hidden md:block" aria-hidden />

        <div className="c-layout-simple-row relative">
          <div className="bg-white rounded-md p-7 md:p-20">
            <h2 className={clsx(classes.h2, "text-purple-600 md:text-center mb-0")}>
              {t(lg.homepage.whyMawisPhoto.title)}
            </h2>

            <Glider {...gliderConfig}>
              <CarouselItem
                title={t(lg.homepage.whyMawisPhoto.overviewAndAvailability.title)}
                customIconType={"dashboard"}
                content={t(lg.homepage.whyMawisPhoto.overviewAndAvailability.content)}
              />
              <CarouselItem
                title={t(lg.homepage.whyMawisPhoto.organizationAndControl.title)}
                customIconType={"checklist"}
                content={t(lg.homepage.whyMawisPhoto.organizationAndControl.content)}
              />
              <CarouselItem
                title={t(lg.homepage.whyMawisPhoto.cooperationAndCommunication.title)}
                customIconType={"team"}
                content={t(lg.homepage.whyMawisPhoto.cooperationAndCommunication.content)}
              />
              <CarouselItem
                title={t(lg.homepage.whyMawisPhoto.dataAndAccuracy.title)}
                customIconType={"data"}
                content={t(lg.homepage.whyMawisPhoto.dataAndAccuracy.content)}
              />
              <CarouselItem
                title={t(lg.homepage.whyMawisPhoto.easyOperation.title)}
                customIconType={"device"}
                content={t(lg.homepage.whyMawisPhoto.easyOperation.content)}
              />
              <CarouselItem
                title={t(lg.homepage.whyMawisPhoto.fairPrice.title)}
                customIconType={"web-programming"}
                content={t(lg.homepage.whyMawisPhoto.fairPrice.content)}
              />
            </Glider>
          </div>
        </div>
      </div>

      <div id={HOMEPAGE_ANCHOR_ID.HOW_IT_WORKS} className="bg-blue-1000 pt-10 pb-20 md:pt-20 md:pb-40">
        <div className="c-layout-simple-row">
          <h2 className={clsx(classes.h2, "text-purple-200 md:text-center mb-0")}>{t(lg.homepage.howItWorks.title)}</h2>

          <div className="flex flex-col md:flex-row md:justify-between space-y-15 md:space-y-0 md:space-x-20 px-0 md:px-20 mt-14 md:mt-20">
            <HowItWorksCard
              title={t(lg.homepage.howItWorks.webApp.title)}
              description={t(lg.homepage.howItWorks.webApp.content)}
              customIconType={"web-programming"}
            />
            <HowItWorksCard
              title={t(lg.homepage.howItWorks.mobileApp.title)}
              description={t(lg.homepage.howItWorks.mobileApp.content)}
              customIconType={"smartphone"}
            />
          </div>

          <div className="mt-10 md:mt-20">
            <DiagramSection />
          </div>
        </div>
      </div>

      <div id={HOMEPAGE_ANCHOR_ID.BUY} className="c-layout-simple-row pt-10 pb-20 md:py-40">
        <h2 className={clsx(classes.h2, "text-purple-200 md:text-center mb-0")}>{t(lg.homepage.contact.title)}</h2>

        <div className="flex flex-col md:flex-row md:justify-between space-y-15 md:space-y-0 md:space-x-20 px-0 md:px-20 mt-14 md:mt-20">
          {canShowXingCard ? (
            <ContactCard
              loading={loading}
              title={t(lg.homepage.contact.byXing.title)}
              description={t(lg.homepage.contact.byXing.description)}
              customIconType={"xing"}
              actionProps={{
                href: "https://www.xing.com/pages/hrdlicka-spol-s-r-o",
                children: t(lg.homepage.contact.byXing.linkText),
                icon: <ArrowRightOutlined />,
                iconAlign: "end",
                target: "_blank",
                rel: "noopener noreferrer"
              }}
            />
          ) : null}
          <ContactCard
            loading={loading}
            title={t(lg.homepage.contact.byPhone.title)}
            description={t(lg.homepage.contact.byPhone.description)}
            customIconType={"phone"}
            actionProps={{
              href: `tel:${config.phoneContact}`,
              children: config.phoneContact,
              icon: <PhoneOutlined />
            }}
          />

          <ContactCard
            loading={loading}
            title={t(lg.homepage.contact.byEmail.title)}
            description={t(lg.homepage.contact.byEmail.description)}
            customIconType={"e-mail"}
            actionProps={{
              href: `mailto:${config.emailContact}`,
              children: config.emailContact,
              icon: <MailOutlined />
            }}
          />
          {canShowEshopCard ? (
            <ContactCard
              loading={loading}
              title={t(lg.homepage.contact.byEshop.title)}
              description={t(lg.homepage.contact.byEshop.description)}
              customIconType={"eshop"}
              actionProps={{
                href: config.shopUrl,
                children: t(lg.homepage.contact.byEshop.linkText),
                icon: <ShoppingCartOutlined />,
                target: "_blank",
                rel: "noopener noreferrer"
              }}
            />
          ) : null}
        </div>
      </div>
    </LayoutSimple>
  );
};

export default Homepage;
