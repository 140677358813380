import { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Content } from "components/LayoutDashboard";
import { Section } from "components/Section";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { lg } from "assets/translations/lg";
import { useTranslation } from "react-i18next";
import { pluralize } from "assets/translations";
import { routes, setRouteParams } from "routes";
import { ThreeDModelsTable } from "components/OrganizationResources/ThreeDModelsTable";
import { DocumentationRecordsTable } from "components/OrganizationResources/DocumentationRecordsTable";
import { CustomStorageBar } from "components/CustomStorageBar";
import { useConfig, useFormat, useOrganization } from "hooks";
import { ParseMD } from "helpers";

export const OrganizationResources = ({ id }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing organization ID.");

  const { t } = useTranslation();
  const { capitalize, convertGibToB, pluralizeEveryMonth, formatDate, formatBytes } = useFormat();

  /** DATA */
  const { config } = useConfig();
  const { loading, data } = useOrganization(id);

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: `${t(lg.organizationResources.header.titlePrefix)}: ${t(lg.organizationResources.header.title)}`,
      breadcrumb: [
        { value: t(lg.organizations.header.title), route: routes.organizations },
        {
          value: data.organization?.name ?? "",
          route: setRouteParams(routes.organization, { id: data.organization?.id ?? "" })
        },
        { value: t(lg.organizationResources.header.title) }
      ],
      buttons: data.organization
        ? [
            <div key="credits">
              <span className="text-2xl">{data.organization.credits}</span>{" "}
              <span className="text-secondary">
                {capitalize(
                  t(
                    pluralize(
                      data.organization.credits,
                      lg.credit.singularNominative,
                      lg.credit.nominativePlural,
                      lg.credit.genitivePlural
                    )
                  )
                )}
              </span>
            </div>
          ]
        : []
    }),
    [data.organization, t, capitalize]
  );
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      {data.diskUsageStats && (
        <Section title={t(lg.organizationResources.storage.title)}>
          <div className="mt-16">
            <CustomStorageBar items={data.diskUsageStats.byKind} totalSpace={convertGibToB(data.storageClass.maxGb)} />

            <div className="c-grid mt-5">
              {/* left */}
              <div className="c-grid-column">
                <div className="whitespace-pre-line">
                  {t(lg.organizationResources.storage.tariffInfo, {
                    size: formatBytes(convertGibToB(data.storageClass.maxGb)),
                    interval: pluralizeEveryMonth(config.storagePaymentIntervalMonths || 0)
                  })}
                </div>

                <div className="mt-5">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="font-medium text-left">{t(lg.organizationResources.storage.currentPlan)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-secondary">{data.storageName}</td>
                        <td className="text-gray-700">{formatBytes(convertGibToB(data.storageClass.maxGb))}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* right */}
              <div className="c-grid-column" />
            </div>

            <div className="text-center mt-10">
              <ParseMD>
                {t(lg.organizationResources.storage.nextControlInfo, {
                  date: formatDate(data.organization?.nextStoragePaymentDate, "human")
                })}
              </ParseMD>
            </div>
          </div>
        </Section>
      )}

      {/*
       * 3D models
       */}
      <Section title={t(lg.organizationResources.threeDModels.title)} className="mt-16">
        <div className="mt-10">{<ThreeDModelsTable organizationId={id} />}</div>
      </Section>

      {/*
       * Documentation records
       */}
      <Section title={t(lg.organizationResources.documentationRecords.title)} className="mt-16">
        <div className="mt-10">
          <DocumentationRecordsTable organizationId={id} />
        </div>
      </Section>
    </Content>
  );
};
