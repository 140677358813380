import { useCallback, useMemo } from "react";
import { InviteResolution, resolveError, useOrganizationResolveInviteMutation } from "api";
import { useTranslation } from "react-i18next";
import { useAlert } from "hooks";
import { message } from "antd";
import { lg } from "assets/translations";
import { navigate } from "@reach/router";
import { routes, setRouteParams } from "routes";
import { OrganizationResolveInviteError } from "api/enums";

export const useOrganizationResolveInvite = () => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const [resolve, { loading: loadingResolveInviteMutation }] = useOrganizationResolveInviteMutation();

  const loading = useMemo(() => loadingResolveInviteMutation, [loadingResolveInviteMutation]);

  const resolveErrorHandlers = useMemo(() => {
    return {
      [OrganizationResolveInviteError.alreadyMember]: () =>
        navigate(setRouteParams(routes.login, { error: "OTHER_TEAM" }))
    };
  }, []);

  /* Handlers - API */
  const handleSuccess = useCallback(
    (resolution: InviteResolution) => message.info(t(lg.invitations.messages[resolution])),
    [t]
  );

  const handleErrors = useCallback(
    (e: any) => resolveError(e, resolveErrorHandlers, showAlert),
    [resolveErrorHandlers, showAlert]
  );

  /* API */
  const resolveInvite = useCallback(
    async (id: string, resolution: InviteResolution): Promise<boolean> => {
      try {
        await resolve({
          variables: {
            input: {
              id,
              resolution
            }
          }
        });
        handleSuccess(resolution);
        return true;
      } catch (e) {
        handleErrors(e);
        return false;
      }
    },
    [resolve, handleSuccess, handleErrors]
  );

  return {
    loading,
    resolveInvite
  };
};
