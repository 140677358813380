import { HTMLAttributes } from "react";

type LogoIconProps = Omit<HTMLAttributes<SVGSVGElement>, "viewBox" | "fill" | "xmlns">;

export const LogoIcon = (props: LogoIconProps) => {
  return (
    <svg {...props} viewBox="0 0 492 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13544_153393)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.8006 10.9905L116.329 49.9665H7.64324L0 42.6441L31.5758 10.9905L41.3874 19.7801L27.578 33.6235L28.6215 34.6831L52.2153 11.0176L61.9591 19.7394L48.3259 33.4197L49.3694 34.4793L72.8006 10.9905Z"
          fill="white"
        />
        <path
          d="M171.973 48.5129H183.614V29.0453C183.614 20.2964 178.573 14.8215 170.13 14.8215C165.197 14.8215 161.281 17.5657 159.031 20.9485C156.836 17.0767 152.919 14.8215 147.932 14.8215C143.419 14.8215 140.153 16.8457 138.256 19.522V15.1204H126.615V48.5129H138.256V30.594C138.256 26.9667 140.397 24.9425 143.839 24.9425C147.282 24.9425 149.301 26.9667 149.301 30.594V48.5129H160.942V30.594C160.942 26.9667 163.083 24.9425 166.471 24.9425C169.859 24.9425 171.987 26.9667 171.987 30.594V48.5129H171.973Z"
          fill="white"
        />
        <path
          d="M210.623 31.7894C210.623 36.3133 207.831 38.7586 204.565 38.7586C201.299 38.7586 198.508 36.2589 198.508 31.7894C198.508 27.3199 201.245 24.8881 204.565 24.8881C207.885 24.8881 210.623 27.3334 210.623 31.7894ZM186.636 31.7894C186.636 42.4402 192.938 48.934 200.825 48.934C205.514 48.934 208.902 46.8554 210.623 43.8802V48.5264H222.264V15.1203H210.623V19.7665C208.902 16.7913 205.582 14.7128 200.825 14.7128C192.924 14.7128 186.636 21.1386 186.636 31.803V31.7894Z"
          fill="white"
        />
        <path
          d="M232.726 48.5128H245.722L250.533 28.2844L255.168 48.5128H268.232L276.729 15.1204H265.793L262.12 37.3322L257.011 15.1204H244.543L239.61 37.2643L235.87 15.1204H224.283L232.726 48.5128Z"
          fill="white"
        />
        <path
          d="M284.508 12.0229C288.6 12.0229 291.284 9.40098 291.284 6.0726C291.284 2.74422 288.614 0 284.508 0C280.401 0 277.677 2.67629 277.677 6.0726C277.677 9.4689 280.347 12.0229 284.508 12.0229ZM278.626 48.5128H290.267V15.1204H278.626V48.5128Z"
          fill="white"
        />
        <path
          d="M323.523 38.6908C323.469 26.0157 305.703 29.8196 305.703 25.0512C305.703 23.8014 306.706 23.027 308.616 23.027C311.11 23.027 312.601 24.2768 312.953 26.3554H323.469C322.697 19.753 317.954 14.6857 308.86 14.6857C299.767 14.6857 295.024 19.5084 295.024 25.3365C295.024 37.6039 312.723 33.6099 312.723 38.487C312.723 39.6825 311.598 40.6334 309.633 40.6334C307.193 40.6334 305.35 39.438 305.052 37.2371H293.764C294.238 43.9075 300.417 48.9069 309.673 48.9069C318.279 48.9069 323.51 44.5052 323.51 38.6636L323.523 38.6908Z"
          fill="white"
        />
        <path
          d="M356.536 32.0883C356.536 39.7639 351.481 44.2878 345.491 44.2878C339.501 44.2878 334.446 39.8183 334.446 32.1426C334.446 24.467 339.609 20.0518 345.491 20.0518C351.372 20.0518 356.536 24.399 356.536 32.0747V32.0883ZM334.446 21.9129V15.8947H329.039V64H334.446V42.5082C336.465 45.8365 340.68 49.0562 346.616 49.0562C355.343 49.0562 362.051 42.2093 362.051 32.0883C362.051 21.9673 355.343 15.3649 346.616 15.3649C340.68 15.3649 336.398 18.4623 334.446 21.9129Z"
          fill="white"
        />
        <path
          d="M367.77 48.5128H373.177V30.4716C373.177 23.448 376.972 19.9974 382.501 19.9974C388.03 19.9974 391.581 23.3937 391.581 30.064V48.5128H396.92V29.2897C396.92 19.9431 391.161 15.2969 383.91 15.2969C379.343 15.2969 375.305 17.1445 373.164 20.5951V4.46948H367.757V48.5264L367.77 48.5128Z"
          fill="white"
        />
        <path
          d="M407.857 32.1427C407.857 23.9236 413.02 20.0518 418.725 20.0518C424.431 20.0518 429.716 23.9236 429.716 32.1427C429.716 40.3617 424.187 44.2878 418.549 44.2878C412.911 44.2878 407.857 40.4161 407.857 32.1427ZM435.231 32.1427C435.231 21.8451 428.049 15.3513 418.779 15.3513C409.51 15.3513 402.327 21.8451 402.327 32.1427C402.327 42.4403 409.334 49.0563 418.535 49.0563C427.737 49.0563 435.218 42.5082 435.218 32.1427H435.231Z"
          fill="white"
        />
        <path
          d="M442.116 39.5874C442.116 46.1354 445.382 48.5128 451.141 48.5128H456.006V43.9346H452.022C448.702 43.9346 447.509 42.8071 447.509 39.5874V20.3643H456.006V15.8947H447.509V7.67566H442.102V15.8947H437.887V20.3643H442.102V39.5874H442.116Z"
          fill="white"
        />
        <path
          d="M464.625 32.1427C464.625 23.9236 469.789 20.0518 475.494 20.0518C481.199 20.0518 486.484 23.9236 486.484 32.1427C486.484 40.3617 480.969 44.2878 475.318 44.2878C469.667 44.2878 464.625 40.4161 464.625 32.1427ZM492 32.1427C492 21.8451 484.818 15.3513 475.548 15.3513C466.279 15.3513 459.096 21.8451 459.096 32.1427C459.096 42.4403 466.102 49.0563 475.304 49.0563C484.506 49.0563 491.986 42.5082 491.986 32.1427H492Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13544_153393">
          <rect width="492" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
