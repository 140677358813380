import { cloneElement, PropsWithChildren, ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { resolveError, ThreeDModelFragment, useDocumentationRecordDeleteMutation, User } from "api";
import { ParseMD } from "helpers/ParseMD";
import { useAlert, usePermissions } from "hooks";
import { lg } from "assets/translations/lg";
import { useMountedState } from "react-use";

type Props = {
  id: string;
  name: string;
  accountableManagerId: User["id"];
  threeDModel?: ThreeDModelFragment | null;
  onSuccess?: () => any;
  onError?: () => any;
  children: ReactElement;
};

export const Delete = ({
  id,
  name,
  accountableManagerId,
  threeDModel,
  onSuccess = () => {},
  onError = () => {},
  children
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const { showAlert } = useAlert();
  const { canDeleteDocumentationRecord } = usePermissions({
    documentationRecord: { managerId: accountableManagerId }
  });
  const [deleteDocumentationRecord, { loading: sending }] = useDocumentationRecordDeleteMutation();

  const [visible, setVisible] = useState(false);

  const open = useCallback(() => setVisible(true), []);

  const handleOk = useCallback(async () => {
    try {
      await deleteDocumentationRecord({ variables: { input: { documentationRecord: id } } });

      window.setTimeout(onSuccess, 300);

      if (isMounted()) setVisible(false);
    } catch (e) {
      resolveError(e, {}, showAlert);
      onError();
    }
  }, [deleteDocumentationRecord, id, onSuccess, onError, showAlert, isMounted]);

  const handleCancel = useCallback(() => setVisible(false), []);

  if (!canDeleteDocumentationRecord) return null;

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center">
            <DeleteOutlined className="text-lg text-red-600" />
            <span className="ml-4">{t(lg.documentationRecord.pageDetail.modals.delete.title)}</span>
          </div>
        }
        open={visible}
        okText={t(lg.documentationRecord.pageDetail.modals.delete.buttonOkText)}
        cancelText={t(lg.documentationRecord.pageDetail.modals.delete.buttonCancelText)}
        okButtonProps={{ danger: true, disabled: sending }}
        cancelButtonProps={{ disabled: sending }}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        confirmLoading={sending}
      >
        <ParseMD>{t(lg.documentationRecord.pageDetail.modals.delete.description, { name })}</ParseMD>

        {threeDModel && threeDModel.modelFile && threeDModel.modelFile.url && (
          <div className="mt-5">
            <div className="font-medium">
              {t(lg.documentationRecord.pageDetail.modals.delete.foundedThreeDModelsLabel)}
            </div>
            <ul>
              <li key={threeDModel.id}>{threeDModel.modelFile.fileName}</li>
            </ul>
          </div>
        )}

        <div className="flex items-center mt-5">
          <InfoCircleOutlined className="text-red-600 text-lg" />
          <span className="font-medium ml-2">{t(lg.documentationRecord.pageDetail.modals.delete.warning)}</span>
        </div>
      </Modal>
    </>
  );
};
