import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigate, RouteComponentProps } from "@reach/router";
import { Button } from "antd";
import { ArrowLeftOutlined, DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { Content } from "components/LayoutDashboard";
import * as Actions from "components/DocumentationRecord/Actions";
import {
  DownloadThreeDOperator,
  GeneralInfo,
  GeodeticSurveyUploadOverview,
  GroundControlPoint,
  GroundControlPointsOverview,
  ModelUpload,
  SimpleGroundControlPoint,
  VideoUploadOverview
} from "components/Task";
import { SubheaderOptions, useAlert, useFormat, useLogEvent, useMe, useSubheader, useTask } from "hooks";
import { getCoords, getMediaTotalSize, getThreeDModelType, mediaToFile, openInNewTab } from "helpers";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations";
import {
  DocumentationRecordState,
  DocumentationRecordType,
  resolveError,
  UseCaseEvent,
  useNotifyOutputExtensionsCompletedMutation
} from "api";
import { documentationRecordStates } from "api/enums";
import { ModelFilesUpload } from "components/Task/ModelFilesUpload/ModelFilesUpload";
import { Section } from "components/Section";
import { ThreeDModelInfo } from "components/Task/ThreeDModelInfo";
import { ThreeDModelUploadOverview } from "components/Task/ThreeDModelUploadOverview";
import { OutputExtensions } from "components/Task/OutputExtensions/OutputExtensions";
import { OutputExtensionEditToggle } from "components/Task/OutputExtensions/OutputExtensionEditToggle";

export const TaskThreeDOperator = ({ id, path }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing documentation record ID.");

  const { t } = useTranslation();
  const { arrayToCoords } = useFormat();
  const { showAlert } = useAlert();

  const { me } = useMe();
  const { data, handlers, loading, refetch } = useTask(id);

  const {
    record,
    site,
    object,
    triggers,
    video,
    recordVideo,
    geodeticSurvey,
    geodeticSurveyAdditional,
    threeDModel,
    organization,
    outputExtensions
  } = data;

  const { logEvent } = useLogEvent();

  const threeDModelId = threeDModel?.id;

  const handleClickViewModel = useCallback(async () => {
    if (!threeDModelId) return;

    await logEvent(UseCaseEvent.ThreeDModelView);
    openInNewTab(setRouteParams(routes.threeDViewer, { id: threeDModelId }));
  }, [threeDModelId, logEvent]);

  const threeDModelFiles = useMemo(() => threeDModel?.files?.flatMap(file => (file ? [file] : [])), [threeDModel]);

  /* states */
  const requiresThreeDOperator = useMemo(
    () => data.record?.state === DocumentationRecordState.RequiresThreeDOperator,
    [data.record]
  );
  const isRecordFinished: boolean = data.record?.state === DocumentationRecordState.Finished;
  const isMyTask: boolean = me.id === data.record?.accountableThreeDOperator?.id;

  const comeFromTodoPage = useMemo<boolean>(() => !!path?.startsWith(routes.todo + "/"), [path]);
  const backRoute: string = comeFromTodoPage ? routes.todo : routes.todoAll;
  const handleClickBack = useCallback(() => navigate(backRoute), [backRoute]);

  const hasOutputExtensions: boolean = (outputExtensions?.totalCount || 0) > 0;

  const [isOutputExtensionsEditMode, setIsOutputExtensionsEditMode] = useState(false);

  const showActions = isMyTask && !isOutputExtensionsEditMode && !isRecordFinished;

  /* permissions */
  const canEditThreeDModel: boolean = requiresThreeDOperator && isMyTask;
  const canEditThreeDModelFiles: boolean = requiresThreeDOperator || isRecordFinished;
  const canUseOutputExtensionsEditMode: boolean = isRecordFinished;

  const allowOutputExtensionsEditMode = canUseOutputExtensionsEditMode && !isOutputExtensionsEditMode;

  const recordName = record?.name;
  const recordState = record?.state;

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const recordStateLocalized = recordState ? t(documentationRecordStates[recordState].taskNameI18nKey) : "...";
    const heading = isRecordFinished ? recordName : `${recordName}: ${recordStateLocalized}`;

    return {
      heading: recordName ? heading : "...",
      breadcrumb: [
        comeFromTodoPage
          ? { value: t(lg.todo.header.title), route: routes.todo }
          : { value: t(lg.todoAll.header.title), route: routes.todoAll },
        { value: recordName ? recordName : "..." }
      ],
      buttons: showActions
        ? [
            <Actions.MainAction
              type={"taskThreeDOperator"}
              documentationRecordId={id}
              triggers={triggers}
              onAfterTrigger={handlers.handleOnAfterTrigger}
            />
          ]
        : undefined
    };
  }, [
    recordState,
    isRecordFinished,
    recordName,
    comeFromTodoPage,
    showActions,
    t,
    id,
    triggers,
    handlers.handleOnAfterTrigger
  ]);
  useSubheader(subheaderOptions);

  /* prepared data */
  const constructionSiteCoords = arrayToCoords(site?.location || []);
  const constructionObjectCoords = arrayToCoords(object?.location || []);

  const hasXYZFile: boolean = record?.type === DocumentationRecordType.ThreeDScale;
  const modelFile = mediaToFile(threeDModel?.modelFile);
  const textureFile = mediaToFile(threeDModel?.textureFile);
  const coordinatesFile = mediaToFile(threeDModel?.coordinatesFile);

  const threeDModelType = useMemo(() => (record ? getThreeDModelType(record) : undefined), [record]);
  const threeDModelTotalSize = useMemo(
    () =>
      getMediaTotalSize(
        [threeDModel?.modelFile, threeDModel?.textureFile, threeDModel?.coordinatesFile].map(file => ({
          size: file?.size || null
        }))
      ),
    [threeDModel]
  );

  const groundControlPoints = useMemo<GroundControlPoint[]>(
    () =>
      data.groundControlPoints.map(point => ({
        id: point.id,
        order: point.order,
        coords: getCoords(point.location || []),
        description: point.description || "",
        photos: point.files.edges.map(({ node }) => ({
          id: node.id,
          url: node.url || "",
          name: node.fileName || ""
        }))
      })),
    [data.groundControlPoints]
  );

  const simpleGroundControlPoint = useMemo<SimpleGroundControlPoint | undefined>(
    () =>
      data.simpleGroundControlPoint
        ? {
            id: data.simpleGroundControlPoint.id,
            order: 1,
            description: data.simpleGroundControlPoint.description || "",
            photos: data.simpleGroundControlPoint.files.edges.map(({ node }) => ({
              id: node.id,
              url: node.url || "",
              name: node.fileName || ""
            }))
          }
        : undefined,
    [data.simpleGroundControlPoint]
  );

  const [notifyOutputExtensionsCompleted] = useNotifyOutputExtensionsCompletedMutation({
    variables: { input: { documentationRecord: id } }
  });

  const handleClickEditOutputExtensions = useCallback(() => setIsOutputExtensionsEditMode(true), []);
  const handleClickSaveOutputExtensions = useCallback(async () => {
    try {
      const { data } = await notifyOutputExtensionsCompleted();
      const success = data?.outputExtension?.notifyOutputExtensionsCompleted?.success;
      if (!success) {
        resolveError(new Error("notifyOutputExtensionsCompleted_can_not_be_completed"), {}, showAlert);
        return;
      }
      setIsOutputExtensionsEditMode(false);
    } catch (error) {
      resolveError(error, {}, showAlert);
    }
  }, [notifyOutputExtensionsCompleted, showAlert]);

  const showGroundControlPointsOverview = groundControlPoints.length > 0 || !!data.simpleGroundControlPointCount;

  return (
    <>
      <Content loading={!record && loading}>
        {record && site && object && organization && (
          <>
            <div className="flex justify-between w-full flex-wrap">
              <Button size="large" type="default" icon={<ArrowLeftOutlined />} onClick={handleClickBack}>
                {t(lg.taskThreeDOperator.buttonBack)}
              </Button>

              <div className="flex flex-col">
                <Actions.ValidationMessages triggers={data.triggers} className="mb-2" />

                <div className="flex justify-end">
                  <DownloadThreeDOperator
                    documentationRecordName={record.name}
                    points={groundControlPoints}
                    simplePoint={simpleGroundControlPoint}
                    video={video}
                    geodeticSurvey={geodeticSurvey}
                    geodeticSurveyAdditional={geodeticSurveyAdditional}
                  >
                    <Button size="large" type="default" icon={<DownloadOutlined />}>
                      {t(lg.taskThreeDOperator.buttonDownload)}
                    </Button>
                  </DownloadThreeDOperator>
                </div>
              </div>
            </div>

            <GeneralInfo
              documentationRecordId={id}
              hasDocumentationState={false}
              hasDocumentationType={true}
              hasVideoType={true}
              className="mt-16"
            />

            {showGroundControlPointsOverview && (
              <GroundControlPointsOverview
                constructionSiteCoords={constructionSiteCoords}
                constructionObjectCoords={constructionObjectCoords}
                groundControlPoints={groundControlPoints}
                simpleGroundControlPointCount={data.simpleGroundControlPointCount}
                simpleGroundControlPoint={simpleGroundControlPoint}
                hasDownloadAndPrint={false}
                className="mt-16"
              />
            )}

            {!!video && (
              <VideoUploadOverview video={video} captureLocation={recordVideo?.captureLocation} className="mt-16" />
            )}

            {!!geodeticSurvey && (
              <GeodeticSurveyUploadOverview
                geodeticSurvey={geodeticSurvey}
                geodeticSurveyAdditional={geodeticSurveyAdditional}
                className="mt-16"
              />
            )}

            {canEditThreeDModel ? (
              <ModelUpload
                id={record.threeDModel?.id}
                organizationId={organization.id}
                documentationRecordId={record.id}
                modelFile={modelFile}
                textureFile={textureFile}
                coordinatesFile={coordinatesFile}
                coordinateSystem={threeDModel?.coordinateSystem ? threeDModel?.coordinateSystem : undefined}
                hasXYZFile={hasXYZFile}
                className="mt-16"
                disabled={!canEditThreeDModel}
              />
            ) : null}

            {!canEditThreeDModel && threeDModel ? (
              <Section title={t(lg.taskThreeDOperator.modelUpload.title)} className="mt-16">
                <ThreeDModelUploadOverview
                  modelFile={threeDModel.modelFile}
                  coordinatesFile={threeDModel.coordinatesFile}
                  textureFile={threeDModel.textureFile}
                  className="mt-10"
                />
                {threeDModelType ? (
                  <>
                    <ThreeDModelInfo
                      type={threeDModelType}
                      totalSize={threeDModelTotalSize}
                      coordinateSystem={threeDModel.coordinateSystem}
                      createdAt={threeDModel.createdAt}
                      className="mt-10"
                    />
                  </>
                ) : null}
                <div className="text-right mt-10">
                  <Button size="middle" icon={<EyeOutlined />} onClick={handleClickViewModel}>
                    {t(lg.taskThreeDOperator.modelUpload.buttonViewModel)}
                  </Button>
                </div>
              </Section>
            ) : null}

            {canEditThreeDModelFiles ? (
              <ModelFilesUpload
                className="mt-16"
                documentationRecordId={id}
                organizationId={organization.id}
                initialFiles={threeDModelFiles}
              />
            ) : null}

            {hasOutputExtensions && (
              <div className={"flex flex-col space-y-10"}>
                <OutputExtensions
                  className="mt-16"
                  documentationRecordId={id}
                  organizationId={organization.id}
                  readonly={allowOutputExtensionsEditMode}
                  // TODO: possible optimization - refetch documentation record's triggers only, not full the record
                  onSuccess={refetch}
                />
                {canUseOutputExtensionsEditMode && (
                  <OutputExtensionEditToggle
                    className="ml-auto"
                    isEditing={isOutputExtensionsEditMode}
                    onEditClick={handleClickEditOutputExtensions}
                    onSaveClick={handleClickSaveOutputExtensions}
                  />
                )}
              </div>
            )}
          </>
        )}
      </Content>

      {showActions ? (
        <Actions.ActionsSection
          documentationRecordId={id}
          triggers={data.triggers}
          threeDOperator={true}
          cost={data.cost}
          onAfterTrigger={handlers.handleOnAfterTrigger}
        />
      ) : null}
    </>
  );
};
