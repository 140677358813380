import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal, Select } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { useConfig, useFormat } from "hooks";
import { lg } from "assets/translations";
import { CreditSlider } from "components/CreditSlider";
import { useUpdateEffect } from "react-use";
import { FormProps } from "antd/es/form";

/**
 * Return Documentation Record to the Operator
 * Some fields are required by user role
 *
 * doc:
 * https://hrdlicka.atlassian.net/wiki/spaces/HRD/pages/786537/UC0017+-+U+ivatel+vrac+zpracov+n+Dokumenta+n+ho+z+znamu
 * https://hrdlicka.atlassian.net/wiki/spaces/HRD/pages/12877897/UC0059+-+3D+Oper+tor+vrac+zpracov+n+Dokumenta+n+ho+z+znamu
 * */
export type BackOperatorHandler = (comment?: string, reason?: string, percentageRefund?: number) => any;

enum FormField {
  reason = "reason",
  comment = "comment",
  percentageRefund = "percentageRefund"
}

type FormValues = {
  [FormField.reason]: string;
  [FormField.comment]: string;
  [FormField.percentageRefund]: number;
};

type Props = {
  visible: boolean;
  loading?: boolean;
  threeDOperator: boolean;
  cost: number;
  onSubmit: BackOperatorHandler;
  onCancel: () => void;
};

export const ModalBackOperator = ({ visible, loading, threeDOperator, cost, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation();
  const { creditCost } = useFormat();
  const { config } = useConfig();

  const requireComment = useMemo<boolean>(() => threeDOperator, [threeDOperator]);
  const requireReason = useMemo(() => threeDOperator, [threeDOperator]);
  const allowRefund = useMemo(() => threeDOperator && cost > 0, [threeDOperator, cost]);

  const [isFormValid, setIsFormValid] = useState<boolean>(!threeDOperator);
  const [refund, setRefund] = useState<number>(0);

  const [form] = Form.useForm<FormValues>();

  const reasons = useMemo(() => config.documentationRecordReturnReasons, [config.documentationRecordReturnReasons]);
  const initialReason = useMemo(() => (reasons.length > 0 ? reasons[0] : ""), [reasons]);
  const initialValues = useMemo<FormValues>(
    () => ({
      [FormField.reason]: initialReason,
      [FormField.comment]: "",
      [FormField.percentageRefund]: 0
    }),
    [initialReason]
  );

  const resetForm = useCallback(() => form.setFieldsValue(initialValues), [form, initialValues]);

  const validateForm = useCallback(() => {
    const formValues = form.getFieldsValue();
    if (requireReason && !formValues[FormField.reason]) return setIsFormValid(false);
    if (requireComment && !formValues[FormField.comment]) return setIsFormValid(false);
    return setIsFormValid(true);
  }, [form, requireComment, requireReason]);

  const handleChangeForm: Required<FormProps>["onValuesChange"] = useCallback(
    changedValues => {
      validateForm();

      if (changedValues[FormField.percentageRefund] !== undefined) {
        setRefund(Math.round((changedValues[FormField.percentageRefund] * cost) / 100));
      }
    },
    [validateForm, cost]
  );

  const handleOk = useCallback(() => {
    if (!isFormValid) return;

    const formValues = form.getFieldsValue();

    if (threeDOperator) {
      return onSubmit(
        formValues[FormField.comment],
        formValues[FormField.reason],
        formValues[FormField.percentageRefund]
      );
    }

    onSubmit(formValues[FormField.comment] || undefined);
  }, [onSubmit, form, threeDOperator, isFormValid]);

  const handleAfterClose = useCallback(() => resetForm(), [resetForm]);

  /*
   * Update form initial values
   * Initial values can be changed after language change
   * */
  useUpdateEffect(() => resetForm(), [initialValues]);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <RollbackOutlined className="text-purple-600" />
          <span className="ml-2">{t(lg.documentationRecordActions.backOperator.modal.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.backOperator.modal[threeDOperator ? "buttonOkRefund" : "buttonOk"])}
      cancelText={t(
        lg.documentationRecordActions.backOperator.modal[threeDOperator ? "buttonCancelRefund" : "buttonCancel"]
      )}
      okButtonProps={{ type: "primary", disabled: loading || !isFormValid }}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
      destroyOnClose={true}
      afterClose={handleAfterClose}
    >
      {!threeDOperator && (
        <div className="text-base mb-5">{t(lg.documentationRecordActions.backOperator.modal.description)}</div>
      )}

      <Form<FormValues>
        form={form}
        name="modal_send"
        layout="vertical"
        hideRequiredMark={true}
        initialValues={initialValues}
        onValuesChange={handleChangeForm}
      >
        {requireReason && (
          <Form.Item
            name={FormField.reason}
            className="mb-5"
            label={t(lg.documentationRecordActions.backOperator.modal.reason.label)}
          >
            <Select>
              {reasons.map((option, i) => (
                <Select.Option key={`reason-option-${i}`} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name={FormField.comment}
          className="mb-0"
          label={t(
            lg.documentationRecordActions.backOperator.modal.comment[threeDOperator ? "labelRequired" : "label"]
          )}
        >
          <Input.TextArea
            rows={3}
            placeholder={t(lg.documentationRecordActions.backOperator.modal.comment.placeholder)}
          />
        </Form.Item>

        {allowRefund ? (
          <>
            <Form.Item
              name={FormField.percentageRefund}
              className="mt-10 mb-0"
              label={t(lg.documentationRecordActions.backOperator.modal.refund.label)}
            >
              <CreditSlider />
            </Form.Item>

            <div className="mt-5">
              {t(lg.documentationRecordActions.backOperator.modal.refund.return, {
                refund,
                total: creditCost(cost, false)
              })}
            </div>
          </>
        ) : null}
      </Form>
    </Modal>
  );
};
