import { Config, InquiryTopicLocalesFragment, Language } from "api";

/*
 * InquiryTopic
 * */
type InquiryTopicLocalized<T> = T & { topic: string };

export const getLocaleInquiryTopic = <T extends InquiryTopicLocalesFragment>(
  topic: T,
  lng: string
): InquiryTopicLocalized<T> => ({
  ...topic,
  topic: getLocaleValue(lng, topic.topicCs, topic.topicEn, topic.topicUk, topic.topicDe)
});

/*
 * Config
 * */
type ConfigLocalized<T> = T & {
  emailContact: Config["emailContactCs"];
  phoneContact: Config["phoneContactCs"];
  shopUrl: Config["shopUrlCs"];
  defaultConstructionPhases: Config["defaultConstructionPhasesCs"];
  documentationRecordReturnReasons?: Config["documentationRecordReturnReasonsCs"];
  documentationRecordRejectReasons?: Config["documentationRecordRejectReasonsCs"];
};

export const getLocaleConfig = <
  T extends Pick<
    Config,
    | "emailContactCs"
    | "emailContactEn"
    | "emailContactUk"
    | "emailContactDe"
    | "phoneContactCs"
    | "phoneContactEn"
    | "phoneContactUk"
    | "phoneContactDe"
    | "shopUrlCs"
    | "shopUrlDe"
    | "shopUrlEn"
    | "shopUrlUk"
    | "defaultConstructionPhasesCs"
    | "defaultConstructionPhasesEn"
    | "defaultConstructionPhasesUk"
    | "defaultConstructionPhasesDe"
    | "documentationRecordReturnReasonsCs"
    | "documentationRecordReturnReasonsEn"
    | "documentationRecordReturnReasonsUk"
    | "documentationRecordReturnReasonsDe"
    | "documentationRecordRejectReasonsCs"
    | "documentationRecordRejectReasonsEn"
    | "documentationRecordRejectReasonsUk"
    | "documentationRecordRejectReasonsDe"
  >
>(
  config: T,
  lng: string
): ConfigLocalized<T> => ({
  ...config,
  emailContact: getLocaleValue(
    lng,
    config.emailContactCs,
    config.emailContactEn,
    config.emailContactUk,
    config.emailContactDe
  ),
  phoneContact: getLocaleValue(
    lng,
    config.phoneContactCs,
    config.phoneContactEn,
    config.phoneContactUk,
    config.phoneContactDe
  ),
  shopUrl: getLocaleValue(lng, config.shopUrlCs, config.shopUrlEn, config.shopUrlUk, config.shopUrlDe),
  defaultConstructionPhases: getLocaleValue(
    lng,
    config.defaultConstructionPhasesCs,
    config.defaultConstructionPhasesEn,
    config.defaultConstructionPhasesUk,
    config.defaultConstructionPhasesDe
  ),
  documentationRecordReturnReasons: getLocaleValue(
    lng,
    config.documentationRecordReturnReasonsCs,
    config.documentationRecordReturnReasonsEn,
    config.documentationRecordReturnReasonsUk,
    config.documentationRecordReturnReasonsDe
  ),
  documentationRecordRejectReasons: getLocaleValue(
    lng,
    config.documentationRecordRejectReasonsCs,
    config.documentationRecordRejectReasonsEn,
    config.documentationRecordRejectReasonsUk,
    config.documentationRecordRejectReasonsDe
  )
});

const getLocaleValue = <T>(lng: string, cs: T, en: T, uk: T, de: T): T => {
  if (lng === Language.Cs) return cs;
  if (lng === Language.En) return en;
  if (lng === Language.Uk) return uk;
  if (lng === Language.De) return de;

  // fallback
  return cs;
};
