import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { useDebounce } from "react-use";
import { MediaCard } from "components/MediaCard";
import { Section } from "components/Section";
import { MediaUploadSuccessHandler, useAlert, useConfig, useFormat, useMedia } from "hooks";
import {
  ConstructionElementFragment,
  resolveError,
  useConstructionElementCreateMutation,
  useConstructionElementDeleteMutation,
  useConstructionElementUpdateMutation
} from "api";
import { lg } from "assets/translations";
import { ButtonUpload } from "components/ButtonUpload";

type Props = {
  organizationId: string;
  documentationRecordId: string;
  defaultElements?: ConstructionElementFragment[];
  className?: string;
};

// Task Photo Documentation section
export const ConstructionElements = ({
  organizationId,
  documentationRecordId,
  defaultElements = [],
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { config } = useConfig();
  const { allowedFileTypesToString } = useFormat();

  const [createElement, { loading: creating }] = useConstructionElementCreateMutation();
  const [updateElement, { loading: updating }] = useConstructionElementUpdateMutation();
  const [deleteElement, { loading: removing }] = useConstructionElementDeleteMutation();

  const [elements, setElements] = useState<ConstructionElementFragment[]>(defaultElements);
  const [descriptions, setDescriptions] = useState<{ [key: string]: string }>({}); // key is element.id

  const allowedFileTypes = useMemo(() => config.allowedImageFileTypes, [config.allowedImageFileTypes]);
  const uploadAccept = useMemo(
    () => allowedFileTypesToString(allowedFileTypes),
    [allowedFileTypesToString, allowedFileTypes]
  );

  // handler for TUS upload success and Media state is Uploaded
  const handleMediaUploadSuccess = useCallback<MediaUploadSuccessHandler>(
    async media => {
      try {
        const { data } = await createElement({
          variables: {
            input: {
              documentationRecord: documentationRecordId,
              photo: media.id,
              description: ""
            }
          }
        });

        const element = data?.constructionElement?.create?.constructionElement;

        if (!element) return;

        setElements([...elements, element]);
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [documentationRecordId, elements, createElement, showAlert]
  );

  const { upload, uploading, uploadedPercents } = useMedia(organizationId, handleMediaUploadSuccess);
  const loadingMutation = useMemo(
    () => uploading || updating || creating || removing,
    [uploading, updating, creating, removing]
  );
  const inUploadingProcess = useMemo(() => uploading || creating, [uploading, creating]);

  const handleBeforeUpload = useCallback(
    async (file: RcFile): Promise<void> => upload(file, allowedFileTypes),
    [upload, allowedFileTypes]
  );

  const handleRemove = useCallback(
    async (id: string) => {
      if (loadingMutation) return;

      try {
        await deleteElement({ variables: { input: { constructionElement: id } } });
      } catch (e) {
        resolveError(e, {}, showAlert);
        return;
      }

      setElements(elements.filter(element => element.id !== id));
    },
    [loadingMutation, elements, deleteElement, showAlert]
  );

  const handleDescriptionChange = useCallback(
    (id: string, value: string) => setDescriptions({ ...descriptions, [id]: value }),
    [descriptions]
  );

  const saveDescriptions = useCallback(async () => {
    const newElements: ConstructionElementFragment[] = [];

    for (const element of elements) {
      const description = descriptions[element.id];

      if (element.description === description) {
        newElements.push(element);
        continue;
      }

      try {
        const { data } = await updateElement({
          variables: { input: { constructionElement: element.id, description } }
        });

        if (!data?.constructionElement?.update?.constructionElement) return;

        newElements.push(data.constructionElement.update.constructionElement);
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    }

    setElements(newElements);
  }, [descriptions, elements, updateElement, showAlert]);

  useDebounce(saveDescriptions, 1000, [descriptions]);

  return (
    <Section title={t(lg.todoTradesman.pageTaskDetail.photos.title)} className={className}>
      <div className="c-grid mt-5">
        <div className="c-grid-column">
          <div>{t(lg.todoTradesman.pageTaskDetail.photos.description)}</div>
        </div>
      </div>

      <div className="c-grid mt-10">
        {elements.map(({ id, photo, description }) => (
          <div key={id} className="c-grid-column">
            <MediaCard
              fileName={photo.fileName || ""}
              fileUrl={photo.url || ""}
              fileId={id}
              onDelete={() => handleRemove(id)}
            />
            <div className="mt-5">
              {t(lg.todoTradesman.pageTaskDetail.photos.form.constructionElementPhoto.label)}
              <Input.TextArea
                rows={3}
                defaultValue={description || ""}
                placeholder={t(lg.todoTradesman.pageTaskDetail.photos.form.constructionElementPhoto.placeholder)}
                disabled={loadingMutation}
                onChange={e => handleDescriptionChange(id, e.currentTarget.value)}
              />
            </div>
          </div>
        ))}
      </div>

      <Upload type={"select"} fileList={[]} accept={uploadAccept} beforeUpload={handleBeforeUpload}>
        <ButtonUpload
          size="large"
          className="mt-5"
          disabled={inUploadingProcess || loadingMutation}
          progress={uploadedPercents}
          uploading={inUploadingProcess}
        >
          {t(lg.uploadButton.description.photos)}
        </ButtonUpload>
      </Upload>
    </Section>
  );
};
