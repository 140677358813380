import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { lg } from "assets/translations/lg";
import { Tabs } from "antd";
import { Documentation } from "components/About/Documentation";
import { Sample } from "components/About/Sample";
import { AboutApp } from "components/About/AboutApp";
import { MyTasks } from "components/About/MyTasks";
import { ThreeDModels } from "components/About/ThreeDModels";
import { ConstructionSites } from "components/About/ConstructionSites";
import { Organization } from "components/About/Organization";
import { routes, setRouteParams } from "routes";
import { enumToValues } from "helpers";

export enum AboutPageTabKeys {
  WhatIsMawis = "what_is_mawis",
  MyTasks = "my_todo",
  Documentation = "documentation",
  ThreeDModels = "three_d_models",
  ConstructionSites = "construction_sites",
  Organization = "organization",
  Sample = "sample"
}

type Props = RouteComponentProps & {
  activeTab?: AboutPageTabKeys;
};

export const About = ({ activeTab, navigate }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const activeTabIsValid = useMemo<boolean>(
    () => !!enumToValues(AboutPageTabKeys).find(key => key === activeTab),
    [activeTab]
  );
  const redirectToDefaultTab = useCallback(
    () =>
      navigate &&
      navigate(setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.WhatIsMawis }), { replace: true }),
    [navigate]
  );
  const subheaderOptions = useMemo<SubheaderOptions>(() => ({ heading: t(lg.aboutPage.header.title) }), [t]);
  useSubheader(subheaderOptions);

  /* Redirect to the default route with tab ID as param for non-existed tab ID */
  useEffect(() => {
    if (activeTabIsValid) return;
    redirectToDefaultTab();
  }, [redirectToDefaultTab, activeTabIsValid]);

  const handleChangeTab = useCallback(
    (tabKey: string) => {
      if (!navigate) return;
      navigate(setRouteParams(routes.aboutActiveTab, { activeTab: tabKey }));
    },
    [navigate]
  );

  return (
    <Content>
      <Tabs
        activeKey={activeTab}
        items={[
          {
            /* What is Mawis*/
            label: t(lg.aboutPage.tabs.about.title),
            key: AboutPageTabKeys.WhatIsMawis,
            className: "pt-8",
            children: <AboutApp />
          },
          {
            /* My Tasks */
            label: t(lg.aboutPage.tabs.myTasks.title),
            key: AboutPageTabKeys.MyTasks,
            className: "pt-8",
            children: <MyTasks />
          },
          {
            /* Documentation */
            label: t(lg.aboutPage.tabs.documentation.title),
            key: AboutPageTabKeys.Documentation,
            className: "pt-8",
            children: <Documentation />
          },

          {
            /* 3D Models */
            label: t(lg.aboutPage.tabs.threeDModels.title),
            key: AboutPageTabKeys.ThreeDModels,
            className: "pt-8",
            children: <ThreeDModels />
          },

          {
            /* Construction Sites */
            label: t(lg.aboutPage.tabs.constructionSites.title),
            key: AboutPageTabKeys.ConstructionSites,
            className: "pt-8",
            children: <ConstructionSites />
          },

          {
            /* Organization */
            label: t(lg.aboutPage.tabs.organization.title),
            key: AboutPageTabKeys.Organization,
            className: "pt-8",
            children: <Organization />
          },

          {
            /* Sample */
            label: t(lg.aboutPage.tabs.sample.title),
            key: AboutPageTabKeys.Sample,
            className: "pt-8",
            children: <Sample />
          }
        ]}
        onChange={handleChangeTab}
        animated={false}
      />
    </Content>
  );
};
