import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useMe, useSubheader } from "hooks";
import { Tabs } from "antd";
import { enumToValues, isThreeDOperator } from "helpers";
import { routes, setRouteParams } from "routes";
import { PendingInquiriesTab } from "pages/AdditionalServicesReview/PendingInquiresTab";
import { ResolvedInquiresTab } from "pages/AdditionalServicesReview/ResolvedInquiresTab";

export enum AdditionalServicesReviewTabKeys {
  NewInquiries = "pending",
  ResolvedInquiries = "resolved"
}

type Props = RouteComponentProps & {
  activeTab?: AdditionalServicesReviewTabKeys;
};

export const AdditionalServicesReview = ({ activeTab, navigate }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const { me, loading } = useMe();
  const isAuthorizedToView = useMemo<boolean>(() => isThreeDOperator(me), [me]);

  /* redirect a user to the default route if the user is not authorized to render this page */
  useEffect(() => {
    if (!me.role || isAuthorizedToView) return;
    navigate && navigate(routes.todo);
  }, [isAuthorizedToView, me, navigate]);

  const handleChangeTab = useCallback(
    (tabKey: string) => {
      if (!navigate) return;
      navigate(setRouteParams(routes.additionalServicesReviewActiveTab, { activeTab: tabKey }), { replace: true });
    },
    [navigate]
  );

  const activeTabIsValid = useMemo<boolean>(
    () => !!enumToValues(AdditionalServicesReviewTabKeys).find(key => key === activeTab),
    [activeTab]
  );
  const redirectToDefaultTab = useCallback(
    () =>
      navigate &&
      navigate(
        setRouteParams(routes.additionalServicesReviewActiveTab, {
          activeTab: AdditionalServicesReviewTabKeys.NewInquiries
        })
      ),
    [navigate]
  );

  const subheaderOptions = useMemo<SubheaderOptions>(() => ({ heading: t(lg.additionalServices.header.title) }), [t]);
  useSubheader(subheaderOptions);

  /* Redirect to the default route with tab ID as param for non-existed tab ID */
  useEffect(() => {
    if (activeTabIsValid) return;
    redirectToDefaultTab();
  }, [redirectToDefaultTab, activeTabIsValid]);

  return (
    <Content loading={loading}>
      <Tabs
        activeKey={activeTab}
        items={[
          {
            label: t(lg.additionalServicesReview.tabs.pendingInquiries),
            key: AdditionalServicesReviewTabKeys.NewInquiries,
            children: <PendingInquiriesTab />,
            className: "pt-8"
          },
          {
            label: t(lg.additionalServicesReview.tabs.resolvedInquiries),
            key: AdditionalServicesReviewTabKeys.ResolvedInquiries,
            children: <ResolvedInquiresTab />,
            className: "pt-8"
          }
        ]}
        onChange={handleChangeTab}
        animated={false}
        destroyInactiveTabPane
      />
    </Content>
  );
};
