import { GraphQLError } from "graphql";
import { ApolloError } from "apollo-client";
import { routes, setRouteParams } from "routes";
import { navigate } from "@reach/router";
import * as Sentry from "@sentry/browser";
import { HTTPErrors } from "api/enums";
import { ServerError, ServerParseError } from "apollo-link-http-common";
import { isReactSnap } from "helpers";

type _GraphQLError = GraphQLError & {
  code: string;
  errorType: string;
};
type ErrorHandler = (error?: ApolloError) => void;

export const resolveError = (
  error: ApolloError | unknown,
  handlers?: { [code: string]: ErrorHandler },
  defaultErrorHandler?: ErrorHandler,
  sendToSentry: boolean = true
): void => {
  /* don't resolve errors during generating static pages */
  if (isReactSnap) return;

  if (sendToSentry) {
    /**
     * Send error to the Sentry
     */
    Sentry.withScope(scope => {
      error instanceof ApolloError && scope.setExtras(error.extraInfo);
      Sentry.captureException(error);
    });
  }

  if (!(error instanceof ApolloError)) return;
  if (!error.graphQLErrors || !error.graphQLErrors.length) return;

  const firstError = error.graphQLErrors[0] as _GraphQLError;
  const handler = handlers && handlers[firstError.message];

  if (handler) {
    handler();
    return;
  }

  if (!defaultErrorHandler) {
    handleDefaultError();
    return;
  }

  defaultErrorHandler(error);
};

export const handleDefaultError = (useRouter: boolean = true) =>
  useRouter
    ? navigate(setRouteParams(routes.error, { error: HTTPErrors.Internal }), { replace: true })
    : navigate(routes.login, { replace: true });

export function isFetchError(error: Error | ServerError | ServerParseError): boolean {
  return error.message.includes("Failed to fetch");
}
