import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "@reach/router";
import { Section } from "components/Section";
import { lg } from "assets/translations/lg";
import { ThreeDOperatorInProgressTable } from "components/Todo/ThreeDOperatorInProgressTable";
import { ThreeDOperatorNewTable } from "components/Todo/ThreeDOperatorNewTable";
import { FunctionComponent } from "react";

export const TodoThreeDOperator: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section title={t(lg.todoThreeDOperator.todosInProgress.title)}>
        <div className="mt-10">
          <ThreeDOperatorInProgressTable />
        </div>
      </Section>

      <Section title={t(lg.todoThreeDOperator.newTodos.title)} className="mt-16">
        <div className="mt-10">
          <ThreeDOperatorNewTable />
        </div>
      </Section>
    </>
  );
};
