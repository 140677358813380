import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DocumentationRecordState, Media, useOutputExtensionsForDocumentationRecordDetailPageQuery } from "api";
import { getLocaleInquiryTopic } from "helpers";
import { lg } from "assets/translations";
import { Button, message, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ModalDelete } from "./OutputExtension/ModalDelete";
import { Section } from "components/Section";
import { MediaCard } from "components/MediaCard";

type Props = {
  documentationRecordId: string;
  documentationRecordState: DocumentationRecordState;
  className?: string;
  onDeleteSuccess?: () => void;
};

export const OutputExtensions = ({
  documentationRecordId,
  documentationRecordState,
  className,
  onDeleteSuccess
}: PropsWithChildren<Props>) => {
  const { t, i18n } = useTranslation();

  const { data, loading } = useOutputExtensionsForDocumentationRecordDetailPageQuery({
    variables: { id: documentationRecordId }
  });

  const isRecordFinished: boolean = documentationRecordState === DocumentationRecordState.Finished;

  const mappedData = useMemo(
    () => data?.documentationRecord?.outputExtensions?.edges.flatMap(({ node }) => (node ? [node] : [])) || [],
    [data?.documentationRecord?.outputExtensions]
  );

  type Item = {
    id: string;
    name: string;
    comment: string | null | undefined;
    files: Pick<Media, "id" | "url" | "fileName">[];
  };

  const items: Item[] = useMemo(
    () =>
      mappedData.map(outputExtension => ({
        id: outputExtension.id,
        name: getLocaleInquiryTopic(outputExtension.inquiryTopic, i18n.language).topic,
        comment: outputExtension.comment,
        files: outputExtension.files.edges.flatMap(({ node }) => (node ? [node] : [])) || []
      })),
    [i18n.language, mappedData]
  );

  const handleDeleteSuccess = useCallback(async () => {
    if (onDeleteSuccess) await onDeleteSuccess();
    message.success(t(lg.documentationRecord.pageDetail.outputExtensions.body.messages.successfullyDeleted));
  }, [t, onDeleteSuccess]);

  return (
    <Section title={t(lg.documentationRecord.pageDetail.outputExtensions.title)} className={className}>
      <div className="space-y-15 mt-10">
        {loading && (
          <div className="flex justify-center">
            <Spin data-cy={"loading-options-sharing3DModel"} />
          </div>
        )}
        {!loading &&
          items.map(item => {
            return (
              <div key={item.id}>
                <div className={"flex justify-between flex-col md:flex-row"}>
                  <div className="text-base text-blue-700 font-medium">{item.name}</div>
                  {isRecordFinished ? (
                    <ModalDelete id={item.id} name={item.name} onSuccess={handleDeleteSuccess}>
                      <Button icon={<DeleteOutlined />} danger size={"small"} type={"primary"}>
                        {t(lg.documentationRecord.pageDetail.outputExtensions.body.actions.deleteOutputExtension)}
                      </Button>
                    </ModalDelete>
                  ) : null}
                </div>
                {isRecordFinished ? (
                  <div className="c-grid mt-5 md:mt-10">
                    {/* left */}
                    <div className="c-grid-column">
                      <div className="font-medium">
                        {t(lg.documentationRecord.pageDetail.outputExtensions.body.attachments.label)}
                      </div>
                      <div className={"space-y-5"}>
                        {item.files.length > 0
                          ? item.files.map(file => (
                              <MediaCard
                                key={file.id}
                                fileUrl={file.url || undefined}
                                fileName={file.fileName || "-"}
                              />
                            ))
                          : "-"}
                      </div>
                    </div>
                    {/* right */}
                    <div className="c-grid-column">
                      <div className="font-medium">
                        {t(lg.documentationRecord.pageDetail.outputExtensions.body.resolverComment.label)}
                      </div>
                      <div className="text-secondary whitespace-pre-line">{item.comment || "-"}</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center mt-10">
                    <div className="text-xs text-gray-700 text-center whitespace-pre-line">
                      {t(lg.documentationRecord.pageDetail.outputExtensions.body.messages.noResolved)}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </Section>
  );
};
