import { AutoSaveIndicator } from "components/AutoSaveIndicator";
import { Input } from "antd";
import { ComponentPropsWithRef, useState } from "react";
import { resolveError } from "api";
import { useAlert } from "hooks";
import { useDebounce } from "react-use";
import clsx from "clsx";

type AutoSavingTextareaProps = TextAreaProps & {
  value?: string;
  defaultValue?: string;
  onSave: (value: string) => void | Promise<void>;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
};

type TextAreaProps = ComponentPropsWithRef<typeof Input.TextArea>;

export const AutoSavingTextarea = ({
  value,
  defaultValue,
  className,
  onSave,
  onSuccess,
  onError,
  onChange,
  ...textareaProps
}: AutoSavingTextareaProps): JSX.Element => {
  const [internalValue, setInternalValue] = useState(defaultValue || "");
  const { showAlert } = useAlert();
  const [isSaving, setIsSaving] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [alreadySaved, setAlreadySaved] = useState(false);

  const val: string = value ? value : internalValue;

  const handleSave = async (value: string) => {
    setIsSaving(true);
    try {
      await onSave(value);
      setAlreadySaved(true);
      if (onSuccess) onSuccess();
    } catch (e) {
      if (onError) {
        onError(e);
        return;
      }
      resolveError(e, {}, showAlert);
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange: TextAreaProps["onChange"] = async e => {
    setAlreadySaved(false);
    setIsTouched(true);
    try {
      setInternalValue(e.target.value);
      if (onChange) onChange(e);
    } catch (e) {
      if (onError) {
        onError(e);
        return;
      }
      resolveError(e, {}, showAlert);
    }
  };

  useDebounce(
    () => {
      if (!isTouched) return;
      return handleSave(val);
    },
    1000,
    [val]
  );

  return (
    <div className={clsx("flex flex-col", className)}>
      <Input.TextArea onChange={handleChange} value={val} {...textareaProps} />
      <AutoSaveIndicator isSaving={isSaving} saved={alreadySaved} className={"ml-auto"} />
    </div>
  );
};
