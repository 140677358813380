import { getInquiryName, TableProps, useColumnSearchProps, usePagination } from "helpers";
import { useTranslation } from "react-i18next";
import { useAlert, useFormat } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  InquiryOrderByEnum,
  InquiryStatusFilterEnum,
  resolveError,
  useInquiriesForAdditionalServicesReviewPageQuery
} from "api";
import { lg } from "assets/translations";
import { Link } from "components/Link";
import { routes, setRouteParams } from "routes";
import { Table } from "antd";
import { SimpleLoader } from "components/SimpleLoader";

type PendingInquiriesTableItem = {
  id: string;
  inquiryName: string;
  createdAt: string;
  requestedBy: string;
  organization: string;
};

type PendingInquiriesTablePropType = TableProps<PendingInquiriesTableItem>;

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  createdAt: {
    ascend: [InquiryOrderByEnum.CreatedAt],
    descend: [InquiryOrderByEnum.CreatedAtDesc]
  }
};

export const PendingInquiriesTab = () => {
  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const { formatDate } = useFormat();

  const organizationSearchProps = useColumnSearchProps();
  const createdBySearchProps = useColumnSearchProps();
  const [inquiryNumberFilter, setInquiryNumberFilter] = useState<string>("");
  const [organizationFilter, setOrganizationFilter] = useState<string>("");
  const [requestedByFilter, setRequestedByFilter] = useState<string>("");

  const [orderBy, setOrderBy] = useState<InquiryOrderByEnum[]>(orderByMap.createdAt.descend);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });

  const { data: dataInquiries, loading } = useInquiriesForAdditionalServicesReviewPageQuery({
    variables: {
      skip,
      orderBy,
      status: [InquiryStatusFilterEnum.Created],
      organizationName: organizationFilter,
      requestedByName: requestedByFilter
    },
    fetchPolicy: "cache-and-network",
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => setTotal(dataInquiries?.inquiries?.totalCount), [dataInquiries]);

  const inquiries = useMemo(
    () => dataInquiries?.inquiries.edges.flatMap(({ node }) => (node ? [node] : [])) || [],
    [dataInquiries]
  );

  const tableData = useMemo<PendingInquiriesTableItem[]>(
    () =>
      inquiries.map(inquiry => {
        return {
          id: inquiry.id,
          inquiryName: getInquiryName(inquiry, i18n.language),
          createdAt: inquiry.createdAt,
          requestedBy: `${inquiry.user.firstName} ${inquiry.user.lastName}`,
          organization: inquiry.organization.name
        };
      }),
    [inquiries, i18n.language]
  );
  const tableColumns = useMemo<PendingInquiriesTablePropType["columns"]>(
    (): PendingInquiriesTablePropType["columns"] => [
      {
        dataIndex: "inquiryName",
        key: "inquiryName",
        title: t(lg.additionalServicesReview.pendingInquiries.table.inquiryName),
        render: (inquiryName, { id }) => <Link href={setRouteParams(routes.inquiryReview, { id })}>{inquiryName}</Link>,
        ellipsis: true
      },
      {
        dataIndex: "createdAt",
        key: "createdAt",
        title: t(lg.additionalServicesReview.pendingInquiries.table.createdAt),
        render: createdAt => (
          <span className="text-secondary">{createdAt ? formatDate(createdAt, "machine", true) : "-"}</span>
        ),
        sorter: true,
        ellipsis: true,
        className: "whitespace-nowrap",
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        dataIndex: "requestedBy",
        key: "requestedBy",
        title: t(lg.additionalServicesReview.pendingInquiries.table.requestedBy),
        render: requestedBy => <span className="text-secondary">{requestedBy}</span>,
        ...createdBySearchProps,
        ellipsis: true
      },
      {
        dataIndex: "organization",
        key: "organization",
        title: t(lg.additionalServicesReview.pendingInquiries.table.organization),
        render: organization => <span className="text-secondary">{organization}</span>,
        ...organizationSearchProps,
        ellipsis: true
      }
    ],
    [t, createdBySearchProps, organizationSearchProps, formatDate]
  );

  const handleOnChange: PendingInquiriesTablePropType["onChange"] = useCallback(
    (pagination, filter, sorter, extra) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);

      setInquiryNumberFilter(filter.inquiryName ? filter.inquiryName[0] : "");
      setOrganizationFilter(filter.organization ? filter.organization[0] : "");
      setRequestedByFilter(filter.requestedBy ? filter.requestedBy[0] : "");
    },
    [setPage]
  );

  const hasFilters = inquiryNumberFilter || organizationFilter || requestedByFilter;

  /* init */
  if (loading && !dataInquiries) return <SimpleLoader />;
  /* no data */
  if (tableData.length === 0 && !hasFilters)
    return (
      <p className="text-center whitespace-pre-line text-base text-blue-700">
        {t(lg.additionalServicesReview.pendingInquiries.noData)}
      </p>
    );

  return (
    <Table<PendingInquiriesTableItem>
      dataSource={tableData}
      columns={tableColumns}
      loading={loading}
      rowKey={"id"}
      onChange={handleOnChange}
      pagination={pagination}
    />
  );
};
