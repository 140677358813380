import { PropsWithChildren } from "react";
import clsx from "clsx";
import { CustomIcon, CustomIconType } from "components/icons/CustomIcon";
import { Tooltip } from "antd";
import { TypeIndicatorProps } from "components/Indicators/Type/index";

type Props = TypeIndicatorProps & {
  tooltipTitle?: string;
  icon: CustomIconType;
};

export const TypeIndicator = ({
  label,
  labelStyle = "text-secondary",
  hideLabel = false,
  hideIcon = false,
  tooltipTitle,
  icon,
  children,
  className
}: PropsWithChildren<Props>) => {
  return (
    <div className={clsx("inline-flex", children ? "items-start" : "items-center", className)}>
      {!hideIcon && (
        <Tooltip title={tooltipTitle} placement="topLeft">
          <div>
            <CustomIcon
              type={icon}
              // fix parent height
              className="float-left"
            />
          </div>
        </Tooltip>
      )}

      {!hideLabel && (
        <div className={clsx(hideIcon ? null : "ml-2")}>
          <div className={labelStyle}>{label}</div>
          {children}
        </div>
      )}
    </div>
  );
};
