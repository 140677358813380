import { lg } from "assets/translations";

import { ComponentPropsWithoutRef, useMemo } from "react";
import { useOutputExtensionsForTaskThreeDOperatorQuery } from "api";
import { useTranslation } from "react-i18next";
import { Section } from "components/Section";
import { getLocaleInquiryTopic, getUserFullName } from "helpers";
import { OutputExtensionEditable } from "./OutputExtensionEditable";
import { OutputExtensionReadonly } from "./OutputExtensionReadonly";

type OutputExtensionProps = ComponentPropsWithoutRef<typeof OutputExtensionEditable>;

/*
---------------------------------------------------------------------
OUTPUT EXTENSIONS
---------------------------------------------------------------------
 */
type OutputExtensionsProps = {
  documentationRecordId: string;
  organizationId: string;
  disabled?: boolean;
  readonly?: boolean;
  className?: string;
  onSuccess?: () => void;
};

/** OutputExtensions fetches a data itself. */
// TODO: possible improvement: add loading indicator
export const OutputExtensions = ({
  documentationRecordId,
  organizationId,
  disabled,
  readonly,
  className,
  onSuccess
}: OutputExtensionsProps) => {
  const { t, i18n } = useTranslation();

  const { data } = useOutputExtensionsForTaskThreeDOperatorQuery({
    variables: { id: documentationRecordId }
  });

  /* remove null values */
  const mappedData = useMemo(
    () => data?.documentationRecord?.outputExtensions?.edges.flatMap(({ node }) => (node ? [node] : [])) || [],
    [data?.documentationRecord?.outputExtensions]
  );

  /* map data to OutputExtension component */
  const items: Pick<
    OutputExtensionProps,
    "id" | "name" | "author" | "createdAt" | "authorComment" | "threeDOperatorComment" | "files"
  >[] = useMemo(
    () =>
      mappedData.map(outputExtension => ({
        id: outputExtension.id,
        name: getLocaleInquiryTopic(outputExtension.inquiryTopic, i18n.language).topic,
        author: getUserFullName(outputExtension.inquiry.user),
        createdAt: outputExtension.inquiry.createdAt,
        authorComment: outputExtension.inquiry.userComment,
        threeDOperatorComment: outputExtension.comment,
        files: outputExtension.files.edges.flatMap(({ node }) => (node ? [node] : []))
      })),
    [i18n.language, mappedData]
  );

  return (
    <Section title={t(lg.taskThreeDOperator.outputExtensions.title)} className={className}>
      <div className={"space-y-20"}>
        {items.map(item =>
          readonly ? (
            <OutputExtensionReadonly key={item.id} {...item} />
          ) : (
            <OutputExtensionEditable
              key={item.id}
              {...item}
              organizationId={organizationId}
              disabled={disabled}
              onSuccess={onSuccess}
            />
          )
        )}
      </div>
    </Section>
  );
};
