import { ComponentPropsWithoutRef, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Section } from "components/Section";
import { FileUpload } from "components/Task/ModelUpload/FileUpload";
import { CoordinateSystemSelect } from "components/Task/ModelUpload/CoordinateSystemSelect";
import { CoordinateSystem, UseCaseEvent } from "api";
import { File } from "types/media";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations";
import { useLogEvent } from "hooks";
import { openInNewTab } from "helpers";

type Props = {
  id?: string;
  organizationId: string;
  documentationRecordId: string;
  modelFile?: File;
  textureFile?: File;
  coordinatesFile?: File;
  coordinateSystem?: CoordinateSystem;
  hasXYZFile: boolean;
  className?: string;
  disabled: boolean;
};

type FileUploadProps = ComponentPropsWithoutRef<typeof FileUpload>;
type FileUploadOnStateChange = Required<FileUploadProps>["onStateChange"];

export const ModelUpload = ({
  id,
  organizationId,
  documentationRecordId,
  modelFile,
  textureFile,
  coordinatesFile,
  coordinateSystem = CoordinateSystem.SJtsk,
  hasXYZFile,
  className,
  disabled
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { logEvent } = useLogEvent();

  const [loading, setLoading] = useState(false);

  const previewDisabled = useMemo(() => !id || loading || !modelFile, [id, modelFile, loading]);
  const changesDisabled = useMemo(() => loading || disabled, [loading, disabled]);

  const handleStateChangeModel: FileUploadOnStateChange = useCallback(loading => setLoading(loading), []);
  const handleStateChangeTexture: FileUploadOnStateChange = useCallback(loading => setLoading(loading), []);
  const handleStateChangeXyz: FileUploadOnStateChange = useCallback(loading => setLoading(loading), []);
  const handleClickViewModel = useCallback(async () => {
    if (!id) return;

    await logEvent(UseCaseEvent.ThreeDModelView);
    openInNewTab(setRouteParams(routes.threeDViewer, { id }));
  }, [id, logEvent]);

  return (
    <Section title={t(lg.taskThreeDOperator.modelUpload.title)} className={className}>
      <div className="c-grid">
        <div className="c-grid-column">
          <FileUpload
            organizationId={organizationId}
            documentationRecordId={documentationRecordId}
            type={"model"}
            file={modelFile}
            disabled={changesDisabled}
            disabledFileRemove={changesDisabled}
            className="mt-10"
            onStateChange={handleStateChangeModel}
          />
          {hasXYZFile && (
            <FileUpload
              organizationId={organizationId}
              documentationRecordId={documentationRecordId}
              type={"coordinates"}
              file={coordinatesFile}
              disabled={changesDisabled}
              disabledFileRemove={changesDisabled}
              className="mt-10"
              onStateChange={handleStateChangeXyz}
            />
          )}
        </div>
        <div className="c-grid-column">
          <FileUpload
            organizationId={organizationId}
            documentationRecordId={documentationRecordId}
            type={"texture"}
            file={textureFile}
            disabled={changesDisabled}
            disabledFileRemove={changesDisabled}
            className="mt-10"
            onStateChange={handleStateChangeTexture}
          />
          {hasXYZFile && (
            <CoordinateSystemSelect
              documentationRecordId={documentationRecordId}
              value={coordinateSystem}
              disabled={changesDisabled}
              className="mt-10"
            />
          )}
        </div>
      </div>

      <div className="text-right mt-10">
        <Button size="middle" icon={<EyeOutlined />} disabled={previewDisabled} onClick={handleClickViewModel}>
          {t(lg.taskThreeDOperator.modelUpload.buttonViewModel)}
        </Button>
      </div>
    </Section>
  );
};
