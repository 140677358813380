import { useMemo } from "react";
import { DocumentationRecordsQueryVariables, resolveError, useDocumentationRecordsQuery, useTasksQuery } from "api";
import { useAlert } from "hooks/useAlert";
import { useMe } from "hooks/useMe";
import { isManager, isReader, isThreeDOperator } from "helpers";

type Args = {
  skip?: DocumentationRecordsQueryVariables["skip"];
  orderBy?: DocumentationRecordsQueryVariables["orderBy"];
  name?: DocumentationRecordsQueryVariables["name"];
  constructionSiteName?: DocumentationRecordsQueryVariables["constructionSiteName"];
  types?: DocumentationRecordsQueryVariables["types"];
  videoTypes?: DocumentationRecordsQueryVariables["videoTypes"];
  states?: DocumentationRecordsQueryVariables["states"];
};
export const useDocumentationRecords = ({
  skip = 0,
  orderBy = [],
  name = "",
  constructionSiteName = "",
  types = [],
  videoTypes = [],
  states = []
}: Args = {}) => {
  const { showAlert } = useAlert();

  /** DATA */
  const { me, organization, loading: loadingMe } = useMe();

  const showOrganizationsDocumentationRecords = useMemo(() => isManager(me) || isReader(me), [me]);

  const { data: dataDocumentationRecords, loading: loadingDocumentationRecords } = useDocumentationRecordsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: organization.id,
      skip: skip,
      orderBy: orderBy,
      name: name,
      constructionSiteName: constructionSiteName,
      types: types,
      videoTypes: videoTypes,
      states: states
    },
    skip: loadingMe || !organization.id || !showOrganizationsDocumentationRecords,
    onError: e => resolveError(e, undefined, showAlert)
  });
  const { data: dataTasks, loading: loadingTasks } = useTasksQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      skip: skip,
      orderBy: orderBy,
      name: name,
      constructionSiteName: constructionSiteName,
      types: types,
      videoTypes: videoTypes,
      state: states,
      toProcess: false,
      threeDOperatorAssigned: isThreeDOperator(me) ? true : undefined
    },
    skip: loadingMe || showOrganizationsDocumentationRecords,
    onError: e => resolveError(e, undefined, showAlert)
  });

  const loading = useMemo(
    () => loadingMe || loadingDocumentationRecords || loadingTasks,
    [loadingMe, loadingDocumentationRecords, loadingTasks]
  );

  const documentationRecords = useMemo(
    () =>
      showOrganizationsDocumentationRecords ? dataDocumentationRecords?.organization?.tasks : dataTasks?.me?.tasks,
    [showOrganizationsDocumentationRecords, dataDocumentationRecords, dataTasks]
  );

  return {
    loading,
    data: {
      documentationRecords,
      totalCount: showOrganizationsDocumentationRecords
        ? dataDocumentationRecords?.organization?.tasks.totalCount
        : dataTasks?.me?.tasks.totalCount
    }
  };
};
