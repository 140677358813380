import { useEffect, useRef } from "react";
import { useLocation } from "react-use";

/*
 * Modified version of https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md solution
 * reach-router issue: https://github.com/reach/router/issues/242
 * */
export const useScrollToTop = () => {
  const refScrollToTop = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const { current: element } = refScrollToTop;

    element && (element.scrollTop = 0);
  }, [pathname]);

  return {
    refScrollToTop
  };
};
