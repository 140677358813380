import { ComponentPropsWithoutRef, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { Section } from "components/Section";
import { useConfig } from "hooks";
import { lg } from "assets/translations";
import { AddStandardGroundControlPoints } from "./AddStandardGroundControlPoints";
import { AddSimpleGroundControlPoints } from "./AddSimpleGroundControlPoints";

export * from "./GroundControlPointsOverview";
export type { SimpleGroundControlPoint } from "./SimpleGroundControlPointsOverview";
export type { GroundControlPoint } from "./StandardGroundControlPointsOverview";

enum AddGroundControlPointVariant {
  Standard = "standard",
  Simple = "simple"
}

type AddSimpleGroundControlPointsProps = ComponentPropsWithoutRef<typeof AddSimpleGroundControlPoints>;
type AddStandardGroundControlPointsProps = ComponentPropsWithoutRef<typeof AddStandardGroundControlPoints>;

type Props = Pick<AddSimpleGroundControlPointsProps, "simpleGroundControlPoint" | "simpleGroundControlPointCount"> &
  Pick<AddStandardGroundControlPointsProps, "constructionObjectCoords" | "constructionSiteCoords" | "savedPoints"> & {
    organizationId: string;
    documentationRecordId: string;
    className?: string;
  };

export const GroundControlPoints = ({
  className,
  simpleGroundControlPointCount,
  simpleGroundControlPoint,
  organizationId,
  documentationRecordId,
  savedPoints,
  constructionSiteCoords,
  constructionObjectCoords
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const {
    config: { minimalGroundControlPoints }
  } = useConfig();

  const [variant, setVariant] = useState<AddGroundControlPointVariant>();

  const hasStandardPoints = !!savedPoints.length;
  const hasSimplePoint = !!simpleGroundControlPoint;

  useEffect(() => {
    setVariant(prevVariant => {
      if (hasSimplePoint && !hasStandardPoints) return AddGroundControlPointVariant.Simple;
      if (!hasSimplePoint && hasStandardPoints) return AddGroundControlPointVariant.Standard;
      return undefined;
    });
  }, [hasSimplePoint, hasStandardPoints]);

  const resetVariant = useCallback(() => setVariant(undefined), []);

  if (minimalGroundControlPoints === undefined) return null;

  return (
    <Section title={t(lg.taskOperator.groundControlPoints.title)} className={className}>
      {!variant && (
        <>
          <p className="text-xs text-gray-800 text-center whitespace-pre-line mt-10">
            {t(lg.taskOperator.groundControlPointsVariantSelector.description)}
          </p>

          <div className="space-x-10 text-center mt-10">
            <Button size={"large"} onClick={() => setVariant(AddGroundControlPointVariant.Simple)}>
              {t(lg.taskOperator.groundControlPointsVariantSelector.buttonSimplifiedVariant)}
            </Button>
            <Button size={"large"} type={"primary"} onClick={() => setVariant(AddGroundControlPointVariant.Standard)}>
              {t(lg.taskOperator.groundControlPointsVariantSelector.buttonStandardVariant)}
            </Button>
          </div>
        </>
      )}

      {variant === AddGroundControlPointVariant.Simple && (
        <AddSimpleGroundControlPoints
          organizationId={organizationId}
          documentationRecordId={documentationRecordId}
          minimalPoints={minimalGroundControlPoints}
          simpleGroundControlPointCount={simpleGroundControlPointCount}
          simpleGroundControlPoint={simpleGroundControlPoint}
          onCancel={resetVariant}
        />
      )}
      {variant === AddGroundControlPointVariant.Standard && (
        <AddStandardGroundControlPoints
          organizationId={organizationId}
          documentationRecordId={documentationRecordId}
          minimalPoints={minimalGroundControlPoints}
          savedPoints={savedPoints}
          constructionSiteCoords={constructionSiteCoords}
          constructionObjectCoords={constructionObjectCoords}
        />
      )}
    </Section>
  );
};
