import { useCallback, useMemo } from "react";
import { PurchaseClaimInput, resolveError, usePurchaseMutation } from "api";
import { useTranslation } from "react-i18next";
import { useAlert } from "hooks";
import { message } from "antd";
import { lg } from "assets/translations";

export const usePurchaseClaim = () => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const [claim, { loading: loadingPurchaseMutation }] = usePurchaseMutation();

  const loading = useMemo(() => loadingPurchaseMutation, [loadingPurchaseMutation]);

  const resolveErrorHandlers = useMemo(() => {
    return {};
  }, []);

  /* Handlers - API */
  const handleSuccess = useCallback(() => message.info(t(lg.purchases.messages.accept)), [t]);

  const handleErrors = useCallback(
    (e: any) => resolveError(e, resolveErrorHandlers, showAlert),
    [resolveErrorHandlers, showAlert]
  );

  /* API */
  const claimPurchase = useCallback(
    async (input: PurchaseClaimInput): Promise<boolean> => {
      try {
        await claim({
          variables: {
            input
          }
        });
        handleSuccess();
        return true;
      } catch (e) {
        handleErrors(e);
        return false;
      }
    },
    [claim, handleSuccess, handleErrors]
  );

  return {
    loading,
    claimPurchase
  };
};
