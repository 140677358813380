import { PropsWithChildren } from "react";
import { Spin } from "antd";
import { usePrefetchQuery } from "api";

export const ApiPrefetch = ({ children }: PropsWithChildren<{}>) => {
  const { loading } = usePrefetchQuery();

  return loading ? (
    <div className="flex items-center justify-center w-full min-h-screen bg-blue-100">
      <Spin />
    </div>
  ) : (
    <>{children}</>
  );
};
