import { PropsWithChildren, useCallback } from "react";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { ConstructionSiteState } from "api";

type Props = {
  visible: boolean;
  constructionSiteState?: ConstructionSiteState;
  onChange: (updateConstructionObject: boolean) => void;
};

export const StateModalChange = ({ visible, constructionSiteState, onChange }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const handleOk = useCallback(() => {
    onChange(true);
  }, [onChange]);

  const handleCancel = useCallback(() => {
    onChange(false);
  }, [onChange]);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <InfoCircleOutlined className="text-lg text-blue-700" />
          <span className="ml-4">{t(lg.constructionSite.pageEdit.modals.stateChange.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.constructionSite.pageEdit.modals.stateChange.buttonOkText)}
      cancelText={t(lg.constructionSite.pageEdit.modals.stateChange.buttonCancelText)}
      okButtonProps={{ type: "primary" }}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
    >
      <div>
        {constructionSiteState === ConstructionSiteState.Inactive
          ? t(lg.constructionSite.pageEdit.modals.stateChange.descriptionSetInactive)
          : t(lg.constructionSite.pageEdit.modals.stateChange.descriptionSetActive)}
      </div>
    </Modal>
  );
};
