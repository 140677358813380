import { ChangeEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Coords, Props as GoogleMapReactProps } from "google-map-react";
import { useDebounce, useMeasure } from "react-use";
import {
  calculateMapHeight,
  handleApiLoaded,
  parseCoords,
  recalculateMapCenter,
  roundCoord,
  updatePolygon
} from "./helpers";

export type EditType = "site" | "object" | "groundControlPoints";

export const DEFAULT_SEARCH_ZOOM: number = 15;

export const useMap = (
  constructionSite: Coords[],
  constructionObject: Coords[],
  editType: EditType,
  hasSearch: boolean = false,
  searchZoom: number = DEFAULT_SEARCH_ZOOM
) => {
  const [points, setPoints] = useState(editType === "site" ? constructionSite : constructionObject);

  const [isEditMode, enableEditMode] = useState(points.length > 0);
  const [isMapDraggable, setIsMapDraggable] = useState(true);
  const [newPointValue, setNewPointValue] = useState("");
  const [inputValues, setInputValues] = useState(points.map(({ lat, lng }) => `${lat}, ${lng}`));
  const [history, setHistory] = useState([inputValues]);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [initPoints] = useState<Coords[]>(editType === "site" ? constructionSite : constructionObject);
  const [mapHeight, setMapHeight] = useState(0);

  const mapRef = useRef<any>();
  const mapsRef = useRef<any>();
  const constructionSitePolygonRef = useRef<any>();
  const constructionObjectPolygonRef = useRef<any>();
  const searchInputRef = useRef<any>();
  const searchBoxRef = useRef<any>();

  /** Get map width - width is 100% of parent container - we need width to calc height by ratio */
  const [mapContainerRef, { width: mapContainerWidth }] = useMeasure<HTMLDivElement>();
  useEffect(() => setMapHeight(calculateMapHeight(mapContainerWidth)), [mapContainerWidth]);

  /** readable helper **/
  const canRecalculate = useMemo<boolean>(() => isMapDraggable, [isMapDraggable]);
  const canRecenter = useMemo<boolean>(() => isMapDraggable && !isTouched, [isMapDraggable, isTouched]);

  /** Search result select handler */
  const handleSearch = useCallback(() => {
    const places = searchBoxRef.current?.getPlaces();

    if (!places || !places.length) return;

    const lat: number | undefined = places?.[0]?.geometry?.location?.lat();
    const lng: number | undefined = places?.[0]?.geometry?.location?.lng();

    if (!lat || !lng) return;

    mapRef.current.setCenter({ lat, lng });
    mapRef.current.setZoom(searchZoom);
  }, [searchZoom]);

  /** Set Google map instances to refs for later use */
  const handleGoogleApiLoaded: Required<GoogleMapReactProps>["onGoogleApiLoaded"] = useCallback(
    ({ map, maps }) => {
      const { constructionSitePolygon, constructionObjectPolygon } = handleApiLoaded(
        map,
        maps,
        constructionSite,
        constructionObject
      );
      mapRef.current = map;
      mapsRef.current = maps;
      constructionSitePolygonRef.current = constructionSitePolygon;
      constructionObjectPolygonRef.current = constructionObjectPolygon;

      /** Set search if enabled */
      if (hasSearch && maps.places?.SearchBox) {
        searchBoxRef.current = new maps.places.SearchBox(searchInputRef.current);
        map.controls[maps.ControlPosition.TOP_RIGHT].push(searchInputRef.current);
        searchBoxRef.current.addListener("places_changed", handleSearch);
      }
    },
    [constructionSite, constructionObject, hasSearch, handleSearch]
  );

  /** Handle entering edit mode */
  const handleClickSetPoints = useCallback(() => {
    return enableEditMode(true);
  }, []);

  /** Handle new point input change - if valid coords, set as new point */
  const handleChangeNewPoint = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      setNewPointValue(value);
      const coords = parseCoords(value);
      if (coords) {
        setPoints([...points, { ...coords }]);
        setInputValues([...inputValues, value]);
        setNewPointValue("");
      }
    },
    [points, inputValues]
  );

  /** Point input handlers */
  const handleChangePoint = useCallback(
    (e: ChangeEvent<HTMLInputElement>, i: number) => {
      const newInputValues = [...inputValues];
      newInputValues[i] = e.currentTarget.value;
      setInputValues(newInputValues);
    },
    [inputValues]
  );
  const handleClickRemovePoint = useCallback(
    (index: number) => setInputValues(inputValues.filter((v, i) => i !== index)),
    [inputValues]
  );

  /** Additional controls handlers */
  const handleClickRemoveAllPoints = useCallback(() => setInputValues([]), []);
  const handleClickBack = useCallback(() => {
    const newHistory = [...history];
    newHistory.pop();
    const lastEntry = newHistory.pop();
    if (!lastEntry) return;
    setInputValues(lastEntry);
    setHistory(newHistory);
  }, [history]);

  /** Handle adding new point via clicking to map */
  const handleClickMap: Required<GoogleMapReactProps>["onClick"] = useCallback(
    ({ lat, lng }) => {
      if (!isEditMode) return;
      setPoints([...points, { lat: roundCoord(lat), lng: roundCoord(lng) }]);
      setInputValues([...inputValues, `${roundCoord(lat)}, ${roundCoord(lng)}`]);
    },
    [isEditMode, points, inputValues]
  );

  /** Handle points drag position change */
  const handleChildMouseDown: Required<GoogleMapReactProps>["onChildMouseDown"] = useCallback(
    () => setIsMapDraggable(false),
    []
  );
  const handleChildMouseUp: Required<GoogleMapReactProps>["onChildMouseUp"] = useCallback(
    () => setIsMapDraggable(true),
    []
  );
  const handleChildMouseMove: Required<GoogleMapReactProps>["onChildMouseMove"] = useCallback(
    (key, { value }, { lat, lng }) => {
      if (!lat || !lng) return;
      const newInputValues = [...inputValues];
      const newPoints = [...points];
      newInputValues[value - 1] = `${roundCoord(lat)}, ${roundCoord(lng)}`;
      newPoints[value - 1] = { lat: roundCoord(lat), lng: roundCoord(lng) };
      setInputValues(newInputValues);
      setPoints(newPoints);
    },
    [inputValues, points]
  );

  const recalculateMap = useCallback(() => recalculateMapCenter(mapRef.current, mapsRef.current, points), [points]);

  /** Redraw points on input value change */
  useDebounce(() => canRecalculate && setPoints(inputValues.map((value, i) => parseCoords(value) || points[i])), 500, [
    inputValues,
    canRecalculate
  ]);

  /** Handle construction site and map size change */
  useDebounce(
    () => {
      // groundControlPoints is not editable
      if (editType === "groundControlPoints") return;

      updatePolygon(
        editType === "site" ? constructionSitePolygonRef.current : constructionObjectPolygonRef.current,
        points
      );

      if (canRecenter) recalculateMap();
    },
    10,
    [points, mapContainerWidth, canRecenter]
  );

  /** Set entry to history */
  useDebounce(() => setHistory([...history, inputValues]), 500, [inputValues]);

  /* check if points were touched */
  useEffect(() => {
    if (JSON.stringify(points) !== JSON.stringify(initPoints)) {
      setIsTouched(true);
    }
  }, [initPoints, points]);

  /** recenter map after window resize */
  useLayoutEffect(() => {
    window.addEventListener("resize", recalculateMap);
    return () => window.removeEventListener("resize", recalculateMap);
  }, [points, recalculateMap]);

  return {
    mapContainerRef,
    searchInputRef,
    mapHeight,
    isEditMode,
    isMapDraggable,
    canRecalculate,
    newPointValue,
    inputValues,
    points,
    history,
    isTouched,
    handleGoogleApiLoaded,
    handleClickMap,
    handleChildMouseDown,
    handleChildMouseUp,
    handleChildMouseMove,
    handleClickSetPoints,
    handleChangeNewPoint,
    handleChangePoint,
    handleClickRemovePoint,
    handleClickRemoveAllPoints,
    handleClickBack
  };
};
