import { useCallback, useMemo } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowLeftOutlined, DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import { ProgressSteps } from "components/DocumentationRecord/ProgressSteps";
import { Content } from "components/LayoutDashboard";
import {
  GeneralInfo,
  GeodeticSurveyAdditionalUpload,
  GeodeticSurveyUpload,
  GroundControlPoint,
  GroundControlPointsOverview,
  ReturnInfo,
  SimpleGroundControlPoint
} from "components/Task";
import * as Actions from "components/DocumentationRecord/Actions";
import { Comments } from "components/DocumentationRecordDetail/Comments";
import { Details } from "components/DocumentationRecordDetail/Details";
import { MapOverview } from "components/ConstructionObject/MapOverview";
import { SubheaderOptions, useFormat, useLogEvent, useMe, useSubheader, useTask, useZip } from "hooks";
import { routes } from "routes";
import { lg } from "assets/translations";
import { DocumentationRecordState, UseCaseEvent } from "api";
import { documentationRecordStates } from "api/enums";
import { getCoords } from "helpers";

/**
 * Documentation Record process page for accountable Surveyor.
 */
export const TaskSurveyor = ({ id }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing documentation record ID.");

  const { t } = useTranslation();
  const { arrayToCoords } = useFormat();

  const { data, handlers, loading } = useTask(id);
  const { downloadFiles, loading: loadingZip } = useZip();
  const { logEvent } = useLogEvent();
  const { me } = useMe();

  const taskAccountableSurveyor = useMemo(() => data.record?.accountableSurveyor, [data.record]);
  const taskRequireSurveyor = useMemo(
    () => data.record?.state === DocumentationRecordState.RequiresSurveyor,
    [data.record]
  );
  const amIAccountableSurveyor = useMemo(() => taskAccountableSurveyor?.id === me.id, [taskAccountableSurveyor, me]);

  const canMakeAnyActionWithTask = useMemo(
    () => taskRequireSurveyor && amIAccountableSurveyor,
    [taskRequireSurveyor, amIAccountableSurveyor]
  );

  const groundControlPoints = useMemo<GroundControlPoint[]>(
    () =>
      data.groundControlPoints.map(point => ({
        id: point.id,
        order: point.order,
        coords: getCoords(point.location || []),
        description: point.description || "",
        photos: point.files.edges.map(({ node }) => ({
          id: node.id,
          url: node.url || "",
          name: node.fileName || ""
        }))
      })),
    [data.groundControlPoints]
  );

  const simpleGroundControlPoint = useMemo<SimpleGroundControlPoint | undefined>(
    () =>
      data.simpleGroundControlPoint
        ? {
            id: data.simpleGroundControlPoint.id,
            order: 1,
            description: data.simpleGroundControlPoint.description || "",
            photos: data.simpleGroundControlPoint.files.edges.map(({ node }) => ({
              id: node.id,
              url: node.url || "",
              name: node.fileName || ""
            }))
          }
        : undefined,
    [data.simpleGroundControlPoint]
  );

  const handleClickBack = useCallback(() => navigate(routes.todo), []);

  const { record, site, object, triggers, organization, geodeticSurvey, geodeticSurveyAdditional } = data;

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const recordName = record?.name || "...";
    const recordState = record?.state ? t(documentationRecordStates[record.state].taskNameI18nKey) : "...";

    return {
      heading: `${recordName}: ${recordState}`,
      breadcrumb: [{ value: t(lg.todo.header.title), route: routes.todo }, { value: recordName }],
      buttons: [
        <Actions.MainAction
          type={"taskSurveyor"}
          documentationRecordId={id}
          triggers={triggers}
          onAfterTrigger={handlers.handleOnAfterTrigger}
        />
      ]
    };
  }, [id, record, triggers, handlers.handleOnAfterTrigger, t]);
  useSubheader(subheaderOptions);

  const constructionSiteCoords = arrayToCoords(site?.location || []);
  const constructionObjectCoords = arrayToCoords(object?.location || []);

  const handleClickDownload = useCallback(async () => {
    await logEvent(UseCaseEvent.FileDownload);

    return downloadFiles(
      record?.name || t(lg.zipDownload.defaultFileName),
      groundControlPoints,
      simpleGroundControlPoint
    );
  }, [groundControlPoints, simpleGroundControlPoint, record, downloadFiles, t, logEvent]);

  const handleClickPrint = useCallback(() => window.print(), []);

  return (
    <>
      <Content loading={!record && loading} flexGrow={false} className="print_hidden print-old_hidden">
        {record && site && (
          <ProgressSteps
            documentationRecordType={record.type}
            documentationRecordState={record.state}
            autoRequestThreeD={site.autoRequestThreeD}
          />
        )}
      </Content>

      <Content loading={!record && loading} className="print_p-0">
        {record && site && object && organization && (
          <>
            <div className="flex justify-between w-full flex-wrap print_hidden">
              <Button size="large" type="default" icon={<ArrowLeftOutlined />} onClick={handleClickBack}>
                {t(lg.taskSurveyor.buttonBack)}
              </Button>

              <div className="flex flex-col items-end">
                <ReturnInfo
                  lastStateChange={record.lastStateChange || undefined}
                  accountableUsers={{
                    managerId: record.accountableManager?.id || "",
                    operatorId: record.accountableOperator?.id || "",
                    surveyorId: record.accountableSurveyor?.id || "",
                    threeDOperatorId: record.accountableThreeDOperator?.id || ""
                  }}
                  className="mb-2"
                />

                <Actions.ValidationMessages
                  triggers={data.triggers}
                  organizationName={organization?.name}
                  className="mb-2"
                />

                <div className="flex justify-end">
                  <Button
                    size="middle"
                    type="default"
                    icon={<PrinterOutlined />}
                    className="mr-5"
                    onClick={handleClickPrint}
                  >
                    {t(lg.taskSurveyor.buttonPrint)}
                  </Button>
                  <Button
                    size="middle"
                    type="default"
                    icon={<DownloadOutlined />}
                    loading={loadingZip}
                    onClick={handleClickDownload}
                    disabled={!canMakeAnyActionWithTask}
                  >
                    {!loadingZip ? t(lg.taskSurveyor.buttonDownload) : t(lg.zipDownload.processing)}
                  </Button>
                </div>
              </div>
            </div>

            <div className="text-xl font-medium hidden print_block">
              {t(lg.taskSurveyor.titlePrint, { constructionObject: record?.name })}
            </div>

            <GeneralInfo documentationRecordId={id} hasDocumentationType className="mt-10 print_mt-2" />

            <Comments
              items={data.comments}
              accountableUsers={{
                managerId: record.accountableManager?.id,
                operatorId: record.accountableOperator?.id,
                surveyorId: record.accountableSurveyor?.id,
                threeDOperatorId: record.accountableThreeDOperator?.id
              }}
              className="hidden print_block print_mt-5"
            />

            <MapOverview
              constructionSiteCoords={constructionSiteCoords}
              constructionObjectCoords={constructionObjectCoords}
              className="mt-16 print_mt-5 print_no-break"
            />

            <GroundControlPointsOverview
              constructionSiteCoords={constructionSiteCoords}
              constructionObjectCoords={constructionObjectCoords}
              groundControlPoints={groundControlPoints}
              simpleGroundControlPointCount={data.simpleGroundControlPointCount}
              simpleGroundControlPoint={simpleGroundControlPoint}
              className="mt-16 print_mt-5 print_break-before"
            />

            <Comments
              items={data.comments}
              accountableUsers={{
                managerId: record.accountableManager?.id,
                operatorId: record.accountableOperator?.id,
                surveyorId: record.accountableSurveyor?.id,
                threeDOperatorId: record.accountableThreeDOperator?.id
              }}
              className="mt-16 print_hidden"
            />

            <GeodeticSurveyUpload
              organizationId={organization.id}
              documentationRecordId={record.id}
              geodeticSurvey={geodeticSurvey}
              className="mt-16 print_hidden"
              disabled={!canMakeAnyActionWithTask}
            />

            <GeodeticSurveyAdditionalUpload
              organizationId={organization.id}
              documentationRecordId={record.id}
              files={geodeticSurveyAdditional}
              className="mt-16 print_hidden"
              disabled={!canMakeAnyActionWithTask}
            />

            <Details
              createdAt={data.firstVersion?.createdAt}
              author={data.firstVersion?.user || undefined}
              lastEditedAt={data.currentVersion?.createdAt}
              lastEditedBy={data.currentVersion?.user || undefined}
              className="mt-16 print_hidden"
            />
          </>
        )}
      </Content>

      {canMakeAnyActionWithTask && (
        <Actions.ActionsSection
          documentationRecordId={id}
          triggers={data.triggers}
          onAfterTrigger={handlers.handleOnAfterTrigger}
          className="print_hidden"
        />
      )}
    </>
  );
};
