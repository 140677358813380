import { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { useColumnSearchProps, usePagination } from "helpers/serverSideTableFilter";
import { useTranslation } from "react-i18next";
import {
  DocumentationRecordOrderByEnum,
  DocumentationRecordStateFilterEnum,
  DocumentationRecordVideoTypeFilterEnum,
  TasksQuery,
  useTasksQuery
} from "api";
import { lg } from "assets/translations";
import { enumToValues, getThreeDModelType, modelTypeToVideoTypeFilterEnum, TableProps } from "helpers";
import { Link } from "components/Link";
import { StateIndicatorDocumentationRecord } from "components/Indicators/State";
import { routes, setRouteParams } from "routes";
import { TypeIndicatorThreeDModel } from "components/Indicators/Type";
import { documentationRecordStateFilters, ThreeDModelType, threeDModelTypes } from "api/enums";
import { useFormat, useTableDataStatus } from "hooks";
import { SimpleLoader } from "components/SimpleLoader";

type TableItem = NonNullable<TasksQuery["me"]>["tasks"]["edges"][0]["node"] & {
  threeDModelType?: ThreeDModelType;
  organizationName: string;
  lastChange: string;
};
type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: DocumentationRecordOrderByEnum[] } } = {
  name: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  organizationName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteOrganizationName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteOrganizationNameDesc]
  },
  lastChange: {
    ascend: [DocumentationRecordOrderByEnum.LastModified],
    descend: [DocumentationRecordOrderByEnum.LastModifiedDesc]
  }
};

const defaultStateFilter = [DocumentationRecordStateFilterEnum.RequiresThreeDOperator];
export const ThreeDOperatorInProgressTable = () => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState<DocumentationRecordOrderByEnum[]>(orderByMap.lastChange.descend);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const [nameFilter, setNameFilter] = useState<string>("");
  const [stateFilter, setStateFilter] = useState<DocumentationRecordStateFilterEnum[]>(defaultStateFilter);
  const [videoTypesFilter, setVideoTypesFilter] = useState<DocumentationRecordVideoTypeFilterEnum[]>([]);
  const nameSearchProps = useColumnSearchProps();
  const [organizationNameFilter, setOrganizationNameFilter] = useState("");
  const organizationNameSearchProps = useColumnSearchProps();
  const { getFromNow } = useFormat();

  const { data, loading } = useTasksQuery({
    variables: {
      skip: skip,
      orderBy: orderBy,
      name: nameFilter,
      organizationName: organizationNameFilter,
      state: stateFilter,
      threeDOperatorAssigned: true,
      videoTypes: videoTypesFilter
    },
    fetchPolicy: "cache-and-network"
  });
  useEffect(() => setTotal(data?.me?.tasks.totalCount), [data]);

  const tableData = useMemo<TableItem[]>(
    () =>
      data?.me?.tasks.edges
        .map(e => e.node)
        .map(item => ({
          ...item,
          organizationName: item.constructionObject.constructionSite.organization.name,
          threeDModelType: getThreeDModelType(item),
          lastChange: item.lastStateChange?.createdAt
        })) || [],
    [data?.me?.tasks.edges]
  );

  const columns = useMemo(
    (): TablePropType["columns"] => [
      {
        dataIndex: "name",
        key: "name",
        title: t(lg.todoThreeDOperator.todosInProgress.table.header.documentation),
        render: (name, { id }) => <Link href={setRouteParams(routes.taskThreeDOperator, { id })}>{name}</Link>,
        sorter: true,
        ...nameSearchProps,
        ellipsis: true
      },
      {
        dataIndex: "organizationName",
        key: "organizationName",
        title: t(lg.typenames.submitter),
        sorter: true,
        ...organizationNameSearchProps,
        render: submitter => <span className="text-secondary">{submitter}</span>
      },
      {
        dataIndex: "threeDModelType",
        key: "threeDModelType",
        title: t(lg.todoThreeDOperator.todosInProgress.table.header.threeDModelType),
        filters: enumToValues<ThreeDModelType>(ThreeDModelType).map(type => ({
          text: t(threeDModelTypes[type].nameI18nKey),
          value: type
        })),
        render: threeDModelType => (threeDModelType ? <TypeIndicatorThreeDModel type={threeDModelType} /> : null)
      },
      {
        dataIndex: "lastChange",
        key: "lastChange",
        title: t(lg.todoThreeDOperator.todosInProgress.table.header.receivedBefore),
        sorter: true,
        defaultSortOrder: "descend",
        render: lastChange => <span className="text-secondary">{lastChange ? getFromNow(lastChange) : "-"}</span>
      },
      {
        dataIndex: "state",
        key: "state",
        title: t(lg.todoThreeDOperator.todosInProgress.table.header.treatmentStatus),
        filters: enumToValues<DocumentationRecordStateFilterEnum>(DocumentationRecordStateFilterEnum)
          .filter(s => s !== DocumentationRecordStateFilterEnum.Archived)
          .map(type => ({
            text: t(documentationRecordStateFilters[type].nameI18nKey),
            value: type
          })),
        defaultFilteredValue: defaultStateFilter?.length ? defaultStateFilter : undefined,
        render: state => <StateIndicatorDocumentationRecord state={state} />
      }
    ],
    [t, getFromNow, nameSearchProps, organizationNameSearchProps]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setNameFilter(filter.name ? filter.name[0] : "");
      setVideoTypesFilter(filter.threeDModelType?.map(modelTypeToVideoTypeFilterEnum) || []);
      setStateFilter(filter.state || []);
      setOrganizationNameFilter(filter.organizationName ? filter.organizationName[0] : "");
    },
    [setPage]
  );

  const { hasData, hasFilters } = useTableDataStatus(tableData.length, [
    nameFilter,
    videoTypesFilter,
    stateFilter,
    organizationNameFilter
  ]);

  if (loading && !hasFilters && !hasData)
    return (
      <div className="flex justify-center w-full p-10">
        <SimpleLoader />
      </div>
    );

  if (!hasFilters && !hasData) {
    return (
      <div className="text-base text-blue-700 text-center whitespace-pre-line">
        {t(lg.todoThreeDOperator.todosInProgress.table.noData)}
      </div>
    );
  }

  return (
    <Table<TableItem>
      dataSource={tableData}
      columns={columns}
      rowKey={"id"}
      loading={loading}
      showSorterTooltip={false}
      onChange={handleOnChange}
      pagination={pagination}
      scroll={{ x: true }}
    />
  );
};
