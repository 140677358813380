import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import clsx from "clsx";
import { CloseOutlined, DownloadOutlined, FileTextOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Lightbox } from "./Lightbox";
import { Link } from "./Link";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";
import { UseCaseEvent } from "api";
import { useLogEvent } from "hooks";
import { getMediaType, MediaType } from "helpers";

type Props = {
  fileName: string;
  fileUrl?: string;
  fileId?: string;
  onDelete?: (fileId?: string) => any;
  onDownload?: () => void;
  className?: string;
};

export const MediaCard = ({ fileName, fileUrl, className, fileId, onDownload, onDelete }: PropsWithChildren<Props>) => {
  const [t] = useTranslation();
  const [visible, setVisible] = useState(false);
  const { logEvent } = useLogEvent();

  const mediaType = useMemo(() => getMediaType(fileName), [fileName]);
  const isImage = useMemo(() => mediaType === MediaType.Image, [mediaType]);
  const isVideo = useMemo(() => mediaType === MediaType.Video, [mediaType]);
  const isOtherFile = useMemo(() => mediaType === MediaType.Other, [mediaType]);

  const allowLightbox = useMemo(() => fileUrl && (isImage || isVideo), [fileUrl, isImage, isVideo]);
  const canBeDownloaded = useMemo(() => fileUrl || onDownload, [fileUrl, onDownload]);

  const handleDownload = useCallback(async () => {
    await logEvent(UseCaseEvent.FileDownload);
    onDownload && onDownload();
  }, [logEvent, onDownload]);

  const handleClickOpenLightbox = useCallback(() => setVisible(true), []);
  const handleClickCloseLightbox = useCallback(() => setVisible(false), []);
  const handleDeleteFile = useCallback(() => onDelete && onDelete(fileId), [onDelete, fileId]);

  return (
    <div className={clsx("flex items-center p-2 border border-gray-500 rounded relative", className)}>
      {onDelete ? (
        <Link className="absolute top-0 right-0" onClick={handleDeleteFile}>
          <CloseOutlined className="p-1 text-gray-700 hover:text-purple-700" />
        </Link>
      ) : null}

      {allowLightbox && fileUrl && (
        <Lightbox fileUrl={fileUrl} title={fileName} visible={visible} onCancel={handleClickCloseLightbox} />
      )}

      {/* Thumbnail */}
      {isImage && fileUrl && (
        <div
          className={clsx(
            "flex justify-center flex-none w-12 h-12 overflow-hidden",
            allowLightbox && fileUrl && "cursor-pointer"
          )}
          onClick={handleClickOpenLightbox}
          title={t(lg.lightbox.title.image)}
        >
          <img
            src={fileUrl}
            /*
             * max-w-none - disable global style
             * transform-z-0 - force hardware acceleration
             */
            className="w-auto h-full max-w-none transform-z-0"
            alt={fileName}
          />
        </div>
      )}
      {isVideo && fileUrl && (
        <div
          className={clsx(
            "flex items-center justify-center flex-shrink-0 w-12 h-12 bg-gray-400",
            allowLightbox && "cursor-pointer"
          )}
          onClick={handleClickOpenLightbox}
          title={t(lg.lightbox.title.video)}
        >
          <VideoCameraOutlined className="text-gray-600 text-lg" />
        </div>
      )}
      {(isOtherFile || !fileUrl) && (
        <div className="flex flex-shrink-0 w-12 h-12 bg-gray-300 justify-center items-center">
          <FileTextOutlined className="text-2xl text-gray-600" />
        </div>
      )}

      {/* Content */}
      {canBeDownloaded && (
        <a
          href={fileUrl}
          rel="noopener noreferrer"
          className="c-link ml-2 truncate"
          title={isVideo ? t(lg.downloads.video) : isOtherFile ? t(lg.downloads.file) : t(lg.downloads.image)}
          onClick={handleDownload}
          download
        >
          <DownloadOutlined className="text-xl align-middle" />
          <span className="ml-1">{fileName}</span>
        </a>
      )}

      {!canBeDownloaded && <span className="ml-2 truncate">{fileName}</span>}
    </div>
  );
};
