import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { DeleteOutlined, DownloadOutlined, EyeOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Section } from "components/Section";
import { TypeIndicatorThreeDModel } from "components/Indicators/Type";
import { Delete } from "components/ThreeDModel/Delete";
import { useDownloadThreeDModel, useFormat, useLogEvent } from "hooks";
import { getMediaTotalSize, hasFilesForDownload, openInNewTab } from "helpers";
import { ThreeDModelFragment, UseCaseEvent } from "api";
import { lg } from "assets/translations";
import { routes, setRouteParams } from "routes";
import { coordinateSystems, ThreeDModelType } from "api/enums";
import { ModalSharingThreeDModel } from "components/ThreeDModel/ModalSharingThreeDModel";
import { ModalDownloadThreeDModel } from "components/ThreeDModel/ModalDownloadThreeDModel";

type Props = {
  isFinished: boolean;
  type?: ThreeDModelType;
  model?: ThreeDModelFragment;
  className?: string;
  onDeleteSuccess?: () => void;
};

export const ThreeDModel = ({ isFinished, type, model, onDeleteSuccess, className }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { formatDate, formatBytes } = useFormat();
  const { logEvent } = useLogEvent();

  const size = useMemo(() => {
    if (!model) return "-";

    return formatBytes(
      getMediaTotalSize(
        [model.modelFile, model.textureFile, model.coordinatesFile].map(file => ({ size: file?.size || null }))
      )
    );
  }, [model, formatBytes]);

  const _hasFilesForDownload = useMemo<boolean>(() => !!model && hasFilesForDownload(model), [model]);
  const { isDisabled: isDownloadDisabled } = useDownloadThreeDModel();
  const canDownloadThreeDModel = useMemo(
    () => !isDownloadDisabled && _hasFilesForDownload,
    [isDownloadDisabled, _hasFilesForDownload]
  );

  const handleClickViewModel = useCallback(async () => {
    if (!model?.id) return;

    await logEvent(UseCaseEvent.ThreeDModelView);
    openInNewTab(setRouteParams(routes.threeDViewer, { id: model.id }));
  }, [model, logEvent]);

  return (
    <Section title={t(lg.documentationRecord.pageDetail.threeDModel.title)} className={className}>
      {(!isFinished || !model) && (
        <div className="flex flex-col items-center mt-10">
          <div className="text-xs text-gray-700 text-center whitespace-pre-line">
            {t(lg.documentationRecord.pageDetail.threeDModel.table.noData)}
          </div>
        </div>
      )}
      {isFinished && model && (
        <div className="mt-10">
          <div className="c-grid">
            <div className="c-grid-column">
              <div>
                <div className="font-medium">{t(lg.documentationRecord.pageDetail.threeDModel.table.header.type)}</div>
                <span className="text-secondary whitespace-nowrap">
                  <TypeIndicatorThreeDModel type={type} />
                </span>
              </div>
              <div className="mt-5">
                <div className="font-medium">
                  {t(lg.documentationRecord.pageDetail.threeDModel.table.header.fileSize)}
                </div>
                <span className="text-secondary whitespace-nowrap">{size}</span>
              </div>
            </div>
            <div className="c-grid-column">
              <div>
                <div className="font-medium">
                  {t(lg.documentationRecord.pageDetail.threeDModel.table.header.createdAt)}
                </div>
                <span className="text-secondary whitespace-nowrap">{formatDate(model.createdAt, "human", true)}</span>
              </div>
              {model.coordinateSystem ? (
                <div className="mt-5">
                  <div className="font-medium">
                    {t(lg.documentationRecord.pageDetail.threeDModel.table.header.coordinatesSystemType)}
                  </div>
                  <span className="text-secondary whitespace-nowrap">
                    {t(coordinateSystems[model.coordinateSystem].nameI18nKey)}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Delete id={model.id} onSuccess={onDeleteSuccess}>
              <Button icon={<DeleteOutlined />} danger type="primary">
                {t(lg.threeDModel.actions.delete)}
              </Button>
            </Delete>
            {canDownloadThreeDModel ? (
              <ModalDownloadThreeDModel modelId={model.id}>
                <Button size="middle" icon={<DownloadOutlined />} className="ml-5">
                  {t(lg.threeDModel.actions.download)}
                </Button>
              </ModalDownloadThreeDModel>
            ) : null}
            <ModalSharingThreeDModel modelId={model.id}>
              <Button size="middle" icon={<ShareAltOutlined />} className="ml-5">
                {t(lg.threeDModel.actions.share)}
              </Button>
            </ModalSharingThreeDModel>
            <Button size="middle" icon={<EyeOutlined />} className="ml-5" onClick={handleClickViewModel}>
              {t(lg.threeDModel.actions.show)}
            </Button>
          </div>
        </div>
      )}
    </Section>
  );
};
