import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { ActionProps } from "components/DocumentationRecord/Actions/index";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import {
  ModalSubmitManagerApproval,
  SubmitManagerApprovalHandler
} from "components/DocumentationRecord/Actions/modals/ModalSubmitManagerApproval";
import { Triggers } from "api";
import { lg } from "assets/translations";
import { documentationRecordTriggers } from "api/enums";

/** For Trigger SUBMIT_MANAGER_APPROVAL */
export const SubmitManagerApproval = ({
  documentationRecordId,
  triggers,
  disabled = false,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {},
  onTriggerError = () => {}
}: ActionProps) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.SubmitManagerApproval,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    onAfterTrigger,
    onTriggerError
  );

  const handleClick = useCallback(() => setModalVisible(true), []);
  const handleCancel = useCallback(() => setModalVisible(false), []);
  const handleSubmit = useCallback<SubmitManagerApprovalHandler>(comment => callTrigger(comment), [callTrigger]);

  if (!available) return null;

  return (
    <>
      <Button type="primary" onClick={handleClick} size="large" disabled={disabled || !conditions || loading}>
        {t(!loading ? documentationRecordTriggers[Triggers.SubmitManagerApproval].buttonI18nKey : lg.saving)}
        {!loading ? <ArrowRightOutlined /> : <LoadingOutlined />}
      </Button>
      <ModalSubmitManagerApproval
        visible={modalVisible}
        loading={loading}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};
