import { PropsWithChildren, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations/lg";
import { Section } from "components/Section";
import { useAlert } from "hooks";
import { resolveError, useConstructionPhaseQuery } from "api";
import { DocumentationRecordsTable } from "components/ConstructionPhase/DocumentationRecordsTable";

export const ConstructionPhaseDetail = ({ id }: PropsWithChildren<RouteComponentProps<{ id: string }>>) => {
  if (!id) throw new Error("Missing phase ID.");

  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { data, loading } = useConstructionPhaseQuery({
    variables: { id: id },
    onError: e => resolveError(e, undefined, showAlert),
    fetchPolicy: "cache-and-network"
  });

  const constructionPhase = useMemo(() => data?.constructionPhase || undefined, [data]);

  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const phaseName = constructionPhase?.name || "...";
    const siteName = constructionPhase?.constructionSite.name || "...";

    return {
      heading: `${t(lg.constructionPhase.header.title)}: ${phaseName}`,
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        {
          value: siteName,
          route: setRouteParams(routes.constructionSiteDetail, { id: constructionPhase?.constructionSite.id || "" })
        },
        { value: phaseName }
      ]
    };
  }, [constructionPhase?.name, constructionPhase?.constructionSite, t]);
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      {constructionPhase?.id && (
        <Section title={t(lg.constructionPhase.documentation.title)}>
          <div className="mt-10">
            <DocumentationRecordsTable constructionPhaseId={constructionPhase.id} />
          </div>
        </Section>
      )}
    </Content>
  );
};
