import { PropsWithChildren, useCallback, useState } from "react";
import { RadioCard } from "../RadioCard";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";

type RadioCardValue = "manually" | "automatically";
export type ControlSettingsChangeHandler = (value: RadioCardValue) => any;

type Props = {
  defaultValue: RadioCardValue;
  disabled?: boolean;
  onChange?: ControlSettingsChangeHandler;
};

export const ControlSettingsRadioCard = ({
  defaultValue,
  disabled = false,
  onChange = () => {}
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<RadioCardValue>(defaultValue);

  const handleClickRadioCard = useCallback(
    (state: RadioCardValue) => {
      onChange(state);
      setValue(state);
    },
    [onChange]
  );

  return (
    <div>
      <div className="c-grid">
        {/* left */}
        <div className="c-grid-column">
          <RadioCard<RadioCardValue>
            title={t(lg.constructionSite.formControlSettings.cardCheckBeforePayment.title)}
            value="manually"
            checked={value === "manually"}
            disabled={disabled}
            onClick={handleClickRadioCard}
          >
            <div>{t(lg.constructionSite.formControlSettings.cardCheckBeforePayment.description)}</div>

            <div className="font-medium mt-5">
              {t(lg.constructionSite.formControlSettings.cardCheckBeforePayment.advantages.title)}
            </div>
            <div>{t(lg.constructionSite.formControlSettings.cardCheckBeforePayment.advantages.description)}</div>

            <div className="font-medium mt-5">
              {t(lg.constructionSite.formControlSettings.cardCheckBeforePayment.disadvantages.title)}
            </div>
            <div>{t(lg.constructionSite.formControlSettings.cardCheckBeforePayment.disadvantages.description)}</div>
          </RadioCard>
        </div>
        {/* right  */}
        <div className="c-grid-column">
          <RadioCard<RadioCardValue>
            title={t(lg.constructionSite.formControlSettings.cardAutomatically.title)}
            value="automatically"
            checked={value === "automatically"}
            disabled={disabled}
            onClick={handleClickRadioCard}
          >
            <div>{t(lg.constructionSite.formControlSettings.cardAutomatically.description)}</div>
            <div className="font-medium mt-5">
              {t(lg.constructionSite.formControlSettings.cardAutomatically.advantages.title)}
            </div>
            <div>{t(lg.constructionSite.formControlSettings.cardAutomatically.advantages.description)}</div>
            <div className="font-medium mt-5">
              {t(lg.constructionSite.formControlSettings.cardAutomatically.disadvantages.title)}
            </div>
            <div>{t(lg.constructionSite.formControlSettings.cardAutomatically.disadvantages.description)}</div>
          </RadioCard>
        </div>
      </div>
    </div>
  );
};
