import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spin, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { useQueue } from "react-use";
import { Section } from "components/Section";
import { MediaCard } from "components/MediaCard";
import { MediaUploadSuccessHandler, useAlert, useConfig, useFormat, useMedia } from "hooks";
import { resolveError, useDocumentationRecordUpdateMutation } from "api";
import { lg } from "assets/translations";

export type GeodeticSurveyAdditional = {
  id: string;
  url: string;
  fileName: string;
};

type Props = {
  organizationId: string;
  documentationRecordId: string;
  files: GeodeticSurveyAdditional[];
  className?: string;
  disabled?: boolean;
};

export const GeodeticSurveyAdditionalUpload = ({
  organizationId,
  documentationRecordId,
  files,
  className,
  disabled = false
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { allowedFileTypesToString, allowedFileTypesToReadableString } = useFormat();
  const { showAlert } = useAlert();
  const { config } = useConfig();

  const [updateDocumentationRecord, { loading: updating }] = useDocumentationRecordUpdateMutation();
  const [removeDocumentationRecord, { loading: removing }] = useDocumentationRecordUpdateMutation();

  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);

  const { add: queueAdd, remove: queueRemove, first: queueFirst, size: queueSize } = useQueue<RcFile>();

  const handleMediaUploadSuccess = useCallback<MediaUploadSuccessHandler>(
    async media => {
      queueRemove();
      await setUploadedFiles([...uploadedFiles, media.id]);
    },
    [uploadedFiles, setUploadedFiles, queueRemove]
  );

  const allowedFileTypes = useMemo(() => config.allowedOtherFileTypes, [config.allowedOtherFileTypes]);
  const uploadAccept = useMemo(
    () => allowedFileTypesToString(allowedFileTypes),
    [allowedFileTypes, allowedFileTypesToString]
  );

  const { upload, uploading } = useMedia(organizationId, handleMediaUploadSuccess);
  const loading = useMemo(() => uploading || updating || removing, [uploading, updating, removing]);

  const _disabled = useMemo(() => disabled || uploading || removing, [disabled, uploading, removing]);

  const processQueueItem = useCallback(
    (file: RcFile): boolean | PromiseLike<void> => upload(file, allowedFileTypes),
    [upload, allowedFileTypes]
  );

  const processUploadedFiles = useCallback(async () => {
    if (uploadedFiles.length < 1 || loading) return;

    try {
      const currentFiles = files.map(({ id }) => id);

      await updateDocumentationRecord({
        variables: {
          input: {
            documentationRecord: documentationRecordId,
            geodeticSurvey: { files: [...currentFiles, ...uploadedFiles] }
          }
        }
      });
    } catch (e) {
      resolveError(e, {}, showAlert);
    }

    setUploadedFiles([]);
  }, [files, uploadedFiles, setUploadedFiles, documentationRecordId, updateDocumentationRecord, showAlert, loading]);

  const handleRemove = useCallback(
    async (id?: string) => {
      if (!id) return;

      try {
        const newFiles = files.filter(file => file.id !== id).map(({ id }) => id);

        await removeDocumentationRecord({
          variables: {
            input: {
              documentationRecord: documentationRecordId,
              geodeticSurvey: { files: newFiles }
            }
          }
        });
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [files, removeDocumentationRecord, documentationRecordId, showAlert]
  );

  useEffect(() => {
    if (loading || queueSize === 0) return;

    processQueueItem(queueFirst);
  }, [queueFirst, queueSize, processQueueItem, loading]);

  useEffect(() => {
    if ((queueSize === 0 && uploadedFiles.length > 0) || !uploading) processUploadedFiles();
  }, [queueSize, uploadedFiles, processUploadedFiles, uploading]);

  const handleBeforeUpload = useCallback(async (file: RcFile): Promise<void> => queueAdd(file), [queueAdd]);

  return (
    <Section title={t(lg.taskSurveyor.geodeticSurveyAdditionalUpload.title)} className={className}>
      <div className="c-grid mt-10">
        <div className="c-grid-column">
          <div>
            {t(lg.taskSurveyor.geodeticSurveyAdditionalUpload.description, {
              allowed: allowedFileTypesToReadableString(allowedFileTypes)
            })}
          </div>
        </div>
      </div>

      <div className="c-grid mt-5">
        <div className="c-grid-column">
          <div className={_disabled ? "opacity-50" : ""}>
            <Upload.Dragger
              name="file"
              accept={uploadAccept}
              multiple={true}
              fileList={[]}
              disabled={_disabled}
              beforeUpload={handleBeforeUpload}
            >
              <p className="ant-upload-drag-icon mt-2">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text text-base">{t(lg.uploader.title)}</p>
              <p className="ant-upload-hint text-secondary whitespace-pre-line mb-2">{t(lg.uploader.description)}</p>
            </Upload.Dragger>
          </div>
        </div>
        <div className="c-grid-column">
          {loading && (
            <div className="flex items-center justify-center min-h-full">
              <Spin />
            </div>
          )}

          {!loading &&
            files.length > 0 &&
            files.map(({ id, url, fileName }, i) => (
              <div key={id} className={i > 0 ? "mt-5" : ""}>
                <MediaCard
                  fileName={fileName}
                  fileUrl={url}
                  fileId={id}
                  onDelete={_disabled ? undefined : handleRemove}
                />
              </div>
            ))}
        </div>
      </div>
    </Section>
  );
};
