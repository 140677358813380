import useStore, { createStore } from "global-hook-store";
import { useMemo } from "react";
import moment from "moment";

type ConfigPollingStore = { lastUpdate?: string };
type ConfigPollingReducers = { setLastUpdate: (store: ConfigPollingStore) => ConfigPollingStore };
const configPollingStore = createStore<ConfigPollingStore, ConfigPollingReducers>(
  {},
  { setLastUpdate: store => ({ ...store, lastUpdate: new Date().toISOString() }) }
);

/**
 * Save last polled date
 * */
export const useConfigPollingStore = () => {
  const { state, actions } = useStore(configPollingStore);

  const canPoll = useMemo<boolean>(() => {
    if (!state.lastUpdate) return true;
    // compare last polled date with now
    const diff = moment().diff(moment(state.lastUpdate), "minutes");
    return diff >= 5;
  }, [state.lastUpdate]);

  return {
    canPoll,
    setWasPolled: actions.setLastUpdate
  };
};
