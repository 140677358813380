import { PropsWithChildren } from "react";
import { Form, Input, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import { SelectProps } from "antd/es/select";
import { FieldData } from "rc-field-form/es/interface";
import { Role, UserFragment } from "api";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";

export enum GeneralInfoFormItemName {
  name = "name",
  description = "description",
  accountableManagerId = "accountableManagerId",
  accountableOperatorId = "accountableOperatorId",
  accountableSurveyorId = "accountableSurveyorId"
}

export type GeneralInfoFormData = Partial<{ [key in keyof typeof GeneralInfoFormItemName]: string }>;

type Props = {
  formRef: FormInstance;
  disabled?: boolean;
  onChange: (changedFields: FieldData[], allFields: FieldData[]) => void;
  onFinish: (values: GeneralInfoFormData) => any;
  users: UserFragment[];
};

export const GeneralInfoForm = ({ formRef, disabled, onChange, onFinish, users }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Form
      form={formRef}
      name="general-info-object"
      hideRequiredMark={true}
      layout={"vertical"}
      onFinish={onFinish}
      onFieldsChange={onChange}
    >
      <div className="c-grid">
        <div className="c-grid-column">
          <Form.Item
            label={
              <div>
                {t(lg.constructionObject.formBasicInformation.name.label)}{" "}
                <span className="text-secondary">({t(lg.form.required)})</span>
              </div>
            }
            name={GeneralInfoFormItemName.name}
            rules={[
              { required: true, message: t(lg.constructionObject.formBasicInformation.name.validations.addName) }
            ]}
          >
            <Input
              size={"large"}
              placeholder={t(lg.constructionObject.formBasicInformation.name.placeholder)}
              disabled={disabled}
            />
          </Form.Item>

          <Form.Item
            label={t(lg.constructionObject.formBasicInformation.description.label)}
            name={GeneralInfoFormItemName.description}
          >
            <Input.TextArea
              placeholder={t(lg.constructionObject.formBasicInformation.description.placeholder)}
              rows={6}
              disabled={disabled}
            />
          </Form.Item>
        </div>

        <div className="c-grid-column">
          <Form.Item
            label={t(lg.constructionObject.formBasicInformation.manager.label)}
            name={GeneralInfoFormItemName.accountableManagerId}
            className="mb-5"
          >
            <Select {...selectFilterProps} disabled={disabled}>
              {users
                .filter(({ role }) => role === Role.Manager)
                .map(({ id, firstName, lastName, email }) => (
                  <Select.Option key={id} value={id}>
                    {firstName} {lastName} {email ? `(${email})` : null}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(lg.constructionObject.formBasicInformation.operator.label)}
            name={GeneralInfoFormItemName.accountableOperatorId}
            className="mb-5"
          >
            <Select {...selectFilterProps} disabled={disabled}>
              <Select.Option value="">
                {t(lg.constructionObject.formBasicInformation.operator.defaultValue)}
              </Select.Option>
              {users
                .filter(({ role }) => role === Role.Operator || role === Role.Manager)
                .map(({ id, firstName, lastName, email }) => (
                  <Select.Option key={id} value={id}>
                    {firstName} {lastName} {email ? `(${email})` : null}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t(lg.constructionObject.formBasicInformation.surveyor.label)}
            name={GeneralInfoFormItemName.accountableSurveyorId}
            className="mb-5"
          >
            <Select {...selectFilterProps} disabled={disabled}>
              <Select.Option value="">
                {t(lg.constructionObject.formBasicInformation.surveyor.defaultValue)}
              </Select.Option>
              {users
                .filter(({ role }) => role === Role.Surveyor || role === Role.Manager)
                .map(({ id, firstName, lastName, email }) => (
                  <Select.Option key={id} value={id}>
                    {firstName} {lastName} {email ? `(${email})` : null}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>

          <div className="flex items-center text-purple-600">
            <Tooltip
              placement="bottomLeft"
              title={t(lg.constructionObject.formBasicInformation.tooltips.needAddNewUser.content)}
              className="mr-2"
              arrowPointAtCenter
            >
              <InfoCircleOutlined className="text-xl cursor-pointer" />
            </Tooltip>
            <span className="text-xs">
              {t(lg.constructionObject.formBasicInformation.tooltips.needAddNewUser.text)}
            </span>
          </div>
        </div>
      </div>
    </Form>
  );
};

const selectFilterProps: Partial<SelectProps<string>> = {
  showSearch: true,
  optionFilterProp: "children",
  filterOption: (input, option) => {
    if (!option || !option.children) return false;

    return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
};
