import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";
import { MediaCard } from "components/MediaCard";
import clsx from "clsx";
import { GroundControlPoint } from "./StandardGroundControlPointsOverview";

export type SimpleGroundControlPoint = Pick<GroundControlPoint, "id" | "description" | "photos">;

type Props = {
  simpleGroundControlPointCount?: number;
  simpleGroundControlPoint?: SimpleGroundControlPoint;

  className?: string;
};

export const SimpleGroundControlPointsOverview = ({
  simpleGroundControlPointCount: countOfPoints,
  simpleGroundControlPoint: point,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  if (!point) return null;

  return (
    <div className={clsx(className, "c-grid")}>
      <div className="c-grid-column">
        <div>
          <div className="font-medium">{t(lg.documentationRecord.pageDetail.simplifiedGroundControlPoints.count)}</div>
          <div className="text-secondary whitespace-pre-line">{countOfPoints}</div>
        </div>

        <div className="mt-5">
          <div className="font-medium">
            {t(lg.documentationRecord.pageDetail.simplifiedGroundControlPoints.description)}
          </div>
          <div className="text-secondary whitespace-pre-line">{point.description || "-"}</div>
        </div>
      </div>
      <div className="c-grid-column print_hidden">
        <div className="font-medium">{t(lg.documentationRecord.pageDetail.simplifiedGroundControlPoints.photos)}</div>
        <div className="mt-5">
          {point.photos.map((photo, index) => (
            <MediaCard
              key={photo.id}
              fileName={photo.name || ""}
              fileUrl={photo.url || ""}
              className={index > 0 ? "mt-5" : ""}
            />
          ))}
        </div>
      </div>
      {/* Print */}
      <div className="hidden print_block">
        {point.photos.map(photo => (
          <div className="c-grid-column" key={`photo-print-${photo.id}`}>
            <img src={photo.url || ""} alt={photo.name || ""} style={{ maxHeight: 250 }} />
          </div>
        ))}
      </div>
    </div>
  );
};
