/* Get the inquiry name from the requests. Name is formed by the topics names */
import { InquiryStatusFilterEnum, InquiryTopicLocalesFragment } from "api";
import { getLocaleInquiryTopic } from "helpers/translationsFromApi";
import { enumToValues } from "helpers/types";

type Inquiry = {
  requests: { edges: { node: { inquiryTopic: InquiryTopicLocalesFragment } }[] };
};

export function getInquiryName<T extends Inquiry>(
  inquiry: T | undefined | null,
  locale: Parameters<typeof getLocaleInquiryTopic>[1]
): string {
  if (!inquiry) return "-";
  return inquiry.requests.edges.map(({ node }) => getLocaleInquiryTopic(node.inquiryTopic, locale).topic).join(", ");
}

/* Inquiries can no-longer be archived, but some of them have this status from before the archival was implemented */
export function getInquiryStatusFilter() {
  return enumToValues<InquiryStatusFilterEnum>(InquiryStatusFilterEnum).filter(
    status => status !== InquiryStatusFilterEnum.Archived
  );
}
