import { ApolloClient, ApolloLink, InMemoryCache } from "apollo-boost";
import { errorLink } from "api/links/errorLink";
import { refreshTokenLink } from "api/links/refreshTokenLink";
import { authLink } from "api/links/authLink";
import { httpLink } from "api/links/httpLink";
import { setApiCompatibilityLink } from "api/links/setApiCompatibilityLink";
import { blockedUserLink } from "api/links/blockedUserLink";

const link = ApolloLink.from([
  setApiCompatibilityLink,
  authLink,
  refreshTokenLink,
  blockedUserLink,
  errorLink,
  httpLink
]);
const cache = new InMemoryCache();
export const client = new ApolloClient({ link, cache });

export * from "./generated";
export * from "./resolvers";
