import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { useColumnSearchProps, usePagination } from "helpers/serverSideTableFilter";
import { useTranslation } from "react-i18next";
import {
  DocumentationRecordOrderByEnum,
  DocumentationRecordState,
  DocumentationRecordStateFilterEnum,
  Role,
  TasksQuery,
  TasksQueryVariables,
  useTasksQuery
} from "api";
import { lg } from "assets/translations";
import { enumToValues, getTaskUrl, TableProps } from "helpers";
import { Link } from "components/Link";
import { StateIndicatorDocumentationRecord } from "components/Indicators/State";
import { documentationRecordStateFilters, documentationRecordStates } from "api/enums";
import { useFormat } from "hooks";

type TableItem = NonNullable<TasksQuery["me"]>["tasks"]["edges"][0]["node"] & {
  constructionSiteName: string;
  constructionObjectName: string;
  constructionPhaseOrder: string;
  lastChange: string;
};
type TablePropType = TableProps<TableItem>;

type Props = {
  userRole?: Role;
  defaultStateFilter?: DocumentationRecordStateFilterEnum[];
};

const orderByMap: { [key: string]: { [key: string]: DocumentationRecordOrderByEnum[] } } = {
  name: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  constructionSiteName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteNameDesc]
  },
  constructionObjectName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectNameDesc]
  },
  constructionPhaseOrder: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionPhaseOrder],
    descend: [DocumentationRecordOrderByEnum.ConstructionPhaseOrderDesc]
  },
  lastChange: {
    ascend: [DocumentationRecordOrderByEnum.LastModified],
    descend: [DocumentationRecordOrderByEnum.LastModifiedDesc]
  }
};

export const TableTodo = ({ userRole, defaultStateFilter: originalDefaultStateFilter }: PropsWithChildren<Props>) => {
  const defaultStateFilter = useMemo(
    () => originalDefaultStateFilter?.filter(s => s !== DocumentationRecordStateFilterEnum.Archived) || [],
    [originalDefaultStateFilter]
  );
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState<TasksQueryVariables["orderBy"]>(orderByMap.lastChange.descend);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const [nameFilter, setNameFilter] = useState("");
  const [siteNameFilter, setSiteNameFilter] = useState("");
  const [objectNameFilter, setObjectNameFilter] = useState("");
  const [constructionPhaseOrderFilter, setConstructionPhaseOrderFilter] = useState("");
  const [stateFilter, setStateFilter] = useState<TasksQueryVariables["state"]>(defaultStateFilter);
  const nameSearchProps = useColumnSearchProps();
  const siteNameSearchProps = useColumnSearchProps();
  const objectNameSearchProps = useColumnSearchProps();
  const constructionPhaseOrderSearchProps = useColumnSearchProps();
  const { getFromNow } = useFormat();

  const { data, loading } = useTasksQuery({
    variables: {
      skip: skip,
      orderBy: orderBy,
      name: nameFilter,
      constructionPhaseOrder: constructionPhaseOrderFilter,
      constructionSiteName: siteNameFilter,
      constructionObjectName: objectNameFilter,
      state: stateFilter
    },
    fetchPolicy: "cache-and-network"
  });
  useEffect(() => {
    setTotal(data?.me?.tasks.totalCount);
  }, [data]);

  const tableData =
    data?.me?.tasks.edges
      .map(e => e.node)
      .map(item => ({
        ...item,
        constructionSiteName: item.constructionObject.constructionSite.name,
        constructionObjectName: item.constructionObject.name,
        constructionPhaseOrder: `${item.constructionPhase.order}.`,
        lastChange: item.lastStateChange?.createdAt || item.createdAt
      })) || [];

  const columns = useMemo(
    (): TablePropType["columns"] => [
      {
        dataIndex: "name",
        key: "name",
        title: t(lg.todo.table.header.documentationRecord),
        render: (name, { id, state }) => {
          const url = userRole ? getTaskUrl(id, userRole, state) : "";
          return !url ? <span className="text-secondary">{name}</span> : <Link href={url}>{name}</Link>;
        },
        sorter: true,
        ...nameSearchProps,
        ellipsis: true
      },
      {
        dataIndex: "constructionSiteName",
        key: "constructionSiteName",
        title: t(lg.todo.table.header.constructionSite),
        sorter: true,
        ...siteNameSearchProps,
        ellipsis: true,
        render: constructionSiteName => <span className="text-secondary">{constructionSiteName || "-"}</span>
      },
      {
        dataIndex: "constructionObjectName",
        key: "constructionObjectName",
        title: t(lg.todo.table.header.constructionObject),
        sorter: true,
        ellipsis: true,
        ...objectNameSearchProps,
        render: name => <span className="text-secondary">{name || "-"}</span>
      },
      {
        dataIndex: "constructionPhaseOrder",
        key: "constructionPhaseOrder",
        title: t(lg.todo.table.header.constructionPhase),
        sorter: true,
        ...constructionPhaseOrderSearchProps,
        render: order => <span className="text-secondary">{order}</span>
      },
      {
        dataIndex: "lastChange",
        key: "lastChange",
        title: t(lg.todo.table.header.pendingTime),
        sorter: true,
        defaultSortOrder: "descend",
        render: lastChange => <span className="text-secondary">{getFromNow(lastChange) || "-"}</span>
      },
      {
        dataIndex: "state",
        key: "state",
        title: t(lg.todo.table.header.state),
        filters: enumToValues<DocumentationRecordStateFilterEnum>(DocumentationRecordStateFilterEnum)
          .filter(s => s !== DocumentationRecordStateFilterEnum.Archived)
          .map(type => ({
            text: t(documentationRecordStateFilters[type].nameI18nKey),
            value: type
          })),
        defaultFilteredValue: defaultStateFilter?.length ? defaultStateFilter : undefined,
        render: (state: any) => <StateIndicatorDocumentationRecord state={state} />
      },
      {
        dataIndex: "state",
        key: "what_to_do",
        title: t(lg.todo.table.header.description),
        render: (state: DocumentationRecordState) => (
          <span className="text-secondary">{t(documentationRecordStates[state].taskNameI18nKey)}</span>
        )
      }
    ],
    [
      t,
      getFromNow,
      nameSearchProps,
      siteNameSearchProps,
      objectNameSearchProps,
      constructionPhaseOrderSearchProps,
      defaultStateFilter,
      userRole
    ]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter, extra) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setNameFilter(filter.name ? filter.name[0] : "");
      setSiteNameFilter(filter.constructionSiteName ? filter.constructionSiteName[0] : "");
      setObjectNameFilter(filter.constructionObjectName ? filter.constructionObjectName[0] : "");
      setConstructionPhaseOrderFilter(filter.constructionPhaseOrder ? filter.constructionPhaseOrder[0] : "");
      setStateFilter(filter.state || []);
    },
    [setPage]
  );

  return (
    <Table<TableItem>
      dataSource={tableData}
      columns={columns}
      rowKey={"id"}
      loading={{ delay: tableData.length === 0 ? 0 : 300, spinning: loading }}
      showSorterTooltip={false}
      onChange={handleOnChange}
      pagination={pagination}
      scroll={{ x: true }}
    />
  );
};
