import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Language } from "api/generated";
import moment from "moment";
/* import all supported languages specified at "Language" enum */
import "moment/locale/cs";
import "moment/locale/en-gb";
import "moment/locale/uk";
import "moment/locale/de";
import { setLngToStorage } from "api/storage";
// import "moment/locale/hu";

export const FALLBACK_LANGUAGE: Language = Language.Cs;

const resources = {
  [Language.Cs]: require("./cs.json"),
  [Language.En]: require("./en.json"),
  [Language.Uk]: require("./uk.json"),
  [Language.De]: require("./de.json")
  // [Language.Hu]: require("./hu.json")}, // uncomment moment/locale/hu too
};

export const availableLanguages = Object.keys(resources) as Language[];

const domainLocaleMap: Record<Language, string> = {
  [Language.Cs]: "cs",
  [Language.En]: "en",
  [Language.Uk]: "uk",
  [Language.De]: "de"
};

const detectLngByDomain = (): Language | undefined | null => {
  const paths = window?.location.pathname.split("/");
  const pathLng = paths.length >= 2 ? paths[1] : undefined;

  let result: Language | undefined;

  for (const [lng, lngKey] of Object.entries(domainLocaleMap)) {
    if (lngKey !== pathLng) continue;

    result = lng as Language;
    break;
  }

  return result;
};

const setDocumentLanguage = (lng: Language) => {
  if (typeof document !== "undefined") {
    document.documentElement.lang = lng;
  }
};

const detectLngByBrowser = (): Language | undefined => {
  let navigatorLng: string | undefined;

  if (typeof window !== "undefined") {
    navigatorLng = window.navigator.language || window.navigator.userLanguage;
  }
  // format language codes to the custom format
  // example:  from "cs-CZ" to "cs"
  const sanitized = navigatorLng?.split("-")[0];
  return Object.values(Language).find(key => key === sanitized);
};

export const detectDefaultLng = (): Language => {
  const domainLng = detectLngByDomain();
  if (domainLng) return domainLng;

  const browserLng = detectLngByBrowser();
  if (browserLng) return browserLng;

  // fallback
  return FALLBACK_LANGUAGE;
};

export const initTranslations = () => {
  i18n
    .use(initReactI18next)
    .init({
      resources,
      returnNull: false,
      lng: detectDefaultLng(),
      fallbackLng: FALLBACK_LANGUAGE,

      interpolation: {
        escapeValue: false
      }
    })
    .then(() => {
      // compare activeLng with resources
      const activeLng = availableLanguages.find(lng => lng === i18n?.language) || FALLBACK_LANGUAGE;
      setAppLanguage(activeLng);
      moment.updateLocale(activeLng, []);
    });
};

export const setAppLanguage = (lng: Language) => {
  setLngToStorage(lng);
  i18n.changeLanguage(lng).then(() => {
    setDocumentLanguage(lng);
    moment.updateLocale(lng, []);
  });
};
export const getAppLanguage = (): Language => i18n.language as Language;

export const pluralize = (
  count: number,
  singularNominative: string, // 1
  nominativePlural: string, // 2 - 4
  genitivePlural: string, // 5 - n
  zero?: string // 0
): string => {
  if (!count && zero) return zero;
  if (count === 1) return singularNominative;
  if (count >= 2 && count <= 4) return nominativePlural;
  return genitivePlural;
};

export { Language };
export * from "./lg";
