import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/Section";
import { lg } from "assets/translations";
import { useFormat } from "hooks";

type Author = {
  firstName: string;
  lastName: string;
  email: string;
};

type Props = {
  createdAt?: string;
  author?: Author;
  lastEditedAt?: string;
  lastEditedBy?: Author;
  className?: string;
};

export const Details = ({ createdAt, lastEditedAt, author, lastEditedBy, className }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { formatDate } = useFormat();

  if (!createdAt || !author) return null;

  return (
    <Section title={t(lg.documentationRecord.pageDetail.details.title)} className={className}>
      <div className="mt-10">
        <div className="c-grid">
          {/* left */}
          <div className="c-grid-column">
            <div>
              <div className="text-gray-700">{t(lg.documentationRecord.pageDetail.details.createdAt.label)}</div>
              <span className="text-secondary">{formatDate(createdAt, "human", true)}</span>
            </div>

            <div className="mt-5">
              <div className="text-gray-700">{t(lg.documentationRecord.pageDetail.details.editedAt.label)}</div>
              <span className="text-secondary">{lastEditedAt ? formatDate(lastEditedAt, "human", true) : "-"}</span>
            </div>
          </div>
          {/* right */}
          <div className="c-grid-column">
            <div>
              <div className="text-gray-700">{t(lg.documentationRecord.pageDetail.details.createdBy.label)}</div>
              <span className="text-secondary">
                {author.firstName} {author.lastName} ({author.email})
              </span>
            </div>

            <div className="mt-5">
              <div className="text-gray-700">{t(lg.documentationRecord.pageDetail.details.editedBy.label)}</div>
              <span className="text-secondary">
                {lastEditedBy ? (
                  <>
                    {lastEditedBy.firstName} {lastEditedBy.lastName} ({lastEditedBy.email})
                  </>
                ) : (
                  "-"
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
