import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { Section } from "components/Section";
import { useAlert, useFormat } from "hooks";
import { MediaState, resolveError, useInquiryForReviewDetailPageQuery } from "api";
import { decodeRelayId, getLocaleInquiryTopic } from "helpers";
import { StateIndicatorInquiry } from "components/Indicators/State/StateIndicatorInquiry";
import { ConstructionBreadcrumb } from "components/ConstructionBreadcrumb";
import { MediaCard } from "components/MediaCard";

type ResolvedInquiryReviewProps = { id: string };

export const ResolvedInquiryReview = ({ id }: ResolvedInquiryReviewProps) => {
  if (!id) throw new Error("Missing inquiry ID.");

  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const { formatDate } = useFormat();

  const { data } = useInquiryForReviewDetailPageQuery({
    variables: { id },
    fetchPolicy: "cache-first", // fetched at parent component InquiryReview.tsx
    skip: !id,
    onError: e => resolveError(e, undefined, showAlert)
  });

  const decodedId = useMemo(() => decodeRelayId(id)?.id || "", [id]);
  const inquiry = useMemo(() => data?.inquiry, [data]);
  const requests = useMemo(() => inquiry?.requests.edges.flatMap(({ node }) => (node ? [node] : [])) || [], [inquiry]);
  const attachments = useMemo(
    () => inquiry?.files.edges.flatMap(({ node }) => (node && node.state === MediaState.Uploaded ? [node] : [])) || [],
    [inquiry]
  );

  return (
    <div>
      <Section title={t(lg.inquiryReview.progressResolved.title)} className="mt-10">
        <div className="c-grid mt-5 md:mt-10">
          {/* left */}
          <div className="c-grid-column">
            <div className="font-medium">{t(lg.inquiryReview.progressResolved.body.resolverComment.label)}</div>
            <div className="text-secondary whitespace-pre-line">{inquiry?.resolverComment || "-"}</div>
          </div>

          {/* right */}
          <div className="c-grid-column">
            {attachments.length > 0 && (
              <>
                <div className="font-medium">{t(lg.inquiryReview.progressResolved.body.attachments.label)}</div>
                {attachments.map((file, index) => {
                  return (
                    <MediaCard
                      key={file.id}
                      fileUrl={file.url || undefined}
                      fileName={file.fileName || "-"}
                      className="mt-5"
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </Section>

      {/*
      Details
      */}
      <Section title={t(lg.inquiryReview.inquiryDetails.title)} className="mt-10">
        <div className="c-grid mt-5 md:mt-10">
          {/* left */}
          <div className="c-grid-column">
            <div>
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.inquiryNumber.label)}</div>
              <div className="text-secondary whitespace-pre-line">{decodedId}</div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.createdBy.label)}</div>
              <div className="text-secondary whitespace-pre-line">
                {`${inquiry?.user.firstName} ${inquiry?.user.lastName}` || "-"}
              </div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.createdAt.label)}</div>
              <div className="text-secondary whitespace-pre-line">
                {inquiry?.createdAt ? formatDate(inquiry.createdAt, "machine", true) : "-"}
              </div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.userComment.label)}</div>
              <div className="text-secondary whitespace-pre-line">{inquiry?.userComment || "-"}</div>
            </div>
          </div>

          {/* right */}
          <div className="c-grid-column">
            <div>
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.status.label)}</div>
              <div className="text-secondary">{inquiry ? <StateIndicatorInquiry state={inquiry.status} /> : "-"}</div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.organization.label)}</div>
              <div className="text-secondary whitespace-pre-line">{inquiry?.organization.name || "-"}</div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.resolvedAt.label)}</div>
              <div className="text-secondary">
                {inquiry?.resolvedAt ? formatDate(inquiry.resolvedAt, "machine", true) : "-"}
              </div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.requestType.label)}</div>
              {requests.length > 0
                ? requests.map((request, index) => {
                    const documentationRecords =
                      request.documentationRecords.edges.flatMap(({ node }) => (node ? [node] : [])) || [];
                    const constructionSites =
                      request.constructionSites.edges.flatMap(({ node }) => (node ? [node] : [])) || [];
                    const constructionObjects =
                      request.constructionObjects.edges.flatMap(({ node }) => (node ? [node] : [])) || [];

                    const topic = getLocaleInquiryTopic(request.inquiryTopic, i18n.language).topic;

                    return (
                      <div key={request.id} className={index === 0 ? "" : "mt-5"}>
                        <div className="text-secondary">{topic}</div>

                        {documentationRecords.map(record => (
                          <ConstructionBreadcrumb
                            key={record.id}
                            constructionSite={record.constructionObject.constructionSite}
                            constructionObject={record.constructionObject}
                            documentationRecord={record}
                            disabled
                          />
                        ))}

                        {constructionSites.map(site => (
                          <ConstructionBreadcrumb key={site.id} constructionSite={site} disabled />
                        ))}

                        {constructionObjects.map(object => (
                          <ConstructionBreadcrumb
                            key={object.id}
                            constructionSite={object.constructionSite}
                            constructionObject={object}
                            disabled
                          />
                        ))}
                      </div>
                    );
                  })
                : "-"}
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};
