import { PropsWithChildren, useCallback, useMemo } from "react";
import { Alert, Modal } from "antd";
import ReactPlayer from "react-player/lazy";
import { Trans, useTranslation } from "react-i18next";
import { lg } from "assets/translations";
import { DownloadOutlined } from "@ant-design/icons";
import { UseCaseEvent } from "api";
import { useLogEvent } from "hooks";
import { getMediaType, MediaType, removeQueriesFromUrl } from "helpers";

type Props = {
  fileUrl: string;
  title?: string;
  description?: string;
  visible: boolean;
  onCancel: () => any;
};

export const Lightbox = ({ fileUrl, title, description, visible, onCancel }: PropsWithChildren<Props>) => {
  const [t] = useTranslation();
  const { logEvent } = useLogEvent();

  const urlWithoutQueries = useMemo(() => removeQueriesFromUrl(fileUrl), [fileUrl]);
  const mediaType = useMemo(() => getMediaType(urlWithoutQueries), [urlWithoutQueries]);
  const isImage = useMemo(() => mediaType === MediaType.Image, [mediaType]);
  const isVideo = useMemo(() => mediaType === MediaType.Video, [mediaType]);

  const showFooter = useMemo(() => title || description || isVideo, [title, description, isVideo]);

  const handleDownload = useCallback(async () => logEvent(UseCaseEvent.FileDownload), [logEvent]);

  return (
    <Modal width={"auto"} open={visible} footer={false} className="p-5 md:p-10" onCancel={onCancel} destroyOnClose>
      {isVideo && (
        <div className="px-4">
          <ReactPlayer
            url={fileUrl}
            controls={true}
            width={"100%"}
            stopOnUnmount
            // ant-modal top value * 2 + ant-modal inner horizontal spacing = 300px
            height={"calc(100vh - 300px)"}
          />
        </div>
      )}

      {isImage && <img src={fileUrl} alt={title} className="mx-auto" style={{ maxHeight: "calc(100vh - 300px)" }} />}

      {showFooter && (
        <div className="flex flex-wrap gap-2 items-center justify-between mt-4 px-4">
          {title && (
            <a
              href={fileUrl}
              rel="noopener noreferrer"
              className="c-link"
              title={isVideo ? t(lg.downloads.video) : isImage ? t(lg.downloads.image) : undefined}
              onClick={handleDownload}
              download
            >
              <DownloadOutlined className="text-xl align-middle" />
              <span className="ml-3">{title}</span>
            </a>
          )}
          {isVideo && (
            <Alert
              type={"info"}
              message={
                <Trans i18nKey={lg.lightbox.compatibilityInfo.video}>
                  {/* Trans component add content to the anchor element */}
                  {/* eslint-disable jsx-a11y/anchor-has-content */}
                  <a
                    href={fileUrl}
                    rel="noopener noreferrer"
                    className="underline"
                    title={t(lg.downloads.video)}
                    onClick={handleDownload}
                    download
                  />
                  {/* eslint-enable jsx-a11y/anchor-has-content */}
                </Trans>
              }
            />
          )}
          {description && <div className="text-base text-secondary align-middle">{description}</div>}
        </div>
      )}
    </Modal>
  );
};
