import { PropsWithChildren } from "react";
import { Switch } from "antd";
import clsx from "clsx";

type Props = {
  isActive: boolean;
  onChange?: (isActive: boolean) => void;
  disabled?: boolean;
  description?: string;
  className?: string;
};

export const StateToggle = ({
  isActive = false,
  onChange = () => {},
  disabled,
  description,
  className
}: PropsWithChildren<Props>) => (
  <div className={clsx("flex items-center", className)}>
    {description && <span className="mr-2">{description}</span>}
    <Switch checked={isActive} disabled={disabled} onChange={onChange} />
  </div>
);
