import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";
import { Button } from "antd";
import { getEnv } from "helpers";
import { useServiceWorker } from "hooks";

type Props = {
  className?: string;
};

export const AppVersion = ({ className }: PropsWithChildren<Props>) => {
  const [t] = useTranslation();
  const { isUpdateAvailable, updateAssets } = useServiceWorker();

  const version = useMemo(() => getEnv("REACT_APP_VERSION"), []);

  return (
    <div className={className}>
      <div className="text-gray-700 text-center">
        {t(lg.version.actually.label)}
        <br />
        {version}
      </div>

      {isUpdateAvailable && (
        <div className="mt-3">
          <div className="font-medium text-gray-700 text-center">{t(lg.version.update.description)}</div>
          <Button type="primary" className="block mt-2 mx-auto" onClick={updateAssets}>
            {t(lg.version.update.button.text)}
          </Button>
        </div>
      )}
    </div>
  );
};
