import { PropsWithChildren, useMemo } from "react";
import clsx from "clsx";
import { InviteState } from "api";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";

type Props = {
  state: InviteState;
  className?: string;
};

export const BadgeInvitationStatus = ({ state, className }: PropsWithChildren<Props>) => {
  const [t] = useTranslation();

  const bgColor = useMemo(() => {
    if (state === InviteState.Rejected) return "bg-red-600";
    if (state === InviteState.Sent || state === InviteState.Created) return "bg-blue-600";
    if (state === InviteState.Expired) return "bg-gray-600";
  }, [state]);

  const text = useMemo(() => {
    if (state === InviteState.Rejected) return t(lg.organization.modals.inviteUser.invitationState.rejected);
    if (state === InviteState.Sent || state === InviteState.Created)
      return t(lg.organization.modals.inviteUser.invitationState.sent);
    if (state === InviteState.Expired) return t(lg.organization.modals.inviteUser.invitationState.expired);
  }, [t, state]);

  if (!bgColor || !text) return null;

  return (
    <span
      className={clsx(
        "inline-block text-white text-sm leading-none rounded-full px-2 py-1 whitespace-nowrap",
        bgColor,
        className
      )}
    >
      {text}
    </span>
  );
};
