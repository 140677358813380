import { useTranslation } from "react-i18next";
import { lg, pluralize } from "assets/translations";
import { Maybe } from "api";
import { Coords } from "google-map-react";
import moment from "moment";
import { useCallback } from "react";

export const useFormat = () => {
  const { t, i18n } = useTranslation();

  const capitalize = useCallback((value: string): string => value.charAt(0).toUpperCase() + value.slice(1), []);

  const creditCost = useCallback(
    (cost: number, _capitalize: boolean = true): string => {
      let unit = pluralize(
        cost,
        t(lg.credit.singularNominative),
        t(lg.credit.nominativePlural),
        t(lg.credit.genitivePlural)
      );
      if (_capitalize) unit = capitalize(unit);

      return `${cost} ${unit}`;
    },
    [t, capitalize]
  );

  const creditCostGenitive = useCallback(
    (cost: number, _capitalize: boolean = true): string => {
      let unit = pluralize(
        cost,
        t(lg.credit.singularGenitive),
        t(lg.credit.genitivePlural),
        t(lg.credit.genitivePlural)
      );
      if (_capitalize) unit = capitalize(unit);

      return `${cost} ${unit}`;
    },
    [t, capitalize]
  );

  const pluralizeMonth = useCallback(
    (months: number, _capitalize: boolean = true) => {
      let unit = pluralize(
        months,
        t(lg.month.unit.singularNominative),
        t(lg.month.unit.nominativePlural),
        t(lg.month.unit.genitivePlural)
      );
      if (_capitalize) unit = capitalize(unit);

      return `${months} ${unit}`;
    },
    [t, capitalize]
  );

  const pluralizeEveryMonth = useCallback(
    (months: number, _capitalize: boolean = true) => {
      let unit = pluralize(
        months,
        t(lg.month.every.singularNominative),
        t(lg.month.every.nominativePlural),
        t(lg.month.every.genitivePlural)
      );
      if (_capitalize) unit = capitalize(unit);

      return `${unit} ${pluralizeMonth(months, false)}`;
    },
    [t, pluralizeMonth, capitalize]
  );

  const formatBytes = useCallback(
    (bytes: number, decimals: number = 2, unitsType: "binary" | "metric" = "metric"): string => {
      if (bytes === 0) return `0 ${t(lg.bytes.zero)}`;

      const unit: string = pluralize(
        bytes,
        t(lg.bytes.singularNominative),
        t(lg.bytes.nominativePlural),
        t(lg.bytes.genitivePlural)
      );

      const k = unitsType === "binary" ? 1024 : 1000;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes =
        unitsType === "binary"
          ? [unit, "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
          : [unit, "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    [t]
  );

  const convertGibToB = useCallback((gb: number) => gb * Math.pow(1024, 3), []);

  const formatPhoneNumber = useCallback(
    (value: string): string =>
      value.replace(/^(\+\d{3})(\d{3})(\d{3})(\d{3})$/, "$1 $2 $3 $4").replace(/^(\d{3})(\d{3})(\d{3})$/, "$1 $2 $3"),
    []
  );

  const getFileName = useCallback((path: string): string | undefined => {
    return path.split("/").pop();
  }, []);

  const arrayToCoords = useCallback((coordsArray: Maybe<Maybe<number>[]>[]): Coords[] => {
    return coordsArray.map(coords =>
      !Array.isArray(coords) ? { lat: 0, lng: 0 } : { lat: coords[0] || 0, lng: coords[1] || 0 }
    );
  }, []);

  const coordsToArray = useCallback((coords: Coords[]): Maybe<Maybe<number>[]>[] => {
    return coords.map(({ lat, lng }) => [lat, lng]);
  }, []);

  const formatDate = useCallback(
    (date: string, type: "human" | "machine", withTime: boolean = false): string =>
      moment(date)
        .locale(i18n.language)
        .format(`${t(lg.dateAndTime[type].date)}${withTime ? ` ${t(lg.dateAndTime[type].time)}` : ""}`),
    [i18n.language, t]
  );

  const getFromNow = useCallback(
    (date: string): string => moment(date).locale(i18n.language).fromNow(),
    [i18n.language]
  );

  /** For Antd Upload accept prop */
  const allowedFileTypesToString = useCallback((types?: string[]): string => {
    if (!types || !types.length) return "";

    return types.map(type => `.${type}`).join(",");
  }, []);

  /**
   * get array of the file extensions and return
   * formatted and translated string
   *
   * examples:
   * ["txt"] -> ".txt"
   * ["jpg", "jpeg"] -> ".jpg or .jpeg"
   * ["jpg", "jpeg", "bmp] -> ".jpg, .jpeg or .bmp"
   * */
  const allowedFileTypesToReadableString = useCallback(
    (types?: string[]): string => {
      if (!types || !types.length) return "";

      const formattedTypes = types.map(type => `.${type}`);

      if (formattedTypes.length < 2) return formattedTypes.join();

      const lastItem = formattedTypes[types.length - 1];
      return formattedTypes.slice(0, -1).join(", ") + ` ${t(lg.conjunctions.or)} ${lastItem}`;
    },
    [t]
  );

  /*
   * Get array of all numbers in range minimal and maximal value
   * */
  const getNumberRangeArray = useCallback(
    (step: number = 1, maximumValue: number, minimalValue: number = 0): number[] => {
      const result: number[] = [];
      const steps = Math.floor((maximumValue - minimalValue) / step);

      if (steps > 0) {
        // init minimal value
        result.push(minimalValue);
        // put values to the result. Values are increased by step value
        for (let i = 1; i <= steps; i++) {
          result.push(minimalValue + i * step);
        }
      }

      return result;
    },
    []
  );

  return {
    capitalize,
    creditCost,
    creditCostGenitive,
    pluralizeMonth,
    pluralizeEveryMonth,
    formatBytes,
    convertGibToB,
    formatPhoneNumber,
    getFileName,
    getNumberRangeArray,
    arrayToCoords,
    coordsToArray,
    formatDate,
    getFromNow,
    allowedFileTypesToString,
    allowedFileTypesToReadableString
  };
};
