import { useMemo } from "react";
import { useMe } from "hooks/useMe";
import { useCatalog } from "hooks/useCatalog";
import { useConfig } from "hooks/useConfig";

export const usePricing = () => {
  const { organization, loading: loadingMe } = useMe();
  const { catalog, loading: loadingCatalog } = useCatalog();
  const { config, loading: loadingConfig } = useConfig();

  const loading = useMemo(
    () => loadingMe || loadingCatalog || loadingConfig,
    [loadingMe, loadingCatalog, loadingConfig]
  );

  /* Handlers - form */
  return {
    loading,

    organization,
    catalog,
    shopUrl: config.shopUrl
  };
};
