import { useMemo } from "react";
import { resolveError, useConfigQuery } from "api";
import { useAlert, useConfigPollingStore } from "hooks";
import { useTranslation } from "react-i18next";
import { getEnv, getLocaleConfig } from "helpers";
import { WatchQueryFetchPolicy } from "apollo-client";

const STATIC_CONTACT_EMAIL = getEnv("REACT_APP_CONTACT_EMAIL");
const STATIC_CONTACT_PHONE = getEnv("REACT_APP_CONTACT_PHONE");
const STATIC_HELPDESK_URL = getEnv("REACT_APP_HELPDESK_URL");

export const useConfig = (fetchPolicy?: WatchQueryFetchPolicy) => {
  const { showAlert } = useAlert();
  const { canPoll, setWasPolled } = useConfigPollingStore();
  const { i18n } = useTranslation();

  const defaultFetchPolicy = useMemo<WatchQueryFetchPolicy>(
    // reduce count of the API request
    () => (canPoll ? "cache-and-network" : "cache-first"),
    [canPoll]
  );

  const { data, loading, error } = useConfigQuery({
    fetchPolicy: fetchPolicy || defaultFetchPolicy,
    onError: e => resolveError(e, undefined, showAlert),
    onCompleted: () => setWasPolled()
  });

  const config = useMemo(() => data?.config, [data]);

  const localizedConfig = useMemo(() => config && getLocaleConfig(config, i18n.language), [config, i18n.language]);

  return {
    loading,

    config: {
      id: config?.id,
      minimalGroundControlPoints: config?.minimalGroundControlPoints,
      threeDModelDeleteAfterDays: config?.threeDModelDeleteAfterDays,
      documentationRecordDeleteAfterDays: config?.documentationRecordDeleteAfterDays,
      defaultConstructionPhases: localizedConfig?.defaultConstructionPhases || [],
      organizationInviteSentValidDays: config?.organizationInviteSentValidDays,
      purchaseExpirationMonths: config?.purchaseExpirationMonths,
      helpDeskUrl: config?.helpdeskUrl || STATIC_HELPDESK_URL,
      shopUrl: localizedConfig?.shopUrl || "#",
      externalAccountUrl: config?.externalAccountUrl || "#",
      externalBaseName: config?.externalBaseName,
      externalBaseUrl: config?.externalBaseUrl || "#",
      contactUrl: config?.contactUrl || "#",
      logoutRedirectUrl: config?.logoutRedirectUrl || "#", // deprecated, don't use it
      emailContact: localizedConfig?.emailContact || STATIC_CONTACT_EMAIL,
      phoneContact: localizedConfig?.phoneContact || STATIC_CONTACT_PHONE,
      providerName: config?.providerName,
      providerUrl: config?.providerUrl || "#",
      providerCrn: config?.providerCrn,
      transactionMessagesBccEmail: config?.transactionMessagesBccEmail,
      allowedGeodeticSurveyFileTypes: config?.allowedGeodeticSurveyFileTypes?.flatMap(item => (item ? [item] : [])),
      allowedImageFileTypes: config?.allowedImageFileTypes?.flatMap(item => (item ? [item] : [])),
      allowedVideoFileTypes: config?.allowedVideoFileTypes?.flatMap(item => (item ? [item] : [])),
      allowedOtherFileTypes: config?.allowedOtherFileTypes?.flatMap(item => (item ? [item] : [])),
      documentationRecordReturnReasons:
        localizedConfig?.documentationRecordReturnReasons?.flatMap(item => (item ? [item] : [])) || [],
      documentationRecordRejectReasons:
        localizedConfig?.documentationRecordRejectReasons?.flatMap(item => (item ? [item] : [])) || [],
      storagePaymentIntervalMonths: config?.storagePaymentIntervalMonths,
      transactionEmailsEnabled: config?.transactionEmailsEnabled,
      oauthUserinfoUrl: config?.oauthUserinfoUrl || "#",
      fileUploadUrl: config?.fileUploadUrl || "#",
      threeDViewerUrl: config?.threeDViewerUrl || "#"
    },
    error
  };
};
