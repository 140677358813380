import { ComponentProps, useCallback, useMemo } from "react";
import { Dropdown } from "antd";
import { availableLanguages, Language, setAppLanguage } from "assets/translations";
import { CustomIcon, CustomIconType } from "components/icons/CustomIcon";
import { useSetLanguage } from "hooks";
import { useAuth } from "api/auth";
import { useTranslation } from "react-i18next";

type LanguageSwitcherProps = {
  className?: string;
};

export const LanguageSwitcher = (props: LanguageSwitcherProps) => {
  const { i18n } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { setUserLanguage } = useSetLanguage();

  const availableItems = useMemo(() => items.filter(item => availableLanguages.includes(item.lng)), []);
  const activeItem = useMemo(
    () => availableItems.find(item => item.lng === i18n.language),
    [availableItems, i18n.language]
  );

  const setLanguage = useCallback(
    async (lng: Language) => {
      if (isLoggedIn()) await setUserLanguage(lng);
      await setAppLanguage(lng);
    },
    [isLoggedIn, setUserLanguage]
  );

  const handleClickItem = useCallback((lng: Language) => setLanguage(lng), [setLanguage]);

  const menu = useMemo<ComponentProps<typeof Dropdown>["menu"]>(
    () => ({
      items: availableItems.map(item => ({
        key: `lngSwitcher-${item.lng}`,
        label: item.name,
        icon: <CustomIcon type={item.icon} className="w-4 h-4" />,
        onClick: () => handleClickItem(item.lng)
      }))
    }),
    [availableItems, handleClickItem]
  );

  if (!activeItem || availableItems.length <= 1) return null;

  return (
    <div className={props.className}>
      <Dropdown menu={menu}>
        <div className="ant-dropdown-link flex items-center">
          <CustomIcon type={activeItem.icon} className="w-6 h-6" />
          <div className="hidden sm:block text-white ml-2.5">{activeItem.shortcut}</div>
        </div>
      </Dropdown>
    </div>
  );
};

type LanguageSwitcherItem = {
  lng: Language;
  name: string;
  shortcut: string;
  icon: CustomIconType;
};

const items: LanguageSwitcherItem[] = [
  {
    lng: Language.Cs,
    name: "Česky",
    shortcut: "CZ",
    icon: "flag-cs"
  },
  {
    lng: Language.En,
    name: "English",
    shortcut: "EN",
    icon: "flag-en"
  },
  {
    lng: Language.Uk,
    name: "Українська",
    shortcut: "UA",
    icon: "flag-uk"
  },
  {
    lng: Language.De,
    name: "Deutsch",
    shortcut: "DE",
    icon: "flag-de"
  }
];
