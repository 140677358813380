import { ComponentProps, useCallback, useMemo } from "react";
import { Avatar, Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { LogoutOutlined } from "@ant-design/icons";
import { navigate } from "@reach/router";
import { logoutUser } from "api/auth";
import { lg } from "assets/translations/lg";
import { routes } from "routes";
import { useMe } from "hooks";
import { client, UseCaseEvent, useOrganizationLogEventMutation } from "api";

export const UserMenu = () => {
  const [t] = useTranslation();
  const { me, loading: loadingMe } = useMe();
  const [logEvent, { loading: loadingLogMutation }] = useOrganizationLogEventMutation();

  const loading = useMemo(() => loadingMe || loadingLogMutation, [loadingMe, loadingLogMutation]);

  const handleClickLogout = useCallback(async () => {
    await logEvent({
      variables: {
        input: {
          event: UseCaseEvent.UserLogout
        }
      }
    });

    logoutUser();

    await client.stop();
    await client.clearStore();

    await navigate(routes.login);
  }, [logEvent]);

  const menu = useMemo<ComponentProps<typeof Dropdown>["menu"]>(
    () => ({
      items: [
        {
          key: "menu-item-logout",
          label: t(lg.headerLogout),
          icon: <LogoutOutlined />,
          onClick: handleClickLogout
        }
      ]
    }),
    [t, handleClickLogout]
  );

  if (loading || !me) return null;

  return (
    <Dropdown menu={menu}>
      <div className="ant-dropdown-link flex items-center space-x-2.5">
        <Avatar size={"small"} className="text-purple-800 bg-purple-500 flex-shrink-0">
          {me.firstName?.charAt(0)}
        </Avatar>
        <span className="text-white text-sm">
          {me.firstName} {me.lastName}
        </span>
      </div>
    </Dropdown>
  );
};
