import { ReactElement, ReactNode, useCallback } from "react";
import { Button, Card } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";

type Props<T> = {
  title?: ReactNode;
  value: T;
  checked: boolean;
  disabled?: boolean;
  children: ReactNode;
  onClick: (value: T) => any;
  className?: string;
};

export const RadioCard = <T extends any>({
  title,
  checked,
  disabled = false,
  children,
  onClick,
  value,
  className
}: Props<T>): ReactElement | null => {
  const { t } = useTranslation();
  const onClickHandler = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  return (
    <Card
      title={title}
      className={clsx(checked ? "ant-card-selected" : "", className)}
      bodyStyle={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
    >
      {children}
      <div className={clsx("flex justify-center", children ? "mt-5" : "")}>
        <Button
          onClick={onClickHandler}
          type={checked ? "link" : "primary"}
          size={"large"}
          icon={checked ? <CheckOutlined /> : null}
          className="text-white"
          disabled={disabled}
        >
          {checked ? t(lg.radioCard.buttonSelectedText) : t(lg.radioCard.buttonSelectText)}
        </Button>
      </div>
    </Card>
  );
};
