import { useCallback, useEffect, useMemo, useState } from "react";
import { Coords } from "google-map-react";
import { navigate } from "@reach/router";
import {
  ConstructionSiteState,
  resolveError,
  useConstructionSiteCreateEditPageQuery,
  useConstructionSiteCreateMutation,
  useConstructionSiteUpdateMutation
} from "api";
import { useAlert, useCoordsModal, useFormat, useMe } from "hooks";
import { routes, setRouteParams } from "routes";
import { ControlSettingsChangeHandler } from "components/ConstructionSites/ControlSettingsRadioCards";
import { Form } from "antd";
import { FormRoleChangeItems } from "components/ConstructionSiteEdit/RoleModalChange";
import { FieldData } from "rc-field-form/es/interface";
import { GeneralInfoFormData } from "components/ConstructionSites/GeneralInfoForm";
import { useMountedState } from "react-use";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";

type DefaultValues = {
  siteState: ConstructionSiteState;
  autoRequestThreeD: boolean;
  coords: Coords[];
};

export const useConstructionSite = (type: "create" | "edit", id: string = "") => {
  const { showAlert } = useAlert();
  const { arrayToCoords, coordsToArray } = useFormat();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isMounted = useMountedState();

  const [create, { loading: loadingCreateMutation }] = useConstructionSiteCreateMutation();
  const [update, { loading: loadingUpdateMutation }] = useConstructionSiteUpdateMutation();

  /** STATES */
  const [isFormValid, setIsFormValid] = useState(false);
  const [coords, setCoords] = useState<Coords[]>();
  const [savedCoords, setSavedCoords] = useState<Coords[]>();
  const [updatedSiteState, setUpdatedSiteState] = useState<ConstructionSiteState>();
  const [hasChangedAccountableUser, setHasChangedAccountableUser] = useState(false);
  const [updateConstructionObjectsStates, setUpdateConstructionObjectsStates] = useState(false);
  const [updatedAutoRequestThreeD, setUpdatedAutoRequestThreeD] = useState<boolean>();
  const [descendantValues, setDescendantValues] = useState<FormRoleChangeItems[]>([]);
  const [roleChangeModalVisible, setRoleChangeModalVisible] = useState<boolean>(false);
  const [stateChangeModalVisible, setStateChangeModalVisible] = useState<boolean>(false);
  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  /** DATA */
  const { loading: loadingOrganization, data: dataConstructionSite } = useConstructionSiteCreateEditPageQuery({
    variables: { id },
    onError: e => resolveError(e, undefined, showAlert)
  });
  const { me, organization: myOrganization, refetch, loading: loadingMe } = useMe();

  const constructionSite = useMemo(() => dataConstructionSite?.constructionSite, [dataConstructionSite]);

  const defaultOrganizationValues = useMemo<DefaultValues>(
    () => ({
      siteState: constructionSite?.state || ConstructionSiteState.Active,
      autoRequestThreeD: constructionSite?.autoRequestThreeD || false,
      coords: arrayToCoords(constructionSite?.location || [])
    }),
    [constructionSite, arrayToCoords]
  );

  const defaultFormValuesCreate = useMemo<GeneralInfoFormData | undefined>(() => {
    if (!myOrganization || !me.id) return;
    return {
      accountableManagerId: myOrganization.users.find(({ id }) => id === me.id)?.id || "",
      accountableOperatorId: "",
      accountableSurveyorId: ""
    };
  }, [me.id, myOrganization]);

  const defaultFormValuesEdit = useMemo<GeneralInfoFormData | undefined>(() => {
    /* wait for all data before init values */
    if (!form || !constructionSite || !myOrganization || !me.id) return;

    return {
      name: constructionSite?.name || "",
      description: constructionSite?.description || "",
      accountableManagerId:
        constructionSite?.accountableManager?.id || myOrganization.users.find(user => user.id === me.id)?.id || "",
      accountableOperatorId: constructionSite?.accountableOperator?.id || "",
      accountableSurveyorId: constructionSite?.accountableSurveyor?.id || ""
    };
  }, [form, me.id, myOrganization, constructionSite]);

  const isSiteActive = useMemo<boolean>(
    () =>
      updatedSiteState
        ? updatedSiteState === ConstructionSiteState.Active
        : defaultOrganizationValues.siteState === ConstructionSiteState.Active,
    [updatedSiteState, defaultOrganizationValues.siteState]
  );

  const hasDocumentationRecords = useMemo<boolean>(
    () =>
      !!constructionSite?.createdRecords ||
      !!constructionSite?.workInProgressRecords ||
      !!constructionSite?.completedRecords,
    [constructionSite]
  );
  const hasConstructionObjects = useMemo<boolean>(
    () => !!constructionSite?.constructionObjects.totalCount,
    [constructionSite]
  );
  const isModalRoleChangeRequired = useMemo<boolean>(
    () => type === "edit" && (hasDocumentationRecords || hasConstructionObjects),
    [type, hasDocumentationRecords, hasConstructionObjects]
  );

  const { hasSavedCoords, setHasSavedCoords, isVisibleModalCoords, setIsVisibleModalCoords } = useCoordsModal();

  const loading = useMemo(() => loadingMe || loadingOrganization, [loadingMe, loadingOrganization]);
  const sending = useMemo(
    () => loadingCreateMutation || loadingUpdateMutation || isRefetch,
    [loadingCreateMutation, loadingUpdateMutation, isRefetch]
  );

  const hasValidCoords = useMemo(() => {
    const hasSavedCoords = savedCoords !== undefined;
    const hasCoords = coords !== undefined;

    if (!hasSavedCoords && !hasCoords && (constructionSite?.location?.length || 0) > 2) return true;
    if (!hasSavedCoords && hasCoords && (coords?.length || 0) > 2) return true;
    return hasSavedCoords && hasCoords && (coords?.length || 0) > 2;
  }, [coords, savedCoords, constructionSite]);

  const disabled = useMemo(() => loading || sending, [loading, sending]);
  const canSend = useMemo(() => loading || sending || isFormValid, [loading, sending, isFormValid]);

  /** HANDLERS */
  /** Header handlers */
  const handleClickAbort = useCallback(() => {
    navigate(type === "create" ? routes.constructionSites : setRouteParams(routes.constructionSiteDetail, { id }));
  }, [id, type]);

  const handleClickSave = useCallback(() => {
    if (type === "create" && savedCoords === undefined && (coords?.length || 0) > 2) {
      setSavedCoords(coords);
      setHasSavedCoords(true);
    }
    form.submit();
  }, [type, coords, savedCoords, setHasSavedCoords, form]);

  /** Form handlers */
  const handleFormChange = useCallback((changedFields: FieldData[], allFields: FieldData[]) => {
    // validate
    setIsFormValid(!allFields.filter(field => field.errors && field.errors.length > 0).length);

    // Check for changed accountable users
    const wasAccountableUserChanges = changedFields.find(({ name }) =>
      (Array.isArray(name) ? name[0] : name).toString().includes("accountable")
    );

    return wasAccountableUserChanges ? setHasChangedAccountableUser(true) : null;
  }, []); // TODO validate coords too? - https://hrdlicka.atlassian.net/browse/HRD003-443

  const handleSubmit = useCallback(
    async (values: GeneralInfoFormData) => {
      if (!hasSavedCoords) {
        setIsVisibleModalCoords(true);
        return;
      }

      if (isModalRoleChangeRequired && hasChangedAccountableUser) {
        return setRoleChangeModalVisible(true);
      }

      const input = {
        accountableManager: values.accountableManagerId,
        accountableOperator: values.accountableOperatorId,
        accountableSurveyor: values.accountableSurveyorId,
        autoRequestThreeD:
          updatedAutoRequestThreeD === undefined
            ? defaultOrganizationValues.autoRequestThreeD
            : updatedAutoRequestThreeD,
        description: values.description || "",
        location: savedCoords ? coordsToArray(savedCoords) : undefined,
        name: values.name || "",
        state: updatedSiteState === undefined ? defaultOrganizationValues.siteState : updatedSiteState
      };

      try {
        if (type === "create") {
          await create({
            variables: {
              input: {
                ...input,
                organization: myOrganization.id || "",
                defaultPhaseName: t(lg.defaultConstructionPhaseName)
              }
            }
          });
          setIsRefetch(true);
          await refetch();
          await navigate(routes.constructionSites);
        }

        if (type === "edit") {
          await update({
            variables: {
              input: {
                ...input,
                constructionSite: id,
                updateDescendantConstructionObjects: descendantValues.includes(
                  FormRoleChangeItems.updateDescendantConstructionObjects
                ),
                updateDescendantDocumentationRecords: descendantValues.includes(
                  FormRoleChangeItems.updateDescendantDocumentationRecords
                ),
                updateDescendantConstructionObjectState: updateConstructionObjectsStates
              }
            }
          });

          await navigate(setRouteParams(routes.constructionSiteDetail, { id }));
        }
      } catch (e) {
        resolveError(e, {}, showAlert);
      } finally {
        if (isMounted()) setIsRefetch(false);
      }
    },
    [
      t,
      id,
      type,
      showAlert,
      coordsToArray,
      defaultOrganizationValues,
      savedCoords,
      hasSavedCoords,
      hasChangedAccountableUser,
      setIsVisibleModalCoords,
      updatedSiteState,
      updatedAutoRequestThreeD,
      updateConstructionObjectsStates,
      descendantValues,
      myOrganization,
      create,
      update,
      refetch,
      isMounted,
      isModalRoleChangeRequired
    ]
  );

  /** Map handlers */
  const handleMapChange = useCallback(
    (coords: Coords[]) => {
      setCoords(coords);
      setHasSavedCoords(false);
    },
    [setHasSavedCoords]
  );
  const handleMapSave = useCallback(() => {
    setSavedCoords(coords);
    setHasSavedCoords(true);
  }, [coords, setHasSavedCoords]);

  /** Modal Unsaved Map handlers */
  const handleClickCoordsModalCancel = useCallback(() => {
    setIsVisibleModalCoords(false);
    setHasSavedCoords(true);
    handleClickSave();
  }, [handleClickSave, setIsVisibleModalCoords, setHasSavedCoords]);

  const handleClickCoordsModalOk = useCallback(() => {
    setSavedCoords(coords);
    setHasSavedCoords(true);
    setIsVisibleModalCoords(false);
    handleClickSave();
  }, [handleClickSave, setHasSavedCoords, setIsVisibleModalCoords, coords]);

  /** Modal - role change **/
  const handleOkModalRoleChange = useCallback(
    (modalData: FormRoleChangeItems[]) => {
      setHasChangedAccountableUser(false);
      setDescendantValues(modalData);
      setRoleChangeModalVisible(false);
      handleClickSave();
    },
    [handleClickSave]
  );

  const handleCancelModalRoleChange = useCallback(() => {
    setHasChangedAccountableUser(false);
    setDescendantValues([]);
    setRoleChangeModalVisible(false);
    handleClickSave();
  }, [handleClickSave]);

  /** Modal - state change **/
  const handleModalStateChange = useCallback((value: boolean) => {
    setUpdateConstructionObjectsStates(value);
    setStateChangeModalVisible(false);
  }, []);

  /* other handlers */
  const handleChangeState = useCallback(
    (isActive: boolean) => {
      setUpdatedSiteState(isActive ? ConstructionSiteState.Active : ConstructionSiteState.Inactive);
      if (type === "edit") setStateChangeModalVisible(true);
    },
    [type]
  );

  const handleChangeControlSettings = useCallback<ControlSettingsChangeHandler>(
    value => setUpdatedAutoRequestThreeD(value === "automatically"),
    []
  );

  /** EFFECTS */
  /*
   * Init default values to the form and validate by fields validation rules
   * */
  useEffect(() => {
    if (!form || form.isFieldsTouched()) return;

    if (type === "create" && defaultFormValuesCreate) return form.setFieldsValue(defaultFormValuesCreate);
    if (type !== "edit" || !defaultFormValuesEdit) return;

    form.setFieldsValue(defaultFormValuesEdit);

    /* validate */
    form
      .validateFields()
      .then(async () => {
        setIsFormValid(true);
      })
      .catch(info => {
        setIsFormValid(false);
      });
  }, [form, type, me.id, myOrganization, constructionSite, defaultFormValuesEdit, defaultFormValuesCreate]);

  return {
    form,
    loading,
    sending,
    disabled,
    state: {
      isFormValid,
      roleChangeModalVisible,
      stateChangeModalVisible,
      descendantValues
    },
    data: {
      me,
      myOrganization,

      updatedSiteState,
      isSiteActive,
      defaultOrganizationValues,
      constructionSite,
      hasDocumentationRecords,
      hasConstructionObjects,

      canSend,
      hasSavedCoords,
      hasValidCoords,
      isVisibleModalCoords
    },
    handlers: {
      handleClickAbort,
      handleClickSave,
      handleChangeState,
      handleChangeControlSettings,
      handleFormChange,
      handleSubmit,
      handleMapChange,
      handleMapSave,

      handleClickCoordsModalOk,
      handleClickCoordsModalCancel,
      handleOkModalRoleChange,
      handleCancelModalRoleChange,

      handleModalStateChange
    }
  };
};
