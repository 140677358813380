import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { navigate } from "@reach/router";
import {
  ConstructionObjectOrderByEnum,
  ConstructionObjectsTableQuery,
  ConstructionObjectStateFilterEnum,
  resolveError,
  useConstructionObjectsTableQuery
} from "api";
import { Button, Table } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import { Link } from "components/Link";
import { routes, setRouteParams } from "routes";
import { StateIndicatorConstructionObject } from "components/Indicators/State/StateIndicatorConstructionObject";
import { enumToValues, TableProps, useColumnSearchProps, usePagination } from "helpers";
import { useAlert, useTableDataStatus } from "hooks";
import { SimpleLoader } from "components/SimpleLoader";
import { constructionObjectStateFilters } from "api/enums";

type TableItem = NonNullable<
  ConstructionObjectsTableQuery["constructionSite"]
>["constructionObjects"]["edges"][0]["node"];
type TablePropType = TableProps<TableItem>;

type Props = {
  constructionSiteId: string;
  hasCreateButton?: boolean;
};

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  name: {
    ascend: [ConstructionObjectOrderByEnum.Name],
    descend: [ConstructionObjectOrderByEnum.NameDesc]
  }
};
export const ObjectsTable = ({ constructionSiteId, hasCreateButton = false }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [nameFilter, setNameFilter] = useState("");
  const [statesFilter, setStatesFilter] = useState<ConstructionObjectStateFilterEnum[]>([]);
  const [orderBy, setOrderBy] = useState<ConstructionObjectOrderByEnum[]>([]);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const nameSearchProps = useColumnSearchProps();

  const { data, loading } = useConstructionObjectsTableQuery({
    variables: {
      constructionSiteId: constructionSiteId,
      skip,
      orderBy,
      name: nameFilter,
      states: statesFilter
    },
    fetchPolicy: "cache-and-network",
    skip: !constructionSiteId,
    onError: e => resolveError(e, undefined, showAlert)
  });
  useEffect(() => setTotal(data?.constructionSite?.constructionObjects.totalCount), [data]);

  const objects = useMemo(() => data?.constructionSite?.constructionObjects, [data]);
  const tableData = useMemo(() => objects?.edges?.flatMap(({ node }) => (node ? [node] : [])), [objects]);

  const columns = useMemo<TablePropType["columns"]>(
    () => [
      {
        title: t(lg.constructionSite.pageDetail.objects.table.header.objectName),
        dataIndex: "name",
        key: "name",
        className: "border-b-1",
        render: (name, { id }) => (
          <Link href={setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id })}>
            <span className="text-purple-600 font-medium">{name}</span>
          </Link>
        ),
        sorter: true,
        ...nameSearchProps,
        ellipsis: true
      },
      {
        title: t(lg.constructionSite.pageDetail.objects.table.header.objectState),
        dataIndex: "state",
        key: "state",
        className: "border-b-1",
        filters: enumToValues<ConstructionObjectStateFilterEnum>(ConstructionObjectStateFilterEnum).map(state => ({
          value: state,
          text: t(constructionObjectStateFilters[state].nameI18nKey)
        })),
        render: state => <StateIndicatorConstructionObject state={state} />
      },
      {
        title: t(lg.constructionSite.pageDetail.objects.table.header.documentationCreated),
        dataIndex: "createdRecords",
        key: "createdRecords",
        className: "border-b-1",
        render: count => <span className="text-secondary">{count}</span>
      },
      {
        title: t(lg.constructionSite.pageDetail.objects.table.header.documentationInProgress),
        dataIndex: "workInProgressRecords",
        key: "workInProgressRecords",
        className: "border-b-1",
        render: count => <span className="text-secondary">{count}</span>
      },
      {
        title: t(lg.constructionSite.pageDetail.objects.table.header.documentationDone),
        dataIndex: "completedRecords",
        key: "completedRecords",
        className: "border-b-1",
        render: count => <span className="text-secondary">{count}</span>
      }
    ],
    [constructionSiteId, nameSearchProps, t]
  );

  const handleClickCreate = useCallback(
    () => navigate(setRouteParams(routes.constructionObjectCreate, { constructionSiteId })),
    [constructionSiteId]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter, extra) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setNameFilter(filter.name ? filter.name[0] : "");
      setStatesFilter(filter.state || []);
    },
    [setPage]
  );

  const { hasData, hasFilters } = useTableDataStatus(tableData?.length, [nameFilter, statesFilter]);

  if (loading && !hasFilters && !hasData)
    return (
      <div className="flex justify-center w-full p-10">
        <SimpleLoader />
      </div>
    );

  /* No table data */
  if (!hasFilters && !hasData) {
    return (
      <div className="flex flex-col items-center mt-10">
        <div className="text-xs text-gray-700 text-center whitespace-pre-line">
          {t(lg.constructionSite.pageDetail.objects.table.noDataText)}
        </div>
        {hasCreateButton && (
          <Button type="dashed" icon={<PlusOutlined />} className="mt-5" onClick={handleClickCreate}>
            {t(lg.constructionSite.pageDetail.objects.table.buttonObjectCreateText)}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={hasCreateButton ? "mt-5" : "mt-10"}>
      {hasCreateButton && (
        <div className="flex justify-end">
          <Button icon={<PlusOutlined />} onClick={handleClickCreate}>
            {t(lg.constructionSite.pageDetail.objects.table.buttonObjectCreateText)}
          </Button>
        </div>
      )}

      <div className="mt-5">
        <Table<TableItem>
          dataSource={tableData}
          columns={columns}
          rowKey={"id"}
          loading={{ delay: hasData ? 300 : 0, spinning: loading }}
          showSorterTooltip={false}
          onChange={handleOnChange}
          pagination={pagination}
          scroll={{ x: true }}
        />
      </div>
    </div>
  );
};
