import { resolveError, useMeLanguageQuery } from "api";
import { useAuth } from "api/auth";
import { useSetLanguage } from "hooks/useSetLanguage";
import { useCallback, useState } from "react";
import { getAppLanguage, setAppLanguage } from "assets/translations";

/**
 * Resolve language settings
 * no data are returned
 *
 * documentation: https://hrdlicka.atlassian.net/l/c/a2XNY5U1
 * */
export const useLanguageResolver = () => {
  const { isLoggedIn } = useAuth();
  const { setUserLanguage } = useSetLanguage();
  const [wasAppLanguageSets, setWasAppLanguageSets] = useState<boolean>(false);

  // must be function 'cause call isLoggedIn()
  const canSkipQuery = useCallback(() => !isLoggedIn() || wasAppLanguageSets, [isLoggedIn, wasAppLanguageSets]);

  useMeLanguageQuery({
    skip: canSkipQuery(),
    fetchPolicy: "network-only",
    onCompleted: data => {
      /**
       * react-apollo contains issue when onCompleted is called when query skipped
       * issue: https://github.com/apollographql/react-apollo/issues/3943
       * */
      if (canSkipQuery()) return;

      /**
       * Set User language
       * update user without language option by app language
       * */
      if (!data.me?.language) return setUserLanguage(getAppLanguage());

      /**
       * Set App language
       * update app language by user's option
       * */
      setAppLanguage(data.me.language);
      setWasAppLanguageSets(true);
    },
    onError: e => resolveError(e, undefined, undefined)
  });
};
