import { ReactNode, useCallback, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
import { PlusOutlined, RightCircleOutlined } from "@ant-design/icons";
import { pluralize } from "assets/translations";
import { lg } from "assets/translations/lg";
import { ParseMD } from "helpers";
import { Content } from "components/LayoutDashboard";
import { Link } from "components/Link";
import { Section } from "components/Section";
import { CustomIcon } from "components/icons/CustomIcon";
import { useFormat } from "hooks/useFormat";
import { SubheaderOptions, usePricing, useSubheader } from "hooks";
import { routes, setRouteParams } from "routes";
import { AboutPageTabKeys } from "pages/About";

export const Pricing = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const { capitalize, creditCost, formatBytes, convertGibToB } = useFormat();

  const { loading, organization, catalog, shopUrl } = usePricing();

  const formatPrice = useCallback(
    (value: number, per3Months: boolean = false): string => {
      if (!value) return t(lg.pricing.credits.free);
      const credits = creditCost(value, false);
      return per3Months ? t(lg.pricing.credits.per3Months, { credits }) : credits;
    },
    [t, creditCost]
  );

  const getStorageData = useCallback(
    (limits: number[], prices: number[]) =>
      limits.map((value, i) => ({
        key: `storage-${i}`,
        limit: t(lg.pricing.storage.table.limit.label, { value: formatBytes(convertGibToB(value)) }),
        price: formatPrice(prices[i], true)
      })),
    [t, formatPrice, formatBytes, convertGibToB]
  );

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: t(lg.pricing.header.title),
      breadcrumb: [{ value: t(lg.pricing.header.title) }],
      buttons: [
        <div key="credits" className="flex items-center h-10">
          <span>
            <span className="text-2xl">{organization.credits}</span>{" "}
            <span className="text-secondary">
              {capitalize(
                t(
                  pluralize(
                    organization.credits,
                    lg.credit.singularNominative,
                    lg.credit.nominativePlural,
                    lg.credit.genitivePlural
                  )
                )
              )}
            </span>
          </span>
        </div>,
        <Button
          href={shopUrl}
          target="_blank"
          key="buy-credits"
          type={"primary"}
          size={"large"}
          icon={<PlusOutlined />}
        >
          {t(lg.pricing.header.buttonPrimaryText)}
        </Button>
      ]
    }),
    [t, capitalize, shopUrl, organization.credits]
  );
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      <Section title={t(lg.pricing.storage.title)} className="mb-16">
        <div className="mt-5">
          <Table<ReturnType<typeof getStorageData>[0]>
            columns={[
              { title: t(lg.pricing.storage.table.header.limit), dataIndex: "limit" },
              { title: t(lg.pricing.storage.table.header.price), dataIndex: "price", className: "w-56" }
            ]}
            dataSource={getStorageData(catalog.storageLimits as number[], catalog.storagePrices as number[])}
            pagination={false}
            bordered={true}
          />
        </div>
      </Section>

      <Section title={t(lg.pricing.modelRequest.title)} className="mb-16">
        <div className="mt-5">
          <p className="text-secondary mb-1">{t(lg.pricing.modelRequest.info1)}</p>
          <p className="text-secondary mb-2">
            {t(lg.pricing.modelRequest.info2)}
            <span className="ml-4 font-medium">
              <CustomIcon type={"model-no-geo"} className="relative" style={{ top: 2 }} />{" "}
              {t(lg.pricing.modelRequest.noScale)}
            </span>
            <span className="ml-4 font-medium">
              <CustomIcon type={"model-geo"} className="relative" style={{ top: 2 }} />{" "}
              {t(lg.pricing.modelRequest.scale)}
            </span>
            .
          </p>
          <p className="text-secondary mb-3">{t(lg.pricing.modelRequest.info3)}</p>

          <Link
            href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.ThreeDModels })}
            icon={<RightCircleOutlined className="text-xl" />}
            className="text-xs leading-relaxed font-normal mb-5"
          >
            {t(lg.pricing.modelRequest.about3DModels)}
          </Link>

          <Table<{ key: string; icon: ReactNode; type: ReactNode; price: string }>
            columns={[
              { title: "", colSpan: 0, align: "center", className: "w-16", dataIndex: "icon" },
              { title: t(lg.pricing.modelRequest.table.header.type), colSpan: 2, align: "left", dataIndex: "type" },
              { title: t(lg.pricing.modelRequest.table.header.price), dataIndex: "price", className: "w-56" }
            ]}
            dataSource={[
              {
                key: "1",
                icon: <CustomIcon type={"model-simple"} />,
                type: t(lg.pricing.modelRequest.table.noScale),
                price: formatPrice(catalog.requestThreeDModelNoScale)
              },
              {
                key: "2",
                icon: <CustomIcon type={"model-ruler"} />,
                type: t(lg.pricing.modelRequest.table.scale),
                price: formatPrice(catalog.requestThreeDModelScale)
              },
              {
                key: "3",
                icon: <CustomIcon type={"model-map"} />,
                type: t(lg.pricing.modelRequest.table.maps),
                price: formatPrice(catalog.requestThreeDModelMaps)
              },
              {
                key: "4",
                icon: <CustomIcon type={"model-spray"} />,
                type: t(lg.pricing.modelRequest.table.spray),
                price: formatPrice(catalog.requestThreeDModelSpray)
              },
              {
                key: "5",
                icon: <CustomIcon type={"model-qr"} />,
                type: t(lg.pricing.modelRequest.table.qrCodes),
                price: formatPrice(catalog.requestThreeDModelQrCodes)
              }
            ]}
            pagination={false}
            bordered={true}
            scroll={{ x: true }}
          />
        </div>
      </Section>

      <Section title={t(lg.pricing.other.title)} className="mb-16">
        <div className="mt-5">
          <Table<{ key: string; title: ReactNode; price: string }>
            columns={[
              { title: t(lg.pricing.other.table.header.request), dataIndex: "title" },
              { title: t(lg.pricing.other.table.header.price), dataIndex: "price", className: "w-56" }
            ]}
            dataSource={[
              {
                key: "1",
                title: t(lg.pricing.other.table.createDocumentationRecord),
                price: formatPrice(catalog.createDocumentationRecord)
              },
              {
                key: "2",
                title: <ParseMD>{t(lg.pricing.other.table.inviteManager)}</ParseMD>,
                price: formatPrice(catalog.inviteManager)
              },
              {
                key: "3",
                title: t(lg.pricing.other.table.downloadThreeDModel),
                price: formatPrice(catalog.downloadThreeDModel)
              }
            ]}
            pagination={false}
            bordered={true}
          />
        </div>
      </Section>

      <Section title={t(lg.pricing.refundOptions.title)}>
        <div className="mt-5">
          <Table
            columns={[{ dataIndex: "title" }]}
            dataSource={[
              { key: "1", title: t(lg.pricing.refundOptions.table.reject) },
              { key: "2", title: t(lg.pricing.refundOptions.table.return) }
            ]}
            showHeader={false}
            pagination={false}
            bordered={true}
          />
        </div>
      </Section>
    </Content>
  );
};
