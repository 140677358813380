import { useCallback } from "react";
import { LayoutSimple } from "components/LayoutSimple";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { useTitle } from "react-use";
import { routes } from "routes";
import { navigate } from "@reach/router";
import { Button } from "antd";
import { Illustration } from "components/Illustration";
import { RegisterAppendix } from "components/RegisterAppendix";

export const Error404 = () => {
  const { t } = useTranslation();

  useTitle(t(lg.errorPage.httpError.notFound.title));
  const handleClick = useCallback(() => navigate(routes.todo, { replace: true }), []);

  return (
    <LayoutSimple
      childLeft={
        <Illustration
          type="ill-404"
          className="max-w-full h-64 mx-auto md:h-auto"
          title={t(lg.errorPage.httpError.notFound.title)}
        />
      }
      childRight={
        <div>
          <div className="text-lg md:text-2xl text-purple-200 text-center md:text-left md:leading-snug md:mb-10">
            {t(lg.errorPage.httpError.notFound.title)}
          </div>
          <div className="text-purple-200 text-center md:text-left mt-10 md:mt-0 whitespace-pre-line">
            {t(lg.errorPage.httpError.notFound.description)}
          </div>

          <div className="text-center md:text-left mt-5">
            <Button type={"primary"} size={"large"} onClick={handleClick}>
              {t(lg.errorPage.httpError.notFound.button.text)}
            </Button>
          </div>

          <RegisterAppendix className={"mt-10"} />
        </div>
      }
    />
  );
};
