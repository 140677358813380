import { onError } from "apollo-link-error";
import { logoutUser } from "api/auth";
import { client, isFetchError } from "api/index";
import { navigate } from "@reach/router";
import { routes } from "routes";
import { isReactSnap } from "helpers";

let isProcessing = false;

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (isReactSnap) return;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, name }) =>
      console.log(`[GraphQL error]: Message: ${message}, Operation: ${name} Path: ${path}`)
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    if (!isProcessing && isFetchError(networkError)) {
      (async function resolveError() {
        isProcessing = true;
        logoutUser();
        await client.clearStore();

        await navigate(routes.login, { replace: true });
        isProcessing = false;
      })();
    }
  }
});
