import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { lg } from "assets/translations/lg";
import { Section } from "components/Section";
import { TaskItem, useAlert, useFormat, useTableDataStatus } from "hooks";
import {
  getThreeDModelsFromTasks,
  hasFilesForDownload,
  TableProps,
  ThreeDModelExtended,
  useColumnSearchProps,
  usePagination
} from "helpers";
import { TypeIndicatorThreeDModel } from "components/Indicators/Type";
import {
  DocumentationRecordOrderByEnum,
  DocumentationRecordStateFilterEnum,
  resolveError,
  TasksQueryVariables,
  useTasksQuery
} from "api";
import { Illustration } from "components/Illustration";
import { Table } from "antd";
import { ThreeDModelTableActions } from "components/ThreeDModelTableActions";

type TableItem = ThreeDModelExtended & {
  organizationName: string;
  constructionSiteName: string;
  constructionObjectName: string;
  constructionPhaseName: string;
  documentationRecordName: string;
  documentationRecordLastStateChangeAt?: string;
};

type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: DocumentationRecordOrderByEnum[] } } = {
  organizationName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteOrganizationName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteOrganizationNameDesc]
  },
  constructionSiteName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteNameDesc]
  },
  constructionObjectName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectNameDesc]
  },
  constructionPhaseName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionPhaseName],
    descend: [DocumentationRecordOrderByEnum.ConstructionPhaseNameDesc]
  },
  documentationRecordName: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  documentationRecordLastStateChangeAt: {
    ascend: [DocumentationRecordOrderByEnum.LastModified],
    descend: [DocumentationRecordOrderByEnum.LastModifiedDesc]
  }
};

export const ThreeDModelsCompleted: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { formatDate } = useFormat();
  const { showAlert } = useAlert();
  const [orderBy, setOrderBy] = useState<TasksQueryVariables["orderBy"]>(
    orderByMap.documentationRecordLastStateChangeAt.descend
  );
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const [constructionSiteNameFilter, setConstructionSiteNameFilter] = useState("");
  const constructionSiteNameSearchProps = useColumnSearchProps();
  const [constructionObjectNameFilter, setConstructionObjectNameFilter] = useState("");
  const constructionObjectNameSearchProps = useColumnSearchProps();
  const [organizationNameFilter, setOrganizationNameFilter] = useState("");
  const organizationNameSearchProps = useColumnSearchProps();

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({ heading: t(lg.threeDModelsCompleted.header.title) }),
    [t]
  );
  useSubheader(subheaderOptions);

  const { data, loading } = useTasksQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      state: [DocumentationRecordStateFilterEnum.Finished],
      skip,
      orderBy,
      organizationName: organizationNameFilter,
      constructionSiteName: constructionSiteNameFilter,
      constructionObjectName: constructionObjectNameFilter,
      toProcess: false,
      types: undefined,
      threeDModelUploaded: true
    },
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => setTotal(data?.me?.tasks.totalCount), [data]);

  const tasks: TaskItem[] = useMemo(
    () =>
      data?.me?.tasks.edges.map(edge => ({
        ...edge.node
      })) || [],
    [data]
  );

  const threeDModels = useMemo<ThreeDModelExtended[]>(() => getThreeDModelsFromTasks(tasks), [tasks]);

  const tableData = useMemo<TableItem[]>(
    () =>
      threeDModels?.map(item => ({
        ...item,
        organizationName: item.organization.name,
        constructionSiteName: item.constructionSite.name,
        constructionObjectName: item.constructionObject.name,
        constructionPhaseName: item.constructionPhase.name,
        documentationRecordName: item.documentationRecord.name,
        documentationRecordLastStateChangeAt: item.documentationRecord.lastStateChangeAt
      })) || [],
    [threeDModels]
  );

  const columns = useMemo<TablePropType["columns"]>(
    (): TablePropType["columns"] => [
      {
        dataIndex: "id",
        key: "id",
        title: t(lg.threeDModelsCompleted.table.header.modelName),
        render: (id, model) => (
          <div className="flex flex-col">
            <TypeIndicatorThreeDModel type={model.type} />
            <ThreeDModelTableActions
              className="ml-8"
              modelId={id}
              isShared={model.isShared}
              hasFilesForDownload={hasFilesForDownload(model)}
            />
          </div>
        )
      },
      {
        dataIndex: "organizationName",
        key: "organizationName",
        title: t(lg.typenames.submitter),
        sorter: true,
        ...organizationNameSearchProps,
        render: submitter => <span className="text-secondary">{submitter}</span>
      },
      {
        dataIndex: "documentationRecordLastStateChangeAt",
        key: "documentationRecordLastStateChangeAt",
        title: t(lg.threeDModelsCompleted.table.header.createdAt),
        render: (documentationRecordLastStateChangeAt?: string) => (
          <span className="text-secondary">
            {documentationRecordLastStateChangeAt
              ? formatDate(documentationRecordLastStateChangeAt, "machine", true)
              : "-"}
          </span>
        ),
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        dataIndex: "constructionSiteName",
        key: "constructionSiteName",
        title: t(lg.threeDModelsCompleted.table.header.constructionSite),
        sorter: true,
        ...constructionSiteNameSearchProps,
        render: constructionSiteName => <span className="text-secondary">{constructionSiteName || "-"}</span>
      },
      {
        dataIndex: "constructionObjectName",
        key: "constructionObjectName",
        title: t(lg.threeDModelsCompleted.table.header.constructionObject),
        sorter: true,
        ...constructionObjectNameSearchProps,
        render: constructionObjectName => <span>{constructionObjectName || "-"}</span>
      },
      {
        dataIndex: "documentationRecordName",
        key: "documentationRecordName",
        title: t(lg.threeDModelsCompleted.table.header.documentationName),
        sorter: true,
        render: documentationRecordName => <span className="text-secondary">{documentationRecordName || "-"}</span>
      }
    ],
    [t, constructionSiteNameSearchProps, constructionObjectNameSearchProps, organizationNameSearchProps, formatDate]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter, extra) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setConstructionSiteNameFilter(filter.constructionSiteName ? filter.constructionSiteName[0] : "");
      setConstructionObjectNameFilter(filter.constructionObjectName ? filter.constructionObjectName[0] : "");
      setOrganizationNameFilter(filter.organizationName ? filter.organizationName[0] : "");
    },
    [setPage]
  );

  const { hasData, hasFilters } = useTableDataStatus(tableData.length, [
    constructionSiteNameFilter,
    constructionObjectNameFilter,
    organizationNameFilter
  ]);

  return (
    <Content loading={loading && !hasFilters && !hasData}>
      {!hasFilters && !hasData ? (
        <div className="flex flex-col items-center min-h-full">
          <p className="text-center whitespace-pre-line text-base text-blue-700">
            {t(lg.threeDModelsCompleted.noData)}
          </p>

          <div className="flex-grow flex flex-col w-full p-5 md:p-20">
            <Illustration type="ill-02" className="flex-grow h-0" title={t(lg.threeDModelsCompleted.noData)} />
          </div>
        </div>
      ) : (
        <Section title={t(lg.threeDModelsCompleted.header.title)}>
          <div className="mt-10">
            <Table<TableItem>
              dataSource={tableData}
              columns={columns}
              rowKey={"id"}
              loading={{ delay: hasData ? 300 : 0, spinning: loading }}
              showSorterTooltip={false}
              onChange={handleOnChange}
              pagination={pagination}
              scroll={{ x: true }}
            />
          </div>
        </Section>
      )}
    </Content>
  );
};
