import { PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { CustomIcon } from "components/icons/CustomIcon";
import { lg } from "assets/translations";
import { useFormat } from "hooks/useFormat";

export type ChangeToThreeDHandler = (comment?: string) => any;

type Props = {
  visible: boolean;
  loading?: boolean;
  organizationCredits: number;
  cost: number;
  onSubmit: ChangeToThreeDHandler;
  onCancel: () => any;
};

export const ModalChangeToThreeD = ({
  visible,
  loading,
  organizationCredits,
  cost,
  onSubmit,
  onCancel
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { creditCost } = useFormat();

  const handleOk = useCallback(() => onSubmit(), [onSubmit]);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <CustomIcon type="send" className="text-lg text-purple-600" />
          <span className="ml-2">{t(lg.documentationRecordActions.changeToThreeD.modal.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.changeToThreeD.modal.buttonOk)}
      cancelText={t(lg.documentationRecordActions.changeToThreeD.modal.buttonCancel)}
      okButtonProps={{ type: "primary", disabled: loading }}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
    >
      <div className="text-base">{t(lg.documentationRecordActions.changeToThreeD.modal.description)}</div>

      <table className="mt-5">
        <tbody>
          <tr className="text-base text-right">
            <td>{t(lg.documentationRecordActions.changeToThreeD.modal.organizationCredits)}</td>
            <td className="text-purple-600">{creditCost(organizationCredits, false)}</td>
          </tr>
          <tr className="h-1" />
          <tr className="text-base text-right">
            <td className="font-medium">{t(lg.documentationRecordActions.changeToThreeD.modal.cost)}</td>
            <td className="text-purple-600 font-medium">{creditCost(cost, false)}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};
