import { Catalog as CatalogApi, resolveError, StorageClass, useCatalogQuery } from "api";
import { useAlert } from "hooks/useAlert";
import { useMemo } from "react";

export type Catalog = {
  id: string | undefined;
  createDocumentationRecord: number;
  requestThreeDModelNoScale: number;
  requestThreeDModelScale: number;
  requestThreeDModelMaps: number;
  requestThreeDModelSpray: number;
  requestThreeDModelQrCodes: number;
  inviteManager: number;
  downloadThreeDModel: number;
  storageLimits: number[];
  storagePrices: number[];
  storageClassPrices: StorageClass[];
  requestModelPrices: StorageClass[];
};

export const useCatalog = () => {
  const { showAlert } = useAlert();
  const { data, loading, refetch } = useCatalogQuery({ onError: e => resolveError(e, undefined, showAlert) });

  const catalog: CatalogApi | undefined = useMemo(() => data?.catalog || undefined, [data]);

  return {
    loading,
    refetch,
    catalog: {
      id: catalog?.id,
      createDocumentationRecord: catalog?.createDocumentationRecord || 0,
      requestThreeDModelNoScale: catalog?.requestThreeDModelNoScale || 0,
      requestThreeDModelScale: catalog?.requestThreeDModelScale || 0,
      requestThreeDModelMaps: catalog?.requestThreeDModelMaps || 0,
      requestThreeDModelSpray: catalog?.requestThreeDModelSpray || 0,
      requestThreeDModelQrCodes: catalog?.requestThreeDModelQrCodes || 0,
      inviteManager: catalog?.inviteManager || 0,
      downloadThreeDModel: catalog?.downloadThreeDModel, // undefined: disabled download functionality, 0: free download, >= 0: download price
      storageLimits: (catalog?.storageLimits || []) as number[],
      storagePrices: (catalog?.storagePrices || []) as number[],
      storageClassPrices: (catalog?.storageClassPrices || []) as StorageClass[],
      requestModelPrices: (catalog?.storageClassPrices || []) as StorageClass[]
    } as Catalog
  };
};
