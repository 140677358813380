import { PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { CustomIcon } from "components/icons/CustomIcon";
import { lg } from "assets/translations";

export type ChangeNoScaleHandler = () => any;

type Props = {
  visible: boolean;
  loading?: boolean;
  onSubmit: ChangeNoScaleHandler;
  onCancel: () => any;
};

export const ModalChangeNoScale = ({ visible, loading, onSubmit, onCancel }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const handleOk = useCallback(() => onSubmit(), [onSubmit]);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <CustomIcon type="send" className="text-lg text-purple-600" />
          <span className="ml-2">{t(lg.documentationRecordActions.changeNoScale.modal.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.changeNoScale.modal.buttonOk)}
      cancelText={t(lg.documentationRecordActions.changeNoScale.modal.buttonCancel)}
      okButtonProps={{ type: "primary", disabled: loading }}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
    >
      <div className="text-base whitespace-pre-line">
        {t(lg.documentationRecordActions.changeNoScale.modal.description)}
      </div>
    </Modal>
  );
};
