import { useLayoutEffect, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { routes } from "routes";
import { LayoutSimple } from "components/LayoutSimple";
import { enumToValues } from "helpers";
import { Error403, Error404, Error404Logged, Error500 } from "components/Error/HTTPError";
import { logoutUser, useAuth } from "api/auth";
import { AccountBlocked, AccountLocked, NoTeamOrLicence, OtherTeam, WrongRole } from "components/Error";
import { AccessErrors, HTTPErrors } from "api/enums";

const errors = { ...HTTPErrors, ...AccessErrors };
type Errors = AccessErrors | HTTPErrors;

type Props = RouteComponentProps & {
  error?: Errors;
};

export const Error = ({ error = HTTPErrors.NotFound, navigate }: Props) => {
  const { isLoggedIn } = useAuth();

  const isUnknownError = useMemo<boolean>(() => !enumToValues(errors).find(item => item === error), [error]);
  const isAccessError = useMemo(() => enumToValues<AccessErrors>(AccessErrors).find(item => item === error), [error]);

  /* redirect if error is unknown */
  useLayoutEffect(() => {
    if (!isUnknownError) return;
    navigate && navigate(routes.login);
  }, [navigate, isUnknownError]);

  /* logout user if error is AccessError */
  useLayoutEffect(() => {
    if (!isAccessError) return;
    logoutUser();
  }, [isAccessError]);

  if (error === AccessErrors.AccountBlocked) return <AccountBlocked />;
  if (error === AccessErrors.AccountLocked) return <AccountLocked />;
  if (error === AccessErrors.NoTeamOrLicence) return <NoTeamOrLicence />;
  if (error === AccessErrors.OtherTeam) return <OtherTeam />;
  if (error === AccessErrors.WrongRole) return <WrongRole />;

  if (error === HTTPErrors.Forbidden) return <Error403 />;
  if (error === HTTPErrors.NotFound && isLoggedIn()) return <Error404Logged />;
  if (error === HTTPErrors.NotFound) return <Error404 />;
  if (error === HTTPErrors.Internal) return <Error500 />;

  return <LayoutSimple childLeft={null} childRight={null} loading={true} />;
};
