import { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { MediaCard } from "components/MediaCard";
import { MediaUploadSuccessHandler, useAlert, useFormat, useMedia } from "hooks";
import { DocumentationRecordUpdateMutationVariables, resolveError, useDocumentationRecordUpdateMutation } from "api";
import { File } from "types/media";
import { lg } from "assets/translations";
import { ButtonUpload } from "components/ButtonUpload";

type FileUploadType = "model" | "texture" | "coordinates";

type Props = {
  organizationId: string;
  documentationRecordId: string;
  type: FileUploadType;
  file?: File;
  disabled?: boolean;
  disabledFileRemove?: boolean;
  className?: string;
  onStateChange?: (loading: boolean) => any;
};

export const FileUpload = ({
  organizationId,
  documentationRecordId,
  type,
  file,
  disabled = false,
  disabledFileRemove = false,
  className,
  onStateChange = () => {}
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { allowedFileTypesToString, allowedFileTypesToReadableString } = useFormat();

  const [updateDocumentationRecord, { loading: updating }] = useDocumentationRecordUpdateMutation();
  const [removeDocumentationRecord, { loading: removing }] = useDocumentationRecordUpdateMutation();

  const allowedFileTypes = useMemo(() => getUploadAccept(type), [type]);
  const uploadAccept = useMemo(
    () => allowedFileTypesToString(allowedFileTypes),
    [allowedFileTypesToString, allowedFileTypes]
  );

  const handleMediaUploadSuccess = useCallback<MediaUploadSuccessHandler>(
    async media => {
      try {
        await updateDocumentationRecord({ variables: getUpdateVariables(documentationRecordId, media.id, type) });
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [documentationRecordId, type, updateDocumentationRecord, showAlert]
  );

  const handleRemove = useCallback(async () => {
    if (disabledFileRemove) return;
    try {
      await removeDocumentationRecord({ variables: getUpdateVariables(documentationRecordId, null, type) });
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [removeDocumentationRecord, documentationRecordId, type, showAlert, disabledFileRemove]);

  const { upload, uploading, uploadedPercents } = useMedia(organizationId, handleMediaUploadSuccess);
  const inUploadingProcess = useMemo(() => uploading || updating, [uploading, updating]);
  const loading = useMemo(() => uploading || updating || removing, [uploading, updating, removing]);

  const handleBeforeUpload = useCallback(
    async (file: RcFile): Promise<void> => upload(file, allowedFileTypes, getCustomMimeType(type)),
    [upload, type, allowedFileTypes]
  );

  useEffect(() => onStateChange(loading), [loading, onStateChange]);

  return (
    <div className={className}>
      <div className="text-blue-700 text-base font-medium">
        {t(lg.taskThreeDOperator.modelUpload.fileUpload[type].title)}
      </div>
      <p className="mt-2">
        {t(lg.taskThreeDOperator.modelUpload.fileUpload[type].description, {
          allowed: allowedFileTypesToReadableString(allowedFileTypes)
        })}
      </p>

      <Upload type={"select"} fileList={[]} accept={uploadAccept} beforeUpload={handleBeforeUpload}>
        <ButtonUpload
          size="large"
          disabled={loading || !!file || disabled}
          className="mt-5"
          progress={uploadedPercents}
          uploading={inUploadingProcess}
        >
          {t(lg.uploadButton.description[type])}
        </ButtonUpload>
      </Upload>

      {!!file && (
        <MediaCard
          fileName={file.fileName}
          fileUrl={file.url}
          fileId={file.id}
          className="mt-5"
          onDelete={disabledFileRemove ? undefined : handleRemove}
        />
      )}
    </div>
  );
};

const getUpdateVariables = (
  id: string,
  mediaId: string | null,
  type: FileUploadType
): DocumentationRecordUpdateMutationVariables => {
  switch (type) {
    case "model":
      return { input: { documentationRecord: id, threeDModel: { modelFile: mediaId } } };
    case "texture":
      return { input: { documentationRecord: id, threeDModel: { textureFile: mediaId } } };
    case "coordinates":
      return { input: { documentationRecord: id, threeDModel: { coordinatesFile: mediaId } } };
  }
};

const getCustomMimeType = (type: FileUploadType): string | undefined => {
  switch (type) {
    case "model":
      return "application/octet-stream";
    case "texture":
      return undefined;
    case "coordinates":
      return "text/plain";
  }
};

const getUploadAccept = (type: FileUploadType): string[] => {
  switch (type) {
    case "model":
      return ["obj", "fbx", "glb"];
    case "texture":
      return ["jpg", "jpeg", "png"];
    case "coordinates":
      return ["xyz"];
  }
};
