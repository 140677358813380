import { PropsWithChildren } from "react";
import { Button, Card } from "antd";
import { CheckOutlined, WalletOutlined } from "@ant-design/icons";
import { CustomIcon, CustomIconType } from "components/icons/CustomIcon";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { useFormat } from "hooks";
import clsx from "clsx";

type Props = {
  title: string;
  icon: CustomIconType;
  cost?: number;
  selected?: boolean;
  disabled?: boolean;
  onButtonClick: () => any;
  className?: string;
};

export const TypeCard = ({
  title,
  icon,
  cost = 0,
  selected = false,
  disabled = false,
  children,
  onButtonClick,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { creditCost } = useFormat();

  return (
    <Card
      title={
        <div className="flex items-center">
          <CustomIcon type={icon} className="mr-2" />
          <span>{title}</span>
        </div>
      }
      className={clsx(selected ? "ant-card-selected" : "", className)}
      bodyStyle={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
    >
      {children}

      <div className="flex flex-col items-center mt-auto">
        {cost > 0 && (
          <>
            <p className="text-xs mt-5">{t(lg.documentationRecord.formSelectType.creditPriceInformation)}</p>

            <div className="flex items-center w-full mt-2">
              <WalletOutlined className="text-lg" />
              <span className="font-medium ml-2">{creditCost(cost, true)}</span>
            </div>
          </>
        )}

        <Button
          type={selected ? "link" : "primary"}
          size={"large"}
          icon={selected ? <CheckOutlined /> : null}
          disabled={disabled}
          className="mt-5 text-white"
          onClick={onButtonClick}
        >
          {selected ? t(lg.radioCard.buttonSelectedText) : t(lg.radioCard.buttonSelectText)}
        </Button>
      </div>
    </Card>
  );
};
