import { Media, useAlert, useFormat } from "hooks";
import { useTranslation } from "react-i18next";

import { ComponentPropsWithoutRef, useCallback, useMemo, useState } from "react";
import { decodeRelayId } from "helpers";
import { resolveError, useOutputExtensionsUpdateMutation } from "api";
import { AutoSavingTextarea } from "components/AutoSavingTextarea";
import { lg } from "assets/translations";
import { Uploader } from "components/Uploader";
import { UI } from "./UI";
import { OutputExtensionReadonly } from "./OutputExtensionReadonly";

type OutputExtensionReadonlyProps = ComponentPropsWithoutRef<typeof OutputExtensionReadonly>;
type UploaderProps = ComponentPropsWithoutRef<typeof Uploader>;

type OutputExtensionEditableProps = OutputExtensionReadonlyProps & {
  organizationId: string;
  disabled?: boolean;
  onSuccess?: () => void;
};

export const OutputExtensionEditable = ({
  organizationId,

  id,
  name,
  author,
  createdAt,
  authorComment,
  threeDOperatorComment,
  files: initFiles,

  disabled = false,

  onSuccess
}: OutputExtensionEditableProps) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { formatDate } = useFormat();

  const [files, setFiles] = useState<Media[]>(initFiles || []);
  const [comment, setComment] = useState<string>(threeDOperatorComment || "");

  const decodedId: string = useMemo(() => decodeRelayId(id)?.id || "-", [id]);

  const [updateFiles, { loading: updatingFiles }] = useOutputExtensionsUpdateMutation();
  const [updateComment] = useOutputExtensionsUpdateMutation();

  const handleUploaderFinish = useCallback(
    async (files: Media[]) => {
      try {
        const { data } = await updateFiles({
          variables: { input: { outputExtension: id, comment, files: files.map(file => file.id) } }
        });

        const updatedFiles = data?.outputExtension?.update?.outputExtension?.files.edges.map(edge => edge.node);
        updatedFiles && setFiles(updatedFiles.flatMap(file => (file ? [file] : [])));

        onSuccess && onSuccess();
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [comment, id, onSuccess, showAlert, updateFiles]
  );

  const handleUploaderChange: UploaderProps["onChange"] = useCallback(newFiles => setFiles(newFiles), []);

  const handleTextareaChange: ComponentPropsWithoutRef<typeof AutoSavingTextarea>["onSave"] = useCallback(
    async message => {
      try {
        const { data } = await updateComment({
          variables: { input: { outputExtension: id, comment: message, files: files.map(file => file.id) } }
        });

        const updatedComment = data?.outputExtension?.update?.outputExtension?.comment;
        setComment(updatedComment || "");

        onSuccess && onSuccess();
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [files, id, onSuccess, showAlert, updateComment]
  );

  return (
    <div key={id}>
      <UI.Subheading className="mt-10 mb-5">{name}</UI.Subheading>
      <div className="c-grid mt-5 md:mt-10">
        <div className="c-grid-column space-y-5">
          <UI.InfoItem
            label={t(lg.taskThreeDOperator.outputExtensions.outputExtensionNumber.label)}
            content={decodedId}
          />
          <UI.InfoItem label={t(lg.taskThreeDOperator.outputExtensions.author.label)} content={author} />
        </div>

        <div className="c-grid-column space-y-5">
          <UI.InfoItem
            label={t(lg.taskThreeDOperator.outputExtensions.createdAt.label)}
            content={formatDate(createdAt, "machine", true)}
          />
          <UI.InfoItem
            label={t(lg.taskThreeDOperator.outputExtensions.authorComment.label)}
            content={authorComment || "-"}
          />
        </div>
      </div>

      <div className="c-grid mt-5 md:mt-10">
        <UI.InfoItem
          label={t(lg.taskThreeDOperator.outputExtensions.attachmentsUpload.title)}
          content={t(lg.taskThreeDOperator.outputExtensions.attachmentsUpload.description)}
          className="c-grid-column"
        >
          <Uploader
            organizationId={organizationId}
            uploaderClassName={"p-5"}
            disabled={disabled}
            files={files}
            loading={updatingFiles}
            onChange={handleUploaderChange}
            onFinish={handleUploaderFinish}
          />
        </UI.InfoItem>

        <UI.InfoItem
          label={t(lg.taskThreeDOperator.outputExtensions.comment.label)}
          content={t(lg.taskThreeDOperator.outputExtensions.comment.description)}
          className="c-grid-column"
        >
          <AutoSavingTextarea
            rows={10}
            placeholder={t(lg.taskThreeDOperator.outputExtensions.comment.formElement.placeholder)}
            defaultValue={threeDOperatorComment || ""}
            disabled={disabled}
            onSave={handleTextareaChange}
          />
        </UI.InfoItem>
      </div>
    </div>
  );
};
