import { PropsWithChildren } from "react";
import { InquiryStatus } from "api";
import { useTranslation } from "react-i18next";
import { StateIndicator, StateIndicatorType } from "components/Indicators/State/StateIndicator";
import { inquiryStates } from "api/enums";

type Props = { state: InquiryStatus };

const stateToTypeMap: { [key in InquiryStatus]: StateIndicatorType } = {
  [InquiryStatus.Created]: StateIndicatorType.warning,
  [InquiryStatus.Completed]: StateIndicatorType.success,
  [InquiryStatus.Rejected]: StateIndicatorType.error,
  [InquiryStatus.Archived]: StateIndicatorType.warning
};

export const StateIndicatorInquiry = ({ state }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <StateIndicator type={stateToTypeMap[state] || StateIndicatorType.default}>
      {t(inquiryStates[state].nameI18nKey)}
    </StateIndicator>
  );
};
