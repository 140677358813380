import { OrganizationInviteFragment, PurchaseSimpleFragment, resolveError, Role, useMeQuery, UserFragment } from "api";
import { useAlert } from "hooks/useAlert";
import { useMemo } from "react";
import { useAuth } from "api/auth";

export const useMe = () => {
  const { showAlert } = useAlert();
  const { isLoggedIn } = useAuth();

  const { data, loading, refetch } = useMeQuery({
    skip: !isLoggedIn(),
    onError: e => resolveError(e, undefined, showAlert)
  });

  const me = useMemo(() => data?.me, [data]);

  const allOrganizations = useMemo(() => {
    return data?.me?.organizationMemberships.edges.flatMap(({ node }) => [node.organization]) || [];
  }, [data]);

  const activeOrganizations = useMemo(() => {
    return allOrganizations.filter(organization => organization.activatedAt);
  }, [allOrganizations]);

  const inactiveOrganization = useMemo(
    () => allOrganizations.find(organization => !organization.activatedAt),
    [allOrganizations]
  );

  const myOrganization = useMemo(() => {
    return activeOrganizations[0];
  }, [activeOrganizations]);

  const users: UserFragment[] = useMemo(() => {
    return myOrganization?.organizationMemberships.edges.map(edge => edge.node.user) || [];
  }, [myOrganization]);

  const isTradesman: boolean = useMemo(() => me?.role === Role.Manager && users.length === 1, [me, users]);

  const purchases: PurchaseSimpleFragment[] = useMemo(() => {
    return data?.me?.purchases.edges.map(edge => edge.node) || [];
  }, [data]);

  const receivedInvitations: OrganizationInviteFragment[] = useMemo<OrganizationInviteFragment[]>(() => {
    return data?.me?.receivedInvitations.edges.map(edge => edge.node) || [];
  }, [data]);

  return {
    me: {
      id: me?.id,
      role: me?.role,
      email: me?.email,
      firstName: me?.firstName,
      lastName: me?.lastName,
      isTradesman: isTradesman,
      language: me?.language
    },
    organizations: activeOrganizations,
    organization: {
      id: myOrganization?.id,
      credits: myOrganization?.credits || 0,
      users
    },
    defaultOrganization: {
      id: inactiveOrganization?.id,
      name: inactiveOrganization?.name,
      crn: inactiveOrganization?.crn
    },
    purchases,
    receivedInvitations,
    loading,
    refetch
  };
};
