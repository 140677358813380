import { Select, Spin } from "antd";
import { lg, pluralize } from "assets/translations";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useConstructionSiteNameQuery, useConstructionSitesForSelectQuery } from "api";

const FETCH_STEP: number = 8; // count of the sites to load during one fetch

type SelectConstructionSiteProps = {
  organizationId: string;
  /** API filters results by provided accountable manager ID */
  accountableManagerId?: string;
  /** Construction Site ID */
  value?: string;
  onChange: (constructionSiteId?: string) => void;
  disabled?: boolean;
};

export const SelectConstructionSite = ({
  organizationId,
  accountableManagerId,
  value,
  onChange,
  disabled = false
}: SelectConstructionSiteProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  useDebounce(() => setDebouncedSearchValue(searchValue), 500, [searchValue]);
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const fetchSkip: boolean = !organizationId || disabled;

  const { data: dataSearch, loading: loadingSearch } = useConstructionSitesForSelectQuery({
    variables: { organizationId, name: debouncedSearchValue, first: FETCH_STEP, accountableManagerId },
    fetchPolicy: "cache-and-network",
    skip: fetchSkip
  });

  const sites = useMemo(() => dataSearch?.organization?.constructionSites, [dataSearch]);
  const sitesMapped = useMemo(() => sites?.edges.flatMap(({ node }) => (node ? [node] : [])) || [], [sites]);
  const totalCountOfSites = sites?.totalCount;

  const options = useMemo(() => sitesMapped.map(result => ({ value: result.id, label: result.name })), [sitesMapped]);

  /* get name of the construction site which ID is provided as init value  */
  const { data: siteData, loading: loadingSiteData } = useConstructionSiteNameQuery({
    variables: { id: value || "" },
    fetchPolicy: "cache-first",
    skip: !value || isTouched
  });

  const constructionSiteName = siteData?.constructionSite?.name;

  useEffect(() => {
    if (isTouched) return;
    setDebouncedSearchValue(constructionSiteName);
  }, [isTouched, constructionSiteName]);

  const resetSearch = useCallback(() => {
    setIsTouched(true);
    setSearchValue(undefined);
  }, []);

  const handleSearch = useCallback((value: string) => {
    setIsTouched(true);
    setSearchValue(value);
  }, []);

  const handleChange = useCallback(
    (value: string) => {
      setIsTouched(true);
      onChange(value);
      resetSearch();
    },
    [onChange, resetSearch]
  );

  const loading = loadingSearch || loadingSiteData;

  return (
    <Select
      loading={loading}
      value={value}
      notFoundContent={loading ? <Spin size="small" className="w-full" /> : t(lg.searchSelect.common.noResult)}
      placeholder={t(lg.searchSelect.constructionSite.placeholder)}
      dropdownRender={menu => {
        if (options.length >= FETCH_STEP) {
          return (
            <div>
              {menu}
              <div className="text-gray-700 px-3">
                {t(
                  pluralize(
                    totalCountOfSites || 0,
                    lg.searchSelect.common.resultInfo.singularNominative,
                    lg.searchSelect.common.resultInfo.nominativePlural,
                    lg.searchSelect.common.resultInfo.genitivePlural,
                    lg.searchSelect.common.resultInfo.zero
                  ),
                  {
                    currentCount: options.length,
                    totalCount: totalCountOfSites
                  }
                )}
              </div>
            </div>
          );
        }

        return menu;
      }}
      filterOption={false}
      style={{ width: "100%" }}
      options={options}
      disabled={disabled}
      showSearch
      allowClear
      onSearch={handleSearch}
      onChange={handleChange}
    />
  );
};
