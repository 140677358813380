import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ParseMD } from "helpers/ParseMD";
import { lg } from "assets/translations/lg";
import { useConstructionPhaseDelete } from "hooks";

type PhaseDeleteProps = {
  visible: boolean;

  id: string;
  name: string;

  onClose: () => void;
  onSuccess?: () => void;
};

export const PhaseDelete = ({ id, name, visible, onClose, onSuccess }: PhaseDeleteProps) => {
  const { t } = useTranslation();
  const { data, handlers } = useConstructionPhaseDelete(id, onSuccess, onClose);

  return (
    <>
      <Modal
        title={
          <div className="flex items-center">
            <DeleteOutlined className="text-lg text-red-600" />
            <span className="ml-2">{t(lg.constructionSite.pageDetail.phases.modalDelete.title)}</span>
          </div>
        }
        open={visible}
        okText={t(lg.constructionSite.pageDetail.phases.modalDelete.buttonOkText)}
        cancelText={t(
          data.hasFailedRecord
            ? lg.constructionSite.pageDetail.phases.modalDelete.failedData.buttonOkText
            : lg.constructionSite.pageDetail.phases.modalDelete.buttonCancelText
        )}
        okButtonProps={{
          danger: true,
          style: { display: data.hasFailedRecord ? "none" : "inline-block" },
          disabled: data.sending
        }}
        cancelButtonProps={{
          type: data.hasFailedRecord ? "primary" : "default",
          disabled: data.sending
        }}
        onOk={handlers.handleClickOk}
        onCancel={onClose}
        closable={false}
        confirmLoading={data.sending}
        afterClose={handlers.handleAfterClose}
      >
        {/* default content */}
        {!data.hasFailedRecord && (
          <>
            <ParseMD>
              {t(lg.constructionSite.pageDetail.phases.modalDelete.description, {
                name
              })}
            </ParseMD>

            <div className="flex items-center mt-5">
              <InfoCircleOutlined className="text-red-600 text-lg" />
              <span className="font-medium ml-2">{t(lg.constructionSite.pageDetail.phases.modalDelete.warning)}</span>
            </div>
          </>
        )}

        {/* error content */}
        {data.hasFailedRecord && (
          <>
            <div>{t(lg.constructionSite.pageDetail.phases.modalDelete.failedData.description)}</div>

            <div className="mt-5">
              <div className="font-medium">
                {t(lg.constructionSite.pageDetail.phases.modalDelete.failedData.foundedRecords)}
              </div>
              <ul>{data.failedRecords?.map(failedRecord => <li key={failedRecord.id}>{failedRecord.name}</li>)}</ul>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
