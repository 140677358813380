import { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Alert, Button } from "antd";
import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Content } from "components/LayoutDashboard";
import { Section } from "components/Section";
import { GeneralInfoForm } from "components/DocumentationRecord/GeneralInfoForm";
import { VideoTypeRadioCards } from "components/DocumentationRecord/VideoTypeRadioCards";
import { ProgressSteps } from "components/DocumentationRecord/ProgressSteps";
import { ModalMove } from "components/DocumentationRecord/ModalMove";
import { TypeSelect } from "components/DocumentationRecord/TypeSelect";
import { Delete } from "components/DocumentationRecordDetail/Delete";
import { SubheaderOptions, useDocumentationRecordEdit, useSubheader } from "hooks";
import { DocumentationRecordType } from "api";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations/lg";
import { ModalSubmitOperator } from "components/DocumentationRecord/Actions/modals/ModalSubmitOperator";

type Props = RouteComponentProps<{ id: string }>; // id passed as URL parameter;

export const DocumentationRecordEdit = ({ id }: Props) => {
  if (!id) {
    throw new Error("Missing documentation record ID.");
  }

  const { t } = useTranslation();

  const {
    loading,
    disabled,
    inProgressSendToOperator,
    inProgressSave,
    form,
    formDefaultValues,
    data,
    state,
    handlers
  } = useDocumentationRecordEdit(id);

  const accountableManager = useMemo(() => data.record?.accountableManager, [data.record]);

  const {
    record,
    object,
    site,
    users,
    organization,
    canEditType,
    canSendToOperator,
    isSaveDisabled,
    isSaveAndSendDisabled,
    isSaveAndSendVisible,
    isDeleteVisible,
    isModalSubmitToOperatorVisible
  } = data;

  const { type, videoType } = state;

  const recordName = record?.name || "...";
  const objectName = object?.name || "...";
  const objectId = object?.id;
  const siteName = site?.name || "...";
  const siteId = site?.id;

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    return {
      heading: `${t(lg.documentationRecord.pageEdit.header.title)}: ${recordName}`,
      buttons: [
        <Button key={"cancel-editing"} size={"large"} disabled={disabled} onClick={handlers.handleClickBack}>
          {t(lg.documentationRecord.pageEdit.actions2.buttonAbortText)}
        </Button>
      ],
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        { value: siteName, route: siteId ? setRouteParams(routes.constructionSiteDetail, { id: siteId }) : undefined },
        {
          value: objectName,
          route:
            siteId && objectId
              ? setRouteParams(routes.constructionObjectDetail, {
                  constructionSiteId: siteId,
                  id: objectId
                })
              : undefined
        },
        { value: recordName, route: setRouteParams(routes.documentationRecordDetail, { id }) },
        { value: t(lg.documentationRecord.pageEdit.header.title) }
      ]
    };
  }, [t, recordName, disabled, handlers.handleClickBack, siteName, siteId, objectName, objectId, id]);
  useSubheader(subheaderOptions);

  return (
    <>
      <Content loading={loading} flexGrow={false}>
        {record && site && type && (
          <ProgressSteps
            documentationRecordType={type}
            documentationRecordState={record.state}
            autoRequestThreeD={site.autoRequestThreeD}
          />
        )}
      </Content>

      <Content loading={loading}>
        <Button size={"large"} disabled={disabled} onClick={handlers.handleClickBack}>
          <ArrowLeftOutlined />
          {t(lg.documentationRecord.pageEdit.actions1.buttonBack)}
        </Button>

        <Section title={t(lg.documentationRecord.pageEdit.baseInformation.title)} className="mt-10 mb-10">
          <div className="mt-10">
            <GeneralInfoForm
              form={form}
              initialValues={formDefaultValues}
              constructionSiteId={site?.id || ""}
              documentationRecordType={type}
              showPhaseSelect={false}
              users={users}
              disabled={disabled}
              onChange={handlers.handleChange}
              onSubmit={handlers.handleSubmit}
            />
          </div>
        </Section>

        {canEditType && (
          <TypeSelect
            type={type || DocumentationRecordType.NoThreeDNoVideo}
            disabled={disabled}
            onSelect={handlers.handleChangeDocumentationType}
          />
        )}

        {canEditType && type !== DocumentationRecordType.NoThreeDNoVideo && (
          <Section title={t(lg.documentationRecord.pageEdit.videoType.title)} className="mt-10">
            <VideoTypeRadioCards
              documentationRecordType={type}
              videoType={videoType}
              disabled={disabled}
              onSelect={handlers.handleChangeVideoType}
            />

            {!data.isValidVideoType && (
              <div className="flex justify-end mt-5">
                <Alert
                  message={t(lg.documentationRecord.pageEdit.customValidations.videoTypeRequired)}
                  type={"error"}
                />
              </div>
            )}
          </Section>
        )}

        {/* Validation message - "can't send because no accountable operator is assigned to the documentation record" */}
        {isSaveAndSendVisible && !canSendToOperator ? (
          <div className="flex justify-end mt-5">
            <Alert message={t(lg.documentationRecordActions.attribute.accountableOperator)} type={"error"} />
          </div>
        ) : null}

        <div className="flex flex-wrap justify-between mt-16">
          <div className={"space-x-5"}>
            {isDeleteVisible && record ? (
              <Delete
                id={record.id}
                name={record.name}
                accountableManagerId={accountableManager?.id || ""}
                threeDModel={record.threeDModel}
                onSuccess={handlers.handleDeleteSuccess}
              >
                <Button icon={<DeleteOutlined />} danger type="primary" size="large" disabled={disabled}>
                  {t(lg.documentationRecord.pageDetail.actions2.deleteButtonText)}
                </Button>
              </Delete>
            ) : null}

            <ModalMove organizationId={organization?.id || ""} documentationRecordId={id}>
              <Button size={"large"} disabled={disabled}>
                {t(lg.documentationRecord.pageEdit.actions2.buttonMoveDocumentationText)}
              </Button>
            </ModalMove>
          </div>

          <div className={"space-x-5"}>
            <Button
              size={"large"}
              icon={<SaveOutlined />}
              disabled={disabled || isSaveDisabled}
              loading={inProgressSave}
              onClick={handlers.handleClickSave}
            >
              {t(lg.documentationRecord.pageEdit.actions2.buttonSaveText)}
            </Button>
            {isSaveAndSendVisible && (
              <Button
                size={"large"}
                type={"primary"}
                icon={<SaveOutlined />}
                disabled={disabled || isSaveAndSendDisabled}
                loading={inProgressSendToOperator}
                onClick={handlers.handleClickSaveAndSend}
              >
                {t(lg.documentationRecord.pageEdit.actions2.buttonSaveAndSendText)}
              </Button>
            )}
          </div>
        </div>
      </Content>

      <ModalSubmitOperator
        loading={inProgressSendToOperator}
        visible={isModalSubmitToOperatorVisible}
        onSubmit={handlers.handleModalSubmitToOperatorOk}
        onCancel={handlers.handleModalSubmitToOperatorCancel}
      />
    </>
  );
};
