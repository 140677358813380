import { useCallback, useMemo, useState } from "react";
import { useMe, useOrganizationResolveInvite } from "hooks";
import { InviteResolution, OrganizationInviteFragment } from "api";
import { navigate } from "@reach/router";
import { routes } from "routes";
import { getSkippedInvitations, setSkippedInvitations } from "api/storage";

export const useInvitation = (id: string) => {
  const { resolveInvite, loading: loadingResolveInviteMutation } = useOrganizationResolveInvite();

  const redirect = useCallback(() => {
    navigate(routes.todo, { replace: true });
  }, []);

  /** STATE */
  const [selectedInviteResolution, setSelectedInviteResolution] = useState<InviteResolution>();

  /** DATA */
  const { receivedInvitations, loading: loadingMe, refetch: refetchMe } = useMe();
  const invitation = useMemo<OrganizationInviteFragment | undefined>(
    () => receivedInvitations.find(invite => invite.id === id),
    [id, receivedInvitations]
  );

  const loading = useMemo<boolean>(() => loadingMe, [loadingMe]);
  const sendingAccept = useMemo<boolean>(
    () => loadingResolveInviteMutation && selectedInviteResolution === InviteResolution.Accept,
    [loadingResolveInviteMutation, selectedInviteResolution]
  );
  const sendingReject = useMemo<boolean>(
    () => loadingResolveInviteMutation && selectedInviteResolution === InviteResolution.Reject,
    [loadingResolveInviteMutation, selectedInviteResolution]
  );
  const disabled = useMemo<boolean>(
    () => loading || sendingAccept || sendingReject,
    [loading, sendingAccept, sendingReject]
  );

  /** HANDLERS */
  const handleClickAcceptInvite = useCallback(async () => {
    setSelectedInviteResolution(InviteResolution.Accept);
    const wasResolved = await resolveInvite(id, InviteResolution.Accept);
    await refetchMe();
    wasResolved && redirect();
  }, [resolveInvite, id, redirect, refetchMe]);

  const handleClickRejectInvite = useCallback(async () => {
    setSelectedInviteResolution(InviteResolution.Reject);
    const wasResolved = await resolveInvite(id, InviteResolution.Reject);
    wasResolved && redirect();
  }, [resolveInvite, id, redirect]);

  const handleClickNotNow = useCallback(() => {
    const previous = getSkippedInvitations() || [];
    const updatedSkippedIds = Array.from(new Set([...previous, id]));
    setSkippedInvitations(updatedSkippedIds);

    redirect();
  }, [redirect, id]);

  return {
    loading,
    sendingAccept,
    sendingReject,
    disabled,
    redirect,
    data: { invitation },
    handlers: {
      handleClickAcceptInvite,
      handleClickRejectInvite,
      handleClickNotNow
    }
  };
};
