import { ConstructionSite, resolveError, useOutputExtensionDeleteMutation } from "api";

import { cloneElement, ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { lg } from "assets/translations";
import { ParseMD } from "helpers";
import { useAlert } from "hooks";

type ModalDeleteProps = {
  id: string;
  name: ConstructionSite["name"];
  children: ReactElement;
  onSuccess?: () => any;
  onError?: () => any;
};

export const ModalDelete = ({ id, name, children, onSuccess = () => {}, onError = () => {} }: ModalDeleteProps) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const open = useCallback(() => setVisible(true), []);

  const [mutate, { loading: sending }] = useOutputExtensionDeleteMutation();
  const { showAlert } = useAlert();

  const handleDelete = useCallback(async () => {
    try {
      const response = await mutate({ variables: { input: { outputExtension: id } } });
      const success = response.data?.outputExtension?.delete?.success;

      if (!success) {
        // TODO: handle success mutation but not success value from the response
        resolveError(new Error("not_deleted"), {}, showAlert);
        return;
      }

      window.setTimeout(onSuccess, 300);
      setVisible(false);
    } catch (e) {
      resolveError(e, {}, showAlert);
      onError();
    }
  }, [onError, onSuccess, id, mutate, showAlert]);

  const handleClickOk = useCallback(() => handleDelete(), [handleDelete]);
  const handleClickCancel = useCallback(() => setVisible(false), []);

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center">
            <DeleteOutlined className="text-lg text-red-600" />
            <span className="ml-4">{t(lg.documentationRecord.pageDetail.outputExtensions.modals.delete.title)}</span>
          </div>
        }
        open={visible}
        okText={t(lg.documentationRecord.pageDetail.outputExtensions.modals.delete.buttonOkText)}
        cancelText={t(lg.documentationRecord.pageDetail.outputExtensions.modals.delete.buttonCancelText)}
        okButtonProps={{
          danger: true,
          disabled: sending
        }}
        cancelButtonProps={{
          type: "default",
          disabled: sending
        }}
        onOk={handleClickOk}
        onCancel={handleClickCancel}
        confirmLoading={sending}
      >
        <ParseMD>
          {t(lg.documentationRecord.pageDetail.outputExtensions.modals.delete.description, {
            name
          })}
        </ParseMD>

        <div className="flex items-center mt-5">
          <InfoCircleOutlined className="text-red-600 text-lg" />
          <span className="font-medium ml-2">
            {t(lg.documentationRecord.pageDetail.outputExtensions.modals.delete.warning)}
          </span>
        </div>
      </Modal>
    </>
  );
};
