import { ComponentPropsWithoutRef, ReactNode, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { Menu } from "antd";
import {
  BankOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  RocketOutlined,
  TeamOutlined,
  WalletOutlined
} from "@ant-design/icons";
import { CustomIcon } from "components/icons/CustomIcon";
import { useMe } from "hooks";
import { Role } from "api";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations";
import { useLocation } from "react-use";

type MenuItem = {
  value: string;
  route: string;
  roles: Role[];
  icon?: ReactNode;
  dataCy: string;
};

export const MenuMain = () => {
  const { t } = useTranslation();
  const { me, organization } = useMe();
  const location = useLocation();

  const items = useMemo<MenuItem[]>(
    () => [
      {
        value: t(lg.mainMenu.todo),
        route: routes.todo,
        roles: [Role.Manager, Role.Reader, Role.ThreeDOperator, Role.Surveyor, Role.Operator],
        icon: <FileDoneOutlined className="text-base" />,
        dataCy: "main-menu-todo"
      },
      {
        value: t(lg.mainMenu.todoAll),
        route: routes.todoAll,
        roles: [Role.ThreeDOperator],
        icon: <ReadOutlined className="text-base" />,
        dataCy: "main-menu-todo-all"
      },
      {
        value: t(lg.mainMenu.documentationRecords),
        route: routes.documentationRecords,
        roles: [Role.Manager, Role.Reader, Role.Surveyor, Role.Operator],
        icon: <ReadOutlined className="text-base" />,
        dataCy: "main-menu-documentation-records"
      },
      {
        value: t(lg.mainMenu.threeDModels),
        route: routes.threeDModels,
        roles: [Role.Manager, Role.Reader],
        icon: <CustomIcon className="text-base" type="model-geo" />,
        dataCy: "main-menu-3d-model"
      },
      {
        value: t(lg.mainMenu.threeDModelsCompleted),
        route: routes.threeDModelsCompleted,
        roles: [Role.ThreeDOperator],
        icon: <CustomIcon className="text-base" type="model-geo" />,
        dataCy: "main-menu-3d-model-completed"
      },
      {
        value: t(lg.mainMenu.additionalServices),
        route: routes.additionalServicesReview,
        roles: [Role.ThreeDOperator],
        icon: <RocketOutlined className="text-base" />,
        dataCy: "main-menu-additional-services"
      },
      {
        value: t(lg.mainMenu.constructionSites),
        route: routes.constructionSites,
        roles: [Role.Manager, Role.Reader],
        icon: <BankOutlined className="text-base" />,
        dataCy: "main-menu-construction-sites"
      },
      {
        value: t(lg.mainMenu.organization),
        route: setRouteParams(routes.organization, { id: organization.id || "" }),
        roles: [Role.Manager, Role.Reader],
        icon: <TeamOutlined className="text-base" />,
        dataCy: "main-menu-organization"
      },
      {
        value: t(lg.mainMenu.organizations),
        route: routes.organizations,
        roles: [Role.Surveyor, Role.Operator],
        icon: <TeamOutlined className="text-base" />,
        dataCy: "main-menu-organizations"
      },
      {
        value: t(lg.mainMenu.pricing),
        route: routes.pricing,
        roles: [Role.Manager, Role.Reader],
        icon: <WalletOutlined className="text-base" />,
        dataCy: "main-menu-pricing"
      },
      {
        value: t(lg.mainMenu.additionalServices),
        route: routes.additionalServices,
        roles: [Role.Manager, Role.Reader],
        icon: <RocketOutlined className="text-base" />,
        dataCy: "main-menu-additionalServices"
      },
      {
        value: t(lg.mainMenu.about),
        route: routes.about,
        roles: [Role.Manager, Role.Reader, Role.Surveyor, Role.Operator],
        icon: <ExclamationCircleOutlined className="text-base" />,
        dataCy: "main-menu-about"
      },
      {
        value: t(lg.mainMenu.support),
        route: routes.support,
        roles: [Role.Manager, Role.Reader, Role.Surveyor, Role.Operator],
        icon: <QuestionCircleOutlined className="text-base" />,
        dataCy: "main-menu-support"
      }
    ],
    [organization, t]
  );

  const activeRoute = useMemo<string>(
    () =>
      items
        // get routes starts by current path name
        .flatMap(item => (location.pathname?.startsWith(item.route) ? item.route : []))
        // get longest available route
        .reduce((a, b) => (a.length > b.length ? a : b), ""),

    [items, location.pathname]
  );

  const handleClick: Required<ComponentPropsWithoutRef<typeof Menu>>["onClick"] = useCallback(
    ({ key }) => navigate(key),
    []
  );

  return (
    <Menu
      theme={"dark"}
      className="flex-grow z-20"
      onClick={handleClick}
      selectedKeys={[activeRoute]}
      items={items
        .filter(({ roles }) => me.role && roles.includes(me.role))
        .map(({ route, value, icon, dataCy }) => ({
          key: route,
          // override hardcoded antd height by "h-auto"
          // fixes multiline content
          style: { minHeight: 40 },
          className: "flex items-center my-0 h-auto leading-none",
          icon: icon,
          label: (
            <span className="font-medium whitespace-normal leading-normal py-2" data-cy={dataCy}>
              {value}
            </span>
          )
        }))}
    />
  );
};
