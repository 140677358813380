import { PropsWithChildren } from "react";
import { DocumentationRecordState } from "api";
import { useTranslation } from "react-i18next";
import { StateIndicator, StateIndicatorType } from "components/Indicators/State/StateIndicator";
import { documentationRecordStates } from "api/enums";

type Props = { state: DocumentationRecordState };

const stateToTypeMap: { [key in DocumentationRecordState]: StateIndicatorType } = {
  [DocumentationRecordState.Created]: StateIndicatorType.warning,
  [DocumentationRecordState.RequiresOperator]: StateIndicatorType.warning,
  [DocumentationRecordState.RequiresSurveyor]: StateIndicatorType.warning,
  [DocumentationRecordState.RequiresThreeDOperator]: StateIndicatorType.warning,
  [DocumentationRecordState.RequiresManagerConfirmation]: StateIndicatorType.warning,
  [DocumentationRecordState.Finished]: StateIndicatorType.success,
  [DocumentationRecordState.Rejected]: StateIndicatorType.error,
  [DocumentationRecordState.Archived]: StateIndicatorType.default
};

export const StateIndicatorDocumentationRecord = ({ state }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <StateIndicator type={stateToTypeMap[state] || StateIndicatorType.default}>
      {t(documentationRecordStates[state].nameI18nKey)}
    </StateIndicator>
  );
};
