import {
  ConstructionObject,
  ConstructionPhase,
  ConstructionSite,
  DocumentationRecord,
  DocumentationRecordState,
  DocumentationRecordType,
  DocumentationRecordVideo,
  DocumentationRecordVideoType,
  DocumentationRecordVideoTypeFilterEnum,
  DownloadableFile,
  Maybe,
  Organization,
  TaskFragment,
  ThreeDModelFragment
} from "api";
import { Modify } from "helpers/types";
import { ThreeDModelType } from "api/enums";

export type ThreeDModelExtended = Modify<
  ThreeDModelFragment,
  {
    type?: ThreeDModelType;
    files?: Maybe<Maybe<DownloadableFile>[]>;
    organization: Pick<Organization, "id" | "name">;
    constructionSite: Pick<ConstructionSite, "id" | "name">;
    constructionObject: Pick<ConstructionObject, "id" | "name">;
    documentationRecord: Pick<DocumentationRecord, "id" | "name"> & { lastStateChangeAt?: string };
    constructionPhase: Pick<ConstructionPhase, "id" | "name">;
  }
>;

export const getThreeDModelType = (record: {
  type: DocumentationRecordType;
  documentationRecordVideo: Maybe<Pick<DocumentationRecordVideo, "type">>;
}): ThreeDModelType | undefined => {
  if (
    (record.type === DocumentationRecordType.NoThreeDVideo &&
      record.documentationRecordVideo?.type === DocumentationRecordVideoType.NoScale) ||
    (record.type === DocumentationRecordType.ThreeDNoScale &&
      record.documentationRecordVideo?.type === DocumentationRecordVideoType.NoScale)
  ) {
    return ThreeDModelType.Simple;
  }

  if (
    (record.type === DocumentationRecordType.NoThreeDVideo &&
      record.documentationRecordVideo?.type === DocumentationRecordVideoType.Scale) ||
    (record.type === DocumentationRecordType.ThreeDNoScale &&
      record.documentationRecordVideo?.type === DocumentationRecordVideoType.Scale)
  ) {
    return ThreeDModelType.Scale;
  }

  if (
    record.type === DocumentationRecordType.ThreeDScale &&
    record.documentationRecordVideo?.type === DocumentationRecordVideoType.Maps
  ) {
    return ThreeDModelType.Maps;
  }

  if (
    record.type === DocumentationRecordType.ThreeDScale &&
    record.documentationRecordVideo?.type === DocumentationRecordVideoType.Spray
  ) {
    return ThreeDModelType.Spray;
  }

  if (
    record.type === DocumentationRecordType.ThreeDScale &&
    record.documentationRecordVideo?.type === DocumentationRecordVideoType.QrCodes
  ) {
    return ThreeDModelType.QrCodes;
  }
};

export const modelTypeToVideoTypeFilterEnum = (modelType: ThreeDModelType): DocumentationRecordVideoTypeFilterEnum => {
  switch (modelType) {
    case ThreeDModelType.Simple:
      return DocumentationRecordVideoTypeFilterEnum.NoScale;
    case ThreeDModelType.QrCodes:
      return DocumentationRecordVideoTypeFilterEnum.QrCodes;
    case ThreeDModelType.Spray:
      return DocumentationRecordVideoTypeFilterEnum.Spray;
    case ThreeDModelType.Scale:
      return DocumentationRecordVideoTypeFilterEnum.Scale;
    case ThreeDModelType.Maps:
      return DocumentationRecordVideoTypeFilterEnum.Maps;
  }
};

export type ThreeDModelFileType = "model" | "texture" | "xyz";

export const getFileType = (fileName: string): ThreeDModelFileType | undefined => {
  const extension = fileName.split(".").pop();

  if (extension === "obj" || extension === "fbx" || extension === "glb") return "model";
  if (extension === "jpg" || extension === "jpeg" || extension === "png") return "texture";
  if (extension === "xyz" || extension === "txt") return "xyz";
};

export const getThreeDModelsFromTasks = (tasks: TaskFragment[]): ThreeDModelExtended[] => {
  return tasks.flatMap(documentationRecord => {
    if (
      documentationRecord.state === DocumentationRecordState.Archived ||
      documentationRecord.threeDModel === null ||
      documentationRecord.threeDModel.archivedAt
    ) {
      return [];
    }

    return [
      {
        id: documentationRecord.threeDModel.id,
        createdAt: documentationRecord.threeDModel.createdAt,
        archivedAt: documentationRecord.threeDModel.archivedAt,
        isShared: documentationRecord.threeDModel.isShared,
        organization: {
          id: documentationRecord.constructionObject.constructionSite.organization.id,
          name: documentationRecord.constructionObject.constructionSite.organization.name
        },
        constructionSite: {
          id: documentationRecord.constructionObject.constructionSite.id,
          name: documentationRecord.constructionObject.constructionSite.name
        },
        constructionObject: {
          id: documentationRecord.constructionObject.id,
          name: documentationRecord.constructionObject.name
        },
        documentationRecord: {
          id: documentationRecord.id,
          name: documentationRecord.name,
          lastStateChangeAt: documentationRecord.lastStateChange?.createdAt
        },
        constructionPhase: {
          id: documentationRecord.constructionPhase.id,
          name: documentationRecord.constructionPhase.name
        },
        files: documentationRecord.threeDModel.files,
        type: getThreeDModelType(documentationRecord),
        coordinateSystem: documentationRecord.threeDModel.coordinateSystem,
        modelFile: documentationRecord.threeDModel.modelFile,
        textureFile: documentationRecord.threeDModel.textureFile,
        coordinatesFile: documentationRecord.threeDModel.coordinatesFile
      }
    ];
  });
};

export const getThreeDViewerModelUrl = (viewerUrl: string, modelCode: string, locale: string): undefined | string => {
  if (viewerUrl.length <= 0 || modelCode.length <= 0 || locale.length <= 0) return; // handle empty strings
  return `${viewerUrl}?id=${modelCode}&locale=${locale}`;
};

export const hasFilesForDownload = (record: { files?: Maybe<Maybe<DownloadableFile>[]> }): boolean =>
  !!record.files && record.files.length > 0;
