import { cloneElement, PropsWithChildren, ReactElement } from "react";
import { Form, Input, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { InfoCircleOutlined, UserAddOutlined } from "@ant-design/icons";
import { User } from "api";
import clsx from "clsx";
import { InviteUserFormFields, useConfig, useOrganizationInviteUser } from "hooks";
import { useFormat } from "hooks/useFormat";
import { OrganizationInviteUserError, roles } from "api/enums";

type Props = {
  children: ReactElement;
  organizationId: string;
  credits: number;
  user?: Pick<User, "email" | "role">;
};

export const OrganizationInviteUser = ({ children, organizationId, credits, user }: PropsWithChildren<Props>) => {
  const [t] = useTranslation();

  const { sending, disabled, form, state, data, open, handlers } = useOrganizationInviteUser(
    organizationId,
    user?.email,
    user?.role
  );

  const { creditCost } = useFormat();
  const { config } = useConfig();

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center" data-cy={"modal-invite-user"}>
            <UserAddOutlined className="text-lg text-purple-600" />
            <span className="ml-4">{t(lg.organization.modals.inviteUser.title)}</span>
          </div>
        }
        open={state.visible}
        okText={t(lg.organization.modals.inviteUser.buttonOkText)}
        cancelText={
          state.existInviteErrors
            ? t(lg.organization.modals.inviteUser.buttonCloseText)
            : t(lg.organization.modals.inviteUser.buttonCancelText)
        }
        okButtonProps={{
          type: "primary",
          disabled: !state.canSend || disabled,
          className: state.existInviteErrors ? "hidden" : "",
          id: "cy-action-send"
        }}
        cancelButtonProps={{ type: state.existInviteErrors ? "primary" : "default", id: "cy-action-cancel" }}
        onOk={handlers.handleOk}
        onCancel={handlers.handleCancel}
        closable={false}
        confirmLoading={sending}
        afterClose={handlers.handleAfterClose}
        destroyOnClose
      >
        <Form
          form={form}
          autoComplete="off"
          layout={"vertical"}
          onFieldsChange={handlers.handleChange}
          initialValues={data.defaultValues}
          data-cy={"form-invite-user"}
        >
          <div className="flex flex-col md:flex-row -m-2">
            <Form.Item
              className="flex-0 w-full md:w-2/3 p-2"
              label={
                <div>
                  {t(lg.organization.modals.inviteUser.form.email.label)}{" "}
                  <span className="text-secondary">({t(lg.form.required)})</span>
                </div>
              }
              name={InviteUserFormFields.email}
              rules={[
                {
                  type: "email",
                  message: t(lg.organization.modals.inviteUser.form.email.validations.wrongFormat)
                }
              ]}
              validateStatus={state.existInviteErrors ? "error" : undefined}
              hasFeedback={state.existInviteErrors}
              help={
                state.existInviteErrors ? t(lg.organization.modals.inviteUser.errors.cantInviteThisEmail) : undefined
              }
            >
              <Input autoComplete="off" data-cy={"input-email"} />
            </Form.Item>

            <Form.Item
              className="flex-0 w-full md:w-1/3 p-2"
              label={
                <div>
                  {t(lg.organization.modals.inviteUser.form.role.label)}{" "}
                  <span className="text-secondary">({t(lg.form.required)})</span>
                </div>
              }
              name={InviteUserFormFields.role}
            >
              <Select data-cy={"select-role"}>
                {data.allowedRoles.map(value => (
                  <Select.Option key={value} value={value} data-cy={`select-role-option-${value}`}>
                    {t(roles[value].nameI18nKey)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {!state.existInviteErrors && (
            <Form.Item
              label={t(lg.organization.modals.inviteUser.form.comment.label)}
              name={InviteUserFormFields.comment}
            >
              <Input.TextArea
                placeholder={t(lg.organization.modals.inviteUser.form.comment.placeholder)}
                rows={3}
                data-cy={"textarea-comment"}
              />
            </Form.Item>
          )}
        </Form>

        {!state.existInviteErrors && state.canShowCreditInfo && (
          <div className="flex text-right">
            <div className="mr-1">
              <div>{t(lg.organization.modals.inviteUser.credits.actualState)}:</div>
              <div className="font-medium mt-2">{t(lg.organization.modals.inviteUser.credits.price)}:</div>
            </div>
            <div className="text-purple-600">
              <div data-credit-cost={credits} data-cy={"credit-cost"}>
                {creditCost(credits)}
              </div>
              <div className="font-medium mt-2" data-invite-price={data.invitePrice} data-cy={"invite-price"}>
                {data.invitePrice ? creditCost(data.invitePrice) : "-"}
              </div>
            </div>
          </div>
        )}
        {/* Errors */}
        {data.inviteErrors?.map((errorCode, index) => {
          return (
            <div className={clsx("flex items-center", index > 0 ? "mt-3" : "mt-5")} key={errorCode}>
              <InfoCircleOutlined className="text-lg text-red-600" />
              <span className="ml-2">{t(getErrorI18nKey(errorCode))}</span>
              {errorCode === OrganizationInviteUserError.NotEnoughCredits && (
                <a href={config.shopUrl} target="_blank" rel="noopener noreferrer" className="c-link ml-1">
                  {t(lg.organization.modals.inviteUser.linkBuyCredits)}
                </a>
              )}
            </div>
          );
        })}
      </Modal>
    </>
  );
};

const getErrorI18nKey = (code: OrganizationInviteUserError): string => {
  if (code === OrganizationInviteUserError.NotFound) {
    return lg.organization.modals.inviteUser.errors.notFound;
  }
  if (code === OrganizationInviteUserError.PermissionDenied) {
    return lg.organization.modals.inviteUser.errors.permissionDenied;
  }
  if (code === OrganizationInviteUserError.RoleNotAllowed) {
    return lg.organization.modals.inviteUser.errors.roleNotAllowed;
  }
  if (code === OrganizationInviteUserError.EmailInvalid) {
    return lg.organization.modals.inviteUser.errors.emailInvalid;
  }
  if (code === OrganizationInviteUserError.AlreadyMember) {
    return lg.organization.modals.inviteUser.errors.alreadyMember;
  }
  if (code === OrganizationInviteUserError.RoleMismatch) {
    return lg.organization.modals.inviteUser.errors.roleMismatch;
  }
  if (code === OrganizationInviteUserError.AlreadyInvited) {
    return lg.organization.modals.inviteUser.errors.alreadyInvited;
  }
  if (code === OrganizationInviteUserError.NotEnoughCredits) {
    return lg.organization.modals.inviteUser.errors.notEnoughCredits;
  }
  return "";
};
