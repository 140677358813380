import { MediaCard } from "components/MediaCard";

import { Maybe, Media } from "api";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";

type ThreeDModelUploadedOverviewProps = {
  modelFile?: Maybe<Pick<Media, "fileName" | "id" | "url">>;
  coordinatesFile?: Maybe<Pick<Media, "fileName" | "id" | "url">>;
  textureFile?: Maybe<Pick<Media, "fileName" | "id" | "url">>;
  className?: string;
};

export const ThreeDModelUploadOverview = ({
  modelFile,
  coordinatesFile,
  textureFile,
  className
}: ThreeDModelUploadedOverviewProps) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className="text-blue-700 text-base font-medium mt-10">
        {t(lg.taskThreeDOperator.threeDModelUploadOverview.title)}
      </div>
      <div className="c-grid mt-2">
        <div className="c-grid-column">
          <div className="font-medium">{t(lg.taskThreeDOperator.threeDModelUploadOverview.modelFile)}</div>
          {modelFile ? (
            <MediaCard
              fileName={modelFile.fileName || "-"}
              fileId={modelFile.id}
              fileUrl={modelFile.url || undefined}
            />
          ) : (
            "-"
          )}
          <div className="font-medium mt-5">
            {t(lg.taskThreeDOperator.threeDModelUploadOverview.coordinateSystemFile)}
          </div>
          {coordinatesFile ? (
            <MediaCard
              fileName={coordinatesFile.fileName || "-"}
              fileId={coordinatesFile.id}
              fileUrl={coordinatesFile.url || undefined}
            />
          ) : (
            "-"
          )}
        </div>

        <div className="c-grid-column">
          <div className="font-medium">{t(lg.taskThreeDOperator.threeDModelUploadOverview.textureFile)}</div>
          {textureFile ? (
            <MediaCard
              fileName={textureFile.fileName || "-"}
              fileId={textureFile.id}
              fileUrl={textureFile.url || undefined}
            />
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
  );
};
