import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { ActionProps } from "components/DocumentationRecord/Actions/index";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import { Triggers } from "api";
import { lg } from "assets/translations";
import { documentationRecordTriggers } from "api/enums";

/** For Trigger SUBMIT_FINISHED */
export const SubmitFinished = ({
  documentationRecordId,
  triggers,
  disabled = false,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {},
  onTriggerError = () => {}
}: ActionProps) => {
  const { t } = useTranslation();

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.SubmitFinished,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    onAfterTrigger,
    onTriggerError
  );

  const handleClick = useCallback(() => callTrigger(), [callTrigger]);

  if (!available) return null;

  return (
    <Button type="primary" onClick={handleClick} size="large" disabled={disabled || !conditions || loading}>
      {t(!loading ? documentationRecordTriggers[Triggers.SubmitFinished].buttonI18nKey : lg.saving)}
      {!loading ? <ArrowRightOutlined /> : <LoadingOutlined />}
    </Button>
  );
};
