import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "@reach/router";
import { Section } from "components/Section";
import { lg } from "assets/translations/lg";
import {
  DocumentationRecordOrderByEnum,
  DocumentationRecordStateFilterEnum,
  DocumentationRecordTypeFilterEnum,
  DocumentationRecordVideoType,
  DocumentationRecordVideoTypeFilterEnum,
  Maybe,
  Media,
  resolveError,
  TaskFragment,
  ThreeDModel,
  useAllTasksForThreeDOperatorQuery,
  UseCaseEvent
} from "api";
import { useAlert, useFormat, useLogEvent, useMe, useTableDataStatus } from "hooks";
import { Table } from "antd";
import { Link } from "components/Link";
import { enumToValues, getUserFullName, openInNewTab, TableProps, useColumnSearchProps, usePagination } from "helpers";
import { routes, setRouteParams } from "routes";
import { StateIndicatorDocumentationRecord } from "components/Indicators/State";
import {
  documentationRecordStateFilters,
  documentationRecordTypeFilters,
  documentationRecordVideoTypesFilter
} from "api/enums";
import { TypeIndicatorDocumentationRecord, TypeIndicatorDocumentationVideo } from "components/Indicators/Type";
import { SimpleLoader } from "components/SimpleLoader";
import { Illustration } from "components/Illustration";

type TableItem = Pick<TaskFragment, "id" | "name" | "createdAt" | "state" | "type"> & {
  submitterName: string; // organization name
  videoType?: Maybe<DocumentationRecordVideoType>;
  processedBy?: string; // accountable 3D operator full name
  processedById?: string; // accountable 3D operator ID
  threeDModel: Maybe<Pick<ThreeDModel, "id"> & { modelFile: Maybe<Pick<Media, "id" | "url">> }>;
};

type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  name: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  submitterName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteOrganizationName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteOrganizationNameDesc]
  },
  createdAt: {
    ascend: [DocumentationRecordOrderByEnum.CreatedAt],
    descend: [DocumentationRecordOrderByEnum.CreatedAtDesc]
  }
};

type Props = RouteComponentProps & {};

export const TodoAllThreeDOperator: FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const { formatDate } = useFormat();
  const { showAlert } = useAlert();
  const { me } = useMe();

  const [nameFilter, setNameFilter] = useState("");
  const [submitterNameFilter, setSubmitterNameFilter] = useState("");
  const [processedByNameFilter, setProcessedByNameFilter] = useState("");
  const nameSearchProps = useColumnSearchProps();
  const submitterNameSearchProps = useColumnSearchProps();
  const processedByNameSearchProps = useColumnSearchProps();
  const [typesFilter, setTypesFilter] = useState<DocumentationRecordTypeFilterEnum[]>([]);
  const [videoTypesFilter, setVideoTypesFilter] = useState<DocumentationRecordVideoTypeFilterEnum[]>([]);
  const [statesFilter, setStatesFilter] = useState<DocumentationRecordStateFilterEnum[]>([]);
  const [orderBy, setOrderBy] = useState<DocumentationRecordOrderByEnum[]>(orderByMap.createdAt.descend);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });

  const { logEvent } = useLogEvent();
  const handleClickViewThreeDModel = useCallback(
    async (modelId: string) => {
      await logEvent(UseCaseEvent.ThreeDModelView);
      openInNewTab(setRouteParams(routes.threeDViewer, { id: modelId }));
    },
    [logEvent]
  );

  const { data, loading } = useAllTasksForThreeDOperatorQuery({
    variables: {
      skip: skip,
      orderBy: orderBy,
      name: nameFilter,
      organizationName: submitterNameFilter,
      threeDOperatorName: processedByNameFilter,
      types: typesFilter,
      videoTypes: videoTypesFilter,
      states: statesFilter
    },
    fetchPolicy: "cache-and-network",
    onError: e => resolveError(e, undefined, showAlert)
  });

  const tasks = useMemo(() => data?.me?.tasks, [data]);
  const tasksMapped = useMemo(() => tasks?.edges.flatMap(({ node }) => (node ? [node] : [])), [tasks]);
  useEffect(() => setTotal(tasks?.totalCount), [tasks]);

  const tableData = useMemo<TableItem[]>(
    () =>
      tasksMapped?.map(task => ({
        ...task,
        submitterName: task.constructionObject.constructionSite.organization.name,
        processedBy: task.accountableThreeDOperator ? getUserFullName(task.accountableThreeDOperator) : undefined,
        processedById: task.accountableThreeDOperator?.id,
        videoType: task.documentationRecordVideo?.type,
        threeDModel: task.threeDModel
      })) || [],
    [tasksMapped]
  );

  const columns = useMemo<TablePropType["columns"]>(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: t(lg.typenames.documentationRecord),
        render: (name, { id, processedById }) => {
          const isMyTask: boolean = processedById === me.id;
          const route: string = isMyTask ? routes.taskThreeDOperator : routes.taskThreeDOperatorFromAllTodoPage;
          return <Link href={setRouteParams(route, { id })}>{name}</Link>;
        },
        ...nameSearchProps,
        ellipsis: true,
        sorter: true
      },
      {
        key: "submitterName",
        dataIndex: "submitterName",
        title: t(lg.typenames.submitter),
        render: submitterName => <span className="text-secondary">{submitterName}</span>,
        ...submitterNameSearchProps,
        ellipsis: true,
        sorter: true
      },
      {
        key: "createdAt",
        dataIndex: "createdAt",
        title: t(lg.todoAll.tasksOverview.table.header.createdAt),
        render: createdAt => <span className="text-secondary">{formatDate(createdAt, "machine", true)}</span>,
        ellipsis: true,
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        key: "state",
        dataIndex: "state",
        title: t(lg.typenames.documentationState),
        render: state => <StateIndicatorDocumentationRecord state={state} />,
        filters: enumToValues<DocumentationRecordStateFilterEnum>(DocumentationRecordStateFilterEnum)
          .filter(s => s !== DocumentationRecordStateFilterEnum.Archived)
          .map(type => ({
            text: t(documentationRecordStateFilters[type].nameI18nKey),
            value: type
          })),
        ellipsis: true
      },
      {
        key: "type",
        dataIndex: "type",
        title: t(lg.typenames.documentationType),
        render: type => <TypeIndicatorDocumentationRecord type={type} />,
        filters: enumToValues<DocumentationRecordTypeFilterEnum>(DocumentationRecordTypeFilterEnum).map(type => ({
          text: t(documentationRecordTypeFilters[type].nameI18nKey),
          value: type
        }))
      },
      {
        key: "videoType",
        dataIndex: "videoType",
        title: t(lg.typenames.videoType),
        render: videoType => (videoType ? <TypeIndicatorDocumentationVideo type={videoType} /> : "-"),
        filters: enumToValues<DocumentationRecordVideoTypeFilterEnum>(DocumentationRecordVideoTypeFilterEnum).map(
          type => ({
            text: t(documentationRecordVideoTypesFilter[type].nameI18nKey),
            value: type
          })
        )
      },
      {
        key: "processedBy",
        dataIndex: "processedBy",
        title: t(lg.todoAll.tasksOverview.table.header.processedBy),
        render: processedBy => <span className="text-secondary">{processedBy || "-"}</span>,
        ...processedByNameSearchProps
      },
      {
        key: "threeDModel",
        dataIndex: "threeDModel",
        title: t(lg.typenames.threeDModel),
        render: threeDModel => {
          if (!threeDModel || !threeDModel?.id || !threeDModel?.modelFile?.url) return "-";
          return (
            <button className="c-link" onClick={() => handleClickViewThreeDModel(threeDModel.id)}>
              {t(lg.table.actions.show)}
            </button>
          );
        }
      }
    ],
    [
      t,
      me.id,
      formatDate,
      nameSearchProps,
      submitterNameSearchProps,
      processedByNameSearchProps,
      handleClickViewThreeDModel
    ]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setNameFilter(filter.name ? filter.name[0] : "");
      setTypesFilter(filter.type || []);
      setVideoTypesFilter(filter.videoType || []);
      setStatesFilter(filter.state || []);
      setSubmitterNameFilter(filter.submitterName ? filter.submitterName[0] : "");
      setProcessedByNameFilter(filter.processedBy ? filter.processedBy[0] : "");
    },
    [setPage]
  );

  const { hasData, hasFilters } = useTableDataStatus(tableData.length, [
    nameFilter,
    typesFilter,
    videoTypesFilter,
    statesFilter,
    submitterNameFilter,
    processedByNameFilter
  ]);

  const noData = !hasFilters && !hasData;
  const initializing = loading && noData;

  return (
    <Section title={t(lg.todoAll.tasksOverview.title)}>
      <div className="mt-10">
        {initializing ? (
          <SimpleLoader />
        ) : !loading && noData ? (
          <div className="flex flex-col items-center min-h-full">
            <div className="flex-grow flex flex-col w-full p-5 md:p-20">
              <Illustration type="ill-03" className="flex-grow h-0" />
            </div>
          </div>
        ) : (
          <Table<TableItem>
            dataSource={tableData}
            columns={columns}
            rowKey={"id"}
            loading={{ delay: hasData ? 0 : 300, spinning: loading }}
            showSorterTooltip={false}
            onChange={handleOnChange}
            pagination={pagination}
            scroll={{ x: true }}
          />
        )}
      </div>
    </Section>
  );
};
