/*
---------------------------------------------------------------------
SUBHEADING
---------------------------------------------------------------------
 */

import { ReactNode } from "react";
import clsx from "clsx";

const Subheading = ({ className, children }: { children: ReactNode; className?: string }) => (
  <div className={clsx("text-blue-700 text-base font-medium print_text-black print_text-sm", className)}>
    {children}
  </div>
);

/*
---------------------------------------------------------------------
ITEM INFO
---------------------------------------------------------------------
 */
const InfoItem = ({
  label,
  content,
  className,
  children
}: {
  label: string;
  content?: string | undefined | null;
  children?: ReactNode;
  className?: string;
}) => (
  <div className={clsx("print_mt-0", className)}>
    <div className="font-medium print_font-normal print_inline-block">
      {label}
      <span className="hidden print_inline print_mr-1">:</span>
    </div>

    {content ? (
      <div className="text-secondary print_inline-block print_align-middle whitespace-pre-line">{content}</div>
    ) : null}

    {children}
  </div>
);

export const UI = { InfoItem, Subheading };
