import { cloneElement, PropsWithChildren, ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ParseMD } from "helpers/ParseMD";
import { useConstructionObjectDelete } from "hooks";
import { lg } from "assets/translations/lg";

type Props = {
  id: string;
  name: string;
  children: ReactElement;
  onSuccess?: () => any;
  onError?: () => any;
};

export const Delete = ({ id, name, children, onSuccess = () => {}, onError = () => {} }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const open = useCallback(() => setVisible(true), []);

  /** DATA */
  const { data, handlers } = useConstructionObjectDelete(id);

  /** HANDLERS */
  const handleClickOk = useCallback(async () => {
    await handlers.handleDelete();
  }, [handlers]);

  const handleClickCancel = useCallback(() => setVisible(false), []);

  /** EFFECTS */
  useEffect(() => {
    if (data.wasCompletelyDeleted) {
      onSuccess();
    }
  }, [data.wasCompletelyDeleted, onSuccess]);

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center">
            <DeleteOutlined className="text-lg text-red-600" />
            <span className="ml-4">{t(lg.constructionObject.pageEdit.modals.deleteObject.title)}</span>
          </div>
        }
        open={visible}
        okText={t(lg.constructionObject.pageEdit.modals.deleteObject.buttonOkText)}
        cancelText={t(
          data.failedData
            ? lg.constructionObject.pageEdit.modals.deleteObject.failedData.buttonOkText
            : lg.constructionObject.pageEdit.modals.deleteObject.buttonCancelText
        )}
        okButtonProps={{
          danger: true,
          style: { display: data.failedData ? "none" : "inline-block" },
          disabled: data.sending
        }}
        cancelButtonProps={{
          type: data.failedData ? "primary" : "default",
          disabled: data.sending
        }}
        onOk={handleClickOk}
        onCancel={handleClickCancel}
        closable={false}
        confirmLoading={data.sending}
        afterClose={handlers.handleClear}
      >
        {!data.failedData && (
          <>
            <ParseMD>
              {t(lg.constructionObject.pageEdit.modals.deleteObject.description, {
                name
              })}
            </ParseMD>

            <div className="flex items-center mt-5">
              <InfoCircleOutlined className="text-red-600 text-lg" />
              <span className="font-medium ml-2">{t(lg.constructionObject.pageEdit.modals.deleteObject.warning)}</span>
            </div>
          </>
        )}

        {data.failedData && (
          <>
            <div>{t(lg.constructionObject.pageEdit.modals.deleteObject.failedData.description)}</div>

            {data.failedData.failedRecords.length > 0 && (
              <List
                className="mt-5"
                description={t(lg.constructionObject.pageEdit.modals.deleteObject.failedData.foundedRecords)}
                data={data.failedData.failedRecords}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
};

const List = ({
  data,
  description,
  className
}: {
  data: { id: string; name: string }[];
  description: string;
  className: string;
}) => (
  <div className={className}>
    <div className="font-medium">{description}</div>
    <ul>
      {data.map(item => (
        <li key={item.id}>{item.name}</li>
      ))}
    </ul>
  </div>
);
