import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/Section";
import { DocumentationRecordType } from "api";
import { lg } from "assets/translations";
import { TypeIndicatorDocumentationRecord } from "components/Indicators/Type";
import { documentationRecordTypes } from "api/enums";

type Props = {
  type: DocumentationRecordType;
  className?: string;
};

/**
 * Type of Documentation Record
 */
export const RecordTypeInfo = ({ type, className }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Section title={t(lg.typenames.documentationType)} className={className}>
      <div className="c-grid mt-10">
        <div className="c-grid-column">
          <TypeIndicatorDocumentationRecord type={type} labelStyle="font-medium">
            <div className="whitespace-pre-line">{t(documentationRecordTypes[type].descriptionI18nKey)}</div>
          </TypeIndicatorDocumentationRecord>
        </div>
      </div>
    </Section>
  );
};
