import { ComponentPropsWithoutRef, PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { usePrevious, useQueue } from "react-use";
import { Media, MediaUploadSuccessHandler, useFormat, useMedia } from "hooks";
import { lg } from "assets/translations";
import { SimpleLoader } from "components/SimpleLoader";
import { MediaCard } from "components/MediaCard";
import clsx from "clsx";

type Props = {
  organizationId: string;

  files: Media[];
  onChange: (media: Media[]) => Promise<void> | void;
  onFinish?: (media: Media[]) => Promise<void> | void;

  allowedFileTypes?: string[];

  loading?: boolean;
  disabled?: boolean;

  className?: string;
  uploaderClassName?: string;
  filesClassName?: string;
};

/*
 * Controlled Components - Uploader
 * */
export const Uploader = ({
  files,
  onChange,
  onFinish,

  loading = false,
  disabled = false,

  organizationId,
  allowedFileTypes,

  className,
  uploaderClassName,
  filesClassName = "mt-5"
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { allowedFileTypesToString } = useFormat();

  const { add: queueAdd, remove: queueRemove, first: queueFirst, size: queueSize } = useQueue<RcFile>();
  const prevQueueSize = usePrevious(queueSize);

  const handleMediaUploadSuccess = useCallback<MediaUploadSuccessHandler>(
    async media => {
      queueRemove();
      return onChange([...files, media]);
    },
    [onChange, files, queueRemove]
  );

  const uploadAccept = useMemo(
    () => allowedFileTypesToString(allowedFileTypes),
    [allowedFileTypes, allowedFileTypesToString]
  );

  const { upload, uploading, uploadedPercents } = useMedia(organizationId, handleMediaUploadSuccess);
  const inProgress = uploading || loading;

  const processQueueItem = useCallback(
    (file: RcFile): boolean | PromiseLike<void> => upload(file, allowedFileTypes),
    [upload, allowedFileTypes]
  );

  const handleBeforeUpload = useCallback(async (file: RcFile): Promise<void> => queueAdd(file), [queueAdd]);

  const handleRemove: Required<ComponentPropsWithoutRef<typeof MediaCard>>["onDelete"] = useCallback(
    mediaId => {
      if (!onFinish) return;
      onFinish(files.filter(file => file.id !== mediaId));
    },
    [onFinish, files]
  );

  useEffect(() => {
    if (inProgress || queueSize === 0) return;

    processQueueItem(queueFirst);
  }, [queueFirst, queueSize, processQueueItem, loading, inProgress]);

  /* handle finished upload of the all files */
  useEffect(() => {
    if (!onFinish) return;
    if (prevQueueSize === 1 && queueSize === 0) {
      const timer = setTimeout(async () => await onFinish(files), 0);
      return () => clearTimeout(timer);
    }
  }, [prevQueueSize, queueSize, onFinish, files]);

  const hasFiles = files.length > 0;

  return (
    <div className={clsx((inProgress || disabled) && "pointer-events-none", className)}>
      <Upload.Dragger
        name="file"
        accept={uploadAccept}
        multiple={true}
        disabled={disabled || inProgress}
        showUploadList={false}
        beforeUpload={handleBeforeUpload}
        className={clsx("opacity-80", uploaderClassName)}
      >
        <p className="ant-upload-drag-icon mt-2">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text text-base">{t(lg.uploader.title)}</p>
        <p className="ant-upload-hint text-secondary whitespace-pre-line mb-2">{t(lg.uploader.description)}</p>
      </Upload.Dragger>

      <div className={clsx("relative", filesClassName)}>
        {/* Custom file list */}
        {hasFiles && (
          <div className={clsx(inProgress ? "opacity-20" : disabled ? "opacity-80" : "")}>
            {files.map((media, index) => {
              return (
                <MediaCard
                  key={media.id}
                  className={index === 0 ? "" : "mt-2"}
                  fileName={media.fileName || ""}
                  fileUrl={media.url || ""}
                  fileId={media.id}
                  onDelete={handleRemove}
                />
              );
            })}
          </div>
        )}

        {inProgress && (
          <div className={clsx("flex items-center justify-center p-4", hasFiles && "absolute inset-0")}>
            <SimpleLoader />
            {uploading ? <div className="text-secondary ml-2">{uploadedPercents} %</div> : null}
          </div>
        )}
      </div>
    </div>
  );
};
