import { forwardRef, HTMLAttributes } from "react";
import clsx from "clsx";
import { CustomIcon, CustomIconType } from "components/icons/CustomIcon";
import { classes } from "components/Homepage/classes";

type DiagramBlockProps = HTMLAttributes<HTMLDivElement> & {
  active?: boolean; // has lighter and filled border
  title: string;
  customIconType: CustomIconType;
};
export const DiagramCard = forwardRef<HTMLDivElement, DiagramBlockProps>(
  ({ active = false, title, customIconType, className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "flex flex-col justify-between",
          "px-2 py-5 md:p-10",
          !active && "c-border-dashed-blue-700",
          active && "border rounded border-blue-500",
          className
        )}
        {...props}
      >
        <div className="flex flex-col items-center">
          <CustomIcon type={customIconType} className="w-12 text-blue-500" />
          <h3 className={clsx(classes.h3, "text-purple-200 mt-5 mb-0")}>{title}</h3>
        </div>

        <div className="flex flex-col items-center mt-5 md:mt-10 space-y-5 md:space-y-10">{children}</div>
      </div>
    );
  }
);
