import { PropsWithChildren, useCallback, useEffect } from "react";
import GoogleMapReact, { Coords, Props as GoogleMapProps } from "google-map-react";
import { Button, Form, Input, message } from "antd";
import { useCopyToClipboard } from "react-use";
import { CustomMapDot } from "./CustomMapDot";
import { createMapOptions, parseCoords } from "./helpers";
import { DEFAULT_SEARCH_ZOOM, EditType, useMap } from "./useMap";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { Link } from "components/Link";
import { getEnv } from "helpers";
import { ValidatorRule } from "rc-field-form/lib/interface";

type Props = {
  constructionSite?: Coords[];
  constructionObject?: Coords[];
  groundControlPoints?: Coords[];
  editable?: boolean;
  editType?: EditType;
  center?: Coords;
  zoom?: number;
  searchZoom?: number;
  saveDisabled?: boolean;
  hasSearch?: boolean;
  hasValidCoords?: boolean;
  onChange?: (coords: Coords[]) => any;
  onSave?: (coords: Coords[]) => any;
};

export const Map = ({
  center = { lat: 50.38127253206591, lng: 21.054054687500013 }, // Germany, Czech Republic, Ukraine
  constructionSite = [],
  constructionObject = [],
  zoom = 4,
  searchZoom = DEFAULT_SEARCH_ZOOM,
  editable = false,
  editType = "site",
  saveDisabled = false,
  hasSearch = false,
  hasValidCoords = true,
  onChange = () => {},
  onSave = () => {}
}: PropsWithChildren<Props>) => {
  const {
    mapContainerRef,
    searchInputRef,
    mapHeight,
    isEditMode,
    isMapDraggable,
    newPointValue,
    inputValues,
    points,
    history,
    isTouched,
    handleGoogleApiLoaded,
    handleClickMap,
    handleChildMouseDown,
    handleChildMouseUp,
    handleChildMouseMove,
    handleClickSetPoints,
    handleChangeNewPoint,
    handleChangePoint,
    handleClickRemovePoint,
    handleClickRemoveAllPoints,
    handleClickBack
  } = useMap(constructionSite, constructionObject, editType, hasSearch, searchZoom);

  const {
    t,
    i18n: { language }
  } = useTranslation();
  const [copyState, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (copyState.value && !copyState.error) {
      message.success(t(lg.copyToClipboardSuccess, { value: copyState.value }), 1);
    }
  }, [copyState, t]);

  useEffect(() => (isTouched ? onChange(points) : undefined), [onChange, points, isTouched]);

  const handleClickSave = useCallback(() => onSave(points), [onSave, points]);

  const validateCoords: ValidatorRule["validator"] = useCallback(
    (rule, value) => {
      // @ts-expect-error: Validator type is missing the field key
      const { field } = rule;
      if ((field === "new-point" && !value?.length) || parseCoords(value)) return Promise.resolve();

      return Promise.reject(t(lg.map.validations.invalidCoordinates));
    },
    [t]
  );

  const editableMapProps: GoogleMapProps = editable
    ? {
        onClick: handleClickMap,
        onChildMouseDown: handleChildMouseDown,
        onChildMouseUp: handleChildMouseUp,
        onChildMouseMove: handleChildMouseMove
      }
    : {};

  return (
    <div className="c-grid">
      <div className="c-grid-column">
        <div ref={mapContainerRef} className="w-full" style={{ height: mapHeight }}>
          {hasSearch && (
            <input
              ref={searchInputRef}
              type={"text"}
              style={searchInputStyle}
              placeholder={t(lg.map.searchPlaceholder)}
            />
          )}

          <GoogleMapReact
            bootstrapURLKeys={{
              key: getEnv("REACT_APP_MAP_API_KEY"),
              libraries: ["places"],
              language: language
            }}
            options={createMapOptions}
            defaultCenter={center}
            defaultZoom={zoom}
            draggable={isMapDraggable}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={handleGoogleApiLoaded}
            {...editableMapProps}
          >
            {points.map(({ lat, lng }, i) => (
              <CustomMapDot key={i + lat + lng} lat={lat} lng={lng} value={i + 1} secondary={editType === "object"} />
            ))}
          </GoogleMapReact>
        </div>

        {editable && (
          <div className="flex justify-between mt-5">
            <Button disabled={!inputValues.length || !isEditMode} onClick={handleClickRemoveAllPoints}>
              {t(lg.map.buttons.cancelAllSelection)}
            </Button>
            <div className="flex">
              <Button disabled={history.length < 2 || !isEditMode} onClick={handleClickBack}>
                {t(lg.map.buttons.back)}
              </Button>
              <Button
                disabled={points.length < 3 || saveDisabled}
                type="primary"
                className="ml-5"
                onClick={handleClickSave}
              >
                {t(lg.map.buttons.saveSelection)}
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="c-grid-column">
        {editable ? (
          !isEditMode ? (
            <>
              <p className="text-gray-700 mb-5">{t(lg.map.noDataText)}</p>
              <Button type="primary" size={"large"} onClick={handleClickSetPoints}>
                {t(lg.map.buttons.startMark)}
              </Button>
            </>
          ) : (
            <>
              <Form layout="vertical" name="points">
                {inputValues.map((value, i) => (
                  <div key={i} className="mb-8">
                    <Form.Item
                      name={i}
                      label={`${t(lg.map.markName)} ${i + 1}`}
                      rules={[{ validator: validateCoords, validateTrigger: "onChange" }]}
                    >
                      <div className="flex">
                        <Input type="text" value={inputValues[i]} onChange={e => handleChangePoint(e, i)} />
                        <Button type="default" className="ml-5" onClick={() => handleClickRemovePoint(i)}>
                          {t(lg.map.buttons.buttonDeleteMark)}
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                ))}
                <Form.Item
                  name="new-point"
                  label={`${t(lg.map.markName)} ${points.length + 1}`}
                  rules={[{ validator: validateCoords, validateTrigger: "onChange" }]}
                >
                  <div className="flex mt-2">
                    <Input
                      type="text"
                      value={newPointValue}
                      placeholder={t(lg.map.markPlaceholder)}
                      onChange={handleChangeNewPoint}
                    />
                    <Button type="default" disabled={true} className="ml-5">
                      {t(lg.map.buttons.buttonDeleteMark)}
                    </Button>
                  </div>
                </Form.Item>
              </Form>

              {!hasValidCoords && (
                <div className="mt-16">
                  <span className="text-red-600">{t(lg.map.validations.invalidCoordinatesCount)}</span>
                </div>
              )}
            </>
          )
        ) : !isEditMode ? null : (
          inputValues.map((value, i) => (
            <div key={i} className={i > 0 ? "mt-5" : ""}>
              <label className="block font-medium">{`${t(lg.map.markName)} ${i + 1}`}</label>
              {/* disable Edge formatting: x-ms-format-detection="none"*/}
              <span className="inline-block text-secondary" style={{ minWidth: 180 }} x-ms-format-detection="none">
                {value}
              </span>
              <Link onClick={() => copyToClipboard(value)} className="print_hidden">
                {t(lg.copyToClipboard)}
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const searchInputStyle = {
  width: "250px",
  height: "40px",
  margin: "10px",
  padding: "0px 15px",
  fontSize: "14px",
  borderRadius: "2px",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px"
};
