import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { navigate, RouteComponentProps } from "@reach/router";
import { Alert, Button, Form, Input, Radio } from "antd";
import { Content } from "components/LayoutDashboard";
import {
  SubheaderOptions,
  useAlert,
  useCatalog,
  useDocumentationRecordCreateSimplified,
  useMe,
  useSubheader
} from "hooks";
import {
  ConstructionObjectState,
  DocumentationRecordType,
  Errors,
  resolveError,
  useConstructionObjectCreateMutation,
  useConstructionPhaseCreateMutation,
  useConstructionSiteCreateMutation,
  useDocumentationRecordCreateMutation,
  useDocumentationRecordCreatePageLazyQuery
} from "api";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations/lg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { SelectConstructionSite } from "components/SelectConstructionSite";
import { SelectConstructionObject } from "components/SelectConstructionObject";
import { SelectConstructionPhase } from "components/SelectConstructionPhase";
import { Section } from "components/Section";
import { getHasEnoughCredits } from "helpers";
import { ModalCost } from "components/DocumentationRecord/ModalCost";

const DEFAULT_DOCUMENTATION_RECORD_TYPE = DocumentationRecordType.NoThreeDNoVideo;

type Props = RouteComponentProps<{
  constructionSiteId?: string;
  constructionObjectId?: string;
  type: DocumentationRecordType;
}>;

export const DocumentationRecordCreateSimplified = ({ constructionSiteId: initSiteId }: Props) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const initSiteRoute = initSiteId ? setRouteParams(routes.constructionSiteDetail, { id: initSiteId }) : undefined;

  const abortRoute = initSiteRoute || routes.documentationRecords;
  const handleClickAbort = useCallback(() => navigate(abortRoute), [abortRoute]);

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: t(lg.documentationRecord.pageCreateSimplified.header.title),
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        { value: t(lg.documentationRecord.pageCreateSimplified.header.title) }
      ],
      buttons: [
        <Button key={"abort"} type={"default"} size={"large"} onClick={handleClickAbort}>
          {t(lg.documentationRecord.pageCreateSimplified.header.buttonSecondaryText)}
        </Button>
      ]
    }),
    [t, handleClickAbort]
  );
  useSubheader(subheaderOptions);

  const { me, organization, loading: loadingMe, refetch: refetchMe } = useMe();
  const organizationId = organization?.id;

  const { catalog, loading: loadingCatalog } = useCatalog();
  const createRecordCost = catalog.createDocumentationRecord;

  const isCreatingRecordFreeOfCharge = createRecordCost === 0;
  const hasEnoughCreditsToCreateRecord = getHasEnoughCredits(organization.credits, createRecordCost);

  const [isModalCreateCostVisible, setIsModalCreateCostVisible] = useState(false);

  const [createSite, { loading: loadingCreateSiteMutation }] = useConstructionSiteCreateMutation();
  const [createObject, { loading: loadingCreateObjectMutation }] = useConstructionObjectCreateMutation();
  const [createPhase, { loading: loadingCreatePhaseMutation }] = useConstructionPhaseCreateMutation();
  const [createRecord, { loading: loadingCreateRecordMutation }] = useDocumentationRecordCreateMutation({
    onError: error => {
      resolveError(
        error,
        {
          [Errors.NotEnoughCredits]: async () => {
            try {
              await refetchMe();
              setIsModalCreateCostVisible(true);
            } catch (e) {
              resolveError(e, {}, showAlert);
            }
          }
        },
        showAlert
      );
    }
  });
  const [loadRecordCreatePageQuery] = useDocumentationRecordCreatePageLazyQuery({
    onCompleted: async ({ constructionSite, constructionObject }) => {
      if (!constructionSite || !constructionObject) return;

      try {
        const { suggestedDocumentationName } = constructionObject;
        if (!suggestedDocumentationName) return;

        const phaseId = await resolvePhase(constructionSite.id);
        if (!phaseId) return;

        await handleCreateRecord(suggestedDocumentationName, constructionObject.id, phaseId);
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    onError: e => resolveError(e, {}, showAlert),
    fetchPolicy: "network-only"
  });

  const handleCreateRecord = async (name: string, objectId: string, phaseId: string) => {
    try {
      const response = await createRecord({
        variables: {
          input: {
            name,
            type: DEFAULT_DOCUMENTATION_RECORD_TYPE,
            constructionObject: objectId,
            constructionPhase: phaseId
          }
        }
      });
      const recordId = response.data?.documentationRecord?.create?.documentationRecord?.id;
      if (!recordId) {
        resolveError(new Error("No Documentation ID returned"), {}, showAlert);
        return;
      }
      await navigate(setRouteParams(routes.documentationRecordEdit, { id: recordId }));
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  };

  const loading: boolean = loadingMe || loadingCatalog;

  const creating: boolean =
    loadingCreateSiteMutation ||
    loadingCreateObjectMutation ||
    loadingCreatePhaseMutation ||
    loadingCreateRecordMutation;

  const disabled = loading || creating;

  const meId = me?.id;

  const {
    form,
    initFormData,
    FORM_ITEM_NAME,
    FORM_ITEM_VALUE,
    handleFormChange,
    setNewPhaseName,
    states,
    selectSiteProps,
    inputSiteProps,
    selectObjectProps,
    inputObjectProps,
    selectPhaseProps,
    inputPhaseProps
  } = useDocumentationRecordCreateSimplified({ disabled, initSiteId, userId: meId });
  const {
    assignSiteId,
    assignObjectId,
    assignPhaseId,

    newSiteName,
    newObjectName,
    newPhaseName,

    isSiteFilled,
    isSiteAssign,
    isSiteCreate,

    isObjectFilled,
    isObjectAssign,
    isObjectCreate,

    isPhaseFilled,
    isPhaseAssign,
    isPhaseCreate,

    disableSiteAssignOption,
    disableObjectAssignOption,
    disablePhaseAssignOption,

    disableSiteCreateOption,
    disableObjectCreateOption,
    disablePhaseCreateOption,

    isPhaseTouched,
    isNewPhaseNameEmpty,
    showNoPermissionsToCreateObjectError,
    showNoPermissionsToCreatePhaseError
  } = states;

  useEffect(() => {
    if (isPhaseCreate && isNewPhaseNameEmpty && !isPhaseTouched) setNewPhaseName(t(lg.defaultConstructionPhaseName));
  }, [isPhaseCreate, isNewPhaseNameEmpty, setNewPhaseName, isPhaseTouched, t]);

  const canSubmitForm = hasEnoughCreditsToCreateRecord && isSiteFilled && isObjectFilled && isPhaseFilled;

  /*
   * Resolvers
   * */
  const resolveSite = useCallback(async (): Promise<string | undefined> => {
    // handle assign
    if (isSiteAssign && assignSiteId) return assignSiteId;

    // handle create
    if (!isSiteCreate || !newSiteName) return;
    const response = await createSite({
      variables: { input: { name: newSiteName, organization: organizationId } }
    });
    return response.data?.constructionSite?.create?.constructionSite?.id;
  }, [isSiteAssign, assignSiteId, isSiteCreate, newSiteName, createSite, organizationId]);

  const resolveObject = useCallback(
    async (siteId: string): Promise<string | undefined> => {
      // handle assign
      if (isObjectAssign && assignObjectId) return assignObjectId;
      // handle create
      if (!isObjectCreate || !newObjectName) return;

      const response = await createObject({
        variables: {
          input: {
            name: newObjectName,
            constructionSite: siteId,
            state: ConstructionObjectState.Active,
            accountableManager: me.id
          }
        }
      });
      return response.data?.constructionObject?.create?.constructionObject?.id;
    },
    [isObjectAssign, assignObjectId, isObjectCreate, newObjectName, createObject, me.id]
  );

  const resolvePhase = useCallback(
    async (siteId: string): Promise<string | undefined> => {
      // handle assign
      if (isPhaseAssign && assignPhaseId) return assignPhaseId;
      // handle create
      if (!isPhaseCreate || !newPhaseName) return;

      const response = await createPhase({ variables: { input: { name: newPhaseName, constructionSite: siteId } } });
      return response.data?.constructionPhase?.create?.constructionPhase?.id;
    },
    [isPhaseAssign, assignPhaseId, isPhaseCreate, newPhaseName, createPhase]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const siteId = await resolveSite();
      if (!siteId) return;

      const objectId = await resolveObject(siteId);
      if (!objectId) return;

      loadRecordCreatePageQuery({ variables: { constructionSiteId: siteId, constructionObjectId: objectId } });
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [loadRecordCreatePageQuery, resolveObject, resolveSite, showAlert]);

  const handleCreateDocumentationClick = useCallback(
    () => (isCreatingRecordFreeOfCharge ? handleSubmit() : setIsModalCreateCostVisible(true)),
    [handleSubmit, isCreatingRecordFreeOfCharge]
  );

  const handleModalCreateCostOk = useCallback(() => {
    setIsModalCreateCostVisible(false);
    return handleSubmit();
  }, [handleSubmit]);

  const handleModalCreateCostCancel = useCallback(() => setIsModalCreateCostVisible(false), []);

  return (
    <Content>
      <div className={"flex justify-end"}>
        {hasEnoughCreditsToCreateRecord ? null : (
          <Alert message={t(lg.documentationRecord.validations.notEnoughCreditsToCreate)} type={"error"} />
        )}
      </div>
      <Section title={t(lg.documentationRecord.pageCreateSimplified.sectionTitle)}>
        <Form className={"mt-10 space-y-16"} form={form} initialValues={initFormData} onChange={handleFormChange}>
          {/* Construction Site */}
          <fieldset className={"space-y-2.5"}>
            <p className="whitespace-pre-line text-base font-medium text-blue-700">
              {t(lg.typenames.constructionSite)}
            </p>
            <Form.Item name={FORM_ITEM_NAME.site}>
              <Radio.Group className={"c-grid"}>
                <div className={"c-grid-column"}>
                  <Radio value={FORM_ITEM_VALUE.assign} disabled={disableSiteAssignOption}>
                    {t(lg.documentationRecord.pageCreateSimplified.assignToConstructionSite.label)}
                  </Radio>
                  <Form.Item shouldUpdate className={"ml-6"}>
                    <SelectConstructionSite organizationId={organizationId} {...selectSiteProps} />
                  </Form.Item>
                </div>

                <div className={"c-grid-column"}>
                  <Radio value={FORM_ITEM_VALUE.create} disabled={disableSiteCreateOption}>
                    {t(lg.documentationRecord.pageCreateSimplified.createConstructionSite.label)}
                  </Radio>
                  <Form.Item shouldUpdate className={"ml-6"}>
                    <Input
                      placeholder={t(lg.documentationRecord.pageCreateSimplified.createConstructionSite.placeholder)}
                      {...inputSiteProps}
                    />
                  </Form.Item>
                </div>
              </Radio.Group>
            </Form.Item>
          </fieldset>

          {/* Construction Object */}
          <fieldset className={"space-y-2.5"}>
            <p className="whitespace-pre-line text-base font-medium text-blue-700">
              {t(lg.typenames.constructionObject)}
            </p>
            <Form.Item name={FORM_ITEM_NAME.object}>
              <Radio.Group className={"c-grid"}>
                <div className={"c-grid-column"}>
                  <Radio value={FORM_ITEM_VALUE.assign} disabled={disableObjectAssignOption}>
                    {t(lg.documentationRecord.pageCreateSimplified.assignToConstructionObject.label)}
                  </Radio>
                  <Form.Item shouldUpdate className={"ml-6"}>
                    <SelectConstructionObject
                      constructionSiteId={assignSiteId || ""}
                      accountableManagerId={me.id}
                      {...selectObjectProps}
                    />
                  </Form.Item>
                </div>

                <div className={"c-grid-column"}>
                  <Radio value={FORM_ITEM_VALUE.create} disabled={disableObjectCreateOption}>
                    {t(lg.documentationRecord.pageCreateSimplified.createConstructionObject.label)}
                  </Radio>
                  <Form.Item
                    hasFeedback
                    shouldUpdate
                    className={"ml-6"}
                    {...(showNoPermissionsToCreateObjectError
                      ? {
                          validateStatus: "error",
                          help: t(
                            lg.documentationRecord.pageCreateSimplified.createConstructionObject.errors.noPermission
                          )
                        }
                      : {})}
                  >
                    <Input
                      placeholder={t(lg.documentationRecord.pageCreateSimplified.createConstructionObject.placeholder)}
                      {...inputObjectProps}
                    />
                  </Form.Item>
                </div>
              </Radio.Group>
            </Form.Item>
          </fieldset>

          {/* Phase */}
          <fieldset className={"space-y-2.5"}>
            <p className="whitespace-pre-line text-base font-medium text-blue-700">
              {t(lg.typenames.constructionPhase)}
            </p>
            <Form.Item name={FORM_ITEM_NAME.phase}>
              <Radio.Group className={"c-grid"}>
                <div className={"c-grid-column"}>
                  <Radio value={FORM_ITEM_VALUE.assign} disabled={disablePhaseAssignOption}>
                    {t(lg.documentationRecord.pageCreateSimplified.assignToConstructionPhase.label)}
                  </Radio>
                  <Form.Item shouldUpdate className={"ml-6"}>
                    <SelectConstructionPhase constructionSiteId={assignSiteId || ""} {...selectPhaseProps} />
                  </Form.Item>
                </div>

                <div className={"c-grid-column"}>
                  <Radio value={FORM_ITEM_VALUE.create} disabled={disablePhaseCreateOption}>
                    {t(lg.documentationRecord.pageCreateSimplified.createConstructionPhase.label)}
                  </Radio>
                  <Form.Item
                    hasFeedback
                    shouldUpdate
                    className={"ml-6"}
                    {...(showNoPermissionsToCreatePhaseError
                      ? {
                          validateStatus: "error",
                          help: t(
                            lg.documentationRecord.pageCreateSimplified.createConstructionPhase.errors.noPermission
                          )
                        }
                      : {})}
                  >
                    <Input
                      placeholder={t(lg.documentationRecord.pageCreateSimplified.createConstructionPhase.placeholder)}
                      {...inputPhaseProps}
                    />
                  </Form.Item>
                </div>
              </Radio.Group>
            </Form.Item>
          </fieldset>

          <div className="flex justify-end">
            <Button
              size={"large"}
              type={"primary"}
              disabled={!canSubmitForm}
              loading={creating}
              className="ml-5"
              onClick={handleCreateDocumentationClick}
            >
              {t(lg.documentationRecord.pageCreateSimplified.buttonCreateText)}
              <ArrowRightOutlined />
            </Button>
          </div>
        </Form>
      </Section>

      <ModalCost
        visible={isModalCreateCostVisible}
        title={t(lg.documentationRecord.pageCreate.modals.createCost.title.createAndSave)}
        buttonOk={t(lg.documentationRecord.pageCreate.modals.createCost.buttonOkText.createAndSaveText)}
        cost={createRecordCost}
        resources={organization.credits}
        onOk={handleModalCreateCostOk}
        onCancel={handleModalCreateCostCancel}
      />
    </Content>
  );
};
