import useStore, { createStore } from "global-hook-store";
import { useEffect } from "react";

type SubheaderStore = {
  heading: string;
  breadcrumb: {
    value: string;
    route?: string;
  }[];
  buttons: (JSX.Element | undefined | null)[];
  title: string;
};

type SubheaderPayloadInit = Partial<SubheaderStore>;

type SubheaderReducers = {
  init: (store: SubheaderStore, payload: SubheaderPayloadInit) => SubheaderStore;
};

const subheaderStore = createStore<SubheaderStore, SubheaderReducers>(
  {
    heading: "",
    title: "",
    breadcrumb: [],
    buttons: []
  },
  {
    init: (state, payload) => {
      return {
        heading: payload.heading || "",
        breadcrumb: payload.breadcrumb || [],
        buttons: payload.buttons || [],
        title: payload.title || payload.heading || ""
      };
    }
  }
);

/*
 * Important: Paste memoized value to prevent re-render loop
 * */
export type SubheaderOptions = SubheaderPayloadInit;
export const useSubheader = (options?: SubheaderOptions): SubheaderStore => {
  const {
    state,
    actions: { init }
  } = useStore(subheaderStore);

  useEffect(() => {
    options && init(options);
  }, [init, options]);

  return state;
};
