import { ComponentProps, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Steps } from "antd";
import { DocumentationRecordState, DocumentationRecordType } from "api";
import { lg } from "assets/translations";
import { useMeasure } from "react-use";

type StepKey =
  | "creation_of_documentation"
  | "get_data_by_smart_phone"
  | "data_upload_by_surveyor"
  | "data_check_by_manager"
  | "creating_three_d_model"
  | "done";

type Props = {
  documentationRecordType: DocumentationRecordType;
  documentationRecordState?: DocumentationRecordState;
  autoRequestThreeD: boolean;
};

export const ProgressSteps = ({
  documentationRecordType,
  documentationRecordState,
  autoRequestThreeD
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  const direction = useMemo(() => (width < 680 ? "vertical" : "horizontal"), [width]);

  const has3DModel =
    documentationRecordType === DocumentationRecordType.ThreeDNoScale ||
    documentationRecordType === DocumentationRecordType.ThreeDScale;
  const requiresSurveyor = documentationRecordType === DocumentationRecordType.ThreeDScale;

  const steps = useMemo<StepKey[]>(
    () => [
      "creation_of_documentation",
      "get_data_by_smart_phone",
      ...(requiresSurveyor ? (["data_upload_by_surveyor"] as StepKey[]) : []),
      ...(has3DModel && !autoRequestThreeD ? (["data_check_by_manager"] as StepKey[]) : []),
      ...(has3DModel ? (["creating_three_d_model"] as StepKey[]) : []),
      "done"
    ],
    [autoRequestThreeD, has3DModel, requiresSurveyor]
  );
  const items = useMemo<Required<ComponentProps<typeof Steps>>["items"]>(
    () =>
      steps.map(step => ({
        title: <span className="text-xs">{t(getStepTranslationKey(step))}</span>
      })),
    [t, steps]
  );

  return (
    <div ref={ref}>
      <Steps
        direction={direction}
        size={"small"}
        progressDot={true}
        items={items}
        current={resolveStep(steps.length, documentationRecordState)}
      />
    </div>
  );
};

const resolveStep = (stepsCount: number, state?: DocumentationRecordState): number => {
  if (!state) return 0;

  // Sorry for this - too much variables
  switch (state) {
    case DocumentationRecordState.Created:
      return 0;
    case DocumentationRecordState.RequiresOperator:
      return 1;
    case DocumentationRecordState.RequiresSurveyor:
      return 2;
    case DocumentationRecordState.RequiresManagerConfirmation:
      return stepsCount - 3;
    case DocumentationRecordState.RequiresThreeDOperator:
      return stepsCount - 2;
    case DocumentationRecordState.Finished:
    case DocumentationRecordState.Rejected:
    case DocumentationRecordState.Archived:
      return stepsCount - 1;
  }
};

const getStepTranslationKey = (step: StepKey): string => {
  switch (step) {
    case "creating_three_d_model":
      return lg.documentationRecord.progressSteps.creatingThreeDModel;
    case "creation_of_documentation":
      return lg.documentationRecord.progressSteps.creationOfDocumentation;
    case "data_check_by_manager":
      return lg.documentationRecord.progressSteps.dataCheckByManager;
    case "data_upload_by_surveyor":
      return lg.documentationRecord.progressSteps.dataUploadBySurveyor;
    case "get_data_by_smart_phone":
      return lg.documentationRecord.progressSteps.getDataBySmartPhone;
    case "done":
      return lg.documentationRecord.progressSteps.done;
  }
};
