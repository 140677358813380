import { useCallback, useMemo, useState } from "react";
import { resolveError, useOrganizationQuery, useOrganizationRemoveUserMutation } from "api";
import { useAlert } from "hooks";
import { Form, message } from "antd";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";
import { isManager } from "helpers";
import { useMountedState } from "react-use";

type OrganizationRemoveUserData = {
  membership: string;
  accountableUser?: string;
};

export enum RemoveUserFormFields {
  accountableUser = "accountableUser"
}

export const useOrganizationRemoveUser = (membershipId: string = "", organizationId: string) => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [canSend, setCanSend] = useState<boolean>(false);
  const isMounted = useMountedState();

  const { data, loading: loadingOrganization } = useOrganizationQuery({
    variables: { id: organizationId },
    onError: e => resolveError(e, undefined, showAlert)
  });
  const [remove, { loading: loadingRemovingUser }] = useOrganizationRemoveUserMutation();

  const availableAccountableManagers = useMemo(() => {
    return (
      data?.organization?.organizationMemberships.edges
        .map(({ node }) => node)
        // filter removed user
        .filter(membership => membership.id !== membershipId)
        // get managers
        .filter(membership => isManager(membership.user))
        .map(membership => membership.user)
    );
  }, [membershipId, data]);

  const loading = useMemo(() => loadingOrganization || loadingRemovingUser, [loadingOrganization, loadingRemovingUser]);

  const open = useCallback(() => setVisible(true), [setVisible]);

  /* Handlers - API */
  const handleSuccess = useCallback(() => {
    message.info(t(lg.organization.messages.userRemoved));
    if (isMounted()) setVisible(false);
  }, [t, setVisible, isMounted]);

  /* API */
  const removeUser = useCallback(
    async (data: OrganizationRemoveUserData) => {
      try {
        await remove({
          variables: {
            input: {
              membership: data.membership,
              accountableUser: data.accountableUser
            }
          }
        });
        window.setTimeout(handleSuccess, 300);
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [showAlert, remove, handleSuccess]
  );

  /* Handlers - Modal */
  const handleOk = useCallback(async () => {
    form
      .validateFields()
      .then(async values => {
        await removeUser({
          membership: membershipId,
          accountableUser: values[RemoveUserFormFields.accountableUser]
        });
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  }, [form, membershipId, removeUser]);

  const handleCancel = useCallback(() => setVisible(false), []);

  const handleChange = useCallback((changedFields: any[], allFields: any[]) => {
    const isError: boolean = allFields.filter(field => field.errors && field.errors.length > 0).length > 0;
    setCanSend(!isError);
  }, []);

  const handleAfterClose = useCallback(() => {
    form.resetFields();
  }, [form]);

  return {
    loading,
    canSend,
    visible,
    form,
    availableAccountableManagers,

    open,
    removeUser,

    handleOk,
    handleCancel,
    handleChange,
    handleAfterClose
  };
};
