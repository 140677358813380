import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import { useAlert } from "hooks/useAlert";
import { useFormat } from "hooks";

export const Alert = () => {
  const { t } = useTranslation();
  const { visible, hideAlert, error, errorAt } = useAlert();
  const { formatDate } = useFormat();

  const errorMessages = useMemo(() => error?.graphQLErrors.map(error => error.message), [error]);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <ExclamationCircleOutlined className="text-lg text-red-600" />
          <span className="ml-4">{t(lg.alert.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.alert.buttonOkText)}
      okButtonProps={{ type: "primary", style: { width: 100 } }}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={hideAlert}
      closable={false}
    >
      <p>{t(lg.alert.description)}</p>

      {errorMessages && (
        <>
          <hr className="mt-4 mb-4" />
          {/* error code*/}
          <div>
            <span>{`${t(lg.alert.errorCode.label)}:`}</span>
            <span className="ml-1">{errorMessages.join(", ")}</span>
          </div>

          {/* error time*/}
          <div>
            <span>{`${t(lg.alert.errorTime.label)}:`}</span>
            <span className="ml-1">{errorAt ? formatDate(errorAt, "machine", true) : "-"}</span>
          </div>
        </>
      )}
    </Modal>
  );
};
