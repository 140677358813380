import { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { usePrevious, useQueue } from "react-use";
import { Media, MediaUploadSuccessHandler, useFormat, useMedia } from "hooks";
import { lg } from "assets/translations";
import { SimpleLoader } from "components/SimpleLoader";
import { MediaCard } from "components/MediaCard";
import clsx from "clsx";

export type MediaUploaderProps = {
  organizationId: string;

  files: Media[];
  onUpload: (media: Media) => Promise<void> | void;
  onRemove: (mediaId: string) => Promise<void> | void;
  onFinish?: () => Promise<void> | void;

  loading?: boolean;
  disabled?: boolean;

  allowedFileTypes?: string[];

  className?: string;
  uploaderClassName?: string;
  filesClassName?: string;
};

/*
 * Controlled Components - Uploader
 * */
export const ModelFilesUploader = ({
  files,
  onUpload,
  onRemove,
  onFinish,

  loading = false,
  disabled,

  organizationId,
  allowedFileTypes,

  className,
  uploaderClassName,
  filesClassName = "mt-5"
}: MediaUploaderProps) => {
  const { t } = useTranslation();
  const { allowedFileTypesToString } = useFormat();

  const { add: queueAdd, remove: queueRemove, first: queueFirst, size: queueSize } = useQueue<RcFile>();
  const prevQueueSize = usePrevious(queueSize);

  const handleMediaUploadSuccess = useCallback<MediaUploadSuccessHandler>(
    async media => {
      queueRemove();
      return onUpload(media);
    },
    [queueRemove, onUpload]
  );

  const uploadAccept = useMemo(
    () => allowedFileTypesToString(allowedFileTypes),
    [allowedFileTypes, allowedFileTypesToString]
  );

  const { upload, uploading, uploadedPercents } = useMedia(organizationId, handleMediaUploadSuccess);
  const inProgress = uploading || loading;

  const processQueueItem = useCallback(
    (file: RcFile): boolean | PromiseLike<void> => upload(file, allowedFileTypes),
    [upload, allowedFileTypes]
  );

  const handleBeforeUpload = useCallback(async (file: RcFile): Promise<void> => queueAdd(file), [queueAdd]);

  const handleRemove = useCallback(async (mediaId: string | undefined) => mediaId && onRemove(mediaId), [onRemove]);

  useLayoutEffect(() => {
    if (inProgress || queueSize === 0) return;

    processQueueItem(queueFirst);
  }, [queueFirst, queueSize, processQueueItem, inProgress]);

  /* handle finished upload of the all files */
  useEffect(() => {
    if (!onFinish) return;
    if (prevQueueSize === 1 && queueSize === 0) {
      const timer = setTimeout(async () => await onFinish(), 0);
      return () => clearTimeout(timer);
    }
  }, [prevQueueSize, queueSize, onFinish]);

  return (
    <div className={clsx((inProgress || disabled) && "opacity-80 pointer-events-none", className)}>
      <div className={uploaderClassName}>
        <Upload.Dragger
          name="file"
          accept={uploadAccept}
          multiple={true}
          fileList={[]}
          disabled={disabled || inProgress}
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          className="w-full h-auto p-5"
        >
          <p className="ant-upload-drag-icon mt-2">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text text-base">{t(lg.uploader.title)}</p>
          <p className="ant-upload-hint text-secondary whitespace-pre-line mb-2">{t(lg.uploader.description)}</p>
        </Upload.Dragger>
      </div>

      <div className={clsx("relative", filesClassName)}>
        {/* Custom file list */}
        {files.length > 0 && (
          <div className={inProgress ? "opacity-0" : undefined}>
            {files.map((media, index) => {
              return (
                <MediaCard
                  key={media.id}
                  className={index === 0 ? "" : "mt-2"}
                  fileName={media.fileName || ""}
                  fileUrl={media.url || ""}
                  fileId={media.id}
                  onDelete={disabled ? undefined : handleRemove}
                />
              );
            })}
          </div>
        )}

        {inProgress && (
          <div className="absolute inset-0 flex items-center justify-center p-4">
            <SimpleLoader />
            {uploading && <div className="text-secondary ml-2">{uploadedPercents} %</div>}
          </div>
        )}
      </div>
    </div>
  );
};
