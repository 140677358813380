import { PropsWithChildren } from "react";

export enum StateIndicatorType {
  default = "default",
  warning = "warning",
  success = "success",
  error = "error"
}

const typeToColorMap: { [type in StateIndicatorType]: string } = {
  [StateIndicatorType.default]: "bg-gray-600",
  [StateIndicatorType.warning]: "bg-gold-600",
  [StateIndicatorType.success]: "bg-green-600",
  [StateIndicatorType.error]: "bg-red-600"
};

type Props = {
  type: StateIndicatorType;
};

export const StateIndicator = ({ type = StateIndicatorType.default, children }: PropsWithChildren<Props>) => (
  <div className="inline-flex items-center">
    <div
      className={`w-3 h-3 ${
        typeToColorMap[type] || typeToColorMap[StateIndicatorType.default]
      } rounded-full flex-shrink-0 print_color-adjust`}
    />
    {children && <span className="text-secondary ml-2">{children}</span>}
  </div>
);
