import { Media, useFormat } from "hooks";
import { useTranslation } from "react-i18next";

import { useMemo } from "react";
import { decodeRelayId } from "helpers";
import { lg } from "assets/translations";
import { MediaCard } from "components/MediaCard";
import { UI } from "./UI";

type OutputExtensionReadonlyProps = {
  id: string;
  name: string;
  author: string;
  createdAt: string;
  authorComment?: string | undefined | null;
  threeDOperatorComment?: string | undefined | null;
  files?: Media[];
};

export const OutputExtensionReadonly = ({
  id,
  name,
  author,
  createdAt,
  authorComment,
  threeDOperatorComment,
  files
}: OutputExtensionReadonlyProps) => {
  const { t } = useTranslation();
  const { formatDate } = useFormat();

  const decodedId: string = useMemo(() => decodeRelayId(id)?.id || "-", [id]);

  return (
    <div key={id}>
      <UI.Subheading className="mt-10 mb-5">{name}</UI.Subheading>
      <div className="c-grid mt-5 md:mt-10">
        <div className="c-grid-column space-y-5">
          <UI.InfoItem
            label={t(lg.taskThreeDOperator.outputExtensions.outputExtensionNumber.label)}
            content={decodedId}
          />
          <UI.InfoItem label={t(lg.taskThreeDOperator.outputExtensions.author.label)} content={author} />
        </div>

        <div className="c-grid-column space-y-5">
          <UI.InfoItem
            label={t(lg.taskThreeDOperator.outputExtensions.createdAt.label)}
            content={formatDate(createdAt, "machine", true)}
          />
          <UI.InfoItem
            label={t(lg.taskThreeDOperator.outputExtensions.authorComment.label)}
            content={authorComment || "-"}
          />
        </div>
      </div>

      <div className="c-grid mt-5 md:mt-10">
        <UI.InfoItem
          label={t(lg.taskThreeDOperator.outputExtensions.uploadedAttachments.title)}
          className="c-grid-column space-y-5"
        >
          {files && files.length > 0 ? (
            <div className={"space-y-5"}>
              {files.map(file => (
                <MediaCard key={file.id} fileName={file.fileName || ""} fileId={file.id} fileUrl={file.url || ""} />
              ))}
            </div>
          ) : (
            "-"
          )}
        </UI.InfoItem>

        <UI.InfoItem
          label={t(lg.taskThreeDOperator.outputExtensions.comment.label)}
          content={threeDOperatorComment || "-"}
          className="c-grid-column"
        ></UI.InfoItem>
      </div>
    </div>
  );
};
