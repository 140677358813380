import { Select, Spin } from "antd";
import { lg, pluralize } from "assets/translations";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useConstructionObjectsForSelectQuery } from "api";

const FETCH_STEP: number = 8; // count of the objects to load during one fetch

type SelectConstructionObjectProps = {
  constructionSiteId: string;
  /** API filters results by provided accountable manager ID */
  accountableManagerId?: string;
  onChange: (value?: string) => void;
  /** Construction Object ID */
  value: string | undefined;
  disabled?: boolean;
};

export const SelectConstructionObject = ({
  constructionSiteId,
  accountableManagerId,
  value,
  onChange,
  disabled = false
}: SelectConstructionObjectProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  /* debounced search */
  useDebounce(() => setDebouncedSearchValue(searchValue), 500, [searchValue]);

  const fetchSkip: boolean = !constructionSiteId || disabled;

  const { data: dataSearch, loading } = useConstructionObjectsForSelectQuery({
    variables: {
      constructionSiteId: constructionSiteId || "",
      name: debouncedSearchValue,
      first: FETCH_STEP,
      accountableManagerId: accountableManagerId
    },
    fetchPolicy: "cache-and-network",
    skip: fetchSkip
  });

  const objects = useMemo(() => dataSearch?.constructionSite?.constructionObjects, [dataSearch]);
  const objectsMapped = useMemo(() => objects?.edges.flatMap(({ node }) => (node ? [node] : [])) || [], [objects]);
  const totalCountOfObjects = useMemo(() => objects?.totalCount, [objects]);

  // no objects for parsed ConstructionSiteId
  const noData = useMemo(
    () => !disabled && !loading && !debouncedSearchValue && objectsMapped.length <= 0,
    [disabled, loading, debouncedSearchValue, objectsMapped]
  );

  const options = useMemo(
    () =>
      objectsMapped.map(result => ({
        value: result.id,
        label: result.name
      })),
    [objectsMapped]
  );

  const resetSearch = useCallback(() => setSearchValue(undefined), []);

  const handleSearch = useCallback((value: string) => setSearchValue(value), []);
  const handleChange = useCallback(
    (value: string) => {
      onChange(value);
      resetSearch();
    },
    [onChange, resetSearch]
  );

  return (
    <>
      <Select
        loading={loading}
        value={value}
        notFoundContent={loading ? <Spin size="small" className="w-full" /> : t(lg.searchSelect.common.noResult)}
        placeholder={t(lg.searchSelect.constructionObject.placeholder)}
        dropdownRender={menu => {
          if (options.length >= FETCH_STEP) {
            return (
              <div>
                {menu}
                <div className="text-gray-700 px-3">
                  {t(
                    pluralize(
                      totalCountOfObjects || 0,
                      lg.searchSelect.common.resultInfo.singularNominative,
                      lg.searchSelect.common.resultInfo.nominativePlural,
                      lg.searchSelect.common.resultInfo.genitivePlural,
                      lg.searchSelect.common.resultInfo.zero
                    ),
                    {
                      currentCount: options.length,
                      totalCount: totalCountOfObjects
                    }
                  )}
                </div>
              </div>
            );
          }

          return menu;
        }}
        filterOption={false}
        style={{ width: "100%" }}
        options={options}
        disabled={disabled}
        showSearch
        allowClear
        onSearch={handleSearch}
        onChange={handleChange}
      />
      {noData && accountableManagerId ? (
        <div className="text-red-600 mt-1">{t(lg.searchSelect.constructionObject.noDataForManager)}</div>
      ) : null}
      {noData && !accountableManagerId ? (
        <div className="text-red-600 mt-1">{t(lg.searchSelect.constructionObject.noData)}</div>
      ) : null}
    </>
  );
};
