/**
 * Converts string to ReactNode and replaces **text** with <strong>text</strong>.
 * Only works with ** parsing for now.
 */
import { ReactNode } from "react";

type Props = {
  children: string;
};

export const ParseMD = ({ children = "" }: Props) => {
  const matches = children.match(/\*\*(\S(.*?\S)?)\*\*/gm) || [];
  if (!matches.length) return <>{children}</>;

  const parts: ReactNode[] = [];

  matches.forEach((match, i) => {
    const _parts = children.split(match);
    children = _parts[1];
    parts.push(_parts[0]);
    parts.push(
      <span className="font-medium" key={i}>
        {match.replace(/\*\*/g, "")}
      </span>
    );
  });

  parts.push(children);

  return <>{parts}</>;
};
