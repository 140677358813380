import { PropsWithChildren } from "react";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";

type Props = {
  visible: boolean;
  onOk: () => any;
  onCancel: () => any;
};

export const RoleChange = ({ visible, onOk, onCancel }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        <div className="flex items-center">
          <InfoCircleOutlined className="text-lg text-blue-600" />
          <span className="ml-4">{t(lg.constructionObject.pageEdit.modals.roleChange.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.constructionObject.pageEdit.modals.roleChange.buttonOkText)}
      cancelText={t(lg.constructionObject.pageEdit.modals.roleChange.buttonCancelText)}
      okButtonProps={{ type: "primary" }}
      onOk={onOk}
      onCancel={onCancel}
      closable={false}
      forceRender
    >
      <div className="text-base">{t(lg.constructionObject.pageEdit.modals.roleChange.description)}</div>
    </Modal>
  );
};
