import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import { lg } from "assets/translations";

/**
 * Return Documentation Record to the author
 *
 * doc:
 * https://hrdlicka.atlassian.net/wiki/spaces/HRD/pages/786537/UC0017+-+U+ivatel+vrac+zpracov+n+Dokumenta+n+ho+z+znamu
 * */

export type BackCreatedHandler = (comment?: string) => any;

enum FormFields {
  comment = "comment"
}

type Props = {
  visible: boolean;
  loading?: boolean;
  onSubmit: BackCreatedHandler;
  onCancel: () => any;
};

export const ModalBackCreated = ({ visible, loading, onSubmit, onCancel }: Props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const handleOk = useCallback(
    () => onSubmit(form.getFieldsValue()[FormFields.comment] || undefined),
    [onSubmit, form]
  );

  return (
    <Modal
      title={
        <div className="flex items-center">
          <RollbackOutlined className="text-purple-600" />
          <span className="ml-2">{t(lg.documentationRecordActions.backCreated.modal.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.backCreated.modal.buttonOk)}
      cancelText={t(lg.documentationRecordActions.backCreated.modal.buttonCancel)}
      okButtonProps={{ type: "primary", disabled: loading }}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
    >
      <div className="text-base">{t(lg.documentationRecordActions.backCreated.modal.description)}</div>
      <Form form={form} name="modal_send" layout="vertical" className="mt-5">
        <Form.Item
          name={FormFields.comment}
          className="mb-0"
          label={t(lg.documentationRecordActions.backCreated.modal.comment.label)}
        >
          <Input.TextArea
            rows={3}
            placeholder={t(lg.documentationRecordActions.backCreated.modal.comment.placeholder)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
