import { useCallback, useRef, useState } from "react";

export const useTable = () => {
  const [sortedInfo, setSortedInfo] = useState(null);
  const [filteredInfo, setFilteredInfo] = useState(null);
  const searchInputRef = useRef(null);

  const handleChange = useCallback((pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
    setFilteredInfo(filters);
  }, []);

  const handleSearch = useCallback((selectedKeys: any, confirm: any) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters: any) => {
    clearFilters();
  }, []);

  return { sortedInfo, filteredInfo, searchInputRef, handleChange, handleSearch, handleReset };
};
