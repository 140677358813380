import { Section } from "components/Section";
import { lg } from "assets/translations";
import { Trans, useTranslation } from "react-i18next";

export const MyTaskOperator = () => {
  const [t] = useTranslation();
  return (
    <>
      <Section title={t(lg.aboutPage.tabs.myTasks.content.operator.aboutYourRole.title)}>
        <div className="c-grid mt-10">
          <div className="c-grid-column">{t(lg.aboutPage.tabs.myTasks.content.operator.aboutYourRole.description)}</div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.myTasks.content.operator.tasks.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="font-medium">{t(lg.aboutPage.tabs.myTasks.content.operator.tasks.list.label)}</div>
            <ul className="list-disc list-outside pl-5">
              <Trans i18nKey={lg.aboutPage.tabs.myTasks.content.operator.tasks.list.items}>
                <li />
              </Trans>
            </ul>
          </div>
          <div className="c-grid-column">
            <div className="whitespace-pre-line">{t(lg.aboutPage.tabs.myTasks.content.operator.tasks.description)}</div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.myTasks.content.operator.authorizationForYou.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <ul className="list-disc list-outside whitespace-pre-line pl-5">
              <Trans i18nKey={lg.aboutPage.tabs.myTasks.content.operator.authorizationForYou.list1.items}>
                <li />
              </Trans>
            </ul>
          </div>
          <div className="c-grid-column">
            <ul className="list-disc list-outside pl-5">
              <Trans i18nKey={lg.aboutPage.tabs.myTasks.content.operator.authorizationForYou.list2.items}>
                <li />
              </Trans>
            </ul>
          </div>
        </div>
      </Section>
    </>
  );
};
