import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { ComponentPropsWithRef } from "react";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";

type OutputExtensionEditToggleProps = {
  isEditing: boolean;
  onEditClick: ComponentPropsWithRef<typeof Button>["onClick"];
  onSaveClick: ComponentPropsWithRef<typeof Button>["onClick"];
  className?: string;
};

export const OutputExtensionEditToggle = ({
  isEditing,
  onEditClick,
  onSaveClick,
  className
}: OutputExtensionEditToggleProps) => {
  const { t } = useTranslation();
  return isEditing ? (
    <Button type={"primary"} size={"large"} className={className} icon={<CheckOutlined />} onClick={onSaveClick}>
      {t(lg.taskThreeDOperator.outputExtensions.editModeToggle.buttonSave)}
    </Button>
  ) : (
    <Button type={"primary"} size={"large"} className={className} onClick={onEditClick}>
      {t(lg.taskThreeDOperator.outputExtensions.editModeToggle.buttonEdit)}
    </Button>
  );
};
