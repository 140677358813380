import { PropsWithChildren, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal } from "antd";
import { CustomIcon } from "components/icons/CustomIcon";
import { lg } from "assets/translations";

export type SubmitManagerApprovalHandler = (comment?: string) => any;

enum FormFields {
  comment = "comment"
}

type Props = {
  visible: boolean;
  loading?: boolean;
  onSubmit: SubmitManagerApprovalHandler;
  onCancel: () => any;
};

export const ModalSubmitManagerApproval = ({ visible, loading, onSubmit, onCancel }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const handleOk = useCallback(
    () => onSubmit(form.getFieldsValue()[FormFields.comment] || undefined),
    [onSubmit, form]
  );

  return (
    <Modal
      title={
        <div className="flex items-center">
          <CustomIcon type="send" className="text-lg text-purple-600" />
          <span className="ml-2">{t(lg.documentationRecordActions.submitManagerApproval.modal.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.submitManagerApproval.modal.buttonOk)}
      cancelText={t(lg.documentationRecordActions.submitManagerApproval.modal.buttonCancel)}
      okButtonProps={{ type: "primary", disabled: loading }}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
    >
      <div className="text-base">{t(lg.documentationRecordActions.submitManagerApproval.modal.description)}</div>
      <Form form={form} name="modal_send" layout="vertical" className="mt-5">
        <Form.Item
          name={FormFields.comment}
          className="mb-0"
          label={t(lg.documentationRecordActions.submitManagerApproval.modal.comment.label)}
        >
          <Input.TextArea
            rows={3}
            placeholder={t(lg.documentationRecordActions.submitManagerApproval.modal.comment.placeholder)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
