import { PropsWithChildren } from "react";
import { DocumentationRecordState } from "api";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";
import { StateIndicatorDocumentationRecord } from "components/Indicators/State/StateIndicatorDocumentationRecord";
import { useFormat } from "hooks";

type User = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string | null;
};

type Props = {
  description: string;
  phase: { name: string; order: number };
  state: DocumentationRecordState;
  accountableManager: User | null;
  accountableOperator: User | null;
  accountableSurveyor?: User | null;
  hasAccountableSurveyor?: boolean;
};

export const GeneralInfo = ({
  description,
  phase,
  state,
  accountableManager,
  accountableOperator,
  accountableSurveyor = null,
  hasAccountableSurveyor = false
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className="c-grid">
      {/* left */}
      <div className="c-grid-column">
        <div>
          <div className="font-medium">
            {t(lg.documentationRecord.pageDetail.baseInformation.documentationDescription.label)}
          </div>
          <span className="text-secondary whitespace-pre-line">{description || "-"}</span>
        </div>

        <div className="mt-5">
          <div className="font-medium">{t(lg.documentationRecord.pageDetail.baseInformation.addedToPhase.label)}</div>
          <span className="text-secondary whitespace-nowrap">
            {phase.name} ({phase.order}.)
          </span>
        </div>

        <div className="mt-5">
          <div className="font-medium">
            {t(lg.documentationRecord.pageDetail.baseInformation.documentationState.label)}
          </div>

          <StateIndicatorDocumentationRecord state={state} />
        </div>
      </div>

      {/* right */}
      <div className="c-grid-column">
        <div>
          <div className="font-medium">{t(lg.documentationRecord.pageDetail.baseInformation.manager.label)}</div>
          <UserInfo user={accountableManager} role={"manager"} />

          <div className="mt-5">
            <div className="font-medium">{t(lg.documentationRecord.pageDetail.baseInformation.operator.label)}</div>
            <UserInfo user={accountableOperator} role={"operator"} />
          </div>

          {hasAccountableSurveyor && (
            <div className="mt-5">
              <div className="font-medium">{t(lg.documentationRecord.pageDetail.baseInformation.surveyor.label)}</div>
              <UserInfo user={accountableSurveyor} role={"surveyor"} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const UserInfo = ({ user, role }: { user: User | null; role: "manager" | "operator" | "surveyor" }) => {
  const { t } = useTranslation();
  const { formatPhoneNumber } = useFormat();

  return (
    <span className="text-secondary whitespace-nowrap">
      {user ? (
        <>
          {user.firstName} {user.lastName} ({user.email})
          <br />
          {user.phoneNumber && formatPhoneNumber(user.phoneNumber)}
        </>
      ) : (
        t(lg.documentationRecord.pageDetail.baseInformation[role].defaultValue)
      )}
    </span>
  );
};
