import { useCatalogQuery } from "api";
import { useMemo } from "react";

/*
 * Get info about status of the 3D model download feature
 * Catalog -> downloadThreeDModel
 *
 * doc:
 * https://hrdlicka.atlassian.net/wiki/spaces/HRD/pages/627834899/FN0018+v02+-+Nastaven+kl+ov+ch+parametr+syst+mu
 * https://hrdlicka.atlassian.net/wiki/spaces/HRD/pages/627736586
 *
 * */
export const useDownloadThreeDModel = () => {
  const { data: dataCatalog } = useCatalogQuery({ fetchPolicy: "cache-first" });
  const price = useMemo(() => dataCatalog?.catalog?.downloadThreeDModel, [dataCatalog]);

  const isFree = useMemo(() => typeof price === "number" && price === 0, [price]);
  const isPaid = useMemo(() => typeof price === "number" && price > 0, [price]);
  const isDisabled = useMemo(() => typeof price !== "number", [price]);

  return {
    price,

    isFree,
    isPaid,
    isDisabled
  };
};
