import { PropsWithChildren, ReactNode, useMemo } from "react";
import { DocumentationRecordState, DocumentationRecordType } from "api";
import { useTranslation } from "react-i18next";
import { CustomIconType } from "components/icons/CustomIcon";
import { TypeIndicatorProps } from "components/Indicators/Type/index";
import { TypeIndicator } from "components/Indicators/Type/TypeIndicator";
import { documentationRecordStates, documentationRecordTypes } from "api/enums";

type Props = {
  type: DocumentationRecordType;
  // color Type icon by state
  state?: DocumentationRecordState | null;
} & TypeIndicatorProps;

export const TypeIndicatorDocumentationRecord = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const iconType = useMemo<CustomIconType>(() => {
    switch (props.type) {
      case DocumentationRecordType.NoThreeDNoVideo:
        if (props.state === DocumentationRecordState.Created) return "read-nothing-gray";
        if (props.state === DocumentationRecordState.Finished) return "read-nothing-green";
        if (props.state === DocumentationRecordState.Rejected) return "read-nothing-red";
        return props.state !== undefined ? "read-nothing-orange" : "read-nothing";
      case DocumentationRecordType.NoThreeDVideo:
        if (props.state === DocumentationRecordState.Created) return "read-question-mark-gray";
        if (props.state === DocumentationRecordState.Finished) return "read-question-mark-green";
        if (props.state === DocumentationRecordState.Rejected) return "read-question-mark-red";
        return props.state !== undefined ? "read-question-mark-orange" : "read-question-mark";
      case DocumentationRecordType.ThreeDNoScale:
        if (props.state === DocumentationRecordState.Created) return "read-no-geo-gray";
        if (props.state === DocumentationRecordState.Finished) return "read-no-geo-green";
        if (props.state === DocumentationRecordState.Rejected) return "read-no-geo-red";
        return props.state !== undefined ? "read-no-geo-orange" : "read-no-geo";
      case DocumentationRecordType.ThreeDScale:
        if (props.state === DocumentationRecordState.Created) return "read-geo-gray";
        if (props.state === DocumentationRecordState.Finished) return "read-geo-green";
        if (props.state === DocumentationRecordState.Rejected) return "read-geo-red";
        return props.state !== undefined ? "read-geo-orange" : "read-geo";
    }
  }, [props.type, props.state]);

  const typeName = useMemo<string>(() => t(documentationRecordTypes[props.type].nameI18nKey), [t, props.type]);

  const label = useMemo<ReactNode>(() => (props.label !== undefined ? props.label : typeName), [props.label, typeName]);

  const tooltipTitle = useMemo<string>(
    () => `${typeName}${props.state ? ` - ${t(documentationRecordStates[props.state].nameI18nKey)}` : ""}`,
    [t, typeName, props.state]
  );

  return <TypeIndicator {...props} icon={iconType} tooltipTitle={tooltipTitle} label={label} />;
};
