import { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button, Drawer, Spin } from "antd";
import { DeleteOutlined, DownloadOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Header } from "components/Header";
import { Subheader } from "components/Subheader";
import { Link } from "components/Link";
import { Delete } from "components/ThreeDModel/Delete";
import { TypeIndicatorThreeDModel } from "components/Indicators/Type";
import {
  SubheaderOptions,
  useDownloadThreeDModel,
  useFormat,
  useMe,
  usePermissions,
  useSubheader,
  useThreeDViewer
} from "hooks";
import { routes } from "routes";
import { lg } from "assets/translations/lg";
import { DocumentationRecordType } from "api";
import { hasFilesForDownload, isThreeDOperator } from "helpers";
import { coordinateSystems } from "api/enums";
import { LayoutThreeDViewer } from "components/LayoutThreeDViewer";
import { ModalSharingThreeDModel } from "components/ThreeDModel/ModalSharingThreeDModel";
import { ModalDownloadThreeDModel } from "components/ThreeDModel/ModalDownloadThreeDModel";

export const ThreeDViewer = ({ id }: RouteComponentProps<{ id: string }>) => (
  <LayoutThreeDViewer>
    <ThreeDViewerContent id={id} />
  </LayoutThreeDViewer>
);

const ThreeDViewerContent = ({ id }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing 3D model ID.");

  const [t] = useTranslation();
  const { me } = useMe();
  const { formatDate } = useFormat();

  const { data, navigation, state, handlers, loading } = useThreeDViewer(id);

  const hasDeleteButton = useMemo(() => !isThreeDOperator(me), [me]);

  const { model, site, object, record, phase, size, type, viewerUrl } = data;

  const _hasFilesForDownload = useMemo<boolean>(() => !!model && hasFilesForDownload(model), [model]);
  const { isDisabled: isDownloadDisabled } = useDownloadThreeDModel();
  const canDownloadThreeDModel = useMemo(
    () => !isDownloadDisabled && _hasFilesForDownload,
    [isDownloadDisabled, _hasFilesForDownload]
  );

  const { canShareThreeDModel } = usePermissions({});

  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const recordName = record?.name || "...";
    const objectName = object?.name || "...";
    const siteName = site?.name || "...";

    return {
      heading: t(lg.threeDViewer.header.heading, { documentation: recordName }),
      breadcrumb: [
        {
          value: t(lg.constructionSites.header.title),
          route: navigation.canNavigate ? routes.constructionSites : undefined
        },
        { value: siteName, route: navigation.canNavigate ? navigation.siteRoute : undefined },
        { value: objectName, route: navigation.canNavigate ? navigation.objectRoute : undefined },
        { value: recordName, route: navigation.canNavigate ? navigation.documentationRoute : undefined },
        { value: t(lg.threeDViewer.header.threeDModel) }
      ],
      buttons: [
        canDownloadThreeDModel ? (
          <ModalDownloadThreeDModel modelId={id}>
            <Button
              className="px-0" // reduce spacing between download and share buttons
              icon={<DownloadOutlined />}
              key="three-d-model-download"
              type={"link"}
              size={"large"}
              title={t(lg.threeDViewer.header.buttonDownload)}
            >
              {t(lg.threeDViewer.header.buttonDownload)}
            </Button>
          </ModalDownloadThreeDModel>
        ) : null,
        canShareThreeDModel ? (
          <ModalSharingThreeDModel modelId={id}>
            <Button
              icon={<ShareAltOutlined />}
              key="three-d-model-share"
              className="px-0"
              type={"link"}
              size={"large"}
              title={t(lg.threeDViewer.header.buttonShare)}
            >
              {t(lg.threeDViewer.header.buttonShare)}
            </Button>
          </ModalSharingThreeDModel>
        ) : null,
        <Button key="three-d-model-back" size={"large"} onClick={handlers.handleClickBack}>
          {t(lg.threeDViewer.header.buttonSecondaryText)}
        </Button>,
        <Button key="three-d-model-detail" type={"primary"} size={"large"} onClick={handlers.handleClickOpenDrawer}>
          {t(lg.threeDViewer.header.buttonPrimaryText)}
        </Button>
      ]
    };
  }, [
    canShareThreeDModel,
    canDownloadThreeDModel,
    handlers.handleClickBack,
    handlers.handleClickOpenDrawer,
    navigation.canNavigate,
    navigation.documentationRoute,
    navigation.objectRoute,
    navigation.siteRoute,
    object,
    record,
    site,
    id,
    t
  ]);
  useSubheader(subheaderOptions);

  return (
    <section className="flex flex-col min-h-screen">
      <Header />

      <section className="flex flex-grow">
        <div className="flex flex-col flex-grow bg-blue-100">
          <Subheader className="z-50" />

          {loading && (
            <div className="flex flex-1 items-center justify-center">
              <Spin />
            </div>
          )}

          {!loading && model && record && site && object && phase && (
            <div className="flex-1 relative overflow-hidden">
              <Drawer
                title={t(lg.threeDViewer.drawer.title)}
                placement="right"
                closable
                onClose={handlers.handleClickCloseDrawer}
                visible={state.visibleDrawer}
                getContainer={false}
                style={{ position: "absolute" }}
                bodyStyle={{ flex: 1, display: "flex", flexDirection: "column" }}
                className="z-40"
              >
                <div>
                  <div>
                    <div className="font-medium">{t(lg.threeDViewer.drawer.documentationRecord)}</div>
                    {navigation.canNavigate ? (
                      <Link href={navigation.documentationRoute}>{record.name}</Link>
                    ) : (
                      <div className="text-secondary">{record.name}</div>
                    )}
                  </div>
                  <div className="mt-3">
                    <div className="font-medium">{t(lg.threeDViewer.drawer.constructionSite)}</div>
                    {navigation.canNavigate ? (
                      <Link href={navigation.siteRoute}>{site.name}</Link>
                    ) : (
                      <div className="text-secondary">{site.name}</div>
                    )}
                  </div>
                  <div className="mt-3">
                    <div className="font-medium">{t(lg.threeDViewer.drawer.constructionObject)}</div>
                    {navigation.canNavigate ? (
                      <Link href={navigation.objectRoute}>{object.name}</Link>
                    ) : (
                      <div className="text-secondary">{object.name}</div>
                    )}
                  </div>
                  <div className="mt-3">
                    <div className="font-medium">{t(lg.threeDViewer.drawer.phase)}</div>
                    {navigation.canNavigate ? (
                      <Link href={navigation.phaseRoute}>{phase.name}</Link>
                    ) : (
                      <div className="text-secondary">{phase.name}</div>
                    )}
                  </div>
                  <div className="mt-3">
                    <div className="font-medium">{t(lg.threeDViewer.drawer.fileSize)}</div>
                    <div className="text-secondary">{size}</div>
                  </div>
                  <div className="mt-3">
                    <div className="font-medium">{t(lg.threeDViewer.drawer.threeDModelType)}</div>
                    <div className="text-secondary">
                      <TypeIndicatorThreeDModel type={type} hideIcon={true} />
                    </div>
                  </div>
                  {record.type === DocumentationRecordType.ThreeDScale && model.coordinateSystem ? (
                    <div className="mt-3">
                      <div className="font-medium">{t(lg.threeDViewer.drawer.coordinatesSystem)}</div>
                      <div className="text-secondary">{t(coordinateSystems[model.coordinateSystem].nameI18nKey)}</div>
                    </div>
                  ) : null}
                </div>

                <div className="mt-auto">
                  <div>
                    <div className="text-gray-700">{t(lg.threeDViewer.drawer.createdDateAndTime)}</div>
                    <div className="text-secondary">{formatDate(model.createdAt, "human", true)}</div>
                  </div>
                  <div className="mt-3">
                    <div className="text-gray-700">{t(lg.threeDViewer.drawer.author)}</div>
                    <div>
                      <span className="text-secondary">
                        {record.accountableThreeDOperator?.firstName} {record.accountableThreeDOperator?.lastName}
                      </span>{" "}
                      <span className="text-gray-700">({record.accountableThreeDOperator?.email})</span>
                    </div>
                  </div>
                  {hasDeleteButton && (
                    <>
                      <hr className="-mx-6 mt-6" />
                      <div className="text-center">
                        <Delete id={id} onSuccess={handlers.handleDeleteSuccess}>
                          <Button danger type="primary" icon={<DeleteOutlined />} className="mt-6">
                            {t(lg.threeDViewer.drawer.actions.buttonDeleteThreeDModelText)}
                          </Button>
                        </Delete>
                      </div>
                    </>
                  )}
                </div>
              </Drawer>

              <div className="absolute top-0 right-0 bottom-0 left-0 flex router-fix">
                {viewerUrl && <iframe src={viewerUrl} title="3D Viewer" className="flex flex-1" />}
              </div>
            </div>
          )}
        </div>
      </section>
    </section>
  );
};
