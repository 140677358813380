import { Maybe, MediaFragment, MediaState } from "api";
import { File } from "types/media";

export const mediaToFile = (media?: Pick<MediaFragment, "id" | "url" | "fileName"> | null): File | undefined => {
  if (!media || !media.url || !media.fileName) return undefined;

  return {
    id: media.id,
    url: media.url,
    fileName: media.fileName
  };
};

export const getMediaTotalSize = (files: { size: Maybe<number> }[]): number =>
  files
    .flatMap(file => (typeof file.size === "number" ? [file.size] : []))
    .reduce((total, fileSize) => total + fileSize, 0);

const getFileExtension = (filename: string): string => filename.replace(/.*?(?:\.(\w+))?$/, "$1").toLowerCase();

export const isUploaded = (state: MediaState): boolean => state === MediaState.Uploaded;

export const isAllowedFileType = (fileName: string, extensions: string[]): boolean =>
  extensions.includes(getFileExtension(fileName));

export const isImage = (path: string): boolean => !!path.toLowerCase().match(/\.(jpg|jpeg|gif|png|tiff|bmp)$/gi);
export const isVideo = (path: string): boolean => !!path.toLowerCase().match(/\.(avi|m4v|mov|mpg|mp4|webm)$/gi);

export enum MediaType {
  Image = "image",
  Video = "video",
  Other = "other"
}

export const getMediaType = (path: string): MediaType => {
  if (isImage(path)) return MediaType.Image;
  if (isVideo(path)) return MediaType.Video;
  return MediaType.Other;
};
