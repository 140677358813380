import { PropsWithChildren, ReactNode, useEffect } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Spin } from "antd";
import { lg } from "assets/translations";
import { useLanguageResolver, useServiceWorker } from "hooks";
import { useTranslation } from "react-i18next";
import { useAuth } from "api/auth";

type Props = {
  childLeft: ReactNode;
  childRight: ReactNode;
  loading?: boolean;
};

export const LayoutSimple = ({
  childLeft,
  childRight,
  loading = false,
  children,
  ...res
}: PropsWithChildren<Props>) => {
  useLanguageResolver();

  const [t] = useTranslation();
  const { isUpdateAvailable, updateAssets } = useServiceWorker();

  const { isLoggedIn } = useAuth();

  /*
   * Force update for the anonymous user if new app build exists
   * */
  useEffect(() => {
    if (isLoggedIn() || !isUpdateAvailable) return;
    updateAssets();
  }, [isLoggedIn, isUpdateAvailable, updateAssets]);

  // add update info to the document title
  useEffect(() => {
    if (!isUpdateAvailable) return;

    document.title = `${t(lg.version.update.description)}! ${document.title}`;
  }, [isUpdateAvailable, t]);

  /*
   * Set body element background-color
   * */
  useEffect(() => {
    document.body.classList.add("bg-blue-900");
    return () => document.body.classList.remove("bg-blue-900");
  }, []);

  return (
    <div className="flex flex-col flex-grow bg-blue-900 w-full" {...res}>
      <Header simple={true} className="c-layout-simple-row mb-16 pt-10 lg:pt-20" />

      {loading ? (
        <div className="c-layout-simple-row flex-grow flex items-center justify-center" data-cy={"page-loading"}>
          <Spin />
        </div>
      ) : (
        <>
          {(childLeft || childRight) && (
            <section className="c-layout-simple-row flex flex-col flex-grow items-center justify-center md:flex-row">
              <div className="w-full md:w-1/2 md:mr-10 xl:mr-20 md:ie-h-full">{childLeft}</div>
              <div className="w-full mt-6 md:w-1/2 md:ml-10 md:mt-0 xl:ml-20">{childRight}</div>
            </section>
          )}

          {children && <section className="flex-grow">{children}</section>}
        </>
      )}

      <Footer type="light" height={24} className="c-layout-simple-row" />
    </div>
  );
};
