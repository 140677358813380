import { useCallback } from "react";
import ClientOAuth2 from "client-oauth2";
import { ApolloError } from "apollo-client";
import { getEnv } from "helpers";
import { useLoginMutation } from "./generated";
import {
  clearAuthToken,
  clearLngStorage,
  clearRefreshToken,
  clearSkippedInvitations,
  clearSkippedPurchases,
  getAuthToken,
  setAuthToken,
  setRefreshToken
} from "api/storage";

const client = new ClientOAuth2({
  clientId: getEnv("REACT_APP_AUTH_CLIENT_ID"),
  clientSecret: getEnv("REACT_APP_AUTH_CLIENT_SECRET"),
  accessTokenUri: getEnv("REACT_APP_AUTH_TOKEN_URI"),
  authorizationUri: getEnv("REACT_APP_AUTH_AUTHORIZATION_URI"),
  redirectUri: getEnv("REACT_APP_AUTH_REDIRECT_URI"),
  scopes: ["basic"]
});

export const useAuth = () => {
  const [mutate] = useLoginMutation();

  const authenticate = useCallback(() => window.location.replace(client.token.getUri()), []);

  const login = useCallback(
    async (onSuccess: () => any, onError: (e: ApolloError | any) => any) => {
      try {
        const token = await client.token.getToken(window.location);
        const result = await mutate({
          variables: { input: { authToken: token.accessToken, clientMutationId: "login" } }
        });

        if (!result?.data?.login?.authToken) return;

        setAuthToken(result.data.login.authToken.token);
        setRefreshToken(result.data.login.authToken.refreshToken);

        onSuccess();
      } catch (e) {
        onError(e);
      }
    },
    [mutate]
  );

  const isLoggedIn = useCallback((): boolean => !!getAuthToken(), []);

  const impersonate = useCallback((hash: string, onSuccess: () => any, onError: (e: any) => any) => {
    if (hash.startsWith("#")) hash = hash.substr(1);

    const params: { [key: string]: string | undefined } = {};
    hash.split("&").forEach(item => {
      const splitItem = item.split("=");
      params[splitItem[0]] = splitItem[1];
    });

    if (typeof params.set_token === "string" && params.set_token.length > 0) {
      setAuthToken(params.set_token);
      onSuccess();
      return;
    }

    onError(new Error("impersonation_error"));
  }, []);

  return { client, authenticate, login, isLoggedIn, impersonate };
};

export const logoutUser = () => {
  // clear saved tokens
  clearAuthToken();
  clearRefreshToken();

  // clear user data at local storage
  clearSkippedInvitations();
  clearSkippedPurchases();

  // clear user data at session storage
  clearLngStorage();
};
