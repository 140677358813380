import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { ActionProps, TriggerErrorHandler } from "components/DocumentationRecord/Actions/index";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import {
  ModalSubmitThreeDOperator,
  SubmitThreeDOperatorHandler
} from "components/DocumentationRecord/Actions/modals/ModalSubmitThreeDOperator";
import { resolveError, Triggers, useDocumentationRecordQuery } from "api";
import { lg } from "assets/translations";
import { useAlert, useCatalog, useMe } from "hooks";
import { documentationRecordTriggers } from "api/enums";
import { getCost, getHasEnoughCredits, isOperator, isSurveyor } from "helpers";
import { ModalOrganizationHasNotEnoughCreditToRequestThreeDModel } from "components/DocumentationRecord/Actions/modals/ModalNotEnoughCreditToRequestThreeDModel";

enum ModalType {
  SubmitSimple = "simple",
  SubmitWithCreditDetail = "SubmitWithCreditDetail",
  NotEnoughCredits = "NotEnoughCredits"
}

/** For Trigger SUBMIT_THREE_D_OPERATOR */
export const SubmitThreeDOperator = ({
  documentationRecordId,
  triggers,
  disabled = false,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {},
  onTriggerError = () => {}
}: ActionProps) => {
  const { t } = useTranslation();

  const { me } = useMe();
  const { catalog } = useCatalog();
  const { data, refetch } = useDocumentationRecordQuery({
    variables: { id: documentationRecordId }
  });
  const { showAlert } = useAlert();

  const organization = useMemo(() => {
    return data?.documentationRecord?.constructionObject?.constructionSite?.organization;
  }, [data]);

  const videoType = data?.documentationRecord?.documentationRecordVideo?.type || null;
  const cost = useMemo(() => getCost(videoType, catalog), [videoType, catalog]);
  const organizationName: string = organization?.name || "-";
  const organizationCredits: number = organization?.credits || 0;
  const hasEnoughCredits: boolean = getHasEnoughCredits(organizationCredits, cost);

  const isAvailableToViewPriceInfo: boolean = !(isSurveyor(me) || isOperator(me));

  const modalType: ModalType = isAvailableToViewPriceInfo
    ? ModalType.SubmitWithCreditDetail
    : hasEnoughCredits
      ? ModalType.SubmitSimple
      : ModalType.NotEnoughCredits;

  const [modalVisible, setModalVisible] = useState(false);

  const handleTriggerError: TriggerErrorHandler = useCallback(
    async (e, trigger) => {
      onTriggerError(e, trigger);

      const response = await refetch();
      const record = response?.data?.documentationRecord;
      if (!record) return;

      const organization = record.constructionObject.constructionSite.organization;
      const organizationCredits: number = organization.credits || 0;
      const hasEnoughCredits: boolean = getHasEnoughCredits(organizationCredits, cost);

      // ModalOrganizationHasNotEnoughCreditToRequestThreeDModel is visible
      if (!isAvailableToViewPriceInfo && !hasEnoughCredits) return;

      resolveError(e, {}, showAlert);
    },
    [onTriggerError, isAvailableToViewPriceInfo, cost, refetch, showAlert]
  );

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.SubmitThreeDOperator,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    onAfterTrigger,
    handleTriggerError
  );

  const handleClick = useCallback(() => setModalVisible(true), []);
  const handleCancel = useCallback(() => setModalVisible(false), []);
  const handleSubmit = useCallback<SubmitThreeDOperatorHandler>(comment => callTrigger(comment), [callTrigger]);

  if (!available) return null;

  return (
    <>
      <Button type="primary" onClick={handleClick} size="large" disabled={disabled || !conditions || loading}>
        {t(!loading ? documentationRecordTriggers[Triggers.SubmitThreeDOperator].buttonI18nKey : lg.saving)}
        {!loading ? <ArrowRightOutlined /> : <LoadingOutlined />}
      </Button>

      {modalType === ModalType.SubmitSimple && (
        <ModalSubmitThreeDOperator.Simple
          visible={modalVisible}
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}
      {modalType === ModalType.SubmitWithCreditDetail && (
        <ModalSubmitThreeDOperator.WithPriceInfo
          visible={modalVisible}
          loading={loading}
          organizationCredits={organizationCredits}
          cost={cost}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      )}

      {modalType === ModalType.NotEnoughCredits && (
        <ModalOrganizationHasNotEnoughCreditToRequestThreeDModel
          organizationName={organizationName}
          visible={modalVisible}
          onClose={handleCancel}
        />
      )}
    </>
  );
};
