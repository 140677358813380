import {
  cloneElement,
  ComponentPropsWithoutRef,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { message, Modal } from "antd";
import { useAlert } from "hooks";
import { resolveError, useDocumentationRecordRelocateMutation } from "api";
import { lg } from "assets/translations";
import { SelectConstructionSite } from "components/SelectConstructionSite";
import { SelectConstructionObject } from "components/SelectConstructionObject";
import { SelectConstructionPhase } from "components/SelectConstructionPhase";

type Props = {
  organizationId: string;
  documentationRecordId: string;
  children: ReactElement;
};

export const ModalMove = ({ organizationId, documentationRecordId, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [relocateMutation, { loading: sending }] = useDocumentationRecordRelocateMutation();

  const [siteId, setSiteId] = useState<string>();
  const [objectId, setObjectId] = useState<string>();
  const [phaseId, setPhaseId] = useState<string>();

  const [visible, setVisible] = useState(false);
  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);

  const sitesDisabled = useMemo<boolean>(() => sending, [sending]);
  const objectsDisabled = useMemo<boolean>(() => !siteId || sending, [siteId, sending]);
  const phasesDisabled = useMemo<boolean>(() => !siteId || !objectId || sending, [siteId, objectId, sending]);
  const okButtonDisabled = useMemo<boolean>(
    () => !siteId || !objectId || !phaseId || sending,
    [siteId, objectId, phaseId, sending]
  );

  const handleChangeSite: ComponentPropsWithoutRef<typeof SelectConstructionSite>["onChange"] = useCallback(value => {
    setSiteId(value);
    setObjectId(undefined);
    setPhaseId(undefined);
  }, []);

  const handleChangeObject: ComponentPropsWithoutRef<typeof SelectConstructionObject>["onChange"] = useCallback(
    value => {
      setObjectId(value);
      setPhaseId(undefined);
    },
    []
  );
  const handleChangePhase: ComponentPropsWithoutRef<typeof SelectConstructionPhase>["onChange"] = useCallback(
    value => setPhaseId(value),
    []
  );

  const reset = useCallback(() => {
    setSiteId(undefined);
    setObjectId(undefined);
    setPhaseId(undefined);
  }, []);

  const handleOk = useCallback(async () => {
    if (!siteId || !objectId || !phaseId) return;

    try {
      await relocateMutation({
        variables: {
          input: {
            documentationRecord: documentationRecordId,
            constructionObject: objectId,
            constructionPhase: phaseId
          }
        }
      });

      message.success(t(lg.documentationRecord.pageEdit.messages.documentationMoved));
      close();
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [relocateMutation, close, documentationRecordId, siteId, objectId, phaseId, t, showAlert]);
  const handleCancel = useCallback(() => close(), [close]);
  const handleAfterClose = useCallback(() => reset(), [reset]);

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={t(lg.documentationRecord.pageEdit.modals.move.title)}
        open={visible}
        okText={t(lg.documentationRecord.pageEdit.modals.move.buttonOkText)}
        cancelText={t(lg.documentationRecord.pageEdit.modals.move.buttonCancelText)}
        okButtonProps={{
          type: "primary",
          disabled: okButtonDisabled
        }}
        confirmLoading={sending}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={handleAfterClose}
        closable={false}
      >
        <label className="block">{t(lg.documentationRecord.pageEdit.modals.move.form.construction.label)}</label>
        <SelectConstructionSite
          organizationId={organizationId}
          value={siteId}
          onChange={handleChangeSite}
          disabled={sitesDisabled}
        />

        <label className="block mt-5">{t(lg.documentationRecord.pageEdit.modals.move.form.object.label)}</label>
        <SelectConstructionObject
          constructionSiteId={siteId || ""}
          value={objectId}
          onChange={handleChangeObject}
          disabled={objectsDisabled}
        />

        <label className="block mt-5">{t(lg.documentationRecord.pageEdit.modals.move.form.phase.label)}</label>
        <SelectConstructionPhase
          constructionSiteId={siteId || ""}
          value={phaseId}
          onChange={handleChangePhase}
          disabled={phasesDisabled}
          allowClear
        />
      </Modal>
    </>
  );
};
