import { CustomIcon, CustomIconType } from "components/icons/CustomIcon";
import clsx from "clsx";
import { classes } from "components/Homepage/classes";
import { SimpleLoader } from "components/SimpleLoader";
import { Button } from "antd";

type ContactCardProps = {
  loading: boolean;
  title: string;
  description: string;
  customIconType: CustomIconType;
  actionProps: Omit<
    JSX.IntrinsicElements["a"] & { icon: JSX.Element; iconAlign?: "start" | "end" },
    "ref" | "className"
  >;
};
export const ContactCard = ({
  loading,
  title,
  description,
  customIconType,
  actionProps: { icon, iconAlign = "start", ...actionProps }
}: ContactCardProps) => (
  <div className="">
    <div className="flex flex-col ">
      <CustomIcon type={customIconType} className="block w-11 h-11 text-blue-500 mx-auto" />
      <h3 className={clsx(classes.h3, "text-purple-200 text-center md:whitespace-nowrap", "mb-0 mt-2.5")}>{title}</h3>
    </div>

    <div>
      {loading ? <SimpleLoader /> : null}
      {!loading && !!actionProps.children ? (
        <>
          {/* mobile */}
          <Button {...actionProps} type={"primary"} size={"large"} className="block w-full mt-5 md:hidden">
            {iconAlign === "start" ? icon : null}
            {actionProps.children}
            {iconAlign === "end" ? icon : null}
          </Button>
          {/* desktop */}

          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a {...actionProps} className="hidden md:block c-link-light text-center mt-2.5" />
        </>
      ) : null}
      <div className="text-xs text-white text-center leading-relaxed md:text-sm md:text-purple-200 md:leading-6 mt-5 md:mt-0">
        {description}
      </div>
    </div>
  </div>
);
