import { PropsWithChildren, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Coords } from "google-map-react";
import {
  Point,
  PointChangeHandler,
  PointItem,
  PointRemoveHandler,
  PointSaveHandler
} from "components/Task/GroundControlPoints/Point";
import { lg } from "assets/translations";

export * from "./GroundControlPointsOverview";

type Props = {
  organizationId: string;
  documentationRecordId: string;
  constructionSiteCoords: Coords[];
  constructionObjectCoords: Coords[];
  savedPoints: PointItem[];
  minimalPoints: number;
};

export const AddStandardGroundControlPoints = ({
  organizationId,
  documentationRecordId,
  savedPoints,
  constructionSiteCoords,
  constructionObjectCoords,
  minimalPoints
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const [points, setPoints] = useState<PointItem[]>(
    !savedPoints.length
      ? [
          {
            id: "0",
            order: 1,
            description: "",
            photos: [],
            draft: true,
            saved: false
          }
        ]
      : savedPoints
  );

  const handleClickAdd = useCallback(() => {
    setPoints(prevPoints => {
      let highestOrder = 0;
      for (const point of prevPoints) {
        if (point.order > highestOrder) highestOrder = point.order;
      }

      const order = highestOrder ? highestOrder + 1 : 1;

      return [
        ...prevPoints,
        {
          id: prevPoints.length.toString(),
          order: order,
          description: "",
          photos: [],
          draft: true,
          saved: false
        }
      ];
    });
  }, []);

  const handleSavePoint = useCallback<PointSaveHandler>(
    (id, point) => setPoints(prevPoints => prevPoints.map(_point => (_point.id === id ? point : _point))),
    []
  );

  const handleChangePoint = useCallback<PointChangeHandler>(
    id => setPoints(prevPoints => prevPoints.map(_point => (_point.id === id ? { ..._point, saved: false } : _point))),
    []
  );

  const handleRemovePoint = useCallback<PointRemoveHandler>(
    id => setPoints(prevPoints => prevPoints.filter(_point => _point.id !== id)),
    []
  );

  return (
    <>
      <div className="mt-5">{t(lg.taskOperator.groundControlPoints.description, { min: minimalPoints })}</div>

      {points.map(point => (
        <Point
          key={point.id}
          point={point}
          organizationId={organizationId}
          documentationRecordId={documentationRecordId}
          constructionSiteCoords={constructionSiteCoords}
          constructionObjectCoords={constructionObjectCoords}
          onSave={handleSavePoint}
          onChange={handleChangePoint}
          onRemove={handleRemovePoint}
        />
      ))}

      <div className="flex justify-center">
        <Button size={"middle"} type={"dashed"} className="mt-10" icon={<PlusOutlined />} onClick={handleClickAdd}>
          {t(lg.taskOperator.groundControlPoints.buttonAddAnother)}
        </Button>
      </div>
    </>
  );
};
