import { ComponentPropsWithoutRef, useCallback, useMemo, useState } from "react";
import { lg } from "assets/translations";
import { Button, Input, message, Spin } from "antd";
import { CustomIcon } from "components/icons/CustomIcon";
import { Section } from "components/Section";
import { resolveError, useInquirySendMutation, useInquiryTopicsQuery } from "api";
import { useTranslation } from "react-i18next";
import { TopicsGroup } from "components/InquiryForm/TopicsGroup";
import { useAlert } from "hooks";
import { getLocaleInquiryTopic } from "helpers";

type TopicsGroupProps = ComponentPropsWithoutRef<typeof TopicsGroup>;
type TextAreaProps = ComponentPropsWithoutRef<typeof Input.TextArea>;

type InquiryFormProps = {
  organizationId: string;
  onSubmitSuccess: () => void;
  className?: string;
};

export const InquiryForm = ({ organizationId, onSubmitSuccess, className }: InquiryFormProps) => {
  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const [activeTopic, setActiveTopic] = useState<Parameters<TopicsGroupProps["onChange"]>[0]>();
  const [commentValue, setCommentValue] = useState<string>();

  const { data, loading } = useInquiryTopicsQuery();
  const [send, { loading: sending }] = useInquirySendMutation();

  const localizedTopics = useMemo(
    () =>
      (data?.inquiryTopics || [])
        .flatMap(topic => (topic ? [topic] : [])) // remove null/undefined values
        .map(topic => getLocaleInquiryTopic(topic, i18n.language)), // localize topics
    [data, i18n.language]
  );

  const disabled: boolean = loading || sending;
  const disabledSubmit: boolean = disabled || !activeTopic;

  const handleCommentChange: Required<TextAreaProps>["onChange"] = useCallback(
    event => setCommentValue(event.target.value),
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      // This should not happen, but just in case.
      if (!activeTopic) {
        resolveError(new Error("No topic selected"), {});
        return;
      }

      await send({
        variables: {
          input: {
            organization: organizationId,
            comment: commentValue,
            topic: activeTopic.topicId,
            nodes: activeTopic.nodesIds
          }
        }
      });

      // reset form data
      setActiveTopic(undefined);
      message.success(t(lg.additionalServices.inquiriesForm.messages.successSend));
      onSubmitSuccess();
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [send, organizationId, commentValue, activeTopic, t, showAlert, onSubmitSuccess]);

  return (
    <Section title={t(lg.additionalServices.inquiriesForm.title)} className={className}>
      {loading || sending ? (
        <div className="flex flex-col justify-center p-10 h-48">
          <Spin />
        </div>
      ) : (
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            {loading ? (
              <Spin />
            ) : (
              <TopicsGroup topics={localizedTopics} onChange={setActiveTopic} disabled={disabled} />
            )}
          </div>

          <div className="c-grid-column">
            <div className="flex flex-col justify-end h-full">
              <div>{t(lg.additionalServices.inquiriesForm.comment.label)}</div>
              <Input.TextArea
                placeholder={t(lg.additionalServices.inquiriesForm.comment.placeholder)}
                rows={8}
                onChange={handleCommentChange}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      )}

      <Button
        className="flex items-center ml-auto mt-10"
        type={"primary"}
        size={"large"}
        icon={<CustomIcon type={"send"} />}
        disabled={disabledSubmit}
        onClick={handleSubmit}
      >
        {t(lg.additionalServices.inquiriesForm.submit)}
      </Button>
    </Section>
  );
};
