import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";
import { TriggerFragment, Validations } from "api";
import { useConfig } from "hooks";
import { enumToValues, ParseMD } from "helpers";
import { Attribute, documentationRecordAttributes, documentationRecordValidations } from "api/enums";
import { lg } from "assets/translations";

type Props = {
  triggers: Pick<TriggerFragment, "available" | "conditionErrors">[];
  className?: string;
  organizationName?: string;
};

const attributes: Attribute[] = enumToValues<Attribute>(Attribute);

export const ValidationMessages = ({ triggers, className, organizationName }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { config } = useConfig();

  const errors = useMemo(
    () => triggers.map(({ available, conditionErrors }) => (!available ? [] : conditionErrors)).flat(),
    [triggers]
  );

  const messages = useMemo(() => {
    const messages: string[] = [];

    for (const error of errors) {
      if (!error) continue;

      const { validation, attribute } = error;
      const knownAttribute: Attribute | undefined = attributes.find(a => a === attribute);

      if (validation === Validations.GroundControlPointsMinimalCount) {
        messages.push(
          t(documentationRecordValidations[validation].messageI18nKey, {
            count: config.minimalGroundControlPoints
          })
        );
        continue;
      }

      if (validation === Validations.DocumentationRecordAttributeRequired && knownAttribute) {
        messages.push(t(documentationRecordAttributes[knownAttribute].messageI18nKey));
        continue;
      }

      // If organization has not enough credits and organizationName props is provided, use custom validation message.
      const notEnoughCredit =
        validation === Validations.OrganizationHasCredits || validation === Validations.OrganizationHasCreditsNoVideo;
      if (notEnoughCredit && organizationName) {
        messages.push(
          t(lg.documentationRecordActions.customValidation.organizationHasCredits, { organization: organizationName })
        );
        continue;
      }

      messages.push(t(documentationRecordValidations[validation].messageI18nKey));
    }

    return messages.filter((message, index) => messages.indexOf(message) === index);
  }, [errors, config.minimalGroundControlPoints, t, organizationName]);

  if (!messages.length) return null;

  return (
    <Alert
      type="error"
      description={messages.map(message => (
        <div key={message} className={"text-secondary whitespace-pre-line"}>
          <ParseMD>{message}</ParseMD>
        </div>
      ))}
      className={className}
    />
  );
};
