import { forwardRef } from "react";
import clsx from "clsx";

type DiagramItemProps = { label: string; className?: string };
export const DiagramItem = forwardRef<HTMLDivElement, DiagramItemProps>(({ label, className, ...props }, ref) => (
  <div
    className={clsx(
      "inline-block text-blue-400 text-center hyphens-auto border border-blue-400 rounded px-4 py-1.5",
      className
    )}
    {...props}
    ref={ref}
  >
    {label}
  </div>
));
