import { cloneElement, PropsWithChildren, ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { resolveError, useThreeDModelDeleteMutation } from "api";
import { useAlert, usePermissions } from "hooks";
import { lg } from "assets/translations/lg";
import { useMountedState } from "react-use";

type Props = {
  id: string;
  children: ReactElement;
  onSuccess?: () => any;
  onError?: () => any;
};

export const Delete = ({ id, children, onSuccess = () => {}, onError = () => {} }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const isMounted = useMountedState();
  const { showAlert } = useAlert();
  const { canDeleteThreeDModel } = usePermissions({});

  const [deleteThreeDModel, { loading }] = useThreeDModelDeleteMutation();

  const [visible, setVisible] = useState(false);

  const open = useCallback(() => setVisible(true), []);

  const handleOk = useCallback(async () => {
    try {
      await deleteThreeDModel({ variables: { input: { threeDModel: id } } });
      window.setTimeout(onSuccess, 300);

      if (isMounted()) setVisible(false);
    } catch (e) {
      resolveError(e, {}, showAlert);
      onError();
    }
  }, [deleteThreeDModel, id, onSuccess, onError, showAlert, isMounted]);

  const handleCancel = useCallback(() => setVisible(false), []);

  if (!canDeleteThreeDModel) return null;

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center">
            <DeleteOutlined className="text-lg text-red-600" />
            <span className="ml-4">{t(lg.threeDViewer.modals.deleteThreeDModel.title)}</span>
          </div>
        }
        open={visible}
        okText={t(lg.threeDViewer.modals.deleteThreeDModel.buttonOkText)}
        cancelText={t(lg.threeDViewer.modals.deleteThreeDModel.buttonCancelText)}
        okButtonProps={{ danger: true, disabled: loading }}
        cancelButtonProps={{ disabled: loading }}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        confirmLoading={loading}
      >
        <div>{t(lg.threeDViewer.modals.deleteThreeDModel.description)}</div>

        <div className="font-medium mt-5">{t(lg.threeDViewer.modals.deleteThreeDModel.warning)}</div>
      </Modal>
    </>
  );
};
