import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import {
  DocumentationRecordOrderByEnum,
  DocumentationRecordsTableAtConstructionPhaseDetailPageQuery,
  DocumentationRecordStateFilterEnum,
  DocumentationRecordType,
  DocumentationRecordTypeFilterEnum,
  DocumentationRecordVideoType,
  DocumentationRecordVideoTypeFilterEnum,
  Maybe,
  resolveError,
  UseCaseEvent,
  useDocumentationRecordsTableAtConstructionPhaseDetailPageQuery
} from "api";
import { lg } from "assets/translations/lg";
import { Link } from "../Link";
import { routes, setRouteParams } from "routes";
import { StateIndicatorDocumentationRecord } from "components/Indicators/State/StateIndicatorDocumentationRecord";
import { useFormat } from "hooks/useFormat";
import {
  TypeIndicatorDocumentationRecord,
  TypeIndicatorDocumentationVideo,
  TypeIndicatorThreeDModel
} from "components/Indicators/Type";
import { enumToValues, getThreeDModelType, openInNewTab, TableProps, usePagination } from "helpers";
import { useAlert, useLogEvent, useTableDataStatus } from "hooks";
import { documentationRecordStateFilters, documentationRecordTypes, documentationRecordVideoTypes } from "api/enums";

type TableItem = NonNullable<
  DocumentationRecordsTableAtConstructionPhaseDetailPageQuery["constructionPhase"]
>["documentationRecords"]["edges"][0]["node"] & {
  constructionObjectName: string;
  videoType: Maybe<DocumentationRecordVideoType>;
  threeDModelId: Maybe<string>;
};
type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  name: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  constructionObjectName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectNameDesc]
  },
  createdAt: {
    ascend: [DocumentationRecordOrderByEnum.CreatedAt],
    descend: [DocumentationRecordOrderByEnum.CreatedAtDesc]
  }
};

type Props = {
  constructionPhaseId: string;
};

export const DocumentationRecordsTable = ({ constructionPhaseId }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { logEvent } = useLogEvent();
  const { formatDate } = useFormat();

  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const [orderBy, setOrderBy] = useState<DocumentationRecordOrderByEnum[]>(orderByMap.createdAt.descend);
  const [typesFilter, setTypesFilter] = useState<DocumentationRecordTypeFilterEnum[]>([]);
  const [videoTypesFilter, setVideoTypesFilter] = useState<DocumentationRecordVideoTypeFilterEnum[]>([]);
  const [statesFilter, setStatesFilter] = useState<DocumentationRecordStateFilterEnum[]>([]);

  const { data, loading } = useDocumentationRecordsTableAtConstructionPhaseDetailPageQuery({
    variables: {
      constructionPhaseId,
      skip: skip,
      orderBy,
      types: typesFilter,
      videoTypes: videoTypesFilter,
      states: statesFilter
    },
    fetchPolicy: "cache-and-network",
    skip: !constructionPhaseId,
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => setTotal(data?.constructionPhase?.documentationRecords.totalCount), [data]);

  const documentationRecords = useMemo(() => data?.constructionPhase?.documentationRecords, [data]);
  const tableData = useMemo<TableItem[] | undefined>(
    () =>
      documentationRecords?.edges?.flatMap(({ node }) =>
        (node ? [node] : []).map(item => ({
          ...item,
          constructionObjectName: item.constructionObject.name,
          videoType: item.documentationRecordVideo?.type || null,
          threeDModelId: item.threeDModel?.id || null
        }))
      ),
    [documentationRecords]
  );

  const handleViewThreeDModel = useCallback(
    async (id: string) => {
      await logEvent(UseCaseEvent.ThreeDModelView);
      openInNewTab(setRouteParams(routes.threeDViewer, { id }));
    },
    [logEvent]
  );

  const columns = useMemo<TablePropType["columns"]>(
    () => [
      {
        dataIndex: "name",
        key: "name",
        title: t(lg.constructionPhase.documentation.table.header.documentationRecord),
        render: (name, { id }) => <Link href={setRouteParams(routes.documentationRecordDetail, { id })}>{name}</Link>,
        sorter: true
      },
      {
        dataIndex: "constructionObjectName",
        key: "constructionObjectName",
        title: t(lg.constructionPhase.documentation.table.header.constructionObject),
        render: constructionObjectName => <span className="text-secondary">{constructionObjectName}</span>,
        sorter: true
      },
      {
        dataIndex: "createdAt",
        key: "createdAt",
        title: t(lg.constructionPhase.documentation.table.header.created),
        render: createdAt => (
          <span className="text-secondary">{createdAt ? formatDate(createdAt, "machine", true) : "-"}</span>
        ),
        className: "whitespace-nowrap",
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        dataIndex: "state",
        key: "state",
        title: t(lg.constructionPhase.documentation.table.header.state),
        filters: enumToValues<DocumentationRecordStateFilterEnum>(DocumentationRecordStateFilterEnum).map(state => ({
          text: t(documentationRecordStateFilters[state].nameI18nKey),
          value: state
        })),
        render: state => (state ? <StateIndicatorDocumentationRecord state={state} /> : null)
      },
      {
        dataIndex: "type",
        key: "type",
        title: t(lg.constructionPhase.documentation.table.header.documentationRecordType),
        filters: enumToValues<DocumentationRecordType>(DocumentationRecordType).map(type => ({
          text: t(documentationRecordTypes[type].nameI18nKey),
          value: type
        })),
        render: type => (type ? <TypeIndicatorDocumentationRecord type={type} /> : null)
      },
      {
        dataIndex: "videoType",
        key: "videoType",
        title: t(lg.constructionPhase.documentation.table.header.videoType),
        filters: enumToValues<DocumentationRecordVideoType>(DocumentationRecordVideoType).map(type => ({
          text: t(documentationRecordVideoTypes[type].nameI18nKey),
          value: type
        })),
        render: videoType => (videoType ? <TypeIndicatorDocumentationVideo type={videoType} /> : null)
      },
      {
        dataIndex: "threeDModel",
        key: "threeDModel",
        title: t(lg.constructionPhase.documentation.table.header.threeDModel),
        render: (threeDModel, record) =>
          threeDModel ? (
            <TypeIndicatorThreeDModel
              type={getThreeDModelType(record)}
              label={<Link onClick={() => handleViewThreeDModel(threeDModel.id)}>{t(lg.table.actions.show)}</Link>}
            />
          ) : (
            "-"
          )
      }
    ],
    [t, formatDate, handleViewThreeDModel]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setTypesFilter(filter.type || []);
      setVideoTypesFilter(filter.videoType || []);
      setStatesFilter(filter.state || []);
    },
    [setPage]
  );

  const { hasData } = useTableDataStatus(tableData?.length);

  return (
    <Table<TableItem>
      dataSource={tableData}
      columns={columns}
      rowKey={"id"}
      loading={{ delay: hasData ? 300 : 0, spinning: loading }}
      showSorterTooltip={false}
      onChange={handleOnChange}
      pagination={pagination}
      scroll={{ x: true }}
    />
  );
};
