import { useCallback, useEffect, useMemo, useState } from "react";
import { navigate } from "@reach/router";
import { useAlert, useConfig, useFormat, useMe } from "hooks";
import {
  getMediaTotalSize,
  getThreeDModelType,
  getThreeDViewerModelUrl,
  isAdministrator,
  isManager,
  isThreeDOperator
} from "helpers";
import { resolveError, useThreeDModelQuery, useThreeDModelShareMutation } from "api";
import { routes, setRouteParams } from "routes";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const useThreeDViewer = (id: string) => {
  const { formatBytes } = useFormat();
  const { i18n } = useTranslation();
  const { showAlert } = useAlert();

  const [shareMutation, { data: dataShare, loading: loadingShare }] = useThreeDModelShareMutation();

  /** DATA */
  const { data, loading: loadingThreeDModel } = useThreeDModelQuery({ variables: { id } });
  const { me, loading: loadingMe } = useMe();
  const { config, loading: loadingConfig } = useConfig();

  const loading = useMemo(
    () => loadingThreeDModel || loadingMe || loadingConfig || loadingShare,
    [loadingThreeDModel, loadingMe, loadingConfig, loadingShare]
  );

  const modelShareCode = useMemo(() => dataShare?.threeDModel?.share?.code, [dataShare]);

  const model = useMemo(() => data?.threeDModel, [data]);
  const record = useMemo(() => model?.documentationRecord, [model]);
  const phase = useMemo(() => record?.constructionPhase, [record]);
  const object = useMemo(() => record?.constructionObject, [record]);
  const site = useMemo(() => object?.constructionSite, [object]);
  const type = useMemo(() => record && getThreeDModelType(record), [record]);
  const size = useMemo(() => {
    if (!model) return "-";

    return formatBytes(
      getMediaTotalSize(
        [model.modelFile, model.textureFile, model.coordinatesFile].map(file => ({ size: file?.size || null }))
      )
    );
  }, [model, formatBytes]);

  const viewerUrl = useMemo(() => {
    if (!modelShareCode || !config.threeDViewerUrl) return;
    return getThreeDViewerModelUrl(config.threeDViewerUrl, modelShareCode, i18n.language);
  }, [i18n.language, modelShareCode, config.threeDViewerUrl]);

  /** NAVIGATION */
  const canNavigate = useMemo(() => isManager(me) || isAdministrator(me), [me]);

  // TODO: HRD003-884
  const previousRoute = useMemo(
    () => (isThreeDOperator(me) ? routes.threeDModelsCompleted : routes.threeDModels),
    [me]
  );

  const documentationRoute = useMemo(
    () => record?.id && setRouteParams(routes.documentationRecordDetail, { id: record.id }),
    [record]
  );

  const siteRoute = useMemo(() => site?.id && setRouteParams(routes.constructionSiteDetail, { id: site.id }), [site]);

  const objectRoute = useMemo(
    () =>
      site?.id &&
      object?.id &&
      setRouteParams(routes.constructionObjectDetail, { constructionSiteId: site.id, id: object.id }),
    [site, object]
  );

  const phaseRoute = useMemo(() => phase?.id && setRouteParams(routes.constructionPhase, { id: phase.id }), [phase]);

  /** STATE */
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);

  /** HANDLERS */
  const handleClickOpenDrawer = useCallback(() => setVisibleDrawer(!visibleDrawer), [visibleDrawer]);

  const handleClickCloseDrawer = useCallback(() => setVisibleDrawer(false), []);

  const handleClickBack = useCallback(() => navigate(previousRoute), [previousRoute]);

  const handleDeleteSuccess = useCallback(() => navigate(routes.todo), []);

  /* Inform about missing 3D Viewer url */
  useEffect(() => {
    if (loadingConfig || config.threeDViewerUrl) return;
    console.warn("Missing 3D Viewer url.");
  }, [loadingConfig, config.threeDViewerUrl]);

  /* load share code of the 3D model */
  useEffect(() => {
    (async function run() {
      try {
        await shareMutation({
          variables: {
            input: {
              public: false,
              threeDModel: id,
              // expire time is specified at the documentation - https://hrdlicka.atlassian.net/l/c/evKpVDci
              expiresAt: moment().add("60", "minutes").toISOString()
            }
          }
        });
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    })();
  }, [shareMutation, id, showAlert]);

  return {
    loading,
    data: {
      model,
      record,
      phase,
      object,
      site,
      type,
      size,
      viewerUrl
    },
    navigation: {
      canNavigate,
      documentationRoute,
      siteRoute,
      objectRoute,
      phaseRoute
    },
    state: {
      /**/ visibleDrawer
    },
    handlers: {
      handleClickBack,
      handleClickOpenDrawer,
      handleClickCloseDrawer,
      handleDeleteSuccess
    }
  };
};
