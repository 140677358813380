import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal, Select } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { lg } from "assets/translations";
import { CreditSlider } from "components/CreditSlider";
import { useConfig, useFormat } from "hooks";
import { useUpdateEffect } from "react-use";

export type RejectHandler = (comment?: string, reason?: string, refund?: number) => any;

enum FormFields {
  comment = "comment",
  reason = "reason",
  refund = "refund"
}

type Props = {
  visible: boolean;
  loading?: boolean;
  cost: number;
  onSubmit: RejectHandler;
  onCancel: () => any;
};

export const ModalReject = ({ visible, loading, cost, onSubmit, onCancel }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { creditCost } = useFormat();
  const { config } = useConfig();

  const [canSend, setCanSend] = useState<boolean>(false);
  const [refund, setRefund] = useState<number>(0);

  const [form] = Form.useForm();

  const handleChangeForm = useCallback(
    (changedValues: any, values: any) => {
      setCanSend(values[FormFields.reason] && values[FormFields.comment]);

      if (changedValues[FormFields.refund] !== undefined) {
        setRefund(Math.round((changedValues[FormFields.refund] * cost) / 100));
      }
    },
    [cost]
  );

  const handleOk = useCallback(() => {
    if (!canSend) return;

    onSubmit(
      form.getFieldsValue()[FormFields.comment],
      form.getFieldsValue()[FormFields.reason],
      form.getFieldsValue()[FormFields.refund]
    );
  }, [onSubmit, form, canSend]);

  const options = useMemo(() => config.documentationRecordRejectReasons, [config.documentationRecordRejectReasons]);

  const initialRejectReason = useMemo(() => options[0], [options]);
  const initialValues = useMemo(() => ({ [FormFields.reason]: initialRejectReason }), [initialRejectReason]);

  /*
   * Update form initial values
   * Initial values can be changed after language change
   * */
  useUpdateEffect(() => form.setFieldsValue(initialValues), [initialValues]);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <StopOutlined className="text-purple-600" />
          <span className="ml-2">{t(lg.documentationRecordActions.reject.modal.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.reject.modal.buttonOk)}
      cancelText={t(lg.documentationRecordActions.reject.modal.buttonCancel)}
      okButtonProps={{ type: "primary", disabled: loading || !canSend }}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
    >
      <Form
        form={form}
        name="modal_send"
        layout="vertical"
        hideRequiredMark={true}
        initialValues={initialValues}
        onValuesChange={handleChangeForm}
      >
        <Form.Item
          name={FormFields.reason}
          className="mb-5"
          label={t(lg.documentationRecordActions.reject.modal.reason.label)}
        >
          <Select>
            {options.map((option, i) => (
              <Select.Option key={`reason-option-${i}`} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name={FormFields.comment}
          required={true}
          className="mb-0"
          label={t(lg.documentationRecordActions.reject.modal.comment.label)}
        >
          <Input.TextArea rows={3} placeholder={t(lg.documentationRecordActions.reject.modal.comment.placeholder)} />
        </Form.Item>

        {cost > 0 && (
          <Form.Item
            name={FormFields.refund}
            className="mt-10 mb-0"
            label={t(lg.documentationRecordActions.reject.modal.refund.label)}
          >
            <CreditSlider />
          </Form.Item>
        )}

        {cost > 0 && (
          <div className="mt-5">
            {t(lg.documentationRecordActions.reject.modal.refund.return, {
              refund,
              total: creditCost(cost, false)
            })}
          </div>
        )}
      </Form>
    </Modal>
  );
};
