import { PropsWithChildren } from "react";
import { ChildComponentProps } from "google-map-react";

type Props = ChildComponentProps & { value: string | number };

export const GroundControlPointMarker = ({ value }: PropsWithChildren<Props>) => (
  <div
    className="relative flex items-center text-center text-white cursor-pointer bg-purple-600 print_color-adjust"
    style={{ width: 28, height: 32, top: -38, left: -14, borderRadius: 4 }}
  >
    <span className="mx-auto" style={{ fontSize: 14, lineHeight: "32px" }}>
      {value}
    </span>
    <div
      style={{ width: 8, height: 8, transform: "rotate(-45deg)", bottom: -4, left: 10 }}
      className="absolute bg-purple-600"
    />
  </div>
);
