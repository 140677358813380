import { useCallback, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Avatar, Button } from "antd";
import { Content } from "components/LayoutDashboard";
import { routes } from "routes";
import { PlusOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Section } from "components/Section";
import { Link } from "components/Link";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { BadgeInvitationStatus } from "components/BadgeInvitationStatus";
import { CustomStorageBar } from "components/CustomStorageBar";
import {
  OrganizationUser,
  SubheaderOptions,
  useConfig,
  useFormat,
  useMe,
  useOrganization,
  useOrganizationCancelInvite,
  usePermissions,
  useSubheader
} from "hooks";
import { InviteState } from "api";
import { ModalLeave } from "components/Organization/ModalLeave";
import { OrganizationInviteUser } from "components/Organization/OrganizationInviteUser";
import { OrganizationRemoveUser } from "components/Organization/OrganizationRemoveUser";
import { isManager, isOperator, isReader, isSurveyor, ParseMD } from "helpers";
import { ConstructionSitesTable } from "components/ConstructionSites/ConstructionSitesTable";
import clsx from "clsx";
import { BadgeUserExternalStatus } from "components/BadgeUserExternalStatus";

export const Organization = ({ id }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing organization ID.");

  const { t } = useTranslation();
  const { convertGibToB, pluralizeEveryMonth, formatDate, formatBytes } = useFormat();

  const { loading: loadingOrganization, data, handlers } = useOrganization(id);
  const { me, loading: loadingMe } = useMe();
  const { config, loading: loadingConfig } = useConfig();
  const { canCreateConstructionSite } = usePermissions({});

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: data.organization
        ? `${data.organization.name} ${
            data.organization.crn ? `(${t(lg.organization.header.crn)}: ${data.organization.crn})` : ""
          }`
        : "",
      breadcrumb: [
        { value: t(lg.organizations.header.title), route: routes.organizations },
        { value: data.organization?.name ?? "" }
      ]
    }),
    [data.organization, t]
  );
  useSubheader(subheaderOptions);

  const accountableManagers = useMemo(() => data.users.filter(user => isManager(user)), [data.users]);
  const accountableOperators = useMemo(() => data.users.filter(user => isOperator(user)), [data.users]);
  const accountableSurveyor = useMemo(() => data.users.filter(user => isSurveyor(user)), [data.users]);
  const readers = useMemo(() => data.users.filter(user => isReader(user)), [data.users]);

  const loading = useMemo(
    () => loadingOrganization || loadingConfig || loadingMe,
    [loadingOrganization, loadingConfig, loadingMe]
  );

  const canInviteUser = useMemo(() => {
    const iAmManager = !!accountableManagers.find(user => user.id === me.id);
    const iAmOperator = !!accountableOperators.find(user => user.id === me.id);
    return iAmManager || iAmOperator;
  }, [me.id, accountableManagers, accountableOperators]);

  return (
    <Content loading={loading} data-cy={"page-organization"}>
      {/*
       * Users
       */}
      <Section title={t(lg.organization.members.title)}>
        {data.organization && canInviteUser && (
          <div className="mt-2">
            <OrganizationInviteUser organizationId={data.organization.id} credits={data.organization.credits}>
              <Button icon={<UserOutlined />} className="block ml-auto" data-cy={"action-invite-user"}>
                {t(lg.organization.members.buttonInviteText)}
              </Button>
            </OrganizationInviteUser>
          </div>
        )}

        <div className="c-grid mt-5">
          {/* left */}
          <div className="c-grid-column">
            {/* manager*/}
            <div>
              <div className="font-medium">{t(lg.organization.members.manager.label)}</div>
              {accountableManagers.length > 0 ? (
                accountableManagers.map(user => (
                  <div className="mt-3" key={user.id}>
                    <UserAtOrganization user={user} organization={data.organization} />
                  </div>
                ))
              ) : (
                <div className="mt-3 text-gray-700">{t(lg.organization.members.noUsers)}</div>
              )}
            </div>

            {/* surveyor*/}
            <div className="mt-16">
              <div className="font-medium">{t(lg.organization.members.surveyor.label)}</div>
              <div className="mt-3">
                {accountableSurveyor.length > 0 ? (
                  accountableSurveyor.map(user => (
                    <div className="mt-3" key={user.id}>
                      <UserAtOrganization user={user} organization={data.organization} />
                    </div>
                  ))
                ) : (
                  <div className="mt-3 text-gray-700">{t(lg.organization.members.noUsers)}</div>
                )}
              </div>
            </div>
          </div>

          {/* right */}
          <div className="c-grid-column">
            {/* operator*/}
            <div>
              <div className="font-medium">{t(lg.organization.members.operator.label)}</div>
              {accountableOperators.length > 0 ? (
                accountableOperators.map(user => (
                  <div className="mt-3" key={user.id}>
                    <UserAtOrganization user={user} organization={data.organization} />
                  </div>
                ))
              ) : (
                <div className="mt-3 text-gray-700">{t(lg.organization.members.noUsers)}</div>
              )}
            </div>

            {/* reader */}
            <div className="mt-16">
              <div className="font-medium">{t(lg.organization.members.reader.label)}</div>
              {readers.length > 0 ? (
                readers.map(user => (
                  <div className="mt-3" key={user.id}>
                    <UserAtOrganization user={user} organization={data.organization} />
                  </div>
                ))
              ) : (
                <div className="mt-3 text-gray-700">{t(lg.organization.members.noUsers)}</div>
              )}
            </div>
          </div>
        </div>
      </Section>

      {/*
       * Construction Sites
       */}
      <Section title={t(lg.organization.constructions.title)} className="mt-16">
        {data.constructionSites?.totalCount === 0 ? (
          <div className="flex flex-col items-center mt-10">
            <div className="text-xs text-gray-700 text-center whitespace-pre-line">
              {t(lg.organization.constructions.table.noData)}
            </div>
            {canCreateConstructionSite && (
              <Button href={routes.constructionSiteCreate} type="dashed" icon={<PlusOutlined />} className="mt-5">
                {t(lg.organization.constructions.table.buttonAddText)}
              </Button>
            )}
          </div>
        ) : (
          <>
            <div className="flex justify-end mt-2">
              {canCreateConstructionSite && (
                <Button icon={<PlusOutlined />} href={routes.constructionSiteCreate}>
                  {t(lg.organization.constructions.table.buttonAddText)}
                </Button>
              )}
            </div>
            <ConstructionSitesTable organizationId={id} className="mt-5" />
          </>
        )}
      </Section>

      {/*
       * Storage
       */}
      {data.diskUsageStats && (
        <Section title={t(lg.organization.storage.title)} className="mt-16">
          <div className="flex justify-end mt-2">
            <Button icon={<SettingOutlined />} onClick={handlers.handleClickManageSpace}>
              {t(lg.organization.storage.buttonManageText)}
            </Button>
          </div>

          <div className="mt-5">
            <CustomStorageBar items={data.diskUsageStats.byKind} totalSpace={convertGibToB(data.storageClass.maxGb)} />

            <div className="c-grid mt-4">
              {/* left */}
              <div className="c-grid-column">
                <div className="whitespace-pre-line">
                  {t(lg.organization.storage.tariffInfo, {
                    size: formatBytes(convertGibToB(data.storageClass.maxGb)),
                    interval: pluralizeEveryMonth(config.storagePaymentIntervalMonths || 0)
                  })}
                </div>

                <div className="mt-20">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="font-medium text-left">{t(lg.organization.storage.currentPlan)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-secondary">{data.storageName}</td>
                        <td className="text-gray-700">{formatBytes(convertGibToB(data.storageClass.maxGb))}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* right */}
              <div className="c-grid-column" />
            </div>

            <div className="text-center mt-10 ">
              <ParseMD>
                {t(lg.organization.storage.nextControlInfo, {
                  date: formatDate(data.organization?.nextStoragePaymentDate, "human")
                })}
              </ParseMD>
            </div>
          </div>
        </Section>
      )}

      {/*
       * Actions
       */}
      <section className="mt-16">
        <ModalLeave organizationId={data.organization?.id}>
          <Button danger type="primary" size="large" className="block ml-auto">
            {t(lg.organization.actions.buttonLeaveOrganizationText)}
          </Button>
        </ModalLeave>
      </section>
    </Content>
  );
};

const UserAtOrganization = ({
  user,
  organization
}: {
  user: OrganizationUser;
  organization?: { id: string; credits: number } | null;
}) => {
  const { t } = useTranslation();
  const { me } = useMe();
  const { cancelInvite, loading: cancelingInvitation } = useOrganizationCancelInvite();

  const handleInviteCancel = useCallback(async () => {
    if (!user.inviteId) return;
    await cancelInvite(user.inviteId);
  }, [cancelInvite, user]);

  const showRemoveUser = useMemo(() => user.id !== me.id && user.membershipId, [user.id, user.membershipId, me.id]);
  const showCancelInvite = useMemo(
    () =>
      user.inviteId &&
      (user.inviteState === InviteState.Created ||
        user.inviteState === InviteState.Sent ||
        user.inviteState === InviteState.Expired),
    [user.inviteId, user.inviteState]
  );
  const showResentInvitation = useMemo(() => user.inviteState === InviteState.Expired, [user.inviteState]);
  const expiredInvitation = useMemo(() => user.inviteState === InviteState.Expired, [user.inviteState]);

  if (!organization) return null;

  return (
    <div className="flex items-center" data-cy={"organization-member"}>
      <Avatar size="small" className="text-purple-900 bg-purple-600 flex-shrink-0 uppercase">
        {user.firstName.charAt(0) || "?"}
      </Avatar>
      <div>
        <span className="text-secondary ml-2 whitespace-nowrap">{`${user.firstName} ${user.lastName} ${
          user.email ? `(${user.email})` : null
        }`}</span>

        {user.inviteState && <BadgeInvitationStatus state={user.inviteState} className="ml-2" />}
        {user.externalState && <BadgeUserExternalStatus state={user.externalState} className="ml-2" />}

        {isManager(me) && (
          <div className="inline-block ml-2">
            <div className="inline-flex flex-wrap -mx-1">
              {/* Remove member */}
              <div className={clsx("px-1", showRemoveUser ? "" : "hidden")}>
                <OrganizationRemoveUser user={user} organizationId={organization.id}>
                  <Link>{t(lg.organization.actions.removeUser)}</Link>
                </OrganizationRemoveUser>
              </div>

              {/* Cancel/Remove invitation*/}
              {showCancelInvite && (
                <div className="px-1">
                  <Link
                    onClick={handleInviteCancel}
                    disabled={cancelingInvitation}
                    data-cy={"action-cancel-invitation"}
                  >
                    {expiredInvitation
                      ? t(lg.organization.actions.removeUser)
                      : t(lg.organization.actions.cancelInvite)}
                  </Link>
                </div>
              )}

              {/* Re-invite user */}
              <div className={clsx("px-1", showResentInvitation ? "" : "hidden")}>
                <OrganizationInviteUser user={user} organizationId={organization.id} credits={organization.credits}>
                  <Link>{t(lg.organization.actions.inviteAgain)}</Link>
                </OrganizationInviteUser>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
