import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { ActionProps } from "components/DocumentationRecord/Actions/index";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import {
  ModalSubmitOperator,
  SubmitOperatorHandler
} from "components/DocumentationRecord/Actions/modals/ModalSubmitOperator";
import { Triggers } from "api";
import { lg } from "assets/translations";
import { documentationRecordTriggers } from "api/enums";

/** For Trigger SUBMIT_OPERATOR */
export const SubmitOperator = ({
  documentationRecordId,
  triggers,
  disabled = false,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {}
}: ActionProps) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const handleAfterTrigger = useCallback(async () => {
    await setModalVisible(false);
    await onAfterTrigger(Triggers.SubmitOperator);
  }, [onAfterTrigger]);

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.SubmitOperator,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    handleAfterTrigger
  );

  const handleClick = useCallback(() => setModalVisible(true), []);
  const handleCancel = useCallback(() => setModalVisible(false), []);
  const handleSubmit = useCallback<SubmitOperatorHandler>(comment => callTrigger(comment), [callTrigger]);

  if (!available) return null;

  return (
    <>
      <Button type="primary" onClick={handleClick} size="large" disabled={disabled || !conditions || loading}>
        {t(!loading ? documentationRecordTriggers[Triggers.SubmitOperator].buttonI18nKey : lg.saving)}
        {!loading ? <ArrowRightOutlined /> : <LoadingOutlined />}
      </Button>
      <ModalSubmitOperator visible={modalVisible} loading={loading} onSubmit={handleSubmit} onCancel={handleCancel} />
    </>
  );
};
