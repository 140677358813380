import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";
import clsx from "clsx";

export enum StateIndicatorType {
  default = "default",
  inProgress = "in-progress",
  success = "success"
}

type AutoSaveIndicatorProps = {
  isSaving?: boolean;
  saved?: boolean;
  className?: string;
};

const mapState: { [key in StateIndicatorType]: { i18nKey: string; badgeClasses: string; textClasses: string } } = {
  [StateIndicatorType.default]: {
    i18nKey: lg.autoSave.info,
    badgeClasses: "hidden",
    textClasses: "text-gray-600"
  },
  [StateIndicatorType.inProgress]: {
    i18nKey: lg.autoSave.saving,
    badgeClasses: "border border-gold-600",
    textClasses: "ml-2"
  },
  [StateIndicatorType.success]: {
    i18nKey: lg.autoSave.saved,
    badgeClasses: "bg-green-600",
    textClasses: "ml-2"
  }
};

export const AutoSaveIndicator = ({ isSaving, saved, className }: AutoSaveIndicatorProps): JSX.Element => {
  const { t } = useTranslation();

  const state = isSaving
    ? StateIndicatorType.inProgress
    : saved
      ? StateIndicatorType.success
      : StateIndicatorType.default;
  const item = mapState[state];

  return (
    <div className={clsx("inline-flex items-center", className)}>
      <div className={clsx("w-3 h-3 rounded-full flex-shrink-0 print_color-adjust", item.badgeClasses)} />
      <span className={clsx("text-secondary", item.textClasses)}>{t(item.i18nKey)}</span>
    </div>
  );
};
