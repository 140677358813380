import useStore, { createStore } from "global-hook-store";
import { ApolloError } from "apollo-client";

type AlertStore = {
  visible: boolean;
  error?: ApolloError;
  errorAt?: string;
};

type AlertReducers = {
  showAlert: (store: AlertStore, payload?: ApolloError) => AlertStore;
  hideAlert: (store: AlertStore) => AlertStore;
};

const alertStore = createStore<AlertStore, AlertReducers>(
  {
    visible: false
  },
  {
    showAlert: (store, payload) => ({
      visible: true,
      error: payload,
      errorAt: new Date().toISOString()
    }),
    hideAlert: () => ({ visible: false, error: undefined, errorAt: undefined })
  }
);

export const useAlert = () => {
  const { state, actions } = useStore(alertStore);

  return { ...state, ...actions };
};
