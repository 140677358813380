import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { Button, message, Table } from "antd";
import { PhaseCreate } from "./PhaseCreate";
import { PhaseUpdate } from "./PhaseUpdate";
import { PhaseDelete } from "./PhaseDelete";
import { ButtonTableAction } from "../ButtonTableAction";
import { useTranslation } from "react-i18next";
import { CaretDownOutlined, CaretUpOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import {
  ConstructionPhaseOrderByEnum,
  ConstructionPhasesTableQuery,
  MoveDirection,
  resolveError,
  useConstructionPhaseMoveMutation,
  useConstructionPhasesTableQuery
} from "api";
import { Link } from "components/Link";
import { routes, setRouteParams } from "routes";
import { useAlert, useTableDataStatus } from "hooks";
import { TableProps, usePagination } from "helpers";
import { SimpleLoader } from "components/SimpleLoader";
import { CustomIcon } from "components/icons/CustomIcon";

type TableItem = NonNullable<
  ConstructionPhasesTableQuery["constructionSite"]
>["constructionPhases"]["edges"][0]["node"] & {
  order: number;
  actions?: JSX.Element;
};
type TablePropType = TableProps<TableItem>;

type Props = {
  constructionSiteId: string;
  onCompleteFetch?: (totalPhases: number) => void;
  editable?: boolean;
};

type PhaseDeleteModalState = {
  visible: boolean;
  phaseId?: string;
  phaseName?: string;
};

const phaseDeleteModalStateDefault: PhaseDeleteModalState = {
  visible: false,
  phaseId: undefined,
  phaseName: undefined
};

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  order: {
    ascend: [ConstructionPhaseOrderByEnum.Order],
    descend: [ConstructionPhaseOrderByEnum.OrderDesc]
  }
};

export const PhasesTable = ({ constructionSiteId, onCompleteFetch, editable = false }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [deleteModalState, setDeleteModalState] = useState<PhaseDeleteModalState>(phaseDeleteModalStateDefault);
  const openDeleteModal = useCallback(
    (phaseId: string, phaseName: string) => setDeleteModalState({ visible: true, phaseId, phaseName }),
    []
  );
  const closeDeleteModal = useCallback(() => setDeleteModalState({ visible: false }), []);
  const resetDeleteModal = useCallback(() => setDeleteModalState(phaseDeleteModalStateDefault), []);

  const [orderBy, setOrderBy] = useState<ConstructionPhaseOrderByEnum[]>([ConstructionPhaseOrderByEnum.Order]);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });

  const { data, loading, refetch } = useConstructionPhasesTableQuery({
    variables: {
      constructionSiteId: constructionSiteId,
      skip,
      orderBy
    },
    fetchPolicy: "cache-and-network",
    skip: !constructionSiteId,
    onCompleted: data => {
      const totalCount = data.constructionSite?.constructionPhases.totalCount;
      if (typeof totalCount !== "number" || !onCompleteFetch) return;
      onCompleteFetch(totalCount);
    },
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => setTotal(data?.constructionSite?.constructionPhases.totalCount), [data]);

  const phases = useMemo(() => data?.constructionSite?.constructionPhases, [data]);
  const tableData = useMemo(() => phases?.edges?.flatMap(({ node }) => (node ? [node] : [])), [phases]);

  const [moveMutation, { loading: moving }] = useConstructionPhaseMoveMutation();

  const handlePhaseCreateSuccess = useCallback(async () => {
    message.success(t(lg.constructionSite.pageDetail.phases.table.messages.phaseCreated));
    await refetch();
  }, [t, refetch]);

  const handlePhaseUpdateSuccess = useCallback(
    () => message.success(t(lg.constructionSite.pageDetail.phases.table.messages.phaseUpdated)),
    [t]
  );
  const handlePhaseDeleteSuccess = useCallback(async () => {
    message.success(t(lg.constructionSite.pageDetail.phases.table.messages.phaseDeleted));
    resetDeleteModal();
    await refetch();
  }, [t, resetDeleteModal, refetch]);

  const isMoveDisabled = useCallback(
    (order: number, type: "up" | "down" | "first" | "last"): boolean => {
      if (!total || total === 1) return true;

      const firstMoveUp = order === 1 && ["up", "first"].includes(type);
      const lastMoveDown = order >= total && ["down", "last"].includes(type);
      return firstMoveUp || lastMoveDown;
    },
    [total]
  );

  const handleMove = useCallback(
    async (phaseId: string, direction: MoveDirection) => {
      try {
        await moveMutation({
          variables: {
            input: {
              constructionPhase: phaseId,
              direction
            }
          }
        });
        await refetch();
      } catch (e) {
        resolveError(e, undefined, showAlert);
      }
    },
    [moveMutation, showAlert, refetch]
  );

  const columns: TablePropType["columns"] = useMemo(() => {
    const result: TablePropType["columns"] = [
      {
        title: t(lg.constructionSite.pageDetail.phases.table.header.phaseOrder),
        dataIndex: "order",
        key: "order",
        className: "border-b-1",
        render: order => <span className="text-secondary">{order}.</span>,
        sorter: true,
        ellipsis: true
      },
      {
        title: t(lg.constructionSite.pageDetail.phases.table.header.phaseName),
        dataIndex: "name",
        key: "name",
        className: "border-b-1",
        render: (name, record) => <Link href={setRouteParams(routes.constructionPhase, { id: record.id })}>{name}</Link>
      },
      {
        title: t(lg.constructionSite.pageDetail.phases.table.header.documentationCreated),
        dataIndex: "createdRecords",
        key: "createdRecords",
        className: "border-b-1",
        render: count => <span className="text-secondary">{count}</span>
      },
      {
        title: t(lg.constructionSite.pageDetail.phases.table.header.documentationInProgress),
        dataIndex: "workInProgressRecords",
        key: "workInProgressRecords",
        className: "border-b-1",
        render: count => <span className="text-secondary">{count}</span>
      },
      {
        title: t(lg.constructionSite.pageDetail.phases.table.header.documentationDone),
        dataIndex: "completedRecords",
        key: "completedRecords",
        className: "border-b-1",
        render: count => <span className="text-secondary">{count}</span>
      }
    ];

    if (editable)
      result.push({
        title: t(lg.constructionSite.pageDetail.phases.table.header.actions),
        dataIndex: "id",
        key: "actions",
        className: "border-b-1",
        render: (id, phase) => (
          <div className="flex items-center">
            <ButtonTableAction
              icon={<CaretUpOutlined className="text-xl" />}
              disabled={isMoveDisabled(phase.order, "up")}
              onClick={() => handleMove(phase.id, MoveDirection.Up)}
            />
            <ButtonTableAction
              icon={<CustomIcon type="caret-up-two" />}
              disabled={isMoveDisabled(phase.order, "first")}
              onClick={() => handleMove(phase.id, MoveDirection.Top)}
            />
            <ButtonTableAction
              icon={<CustomIcon type="caret-down-two" />}
              disabled={isMoveDisabled(phase.order, "last")}
              onClick={() => handleMove(phase.id, MoveDirection.Bottom)}
            />
            <ButtonTableAction
              icon={<CaretDownOutlined className="text-xl" />}
              disabled={isMoveDisabled(phase.order, "down")}
              onClick={() => handleMove(phase.id, MoveDirection.Down)}
            />
            <PhaseUpdate id={phase.id} order={phase.order} name={phase.name} onSuccess={handlePhaseUpdateSuccess}>
              <ButtonTableAction
                icon={<EditOutlined className="text-xl" />}
                title={t(lg.constructionSite.pageDetail.phases.table.buttonPhaseEditText)}
              />
            </PhaseUpdate>
            <ButtonTableAction
              icon={<DeleteOutlined className="text-xl" />}
              title={t(lg.constructionSite.pageDetail.phases.table.buttonPhaseDeleteText)}
              disabled={total === 1}
              onClick={() => openDeleteModal(phase.id, phase.name)}
            />
          </div>
        )
      });

    return result;
  }, [t, editable, isMoveDisabled, handlePhaseUpdateSuccess, total, handleMove, openDeleteModal]);

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter, extra) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
    },
    [setPage]
  );

  const { hasData } = useTableDataStatus(tableData?.length);

  if (loading && !hasData)
    return (
      <div className="flex justify-center w-full p-10">
        <SimpleLoader />
      </div>
    );

  /* No table data */
  if (!hasData) {
    return (
      <div className="flex flex-col items-center mt-10">
        <div className="text-xs text-gray-700 text-center whitespace-pre-line">
          {t(lg.constructionSite.pageDetail.phases.table.noDataText)}
        </div>
        <PhaseCreate constructionSiteId={constructionSiteId} onSuccess={handlePhaseCreateSuccess}>
          <Button type="dashed" icon={<PlusOutlined />} className="mt-5">
            {t(lg.constructionSite.pageDetail.phases.table.buttonPhaseCreateText)}
          </Button>
        </PhaseCreate>
      </div>
    );
  }

  return (
    <div className={editable ? "mt-5" : "mt-10"}>
      {editable && (
        <>
          {/* Create Phase action */}
          <div className="flex justify-end">
            <PhaseCreate constructionSiteId={constructionSiteId} onSuccess={handlePhaseCreateSuccess}>
              <Button icon={<PlusOutlined />}>
                {t(lg.constructionSite.pageDetail.phases.table.buttonPhaseCreateText)}
              </Button>
            </PhaseCreate>
          </div>

          {/* Delete Phase action */}
          <PhaseDelete
            visible={deleteModalState.visible}
            id={deleteModalState.phaseId || ""}
            name={deleteModalState.phaseName || ""}
            onClose={closeDeleteModal}
            onSuccess={handlePhaseDeleteSuccess}
          />
        </>
      )}

      <div className="mt-5">
        <Table<TableItem>
          dataSource={tableData}
          columns={columns}
          rowKey={"id"}
          loading={{ delay: hasData ? 300 : 0 ? 0 : 300, spinning: loading || moving }}
          showSorterTooltip={false}
          onChange={handleOnChange}
          pagination={pagination}
          scroll={{ x: true }}
        />
      </div>
    </div>
  );
};
