import { lg } from "assets/translations";
import { isManager, isOperator, isSurveyor } from "helpers";
import { Section } from "components/Section";
import { MyTaskManager } from "components/About/MyTaskManager";
import { MyTaskOperator } from "components/About/MyTaskOperator";
import { MyTaskSurveyor } from "components/About/MyTaskSurveyor";
import { useMe } from "hooks";
import { useTranslation } from "react-i18next";

export const MyTasks = () => {
  const [t] = useTranslation();
  const { me } = useMe();

  return (
    <>
      <Section title={t(lg.aboutPage.tabs.myTasks.content.aboutTasks.title)}>
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="font-medium text-secondary">
              {t(lg.aboutPage.tabs.myTasks.content.aboutTasks.description)}
            </div>
          </div>
        </div>
      </Section>
      <div className="mt-16">
        {isManager(me) && <MyTaskManager />}
        {isOperator(me) && <MyTaskOperator />}
        {isSurveyor(me) && <MyTaskSurveyor />}
      </div>
    </>
  );
};
