type DecodeRelayIdReturn = {
  type: string;
  id: string;
};

export const decodeRelayId = (base64: string): DecodeRelayIdReturn | undefined => {
  try {
    const decoded = atob(base64).split(":");
    if (decoded.length !== 2) throw new Error();

    return {
      type: decoded[0],
      id: decoded[1]
    };
  } catch (e) {
    console.error("Invalid relay ID", base64);
  }
};
