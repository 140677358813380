import { PropsWithChildren } from "react";
import clsx from "clsx";
import { MediaCard } from "components/MediaCard";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";

type Item = {
  id: string;
  description: string;
  url: string;
  fileName: string;
};

type Props = {
  items?: Item[];
};

export const PhotoDocumentationList = ({ items = [] }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  if (!items.length)
    return (
      <div className="text-xs text-gray-700 text-center whitespace-pre-line">
        {t(lg.documentationRecord.pageDetail.photos.noData)}
      </div>
    );

  return (
    <>
      {items.map(({ id, description, url, fileName }, index) => (
        <div key={id} className={clsx("c-grid", index > 0 ? "mt-5" : "")}>
          {/* left */}
          <div className="c-grid-column">
            <MediaCard fileName={fileName} fileUrl={url} />
          </div>
          {/* right */}
          <div className="c-grid-column">
            <div className="font-medium">{t(lg.documentationRecord.pageDetail.photos.pointDescription.label)}</div>
            <div className="text-secondary whitespace-pre-line">{description || "-"}</div>
          </div>
        </div>
      ))}
    </>
  );
};
