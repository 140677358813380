import { PropsWithChildren, ReactNode } from "react";
import { Button } from "antd";

type Props = {
  icon: ReactNode;
  href?: string;
  title?: string;
  disabled?: boolean;
  onClick?: () => any;
};

export const ButtonTableAction = ({ icon, onClick, href, title, disabled = false }: PropsWithChildren<Props>) => {
  return (
    <Button
      href={href}
      type="link"
      size="small"
      title={title}
      disabled={disabled}
      className="text-secondary"
      onClick={onClick}
    >
      {icon}
    </Button>
  );
};
