export type I18Translation = { translation: Lg; } 

 type Lg = {
  headerLogout: string,
  footer: {
    prefix: string
  },
  login: {
    heading: string,
    buttonText: string,
    descriptionText: string,
    descriptionLinkText: string,
    messages: {
      tokenExpired: string,
      invalidCredentials: string
    },
    form: {
      username: {
        label: string,
        messages: {
          required: string
        }
      },
      password: {
        label: string,
        messages: {
          required: string
        }
      }
    },
    forgotPasswordLinkText: string
  },
  invitations: {
    pageDetail: {
      heading: string,
      role: {
        label: string
      },
      description: {
        accept: string,
        notNow: string,
        expiration: string
      },
      buttonAcceptText: string,
      buttonRejectText: string,
      buttonNotNowText: string
    },
    messages: {
      accept: string,
      reject: string
    }
  },
  purchases: {
    pageDetail: {
      heading: string,
      organization: string,
      description: {
        memberOfMoreOrganizations: string,
        noOrganization: string,
        claimToOrganization: string,
        claimToAnother: string
      },
      form: {
        claimToOrganization: {
          label: string
        }
      },
      buttonClaimText: string,
      buttonNotNowText: string
    },
    messages: {
      accept: string
    }
  },
  todo: {
    header: {
      title: string
    },
    emptySurveyor: string,
    tabs: {
      pending: string,
      forMobile: string
    },
    tabPending: {
      noData: {
        default: string,
        readOnly: string,
        extendedInstruction: string,
        buttonToConstructionSitesText: string
      }
    },
    tabForMobile: {
      description: string,
      noData: {
        default: string,
        readOnly: string,
        extendedInstruction: string
      }
    },
    table: {
      header: {
        documentationRecord: string,
        constructionSite: string,
        constructionObject: string,
        constructionPhase: string,
        pendingTime: string,
        state: string,
        description: string
      }
    },
    tableSurveyor: {
      header: {
        documentationRecord: string,
        organization: string,
        constructionSite: string,
        constructionObject: string,
        createdBefore: string,
        groundControlPoints: string,
        location: string,
        surveyFiles: string
      }
    }
  },
  todoThreeDOperator: {
    header: {
      title: string
    },
    todosInProgress: {
      title: string,
      table: {
        header: {
          documentation: string,
          threeDModelType: string,
          receivedBefore: string,
          documentationReturn: string,
          treatmentStatus: string
        },
        noData: string
      }
    },
    newTodos: {
      title: string,
      table: {
        header: {
          documentation: string,
          threeDModelType: string,
          receivedBefore: string
        },
        noData: string
      }
    }
  },
  todoTradesman: {
    pageTaskDetail: {
      photos: {
        title: string,
        description: string,
        form: {
          constructionElementPhoto: {
            label: string,
            placeholder: string
          }
        }
      }
    }
  },
  constructionSites: {
    header: {
      title: string,
      buttonPrimaryText: string
    },
    noData: {
      default: string,
      readOnly: string
    },
    table: {
      header: {
        name: string,
        objectCount: string,
        phaseCount: string,
        state: string,
        createdDocumentationRecords: string,
        unfinishedDocumentationRecords: string,
        finishedDocumentationRecords: string,
        accountableManager: string,
        createdAt: string,
        documentationRecord: string
      },
      columns: {
        documentationRecord: {
          created: string,
          inProgress: string,
          finished: string
        }
      }
    }
  },
  constructionSite: {
    pageCreate: {
      header: {
        title: string,
        buttonPrimaryText: string,
        buttonSecondaryText: string
      },
      actions: {
        statusActive: string,
        statusInactive: string
      },
      baseInformation: {
        title: string
      },
      map: {
        title: string
      },
      controlSettings: {
        title: string,
        description: string
      }
    },
    pageEdit: {
      header: {
        title: string,
        buttonPrimaryText: string,
        buttonSecondaryText: string
      },
      actions: {
        delete: string,
        statusActive: string,
        statusInactive: string
      },
      baseInformation: {
        title: string
      },
      map: {
        title: string
      },
      controlSettings: {
        title: string,
        description: string
      },
      messages: {
        objectWasDeleted: string,
        objectWasSaved: string
      },
      modals: {
        deleteConstruction: {
          title: string,
          description: string,
          warning: string,
          buttonOkText: string,
          buttonCancelText: string,
          failedData: {
            description: string,
            foundedObjectsLabel: string,
            foundedPhasesLabel: string,
            buttonOkText: string
          }
        },
        stateChange: {
          title: string,
          descriptionSetActive: string,
          descriptionSetInactive: string,
          buttonOkText: string,
          buttonCancelText: string
        },
        roleChange: {
          title: string,
          description: string,
          optionChangeForIndividualParts: {
            label: string
          },
          optionRewriteCurrentDocumentation: {
            label: string
          },
          buttonOkText: string,
          buttonCancelText: string
        }
      }
    },
    formBasicInformation: {
      name: {
        label: string,
        placeholder: string,
        validations: {
          addName: string
        }
      },
      description: {
        label: string,
        placeholder: string
      },
      manager: {
        label: string
      },
      operator: {
        label: string,
        defaultValue: string
      },
      surveyor: {
        label: string,
        defaultValue: string
      },
      tooltips: {
        preFillInTheDocumentation: {
          text: string,
          content: string
        },
        needAddNewUser: {
          text: string,
          content: string
        }
      }
    },
    formControlSettings: {
      cardCheckBeforePayment: {
        title: string,
        description: string,
        advantages: {
          title: string,
          description: string
        },
        disadvantages: {
          title: string,
          description: string
        }
      },
      cardAutomatically: {
        title: string,
        description: string,
        advantages: {
          title: string,
          description: string
        },
        disadvantages: {
          title: string,
          description: string
        }
      }
    },
    pageDetail: {
      header: {
        title: string,
        buttonPrimaryText: string
      },
      baseInformation: {
        title: string,
        constructionDescriptionLabel: string,
        authorPlaceholder: string,
        constructionManagerLabel: string,
        authorDocumentationLabel: string,
        authorGeodeticSurveyLabel: string,
        constructionStateLabel: string,
        checkControlLabel: string
      },
      summaryView: {
        title: string,
        table: {
          header: {
            partOfConstruction: string,
            phase: string
          },
          noDocumentation: string,
          buttonObjectCreateText: string,
          noDataText: string
        }
      },
      objects: {
        title: string,
        table: {
          header: {
            objectName: string,
            objectState: string,
            documentationCreated: string,
            documentationInProgress: string,
            documentationDone: string
          },
          buttonObjectCreateText: string,
          noDataText: string
        }
      },
      phases: {
        title: string,
        table: {
          header: {
            phaseOrder: string,
            phaseName: string,
            documentationCreated: string,
            documentationInProgress: string,
            documentationDone: string,
            actions: string
          },
          buttonPhaseEditText: string,
          buttonPhaseDeleteText: string,
          buttonPhaseCreateText: string,
          noDataText: string,
          messages: {
            phaseCreated: string,
            phaseUpdated: string,
            phaseDeleted: string
          }
        },
        modalUpdate: {
          titleCreate: string,
          titleUpdate: string,
          form: {
            phaseNameLabel: string,
            phaseNameDefault1: string,
            phaseNameDefault2: string,
            phaseNameDefault3: string,
            phaseNameDefault4: string,
            phaseNameCustomInputPlaceholder: string
          },
          buttonOkText: string,
          buttonCancelText: string
        },
        modalDelete: {
          title: string,
          description: string,
          warning: string,
          buttonOkText: string,
          buttonCancelText: string,
          failedData: {
            description: string,
            foundedRecords: string,
            buttonOkText: string
          }
        }
      },
      documentation: {
        title: string,
        description: string
      },
      map: {
        title: string,
        noData: string
      },
      detailInformation: {
        title: string,
        createdAtLabel: string,
        lastEditedAtLabel: string,
        createdByLabel: string,
        lastEditedByLabel: string
      }
    }
  },
  constructionObject: {
    pageCreate: {
      header: {
        title: string,
        buttonPrimaryText: string,
        buttonSecondaryText: string
      },
      actions: {
        statusActive: string,
        statusInactive: string
      },
      baseInformation: {
        title: string
      },
      map: {
        title: string
      }
    },
    pageEdit: {
      header: {
        title: string,
        buttonPrimaryText: string,
        buttonSecondaryText: string
      },
      actions: {
        buttonDeleteText: string,
        objectStatusActive: string,
        objectStatusInactive: string
      },
      baseInformation: {
        title: string
      },
      map: {
        title: string
      },
      messages: {
        objectWasDeleted: string,
        objectWasSaved: string
      },
      modals: {
        deleteObject: {
          title: string,
          description: string,
          warning: string,
          buttonOkText: string,
          buttonCancelText: string,
          failedData: {
            description: string,
            foundedRecords: string,
            buttonOkText: string
          }
        },
        roleChange: {
          title: string,
          description: string,
          buttonOkText: string,
          buttonCancelText: string
        }
      }
    },
    formBasicInformation: {
      name: {
        label: string,
        placeholder: string,
        validations: {
          addName: string
        }
      },
      description: {
        label: string,
        placeholder: string
      },
      manager: {
        label: string
      },
      operator: {
        label: string,
        defaultValue: string
      },
      surveyor: {
        label: string,
        defaultValue: string
      },
      tooltips: {
        needAddNewUser: {
          text: string,
          content: string
        }
      }
    },
    pageDetail: {
      header: {
        title: string,
        buttonPrimaryText: string
      },
      baseInformation: {
        title: string,
        blockConstructionObject: {
          title: string,
          nameLabel: string,
          descriptionLabel: string,
          stateLabel: string
        },
        blockConstructionSite: {
          title: string,
          nameLabel: string,
          descriptionLabel: string
        },
        blockResponsibility: {
          title: string,
          managerLabel: string,
          operatorLabel: string,
          surveyorLabel: string,
          noUser: string
        }
      },
      documentation: {
        title: string,
        table: {
          header: {
            documentation: string,
            phase: string,
            createdAt: string,
            state: string,
            documentationType: string,
            videoType: string,
            threeDModel: string
          },
          noVideos: string,
          noThreeDModels: string,
          buttonObjectCreateText: string,
          noDataText: string
        }
      },
      map: {
        title: string,
        markerName: string
      },
      detailInformation: {
        title: string,
        createdAtLabel: string,
        lastEditedAtLabel: string,
        createdByLabel: string,
        lastEditedByLabel: string
      }
    }
  },
  documentationRecords: {
    header: {
      title: string,
      buttonPrimaryText: string
    },
    noData: {
      default: string,
      readOnly: string,
      extendedInstruction: string,
      buttonToConstructionSitesText: string
    },
    noDataThreeDOperator: string,
    allDocumentation: {
      title: string,
      table: {
        header: {
          documentationRecord: string,
          created: string,
          state: string,
          documentationRecordType: string,
          videoType: string,
          threeDModel: string,
          constructionSite: string
        }
      }
    }
  },
  documentationRecord: {
    pageCreateType: {
      header: {
        title: string,
        buttonSecondaryText: string
      },
      documentationType: {
        title: string
      }
    },
    pageCreate: {
      header: {
        title: string,
        buttonSecondaryText: string
      },
      actions1: {
        buttonBackToTheTypeText: string
      },
      baseInformation: {
        title: string
      },
      videoType: {
        title: string
      },
      priceInfo: string,
      actions2: {
        buttonCreateAndSaveText: string,
        buttonCreateAndSendText: string
      },
      messages: {
        documentationCreatedAndSaved: string,
        documentationCreatedAndSent: string
      },
      modals: {
        sendToOperator: {
          title: string,
          description: string,
          form: {
            note: {
              label: string,
              placeholder: string
            }
          },
          buttonOkText: string,
          buttonCancelText: string
        },
        createCost: {
          title: {
            createAndSend: string,
            createAndSave: string
          },
          description: string,
          credits: {
            actualState: string,
            price: string
          },
          buttonOkText: {
            createAndSendText: string,
            createAndSaveText: string
          },
          buttonCancelText: string,
          topUpCredits: {
            label: string
          }
        }
      }
    },
    pageEditType: {
      header: {
        title: string,
        buttonSecondaryText: string
      },
      documentationType: {
        title: string
      }
    },
    pageEdit: {
      header: {
        title: string,
        buttonPrimaryText: string
      },
      actions1: {
        buttonBack: string
      },
      baseInformation: {
        title: string
      },
      videoType: {
        title: string
      },
      actions2: {
        buttonDeleteText: string,
        buttonMoveDocumentationText: string,
        buttonAbortText: string,
        buttonSaveText: string,
        buttonSaveAndSendText: string
      },
      messages: {
        documentationMoved: string,
        documentationSaved: string,
        documentationSavedAndSent: string
      },
      customValidations: {
        videoTypeRequired: string
      },
      modals: {
        move: {
          title: string,
          form: {
            construction: {
              label: string,
              defaultText: string
            },
            object: {
              label: string,
              defaultText: string,
              noObjectsInfo: string
            },
            phase: {
              label: string,
              defaultText: string
            }
          },
          buttonOkText: string,
          buttonCancelText: string
        }
      }
    },
    pageDetail: {
      header: {
        title: string,
        buttonSendToOperator: string,
        buttonPrimaryText: string
      },
      actions: {
        buttonBackText: string,
        buttonEditText: string,
        buttonTaskOperatorText: string
      },
      type: {
        title: string,
        description: {
          noThreeDNoVideo: string,
          noThreeDVideo: string,
          threeDNoScale: string,
          threeDScale: string
        }
      },
      baseInformation: {
        title: string,
        documentationDescription: {
          label: string
        },
        addedToPhase: {
          label: string
        },
        documentationState: {
          label: string
        },
        manager: {
          label: string,
          defaultValue: string
        },
        operator: {
          label: string,
          defaultValue: string
        },
        surveyor: {
          label: string,
          defaultValue: string
        }
      },
      videoType: {
        title: string,
        default: string,
        costInfo: string,
        description: {
          noScale: string,
          scale: string,
          maps: string,
          spray: string,
          qrCodes: string,
          default: string
        }
      },
      groundControlPoints: {
        noData: string
      },
      videoImport: {
        videoDocumentation: {
          noData: string,
          location: {
            label: string
          }
        },
        geodeticSurvey: {
          noData: string
        },
        protocol: {
          title: string,
          noData: string
        }
      },
      photos: {
        title: string,
        pointDescription: {
          label: string
        },
        noData: string
      },
      threeDModel: {
        title: string,
        table: {
          header: {
            type: string,
            name: string,
            construction: string,
            createdAt: string,
            fileSize: string,
            coordinatesSystemType: string,
            action: string
          },
          cell: {
            deleteText: string
          },
          noData: string
        }
      },
      comments: {
        title: string,
        role: {
          manager: string,
          operator: string,
          surveyor: string,
          threeDOperator: string
        }
      },
      details: {
        title: string,
        createdAt: {
          label: string
        },
        editedAt: {
          label: string
        },
        createdBy: {
          label: string
        },
        editedBy: {
          label: string
        }
      },
      actions2: {
        deleteButtonText: string
      },
      modals: {
        delete: {
          title: string,
          description: string,
          foundedThreeDModelsLabel: string,
          warning: string,
          buttonOkText: string,
          buttonCancelText: string
        }
      },
      messages: {
        delete: string,
        returnDocumentation: string,
        sentToCreateThreeDModel: string,
        solvedConflict: string
      },
      simplifiedGroundControlPoints: {
        titleSuffix: string,
        count: string,
        description: string,
        photos: string
      },
      outputExtensions: {
        title: string,
        body: {
          resolverComment: {
            label: string
          },
          attachments: {
            label: string
          },
          actions: {
            deleteOutputExtension: string
          },
          messages: {
            noResolved: string,
            successfullyDeleted: string
          }
        },
        modals: {
          delete: {
            title: string,
            description: string,
            warning: string,
            buttonOkText: string,
            buttonCancelText: string
          }
        }
      }
    },
    progressSteps: {
      creationOfDocumentation: string,
      getDataBySmartPhone: string,
      dataUploadBySurveyor: string,
      dataCheckByManager: string,
      creatingThreeDModel: string,
      done: string
    },
    formSelectType: {
      creditPriceInformation: string,
      cardNoTheeDModel: {
        title: string,
        description: string
      },
      cardNoTheeDModelYet: {
        title: string,
        description: string
      },
      cardNoGeoreferencedModel: {
        title: string,
        description: string
      },
      cardGeoreferencedModel: {
        title: string,
        description: string
      }
    },
    forms: {
      generalInfo: {
        name: {
          label: string
        },
        description: {
          label: string,
          placeholder: string
        },
        phase: {
          label: string
        },
        accountableManager: {
          label: string
        },
        accountableOperator: {
          label: string
        },
        accountableSurveyor: {
          label: string
        },
        validations: {
          nameRequired: string
        },
        tooltips: {
          needAddNewUser: {
            text: string,
            content: string
          }
        }
      }
    },
    pageCreateSimplified: {
      header: {
        title: string,
        buttonSecondaryText: string
      },
      sectionTitle: string,
      assignToConstructionSite: {
        label: string
      },
      assignToConstructionObject: {
        label: string
      },
      assignToConstructionPhase: {
        label: string
      },
      createConstructionSite: {
        label: string,
        placeholder: string
      },
      createConstructionObject: {
        label: string,
        placeholder: string,
        errors: {
          noPermission: string
        }
      },
      createConstructionPhase: {
        label: string,
        placeholder: string,
        errors: {
          noPermission: string
        }
      },
      buttonCreateText: string
    },
    validations: {
      notEnoughCreditsToCreate: string
    }
  },
  constructionPhase: {
    header: {
      title: string
    },
    documentation: {
      title: string,
      table: {
        header: {
          documentationRecord: string,
          constructionObject: string,
          created: string,
          state: string,
          documentationRecordType: string,
          videoType: string,
          threeDModel: string
        }
      }
    }
  },
  threeDModels: {
    header: {
      title: string
    },
    noData: string,
    table: {
      header: {
        modelName: string,
        constructionSite: string,
        constructionObject: string,
        constructionPhase: string,
        documentationName: string,
        createdAt: string
      }
    }
  },
  threeDViewer: {
    header: {
      heading: string,
      threeDModel: string,
      buttonShare: string,
      buttonSecondaryText: string,
      buttonPrimaryText: string,
      buttonDownload: string
    },
    drawer: {
      title: string,
      documentationRecord: string,
      constructionSite: string,
      constructionObject: string,
      phase: string,
      fileSize: string,
      threeDModelType: string,
      coordinatesSystem: string,
      createdDateAndTime: string,
      author: string,
      actions: {
        buttonDeleteThreeDModelText: string
      }
    },
    modals: {
      deleteThreeDModel: {
        title: string,
        description: string,
        warning: string,
        buttonOkText: string,
        buttonCancelText: string
      },
      shareThreeDModel: {
        title: string,
        options: {
          notShareModel: string,
          shareModel: string
        },
        notShareModel: {
          notice: string
        },
        shareModel: {
          timeLimit: {
            label: string,
            options: {
              unlimited: string,
              forOneHour: string,
              forOneDay: string,
              forOneWeek: string,
              forOneMonth: string
            }
          },
          sendShareForm: {
            recipients: {
              label: string,
              placeholder: string
            },
            comment: {
              label: string,
              placeholder: string
            },
            validations: {
              invalid: string,
              send: string
            }
          },
          notice: string,
          actions: {
            buttonGenerateUrl: string,
            buttonSend: string
          }
        },
        buttonOkText: string,
        messages: {
          successfullySent: string,
          successfullyUnshared: string
        }
      },
      downloadThreeDModel: {
        title: string,
        credits: {
          actualState: string,
          downloadPrice: string
        },
        notice: string,
        buttonOkText: string
      }
    }
  },
  threeDModelsCompleted: {
    header: {
      title: string
    },
    noData: string,
    table: {
      header: {
        modelName: string,
        createdAt: string,
        constructionSite: string,
        constructionObject: string,
        documentationName: string
      }
    }
  },
  organizations: {
    header: {
      title: string
    },
    memberAtOrganizations: {
      title: string,
      table: {
        header: {
          organization: string,
          actions: string
        },
        cell: {
          leaveText: string
        }
      }
    },
    messages: {
      organizationLeaved: string
    }
  },
  organization: {
    header: {
      title: string,
      crn: string
    },
    members: {
      title: string,
      buttonInviteText: string,
      noUsers: string,
      manager: {
        label: string
      },
      operator: {
        label: string
      },
      surveyor: {
        label: string
      },
      reader: {
        label: string
      }
    },
    constructions: {
      title: string,
      table: {
        buttonAddText: string,
        noData: string
      }
    },
    storage: {
      title: string,
      buttonManageText: string,
      tariffInfo: string,
      currentPlan: string,
      nextControlInfo: string
    },
    actions: {
      buttonLeaveOrganizationText: string,
      inviteAgain: string,
      removeUser: string,
      cancelInvite: string
    },
    modals: {
      inviteUser: {
        title: string,
        form: {
          email: {
            label: string,
            validations: {
              wrongFormat: string
            }
          },
          role: {
            label: string
          },
          comment: {
            label: string,
            placeholder: string
          }
        },
        credits: {
          actualState: string,
          price: string
        },
        buttonOkText: string,
        buttonCancelText: string,
        buttonCloseText: string,
        errors: {
          cantInviteThisEmail: string,
          notFound: string,
          permissionDenied: string,
          roleNotAllowed: string,
          emailInvalid: string,
          alreadyMember: string,
          roleMismatch: string,
          alreadyInvited: string,
          notEnoughCredits: string
        },
        linkBuyCredits: string,
        invitationState: {
          sent: string,
          rejected: string,
          expired: string
        }
      },
      removeUser: {
        title: string,
        description: string,
        warning: string,
        buttonOkText: string,
        buttonCancelText: string
      },
      leave: {
        title: string,
        description: {
          leaveAndDelete: string,
          leave: string,
          assignAndLeave: string
        },
        warning: string,
        warningLastManager: string,
        buttonOkText: {
          leaveAndDelete: string,
          leave: string,
          assignAndLeave: string
        },
        buttonCancelText: string
      },
      leaveAlert: {
        title: string,
        description: string,
        buttonOkText: string
      }
    },
    messages: {
      invitationSent: string,
      userRemoved: string,
      inviteCanceled: string
    }
  },
  organizationResources: {
    header: {
      title: string,
      titlePrefix: string
    },
    storage: {
      title: string,
      buttonManageText: string,
      tariffInfo: string,
      currentPlan: string,
      nextControlInfo: string
    },
    threeDModels: {
      title: string,
      table: {
        header: {
          type: string,
          construction: string,
          createdAt: string,
          fileSize: string,
          action: string
        },
        cell: {
          deleteText: string
        },
        noData: string
      }
    },
    documentationRecords: {
      title: string,
      table: {
        header: {
          name: string,
          constructionSite: string,
          createdAt: string,
          size: string,
          actions: string
        },
        cell: {
          deleteText: string
        },
        noDataText: string
      }
    }
  },
  errorBoundary: {
    title: string,
    description: string,
    buttonReload: {
      text: string
    },
    buttonRedirect: {
      text: string
    }
  },
  errorPage: {
    httpError: {
      forbidden: {
        title: string,
        description: string,
        button: {
          text: string
        }
      },
      notFound: {
        title: string,
        description: string,
        button: {
          text: string
        }
      },
      notFoundLogged: {
        title: string,
        description: string,
        button: {
          text: string
        }
      },
      internal: {
        title: string,
        description: string,
        button: {
          text: string
        }
      }
    },
    accessError: {
      otherTeam: {
        heading: string,
        descriptionText: string,
        descriptionLinkText: string
      },
      wrongRole: {
        heading: string,
        descriptionText: string,
        descriptionLinkText: string
      },
      accountLocked: {
        heading: string,
        descriptionText: string,
        descriptionLinkText: string
      },
      accountBlocked: {
        heading: string,
        descriptionText: string,
        descriptionLinkText: string
      },
      noTeamOrLicence: {
        heading: string,
        buttonText: string
      }
    }
  },
  aboutPage: {
    header: {
      title: string
    },
    tabs: {
      about: {
        title: string,
        annotation: string,
        content: string
      },
      myTasks: {
        title: string,
        content: {
          aboutTasks: {
            title: string,
            description: string
          },
          manager: {
            aboutYourRole: {
              title: string,
              description: string
            },
            tasks: {
              title: string,
              list: {
                label: string,
                items: string
              },
              description: string
            },
            authorizationForYou: {
              title: string,
              list1: {
                items: string
              },
              list2: {
                items: string
              }
            }
          },
          operator: {
            aboutYourRole: {
              title: string,
              description: string
            },
            tasks: {
              title: string,
              list: {
                label: string,
                items: string
              },
              description: string
            },
            authorizationForYou: {
              title: string,
              list1: {
                items: string
              },
              list2: {
                items: string
              }
            }
          },
          surveyor: {
            aboutYourRole: {
              title: string,
              description1: string,
              description2: string
            },
            tasks: {
              title: string,
              list: {
                label: string,
                items: string
              }
            },
            authorizationForYou: {
              title: string,
              list: {
                items: string
              }
            }
          }
        }
      },
      documentation: {
        title: string,
        about: {
          title: string,
          description1: string,
          description2: string
        },
        noModel: {
          title: string,
          description: string
        },
        yetNoModel: {
          title: string,
          description1: string,
          description2: string
        },
        modelNoScale: {
          title: string,
          description1: string,
          description2: string
        },
        modelScale: {
          title: string,
          description1: string,
          description2: string
        }
      },
      threeDModels: {
        title: string,
        about: {
          title: string,
          description: string
        },
        whatToDo: {
          title: string,
          description: string
        }
      },
      constructionSites: {
        title: string,
        about: {
          title: string,
          description1: string,
          description2: string
        },
        output: {
          title: string,
          description1: string,
          description2: string
        }
      },
      organization: {
        title: string,
        about: {
          title: string,
          description: string
        }
      },
      sample: {
        title: string,
        intro: {
          title: string,
          description: string
        },
        manager: {
          title: string,
          description1: string,
          description2: string
        },
        operator: {
          title: string,
          description1: string,
          description2: string
        },
        surveyor: {
          title: string,
          description1: string,
          description2: string
        },
        threeDModel: {
          title: string,
          description: string
        }
      }
    }
  },
  documentationRecordDetail: {
    videoUploadOverview: {
      title: string
    },
    geodeticSurveyUploadOverview: {
      geodeticSurvey: {
        title: string
      },
      geodeticSurveyAdditional: {
        title: string
      }
    }
  },
  documentationRecordActions: {
    sectionTitle: string,
    validation: {
      documentationRecordAttributeRequired: string,
      videoValidType: string,
      videoIsSet: string,
      videoIsReady: string,
      groundControlPointsMinimalCount: string,
      groundControlPointsPhotoSet: string,
      groundControlPointsPhotoReady: string,
      groundControlPointsGeolocationSet: string,
      constructionElementPhotoReady: string,
      geodeticSurveyIsSet: string,
      geodeticSurveyIsReady: string,
      threeDModelIsSet: string,
      threeDModelIsReady: string,
      organizationHasCredits: string,
      organizationHasCreditsNoVideo: string,
      outputExtensionIsReady: string
    },
    attribute: {
      accountableOperator: string,
      accountableSurveyor: string
    },
    submitOperator: {
      button: string,
      label: string,
      modal: {
        title: string,
        description: string,
        comment: {
          label: string,
          placeholder: string
        },
        buttonCancel: string,
        buttonOk: string
      },
      messages: {
        success: string
      }
    },
    backCreated: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        description: string,
        comment: {
          label: string,
          placeholder: string
        },
        buttonCancel: string,
        buttonOk: string
      },
      messages: {
        success: string
      }
    },
    submitSurveyor: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        description: string,
        form: {
          comment: {
            label: string,
            placeholder: string
          }
        },
        buttonOk: string,
        buttonCancel: string
      },
      messages: {
        success: string
      }
    },
    backOperator: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        description: string,
        reason: {
          label: string
        },
        comment: {
          label: string,
          labelRequired: string,
          placeholder: string
        },
        refund: {
          label: string,
          return: string
        },
        buttonCancel: string,
        buttonOk: string,
        buttonCancelRefund: string,
        buttonOkRefund: string
      },
      messages: {
        success: string
      }
    },
    submitThreeDOperator: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        description: string,
        organizationCredits: string,
        cost: string,
        buttonCancel: string,
        buttonOk: string
      },
      messages: {
        success: string
      },
      modalSimple: {
        title: string,
        description: string,
        buttonCancel: string,
        buttonOk: string
      },
      modalNotEnoughCredits: {
        title: string,
        description: string,
        buttonOk: string
      }
    },
    submitManagerApproval: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        description: string,
        comment: {
          label: string,
          placeholder: string
        },
        buttonCancel: string,
        buttonOk: string
      },
      messages: {
        success: string
      }
    },
    backSurveyor: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        description: string,
        reason: {
          label: string
        },
        comment: {
          label: string,
          labelRequired: string,
          placeholder: string
        },
        refund: {
          label: string,
          return: string
        },
        buttonCancel: string,
        buttonOk: string,
        buttonCancelRefund: string,
        buttonOkRefund: string
      },
      messages: {
        success: string
      }
    },
    submitFinished: {
      button: string,
      label: string,
      description: string,
      messages: {
        success: string
      }
    },
    reject: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        reason: {
          label: string
        },
        comment: {
          label: string,
          placeholder: string
        },
        refund: {
          label: string,
          return: string
        },
        buttonCancel: string,
        buttonOk: string
      },
      messages: {
        success: string
      }
    },
    changeToThreeD: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        description: string,
        organizationCredits: string,
        cost: string,
        buttonCancel: string,
        buttonOk: string
      },
      messages: {
        success: string
      }
    },
    changeNoScale: {
      button: string,
      label: string,
      description: string,
      modal: {
        title: string,
        description: string,
        buttonCancel: string,
        buttonOk: string
      },
      messages: {
        success: string
      }
    },
    customValidation: {
      organizationHasCredits: string
    }
  },
  taskGeneralInfo: {
    heading: string,
    documentation: {
      heading: string,
      name: string,
      description: string,
      phase: string,
      state: string,
      documentationType: string,
      videoType: string
    },
    object: {
      heading: string,
      name: string,
      description: string
    },
    site: {
      heading: string,
      name: string,
      description: string,
      organization: string
    },
    contacts: {
      heading: string,
      accountableManager: string,
      accountableOperator: string,
      accountableSurveyor: string
    }
  },
  taskOperator: {
    buttonBack: string,
    groundControlPoints: {
      title: string,
      description: string,
      buttonAddAnother: string,
      point: {
        title: string,
        photos: {
          description: string
        },
        buttonSave: string,
        buttonRemove: string,
        saved: string,
        unsaved: string
      }
    },
    videoUpload: {
      title: string,
      description: string,
      descriptionPoints: string,
      errorNoType: string
    },
    groundControlPointsVariantSelector: {
      description: string,
      buttonSimplifiedVariant: string,
      buttonStandardVariant: string
    },
    simplifiedGroundControlPoint: {
      title: string,
      form: {
        title: string,
        description: string,
        fields: {
          countOfPoints: {
            label: string,
            validation: string
          },
          description: {
            label: string,
            placeholder: string
          }
        },
        buttonSave: string,
        buttonCancel: string,
        messages: {
          submitSuccess: string,
          submitError: string
        }
      }
    }
  },
  taskSurveyor: {
    buttonBack: string,
    buttonPrint: string,
    buttonDownload: string,
    titlePrint: string,
    geodeticSurveyUpload: {
      title: string,
      description: string
    },
    geodeticSurveyAdditionalUpload: {
      title: string,
      description: string
    },
    alerts: {
      returned: {
        title: string
      }
    }
  },
  taskThreeDOperator: {
    buttonBack: string,
    buttonDownload: string,
    modelUpload: {
      title: string,
      fileUpload: {
        model: {
          title: string,
          description: string
        },
        texture: {
          title: string,
          description: string
        },
        coordinates: {
          title: string,
          description: string
        }
      },
      buttonViewModel: string,
      coordinateSystemSelect: {
        title: string,
        label: string
      }
    },
    threeDModelUploadOverview: {
      title: string,
      modelFile: string,
      coordinateSystemFile: string,
      textureFile: string
    },
    threeDModelInfo: {
      title: string,
      type: string,
      createdAt: string,
      size: string,
      coordinateSystemType: string
    },
    threeDModelFilesUpload: {
      title: string,
      description: string,
      buttonSave: string,
      message: {
        success: string
      }
    },
    outputExtensions: {
      title: string,
      outputExtensionNumber: {
        label: string
      },
      author: {
        label: string
      },
      attachmentsUpload: {
        title: string,
        description: string
      },
      uploadedAttachments: {
        title: string
      },
      createdAt: {
        label: string
      },
      authorComment: {
        label: string
      },
      comment: {
        label: string,
        description: string,
        formElement: {
          placeholder: string
        }
      },
      editModeToggle: {
        buttonEdit: string,
        buttonSave: string
      }
    }
  },
  additionalServices: {
    header: {
      title: string
    },
    activeInquiries: {
      title: string,
      noData: {
        default: string,
        readOnly: string
      },
      table: {
        inquiryNumber: string,
        created: string,
        state: string,
        archiving: string,
        inquiry: string,
        nodesInfo: string
      }
    },
    inquiriesForm: {
      title: string,
      option: {
        help: string,
        placeholder: string
      },
      comment: {
        label: string,
        placeholder: string
      },
      submit: string,
      messages: {
        successSend: string
      }
    }
  },
  additionalServicesReview: {
    header: {
      title: string
    },
    tabs: {
      pendingInquiries: string,
      resolvedInquiries: string
    },
    pendingInquiries: {
      noData: string,
      table: {
        inquiryNumber: string,
        additionalServiceType: string,
        requestedBy: string,
        organization: string,
        createdAt: string,
        inquiryName: string
      }
    },
    resolvedInquiries: {
      noData: string,
      table: {
        inquiryNumber: string,
        additionalServiceType: string,
        requestedBy: string,
        organization: string,
        createdAt: string,
        status: string,
        archivesAt: string,
        inquiryName: string
      }
    }
  },
  inquiry: {
    actions: {
      backButton: string
    },
    progress: {
      title: string,
      body: {
        inProgress: string,
        resolverComment: {
          label: string
        },
        attachments: {
          label: string
        }
      }
    },
    inquiryDetails: {
      title: string,
      body: {
        inquiryNumber: {
          label: string
        },
        userComment: {
          label: string
        },
        resolverName: {
          label: string
        },
        createdAt: {
          label: string
        },
        status: {
          label: string
        },
        resolvedAt: {
          label: string
        },
        archivesAt: {
          label: string,
          description: string
        },
        requestType: {
          label: string
        }
      }
    }
  },
  inquiryReview: {
    actions: {
      backButton: string,
      declineInquiry: string,
      sendInquiry: string
    },
    messages: {
      inquiryResolved: string,
      inquiryDeclined: string
    },
    inquiryDetails: {
      title: string,
      body: {
        inquiryNumber: {
          label: string
        },
        createdBy: {
          label: string
        },
        createdAt: {
          label: string
        },
        archivesAt: {
          label: string,
          description: string
        },
        userComment: {
          label: string
        },
        status: {
          label: string
        },
        organization: {
          label: string
        },
        resolvedAt: {
          label: string
        },
        requestType: {
          label: string
        }
      }
    },
    progressPending: {
      title: string,
      body: {
        attachmentUpload: {
          title: string,
          label: string
        },
        comment: {
          title: string,
          label: string,
          placeholder: string
        }
      }
    },
    progressResolved: {
      title: string,
      body: {
        resolverComment: {
          label: string
        },
        attachments: {
          label: string
        }
      }
    }
  },
  state: {
    constructionSite: {
      active: string,
      inactive: string
    },
    constructionSiteStateFilter: {
      active: string,
      inactive: string
    },
    documentationRecord: {
      created: string,
      requiresOperator: string,
      requiresSurveyor: string,
      requiresThreeDOperator: string,
      requiresManagerConfirmation: string,
      finished: string,
      rejected: string,
      archived: string
    },
    documentationRecordStateFilter: {
      created: string,
      requiresOperator: string,
      requiresSurveyor: string,
      requiresThreeDOperator: string,
      requiresManagerConfirmation: string,
      finished: string,
      rejected: string,
      archived: string
    },
    constructionObject: {
      active: string,
      inactive: string
    },
    geodeticSurveyUploaded: {
      true: string,
      false: string
    },
    documentationRecordReturned: {
      true: string,
      false: string
    },
    inquiry: {
      created: string,
      completed: string,
      rejected: string,
      archived: string
    },
    inquiryStateFilter: {
      created: string,
      completed: string,
      rejected: string,
      archived: string
    },
    constructionObjectStateFilter: {
      active: string,
      inactive: string
    },
    userExternalState: {
      blocked: string
    }
  },
  type: {
    documentationRecord: {
      noThreeDNoVideo: string,
      noThreeDVideo: string,
      threeDNoScale: string,
      threeDScale: string
    },
    documentationRecordTypeFilter: {
      noThreeDNoVideo: string,
      noThreeDVideo: string,
      threeDNoScale: string,
      threeDScale: string
    },
    documentationRecordVideo: {
      default: string,
      noScale: string,
      scale: string,
      maps: string,
      spray: string,
      qrCodes: string
    },
    documentationRecordVideoTypeFilter: {
      none: string,
      noScale: string,
      scale: string,
      maps: string,
      spray: string,
      qrCodes: string
    },
    threeDModel: {
      simple: string,
      scale: string,
      maps: string,
      spray: string,
      qrCodes: string
    }
  },
  form: {
    required: string,
    defaultValues: {
      noSelected: string
    }
  },
  groundPoint: {
    prefix: string,
    location: {
      label: string,
      copyToClipboard: string
    },
    description: {
      label: string
    }
  },
  credit: {
    singularNominative: string,
    nominativePlural: string,
    genitivePlural: string,
    zero: string,
    singularGenitive: string
  },
  from: string,
  bytes: {
    singularNominative: string,
    nominativePlural: string,
    genitivePlural: string,
    zero: string
  },
  storage: {
    usedStorage: string,
    planVariant: {
      free: string,
      paidCredits: string
    }
  },
  table: {
    search: {
      placeholder: string,
      buttonSearch: string,
      buttonReset: string
    },
    actions: {
      show: string,
      delete: string,
      share: string,
      download: string
    },
    threeDModelActions: {
      show: string,
      delete: string,
      share: string,
      download: string
    }
  },
  radioCard: {
    buttonSelectText: string,
    buttonSelectedText: string
  },
  threeDModel: {
    actions: {
      show: string,
      delete: string,
      share: string,
      download: string
    }
  },
  map: {
    noDataText: string,
    buttons: {
      startMark: string,
      cancelAllSelection: string,
      back: string,
      cancel: string,
      saveSelection: string,
      buttonDeleteMark: string
    },
    form: {
      gps: {
        label: string
      },
      groundPoint: {
        label: string,
        placeholder: string
      }
    },
    markName: string,
    markPlaceholder: string,
    validations: {
      invalidCoordinates: string,
      invalidCoordinatesCount: string
    },
    searchPlaceholder: string
  },
  modalUnsavedMap: {
    title: string,
    description: string,
    buttonOkText: string,
    buttonCancelText: string
  },
  support: {
    header: {
      title: string
    },
    contacts: {
      title: string,
      phone: {
        label: string
      },
      email: {
        label: string
      }
    }
  },
  role: {
    operator: string,
    surveyor: string,
    manager: string,
    reader: string,
    administrator: string,
    threeDOperator: string,
    observer: string
  },
  pricing: {
    header: {
      title: string,
      buttonPrimaryText: string
    },
    storage: {
      title: string,
      table: {
        header: {
          limit: string,
          price: string
        },
        limit: {
          label: string
        }
      }
    },
    modelRequest: {
      title: string,
      info1: string,
      info2: string,
      info3: string,
      noScale: string,
      scale: string,
      about3DModels: string,
      table: {
        header: {
          type: string,
          price: string
        },
        noScale: string,
        scale: string,
        maps: string,
        spray: string,
        qrCodes: string
      }
    },
    other: {
      title: string,
      table: {
        header: {
          request: string,
          price: string
        },
        createDocumentationRecord: string,
        inviteManager: string,
        downloadThreeDModel: string
      }
    },
    refundOptions: {
      title: string,
      table: {
        reject: string,
        return: string
      }
    },
    inquiry: {
      title: string,
      form: {
        message: {
          topicsLabel: string,
          placeholder: string
        },
        submit: string
      },
      message: {
        sent: string
      }
    },
    credits: {
      free: string,
      per3Months: string
    }
  },
  uploader: {
    title: string,
    description: string,
    messages: {
      uploadFailed: string,
      notAllowedFile: string
    }
  },
  alert: {
    title: string,
    description: string,
    errorCode: {
      label: string
    },
    errorTime: {
      label: string
    },
    buttonOkText: string
  },
  unsupportedBrowser: string,
  incompatibleApiVersion: {
    title: string,
    description: string,
    buttonOk: string
  },
  saving: string,
  uploadButton: {
    description: {
      video: string,
      photos: string,
      model: string,
      texture: string,
      coordinates: string,
      file: string
    },
    states: {
      inProgress: string
    }
  },
  loading: string,
  copyToClipboard: string,
  copyToClipboardSuccess: string,
  dateAndTime: {
    human: {
      date: string,
      time: string
    },
    machine: {
      date: string,
      time: string
    }
  },
  month: {
    every: {
      singularNominative: string,
      nominativePlural: string,
      genitivePlural: string,
      zero: string
    },
    unit: {
      singularNominative: string,
      nominativePlural: string,
      genitivePlural: string,
      zero: string
    }
  },
  mediaKind: {
    unassigned: string,
    documentation: string,
    threeDModel: string,
    inquiry: string,
    outputExtension: string
  },
  coordinateSystem: {
    sJtsk: string,
    hd72: string,
    dhdn3dGkZ2: string,
    dhdn3dGkZ3: string,
    dhdn3dGkZ4: string,
    dhdn3dGkZ5: string,
    wgs84Utm32n: string,
    wgs84Utm33n: string,
    wgs84Utm34n: string,
    wgs84Utm35n: string,
    dhdn31466: string,
    dhdn31467: string,
    dhdn31468: string,
    ch1903plusLv95: string,
    dhdn: string,
    etrs89polandCs20005: string,
    etrs89polandCs20006: string,
    etrs89polandCs20007: string,
    etrs89polandCs20008: string,
    etrs89utm32n: string,
    etrs89utm33n: string,
    unknown: string,
    wgs84: string,
    wgs84utm32n: string,
    wgs84utm34n: string,
    wgs84utm35n: string,
    wgs84utm36n: string,
    wgs84utm37n: string
  },
  taskName: {
    created: string,
    requiresOperator: string,
    requiresSurveyor: string,
    requiresThreeDOperator: string,
    requiresManagerConfirmation: string,
    finished: string,
    rejected: string,
    archived: string
  },
  zipDownload: {
    defaultFileName: string,
    pointsFolderName: string,
    surveyFolderName: string,
    processing: string,
    modal: {
      title: string,
      buttonOkText: string
    }
  },
  downloads: {
    video: string,
    image: string,
    file: string
  },
  lightbox: {
    title: {
      video: string,
      image: string
    },
    compatibilityInfo: {
      video: string
    }
  },
  mainMenu: {
    todo: string,
    documentationRecords: string,
    threeDModels: string,
    threeDModelsCompleted: string,
    constructionSites: string,
    organization: string,
    organizations: string,
    pricing: string,
    about: string,
    additionalServices: string,
    support: string,
    todoAll: string
  },
  conjunctions: {
    or: string
  },
  version: {
    actually: {
      label: string
    },
    update: {
      description: string,
      button: {
        text: string
      }
    }
  },
  typenames: {
    documentationRecord: string,
    constructionSite: string,
    constructionObject: string,
    submitter: string,
    documentationState: string,
    documentationType: string,
    videoType: string,
    threeDModel: string,
    constructionPhase: string
  },
  searchSelect: {
    common: {
      resultInfo: {
        singularNominative: string,
        nominativePlural: string,
        genitivePlural: string,
        zero: string
      },
      noResult: string
    },
    constructionSite: {
      placeholder: string
    },
    constructionObject: {
      placeholder: string,
      noData: string,
      noDataForManager: string
    },
    constructionPhase: {
      placeholder: string,
      noData: string
    }
  },
  todoAll: {
    header: {
      title: string
    },
    tasksOverview: {
      title: string,
      table: {
        header: {
          createdAt: string,
          processedBy: string
        }
      }
    }
  },
  header: {
    menu: {
      whyMawisPhoto: string,
      howItWorks: string,
      buy: string,
      login: string
    }
  },
  homepage: {
    welcomeBlock: {
      title: string,
      description: string,
      buttonMore: string
    },
    whyMawisPhoto: {
      title: string,
      overviewAndAvailability: {
        title: string,
        content: string
      },
      organizationAndControl: {
        title: string,
        content: string
      },
      cooperationAndCommunication: {
        title: string,
        content: string
      },
      dataAndAccuracy: {
        title: string,
        content: string
      },
      easyOperation: {
        title: string,
        content: string
      },
      fairPrice: {
        title: string,
        content: string
      }
    },
    howItWorks: {
      title: string,
      webApp: {
        title: string,
        content: string
      },
      mobileApp: {
        title: string,
        content: string
      },
      diagram: {
        titles: {
          terrain: string,
          office: string,
          outputs: string
        },
        blocks: {
          videoRecord: string,
          photoDocumentation: string,
          authorization: string,
          constructionSiteInit: string,
          documentationRecordCreate: string,
          dataInClouds: string,
          threeDModel: string,
          orthophoto: string,
          texture: string,
          points: string
        }
      }
    },
    contact: {
      title: string,
      byPhone: {
        title: string,
        description: string
      },
      byEmail: {
        title: string,
        description: string
      },
      byEshop: {
        title: string,
        linkText: string,
        description: string
      },
      byXing: {
        title: string,
        linkText: string,
        description: string
      }
    }
  },
  failedFetchAlert: {
    message: string
  },
  defaultConstructionPhaseName: string,
  next: string,
  autoSave: {
    info: string,
    saving: string,
    saved: string
  },
  errors: {
    notEnoughCredits: string
  },
  warning: string
} 

 export const lg: Lg = {
  "headerLogout": "headerLogout",
  "footer": {
    "prefix": "footer.prefix"
  },
  "login": {
    "heading": "login.heading",
    "buttonText": "login.buttonText",
    "descriptionText": "login.descriptionText",
    "descriptionLinkText": "login.descriptionLinkText",
    "messages": {
      "tokenExpired": "login.messages.tokenExpired",
      "invalidCredentials": "login.messages.invalidCredentials"
    },
    "form": {
      "username": {
        "label": "login.form.username.label",
        "messages": {
          "required": "login.form.username.messages.required"
        }
      },
      "password": {
        "label": "login.form.password.label",
        "messages": {
          "required": "login.form.password.messages.required"
        }
      }
    },
    "forgotPasswordLinkText": "login.forgotPasswordLinkText"
  },
  "invitations": {
    "pageDetail": {
      "heading": "invitations.pageDetail.heading",
      "role": {
        "label": "invitations.pageDetail.role.label"
      },
      "description": {
        "accept": "invitations.pageDetail.description.accept",
        "notNow": "invitations.pageDetail.description.notNow",
        "expiration": "invitations.pageDetail.description.expiration"
      },
      "buttonAcceptText": "invitations.pageDetail.buttonAcceptText",
      "buttonRejectText": "invitations.pageDetail.buttonRejectText",
      "buttonNotNowText": "invitations.pageDetail.buttonNotNowText"
    },
    "messages": {
      "accept": "invitations.messages.accept",
      "reject": "invitations.messages.reject"
    }
  },
  "purchases": {
    "pageDetail": {
      "heading": "purchases.pageDetail.heading",
      "organization": "purchases.pageDetail.organization",
      "description": {
        "memberOfMoreOrganizations": "purchases.pageDetail.description.memberOfMoreOrganizations",
        "noOrganization": "purchases.pageDetail.description.noOrganization",
        "claimToOrganization": "purchases.pageDetail.description.claimToOrganization",
        "claimToAnother": "purchases.pageDetail.description.claimToAnother"
      },
      "form": {
        "claimToOrganization": {
          "label": "purchases.pageDetail.form.claimToOrganization.label"
        }
      },
      "buttonClaimText": "purchases.pageDetail.buttonClaimText",
      "buttonNotNowText": "purchases.pageDetail.buttonNotNowText"
    },
    "messages": {
      "accept": "purchases.messages.accept"
    }
  },
  "todo": {
    "header": {
      "title": "todo.header.title"
    },
    "emptySurveyor": "todo.emptySurveyor",
    "tabs": {
      "pending": "todo.tabs.pending",
      "forMobile": "todo.tabs.forMobile"
    },
    "tabPending": {
      "noData": {
        "default": "todo.tabPending.noData.default",
        "readOnly": "todo.tabPending.noData.readOnly",
        "extendedInstruction": "todo.tabPending.noData.extendedInstruction",
        "buttonToConstructionSitesText": "todo.tabPending.noData.buttonToConstructionSitesText"
      }
    },
    "tabForMobile": {
      "description": "todo.tabForMobile.description",
      "noData": {
        "default": "todo.tabForMobile.noData.default",
        "readOnly": "todo.tabForMobile.noData.readOnly",
        "extendedInstruction": "todo.tabForMobile.noData.extendedInstruction"
      }
    },
    "table": {
      "header": {
        "documentationRecord": "todo.table.header.documentationRecord",
        "constructionSite": "todo.table.header.constructionSite",
        "constructionObject": "todo.table.header.constructionObject",
        "constructionPhase": "todo.table.header.constructionPhase",
        "pendingTime": "todo.table.header.pendingTime",
        "state": "todo.table.header.state",
        "description": "todo.table.header.description"
      }
    },
    "tableSurveyor": {
      "header": {
        "documentationRecord": "todo.tableSurveyor.header.documentationRecord",
        "organization": "todo.tableSurveyor.header.organization",
        "constructionSite": "todo.tableSurveyor.header.constructionSite",
        "constructionObject": "todo.tableSurveyor.header.constructionObject",
        "createdBefore": "todo.tableSurveyor.header.createdBefore",
        "groundControlPoints": "todo.tableSurveyor.header.groundControlPoints",
        "location": "todo.tableSurveyor.header.location",
        "surveyFiles": "todo.tableSurveyor.header.surveyFiles"
      }
    }
  },
  "todoThreeDOperator": {
    "header": {
      "title": "todoThreeDOperator.header.title"
    },
    "todosInProgress": {
      "title": "todoThreeDOperator.todosInProgress.title",
      "table": {
        "header": {
          "documentation": "todoThreeDOperator.todosInProgress.table.header.documentation",
          "threeDModelType": "todoThreeDOperator.todosInProgress.table.header.threeDModelType",
          "receivedBefore": "todoThreeDOperator.todosInProgress.table.header.receivedBefore",
          "documentationReturn": "todoThreeDOperator.todosInProgress.table.header.documentationReturn",
          "treatmentStatus": "todoThreeDOperator.todosInProgress.table.header.treatmentStatus"
        },
        "noData": "todoThreeDOperator.todosInProgress.table.noData"
      }
    },
    "newTodos": {
      "title": "todoThreeDOperator.newTodos.title",
      "table": {
        "header": {
          "documentation": "todoThreeDOperator.newTodos.table.header.documentation",
          "threeDModelType": "todoThreeDOperator.newTodos.table.header.threeDModelType",
          "receivedBefore": "todoThreeDOperator.newTodos.table.header.receivedBefore"
        },
        "noData": "todoThreeDOperator.newTodos.table.noData"
      }
    }
  },
  "todoTradesman": {
    "pageTaskDetail": {
      "photos": {
        "title": "todoTradesman.pageTaskDetail.photos.title",
        "description": "todoTradesman.pageTaskDetail.photos.description",
        "form": {
          "constructionElementPhoto": {
            "label": "todoTradesman.pageTaskDetail.photos.form.constructionElementPhoto.label",
            "placeholder": "todoTradesman.pageTaskDetail.photos.form.constructionElementPhoto.placeholder"
          }
        }
      }
    }
  },
  "constructionSites": {
    "header": {
      "title": "constructionSites.header.title",
      "buttonPrimaryText": "constructionSites.header.buttonPrimaryText"
    },
    "noData": {
      "default": "constructionSites.noData.default",
      "readOnly": "constructionSites.noData.readOnly"
    },
    "table": {
      "header": {
        "name": "constructionSites.table.header.name",
        "objectCount": "constructionSites.table.header.objectCount",
        "phaseCount": "constructionSites.table.header.phaseCount",
        "state": "constructionSites.table.header.state",
        "createdDocumentationRecords": "constructionSites.table.header.createdDocumentationRecords",
        "unfinishedDocumentationRecords": "constructionSites.table.header.unfinishedDocumentationRecords",
        "finishedDocumentationRecords": "constructionSites.table.header.finishedDocumentationRecords",
        "accountableManager": "constructionSites.table.header.accountableManager",
        "createdAt": "constructionSites.table.header.createdAt",
        "documentationRecord": "constructionSites.table.header.documentationRecord"
      },
      "columns": {
        "documentationRecord": {
          "created": "constructionSites.table.columns.documentationRecord.created",
          "inProgress": "constructionSites.table.columns.documentationRecord.inProgress",
          "finished": "constructionSites.table.columns.documentationRecord.finished"
        }
      }
    }
  },
  "constructionSite": {
    "pageCreate": {
      "header": {
        "title": "constructionSite.pageCreate.header.title",
        "buttonPrimaryText": "constructionSite.pageCreate.header.buttonPrimaryText",
        "buttonSecondaryText": "constructionSite.pageCreate.header.buttonSecondaryText"
      },
      "actions": {
        "statusActive": "constructionSite.pageCreate.actions.statusActive",
        "statusInactive": "constructionSite.pageCreate.actions.statusInactive"
      },
      "baseInformation": {
        "title": "constructionSite.pageCreate.baseInformation.title"
      },
      "map": {
        "title": "constructionSite.pageCreate.map.title"
      },
      "controlSettings": {
        "title": "constructionSite.pageCreate.controlSettings.title",
        "description": "constructionSite.pageCreate.controlSettings.description"
      }
    },
    "pageEdit": {
      "header": {
        "title": "constructionSite.pageEdit.header.title",
        "buttonPrimaryText": "constructionSite.pageEdit.header.buttonPrimaryText",
        "buttonSecondaryText": "constructionSite.pageEdit.header.buttonSecondaryText"
      },
      "actions": {
        "delete": "constructionSite.pageEdit.actions.delete",
        "statusActive": "constructionSite.pageEdit.actions.statusActive",
        "statusInactive": "constructionSite.pageEdit.actions.statusInactive"
      },
      "baseInformation": {
        "title": "constructionSite.pageEdit.baseInformation.title"
      },
      "map": {
        "title": "constructionSite.pageEdit.map.title"
      },
      "controlSettings": {
        "title": "constructionSite.pageEdit.controlSettings.title",
        "description": "constructionSite.pageEdit.controlSettings.description"
      },
      "messages": {
        "objectWasDeleted": "constructionSite.pageEdit.messages.objectWasDeleted",
        "objectWasSaved": "constructionSite.pageEdit.messages.objectWasSaved"
      },
      "modals": {
        "deleteConstruction": {
          "title": "constructionSite.pageEdit.modals.deleteConstruction.title",
          "description": "constructionSite.pageEdit.modals.deleteConstruction.description",
          "warning": "constructionSite.pageEdit.modals.deleteConstruction.warning",
          "buttonOkText": "constructionSite.pageEdit.modals.deleteConstruction.buttonOkText",
          "buttonCancelText": "constructionSite.pageEdit.modals.deleteConstruction.buttonCancelText",
          "failedData": {
            "description": "constructionSite.pageEdit.modals.deleteConstruction.failedData.description",
            "foundedObjectsLabel": "constructionSite.pageEdit.modals.deleteConstruction.failedData.foundedObjectsLabel",
            "foundedPhasesLabel": "constructionSite.pageEdit.modals.deleteConstruction.failedData.foundedPhasesLabel",
            "buttonOkText": "constructionSite.pageEdit.modals.deleteConstruction.failedData.buttonOkText"
          }
        },
        "stateChange": {
          "title": "constructionSite.pageEdit.modals.stateChange.title",
          "descriptionSetActive": "constructionSite.pageEdit.modals.stateChange.descriptionSetActive",
          "descriptionSetInactive": "constructionSite.pageEdit.modals.stateChange.descriptionSetInactive",
          "buttonOkText": "constructionSite.pageEdit.modals.stateChange.buttonOkText",
          "buttonCancelText": "constructionSite.pageEdit.modals.stateChange.buttonCancelText"
        },
        "roleChange": {
          "title": "constructionSite.pageEdit.modals.roleChange.title",
          "description": "constructionSite.pageEdit.modals.roleChange.description",
          "optionChangeForIndividualParts": {
            "label": "constructionSite.pageEdit.modals.roleChange.optionChangeForIndividualParts.label"
          },
          "optionRewriteCurrentDocumentation": {
            "label": "constructionSite.pageEdit.modals.roleChange.optionRewriteCurrentDocumentation.label"
          },
          "buttonOkText": "constructionSite.pageEdit.modals.roleChange.buttonOkText",
          "buttonCancelText": "constructionSite.pageEdit.modals.roleChange.buttonCancelText"
        }
      }
    },
    "formBasicInformation": {
      "name": {
        "label": "constructionSite.formBasicInformation.name.label",
        "placeholder": "constructionSite.formBasicInformation.name.placeholder",
        "validations": {
          "addName": "constructionSite.formBasicInformation.name.validations.addName"
        }
      },
      "description": {
        "label": "constructionSite.formBasicInformation.description.label",
        "placeholder": "constructionSite.formBasicInformation.description.placeholder"
      },
      "manager": {
        "label": "constructionSite.formBasicInformation.manager.label"
      },
      "operator": {
        "label": "constructionSite.formBasicInformation.operator.label",
        "defaultValue": "constructionSite.formBasicInformation.operator.defaultValue"
      },
      "surveyor": {
        "label": "constructionSite.formBasicInformation.surveyor.label",
        "defaultValue": "constructionSite.formBasicInformation.surveyor.defaultValue"
      },
      "tooltips": {
        "preFillInTheDocumentation": {
          "text": "constructionSite.formBasicInformation.tooltips.preFillInTheDocumentation.text",
          "content": "constructionSite.formBasicInformation.tooltips.preFillInTheDocumentation.content"
        },
        "needAddNewUser": {
          "text": "constructionSite.formBasicInformation.tooltips.needAddNewUser.text",
          "content": "constructionSite.formBasicInformation.tooltips.needAddNewUser.content"
        }
      }
    },
    "formControlSettings": {
      "cardCheckBeforePayment": {
        "title": "constructionSite.formControlSettings.cardCheckBeforePayment.title",
        "description": "constructionSite.formControlSettings.cardCheckBeforePayment.description",
        "advantages": {
          "title": "constructionSite.formControlSettings.cardCheckBeforePayment.advantages.title",
          "description": "constructionSite.formControlSettings.cardCheckBeforePayment.advantages.description"
        },
        "disadvantages": {
          "title": "constructionSite.formControlSettings.cardCheckBeforePayment.disadvantages.title",
          "description": "constructionSite.formControlSettings.cardCheckBeforePayment.disadvantages.description"
        }
      },
      "cardAutomatically": {
        "title": "constructionSite.formControlSettings.cardAutomatically.title",
        "description": "constructionSite.formControlSettings.cardAutomatically.description",
        "advantages": {
          "title": "constructionSite.formControlSettings.cardAutomatically.advantages.title",
          "description": "constructionSite.formControlSettings.cardAutomatically.advantages.description"
        },
        "disadvantages": {
          "title": "constructionSite.formControlSettings.cardAutomatically.disadvantages.title",
          "description": "constructionSite.formControlSettings.cardAutomatically.disadvantages.description"
        }
      }
    },
    "pageDetail": {
      "header": {
        "title": "constructionSite.pageDetail.header.title",
        "buttonPrimaryText": "constructionSite.pageDetail.header.buttonPrimaryText"
      },
      "baseInformation": {
        "title": "constructionSite.pageDetail.baseInformation.title",
        "constructionDescriptionLabel": "constructionSite.pageDetail.baseInformation.constructionDescriptionLabel",
        "authorPlaceholder": "constructionSite.pageDetail.baseInformation.authorPlaceholder",
        "constructionManagerLabel": "constructionSite.pageDetail.baseInformation.constructionManagerLabel",
        "authorDocumentationLabel": "constructionSite.pageDetail.baseInformation.authorDocumentationLabel",
        "authorGeodeticSurveyLabel": "constructionSite.pageDetail.baseInformation.authorGeodeticSurveyLabel",
        "constructionStateLabel": "constructionSite.pageDetail.baseInformation.constructionStateLabel",
        "checkControlLabel": "constructionSite.pageDetail.baseInformation.checkControlLabel"
      },
      "summaryView": {
        "title": "constructionSite.pageDetail.summaryView.title",
        "table": {
          "header": {
            "partOfConstruction": "constructionSite.pageDetail.summaryView.table.header.partOfConstruction",
            "phase": "constructionSite.pageDetail.summaryView.table.header.phase"
          },
          "noDocumentation": "constructionSite.pageDetail.summaryView.table.noDocumentation",
          "buttonObjectCreateText": "constructionSite.pageDetail.summaryView.table.buttonObjectCreateText",
          "noDataText": "constructionSite.pageDetail.summaryView.table.noDataText"
        }
      },
      "objects": {
        "title": "constructionSite.pageDetail.objects.title",
        "table": {
          "header": {
            "objectName": "constructionSite.pageDetail.objects.table.header.objectName",
            "objectState": "constructionSite.pageDetail.objects.table.header.objectState",
            "documentationCreated": "constructionSite.pageDetail.objects.table.header.documentationCreated",
            "documentationInProgress": "constructionSite.pageDetail.objects.table.header.documentationInProgress",
            "documentationDone": "constructionSite.pageDetail.objects.table.header.documentationDone"
          },
          "buttonObjectCreateText": "constructionSite.pageDetail.objects.table.buttonObjectCreateText",
          "noDataText": "constructionSite.pageDetail.objects.table.noDataText"
        }
      },
      "phases": {
        "title": "constructionSite.pageDetail.phases.title",
        "table": {
          "header": {
            "phaseOrder": "constructionSite.pageDetail.phases.table.header.phaseOrder",
            "phaseName": "constructionSite.pageDetail.phases.table.header.phaseName",
            "documentationCreated": "constructionSite.pageDetail.phases.table.header.documentationCreated",
            "documentationInProgress": "constructionSite.pageDetail.phases.table.header.documentationInProgress",
            "documentationDone": "constructionSite.pageDetail.phases.table.header.documentationDone",
            "actions": "constructionSite.pageDetail.phases.table.header.actions"
          },
          "buttonPhaseEditText": "constructionSite.pageDetail.phases.table.buttonPhaseEditText",
          "buttonPhaseDeleteText": "constructionSite.pageDetail.phases.table.buttonPhaseDeleteText",
          "buttonPhaseCreateText": "constructionSite.pageDetail.phases.table.buttonPhaseCreateText",
          "noDataText": "constructionSite.pageDetail.phases.table.noDataText",
          "messages": {
            "phaseCreated": "constructionSite.pageDetail.phases.table.messages.phaseCreated",
            "phaseUpdated": "constructionSite.pageDetail.phases.table.messages.phaseUpdated",
            "phaseDeleted": "constructionSite.pageDetail.phases.table.messages.phaseDeleted"
          }
        },
        "modalUpdate": {
          "titleCreate": "constructionSite.pageDetail.phases.modalUpdate.titleCreate",
          "titleUpdate": "constructionSite.pageDetail.phases.modalUpdate.titleUpdate",
          "form": {
            "phaseNameLabel": "constructionSite.pageDetail.phases.modalUpdate.form.phaseNameLabel",
            "phaseNameDefault1": "constructionSite.pageDetail.phases.modalUpdate.form.phaseNameDefault1",
            "phaseNameDefault2": "constructionSite.pageDetail.phases.modalUpdate.form.phaseNameDefault2",
            "phaseNameDefault3": "constructionSite.pageDetail.phases.modalUpdate.form.phaseNameDefault3",
            "phaseNameDefault4": "constructionSite.pageDetail.phases.modalUpdate.form.phaseNameDefault4",
            "phaseNameCustomInputPlaceholder": "constructionSite.pageDetail.phases.modalUpdate.form.phaseNameCustomInputPlaceholder"
          },
          "buttonOkText": "constructionSite.pageDetail.phases.modalUpdate.buttonOkText",
          "buttonCancelText": "constructionSite.pageDetail.phases.modalUpdate.buttonCancelText"
        },
        "modalDelete": {
          "title": "constructionSite.pageDetail.phases.modalDelete.title",
          "description": "constructionSite.pageDetail.phases.modalDelete.description",
          "warning": "constructionSite.pageDetail.phases.modalDelete.warning",
          "buttonOkText": "constructionSite.pageDetail.phases.modalDelete.buttonOkText",
          "buttonCancelText": "constructionSite.pageDetail.phases.modalDelete.buttonCancelText",
          "failedData": {
            "description": "constructionSite.pageDetail.phases.modalDelete.failedData.description",
            "foundedRecords": "constructionSite.pageDetail.phases.modalDelete.failedData.foundedRecords",
            "buttonOkText": "constructionSite.pageDetail.phases.modalDelete.failedData.buttonOkText"
          }
        }
      },
      "documentation": {
        "title": "constructionSite.pageDetail.documentation.title",
        "description": "constructionSite.pageDetail.documentation.description"
      },
      "map": {
        "title": "constructionSite.pageDetail.map.title",
        "noData": "constructionSite.pageDetail.map.noData"
      },
      "detailInformation": {
        "title": "constructionSite.pageDetail.detailInformation.title",
        "createdAtLabel": "constructionSite.pageDetail.detailInformation.createdAtLabel",
        "lastEditedAtLabel": "constructionSite.pageDetail.detailInformation.lastEditedAtLabel",
        "createdByLabel": "constructionSite.pageDetail.detailInformation.createdByLabel",
        "lastEditedByLabel": "constructionSite.pageDetail.detailInformation.lastEditedByLabel"
      }
    }
  },
  "constructionObject": {
    "pageCreate": {
      "header": {
        "title": "constructionObject.pageCreate.header.title",
        "buttonPrimaryText": "constructionObject.pageCreate.header.buttonPrimaryText",
        "buttonSecondaryText": "constructionObject.pageCreate.header.buttonSecondaryText"
      },
      "actions": {
        "statusActive": "constructionObject.pageCreate.actions.statusActive",
        "statusInactive": "constructionObject.pageCreate.actions.statusInactive"
      },
      "baseInformation": {
        "title": "constructionObject.pageCreate.baseInformation.title"
      },
      "map": {
        "title": "constructionObject.pageCreate.map.title"
      }
    },
    "pageEdit": {
      "header": {
        "title": "constructionObject.pageEdit.header.title",
        "buttonPrimaryText": "constructionObject.pageEdit.header.buttonPrimaryText",
        "buttonSecondaryText": "constructionObject.pageEdit.header.buttonSecondaryText"
      },
      "actions": {
        "buttonDeleteText": "constructionObject.pageEdit.actions.buttonDeleteText",
        "objectStatusActive": "constructionObject.pageEdit.actions.objectStatusActive",
        "objectStatusInactive": "constructionObject.pageEdit.actions.objectStatusInactive"
      },
      "baseInformation": {
        "title": "constructionObject.pageEdit.baseInformation.title"
      },
      "map": {
        "title": "constructionObject.pageEdit.map.title"
      },
      "messages": {
        "objectWasDeleted": "constructionObject.pageEdit.messages.objectWasDeleted",
        "objectWasSaved": "constructionObject.pageEdit.messages.objectWasSaved"
      },
      "modals": {
        "deleteObject": {
          "title": "constructionObject.pageEdit.modals.deleteObject.title",
          "description": "constructionObject.pageEdit.modals.deleteObject.description",
          "warning": "constructionObject.pageEdit.modals.deleteObject.warning",
          "buttonOkText": "constructionObject.pageEdit.modals.deleteObject.buttonOkText",
          "buttonCancelText": "constructionObject.pageEdit.modals.deleteObject.buttonCancelText",
          "failedData": {
            "description": "constructionObject.pageEdit.modals.deleteObject.failedData.description",
            "foundedRecords": "constructionObject.pageEdit.modals.deleteObject.failedData.foundedRecords",
            "buttonOkText": "constructionObject.pageEdit.modals.deleteObject.failedData.buttonOkText"
          }
        },
        "roleChange": {
          "title": "constructionObject.pageEdit.modals.roleChange.title",
          "description": "constructionObject.pageEdit.modals.roleChange.description",
          "buttonOkText": "constructionObject.pageEdit.modals.roleChange.buttonOkText",
          "buttonCancelText": "constructionObject.pageEdit.modals.roleChange.buttonCancelText"
        }
      }
    },
    "formBasicInformation": {
      "name": {
        "label": "constructionObject.formBasicInformation.name.label",
        "placeholder": "constructionObject.formBasicInformation.name.placeholder",
        "validations": {
          "addName": "constructionObject.formBasicInformation.name.validations.addName"
        }
      },
      "description": {
        "label": "constructionObject.formBasicInformation.description.label",
        "placeholder": "constructionObject.formBasicInformation.description.placeholder"
      },
      "manager": {
        "label": "constructionObject.formBasicInformation.manager.label"
      },
      "operator": {
        "label": "constructionObject.formBasicInformation.operator.label",
        "defaultValue": "constructionObject.formBasicInformation.operator.defaultValue"
      },
      "surveyor": {
        "label": "constructionObject.formBasicInformation.surveyor.label",
        "defaultValue": "constructionObject.formBasicInformation.surveyor.defaultValue"
      },
      "tooltips": {
        "needAddNewUser": {
          "text": "constructionObject.formBasicInformation.tooltips.needAddNewUser.text",
          "content": "constructionObject.formBasicInformation.tooltips.needAddNewUser.content"
        }
      }
    },
    "pageDetail": {
      "header": {
        "title": "constructionObject.pageDetail.header.title",
        "buttonPrimaryText": "constructionObject.pageDetail.header.buttonPrimaryText"
      },
      "baseInformation": {
        "title": "constructionObject.pageDetail.baseInformation.title",
        "blockConstructionObject": {
          "title": "constructionObject.pageDetail.baseInformation.blockConstructionObject.title",
          "nameLabel": "constructionObject.pageDetail.baseInformation.blockConstructionObject.nameLabel",
          "descriptionLabel": "constructionObject.pageDetail.baseInformation.blockConstructionObject.descriptionLabel",
          "stateLabel": "constructionObject.pageDetail.baseInformation.blockConstructionObject.stateLabel"
        },
        "blockConstructionSite": {
          "title": "constructionObject.pageDetail.baseInformation.blockConstructionSite.title",
          "nameLabel": "constructionObject.pageDetail.baseInformation.blockConstructionSite.nameLabel",
          "descriptionLabel": "constructionObject.pageDetail.baseInformation.blockConstructionSite.descriptionLabel"
        },
        "blockResponsibility": {
          "title": "constructionObject.pageDetail.baseInformation.blockResponsibility.title",
          "managerLabel": "constructionObject.pageDetail.baseInformation.blockResponsibility.managerLabel",
          "operatorLabel": "constructionObject.pageDetail.baseInformation.blockResponsibility.operatorLabel",
          "surveyorLabel": "constructionObject.pageDetail.baseInformation.blockResponsibility.surveyorLabel",
          "noUser": "constructionObject.pageDetail.baseInformation.blockResponsibility.noUser"
        }
      },
      "documentation": {
        "title": "constructionObject.pageDetail.documentation.title",
        "table": {
          "header": {
            "documentation": "constructionObject.pageDetail.documentation.table.header.documentation",
            "phase": "constructionObject.pageDetail.documentation.table.header.phase",
            "createdAt": "constructionObject.pageDetail.documentation.table.header.createdAt",
            "state": "constructionObject.pageDetail.documentation.table.header.state",
            "documentationType": "constructionObject.pageDetail.documentation.table.header.documentationType",
            "videoType": "constructionObject.pageDetail.documentation.table.header.videoType",
            "threeDModel": "constructionObject.pageDetail.documentation.table.header.threeDModel"
          },
          "noVideos": "constructionObject.pageDetail.documentation.table.noVideos",
          "noThreeDModels": "constructionObject.pageDetail.documentation.table.noThreeDModels",
          "buttonObjectCreateText": "constructionObject.pageDetail.documentation.table.buttonObjectCreateText",
          "noDataText": "constructionObject.pageDetail.documentation.table.noDataText"
        }
      },
      "map": {
        "title": "constructionObject.pageDetail.map.title",
        "markerName": "constructionObject.pageDetail.map.markerName"
      },
      "detailInformation": {
        "title": "constructionObject.pageDetail.detailInformation.title",
        "createdAtLabel": "constructionObject.pageDetail.detailInformation.createdAtLabel",
        "lastEditedAtLabel": "constructionObject.pageDetail.detailInformation.lastEditedAtLabel",
        "createdByLabel": "constructionObject.pageDetail.detailInformation.createdByLabel",
        "lastEditedByLabel": "constructionObject.pageDetail.detailInformation.lastEditedByLabel"
      }
    }
  },
  "documentationRecords": {
    "header": {
      "title": "documentationRecords.header.title",
      "buttonPrimaryText": "documentationRecords.header.buttonPrimaryText"
    },
    "noData": {
      "default": "documentationRecords.noData.default",
      "readOnly": "documentationRecords.noData.readOnly",
      "extendedInstruction": "documentationRecords.noData.extendedInstruction",
      "buttonToConstructionSitesText": "documentationRecords.noData.buttonToConstructionSitesText"
    },
    "noDataThreeDOperator": "documentationRecords.noDataThreeDOperator",
    "allDocumentation": {
      "title": "documentationRecords.allDocumentation.title",
      "table": {
        "header": {
          "documentationRecord": "documentationRecords.allDocumentation.table.header.documentationRecord",
          "created": "documentationRecords.allDocumentation.table.header.created",
          "state": "documentationRecords.allDocumentation.table.header.state",
          "documentationRecordType": "documentationRecords.allDocumentation.table.header.documentationRecordType",
          "videoType": "documentationRecords.allDocumentation.table.header.videoType",
          "threeDModel": "documentationRecords.allDocumentation.table.header.threeDModel",
          "constructionSite": "documentationRecords.allDocumentation.table.header.constructionSite"
        }
      }
    }
  },
  "documentationRecord": {
    "pageCreateType": {
      "header": {
        "title": "documentationRecord.pageCreateType.header.title",
        "buttonSecondaryText": "documentationRecord.pageCreateType.header.buttonSecondaryText"
      },
      "documentationType": {
        "title": "documentationRecord.pageCreateType.documentationType.title"
      }
    },
    "pageCreate": {
      "header": {
        "title": "documentationRecord.pageCreate.header.title",
        "buttonSecondaryText": "documentationRecord.pageCreate.header.buttonSecondaryText"
      },
      "actions1": {
        "buttonBackToTheTypeText": "documentationRecord.pageCreate.actions1.buttonBackToTheTypeText"
      },
      "baseInformation": {
        "title": "documentationRecord.pageCreate.baseInformation.title"
      },
      "videoType": {
        "title": "documentationRecord.pageCreate.videoType.title"
      },
      "priceInfo": "documentationRecord.pageCreate.priceInfo",
      "actions2": {
        "buttonCreateAndSaveText": "documentationRecord.pageCreate.actions2.buttonCreateAndSaveText",
        "buttonCreateAndSendText": "documentationRecord.pageCreate.actions2.buttonCreateAndSendText"
      },
      "messages": {
        "documentationCreatedAndSaved": "documentationRecord.pageCreate.messages.documentationCreatedAndSaved",
        "documentationCreatedAndSent": "documentationRecord.pageCreate.messages.documentationCreatedAndSent"
      },
      "modals": {
        "sendToOperator": {
          "title": "documentationRecord.pageCreate.modals.sendToOperator.title",
          "description": "documentationRecord.pageCreate.modals.sendToOperator.description",
          "form": {
            "note": {
              "label": "documentationRecord.pageCreate.modals.sendToOperator.form.note.label",
              "placeholder": "documentationRecord.pageCreate.modals.sendToOperator.form.note.placeholder"
            }
          },
          "buttonOkText": "documentationRecord.pageCreate.modals.sendToOperator.buttonOkText",
          "buttonCancelText": "documentationRecord.pageCreate.modals.sendToOperator.buttonCancelText"
        },
        "createCost": {
          "title": {
            "createAndSend": "documentationRecord.pageCreate.modals.createCost.title.createAndSend",
            "createAndSave": "documentationRecord.pageCreate.modals.createCost.title.createAndSave"
          },
          "description": "documentationRecord.pageCreate.modals.createCost.description",
          "credits": {
            "actualState": "documentationRecord.pageCreate.modals.createCost.credits.actualState",
            "price": "documentationRecord.pageCreate.modals.createCost.credits.price"
          },
          "buttonOkText": {
            "createAndSendText": "documentationRecord.pageCreate.modals.createCost.buttonOkText.createAndSendText",
            "createAndSaveText": "documentationRecord.pageCreate.modals.createCost.buttonOkText.createAndSaveText"
          },
          "buttonCancelText": "documentationRecord.pageCreate.modals.createCost.buttonCancelText",
          "topUpCredits": {
            "label": "documentationRecord.pageCreate.modals.createCost.topUpCredits.label"
          }
        }
      }
    },
    "pageEditType": {
      "header": {
        "title": "documentationRecord.pageEditType.header.title",
        "buttonSecondaryText": "documentationRecord.pageEditType.header.buttonSecondaryText"
      },
      "documentationType": {
        "title": "documentationRecord.pageEditType.documentationType.title"
      }
    },
    "pageEdit": {
      "header": {
        "title": "documentationRecord.pageEdit.header.title",
        "buttonPrimaryText": "documentationRecord.pageEdit.header.buttonPrimaryText"
      },
      "actions1": {
        "buttonBack": "documentationRecord.pageEdit.actions1.buttonBack"
      },
      "baseInformation": {
        "title": "documentationRecord.pageEdit.baseInformation.title"
      },
      "videoType": {
        "title": "documentationRecord.pageEdit.videoType.title"
      },
      "actions2": {
        "buttonDeleteText": "documentationRecord.pageEdit.actions2.buttonDeleteText",
        "buttonMoveDocumentationText": "documentationRecord.pageEdit.actions2.buttonMoveDocumentationText",
        "buttonAbortText": "documentationRecord.pageEdit.actions2.buttonAbortText",
        "buttonSaveText": "documentationRecord.pageEdit.actions2.buttonSaveText",
        "buttonSaveAndSendText": "documentationRecord.pageEdit.actions2.buttonSaveAndSendText"
      },
      "messages": {
        "documentationMoved": "documentationRecord.pageEdit.messages.documentationMoved",
        "documentationSaved": "documentationRecord.pageEdit.messages.documentationSaved",
        "documentationSavedAndSent": "documentationRecord.pageEdit.messages.documentationSavedAndSent"
      },
      "customValidations": {
        "videoTypeRequired": "documentationRecord.pageEdit.customValidations.videoTypeRequired"
      },
      "modals": {
        "move": {
          "title": "documentationRecord.pageEdit.modals.move.title",
          "form": {
            "construction": {
              "label": "documentationRecord.pageEdit.modals.move.form.construction.label",
              "defaultText": "documentationRecord.pageEdit.modals.move.form.construction.defaultText"
            },
            "object": {
              "label": "documentationRecord.pageEdit.modals.move.form.object.label",
              "defaultText": "documentationRecord.pageEdit.modals.move.form.object.defaultText",
              "noObjectsInfo": "documentationRecord.pageEdit.modals.move.form.object.noObjectsInfo"
            },
            "phase": {
              "label": "documentationRecord.pageEdit.modals.move.form.phase.label",
              "defaultText": "documentationRecord.pageEdit.modals.move.form.phase.defaultText"
            }
          },
          "buttonOkText": "documentationRecord.pageEdit.modals.move.buttonOkText",
          "buttonCancelText": "documentationRecord.pageEdit.modals.move.buttonCancelText"
        }
      }
    },
    "pageDetail": {
      "header": {
        "title": "documentationRecord.pageDetail.header.title",
        "buttonSendToOperator": "documentationRecord.pageDetail.header.buttonSendToOperator",
        "buttonPrimaryText": "documentationRecord.pageDetail.header.buttonPrimaryText"
      },
      "actions": {
        "buttonBackText": "documentationRecord.pageDetail.actions.buttonBackText",
        "buttonEditText": "documentationRecord.pageDetail.actions.buttonEditText",
        "buttonTaskOperatorText": "documentationRecord.pageDetail.actions.buttonTaskOperatorText"
      },
      "type": {
        "title": "documentationRecord.pageDetail.type.title",
        "description": {
          "noThreeDNoVideo": "documentationRecord.pageDetail.type.description.noThreeDNoVideo",
          "noThreeDVideo": "documentationRecord.pageDetail.type.description.noThreeDVideo",
          "threeDNoScale": "documentationRecord.pageDetail.type.description.threeDNoScale",
          "threeDScale": "documentationRecord.pageDetail.type.description.threeDScale"
        }
      },
      "baseInformation": {
        "title": "documentationRecord.pageDetail.baseInformation.title",
        "documentationDescription": {
          "label": "documentationRecord.pageDetail.baseInformation.documentationDescription.label"
        },
        "addedToPhase": {
          "label": "documentationRecord.pageDetail.baseInformation.addedToPhase.label"
        },
        "documentationState": {
          "label": "documentationRecord.pageDetail.baseInformation.documentationState.label"
        },
        "manager": {
          "label": "documentationRecord.pageDetail.baseInformation.manager.label",
          "defaultValue": "documentationRecord.pageDetail.baseInformation.manager.defaultValue"
        },
        "operator": {
          "label": "documentationRecord.pageDetail.baseInformation.operator.label",
          "defaultValue": "documentationRecord.pageDetail.baseInformation.operator.defaultValue"
        },
        "surveyor": {
          "label": "documentationRecord.pageDetail.baseInformation.surveyor.label",
          "defaultValue": "documentationRecord.pageDetail.baseInformation.surveyor.defaultValue"
        }
      },
      "videoType": {
        "title": "documentationRecord.pageDetail.videoType.title",
        "default": "documentationRecord.pageDetail.videoType.default",
        "costInfo": "documentationRecord.pageDetail.videoType.costInfo",
        "description": {
          "noScale": "documentationRecord.pageDetail.videoType.description.noScale",
          "scale": "documentationRecord.pageDetail.videoType.description.scale",
          "maps": "documentationRecord.pageDetail.videoType.description.maps",
          "spray": "documentationRecord.pageDetail.videoType.description.spray",
          "qrCodes": "documentationRecord.pageDetail.videoType.description.qrCodes",
          "default": "documentationRecord.pageDetail.videoType.description.default"
        }
      },
      "groundControlPoints": {
        "noData": "documentationRecord.pageDetail.groundControlPoints.noData"
      },
      "videoImport": {
        "videoDocumentation": {
          "noData": "documentationRecord.pageDetail.videoImport.videoDocumentation.noData",
          "location": {
            "label": "documentationRecord.pageDetail.videoImport.videoDocumentation.location.label"
          }
        },
        "geodeticSurvey": {
          "noData": "documentationRecord.pageDetail.videoImport.geodeticSurvey.noData"
        },
        "protocol": {
          "title": "documentationRecord.pageDetail.videoImport.protocol.title",
          "noData": "documentationRecord.pageDetail.videoImport.protocol.noData"
        }
      },
      "photos": {
        "title": "documentationRecord.pageDetail.photos.title",
        "pointDescription": {
          "label": "documentationRecord.pageDetail.photos.pointDescription.label"
        },
        "noData": "documentationRecord.pageDetail.photos.noData"
      },
      "threeDModel": {
        "title": "documentationRecord.pageDetail.threeDModel.title",
        "table": {
          "header": {
            "type": "documentationRecord.pageDetail.threeDModel.table.header.type",
            "name": "documentationRecord.pageDetail.threeDModel.table.header.name",
            "construction": "documentationRecord.pageDetail.threeDModel.table.header.construction",
            "createdAt": "documentationRecord.pageDetail.threeDModel.table.header.createdAt",
            "fileSize": "documentationRecord.pageDetail.threeDModel.table.header.fileSize",
            "coordinatesSystemType": "documentationRecord.pageDetail.threeDModel.table.header.coordinatesSystemType",
            "action": "documentationRecord.pageDetail.threeDModel.table.header.action"
          },
          "cell": {
            "deleteText": "documentationRecord.pageDetail.threeDModel.table.cell.deleteText"
          },
          "noData": "documentationRecord.pageDetail.threeDModel.table.noData"
        }
      },
      "comments": {
        "title": "documentationRecord.pageDetail.comments.title",
        "role": {
          "manager": "documentationRecord.pageDetail.comments.role.manager",
          "operator": "documentationRecord.pageDetail.comments.role.operator",
          "surveyor": "documentationRecord.pageDetail.comments.role.surveyor",
          "threeDOperator": "documentationRecord.pageDetail.comments.role.threeDOperator"
        }
      },
      "details": {
        "title": "documentationRecord.pageDetail.details.title",
        "createdAt": {
          "label": "documentationRecord.pageDetail.details.createdAt.label"
        },
        "editedAt": {
          "label": "documentationRecord.pageDetail.details.editedAt.label"
        },
        "createdBy": {
          "label": "documentationRecord.pageDetail.details.createdBy.label"
        },
        "editedBy": {
          "label": "documentationRecord.pageDetail.details.editedBy.label"
        }
      },
      "actions2": {
        "deleteButtonText": "documentationRecord.pageDetail.actions2.deleteButtonText"
      },
      "modals": {
        "delete": {
          "title": "documentationRecord.pageDetail.modals.delete.title",
          "description": "documentationRecord.pageDetail.modals.delete.description",
          "foundedThreeDModelsLabel": "documentationRecord.pageDetail.modals.delete.foundedThreeDModelsLabel",
          "warning": "documentationRecord.pageDetail.modals.delete.warning",
          "buttonOkText": "documentationRecord.pageDetail.modals.delete.buttonOkText",
          "buttonCancelText": "documentationRecord.pageDetail.modals.delete.buttonCancelText"
        }
      },
      "messages": {
        "delete": "documentationRecord.pageDetail.messages.delete",
        "returnDocumentation": "documentationRecord.pageDetail.messages.returnDocumentation",
        "sentToCreateThreeDModel": "documentationRecord.pageDetail.messages.sentToCreateThreeDModel",
        "solvedConflict": "documentationRecord.pageDetail.messages.solvedConflict"
      },
      "simplifiedGroundControlPoints": {
        "titleSuffix": "documentationRecord.pageDetail.simplifiedGroundControlPoints.titleSuffix",
        "count": "documentationRecord.pageDetail.simplifiedGroundControlPoints.count",
        "description": "documentationRecord.pageDetail.simplifiedGroundControlPoints.description",
        "photos": "documentationRecord.pageDetail.simplifiedGroundControlPoints.photos"
      },
      "outputExtensions": {
        "title": "documentationRecord.pageDetail.outputExtensions.title",
        "body": {
          "resolverComment": {
            "label": "documentationRecord.pageDetail.outputExtensions.body.resolverComment.label"
          },
          "attachments": {
            "label": "documentationRecord.pageDetail.outputExtensions.body.attachments.label"
          },
          "actions": {
            "deleteOutputExtension": "documentationRecord.pageDetail.outputExtensions.body.actions.deleteOutputExtension"
          },
          "messages": {
            "noResolved": "documentationRecord.pageDetail.outputExtensions.body.messages.noResolved",
            "successfullyDeleted": "documentationRecord.pageDetail.outputExtensions.body.messages.successfullyDeleted"
          }
        },
        "modals": {
          "delete": {
            "title": "documentationRecord.pageDetail.outputExtensions.modals.delete.title",
            "description": "documentationRecord.pageDetail.outputExtensions.modals.delete.description",
            "warning": "documentationRecord.pageDetail.outputExtensions.modals.delete.warning",
            "buttonOkText": "documentationRecord.pageDetail.outputExtensions.modals.delete.buttonOkText",
            "buttonCancelText": "documentationRecord.pageDetail.outputExtensions.modals.delete.buttonCancelText"
          }
        }
      }
    },
    "progressSteps": {
      "creationOfDocumentation": "documentationRecord.progressSteps.creationOfDocumentation",
      "getDataBySmartPhone": "documentationRecord.progressSteps.getDataBySmartPhone",
      "dataUploadBySurveyor": "documentationRecord.progressSteps.dataUploadBySurveyor",
      "dataCheckByManager": "documentationRecord.progressSteps.dataCheckByManager",
      "creatingThreeDModel": "documentationRecord.progressSteps.creatingThreeDModel",
      "done": "documentationRecord.progressSteps.done"
    },
    "formSelectType": {
      "creditPriceInformation": "documentationRecord.formSelectType.creditPriceInformation",
      "cardNoTheeDModel": {
        "title": "documentationRecord.formSelectType.cardNoTheeDModel.title",
        "description": "documentationRecord.formSelectType.cardNoTheeDModel.description"
      },
      "cardNoTheeDModelYet": {
        "title": "documentationRecord.formSelectType.cardNoTheeDModelYet.title",
        "description": "documentationRecord.formSelectType.cardNoTheeDModelYet.description"
      },
      "cardNoGeoreferencedModel": {
        "title": "documentationRecord.formSelectType.cardNoGeoreferencedModel.title",
        "description": "documentationRecord.formSelectType.cardNoGeoreferencedModel.description"
      },
      "cardGeoreferencedModel": {
        "title": "documentationRecord.formSelectType.cardGeoreferencedModel.title",
        "description": "documentationRecord.formSelectType.cardGeoreferencedModel.description"
      }
    },
    "forms": {
      "generalInfo": {
        "name": {
          "label": "documentationRecord.forms.generalInfo.name.label"
        },
        "description": {
          "label": "documentationRecord.forms.generalInfo.description.label",
          "placeholder": "documentationRecord.forms.generalInfo.description.placeholder"
        },
        "phase": {
          "label": "documentationRecord.forms.generalInfo.phase.label"
        },
        "accountableManager": {
          "label": "documentationRecord.forms.generalInfo.accountableManager.label"
        },
        "accountableOperator": {
          "label": "documentationRecord.forms.generalInfo.accountableOperator.label"
        },
        "accountableSurveyor": {
          "label": "documentationRecord.forms.generalInfo.accountableSurveyor.label"
        },
        "validations": {
          "nameRequired": "documentationRecord.forms.generalInfo.validations.nameRequired"
        },
        "tooltips": {
          "needAddNewUser": {
            "text": "documentationRecord.forms.generalInfo.tooltips.needAddNewUser.text",
            "content": "documentationRecord.forms.generalInfo.tooltips.needAddNewUser.content"
          }
        }
      }
    },
    "pageCreateSimplified": {
      "header": {
        "title": "documentationRecord.pageCreateSimplified.header.title",
        "buttonSecondaryText": "documentationRecord.pageCreateSimplified.header.buttonSecondaryText"
      },
      "sectionTitle": "documentationRecord.pageCreateSimplified.sectionTitle",
      "assignToConstructionSite": {
        "label": "documentationRecord.pageCreateSimplified.assignToConstructionSite.label"
      },
      "assignToConstructionObject": {
        "label": "documentationRecord.pageCreateSimplified.assignToConstructionObject.label"
      },
      "assignToConstructionPhase": {
        "label": "documentationRecord.pageCreateSimplified.assignToConstructionPhase.label"
      },
      "createConstructionSite": {
        "label": "documentationRecord.pageCreateSimplified.createConstructionSite.label",
        "placeholder": "documentationRecord.pageCreateSimplified.createConstructionSite.placeholder"
      },
      "createConstructionObject": {
        "label": "documentationRecord.pageCreateSimplified.createConstructionObject.label",
        "placeholder": "documentationRecord.pageCreateSimplified.createConstructionObject.placeholder",
        "errors": {
          "noPermission": "documentationRecord.pageCreateSimplified.createConstructionObject.errors.noPermission"
        }
      },
      "createConstructionPhase": {
        "label": "documentationRecord.pageCreateSimplified.createConstructionPhase.label",
        "placeholder": "documentationRecord.pageCreateSimplified.createConstructionPhase.placeholder",
        "errors": {
          "noPermission": "documentationRecord.pageCreateSimplified.createConstructionPhase.errors.noPermission"
        }
      },
      "buttonCreateText": "documentationRecord.pageCreateSimplified.buttonCreateText"
    },
    "validations": {
      "notEnoughCreditsToCreate": "documentationRecord.validations.notEnoughCreditsToCreate"
    }
  },
  "constructionPhase": {
    "header": {
      "title": "constructionPhase.header.title"
    },
    "documentation": {
      "title": "constructionPhase.documentation.title",
      "table": {
        "header": {
          "documentationRecord": "constructionPhase.documentation.table.header.documentationRecord",
          "constructionObject": "constructionPhase.documentation.table.header.constructionObject",
          "created": "constructionPhase.documentation.table.header.created",
          "state": "constructionPhase.documentation.table.header.state",
          "documentationRecordType": "constructionPhase.documentation.table.header.documentationRecordType",
          "videoType": "constructionPhase.documentation.table.header.videoType",
          "threeDModel": "constructionPhase.documentation.table.header.threeDModel"
        }
      }
    }
  },
  "threeDModels": {
    "header": {
      "title": "threeDModels.header.title"
    },
    "noData": "threeDModels.noData",
    "table": {
      "header": {
        "modelName": "threeDModels.table.header.modelName",
        "constructionSite": "threeDModels.table.header.constructionSite",
        "constructionObject": "threeDModels.table.header.constructionObject",
        "constructionPhase": "threeDModels.table.header.constructionPhase",
        "documentationName": "threeDModels.table.header.documentationName",
        "createdAt": "threeDModels.table.header.createdAt"
      }
    }
  },
  "threeDViewer": {
    "header": {
      "heading": "threeDViewer.header.heading",
      "threeDModel": "threeDViewer.header.threeDModel",
      "buttonShare": "threeDViewer.header.buttonShare",
      "buttonSecondaryText": "threeDViewer.header.buttonSecondaryText",
      "buttonPrimaryText": "threeDViewer.header.buttonPrimaryText",
      "buttonDownload": "threeDViewer.header.buttonDownload"
    },
    "drawer": {
      "title": "threeDViewer.drawer.title",
      "documentationRecord": "threeDViewer.drawer.documentationRecord",
      "constructionSite": "threeDViewer.drawer.constructionSite",
      "constructionObject": "threeDViewer.drawer.constructionObject",
      "phase": "threeDViewer.drawer.phase",
      "fileSize": "threeDViewer.drawer.fileSize",
      "threeDModelType": "threeDViewer.drawer.threeDModelType",
      "coordinatesSystem": "threeDViewer.drawer.coordinatesSystem",
      "createdDateAndTime": "threeDViewer.drawer.createdDateAndTime",
      "author": "threeDViewer.drawer.author",
      "actions": {
        "buttonDeleteThreeDModelText": "threeDViewer.drawer.actions.buttonDeleteThreeDModelText"
      }
    },
    "modals": {
      "deleteThreeDModel": {
        "title": "threeDViewer.modals.deleteThreeDModel.title",
        "description": "threeDViewer.modals.deleteThreeDModel.description",
        "warning": "threeDViewer.modals.deleteThreeDModel.warning",
        "buttonOkText": "threeDViewer.modals.deleteThreeDModel.buttonOkText",
        "buttonCancelText": "threeDViewer.modals.deleteThreeDModel.buttonCancelText"
      },
      "shareThreeDModel": {
        "title": "threeDViewer.modals.shareThreeDModel.title",
        "options": {
          "notShareModel": "threeDViewer.modals.shareThreeDModel.options.notShareModel",
          "shareModel": "threeDViewer.modals.shareThreeDModel.options.shareModel"
        },
        "notShareModel": {
          "notice": "threeDViewer.modals.shareThreeDModel.notShareModel.notice"
        },
        "shareModel": {
          "timeLimit": {
            "label": "threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.label",
            "options": {
              "unlimited": "threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.unlimited",
              "forOneHour": "threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.forOneHour",
              "forOneDay": "threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.forOneDay",
              "forOneWeek": "threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.forOneWeek",
              "forOneMonth": "threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.forOneMonth"
            }
          },
          "sendShareForm": {
            "recipients": {
              "label": "threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.recipients.label",
              "placeholder": "threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.recipients.placeholder"
            },
            "comment": {
              "label": "threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.comment.label",
              "placeholder": "threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.comment.placeholder"
            },
            "validations": {
              "invalid": "threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.validations.invalid",
              "send": "threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.validations.send"
            }
          },
          "notice": "threeDViewer.modals.shareThreeDModel.shareModel.notice",
          "actions": {
            "buttonGenerateUrl": "threeDViewer.modals.shareThreeDModel.shareModel.actions.buttonGenerateUrl",
            "buttonSend": "threeDViewer.modals.shareThreeDModel.shareModel.actions.buttonSend"
          }
        },
        "buttonOkText": "threeDViewer.modals.shareThreeDModel.buttonOkText",
        "messages": {
          "successfullySent": "threeDViewer.modals.shareThreeDModel.messages.successfullySent",
          "successfullyUnshared": "threeDViewer.modals.shareThreeDModel.messages.successfullyUnshared"
        }
      },
      "downloadThreeDModel": {
        "title": "threeDViewer.modals.downloadThreeDModel.title",
        "credits": {
          "actualState": "threeDViewer.modals.downloadThreeDModel.credits.actualState",
          "downloadPrice": "threeDViewer.modals.downloadThreeDModel.credits.downloadPrice"
        },
        "notice": "threeDViewer.modals.downloadThreeDModel.notice",
        "buttonOkText": "threeDViewer.modals.downloadThreeDModel.buttonOkText"
      }
    }
  },
  "threeDModelsCompleted": {
    "header": {
      "title": "threeDModelsCompleted.header.title"
    },
    "noData": "threeDModelsCompleted.noData",
    "table": {
      "header": {
        "modelName": "threeDModelsCompleted.table.header.modelName",
        "createdAt": "threeDModelsCompleted.table.header.createdAt",
        "constructionSite": "threeDModelsCompleted.table.header.constructionSite",
        "constructionObject": "threeDModelsCompleted.table.header.constructionObject",
        "documentationName": "threeDModelsCompleted.table.header.documentationName"
      }
    }
  },
  "organizations": {
    "header": {
      "title": "organizations.header.title"
    },
    "memberAtOrganizations": {
      "title": "organizations.memberAtOrganizations.title",
      "table": {
        "header": {
          "organization": "organizations.memberAtOrganizations.table.header.organization",
          "actions": "organizations.memberAtOrganizations.table.header.actions"
        },
        "cell": {
          "leaveText": "organizations.memberAtOrganizations.table.cell.leaveText"
        }
      }
    },
    "messages": {
      "organizationLeaved": "organizations.messages.organizationLeaved"
    }
  },
  "organization": {
    "header": {
      "title": "organization.header.title",
      "crn": "organization.header.crn"
    },
    "members": {
      "title": "organization.members.title",
      "buttonInviteText": "organization.members.buttonInviteText",
      "noUsers": "organization.members.noUsers",
      "manager": {
        "label": "organization.members.manager.label"
      },
      "operator": {
        "label": "organization.members.operator.label"
      },
      "surveyor": {
        "label": "organization.members.surveyor.label"
      },
      "reader": {
        "label": "organization.members.reader.label"
      }
    },
    "constructions": {
      "title": "organization.constructions.title",
      "table": {
        "buttonAddText": "organization.constructions.table.buttonAddText",
        "noData": "organization.constructions.table.noData"
      }
    },
    "storage": {
      "title": "organization.storage.title",
      "buttonManageText": "organization.storage.buttonManageText",
      "tariffInfo": "organization.storage.tariffInfo",
      "currentPlan": "organization.storage.currentPlan",
      "nextControlInfo": "organization.storage.nextControlInfo"
    },
    "actions": {
      "buttonLeaveOrganizationText": "organization.actions.buttonLeaveOrganizationText",
      "inviteAgain": "organization.actions.inviteAgain",
      "removeUser": "organization.actions.removeUser",
      "cancelInvite": "organization.actions.cancelInvite"
    },
    "modals": {
      "inviteUser": {
        "title": "organization.modals.inviteUser.title",
        "form": {
          "email": {
            "label": "organization.modals.inviteUser.form.email.label",
            "validations": {
              "wrongFormat": "organization.modals.inviteUser.form.email.validations.wrongFormat"
            }
          },
          "role": {
            "label": "organization.modals.inviteUser.form.role.label"
          },
          "comment": {
            "label": "organization.modals.inviteUser.form.comment.label",
            "placeholder": "organization.modals.inviteUser.form.comment.placeholder"
          }
        },
        "credits": {
          "actualState": "organization.modals.inviteUser.credits.actualState",
          "price": "organization.modals.inviteUser.credits.price"
        },
        "buttonOkText": "organization.modals.inviteUser.buttonOkText",
        "buttonCancelText": "organization.modals.inviteUser.buttonCancelText",
        "buttonCloseText": "organization.modals.inviteUser.buttonCloseText",
        "errors": {
          "cantInviteThisEmail": "organization.modals.inviteUser.errors.cantInviteThisEmail",
          "notFound": "organization.modals.inviteUser.errors.notFound",
          "permissionDenied": "organization.modals.inviteUser.errors.permissionDenied",
          "roleNotAllowed": "organization.modals.inviteUser.errors.roleNotAllowed",
          "emailInvalid": "organization.modals.inviteUser.errors.emailInvalid",
          "alreadyMember": "organization.modals.inviteUser.errors.alreadyMember",
          "roleMismatch": "organization.modals.inviteUser.errors.roleMismatch",
          "alreadyInvited": "organization.modals.inviteUser.errors.alreadyInvited",
          "notEnoughCredits": "organization.modals.inviteUser.errors.notEnoughCredits"
        },
        "linkBuyCredits": "organization.modals.inviteUser.linkBuyCredits",
        "invitationState": {
          "sent": "organization.modals.inviteUser.invitationState.sent",
          "rejected": "organization.modals.inviteUser.invitationState.rejected",
          "expired": "organization.modals.inviteUser.invitationState.expired"
        }
      },
      "removeUser": {
        "title": "organization.modals.removeUser.title",
        "description": "organization.modals.removeUser.description",
        "warning": "organization.modals.removeUser.warning",
        "buttonOkText": "organization.modals.removeUser.buttonOkText",
        "buttonCancelText": "organization.modals.removeUser.buttonCancelText"
      },
      "leave": {
        "title": "organization.modals.leave.title",
        "description": {
          "leaveAndDelete": "organization.modals.leave.description.leaveAndDelete",
          "leave": "organization.modals.leave.description.leave",
          "assignAndLeave": "organization.modals.leave.description.assignAndLeave"
        },
        "warning": "organization.modals.leave.warning",
        "warningLastManager": "organization.modals.leave.warningLastManager",
        "buttonOkText": {
          "leaveAndDelete": "organization.modals.leave.buttonOkText.leaveAndDelete",
          "leave": "organization.modals.leave.buttonOkText.leave",
          "assignAndLeave": "organization.modals.leave.buttonOkText.assignAndLeave"
        },
        "buttonCancelText": "organization.modals.leave.buttonCancelText"
      },
      "leaveAlert": {
        "title": "organization.modals.leaveAlert.title",
        "description": "organization.modals.leaveAlert.description",
        "buttonOkText": "organization.modals.leaveAlert.buttonOkText"
      }
    },
    "messages": {
      "invitationSent": "organization.messages.invitationSent",
      "userRemoved": "organization.messages.userRemoved",
      "inviteCanceled": "organization.messages.inviteCanceled"
    }
  },
  "organizationResources": {
    "header": {
      "title": "organizationResources.header.title",
      "titlePrefix": "organizationResources.header.titlePrefix"
    },
    "storage": {
      "title": "organizationResources.storage.title",
      "buttonManageText": "organizationResources.storage.buttonManageText",
      "tariffInfo": "organizationResources.storage.tariffInfo",
      "currentPlan": "organizationResources.storage.currentPlan",
      "nextControlInfo": "organizationResources.storage.nextControlInfo"
    },
    "threeDModels": {
      "title": "organizationResources.threeDModels.title",
      "table": {
        "header": {
          "type": "organizationResources.threeDModels.table.header.type",
          "construction": "organizationResources.threeDModels.table.header.construction",
          "createdAt": "organizationResources.threeDModels.table.header.createdAt",
          "fileSize": "organizationResources.threeDModels.table.header.fileSize",
          "action": "organizationResources.threeDModels.table.header.action"
        },
        "cell": {
          "deleteText": "organizationResources.threeDModels.table.cell.deleteText"
        },
        "noData": "organizationResources.threeDModels.table.noData"
      }
    },
    "documentationRecords": {
      "title": "organizationResources.documentationRecords.title",
      "table": {
        "header": {
          "name": "organizationResources.documentationRecords.table.header.name",
          "constructionSite": "organizationResources.documentationRecords.table.header.constructionSite",
          "createdAt": "organizationResources.documentationRecords.table.header.createdAt",
          "size": "organizationResources.documentationRecords.table.header.size",
          "actions": "organizationResources.documentationRecords.table.header.actions"
        },
        "cell": {
          "deleteText": "organizationResources.documentationRecords.table.cell.deleteText"
        },
        "noDataText": "organizationResources.documentationRecords.table.noDataText"
      }
    }
  },
  "errorBoundary": {
    "title": "errorBoundary.title",
    "description": "errorBoundary.description",
    "buttonReload": {
      "text": "errorBoundary.buttonReload.text"
    },
    "buttonRedirect": {
      "text": "errorBoundary.buttonRedirect.text"
    }
  },
  "errorPage": {
    "httpError": {
      "forbidden": {
        "title": "errorPage.httpError.forbidden.title",
        "description": "errorPage.httpError.forbidden.description",
        "button": {
          "text": "errorPage.httpError.forbidden.button.text"
        }
      },
      "notFound": {
        "title": "errorPage.httpError.notFound.title",
        "description": "errorPage.httpError.notFound.description",
        "button": {
          "text": "errorPage.httpError.notFound.button.text"
        }
      },
      "notFoundLogged": {
        "title": "errorPage.httpError.notFoundLogged.title",
        "description": "errorPage.httpError.notFoundLogged.description",
        "button": {
          "text": "errorPage.httpError.notFoundLogged.button.text"
        }
      },
      "internal": {
        "title": "errorPage.httpError.internal.title",
        "description": "errorPage.httpError.internal.description",
        "button": {
          "text": "errorPage.httpError.internal.button.text"
        }
      }
    },
    "accessError": {
      "otherTeam": {
        "heading": "errorPage.accessError.otherTeam.heading",
        "descriptionText": "errorPage.accessError.otherTeam.descriptionText",
        "descriptionLinkText": "errorPage.accessError.otherTeam.descriptionLinkText"
      },
      "wrongRole": {
        "heading": "errorPage.accessError.wrongRole.heading",
        "descriptionText": "errorPage.accessError.wrongRole.descriptionText",
        "descriptionLinkText": "errorPage.accessError.wrongRole.descriptionLinkText"
      },
      "accountLocked": {
        "heading": "errorPage.accessError.accountLocked.heading",
        "descriptionText": "errorPage.accessError.accountLocked.descriptionText",
        "descriptionLinkText": "errorPage.accessError.accountLocked.descriptionLinkText"
      },
      "accountBlocked": {
        "heading": "errorPage.accessError.accountBlocked.heading",
        "descriptionText": "errorPage.accessError.accountBlocked.descriptionText",
        "descriptionLinkText": "errorPage.accessError.accountBlocked.descriptionLinkText"
      },
      "noTeamOrLicence": {
        "heading": "errorPage.accessError.noTeamOrLicence.heading",
        "buttonText": "errorPage.accessError.noTeamOrLicence.buttonText"
      }
    }
  },
  "aboutPage": {
    "header": {
      "title": "aboutPage.header.title"
    },
    "tabs": {
      "about": {
        "title": "aboutPage.tabs.about.title",
        "annotation": "aboutPage.tabs.about.annotation",
        "content": "aboutPage.tabs.about.content"
      },
      "myTasks": {
        "title": "aboutPage.tabs.myTasks.title",
        "content": {
          "aboutTasks": {
            "title": "aboutPage.tabs.myTasks.content.aboutTasks.title",
            "description": "aboutPage.tabs.myTasks.content.aboutTasks.description"
          },
          "manager": {
            "aboutYourRole": {
              "title": "aboutPage.tabs.myTasks.content.manager.aboutYourRole.title",
              "description": "aboutPage.tabs.myTasks.content.manager.aboutYourRole.description"
            },
            "tasks": {
              "title": "aboutPage.tabs.myTasks.content.manager.tasks.title",
              "list": {
                "label": "aboutPage.tabs.myTasks.content.manager.tasks.list.label",
                "items": "aboutPage.tabs.myTasks.content.manager.tasks.list.items"
              },
              "description": "aboutPage.tabs.myTasks.content.manager.tasks.description"
            },
            "authorizationForYou": {
              "title": "aboutPage.tabs.myTasks.content.manager.authorizationForYou.title",
              "list1": {
                "items": "aboutPage.tabs.myTasks.content.manager.authorizationForYou.list1.items"
              },
              "list2": {
                "items": "aboutPage.tabs.myTasks.content.manager.authorizationForYou.list2.items"
              }
            }
          },
          "operator": {
            "aboutYourRole": {
              "title": "aboutPage.tabs.myTasks.content.operator.aboutYourRole.title",
              "description": "aboutPage.tabs.myTasks.content.operator.aboutYourRole.description"
            },
            "tasks": {
              "title": "aboutPage.tabs.myTasks.content.operator.tasks.title",
              "list": {
                "label": "aboutPage.tabs.myTasks.content.operator.tasks.list.label",
                "items": "aboutPage.tabs.myTasks.content.operator.tasks.list.items"
              },
              "description": "aboutPage.tabs.myTasks.content.operator.tasks.description"
            },
            "authorizationForYou": {
              "title": "aboutPage.tabs.myTasks.content.operator.authorizationForYou.title",
              "list1": {
                "items": "aboutPage.tabs.myTasks.content.operator.authorizationForYou.list1.items"
              },
              "list2": {
                "items": "aboutPage.tabs.myTasks.content.operator.authorizationForYou.list2.items"
              }
            }
          },
          "surveyor": {
            "aboutYourRole": {
              "title": "aboutPage.tabs.myTasks.content.surveyor.aboutYourRole.title",
              "description1": "aboutPage.tabs.myTasks.content.surveyor.aboutYourRole.description1",
              "description2": "aboutPage.tabs.myTasks.content.surveyor.aboutYourRole.description2"
            },
            "tasks": {
              "title": "aboutPage.tabs.myTasks.content.surveyor.tasks.title",
              "list": {
                "label": "aboutPage.tabs.myTasks.content.surveyor.tasks.list.label",
                "items": "aboutPage.tabs.myTasks.content.surveyor.tasks.list.items"
              }
            },
            "authorizationForYou": {
              "title": "aboutPage.tabs.myTasks.content.surveyor.authorizationForYou.title",
              "list": {
                "items": "aboutPage.tabs.myTasks.content.surveyor.authorizationForYou.list.items"
              }
            }
          }
        }
      },
      "documentation": {
        "title": "aboutPage.tabs.documentation.title",
        "about": {
          "title": "aboutPage.tabs.documentation.about.title",
          "description1": "aboutPage.tabs.documentation.about.description1",
          "description2": "aboutPage.tabs.documentation.about.description2"
        },
        "noModel": {
          "title": "aboutPage.tabs.documentation.noModel.title",
          "description": "aboutPage.tabs.documentation.noModel.description"
        },
        "yetNoModel": {
          "title": "aboutPage.tabs.documentation.yetNoModel.title",
          "description1": "aboutPage.tabs.documentation.yetNoModel.description1",
          "description2": "aboutPage.tabs.documentation.yetNoModel.description2"
        },
        "modelNoScale": {
          "title": "aboutPage.tabs.documentation.modelNoScale.title",
          "description1": "aboutPage.tabs.documentation.modelNoScale.description1",
          "description2": "aboutPage.tabs.documentation.modelNoScale.description2"
        },
        "modelScale": {
          "title": "aboutPage.tabs.documentation.modelScale.title",
          "description1": "aboutPage.tabs.documentation.modelScale.description1",
          "description2": "aboutPage.tabs.documentation.modelScale.description2"
        }
      },
      "threeDModels": {
        "title": "aboutPage.tabs.threeDModels.title",
        "about": {
          "title": "aboutPage.tabs.threeDModels.about.title",
          "description": "aboutPage.tabs.threeDModels.about.description"
        },
        "whatToDo": {
          "title": "aboutPage.tabs.threeDModels.whatToDo.title",
          "description": "aboutPage.tabs.threeDModels.whatToDo.description"
        }
      },
      "constructionSites": {
        "title": "aboutPage.tabs.constructionSites.title",
        "about": {
          "title": "aboutPage.tabs.constructionSites.about.title",
          "description1": "aboutPage.tabs.constructionSites.about.description1",
          "description2": "aboutPage.tabs.constructionSites.about.description2"
        },
        "output": {
          "title": "aboutPage.tabs.constructionSites.output.title",
          "description1": "aboutPage.tabs.constructionSites.output.description1",
          "description2": "aboutPage.tabs.constructionSites.output.description2"
        }
      },
      "organization": {
        "title": "aboutPage.tabs.organization.title",
        "about": {
          "title": "aboutPage.tabs.organization.about.title",
          "description": "aboutPage.tabs.organization.about.description"
        }
      },
      "sample": {
        "title": "aboutPage.tabs.sample.title",
        "intro": {
          "title": "aboutPage.tabs.sample.intro.title",
          "description": "aboutPage.tabs.sample.intro.description"
        },
        "manager": {
          "title": "aboutPage.tabs.sample.manager.title",
          "description1": "aboutPage.tabs.sample.manager.description1",
          "description2": "aboutPage.tabs.sample.manager.description2"
        },
        "operator": {
          "title": "aboutPage.tabs.sample.operator.title",
          "description1": "aboutPage.tabs.sample.operator.description1",
          "description2": "aboutPage.tabs.sample.operator.description2"
        },
        "surveyor": {
          "title": "aboutPage.tabs.sample.surveyor.title",
          "description1": "aboutPage.tabs.sample.surveyor.description1",
          "description2": "aboutPage.tabs.sample.surveyor.description2"
        },
        "threeDModel": {
          "title": "aboutPage.tabs.sample.threeDModel.title",
          "description": "aboutPage.tabs.sample.threeDModel.description"
        }
      }
    }
  },
  "documentationRecordDetail": {
    "videoUploadOverview": {
      "title": "documentationRecordDetail.videoUploadOverview.title"
    },
    "geodeticSurveyUploadOverview": {
      "geodeticSurvey": {
        "title": "documentationRecordDetail.geodeticSurveyUploadOverview.geodeticSurvey.title"
      },
      "geodeticSurveyAdditional": {
        "title": "documentationRecordDetail.geodeticSurveyUploadOverview.geodeticSurveyAdditional.title"
      }
    }
  },
  "documentationRecordActions": {
    "sectionTitle": "documentationRecordActions.sectionTitle",
    "validation": {
      "documentationRecordAttributeRequired": "documentationRecordActions.validation.documentationRecordAttributeRequired",
      "videoValidType": "documentationRecordActions.validation.videoValidType",
      "videoIsSet": "documentationRecordActions.validation.videoIsSet",
      "videoIsReady": "documentationRecordActions.validation.videoIsReady",
      "groundControlPointsMinimalCount": "documentationRecordActions.validation.groundControlPointsMinimalCount",
      "groundControlPointsPhotoSet": "documentationRecordActions.validation.groundControlPointsPhotoSet",
      "groundControlPointsPhotoReady": "documentationRecordActions.validation.groundControlPointsPhotoReady",
      "groundControlPointsGeolocationSet": "documentationRecordActions.validation.groundControlPointsGeolocationSet",
      "constructionElementPhotoReady": "documentationRecordActions.validation.constructionElementPhotoReady",
      "geodeticSurveyIsSet": "documentationRecordActions.validation.geodeticSurveyIsSet",
      "geodeticSurveyIsReady": "documentationRecordActions.validation.geodeticSurveyIsReady",
      "threeDModelIsSet": "documentationRecordActions.validation.threeDModelIsSet",
      "threeDModelIsReady": "documentationRecordActions.validation.threeDModelIsReady",
      "organizationHasCredits": "documentationRecordActions.validation.organizationHasCredits",
      "organizationHasCreditsNoVideo": "documentationRecordActions.validation.organizationHasCreditsNoVideo",
      "outputExtensionIsReady": "documentationRecordActions.validation.outputExtensionIsReady"
    },
    "attribute": {
      "accountableOperator": "documentationRecordActions.attribute.accountableOperator",
      "accountableSurveyor": "documentationRecordActions.attribute.accountableSurveyor"
    },
    "submitOperator": {
      "button": "documentationRecordActions.submitOperator.button",
      "label": "documentationRecordActions.submitOperator.label",
      "modal": {
        "title": "documentationRecordActions.submitOperator.modal.title",
        "description": "documentationRecordActions.submitOperator.modal.description",
        "comment": {
          "label": "documentationRecordActions.submitOperator.modal.comment.label",
          "placeholder": "documentationRecordActions.submitOperator.modal.comment.placeholder"
        },
        "buttonCancel": "documentationRecordActions.submitOperator.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.submitOperator.modal.buttonOk"
      },
      "messages": {
        "success": "documentationRecordActions.submitOperator.messages.success"
      }
    },
    "backCreated": {
      "button": "documentationRecordActions.backCreated.button",
      "label": "documentationRecordActions.backCreated.label",
      "description": "documentationRecordActions.backCreated.description",
      "modal": {
        "title": "documentationRecordActions.backCreated.modal.title",
        "description": "documentationRecordActions.backCreated.modal.description",
        "comment": {
          "label": "documentationRecordActions.backCreated.modal.comment.label",
          "placeholder": "documentationRecordActions.backCreated.modal.comment.placeholder"
        },
        "buttonCancel": "documentationRecordActions.backCreated.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.backCreated.modal.buttonOk"
      },
      "messages": {
        "success": "documentationRecordActions.backCreated.messages.success"
      }
    },
    "submitSurveyor": {
      "button": "documentationRecordActions.submitSurveyor.button",
      "label": "documentationRecordActions.submitSurveyor.label",
      "description": "documentationRecordActions.submitSurveyor.description",
      "modal": {
        "title": "documentationRecordActions.submitSurveyor.modal.title",
        "description": "documentationRecordActions.submitSurveyor.modal.description",
        "form": {
          "comment": {
            "label": "documentationRecordActions.submitSurveyor.modal.form.comment.label",
            "placeholder": "documentationRecordActions.submitSurveyor.modal.form.comment.placeholder"
          }
        },
        "buttonOk": "documentationRecordActions.submitSurveyor.modal.buttonOk",
        "buttonCancel": "documentationRecordActions.submitSurveyor.modal.buttonCancel"
      },
      "messages": {
        "success": "documentationRecordActions.submitSurveyor.messages.success"
      }
    },
    "backOperator": {
      "button": "documentationRecordActions.backOperator.button",
      "label": "documentationRecordActions.backOperator.label",
      "description": "documentationRecordActions.backOperator.description",
      "modal": {
        "title": "documentationRecordActions.backOperator.modal.title",
        "description": "documentationRecordActions.backOperator.modal.description",
        "reason": {
          "label": "documentationRecordActions.backOperator.modal.reason.label"
        },
        "comment": {
          "label": "documentationRecordActions.backOperator.modal.comment.label",
          "labelRequired": "documentationRecordActions.backOperator.modal.comment.labelRequired",
          "placeholder": "documentationRecordActions.backOperator.modal.comment.placeholder"
        },
        "refund": {
          "label": "documentationRecordActions.backOperator.modal.refund.label",
          "return": "documentationRecordActions.backOperator.modal.refund.return"
        },
        "buttonCancel": "documentationRecordActions.backOperator.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.backOperator.modal.buttonOk",
        "buttonCancelRefund": "documentationRecordActions.backOperator.modal.buttonCancelRefund",
        "buttonOkRefund": "documentationRecordActions.backOperator.modal.buttonOkRefund"
      },
      "messages": {
        "success": "documentationRecordActions.backOperator.messages.success"
      }
    },
    "submitThreeDOperator": {
      "button": "documentationRecordActions.submitThreeDOperator.button",
      "label": "documentationRecordActions.submitThreeDOperator.label",
      "description": "documentationRecordActions.submitThreeDOperator.description",
      "modal": {
        "title": "documentationRecordActions.submitThreeDOperator.modal.title",
        "description": "documentationRecordActions.submitThreeDOperator.modal.description",
        "organizationCredits": "documentationRecordActions.submitThreeDOperator.modal.organizationCredits",
        "cost": "documentationRecordActions.submitThreeDOperator.modal.cost",
        "buttonCancel": "documentationRecordActions.submitThreeDOperator.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.submitThreeDOperator.modal.buttonOk"
      },
      "messages": {
        "success": "documentationRecordActions.submitThreeDOperator.messages.success"
      },
      "modalSimple": {
        "title": "documentationRecordActions.submitThreeDOperator.modalSimple.title",
        "description": "documentationRecordActions.submitThreeDOperator.modalSimple.description",
        "buttonCancel": "documentationRecordActions.submitThreeDOperator.modalSimple.buttonCancel",
        "buttonOk": "documentationRecordActions.submitThreeDOperator.modalSimple.buttonOk"
      },
      "modalNotEnoughCredits": {
        "title": "documentationRecordActions.submitThreeDOperator.modalNotEnoughCredits.title",
        "description": "documentationRecordActions.submitThreeDOperator.modalNotEnoughCredits.description",
        "buttonOk": "documentationRecordActions.submitThreeDOperator.modalNotEnoughCredits.buttonOk"
      }
    },
    "submitManagerApproval": {
      "button": "documentationRecordActions.submitManagerApproval.button",
      "label": "documentationRecordActions.submitManagerApproval.label",
      "description": "documentationRecordActions.submitManagerApproval.description",
      "modal": {
        "title": "documentationRecordActions.submitManagerApproval.modal.title",
        "description": "documentationRecordActions.submitManagerApproval.modal.description",
        "comment": {
          "label": "documentationRecordActions.submitManagerApproval.modal.comment.label",
          "placeholder": "documentationRecordActions.submitManagerApproval.modal.comment.placeholder"
        },
        "buttonCancel": "documentationRecordActions.submitManagerApproval.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.submitManagerApproval.modal.buttonOk"
      },
      "messages": {
        "success": "documentationRecordActions.submitManagerApproval.messages.success"
      }
    },
    "backSurveyor": {
      "button": "documentationRecordActions.backSurveyor.button",
      "label": "documentationRecordActions.backSurveyor.label",
      "description": "documentationRecordActions.backSurveyor.description",
      "modal": {
        "title": "documentationRecordActions.backSurveyor.modal.title",
        "description": "documentationRecordActions.backSurveyor.modal.description",
        "reason": {
          "label": "documentationRecordActions.backSurveyor.modal.reason.label"
        },
        "comment": {
          "label": "documentationRecordActions.backSurveyor.modal.comment.label",
          "labelRequired": "documentationRecordActions.backSurveyor.modal.comment.labelRequired",
          "placeholder": "documentationRecordActions.backSurveyor.modal.comment.placeholder"
        },
        "refund": {
          "label": "documentationRecordActions.backSurveyor.modal.refund.label",
          "return": "documentationRecordActions.backSurveyor.modal.refund.return"
        },
        "buttonCancel": "documentationRecordActions.backSurveyor.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.backSurveyor.modal.buttonOk",
        "buttonCancelRefund": "documentationRecordActions.backSurveyor.modal.buttonCancelRefund",
        "buttonOkRefund": "documentationRecordActions.backSurveyor.modal.buttonOkRefund"
      },
      "messages": {
        "success": "documentationRecordActions.backSurveyor.messages.success"
      }
    },
    "submitFinished": {
      "button": "documentationRecordActions.submitFinished.button",
      "label": "documentationRecordActions.submitFinished.label",
      "description": "documentationRecordActions.submitFinished.description",
      "messages": {
        "success": "documentationRecordActions.submitFinished.messages.success"
      }
    },
    "reject": {
      "button": "documentationRecordActions.reject.button",
      "label": "documentationRecordActions.reject.label",
      "description": "documentationRecordActions.reject.description",
      "modal": {
        "title": "documentationRecordActions.reject.modal.title",
        "reason": {
          "label": "documentationRecordActions.reject.modal.reason.label"
        },
        "comment": {
          "label": "documentationRecordActions.reject.modal.comment.label",
          "placeholder": "documentationRecordActions.reject.modal.comment.placeholder"
        },
        "refund": {
          "label": "documentationRecordActions.reject.modal.refund.label",
          "return": "documentationRecordActions.reject.modal.refund.return"
        },
        "buttonCancel": "documentationRecordActions.reject.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.reject.modal.buttonOk"
      },
      "messages": {
        "success": "documentationRecordActions.reject.messages.success"
      }
    },
    "changeToThreeD": {
      "button": "documentationRecordActions.changeToThreeD.button",
      "label": "documentationRecordActions.changeToThreeD.label",
      "description": "documentationRecordActions.changeToThreeD.description",
      "modal": {
        "title": "documentationRecordActions.changeToThreeD.modal.title",
        "description": "documentationRecordActions.changeToThreeD.modal.description",
        "organizationCredits": "documentationRecordActions.changeToThreeD.modal.organizationCredits",
        "cost": "documentationRecordActions.changeToThreeD.modal.cost",
        "buttonCancel": "documentationRecordActions.changeToThreeD.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.changeToThreeD.modal.buttonOk"
      },
      "messages": {
        "success": "documentationRecordActions.changeToThreeD.messages.success"
      }
    },
    "changeNoScale": {
      "button": "documentationRecordActions.changeNoScale.button",
      "label": "documentationRecordActions.changeNoScale.label",
      "description": "documentationRecordActions.changeNoScale.description",
      "modal": {
        "title": "documentationRecordActions.changeNoScale.modal.title",
        "description": "documentationRecordActions.changeNoScale.modal.description",
        "buttonCancel": "documentationRecordActions.changeNoScale.modal.buttonCancel",
        "buttonOk": "documentationRecordActions.changeNoScale.modal.buttonOk"
      },
      "messages": {
        "success": "documentationRecordActions.changeNoScale.messages.success"
      }
    },
    "customValidation": {
      "organizationHasCredits": "documentationRecordActions.customValidation.organizationHasCredits"
    }
  },
  "taskGeneralInfo": {
    "heading": "taskGeneralInfo.heading",
    "documentation": {
      "heading": "taskGeneralInfo.documentation.heading",
      "name": "taskGeneralInfo.documentation.name",
      "description": "taskGeneralInfo.documentation.description",
      "phase": "taskGeneralInfo.documentation.phase",
      "state": "taskGeneralInfo.documentation.state",
      "documentationType": "taskGeneralInfo.documentation.documentationType",
      "videoType": "taskGeneralInfo.documentation.videoType"
    },
    "object": {
      "heading": "taskGeneralInfo.object.heading",
      "name": "taskGeneralInfo.object.name",
      "description": "taskGeneralInfo.object.description"
    },
    "site": {
      "heading": "taskGeneralInfo.site.heading",
      "name": "taskGeneralInfo.site.name",
      "description": "taskGeneralInfo.site.description",
      "organization": "taskGeneralInfo.site.organization"
    },
    "contacts": {
      "heading": "taskGeneralInfo.contacts.heading",
      "accountableManager": "taskGeneralInfo.contacts.accountableManager",
      "accountableOperator": "taskGeneralInfo.contacts.accountableOperator",
      "accountableSurveyor": "taskGeneralInfo.contacts.accountableSurveyor"
    }
  },
  "taskOperator": {
    "buttonBack": "taskOperator.buttonBack",
    "groundControlPoints": {
      "title": "taskOperator.groundControlPoints.title",
      "description": "taskOperator.groundControlPoints.description",
      "buttonAddAnother": "taskOperator.groundControlPoints.buttonAddAnother",
      "point": {
        "title": "taskOperator.groundControlPoints.point.title",
        "photos": {
          "description": "taskOperator.groundControlPoints.point.photos.description"
        },
        "buttonSave": "taskOperator.groundControlPoints.point.buttonSave",
        "buttonRemove": "taskOperator.groundControlPoints.point.buttonRemove",
        "saved": "taskOperator.groundControlPoints.point.saved",
        "unsaved": "taskOperator.groundControlPoints.point.unsaved"
      }
    },
    "videoUpload": {
      "title": "taskOperator.videoUpload.title",
      "description": "taskOperator.videoUpload.description",
      "descriptionPoints": "taskOperator.videoUpload.descriptionPoints",
      "errorNoType": "taskOperator.videoUpload.errorNoType"
    },
    "groundControlPointsVariantSelector": {
      "description": "taskOperator.groundControlPointsVariantSelector.description",
      "buttonSimplifiedVariant": "taskOperator.groundControlPointsVariantSelector.buttonSimplifiedVariant",
      "buttonStandardVariant": "taskOperator.groundControlPointsVariantSelector.buttonStandardVariant"
    },
    "simplifiedGroundControlPoint": {
      "title": "taskOperator.simplifiedGroundControlPoint.title",
      "form": {
        "title": "taskOperator.simplifiedGroundControlPoint.form.title",
        "description": "taskOperator.simplifiedGroundControlPoint.form.description",
        "fields": {
          "countOfPoints": {
            "label": "taskOperator.simplifiedGroundControlPoint.form.fields.countOfPoints.label",
            "validation": "taskOperator.simplifiedGroundControlPoint.form.fields.countOfPoints.validation"
          },
          "description": {
            "label": "taskOperator.simplifiedGroundControlPoint.form.fields.description.label",
            "placeholder": "taskOperator.simplifiedGroundControlPoint.form.fields.description.placeholder"
          }
        },
        "buttonSave": "taskOperator.simplifiedGroundControlPoint.form.buttonSave",
        "buttonCancel": "taskOperator.simplifiedGroundControlPoint.form.buttonCancel",
        "messages": {
          "submitSuccess": "taskOperator.simplifiedGroundControlPoint.form.messages.submitSuccess",
          "submitError": "taskOperator.simplifiedGroundControlPoint.form.messages.submitError"
        }
      }
    }
  },
  "taskSurveyor": {
    "buttonBack": "taskSurveyor.buttonBack",
    "buttonPrint": "taskSurveyor.buttonPrint",
    "buttonDownload": "taskSurveyor.buttonDownload",
    "titlePrint": "taskSurveyor.titlePrint",
    "geodeticSurveyUpload": {
      "title": "taskSurveyor.geodeticSurveyUpload.title",
      "description": "taskSurveyor.geodeticSurveyUpload.description"
    },
    "geodeticSurveyAdditionalUpload": {
      "title": "taskSurveyor.geodeticSurveyAdditionalUpload.title",
      "description": "taskSurveyor.geodeticSurveyAdditionalUpload.description"
    },
    "alerts": {
      "returned": {
        "title": "taskSurveyor.alerts.returned.title"
      }
    }
  },
  "taskThreeDOperator": {
    "buttonBack": "taskThreeDOperator.buttonBack",
    "buttonDownload": "taskThreeDOperator.buttonDownload",
    "modelUpload": {
      "title": "taskThreeDOperator.modelUpload.title",
      "fileUpload": {
        "model": {
          "title": "taskThreeDOperator.modelUpload.fileUpload.model.title",
          "description": "taskThreeDOperator.modelUpload.fileUpload.model.description"
        },
        "texture": {
          "title": "taskThreeDOperator.modelUpload.fileUpload.texture.title",
          "description": "taskThreeDOperator.modelUpload.fileUpload.texture.description"
        },
        "coordinates": {
          "title": "taskThreeDOperator.modelUpload.fileUpload.coordinates.title",
          "description": "taskThreeDOperator.modelUpload.fileUpload.coordinates.description"
        }
      },
      "buttonViewModel": "taskThreeDOperator.modelUpload.buttonViewModel",
      "coordinateSystemSelect": {
        "title": "taskThreeDOperator.modelUpload.coordinateSystemSelect.title",
        "label": "taskThreeDOperator.modelUpload.coordinateSystemSelect.label"
      }
    },
    "threeDModelUploadOverview": {
      "title": "taskThreeDOperator.threeDModelUploadOverview.title",
      "modelFile": "taskThreeDOperator.threeDModelUploadOverview.modelFile",
      "coordinateSystemFile": "taskThreeDOperator.threeDModelUploadOverview.coordinateSystemFile",
      "textureFile": "taskThreeDOperator.threeDModelUploadOverview.textureFile"
    },
    "threeDModelInfo": {
      "title": "taskThreeDOperator.threeDModelInfo.title",
      "type": "taskThreeDOperator.threeDModelInfo.type",
      "createdAt": "taskThreeDOperator.threeDModelInfo.createdAt",
      "size": "taskThreeDOperator.threeDModelInfo.size",
      "coordinateSystemType": "taskThreeDOperator.threeDModelInfo.coordinateSystemType"
    },
    "threeDModelFilesUpload": {
      "title": "taskThreeDOperator.threeDModelFilesUpload.title",
      "description": "taskThreeDOperator.threeDModelFilesUpload.description",
      "buttonSave": "taskThreeDOperator.threeDModelFilesUpload.buttonSave",
      "message": {
        "success": "taskThreeDOperator.threeDModelFilesUpload.message.success"
      }
    },
    "outputExtensions": {
      "title": "taskThreeDOperator.outputExtensions.title",
      "outputExtensionNumber": {
        "label": "taskThreeDOperator.outputExtensions.outputExtensionNumber.label"
      },
      "author": {
        "label": "taskThreeDOperator.outputExtensions.author.label"
      },
      "attachmentsUpload": {
        "title": "taskThreeDOperator.outputExtensions.attachmentsUpload.title",
        "description": "taskThreeDOperator.outputExtensions.attachmentsUpload.description"
      },
      "uploadedAttachments": {
        "title": "taskThreeDOperator.outputExtensions.uploadedAttachments.title"
      },
      "createdAt": {
        "label": "taskThreeDOperator.outputExtensions.createdAt.label"
      },
      "authorComment": {
        "label": "taskThreeDOperator.outputExtensions.authorComment.label"
      },
      "comment": {
        "label": "taskThreeDOperator.outputExtensions.comment.label",
        "description": "taskThreeDOperator.outputExtensions.comment.description",
        "formElement": {
          "placeholder": "taskThreeDOperator.outputExtensions.comment.formElement.placeholder"
        }
      },
      "editModeToggle": {
        "buttonEdit": "taskThreeDOperator.outputExtensions.editModeToggle.buttonEdit",
        "buttonSave": "taskThreeDOperator.outputExtensions.editModeToggle.buttonSave"
      }
    }
  },
  "additionalServices": {
    "header": {
      "title": "additionalServices.header.title"
    },
    "activeInquiries": {
      "title": "additionalServices.activeInquiries.title",
      "noData": {
        "default": "additionalServices.activeInquiries.noData.default",
        "readOnly": "additionalServices.activeInquiries.noData.readOnly"
      },
      "table": {
        "inquiryNumber": "additionalServices.activeInquiries.table.inquiryNumber",
        "created": "additionalServices.activeInquiries.table.created",
        "state": "additionalServices.activeInquiries.table.state",
        "archiving": "additionalServices.activeInquiries.table.archiving",
        "inquiry": "additionalServices.activeInquiries.table.inquiry",
        "nodesInfo": "additionalServices.activeInquiries.table.nodesInfo"
      }
    },
    "inquiriesForm": {
      "title": "additionalServices.inquiriesForm.title",
      "option": {
        "help": "additionalServices.inquiriesForm.option.help",
        "placeholder": "additionalServices.inquiriesForm.option.placeholder"
      },
      "comment": {
        "label": "additionalServices.inquiriesForm.comment.label",
        "placeholder": "additionalServices.inquiriesForm.comment.placeholder"
      },
      "submit": "additionalServices.inquiriesForm.submit",
      "messages": {
        "successSend": "additionalServices.inquiriesForm.messages.successSend"
      }
    }
  },
  "additionalServicesReview": {
    "header": {
      "title": "additionalServicesReview.header.title"
    },
    "tabs": {
      "pendingInquiries": "additionalServicesReview.tabs.pendingInquiries",
      "resolvedInquiries": "additionalServicesReview.tabs.resolvedInquiries"
    },
    "pendingInquiries": {
      "noData": "additionalServicesReview.pendingInquiries.noData",
      "table": {
        "inquiryNumber": "additionalServicesReview.pendingInquiries.table.inquiryNumber",
        "additionalServiceType": "additionalServicesReview.pendingInquiries.table.additionalServiceType",
        "requestedBy": "additionalServicesReview.pendingInquiries.table.requestedBy",
        "organization": "additionalServicesReview.pendingInquiries.table.organization",
        "createdAt": "additionalServicesReview.pendingInquiries.table.createdAt",
        "inquiryName": "additionalServicesReview.pendingInquiries.table.inquiryName"
      }
    },
    "resolvedInquiries": {
      "noData": "additionalServicesReview.resolvedInquiries.noData",
      "table": {
        "inquiryNumber": "additionalServicesReview.resolvedInquiries.table.inquiryNumber",
        "additionalServiceType": "additionalServicesReview.resolvedInquiries.table.additionalServiceType",
        "requestedBy": "additionalServicesReview.resolvedInquiries.table.requestedBy",
        "organization": "additionalServicesReview.resolvedInquiries.table.organization",
        "createdAt": "additionalServicesReview.resolvedInquiries.table.createdAt",
        "status": "additionalServicesReview.resolvedInquiries.table.status",
        "archivesAt": "additionalServicesReview.resolvedInquiries.table.archivesAt",
        "inquiryName": "additionalServicesReview.resolvedInquiries.table.inquiryName"
      }
    }
  },
  "inquiry": {
    "actions": {
      "backButton": "inquiry.actions.backButton"
    },
    "progress": {
      "title": "inquiry.progress.title",
      "body": {
        "inProgress": "inquiry.progress.body.inProgress",
        "resolverComment": {
          "label": "inquiry.progress.body.resolverComment.label"
        },
        "attachments": {
          "label": "inquiry.progress.body.attachments.label"
        }
      }
    },
    "inquiryDetails": {
      "title": "inquiry.inquiryDetails.title",
      "body": {
        "inquiryNumber": {
          "label": "inquiry.inquiryDetails.body.inquiryNumber.label"
        },
        "userComment": {
          "label": "inquiry.inquiryDetails.body.userComment.label"
        },
        "resolverName": {
          "label": "inquiry.inquiryDetails.body.resolverName.label"
        },
        "createdAt": {
          "label": "inquiry.inquiryDetails.body.createdAt.label"
        },
        "status": {
          "label": "inquiry.inquiryDetails.body.status.label"
        },
        "resolvedAt": {
          "label": "inquiry.inquiryDetails.body.resolvedAt.label"
        },
        "archivesAt": {
          "label": "inquiry.inquiryDetails.body.archivesAt.label",
          "description": "inquiry.inquiryDetails.body.archivesAt.description"
        },
        "requestType": {
          "label": "inquiry.inquiryDetails.body.requestType.label"
        }
      }
    }
  },
  "inquiryReview": {
    "actions": {
      "backButton": "inquiryReview.actions.backButton",
      "declineInquiry": "inquiryReview.actions.declineInquiry",
      "sendInquiry": "inquiryReview.actions.sendInquiry"
    },
    "messages": {
      "inquiryResolved": "inquiryReview.messages.inquiryResolved",
      "inquiryDeclined": "inquiryReview.messages.inquiryDeclined"
    },
    "inquiryDetails": {
      "title": "inquiryReview.inquiryDetails.title",
      "body": {
        "inquiryNumber": {
          "label": "inquiryReview.inquiryDetails.body.inquiryNumber.label"
        },
        "createdBy": {
          "label": "inquiryReview.inquiryDetails.body.createdBy.label"
        },
        "createdAt": {
          "label": "inquiryReview.inquiryDetails.body.createdAt.label"
        },
        "archivesAt": {
          "label": "inquiryReview.inquiryDetails.body.archivesAt.label",
          "description": "inquiryReview.inquiryDetails.body.archivesAt.description"
        },
        "userComment": {
          "label": "inquiryReview.inquiryDetails.body.userComment.label"
        },
        "status": {
          "label": "inquiryReview.inquiryDetails.body.status.label"
        },
        "organization": {
          "label": "inquiryReview.inquiryDetails.body.organization.label"
        },
        "resolvedAt": {
          "label": "inquiryReview.inquiryDetails.body.resolvedAt.label"
        },
        "requestType": {
          "label": "inquiryReview.inquiryDetails.body.requestType.label"
        }
      }
    },
    "progressPending": {
      "title": "inquiryReview.progressPending.title",
      "body": {
        "attachmentUpload": {
          "title": "inquiryReview.progressPending.body.attachmentUpload.title",
          "label": "inquiryReview.progressPending.body.attachmentUpload.label"
        },
        "comment": {
          "title": "inquiryReview.progressPending.body.comment.title",
          "label": "inquiryReview.progressPending.body.comment.label",
          "placeholder": "inquiryReview.progressPending.body.comment.placeholder"
        }
      }
    },
    "progressResolved": {
      "title": "inquiryReview.progressResolved.title",
      "body": {
        "resolverComment": {
          "label": "inquiryReview.progressResolved.body.resolverComment.label"
        },
        "attachments": {
          "label": "inquiryReview.progressResolved.body.attachments.label"
        }
      }
    }
  },
  "state": {
    "constructionSite": {
      "active": "state.constructionSite.active",
      "inactive": "state.constructionSite.inactive"
    },
    "constructionSiteStateFilter": {
      "active": "state.constructionSiteStateFilter.active",
      "inactive": "state.constructionSiteStateFilter.inactive"
    },
    "documentationRecord": {
      "created": "state.documentationRecord.created",
      "requiresOperator": "state.documentationRecord.requiresOperator",
      "requiresSurveyor": "state.documentationRecord.requiresSurveyor",
      "requiresThreeDOperator": "state.documentationRecord.requiresThreeDOperator",
      "requiresManagerConfirmation": "state.documentationRecord.requiresManagerConfirmation",
      "finished": "state.documentationRecord.finished",
      "rejected": "state.documentationRecord.rejected",
      "archived": "state.documentationRecord.archived"
    },
    "documentationRecordStateFilter": {
      "created": "state.documentationRecordStateFilter.created",
      "requiresOperator": "state.documentationRecordStateFilter.requiresOperator",
      "requiresSurveyor": "state.documentationRecordStateFilter.requiresSurveyor",
      "requiresThreeDOperator": "state.documentationRecordStateFilter.requiresThreeDOperator",
      "requiresManagerConfirmation": "state.documentationRecordStateFilter.requiresManagerConfirmation",
      "finished": "state.documentationRecordStateFilter.finished",
      "rejected": "state.documentationRecordStateFilter.rejected",
      "archived": "state.documentationRecordStateFilter.archived"
    },
    "constructionObject": {
      "active": "state.constructionObject.active",
      "inactive": "state.constructionObject.inactive"
    },
    "geodeticSurveyUploaded": {
      "true": "state.geodeticSurveyUploaded.true",
      "false": "state.geodeticSurveyUploaded.false"
    },
    "documentationRecordReturned": {
      "true": "state.documentationRecordReturned.true",
      "false": "state.documentationRecordReturned.false"
    },
    "inquiry": {
      "created": "state.inquiry.created",
      "completed": "state.inquiry.completed",
      "rejected": "state.inquiry.rejected",
      "archived": "state.inquiry.archived"
    },
    "inquiryStateFilter": {
      "created": "state.inquiryStateFilter.created",
      "completed": "state.inquiryStateFilter.completed",
      "rejected": "state.inquiryStateFilter.rejected",
      "archived": "state.inquiryStateFilter.archived"
    },
    "constructionObjectStateFilter": {
      "active": "state.constructionObjectStateFilter.active",
      "inactive": "state.constructionObjectStateFilter.inactive"
    },
    "userExternalState": {
      "blocked": "state.userExternalState.blocked"
    }
  },
  "type": {
    "documentationRecord": {
      "noThreeDNoVideo": "type.documentationRecord.noThreeDNoVideo",
      "noThreeDVideo": "type.documentationRecord.noThreeDVideo",
      "threeDNoScale": "type.documentationRecord.threeDNoScale",
      "threeDScale": "type.documentationRecord.threeDScale"
    },
    "documentationRecordTypeFilter": {
      "noThreeDNoVideo": "type.documentationRecordTypeFilter.noThreeDNoVideo",
      "noThreeDVideo": "type.documentationRecordTypeFilter.noThreeDVideo",
      "threeDNoScale": "type.documentationRecordTypeFilter.threeDNoScale",
      "threeDScale": "type.documentationRecordTypeFilter.threeDScale"
    },
    "documentationRecordVideo": {
      "default": "type.documentationRecordVideo.default",
      "noScale": "type.documentationRecordVideo.noScale",
      "scale": "type.documentationRecordVideo.scale",
      "maps": "type.documentationRecordVideo.maps",
      "spray": "type.documentationRecordVideo.spray",
      "qrCodes": "type.documentationRecordVideo.qrCodes"
    },
    "documentationRecordVideoTypeFilter": {
      "none": "type.documentationRecordVideoTypeFilter.none",
      "noScale": "type.documentationRecordVideoTypeFilter.noScale",
      "scale": "type.documentationRecordVideoTypeFilter.scale",
      "maps": "type.documentationRecordVideoTypeFilter.maps",
      "spray": "type.documentationRecordVideoTypeFilter.spray",
      "qrCodes": "type.documentationRecordVideoTypeFilter.qrCodes"
    },
    "threeDModel": {
      "simple": "type.threeDModel.simple",
      "scale": "type.threeDModel.scale",
      "maps": "type.threeDModel.maps",
      "spray": "type.threeDModel.spray",
      "qrCodes": "type.threeDModel.qrCodes"
    }
  },
  "form": {
    "required": "form.required",
    "defaultValues": {
      "noSelected": "form.defaultValues.noSelected"
    }
  },
  "groundPoint": {
    "prefix": "groundPoint.prefix",
    "location": {
      "label": "groundPoint.location.label",
      "copyToClipboard": "groundPoint.location.copyToClipboard"
    },
    "description": {
      "label": "groundPoint.description.label"
    }
  },
  "credit": {
    "singularNominative": "credit.singularNominative",
    "nominativePlural": "credit.nominativePlural",
    "genitivePlural": "credit.genitivePlural",
    "zero": "credit.zero",
    "singularGenitive": "credit.singularGenitive"
  },
  "from": "from",
  "bytes": {
    "singularNominative": "bytes.singularNominative",
    "nominativePlural": "bytes.nominativePlural",
    "genitivePlural": "bytes.genitivePlural",
    "zero": "bytes.zero"
  },
  "storage": {
    "usedStorage": "storage.usedStorage",
    "planVariant": {
      "free": "storage.planVariant.free",
      "paidCredits": "storage.planVariant.paidCredits"
    }
  },
  "table": {
    "search": {
      "placeholder": "table.search.placeholder",
      "buttonSearch": "table.search.buttonSearch",
      "buttonReset": "table.search.buttonReset"
    },
    "actions": {
      "show": "table.actions.show",
      "delete": "table.actions.delete",
      "share": "table.actions.share",
      "download": "table.actions.download"
    },
    "threeDModelActions": {
      "show": "table.threeDModelActions.show",
      "delete": "table.threeDModelActions.delete",
      "share": "table.threeDModelActions.share",
      "download": "table.threeDModelActions.download"
    }
  },
  "radioCard": {
    "buttonSelectText": "radioCard.buttonSelectText",
    "buttonSelectedText": "radioCard.buttonSelectedText"
  },
  "threeDModel": {
    "actions": {
      "show": "threeDModel.actions.show",
      "delete": "threeDModel.actions.delete",
      "share": "threeDModel.actions.share",
      "download": "threeDModel.actions.download"
    }
  },
  "map": {
    "noDataText": "map.noDataText",
    "buttons": {
      "startMark": "map.buttons.startMark",
      "cancelAllSelection": "map.buttons.cancelAllSelection",
      "back": "map.buttons.back",
      "cancel": "map.buttons.cancel",
      "saveSelection": "map.buttons.saveSelection",
      "buttonDeleteMark": "map.buttons.buttonDeleteMark"
    },
    "form": {
      "gps": {
        "label": "map.form.gps.label"
      },
      "groundPoint": {
        "label": "map.form.groundPoint.label",
        "placeholder": "map.form.groundPoint.placeholder"
      }
    },
    "markName": "map.markName",
    "markPlaceholder": "map.markPlaceholder",
    "validations": {
      "invalidCoordinates": "map.validations.invalidCoordinates",
      "invalidCoordinatesCount": "map.validations.invalidCoordinatesCount"
    },
    "searchPlaceholder": "map.searchPlaceholder"
  },
  "modalUnsavedMap": {
    "title": "modalUnsavedMap.title",
    "description": "modalUnsavedMap.description",
    "buttonOkText": "modalUnsavedMap.buttonOkText",
    "buttonCancelText": "modalUnsavedMap.buttonCancelText"
  },
  "support": {
    "header": {
      "title": "support.header.title"
    },
    "contacts": {
      "title": "support.contacts.title",
      "phone": {
        "label": "support.contacts.phone.label"
      },
      "email": {
        "label": "support.contacts.email.label"
      }
    }
  },
  "role": {
    "operator": "role.operator",
    "surveyor": "role.surveyor",
    "manager": "role.manager",
    "reader": "role.reader",
    "administrator": "role.administrator",
    "threeDOperator": "role.threeDOperator",
    "observer": "role.observer"
  },
  "pricing": {
    "header": {
      "title": "pricing.header.title",
      "buttonPrimaryText": "pricing.header.buttonPrimaryText"
    },
    "storage": {
      "title": "pricing.storage.title",
      "table": {
        "header": {
          "limit": "pricing.storage.table.header.limit",
          "price": "pricing.storage.table.header.price"
        },
        "limit": {
          "label": "pricing.storage.table.limit.label"
        }
      }
    },
    "modelRequest": {
      "title": "pricing.modelRequest.title",
      "info1": "pricing.modelRequest.info1",
      "info2": "pricing.modelRequest.info2",
      "info3": "pricing.modelRequest.info3",
      "noScale": "pricing.modelRequest.noScale",
      "scale": "pricing.modelRequest.scale",
      "about3DModels": "pricing.modelRequest.about3DModels",
      "table": {
        "header": {
          "type": "pricing.modelRequest.table.header.type",
          "price": "pricing.modelRequest.table.header.price"
        },
        "noScale": "pricing.modelRequest.table.noScale",
        "scale": "pricing.modelRequest.table.scale",
        "maps": "pricing.modelRequest.table.maps",
        "spray": "pricing.modelRequest.table.spray",
        "qrCodes": "pricing.modelRequest.table.qrCodes"
      }
    },
    "other": {
      "title": "pricing.other.title",
      "table": {
        "header": {
          "request": "pricing.other.table.header.request",
          "price": "pricing.other.table.header.price"
        },
        "createDocumentationRecord": "pricing.other.table.createDocumentationRecord",
        "inviteManager": "pricing.other.table.inviteManager",
        "downloadThreeDModel": "pricing.other.table.downloadThreeDModel"
      }
    },
    "refundOptions": {
      "title": "pricing.refundOptions.title",
      "table": {
        "reject": "pricing.refundOptions.table.reject",
        "return": "pricing.refundOptions.table.return"
      }
    },
    "inquiry": {
      "title": "pricing.inquiry.title",
      "form": {
        "message": {
          "topicsLabel": "pricing.inquiry.form.message.topicsLabel",
          "placeholder": "pricing.inquiry.form.message.placeholder"
        },
        "submit": "pricing.inquiry.form.submit"
      },
      "message": {
        "sent": "pricing.inquiry.message.sent"
      }
    },
    "credits": {
      "free": "pricing.credits.free",
      "per3Months": "pricing.credits.per3Months"
    }
  },
  "uploader": {
    "title": "uploader.title",
    "description": "uploader.description",
    "messages": {
      "uploadFailed": "uploader.messages.uploadFailed",
      "notAllowedFile": "uploader.messages.notAllowedFile"
    }
  },
  "alert": {
    "title": "alert.title",
    "description": "alert.description",
    "errorCode": {
      "label": "alert.errorCode.label"
    },
    "errorTime": {
      "label": "alert.errorTime.label"
    },
    "buttonOkText": "alert.buttonOkText"
  },
  "unsupportedBrowser": "unsupportedBrowser",
  "incompatibleApiVersion": {
    "title": "incompatibleApiVersion.title",
    "description": "incompatibleApiVersion.description",
    "buttonOk": "incompatibleApiVersion.buttonOk"
  },
  "saving": "saving",
  "uploadButton": {
    "description": {
      "video": "uploadButton.description.video",
      "photos": "uploadButton.description.photos",
      "model": "uploadButton.description.model",
      "texture": "uploadButton.description.texture",
      "coordinates": "uploadButton.description.coordinates",
      "file": "uploadButton.description.file"
    },
    "states": {
      "inProgress": "uploadButton.states.inProgress"
    }
  },
  "loading": "loading",
  "copyToClipboard": "copyToClipboard",
  "copyToClipboardSuccess": "copyToClipboardSuccess",
  "dateAndTime": {
    "human": {
      "date": "dateAndTime.human.date",
      "time": "dateAndTime.human.time"
    },
    "machine": {
      "date": "dateAndTime.machine.date",
      "time": "dateAndTime.machine.time"
    }
  },
  "month": {
    "every": {
      "singularNominative": "month.every.singularNominative",
      "nominativePlural": "month.every.nominativePlural",
      "genitivePlural": "month.every.genitivePlural",
      "zero": "month.every.zero"
    },
    "unit": {
      "singularNominative": "month.unit.singularNominative",
      "nominativePlural": "month.unit.nominativePlural",
      "genitivePlural": "month.unit.genitivePlural",
      "zero": "month.unit.zero"
    }
  },
  "mediaKind": {
    "unassigned": "mediaKind.unassigned",
    "documentation": "mediaKind.documentation",
    "threeDModel": "mediaKind.threeDModel",
    "inquiry": "mediaKind.inquiry",
    "outputExtension": "mediaKind.outputExtension"
  },
  "coordinateSystem": {
    "sJtsk": "coordinateSystem.sJtsk",
    "hd72": "coordinateSystem.hd72",
    "dhdn3dGkZ2": "coordinateSystem.dhdn3dGkZ2",
    "dhdn3dGkZ3": "coordinateSystem.dhdn3dGkZ3",
    "dhdn3dGkZ4": "coordinateSystem.dhdn3dGkZ4",
    "dhdn3dGkZ5": "coordinateSystem.dhdn3dGkZ5",
    "wgs84Utm32n": "coordinateSystem.wgs84Utm32n",
    "wgs84Utm33n": "coordinateSystem.wgs84Utm33n",
    "wgs84Utm34n": "coordinateSystem.wgs84Utm34n",
    "wgs84Utm35n": "coordinateSystem.wgs84Utm35n",
    "dhdn31466": "coordinateSystem.dhdn31466",
    "dhdn31467": "coordinateSystem.dhdn31467",
    "dhdn31468": "coordinateSystem.dhdn31468",
    "ch1903plusLv95": "coordinateSystem.ch1903plusLv95",
    "dhdn": "coordinateSystem.dhdn",
    "etrs89polandCs20005": "coordinateSystem.etrs89polandCs20005",
    "etrs89polandCs20006": "coordinateSystem.etrs89polandCs20006",
    "etrs89polandCs20007": "coordinateSystem.etrs89polandCs20007",
    "etrs89polandCs20008": "coordinateSystem.etrs89polandCs20008",
    "etrs89utm32n": "coordinateSystem.etrs89utm32n",
    "etrs89utm33n": "coordinateSystem.etrs89utm33n",
    "unknown": "coordinateSystem.unknown",
    "wgs84": "coordinateSystem.wgs84",
    "wgs84utm32n": "coordinateSystem.wgs84utm32n",
    "wgs84utm34n": "coordinateSystem.wgs84utm34n",
    "wgs84utm35n": "coordinateSystem.wgs84utm35n",
    "wgs84utm36n": "coordinateSystem.wgs84utm36n",
    "wgs84utm37n": "coordinateSystem.wgs84utm37n"
  },
  "taskName": {
    "created": "taskName.created",
    "requiresOperator": "taskName.requiresOperator",
    "requiresSurveyor": "taskName.requiresSurveyor",
    "requiresThreeDOperator": "taskName.requiresThreeDOperator",
    "requiresManagerConfirmation": "taskName.requiresManagerConfirmation",
    "finished": "taskName.finished",
    "rejected": "taskName.rejected",
    "archived": "taskName.archived"
  },
  "zipDownload": {
    "defaultFileName": "zipDownload.defaultFileName",
    "pointsFolderName": "zipDownload.pointsFolderName",
    "surveyFolderName": "zipDownload.surveyFolderName",
    "processing": "zipDownload.processing",
    "modal": {
      "title": "zipDownload.modal.title",
      "buttonOkText": "zipDownload.modal.buttonOkText"
    }
  },
  "downloads": {
    "video": "downloads.video",
    "image": "downloads.image",
    "file": "downloads.file"
  },
  "lightbox": {
    "title": {
      "video": "lightbox.title.video",
      "image": "lightbox.title.image"
    },
    "compatibilityInfo": {
      "video": "lightbox.compatibilityInfo.video"
    }
  },
  "mainMenu": {
    "todo": "mainMenu.todo",
    "documentationRecords": "mainMenu.documentationRecords",
    "threeDModels": "mainMenu.threeDModels",
    "threeDModelsCompleted": "mainMenu.threeDModelsCompleted",
    "constructionSites": "mainMenu.constructionSites",
    "organization": "mainMenu.organization",
    "organizations": "mainMenu.organizations",
    "pricing": "mainMenu.pricing",
    "about": "mainMenu.about",
    "additionalServices": "mainMenu.additionalServices",
    "support": "mainMenu.support",
    "todoAll": "mainMenu.todoAll"
  },
  "conjunctions": {
    "or": "conjunctions.or"
  },
  "version": {
    "actually": {
      "label": "version.actually.label"
    },
    "update": {
      "description": "version.update.description",
      "button": {
        "text": "version.update.button.text"
      }
    }
  },
  "typenames": {
    "documentationRecord": "typenames.documentationRecord",
    "constructionSite": "typenames.constructionSite",
    "constructionObject": "typenames.constructionObject",
    "submitter": "typenames.submitter",
    "documentationState": "typenames.documentationState",
    "documentationType": "typenames.documentationType",
    "videoType": "typenames.videoType",
    "threeDModel": "typenames.threeDModel",
    "constructionPhase": "typenames.constructionPhase"
  },
  "searchSelect": {
    "common": {
      "resultInfo": {
        "singularNominative": "searchSelect.common.resultInfo.singularNominative",
        "nominativePlural": "searchSelect.common.resultInfo.nominativePlural",
        "genitivePlural": "searchSelect.common.resultInfo.genitivePlural",
        "zero": "searchSelect.common.resultInfo.zero"
      },
      "noResult": "searchSelect.common.noResult"
    },
    "constructionSite": {
      "placeholder": "searchSelect.constructionSite.placeholder"
    },
    "constructionObject": {
      "placeholder": "searchSelect.constructionObject.placeholder",
      "noData": "searchSelect.constructionObject.noData",
      "noDataForManager": "searchSelect.constructionObject.noDataForManager"
    },
    "constructionPhase": {
      "placeholder": "searchSelect.constructionPhase.placeholder",
      "noData": "searchSelect.constructionPhase.noData"
    }
  },
  "todoAll": {
    "header": {
      "title": "todoAll.header.title"
    },
    "tasksOverview": {
      "title": "todoAll.tasksOverview.title",
      "table": {
        "header": {
          "createdAt": "todoAll.tasksOverview.table.header.createdAt",
          "processedBy": "todoAll.tasksOverview.table.header.processedBy"
        }
      }
    }
  },
  "header": {
    "menu": {
      "whyMawisPhoto": "header.menu.whyMawisPhoto",
      "howItWorks": "header.menu.howItWorks",
      "buy": "header.menu.buy",
      "login": "header.menu.login"
    }
  },
  "homepage": {
    "welcomeBlock": {
      "title": "homepage.welcomeBlock.title",
      "description": "homepage.welcomeBlock.description",
      "buttonMore": "homepage.welcomeBlock.buttonMore"
    },
    "whyMawisPhoto": {
      "title": "homepage.whyMawisPhoto.title",
      "overviewAndAvailability": {
        "title": "homepage.whyMawisPhoto.overviewAndAvailability.title",
        "content": "homepage.whyMawisPhoto.overviewAndAvailability.content"
      },
      "organizationAndControl": {
        "title": "homepage.whyMawisPhoto.organizationAndControl.title",
        "content": "homepage.whyMawisPhoto.organizationAndControl.content"
      },
      "cooperationAndCommunication": {
        "title": "homepage.whyMawisPhoto.cooperationAndCommunication.title",
        "content": "homepage.whyMawisPhoto.cooperationAndCommunication.content"
      },
      "dataAndAccuracy": {
        "title": "homepage.whyMawisPhoto.dataAndAccuracy.title",
        "content": "homepage.whyMawisPhoto.dataAndAccuracy.content"
      },
      "easyOperation": {
        "title": "homepage.whyMawisPhoto.easyOperation.title",
        "content": "homepage.whyMawisPhoto.easyOperation.content"
      },
      "fairPrice": {
        "title": "homepage.whyMawisPhoto.fairPrice.title",
        "content": "homepage.whyMawisPhoto.fairPrice.content"
      }
    },
    "howItWorks": {
      "title": "homepage.howItWorks.title",
      "webApp": {
        "title": "homepage.howItWorks.webApp.title",
        "content": "homepage.howItWorks.webApp.content"
      },
      "mobileApp": {
        "title": "homepage.howItWorks.mobileApp.title",
        "content": "homepage.howItWorks.mobileApp.content"
      },
      "diagram": {
        "titles": {
          "terrain": "homepage.howItWorks.diagram.titles.terrain",
          "office": "homepage.howItWorks.diagram.titles.office",
          "outputs": "homepage.howItWorks.diagram.titles.outputs"
        },
        "blocks": {
          "videoRecord": "homepage.howItWorks.diagram.blocks.videoRecord",
          "photoDocumentation": "homepage.howItWorks.diagram.blocks.photoDocumentation",
          "authorization": "homepage.howItWorks.diagram.blocks.authorization",
          "constructionSiteInit": "homepage.howItWorks.diagram.blocks.constructionSiteInit",
          "documentationRecordCreate": "homepage.howItWorks.diagram.blocks.documentationRecordCreate",
          "dataInClouds": "homepage.howItWorks.diagram.blocks.dataInClouds",
          "threeDModel": "homepage.howItWorks.diagram.blocks.threeDModel",
          "orthophoto": "homepage.howItWorks.diagram.blocks.orthophoto",
          "texture": "homepage.howItWorks.diagram.blocks.texture",
          "points": "homepage.howItWorks.diagram.blocks.points"
        }
      }
    },
    "contact": {
      "title": "homepage.contact.title",
      "byPhone": {
        "title": "homepage.contact.byPhone.title",
        "description": "homepage.contact.byPhone.description"
      },
      "byEmail": {
        "title": "homepage.contact.byEmail.title",
        "description": "homepage.contact.byEmail.description"
      },
      "byEshop": {
        "title": "homepage.contact.byEshop.title",
        "linkText": "homepage.contact.byEshop.linkText",
        "description": "homepage.contact.byEshop.description"
      },
      "byXing": {
        "title": "homepage.contact.byXing.title",
        "linkText": "homepage.contact.byXing.linkText",
        "description": "homepage.contact.byXing.description"
      }
    }
  },
  "failedFetchAlert": {
    "message": "failedFetchAlert.message"
  },
  "defaultConstructionPhaseName": "defaultConstructionPhaseName",
  "next": "next",
  "autoSave": {
    "info": "autoSave.info",
    "saving": "autoSave.saving",
    "saved": "autoSave.saved"
  },
  "errors": {
    "notEnoughCredits": "errors.notEnoughCredits"
  },
  "warning": "warning"
};