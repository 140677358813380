import { cloneElement, PropsWithChildren, ReactElement, useMemo } from "react";
import { Form, Modal, Radio, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { ParseMD } from "helpers/ParseMD";
import {
  LeaveOrganizationActionType,
  LeaveOrganizationFormFields,
  useOrganizationLeave
} from "hooks/useOrganizationLeave";
import { AlertLeave } from "components/Organization/AlertLeave";
import { roles } from "api/enums";

type Props = {
  children: ReactElement;
  organizationId?: string;
};

export const ModalLeave = ({ children, organizationId }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const {
    loading,
    sending,
    disabled,
    form,

    open,

    data,

    handlers
  } = useOrganizationLeave(organizationId);

  const textDescription = useMemo<string>(
    () =>
      data.actionType === LeaveOrganizationActionType.leaveAndDelete
        ? t(lg.organization.modals.leave.description.leaveAndDelete)
        : data.actionType === LeaveOrganizationActionType.leave
          ? t(lg.organization.modals.leave.description.leave, {
              organization: data?.organization?.name
            })
          : data.actionType === LeaveOrganizationActionType.assignAndLeave
            ? t(lg.organization.modals.leave.description.assignAndLeave, {
                role: data.me.role ? t(roles[data.me.role].nameI18nKey) : "-"
              })
            : "",
    [t, data]
  );

  const textOk = useMemo<string>(() => {
    if (loading) return t(lg.loading);
    if (data.actionType === LeaveOrganizationActionType.assignAndLeave) {
      return t(lg.organization.modals.leave.buttonOkText.assignAndLeave);
    }
    if (data.actionType === LeaveOrganizationActionType.leave) {
      return t(lg.organization.modals.leave.buttonOkText.leave);
    }
    if (data.actionType === LeaveOrganizationActionType.leaveAndDelete) {
      return t(lg.organization.modals.leave.buttonOkText.leaveAndDelete);
    }
    return "";
  }, [t, data.actionType, loading]);

  const textWarning = useMemo<string>(
    () =>
      data.actionType === LeaveOrganizationActionType.leaveAndDelete
        ? t(lg.organization.modals.leave.warningLastManager)
        : t(lg.organization.modals.leave.warning),
    [t, data.actionType]
  );

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center">
            <CloseCircleOutlined className="text-lg text-red-600" />
            <span className="ml-4">
              {t(lg.organization.modals.leave.title, {
                organization: data?.organization?.name
              })}
            </span>
          </div>
        }
        open={data.visible}
        okText={textOk}
        cancelText={t(lg.organization.modals.leave.buttonCancelText)}
        okButtonProps={{ danger: true, disabled: !data.canSend || disabled || loading }}
        cancelButtonProps={{ disabled: disabled }}
        onOk={handlers.handleOk}
        onCancel={handlers.handleCancel}
        closable={false}
        afterClose={handlers.handleAfterClose}
        confirmLoading={loading || sending}
      >
        {loading ? (
          <div className="flex justify-center w-full">
            <Spin />
          </div>
        ) : (
          <>
            <div>
              <ParseMD>{textDescription}</ParseMD>
            </div>

            {data.actionType === LeaveOrganizationActionType.assignAndLeave && (
              <Form form={form} onFieldsChange={handlers.handleChange} className="mt-3">
                <Form.Item name={LeaveOrganizationFormFields.userId}>
                  <Radio.Group className="mt-3">
                    {data?.availableAccountableManagers.map((user, index) => (
                      <Radio key={user.id} value={user.id} className={clsx("block", index > 0 ? "mt-3" : "")}>
                        {user.firstName} {user.lastName}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Form>
            )}

            {/* Warning */}
            <div className="flex items-center mt-5">
              <InfoCircleOutlined className="text-red-600 text-lg" />
              <span className="font-medium ml-2">{textWarning}</span>
            </div>
          </>
        )}
      </Modal>

      <AlertLeave visible={data.visibleAlert} onOk={handlers.handleOkAlertLeaveModal} />
    </>
  );
};
