import { PropsWithChildren, useCallback, useMemo } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button, message } from "antd";
import { DeleteOutlined, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Content } from "components/LayoutDashboard";
import { Map } from "components/Map";
import { useConstructionObject, useFormat, usePermissions, useSubheader } from "hooks";
import { routes, setRouteParams } from "routes";
import { Section } from "components/Section";
import { GeneralInfoForm } from "components/ConstructionObject/GeneralInfoForm";
import { Delete } from "components/ConstructionObject/modals/Delete";
import { RoleChange } from "components/ConstructionObject/modals/RoleChange";
import { ModalUnsavedMap } from "components/ConstructionSites/ModalUnsavedMap";
import { lg } from "assets/translations/lg";
import { StateToggle } from "components/ConstructionSiteEdit/StateToggle";

export const ConstructionObjectEdit = ({
  constructionSiteId,
  id
}: PropsWithChildren<
  RouteComponentProps<{
    constructionSiteId: string;
    id: string;
  }>
>) => {
  if (!constructionSiteId || !id) {
    throw new Error("Missing construction site or construction object ID.");
  }

  const { t } = useTranslation();
  const { arrayToCoords } = useFormat();

  const { form, loading, sending, disabled, state, data, handlers } = useConstructionObject(
    constructionSiteId,
    "edit",
    id
  );

  const { canDeleteConstructionObject } = usePermissions({
    constructionObject: { managerId: data.constructionObject?.accountableManager?.id }
  });

  /** Subheader init and rerender */
  const subheaderOptions = useMemo(() => {
    const constructionSiteName = data.constructionObject?.constructionSite?.name || "...";
    const name = data.constructionObject?.name || "...";

    return {
      heading: `${t(lg.constructionObject.pageEdit.header.title)}: ${name}`,
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        {
          value: constructionSiteName,
          route: setRouteParams(routes.constructionSiteDetail, { id: constructionSiteId })
        },
        { value: name, route: setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id }) },
        { value: t(lg.constructionObject.pageEdit.header.title) }
      ],
      buttons: [
        <Button key="abort" type={"default"} size={"large"} disabled={disabled} onClick={handlers.handleClickAbort}>
          {t(lg.constructionObject.pageEdit.header.buttonSecondaryText)}
        </Button>,
        <Button
          key="save"
          type={"primary"}
          size={"large"}
          icon={!loading ? <SaveOutlined /> : <LoadingOutlined />}
          disabled={disabled || !data.canSend || !data.hasValidCoords}
          onClick={handlers.handleClickSave}
          loading={sending}
        >
          {t(!sending ? lg.constructionObject.pageEdit.header.buttonPrimaryText : lg.saving)}
        </Button>
      ]
    };
  }, [
    constructionSiteId,
    id,
    t,
    loading,
    disabled,
    sending,
    data.canSend,
    data.hasValidCoords,
    data.constructionObject,
    handlers.handleClickAbort,
    handlers.handleClickSave
  ]);
  useSubheader(subheaderOptions);

  const handleDeleteSuccess = useCallback(() => {
    message.success(t(lg.constructionObject.pageEdit.messages.objectWasDeleted));
    navigate && navigate(setRouteParams(routes.constructionSiteDetail, { id: constructionSiteId }));
  }, [constructionSiteId, t]);

  return (
    <Content loading={loading}>
      {/*
       Actions
       */}
      <section className="flex flex-wrap md:justify-between">
        <div>
          {canDeleteConstructionObject && (
            <Delete id={id} name={data.constructionObject?.name || ""} onSuccess={handleDeleteSuccess}>
              <Button icon={<DeleteOutlined />} danger type="primary" size="large" disabled={disabled}>
                {t(lg.constructionObject.pageEdit.actions.buttonDeleteText)}
              </Button>
            </Delete>
          )}
        </div>

        <StateToggle
          isActive={data.isObjectActive}
          disabled={disabled}
          onChange={handlers.handleChangeState}
          description={
            data.isObjectActive
              ? t(lg.constructionObject.pageEdit.actions.objectStatusActive)
              : t(lg.constructionObject.pageEdit.actions.objectStatusInactive)
          }
        />
      </section>

      {/*
       Form section
       */}
      <Section title={t(lg.constructionObject.pageEdit.baseInformation.title)} className="mt-10">
        <div className="mt-10">
          <GeneralInfoForm
            formRef={form}
            disabled={disabled}
            onChange={handlers.handleFormChange}
            onFinish={handlers.handleSubmit}
            users={data.organization.users}
          />
        </div>
      </Section>

      {/*
       Map section
       */}
      <Section title={t(lg.constructionObject.pageEdit.map.title)} className="mt-16">
        <div className="mt-10">
          <Map
            editable={true}
            editType="object"
            saveDisabled={disabled || state.hasSavedCoords}
            constructionSite={arrayToCoords(data.constructionObject?.constructionSite?.location || [])}
            constructionObject={arrayToCoords(data.constructionObject?.location || [])}
            hasValidCoords={data.hasValidCoords}
            onChange={handlers.handleMapChange}
            onSave={handlers.handleMapSave}
          />
        </div>
      </Section>

      {/*
      Modal
      */}
      <RoleChange
        visible={state.roleChangeModalVisible}
        onOk={handlers.handleOkModalRoleChange}
        onCancel={handlers.handleCancelModalRoleChange}
      />
      {/* warning before save - unsaved map */}
      <ModalUnsavedMap
        visible={state.isVisibleModalCoords}
        onOk={handlers.handleClickCoordsModalOk}
        onCancel={handlers.handleClickCoordsModalCancel}
      />
    </Content>
  );
};
