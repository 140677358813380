import { RouteComponentProps } from "@reach/router";
import { LayoutSimple } from "components/LayoutSimple";
import { Button, Select } from "antd";
import { useTitle } from "react-use";
import { PurchaseDetailContentType, usePurchase } from "hooks";
import { lg } from "assets/translations";
import { Trans, useTranslation } from "react-i18next";
import { ParseMD } from "helpers";
import { Illustration } from "components/Illustration";

export const Purchase = ({ id }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing purchase ID.");

  const [t] = useTranslation();
  useTitle(t(lg.purchases.pageDetail.heading));

  const { loading, sending, disabled, data, handlers } = usePurchase(id);

  return (
    <LayoutSimple
      loading={loading}
      childLeft={
        <Illustration
          type="ill-fail2"
          className="max-w-full h-64 mx-auto md:h-auto"
          title={t(lg.purchases.pageDetail.heading)}
        />
      }
      childRight={
        <div className="flex flex-col items-center md:items-start text-purple-200">
          <div className="max-w-full">
            {
              /* user is member of one organization */
              data.contentType === PurchaseDetailContentType.Base && (
                <>
                  <div className="text-lg md:text-2xl text-center md:text-left md:leading-snug">
                    <ParseMD>
                      {t(lg.purchases.pageDetail.organization, { organization: data?.organizations?.[0]?.name })}
                    </ParseMD>
                  </div>

                  <div className="text-base mt-20">
                    <ParseMD>
                      {t(lg.purchases.pageDetail.description.claimToOrganization, {
                        organization: data?.organizations?.[0]?.name
                      })}
                    </ParseMD>
                  </div>
                </>
              )
            }

            {
              /* user is member of more than one organization */
              data.contentType === PurchaseDetailContentType.MoreOrganizations && (
                <>
                  <div className="text-base text-center md:text-left md:leading-snug">
                    <ParseMD>{t(lg.purchases.pageDetail.description.memberOfMoreOrganizations)}</ParseMD>
                  </div>

                  <div className="text-base mt-20">
                    <span className="mr-3">
                      <ParseMD>{t(lg.purchases.pageDetail.form.claimToOrganization.label)}</ParseMD>
                    </span>
                    <Select
                      defaultValue={data.organizations?.[0]?.id}
                      onChange={handlers.handleChangeSelectOrganization}
                      className="w-64"
                      size={"small"}
                    >
                      {data.organizations?.map(organization => (
                        <Select.Option key={organization.id} value={organization.id}>
                          {organization.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </>
              )
            }

            {
              /* user is not member of any organization */
              data.contentType === PurchaseDetailContentType.NoOrganization && (
                <>
                  <div className="text-base text-center md:text-left md:leading-snug whitespace-pre-line">
                    <ParseMD>
                      {t(lg.purchases.pageDetail.description.noOrganization, {
                        crn: data.defaultOrganization?.crn || "-",
                        organization: data.defaultOrganization?.name
                      })}
                    </ParseMD>
                  </div>
                </>
              )
            }

            <div className="mt-5">
              <Button
                onClick={handlers.handleClickClaimPurchase}
                type={"primary"}
                size={"large"}
                loading={sending}
                disabled={disabled}
              >
                {t(lg.purchases.pageDetail.buttonClaimText)}
              </Button>
              <Button
                onClick={handlers.handleClickNotNow}
                type={"default"}
                size={"large"}
                className="ml-5"
                ghost
                disabled={disabled}
              >
                {t(lg.purchases.pageDetail.buttonNotNowText)}
              </Button>
            </div>

            <div className="mt-10">
              <Trans i18nKey={lg.purchases.pageDetail.description.claimToAnother}>
                {/* Trans component add content to the a element */}
                {/* eslint-disable jsx-a11y/anchor-has-content */}
                <a href={data.helpDeskUrl} target="_blank" rel="noopener noreferrer" className="c-link-light" />
                {/* eslint-enable jsx-a11y/anchor-has-content */}
              </Trans>
            </div>
          </div>
        </div>
      }
    />
  );
};
