import { useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { LayoutSimple } from "components/LayoutSimple";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, message } from "antd";
import { lg } from "assets/translations/lg";
import { useAuth } from "api/auth";
import { useEffectOnce, useTitle } from "react-use";
import { routes, setRouteParams } from "routes";
import { useAlert, useConfig } from "hooks";
import { isFetchError, resolveError, useLoginByCredentialsMutation } from "api";
import { Illustration } from "components/Illustration";
import { AccessErrors } from "api/enums";
import { clearTokenErrorNotify, getTokenErrorNotify, setAuthToken, setRefreshToken } from "api/storage";
import { getEnv } from "helpers";
import { RegisterAppendix } from "components/RegisterAppendix";

const forgotPasswordUrl = getEnv("REACT_APP_FORGOT_PASSWORD_URL");

enum LoginFormItem {
  Username = "username",
  Password = "password"
}

type LoginFormData = Record<LoginFormItem, string>;

export const Login = ({ location, navigate }: RouteComponentProps) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { config, loading: loadingConfig, error } = useConfig("cache-and-network");
  const { authenticate, login, isLoggedIn, impersonate } = useAuth();
  useTitle(t(lg.login.heading));
  const [loadingLoginCheck, setLoadingLoginCheck] = useState(false);

  const loading = useMemo(() => loadingConfig || loadingLoginCheck, [loadingConfig, loadingLoginCheck]);

  const redirect = useCallback(() => navigate && navigate(routes.todo, { replace: true }), [navigate]);

  const handleLoginSuccess = useCallback(redirect, [redirect]);
  const handleLoginError = useCallback(
    (e: any) => {
      resolveError(
        e,
        {
          no_access: () => navigate && navigate(setRouteParams(routes.error, { error: AccessErrors.NoTeamOrLicence })),
          credentials_invalid: () => message.error(t(lg.login.messages.invalidCredentials))
        },
        showAlert
      );
    },
    [t, navigate, showAlert]
  );

  const [form] = Form.useForm<LoginFormData>();

  const [loginByCredentials, { loading: sending }] = useLoginByCredentialsMutation({
    onError: handleLoginError
  });

  const handleFinishForm = useCallback(
    async (data: LoginFormData) => {
      const { username, password } = data;
      try {
        const result = await loginByCredentials({ variables: { input: { username, password } } });
        if (!result?.data?.login?.credentials) return;

        const { token, refreshToken } = result.data.login.credentials;

        setAuthToken(token);
        setRefreshToken(refreshToken);

        handleLoginSuccess();
      } catch (e) {
        // error's handled by mutation 'onError'
        console.log(e);
      }
    },
    [loginByCredentials, handleLoginSuccess]
  );

  useEffectOnce(() => {
    (async function run() {
      setLoadingLoginCheck(true);

      /* logout user if had invalid refreshToken or was problem with refreshing authToken */
      if (getTokenErrorNotify()) {
        clearTokenErrorNotify();
        message.info(t(lg.login.messages.tokenExpired));
      }

      // impersonation check
      if (location?.hash.includes("set_token")) {
        impersonate(location.hash, handleLoginSuccess, handleLoginError);
        return;
      }

      // if logged in (has authToken) - redirect
      if (isLoggedIn() && navigate) {
        await redirect();
        return;
      }

      // try login user without click on Login button
      if (location?.hash.includes("force_authentication")) {
        authenticate();
        return;
      }

      // if not logged and URI contains oAuth hash - login
      if (location?.hash.includes("access_token")) {
        await login(handleLoginSuccess, handleLoginError);
        return;
      }
      setLoadingLoginCheck(false);
    })();
  });

  // handle fetch error
  useEffect(() => {
    if (!error || !isFetchError(error)) return;

    (async function resolveFetchError() {
      message.error({
        key: "failed_to_fetch_error",
        content: (
          <span className="whitespace-normal">
            {t(lg.failedFetchAlert.message, { helpdeskUrl: config.helpDeskUrl })}
          </span>
        ),
        duration: 0
      });
    })();
  }, [t, error, config.helpDeskUrl]);

  return (
    <LayoutSimple
      loading={loading}
      childLeft={
        <Illustration
          type="ill-04"
          className="hidden md:block mx-auto"
          style={{
            maxWidth: 500
          }}
          title={t(lg.login.heading)}
        />
      }
      childRight={
        <div className="max-w-sm mx-auto md:mx-0">
          <div className="text-2xl text-purple-200 text-left leading-snug mb-10">{t(lg.login.heading)}</div>

          <Form<LoginFormData>
            form={form}
            onFinish={handleFinishForm}
            layout={"vertical"}
            className="c-ant-form c-ant-form-light"
            requiredMark={false}
          >
            <Form.Item
              name={LoginFormItem.Username}
              label={t(lg.login.form.username.label)}
              rules={[{ required: true, message: t(lg.login.form.username.messages.required) }]}
            >
              <Input size={"large"} className="mt-1" type={"text"} disabled={sending} />
            </Form.Item>

            <Form.Item
              name={LoginFormItem.Password}
              label={t(lg.login.form.password.label)}
              rules={[{ required: true, message: t(lg.login.form.password.messages.required) }]}
            >
              <Input size={"large"} className="mt-1" type={"password"} disabled={sending} />
            </Form.Item>

            <Form.Item noStyle>
              <Button type="primary" htmlType="submit" className="w-full mt-4" size={"large"} loading={sending}>
                {t(lg.login.buttonText)}
              </Button>
            </Form.Item>
          </Form>

          <RegisterAppendix className={"mt-8"}>
            <a href={forgotPasswordUrl} target="_blank" rel="noopener noreferrer" className="block c-link-light mt-5">
              {t(lg.login.forgotPasswordLinkText)}
            </a>
          </RegisterAppendix>
        </div>
      }
      data-cy={"page-login"}
    />
  );
};
