import { useTranslation } from "react-i18next";
import { Button, Input, InputRef } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import { TableHandleReset, TableHandleSearch } from "components/Table";
import { RefObject } from "react";

type Props = FilterDropdownProps & {
  searchInputRef: RefObject<InputRef>;
  handleSearch: TableHandleSearch;
  handleReset: TableHandleReset;
};

export const TableSearchDropdown = ({
  searchInputRef,
  selectedKeys,
  setSelectedKeys,
  handleSearch,
  handleReset,
  clearFilters,
  confirm
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="p-2">
      <Input
        ref={searchInputRef}
        placeholder={t(lg.table.search.placeholder)}
        onChange={e => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{ display: "block" }}
      />
      <div className="flex justify-between w-48 mt-2">
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          className="mr-2"
        >
          {t(lg.table.search.buttonSearch)}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small">
          {t(lg.table.search.buttonReset)}
        </Button>
      </div>
    </div>
  );
};
