import { CustomIcon, CustomIconType } from "components/icons/CustomIcon";
import clsx from "clsx";
import { classes } from "components/Homepage/classes";

type HowItWorksCardProps = {
  title: string;
  description: string;
  customIconType: CustomIconType;
};
export const HowItWorksCard = ({ title, description, customIconType }: HowItWorksCardProps) => (
  <div className="md:flex md:space-x-10">
    <div className="flex flex-col md:flex-col-reverse md:justify-end">
      <CustomIcon type={customIconType} className="block w-12 text-blue-500 mx-auto md:ml-auto md:mr-0 mt-0 md:mt-5" />
      <h3
        className={clsx(
          classes.h3,
          "text-purple-200 text-center md:text-right md:whitespace-nowrap mb-0 mt-2.5 md:mt-0"
        )}
      >
        {title}
      </h3>
    </div>
    <div className="text-xs text-white leading-relaxed mt-5 md:mt-0">{description}</div>
  </div>
);
