import { PropsWithChildren, useCallback } from "react";
import { MediaCard } from "components/MediaCard";
import { useMediaQuery } from "api";

type Props = {
  id: string; // Media ID
  onRemove: (id: string) => any;
};

export const Photo = ({ id, onRemove }: PropsWithChildren<Props>) => {
  const { data } = useMediaQuery({ variables: { id }, fetchPolicy: "cache-and-network" });

  const handleClickDelete = useCallback(() => onRemove(id), [id, onRemove]);

  if (!data?.media) return null;

  const { fileName, url } = data.media;

  return <MediaCard fileName={fileName || ""} fileUrl={url || ""} fileId={id} onDelete={handleClickDelete} />;
};
