import { PropsWithChildren, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { LoadingOutlined, RollbackOutlined } from "@ant-design/icons";
import { ActionProps } from "components/DocumentationRecord/Actions/index";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import { BackCreatedHandler, ModalBackCreated } from "components/DocumentationRecord/Actions/modals/ModalBackCreated";
import { Triggers } from "api";
import { lg } from "assets/translations";
import { documentationRecordTriggers } from "api/enums";

/** For Trigger BACK_CREATED */
export const BackCreated = ({
  documentationRecordId,
  triggers,
  disabled = false,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {}
}: PropsWithChildren<ActionProps>) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.BackCreated,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    onAfterTrigger
  );

  const handleClick = useCallback(() => setModalVisible(true), []);
  const handleCancel = useCallback(() => setModalVisible(false), []);
  const handleSubmit = useCallback<BackCreatedHandler>(comment => callTrigger(comment), [callTrigger]);

  if (!available) return null;

  return (
    <>
      <Button
        type="default"
        onClick={handleClick}
        size="large"
        icon={!loading ? <RollbackOutlined /> : <LoadingOutlined />}
        disabled={disabled || !conditions || loading}
      >
        {t(!loading ? documentationRecordTriggers[Triggers.BackCreated].buttonI18nKey : lg.saving)}
      </Button>
      <ModalBackCreated visible={modalVisible} loading={loading} onSubmit={handleSubmit} onCancel={handleCancel} />
    </>
  );
};
