import { lg } from "assets/translations";

import { useCallback, useState } from "react";
import { resolveError, useDocumentationRecordUpdateMutation } from "api";
import { Media, useAlert } from "hooks";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { MediaUploaderProps, ModelFilesUploader } from "components/Task/ModelFilesUpload/ModelFilesUploader";

type ThreeDModelFilesProps = {
  documentationRecordId: string;
  organizationId: string;
  initialFiles?: Media[];
  disabled?: boolean;
  className?: string;
};

export const ModelFilesUpload = ({
  documentationRecordId,
  organizationId,
  initialFiles,
  disabled,
  className
}: ThreeDModelFilesProps) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [files, setFiles] = useState<Media[]>(initialFiles || []);

  const handleSuccessSave = useCallback(
    () => message.success(t(lg.taskThreeDOperator.threeDModelFilesUpload.message.success), 5),
    [t]
  );

  const [updateDocumentationRecordFiles, { loading: saving }] = useDocumentationRecordUpdateMutation();
  const [removeFileFromTheDocumentationRecord, { loading: removing }] = useDocumentationRecordUpdateMutation();
  const loading: boolean = saving || removing;

  const saveFiles = useCallback(async () => {
    try {
      const { data } = await updateDocumentationRecordFiles({
        variables: {
          input: {
            documentationRecord: documentationRecordId,
            threeDModel: {
              files: files.map(file => file.id)
            }
          }
        }
      });

      const updatedFiles = data?.documentationRecord?.update?.documentationRecord?.threeDModel?.files;
      updatedFiles && setFiles(updatedFiles.flatMap(file => (file ? [file] : [])));

      handleSuccessSave();
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [files, documentationRecordId, updateDocumentationRecordFiles, handleSuccessSave, showAlert]);

  const removeFile = useCallback(
    async (mediaId: string) => {
      try {
        const { data } = await removeFileFromTheDocumentationRecord({
          variables: {
            input: {
              documentationRecord: documentationRecordId,
              threeDModel: {
                files: files.flatMap(file => (file.id !== mediaId ? [file.id] : []))
              }
            }
          }
        });
        const updatedFiles = data?.documentationRecord?.update?.documentationRecord?.threeDModel?.files;
        updatedFiles && setFiles(updatedFiles.flatMap(file => (file ? [file] : [])));
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [files, documentationRecordId, removeFileFromTheDocumentationRecord, showAlert]
  );

  const handleUploadMedia: MediaUploaderProps["onUpload"] = useCallback(
    media => setFiles(prevState => [...prevState, media]),
    []
  );
  const handleRemoveMedia: MediaUploaderProps["onRemove"] = useCallback(mediaId => removeFile(mediaId), [removeFile]);
  const handleFinish = useCallback(() => saveFiles(), [saveFiles]);

  return (
    <div className={className}>
      <div className="text-blue-700 text-base font-medium mt-10 mb-5">
        {t(lg.taskThreeDOperator.threeDModelFilesUpload.title)}
      </div>
      {!disabled && t(lg.taskThreeDOperator.threeDModelFilesUpload.description)}

      <ModelFilesUploader
        organizationId={organizationId}
        files={files}
        loading={loading}
        disabled={disabled}
        className="c-grid mt-1"
        uploaderClassName="c-grid-column"
        filesClassName="c-grid-column"
        onUpload={handleUploadMedia}
        onRemove={handleRemoveMedia}
        onFinish={handleFinish}
      />
    </div>
  );
};
