import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { CustomIcon } from "components/icons/CustomIcon";
import { lg } from "assets/translations";
import { useFormat } from "hooks/useFormat";
import { getHasEnoughCredits } from "helpers";

export type SubmitThreeDOperatorHandler = (comment?: string) => any;

type WithPriceInfoProps = {
  visible: boolean;
  loading?: boolean;
  organizationCredits: number;
  cost: number;
  onSubmit: SubmitThreeDOperatorHandler;
  onCancel: () => any;
};

const WithPriceInfo = ({ visible, loading, organizationCredits, cost, onSubmit, onCancel }: WithPriceInfoProps) => {
  const { t } = useTranslation();
  const { creditCost } = useFormat();

  const hasEnoughCredits: boolean = getHasEnoughCredits(organizationCredits, cost);
  const disabled = useMemo(() => !hasEnoughCredits || loading, [hasEnoughCredits, loading]);

  const handleOk = useCallback(() => onSubmit(), [onSubmit]);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <CustomIcon type="send" className="text-lg text-purple-600" />
          <span className="ml-2">{t(lg.documentationRecordActions.submitThreeDOperator.modal.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.submitThreeDOperator.modal.buttonOk)}
      cancelText={t(lg.documentationRecordActions.submitThreeDOperator.modal.buttonCancel)}
      okButtonProps={{ type: "primary", disabled }}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
      destroyOnClose
    >
      <div className="text-base">{t(lg.documentationRecordActions.submitThreeDOperator.modal.description)}</div>

      <table className="mt-5">
        <tbody>
          <tr className="text-base text-right">
            <td>{t(lg.documentationRecordActions.submitThreeDOperator.modal.organizationCredits)}</td>
            <td className="text-purple-600">{creditCost(organizationCredits, false)}</td>
          </tr>
          <tr className="h-1" />
          <tr className="text-base text-right">
            <td className="font-medium">{t(lg.documentationRecordActions.submitThreeDOperator.modal.cost)}</td>
            <td className="text-purple-600 font-medium">{creditCost(cost, false)}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

type SimpleProps = {
  visible: boolean;
  loading?: boolean;
  onSubmit: SubmitThreeDOperatorHandler;
  onCancel: () => any;
};

const Simple = ({ visible, loading, onSubmit, onCancel }: SimpleProps) => {
  const { t } = useTranslation();
  const disabled = loading;

  const handleOk = useCallback(() => onSubmit(), [onSubmit]);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <CustomIcon type="send" className="text-lg text-purple-600" />
          <span className="ml-2">{t(lg.documentationRecordActions.submitThreeDOperator.modalSimple.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.submitThreeDOperator.modalSimple.buttonOk)}
      cancelText={t(lg.documentationRecordActions.submitThreeDOperator.modalSimple.buttonCancel)}
      okButtonProps={{ type: "primary", disabled }}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={onCancel}
      closable={false}
      destroyOnClose
    >
      <div className="text-base">{t(lg.documentationRecordActions.submitThreeDOperator.modalSimple.description)}</div>
    </Modal>
  );
};

export const ModalSubmitThreeDOperator = { WithPriceInfo, Simple };
