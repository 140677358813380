/* ERRORS */
import {
  ConstructionObject,
  ConstructionObjectState,
  ConstructionSite,
  ConstructionSiteState,
  ConstructionSiteStateFilterEnum,
  CoordinateSystem,
  DocumentationRecord,
  DocumentationRecordState,
  DocumentationRecordStateFilterEnum,
  DocumentationRecordType,
  DocumentationRecordTypeFilterEnum,
  DocumentationRecordVideoType,
  DocumentationRecordVideoTypeFilterEnum,
  InquiryStatus,
  InquiryStatusFilterEnum,
  MediaKind,
  Role,
  Triggers,
  Validations
} from "api/generated";
import { lg } from "assets/translations";
import { CustomIconType } from "components/icons/CustomIcon";
import moment from "moment";
import { routes } from "routes";

/* USER */
type Roles = {
  [type in Role]: {
    nameI18nKey: string;
  };
};
export const roles: Roles = {
  [Role.Operator]: {
    nameI18nKey: lg.role.operator
  },
  [Role.Surveyor]: {
    nameI18nKey: lg.role.surveyor
  },
  [Role.Manager]: {
    nameI18nKey: lg.role.manager
  },
  [Role.Reader]: {
    nameI18nKey: lg.role.reader
  },
  [Role.Administrator]: {
    nameI18nKey: lg.role.administrator
  },
  [Role.ThreeDOperator]: {
    nameI18nKey: lg.role.threeDOperator
  },
  [Role.Observer]: {
    nameI18nKey: lg.role.observer
  }
};

/* ERRORS */
export enum HTTPErrors {
  Forbidden = "403",
  NotFound = "404",
  Internal = "500"
}

export enum AccessErrors {
  AccountBlocked = "ACCOUNT_BLOCKED",
  AccountLocked = "ACCOUNT_LOCKED",
  NoTeamOrLicence = "NO_TEAM_OR_LICENCE",
  OtherTeam = "OTHER_TEAM",
  WrongRole = "WRONG_ROLE"
}

export enum OrganizationCancelInviteError {
  notFound = "not_found",
  permissionDenied = "permission_denied",
  inviteInvalid = "invite_invalid"
}

export enum OrganizationInviteUserError {
  NotFound = "not_found",
  PermissionDenied = "permission_denied",
  RoleNotAllowed = "role_not_allowed",
  EmailInvalid = "email_invalid",
  AlreadyMember = "already_member",
  RoleMismatch = "role_mismatch",
  AlreadyInvited = "already_invited",
  NotEnoughCredits = "not_enough_credits"
}

export enum OrganizationResolveInviteError {
  notFound = "not_found",
  inviteInvalid = "invite_invalid",
  roleMismatch = "role_mismatch",
  alreadyMember = "already_member"
}

export enum PurchaseClaimError {
  notFound = "not_found",
  permissionDenied = "permission_denied",
  notClaimable = "not_claimable",
  organizationNotSpecified = "organization_not_specified"
}

/* TYPES */
type DocumentationRecordTypes = {
  [type in DocumentationRecordType]: {
    nameI18nKey: string;
    descriptionI18nKey: string;
  };
};
export const documentationRecordTypes: DocumentationRecordTypes = {
  [DocumentationRecordType.NoThreeDNoVideo]: {
    nameI18nKey: lg.type.documentationRecord.noThreeDNoVideo,
    descriptionI18nKey: lg.documentationRecord.pageDetail.type.description.noThreeDNoVideo
  },
  [DocumentationRecordType.NoThreeDVideo]: {
    nameI18nKey: lg.type.documentationRecord.noThreeDVideo,
    descriptionI18nKey: lg.documentationRecord.pageDetail.type.description.noThreeDVideo
  },
  [DocumentationRecordType.ThreeDNoScale]: {
    nameI18nKey: lg.type.documentationRecord.threeDNoScale,
    descriptionI18nKey: lg.documentationRecord.pageDetail.type.description.threeDNoScale
  },
  [DocumentationRecordType.ThreeDScale]: {
    nameI18nKey: lg.type.documentationRecord.threeDScale,
    descriptionI18nKey: lg.documentationRecord.pageDetail.type.description.threeDScale
  }
};

type DocumentationRecordTypeFilters = {
  [type in DocumentationRecordTypeFilterEnum]: {
    nameI18nKey: string;
  };
};
export const documentationRecordTypeFilters: DocumentationRecordTypeFilters = {
  [DocumentationRecordTypeFilterEnum.NoThreeDNoVideo]: {
    nameI18nKey: lg.type.documentationRecordTypeFilter.noThreeDNoVideo
  },
  [DocumentationRecordTypeFilterEnum.NoThreeDVideo]: {
    nameI18nKey: lg.type.documentationRecordTypeFilter.noThreeDVideo
  },
  [DocumentationRecordTypeFilterEnum.ThreeDNoScale]: {
    nameI18nKey: lg.type.documentationRecordTypeFilter.threeDNoScale
  },
  [DocumentationRecordTypeFilterEnum.ThreeDScale]: {
    nameI18nKey: lg.type.documentationRecordTypeFilter.threeDScale
  }
};

type DocumentationRecordVideoTypes = {
  [type in DocumentationRecordVideoType | "default"]: {
    nameI18nKey: string;
    descriptionI18nKey: string;
  };
};
export const documentationRecordVideoTypes: DocumentationRecordVideoTypes = {
  [DocumentationRecordVideoType.NoScale]: {
    nameI18nKey: lg.type.documentationRecordVideo.noScale,
    descriptionI18nKey: lg.documentationRecord.pageDetail.videoType.description.noScale
  },
  [DocumentationRecordVideoType.Scale]: {
    nameI18nKey: lg.type.documentationRecordVideo.scale,
    descriptionI18nKey: lg.documentationRecord.pageDetail.videoType.description.scale
  },
  [DocumentationRecordVideoType.Maps]: {
    nameI18nKey: lg.type.documentationRecordVideo.maps,
    descriptionI18nKey: lg.documentationRecord.pageDetail.videoType.description.maps
  },
  [DocumentationRecordVideoType.Spray]: {
    nameI18nKey: lg.type.documentationRecordVideo.spray,
    descriptionI18nKey: lg.documentationRecord.pageDetail.videoType.description.spray
  },
  [DocumentationRecordVideoType.QrCodes]: {
    nameI18nKey: lg.type.documentationRecordVideo.qrCodes,
    descriptionI18nKey: lg.documentationRecord.pageDetail.videoType.description.qrCodes
  },
  default: {
    nameI18nKey: lg.type.documentationRecordVideo.default,
    descriptionI18nKey: lg.documentationRecord.pageDetail.videoType.description.default
  }
};

type DocumentationRecordVideoTypeFilter = {
  [type in DocumentationRecordVideoTypeFilterEnum]: {
    nameI18nKey: string;
  };
};
export const documentationRecordVideoTypesFilter: DocumentationRecordVideoTypeFilter = {
  [DocumentationRecordVideoTypeFilterEnum.None]: {
    nameI18nKey: lg.type.documentationRecordVideoTypeFilter.none
  },
  [DocumentationRecordVideoTypeFilterEnum.NoScale]: {
    nameI18nKey: lg.type.documentationRecordVideoTypeFilter.noScale
  },
  [DocumentationRecordVideoTypeFilterEnum.Scale]: {
    nameI18nKey: lg.type.documentationRecordVideoTypeFilter.scale
  },
  [DocumentationRecordVideoTypeFilterEnum.Maps]: {
    nameI18nKey: lg.type.documentationRecordVideoTypeFilter.maps
  },
  [DocumentationRecordVideoTypeFilterEnum.Spray]: {
    nameI18nKey: lg.type.documentationRecordVideoTypeFilter.spray
  },
  [DocumentationRecordVideoTypeFilterEnum.QrCodes]: {
    nameI18nKey: lg.type.documentationRecordVideoTypeFilter.qrCodes
  }
};

export enum ThreeDModelType {
  Simple = "simple",
  Scale = "scale",
  Maps = "maps",
  Spray = "spray",
  QrCodes = "qr_codes"
}

type ThreeDModelTypes = {
  [type in ThreeDModelType]: {
    nameI18nKey: string;
    icon: CustomIconType;
  };
};
export const threeDModelTypes: ThreeDModelTypes = {
  [ThreeDModelType.Maps]: {
    nameI18nKey: lg.type.threeDModel.maps,
    icon: "model-map"
  },
  [ThreeDModelType.QrCodes]: {
    nameI18nKey: lg.type.threeDModel.qrCodes,
    icon: "model-qr"
  },
  [ThreeDModelType.Scale]: {
    nameI18nKey: lg.type.threeDModel.scale,
    icon: "model-scale"
  },
  [ThreeDModelType.Simple]: {
    nameI18nKey: lg.type.threeDModel.simple,
    icon: "model-simple"
  },
  [ThreeDModelType.Spray]: {
    nameI18nKey: lg.type.threeDModel.spray,
    icon: "model-spray"
  }
};

/*
 * Entity types are not generated by graphql-codegen so we need define them manually
 * not all available entities are defined, extend EntityType as required
 * */

export type EntityType = NonNullable<
  DocumentationRecord["__typename"] | ConstructionSite["__typename"] | ConstructionObject["__typename"]
>;

export enum Entity {
  DocumentationRecord = "DocumentationRecord",
  ConstructionSite = "ConstructionSite",
  ConstructionObject = "ConstructionObject"
}

/**
 * Unused const - EntityCheckHelper
 * Check by typescript if Entity enum values are EntityType
 * */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EntityCheckHelper: Record<EntityType, EntityType> = Entity;

type EntityTypes = {
  [type in Entity]: {
    nameI18nKey: string;
    route: string;
  };
};

export const entityTypes: EntityTypes = {
  [Entity.DocumentationRecord]: {
    nameI18nKey: lg.typenames.documentationRecord,
    route: routes.documentationRecordDetail
  },
  [Entity.ConstructionSite]: {
    nameI18nKey: lg.typenames.constructionSite,
    route: routes.constructionSiteDetail
  },
  [Entity.ConstructionObject]: {
    nameI18nKey: lg.typenames.constructionObject,
    route: routes.constructionObjectDetail
  }
};

/* STATES */
type DocumentationRecordStates = {
  [type in DocumentationRecordState]: {
    nameI18nKey: string;
    taskNameI18nKey: string;
  };
};
export const documentationRecordStates: DocumentationRecordStates = {
  [DocumentationRecordState.Archived]: {
    nameI18nKey: lg.state.documentationRecord.archived,
    taskNameI18nKey: lg.taskName.archived
  },
  [DocumentationRecordState.Created]: {
    nameI18nKey: lg.state.documentationRecord.created,
    taskNameI18nKey: lg.taskName.created
  },
  [DocumentationRecordState.Finished]: {
    nameI18nKey: lg.state.documentationRecord.finished,
    taskNameI18nKey: lg.taskName.finished
  },
  [DocumentationRecordState.Rejected]: {
    nameI18nKey: lg.state.documentationRecord.rejected,
    taskNameI18nKey: lg.taskName.rejected
  },
  [DocumentationRecordState.RequiresManagerConfirmation]: {
    nameI18nKey: lg.state.documentationRecord.requiresManagerConfirmation,
    taskNameI18nKey: lg.taskName.requiresManagerConfirmation
  },
  [DocumentationRecordState.RequiresOperator]: {
    nameI18nKey: lg.state.documentationRecord.requiresOperator,
    taskNameI18nKey: lg.taskName.requiresOperator
  },
  [DocumentationRecordState.RequiresSurveyor]: {
    nameI18nKey: lg.state.documentationRecord.requiresSurveyor,
    taskNameI18nKey: lg.taskName.requiresSurveyor
  },
  [DocumentationRecordState.RequiresThreeDOperator]: {
    nameI18nKey: lg.state.documentationRecord.requiresThreeDOperator,
    taskNameI18nKey: lg.taskName.requiresThreeDOperator
  }
};

type DocumentationRecordStateFilters = {
  [type in DocumentationRecordStateFilterEnum]: {
    nameI18nKey: string;
  };
};
export const documentationRecordStateFilters: DocumentationRecordStateFilters = {
  [DocumentationRecordStateFilterEnum.Archived]: {
    nameI18nKey: lg.state.documentationRecordStateFilter.archived
  },
  [DocumentationRecordStateFilterEnum.Created]: {
    nameI18nKey: lg.state.documentationRecordStateFilter.created
  },
  [DocumentationRecordStateFilterEnum.Finished]: {
    nameI18nKey: lg.state.documentationRecordStateFilter.finished
  },
  [DocumentationRecordStateFilterEnum.Rejected]: {
    nameI18nKey: lg.state.documentationRecordStateFilter.rejected
  },
  [DocumentationRecordStateFilterEnum.RequiresManagerConfirmation]: {
    nameI18nKey: lg.state.documentationRecordStateFilter.requiresManagerConfirmation
  },
  [DocumentationRecordStateFilterEnum.RequiresOperator]: {
    nameI18nKey: lg.state.documentationRecordStateFilter.requiresOperator
  },
  [DocumentationRecordStateFilterEnum.RequiresSurveyor]: {
    nameI18nKey: lg.state.documentationRecordStateFilter.requiresSurveyor
  },
  [DocumentationRecordStateFilterEnum.RequiresThreeDOperator]: {
    nameI18nKey: lg.state.documentationRecordStateFilter.requiresThreeDOperator
  }
};

type ConstructionSiteStates = {
  [type in ConstructionSiteState]: {
    nameI18nKey: string;
  };
};
export const constructionSiteStates: ConstructionSiteStates = {
  [ConstructionSiteState.Active]: {
    nameI18nKey: lg.state.constructionSite.active
  },
  [ConstructionSiteState.Inactive]: {
    nameI18nKey: lg.state.constructionSite.inactive
  }
};

type ConstructionSiteStateFilters = {
  [type in ConstructionSiteStateFilterEnum]: {
    nameI18nKey: string;
  };
};
export const constructionSiteStateFilters: ConstructionSiteStateFilters = {
  [ConstructionSiteStateFilterEnum.Active]: {
    nameI18nKey: lg.state.constructionSiteStateFilter.active
  },
  [ConstructionSiteStateFilterEnum.Inactive]: {
    nameI18nKey: lg.state.constructionSiteStateFilter.inactive
  }
};

type ConstructionObjectStates = {
  [type in ConstructionObjectState]: {
    nameI18nKey: string;
  };
};
export const constructionObjectStates: ConstructionObjectStates = {
  [ConstructionObjectState.Active]: {
    nameI18nKey: lg.state.constructionObject.active
  },
  [ConstructionObjectState.Inactive]: {
    nameI18nKey: lg.state.constructionObject.inactive
  }
};

type ConstructionObjectStateFilters = {
  [type in ConstructionSiteStateFilterEnum]: {
    nameI18nKey: string;
  };
};
export const constructionObjectStateFilters: ConstructionObjectStateFilters = {
  [ConstructionSiteStateFilterEnum.Active]: {
    nameI18nKey: lg.state.constructionObjectStateFilter.active
  },
  [ConstructionSiteStateFilterEnum.Inactive]: {
    nameI18nKey: lg.state.constructionObjectStateFilter.inactive
  }
};

type GeodeticSurveyUploadedStates = {
  [type in "true" | "false"]: {
    nameI18nKey: string;
  };
};
export const geodeticSurveyUploadedStates: GeodeticSurveyUploadedStates = {
  true: {
    nameI18nKey: lg.state.geodeticSurveyUploaded.true
  },
  false: {
    nameI18nKey: lg.state.geodeticSurveyUploaded.false
  }
};

type DocumentationRecordReturnedStates = {
  [type in "true" | "false"]: {
    nameI18nKey: string;
  };
};
export const documentationRecordReturnedStates: DocumentationRecordReturnedStates = {
  true: {
    nameI18nKey: lg.state.documentationRecordReturned.true
  },
  false: {
    nameI18nKey: lg.state.documentationRecordReturned.false
  }
};

type InquiryState = {
  [type in InquiryStatus]: {
    nameI18nKey: string;
  };
};
export const inquiryStates: InquiryState = {
  [InquiryStatus.Archived]: {
    nameI18nKey: lg.state.inquiry.archived
  },
  [InquiryStatus.Completed]: {
    nameI18nKey: lg.state.inquiry.completed
  },
  [InquiryStatus.Created]: {
    nameI18nKey: lg.state.inquiry.created
  },
  [InquiryStatus.Rejected]: {
    nameI18nKey: lg.state.inquiry.rejected
  }
};

type InquiryStateFilter = {
  [type in InquiryStatusFilterEnum]: {
    nameI18nKey: string;
  };
};
export const inquiryStateFilters: InquiryStateFilter = {
  [InquiryStatusFilterEnum.Archived]: {
    nameI18nKey: lg.state.inquiryStateFilter.archived
  },
  [InquiryStatusFilterEnum.Completed]: {
    nameI18nKey: lg.state.inquiryStateFilter.completed
  },
  [InquiryStatusFilterEnum.Created]: {
    nameI18nKey: lg.state.inquiryStateFilter.created
  },
  [InquiryStatusFilterEnum.Rejected]: {
    nameI18nKey: lg.state.inquiryStateFilter.rejected
  }
};

/* ACTIONS */
type DocumentationRecordTriggers = {
  [type in Triggers]: {
    labelI18nKey: string;
    descriptionI18nKey: string;
    buttonI18nKey: string;
    messageSuccessI18nKey: string;
  };
};
export const documentationRecordTriggers: DocumentationRecordTriggers = {
  [Triggers.SubmitOperator]: {
    labelI18nKey: lg.documentationRecordActions.submitOperator.label,
    descriptionI18nKey: "", // no available translation
    buttonI18nKey: lg.documentationRecordActions.submitOperator.button,
    messageSuccessI18nKey: lg.documentationRecordActions.submitOperator.messages.success
  },
  [Triggers.BackCreated]: {
    labelI18nKey: lg.documentationRecordActions.backCreated.label,
    descriptionI18nKey: lg.documentationRecordActions.backCreated.description,
    buttonI18nKey: lg.documentationRecordActions.backCreated.button,
    messageSuccessI18nKey: lg.documentationRecordActions.backCreated.messages.success
  },
  [Triggers.SubmitSurveyor]: {
    labelI18nKey: lg.documentationRecordActions.submitSurveyor.label,
    descriptionI18nKey: lg.documentationRecordActions.submitSurveyor.description,
    buttonI18nKey: lg.documentationRecordActions.submitSurveyor.button,
    messageSuccessI18nKey: lg.documentationRecordActions.submitSurveyor.messages.success
  },
  [Triggers.BackOperator]: {
    labelI18nKey: lg.documentationRecordActions.backOperator.label,
    descriptionI18nKey: lg.documentationRecordActions.backOperator.description,
    buttonI18nKey: lg.documentationRecordActions.backOperator.button,
    messageSuccessI18nKey: lg.documentationRecordActions.backOperator.messages.success
  },
  [Triggers.SubmitThreeDOperator]: {
    labelI18nKey: lg.documentationRecordActions.submitThreeDOperator.label,
    descriptionI18nKey: lg.documentationRecordActions.submitThreeDOperator.description,
    buttonI18nKey: lg.documentationRecordActions.submitThreeDOperator.button,
    messageSuccessI18nKey: lg.documentationRecordActions.submitThreeDOperator.messages.success
  },
  [Triggers.SubmitManagerApproval]: {
    labelI18nKey: lg.documentationRecordActions.submitManagerApproval.label,
    descriptionI18nKey: lg.documentationRecordActions.submitManagerApproval.description,
    buttonI18nKey: lg.documentationRecordActions.submitManagerApproval.button,
    messageSuccessI18nKey: lg.documentationRecordActions.submitManagerApproval.messages.success
  },
  [Triggers.BackSurveyor]: {
    labelI18nKey: lg.documentationRecordActions.backSurveyor.label,
    descriptionI18nKey: lg.documentationRecordActions.backSurveyor.description,
    buttonI18nKey: lg.documentationRecordActions.backSurveyor.button,
    messageSuccessI18nKey: lg.documentationRecordActions.backSurveyor.messages.success
  },
  [Triggers.SubmitFinished]: {
    labelI18nKey: lg.documentationRecordActions.submitFinished.label,
    descriptionI18nKey: lg.documentationRecordActions.submitFinished.description,
    buttonI18nKey: lg.documentationRecordActions.submitFinished.button,
    messageSuccessI18nKey: lg.documentationRecordActions.submitFinished.messages.success
  },
  [Triggers.Reject]: {
    labelI18nKey: lg.documentationRecordActions.reject.label,
    descriptionI18nKey: lg.documentationRecordActions.reject.description,
    buttonI18nKey: lg.documentationRecordActions.reject.button,
    messageSuccessI18nKey: lg.documentationRecordActions.reject.messages.success
  },
  [Triggers.Archive]: {
    labelI18nKey: "", // no available translation
    descriptionI18nKey: "", // no available translation
    buttonI18nKey: "", // no available translation
    messageSuccessI18nKey: ""
  },
  [Triggers.Submit]: {
    labelI18nKey: "", // no available translation
    descriptionI18nKey: "", // no available translation
    buttonI18nKey: "", // no available translation
    messageSuccessI18nKey: ""
  },
  [Triggers.Back]: {
    labelI18nKey: "", // no available translation
    descriptionI18nKey: "", // no available translation
    buttonI18nKey: "", // no available translation
    messageSuccessI18nKey: "" // no available translation
  },
  [Triggers.ChangeToThreeD]: {
    labelI18nKey: lg.documentationRecordActions.changeToThreeD.label,
    descriptionI18nKey: lg.documentationRecordActions.changeToThreeD.description,
    buttonI18nKey: lg.documentationRecordActions.changeToThreeD.button,
    messageSuccessI18nKey: lg.documentationRecordActions.changeToThreeD.messages.success
  },
  [Triggers.ChangeNoScale]: {
    labelI18nKey: lg.documentationRecordActions.changeNoScale.label,
    descriptionI18nKey: lg.documentationRecordActions.changeNoScale.description,
    buttonI18nKey: lg.documentationRecordActions.changeNoScale.button,
    messageSuccessI18nKey: lg.documentationRecordActions.changeNoScale.messages.success
  }
};

type DocumentationRecordValidations = {
  [type in Validations]: {
    messageI18nKey: string;
  };
};
export const documentationRecordValidations: DocumentationRecordValidations = {
  [Validations.DocumentationRecordAttributeRequired]: {
    messageI18nKey: lg.documentationRecordActions.validation.documentationRecordAttributeRequired
  },
  [Validations.VideoValidType]: {
    messageI18nKey: lg.documentationRecordActions.validation.videoValidType
  },
  [Validations.VideoIsSet]: {
    messageI18nKey: lg.documentationRecordActions.validation.videoIsSet
  },
  [Validations.VideoIsReady]: {
    messageI18nKey: lg.documentationRecordActions.validation.videoIsReady
  },
  [Validations.GroundControlPointsMinimalCount]: {
    messageI18nKey: lg.documentationRecordActions.validation.groundControlPointsMinimalCount
  },
  [Validations.GroundControlPointsPhotoSet]: {
    messageI18nKey: lg.documentationRecordActions.validation.groundControlPointsPhotoSet
  },
  [Validations.GroundControlPointsPhotoReady]: {
    messageI18nKey: lg.documentationRecordActions.validation.groundControlPointsPhotoReady
  },
  [Validations.GroundControlPointsGeolocationSet]: {
    messageI18nKey: lg.documentationRecordActions.validation.groundControlPointsGeolocationSet
  },
  [Validations.ConstructionElementPhotoReady]: {
    messageI18nKey: lg.documentationRecordActions.validation.constructionElementPhotoReady
  },
  [Validations.GeodeticSurveyIsSet]: {
    messageI18nKey: lg.documentationRecordActions.validation.geodeticSurveyIsSet
  },
  [Validations.GeodeticSurveyIsReady]: {
    messageI18nKey: lg.documentationRecordActions.validation.geodeticSurveyIsReady
  },
  [Validations.ThreeDModelIsSet]: {
    messageI18nKey: lg.documentationRecordActions.validation.threeDModelIsSet
  },
  [Validations.ThreeDModelIsReady]: {
    messageI18nKey: lg.documentationRecordActions.validation.threeDModelIsReady
  },
  [Validations.OrganizationHasCredits]: {
    messageI18nKey: lg.documentationRecordActions.validation.organizationHasCredits
  },
  [Validations.OrganizationHasCreditsNoVideo]: {
    messageI18nKey: lg.documentationRecordActions.validation.organizationHasCreditsNoVideo
  },
  [Validations.OutputExtensionIsReady]: {
    messageI18nKey: lg.documentationRecordActions.validation.outputExtensionIsReady
  }
};

export enum Attribute {
  AccountableOperator = "accountable_operator",
  AccountableSurveyor = "accountable_surveyor"
}
type DocumentationRecordAttributes = {
  [type in Attribute]: {
    messageI18nKey: string;
  };
};
export const documentationRecordAttributes: DocumentationRecordAttributes = {
  [Attribute.AccountableOperator]: {
    messageI18nKey: lg.documentationRecordActions.attribute.accountableOperator
  },
  [Attribute.AccountableSurveyor]: {
    messageI18nKey: lg.documentationRecordActions.attribute.accountableSurveyor
  }
};

type CoordinateSystems = {
  [type in CoordinateSystem]: {
    nameI18nKey: string;
  };
};

export const coordinateSystems: CoordinateSystems = {
  [CoordinateSystem.Ch1903PlusLv95]: { nameI18nKey: lg.coordinateSystem.ch1903plusLv95 },
  [CoordinateSystem.Dhdn]: { nameI18nKey: lg.coordinateSystem.dhdn },
  [CoordinateSystem.Dhdn_3dGkZ2]: { nameI18nKey: lg.coordinateSystem.dhdn3dGkZ2 },
  [CoordinateSystem.Dhdn_3dGkZ4]: { nameI18nKey: lg.coordinateSystem.dhdn3dGkZ4 },
  [CoordinateSystem.Dhdn_3dGkZ5]: { nameI18nKey: lg.coordinateSystem.dhdn3dGkZ5 },
  [CoordinateSystem.Dhdn_31466]: { nameI18nKey: lg.coordinateSystem.dhdn31466 },
  [CoordinateSystem.Dhdn_31467]: { nameI18nKey: lg.coordinateSystem.dhdn31467 },
  [CoordinateSystem.Dhdn_31468]: { nameI18nKey: lg.coordinateSystem.dhdn31468 },
  [CoordinateSystem.Etrs89PolandCs2000_5]: { nameI18nKey: lg.coordinateSystem.etrs89polandCs20005 },
  [CoordinateSystem.Etrs89PolandCs2000_6]: { nameI18nKey: lg.coordinateSystem.etrs89polandCs20006 },
  [CoordinateSystem.Etrs89PolandCs2000_7]: { nameI18nKey: lg.coordinateSystem.etrs89polandCs20007 },
  [CoordinateSystem.Etrs89PolandCs2000_8]: { nameI18nKey: lg.coordinateSystem.etrs89polandCs20008 },
  [CoordinateSystem.Etrs89Utm_32n]: { nameI18nKey: lg.coordinateSystem.etrs89utm32n },
  [CoordinateSystem.Etrs89Utm_33n]: { nameI18nKey: lg.coordinateSystem.etrs89utm33n },
  [CoordinateSystem.Hd72]: { nameI18nKey: lg.coordinateSystem.hd72 },
  [CoordinateSystem.SJtsk]: { nameI18nKey: lg.coordinateSystem.sJtsk },
  [CoordinateSystem.Unknown]: { nameI18nKey: lg.coordinateSystem.unknown },
  [CoordinateSystem.Wgs84]: { nameI18nKey: lg.coordinateSystem.wgs84 },
  [CoordinateSystem.Wgs84Utm_32n]: { nameI18nKey: lg.coordinateSystem.wgs84utm32n },
  [CoordinateSystem.Wgs84Utm_34n]: { nameI18nKey: lg.coordinateSystem.wgs84utm34n },
  [CoordinateSystem.Wgs84Utm_35n]: { nameI18nKey: lg.coordinateSystem.wgs84utm35n },
  [CoordinateSystem.Wgs84Utm_36n]: { nameI18nKey: lg.coordinateSystem.wgs84utm36n },
  [CoordinateSystem.Wgs84Utm_37n]: { nameI18nKey: lg.coordinateSystem.wgs84utm37n }
};

type MediaKinds = {
  [type in MediaKind]: {
    legendI18nKey: string;
  };
};
export const mediaKinds: MediaKinds = {
  [MediaKind.Documentation]: {
    legendI18nKey: lg.mediaKind.documentation
  },
  [MediaKind.ThreeDModel]: {
    legendI18nKey: lg.mediaKind.threeDModel
  },
  [MediaKind.Unassigned]: {
    legendI18nKey: lg.mediaKind.unassigned
  },
  [MediaKind.Inquiry]: {
    legendI18nKey: lg.mediaKind.inquiry
  },
  [MediaKind.OutputExtension]: {
    legendI18nKey: lg.mediaKind.outputExtension
  }
};

export enum TimeLimit {
  unlimited = "unlimited",
  oneHour = "one-hour",
  oneDay = "one-day",
  oneWeek = "one-week",
  oneMonth = "one-month"
}
type TimeLimits = {
  [type in TimeLimit]: {
    labelI18nKey: string;
    value: () => null | string;
  };
};
export const timeLimits: TimeLimits = {
  [TimeLimit.unlimited]: {
    labelI18nKey: lg.threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.unlimited,
    value: () => null
  },
  [TimeLimit.oneHour]: {
    labelI18nKey: lg.threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.forOneHour,
    value: () => moment().add("1", "hour").toISOString()
  },
  [TimeLimit.oneDay]: {
    labelI18nKey: lg.threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.forOneDay,
    value: () => moment().add("1", "day").toISOString()
  },
  [TimeLimit.oneWeek]: {
    labelI18nKey: lg.threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.forOneWeek,
    value: () => moment().add("1", "week").toISOString()
  },
  [TimeLimit.oneMonth]: {
    labelI18nKey: lg.threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.options.forOneMonth,
    value: () => moment().add("1", "month").toISOString()
  }
};

export enum ApiCompatibility {
  Compatible = "compatible",
  Deprecated = "deprecated",
  Incompatible = "incompatible"
}
