import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { Link } from "../Link";
import { routes, setRouteParams } from "routes";
import { Delete } from "components/DocumentationRecordDetail/Delete";
import {
  DocumentationRecordOrderByEnum,
  OrganizationTasksQuery,
  OrganizationTasksQueryVariables,
  resolveError,
  useOrganizationTasksQuery
} from "api";
import { TypeIndicatorDocumentationRecord } from "components/Indicators/Type";
import { useAlert, useFormat } from "hooks";
import { useColumnSearchProps, usePagination } from "helpers/serverSideTableFilter";
import { Table } from "antd";
import { TableProps } from "helpers";

type DocumentationRecordItem = NonNullable<OrganizationTasksQuery["organization"]>["tasks"]["edges"][0]["node"];

type Props = {
  organizationId: string;
};

type TableItem = DocumentationRecordItem & {
  constructionSiteName: string;
};
type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  name: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  constructionSiteName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteNameDesc]
  },
  createdAt: {
    ascend: [DocumentationRecordOrderByEnum.CreatedAt],
    descend: [DocumentationRecordOrderByEnum.CreatedAtDesc]
  },
  totalSize: {
    ascend: [DocumentationRecordOrderByEnum.TotalSize],
    descend: [DocumentationRecordOrderByEnum.TotalSizeDesc]
  }
};

export const DocumentationRecordsTable = ({ organizationId }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState<OrganizationTasksQueryVariables["orderBy"]>(orderByMap.createdAt.descend);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const { formatBytes, formatDate } = useFormat();
  const { showAlert } = useAlert();
  const [constructionSiteNameFilter, setConstructionSiteNameFilter] = useState("");
  const constructionSiteNameSearchProps = useColumnSearchProps();
  const [nameFilter, setNameFilter] = useState("");
  const nameSearchProps = useColumnSearchProps();

  const { data, loading: loadingOrganizationTasks } = useOrganizationTasksQuery({
    variables: {
      organizationId: organizationId,
      skip,
      orderBy,
      constructionSiteName: constructionSiteNameFilter,
      name: nameFilter,
      withAccountableManager: true,
      withTotalSize: true,
      withModelSize: false
    },
    fetchPolicy: "cache-and-network",
    skip: !organizationId,
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => {
    setTotal(data?.organization?.tasks.totalCount);
  }, [data]);

  const tableData = useMemo<TableItem[]>(
    () =>
      data?.organization?.tasks?.edges.map(({ node: item }) => ({
        ...item,
        constructionSiteName: item.constructionObject.constructionSite.name
      })) || [],
    [data]
  );

  const columns = useMemo<TablePropType["columns"]>(
    (): TablePropType["columns"] => [
      {
        title: t(lg.organizationResources.documentationRecords.table.header.name),
        dataIndex: "name",
        key: "name",
        render: (text: string, { id, type, state }) => (
          <TypeIndicatorDocumentationRecord
            type={type}
            state={state}
            label={<Link href={setRouteParams(routes.documentationRecordDetail, { id })}>{text}</Link>}
          />
        ),
        sorter: true,
        ellipsis: true,
        ...nameSearchProps
      },
      {
        title: t(lg.organizationResources.documentationRecords.table.header.constructionSite),
        dataIndex: "constructionSiteName",
        key: "constructionSiteName",
        sorter: true,
        ellipsis: true,
        ...constructionSiteNameSearchProps,
        render: (name: string) => <div className="text-secondary">{name || "-"}</div>
      },
      {
        title: t(lg.organizationResources.documentationRecords.table.header.createdAt),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt?: string) => (
          <span className="text-secondary">{createdAt ? formatDate(createdAt, "machine", true) : "-"}</span>
        ),
        className: "whitespace-nowrap",
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        title: t(lg.organizationResources.documentationRecords.table.header.size),
        dataIndex: "totalSize",
        key: "totalSize",
        render: totalSize => {
          return <span className="text-secondary">{typeof totalSize === "number" ? formatBytes(totalSize) : "-"}</span>;
        },
        sorter: true
      },
      {
        title: t(lg.organizationResources.threeDModels.table.header.action),
        dataIndex: "id",
        key: "actions",
        className: "border border-l-0",
        render: (id, record) => (
          <Delete
            id={record.id}
            name={record.name}
            threeDModel={record.threeDModel}
            accountableManagerId={record.accountableManager?.id || ""}
          >
            <Link>{t(lg.organizationResources.documentationRecords.table.cell.deleteText)}</Link>
          </Delete>
        )
      }
    ],
    [t, nameSearchProps, constructionSiteNameSearchProps, formatDate, formatBytes]
  );
  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter, extra) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setNameFilter(filter.name ? filter.name[0] : "");
      setConstructionSiteNameFilter(filter.constructionSiteName ? filter.constructionSiteName[0] : "");
    },
    [setPage]
  );

  const hasFilters = nameFilter || constructionSiteNameFilter;

  return (
    <div>
      {tableData.length === 0 && !hasFilters && !loadingOrganizationTasks ? (
        <div className="flex flex-col items-center">
          <div className="text-xs text-gray-700 text-center whitespace-pre-line">
            {t(lg.organizationResources.documentationRecords.table.noDataText)}
          </div>
        </div>
      ) : (
        <Table<TableItem>
          dataSource={tableData}
          columns={columns}
          rowKey={"id"}
          loading={{ delay: tableData.length === 0 ? 0 : 300, spinning: loadingOrganizationTasks }}
          showSorterTooltip={false}
          onChange={handleOnChange}
          pagination={pagination}
          scroll={{ x: true }}
        />
      )}
    </div>
  );
};
