import { ComponentPropsWithoutRef, useCallback, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useSearchForInquiryFormLazyQuery, useSearchForInquiryFormQuery } from "api";
import { Radio, Select, Spin } from "antd";
import { lg } from "assets/translations";
import { entityTypes } from "api/enums";

type RadioGroupProps = ComponentPropsWithoutRef<typeof Radio.Group>;

/*
---------------------------------------------------------------------
TOPIC GROUP
---------------------------------------------------------------------
 */
type TopicsGroupProps = {
  topics: { id: string; isStructural: boolean; topic: string }[];
  onChange: (topic: { topicId: string; nodesIds?: string[] }) => void;
  disabled?: boolean;
};

export const TopicsGroup = ({ topics, onChange, disabled }: TopicsGroupProps) => {
  const [activeTopicId, setActiveTopicId] = useState<string>();

  const handleRadioGroupChange: Required<RadioGroupProps>["onChange"] = useCallback(
    event => {
      const newActiveTopicId = event.target.value;
      setActiveTopicId(newActiveTopicId);
      onChange({ topicId: newActiveTopicId, nodesIds: [] });
    },
    [onChange]
  );

  const handleNodesSelectChange: ComponentPropsWithoutRef<typeof NodesSelect>["onChange"] = useCallback(
    (topicId, nodes) => onChange({ topicId: topicId, nodesIds: nodes }),
    [onChange]
  );

  return (
    <Radio.Group className={"space-y-4"} disabled={disabled} value={activeTopicId} onChange={handleRadioGroupChange}>
      {topics.map(topic => {
        const isSelected: boolean = topic.id === activeTopicId;
        const showExpand: boolean = isSelected && topic.isStructural;
        return (
          <div key={topic.id}>
            <Radio value={topic.id}>{topic.topic}</Radio>
            {showExpand ? (
              <NodesSelect topicId={topic.id} onChange={handleNodesSelectChange} disabled={disabled} />
            ) : null}
          </div>
        );
      })}
    </Radio.Group>
  );
};

/*
---------------------------------------------------------------------
TOPIC GROUP - NODES SELECT
---------------------------------------------------------------------
 */
type NodesSelectProps = {
  topicId: string;
  onChange: (topicId: string, nodes?: string[]) => void;
  disabled?: boolean;
};

type SelectOption = { label: string | JSX.Element; value: string };

const NodesSelect = ({ topicId, onChange, disabled = false }: NodesSelectProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>();

  const [search, { data: dataSearch, loading }] = useSearchForInquiryFormLazyQuery();
  const { data: dataInitSearch } = useSearchForInquiryFormQuery({
    skip: disabled,
    variables: { query: "" },
    fetchPolicy: "cache-first"
  });

  const initSearchResult = useMemo(
    () => dataInitSearch?.search?.flatMap(item => (item ? [item] : [])) || [],
    [dataInitSearch]
  );
  const searchResult = useMemo(() => dataSearch?.search?.flatMap(item => (item ? [item] : [])) || [], [dataSearch]);

  const data = useMemo(
    () => (searchValue ? searchResult : initSearchResult),
    [searchValue, searchResult, initSearchResult]
  );

  const options = useMemo<SelectOption[]>(
    () =>
      data.map(node => ({
        value: node.id,
        label: (
          <span>
            <span className="font-medium">{t(entityTypes[node.__typename].nameI18nKey)}: </span>
            {node.name}
          </span>
        )
      })),
    [t, data]
  );

  const resetSearch = useCallback(() => setSearchValue(""), []);
  const handleSearch = useCallback((value: string) => setSearchValue(value), []);
  const handleChange = useCallback((values: string[]) => onChange(topicId, values), [topicId, onChange]);

  /* Debounce the search */
  useDebounce(
    () => {
      if (!searchValue) return;
      search({ variables: { query: searchValue } });
    },
    500,
    [search, searchValue]
  );

  return (
    <div className="ml-6">
      <div className={"text-sm"}>{t(lg.additionalServices.inquiriesForm.option.help)}</div>
      <Select
        optionLabelProp={"label"}
        mode={"multiple"}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" className="w-full" /> : null}
        placeholder={t(lg.additionalServices.inquiriesForm.option.placeholder)}
        filterOption={false}
        className={"w-full mt-0.5"}
        options={options}
        disabled={disabled}
        onSearch={handleSearch}
        onChange={handleChange}
        onDeselect={resetSearch}
        onBlur={resetSearch}
      />
    </div>
  );
};
