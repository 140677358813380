import { useCallback, useMemo } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Content } from "components/LayoutDashboard";
import { ProgressSteps } from "components/DocumentationRecord/ProgressSteps";
import * as Actions from "components/DocumentationRecord/Actions";
import { ConstructionElements, GroundControlPoints, RecordTypeInfo, VideoTypeInfo, VideoUpload } from "components/Task";
import { PointItem } from "components/Task/GroundControlPoints/Point";
import { DocumentationRecordType } from "api";
import { SubheaderOptions, useFormat, useSubheader, useTask } from "hooks";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations";
import { documentationRecordStates } from "api/enums";
import { getCoords } from "helpers";

/**
 * Documentation Record process page for Manager in role of accountable Operator. Equivalent to mobile app.
 */
export const TaskOperator = ({ id }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing documentation record ID.");

  const { t } = useTranslation();
  const { arrayToCoords } = useFormat();

  const { data, handlers, loading } = useTask(id);

  const hasVideo = useMemo(() => data?.record?.type !== DocumentationRecordType.NoThreeDNoVideo, [data]);

  const hasGroundControlPoints = useMemo(() => data?.record?.type === DocumentationRecordType.ThreeDScale, [data]);

  const savedPoints = useMemo<PointItem[]>(
    () =>
      data.groundControlPoints.map(point => ({
        id: point.id,
        order: point.order,
        coords: getCoords(point.location || []),
        description: point.description || "",
        photos: point.files.edges.map(({ node }) => node.id),
        draft: false,
        saved: true
      })),
    [data.groundControlPoints]
  );

  const handleClickBack = useCallback(() => navigate(setRouteParams(routes.documentationRecordDetail, { id })), [id]);

  const { record, site, object, triggers, video, recordVideo, constructionElements, organization } = data;

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const recordName = record?.name || "...";
    const recordState = record?.state ? t(documentationRecordStates[record.state].taskNameI18nKey) : "...";

    return {
      heading: `${recordName}: ${recordState}`,
      breadcrumb: [
        { value: t(lg.todo.header.title), route: routes.todo },
        { value: t(lg.todo.tabs.forMobile), route: routes.todo + "#mobile" },
        { value: recordName }
      ],
      buttons: [
        <Actions.MainAction
          type={"taskOperator"}
          documentationRecordId={id}
          triggers={triggers}
          onAfterTrigger={handlers.handleOnAfterTrigger}
        />
      ]
    };
  }, [id, record, triggers, handlers.handleOnAfterTrigger, t]);
  useSubheader(subheaderOptions);

  return (
    <>
      <Content loading={!record && loading} flexGrow={false}>
        {record && site && (
          <ProgressSteps
            documentationRecordType={record.type}
            documentationRecordState={record.state}
            autoRequestThreeD={site.autoRequestThreeD}
          />
        )}
      </Content>

      <Content loading={!record && loading}>
        {record && site && (
          <>
            <div className="flex justify-between w-full flex-wrap">
              <Button size="large" type="default" icon={<ArrowLeftOutlined />} onClick={handleClickBack}>
                {t(lg.taskOperator.buttonBack)}
              </Button>

              <Actions.ValidationMessages triggers={data.triggers} organizationName={organization?.name} />
            </div>

            <RecordTypeInfo type={record.type} className="mt-10" />

            {hasVideo && <VideoTypeInfo type={record.documentationRecordVideo?.type || null} className="mt-16" />}

            {hasGroundControlPoints && (
              <GroundControlPoints
                organizationId={site?.organization.id || ""}
                documentationRecordId={record.id}
                savedPoints={savedPoints}
                simpleGroundControlPointCount={data.simpleGroundControlPointCount}
                simpleGroundControlPoint={data.simpleGroundControlPoint}
                constructionSiteCoords={arrayToCoords(site?.location || [])}
                constructionObjectCoords={arrayToCoords(object?.location || [])}
                className="mt-16"
              />
            )}

            {hasVideo && (
              <VideoUpload
                organizationId={site?.organization.id || ""}
                documentationRecordId={record.id}
                type={record.documentationRecordVideo?.type || null}
                video={video}
                captureLocation={recordVideo?.captureLocation}
                hasGroundControlPoints={hasGroundControlPoints}
                className="mt-16"
              />
            )}

            <ConstructionElements
              organizationId={site.organization.id}
              documentationRecordId={record.id}
              defaultElements={constructionElements}
              className="mt-16"
            />
          </>
        )}
      </Content>

      <Actions.ActionsSection
        documentationRecordId={id}
        triggers={data.triggers}
        onAfterTrigger={handlers.handleOnAfterTrigger}
      />
    </>
  );
};
