import { useCallback, useEffect, useMemo, useState } from "react";
import { Maybe, resolveError, Role, useOrganizationInviteUserMutation } from "api";
import { GraphQLError } from "graphql";
import { useTranslation } from "react-i18next";
import { Form, message } from "antd";
import { lg } from "assets/translations";
import { useAlert, useCatalog, useMe } from "hooks";
import { enumToValues, includes } from "helpers";
import { OrganizationInviteUserError } from "api/enums";

export enum InviteUserFormFields {
  email = "email",
  role = "role",
  comment = "comment"
}

type InviteUserRole = Role.Manager | Role.Operator | Role.Surveyor | Role.Reader;

export type InviteUserFormDefaultValues = {
  email: string;
  role: Maybe<InviteUserRole>;
};

type InviteUserData = {
  organization: string;
  email: string;
  role: InviteUserRole;
  description?: string;
};

export const useOrganizationInviteUser = (organizationId: string, userEmail?: string, userRole?: Maybe<Role>) => {
  const { showAlert } = useAlert();
  const { t } = useTranslation();
  const [invite, { loading: loadingInviteUserMutation }] = useOrganizationInviteUserMutation();
  const [form] = Form.useForm();

  /** STATE */
  const [visible, setVisible] = useState<boolean>(false);
  const [canSend, setCanSend] = useState<boolean>(false);
  const [canShowCreditInfo, setCanShowCreditInfo] = useState<boolean>(false);
  const [inviteErrors, setInviteErrors] = useState<OrganizationInviteUserError[]>();

  /** DATA */
  const { catalog, loading: loadingCatalog } = useCatalog();
  const { me } = useMe();

  const allowedRoles = useMemo(() => {
    if (me.role === Role.Operator) return [Role.Surveyor];
    return [Role.Manager, Role.Surveyor, Role.Operator, Role.Reader];
  }, [me.role]);

  const defaultValues = useMemo(
    () => ({
      [InviteUserFormFields.email]: userEmail,
      [InviteUserFormFields.role]: getDefaultRole(allowedRoles, userRole)
    }),
    [allowedRoles, userEmail, userRole]
  );

  const invitePrice = useMemo<number | undefined>(() => catalog?.inviteManager, [catalog]);

  const existInviteErrors: boolean = useMemo<boolean>(
    () => !!(inviteErrors && inviteErrors.length > 0),
    [inviteErrors]
  );

  const loading = useMemo<boolean>(() => loadingCatalog, [loadingCatalog]);
  const sending = useMemo<boolean>(() => loadingInviteUserMutation, [loadingInviteUserMutation]);
  const disabled = useMemo<boolean>(() => loading || sending, [loading, sending]);

  /** HANDLERS */
  const open = useCallback(() => setVisible(true), [setVisible]);

  /* Handlers - API */
  const handleSuccess = useCallback(async () => {
    setVisible(false);
    message.info(t(lg.organization.messages.invitationSent));
  }, [t, setVisible]);

  const handleErrors = useCallback(
    (e: any) => {
      const inviteUserErrors = enumToValues<OrganizationInviteUserError>(OrganizationInviteUserError);
      const _inviteErrors: OrganizationInviteUserError[] =
        e.graphQLErrors
          ?.filter((error: GraphQLError) => includes(inviteUserErrors, error.message))
          ?.map((error: GraphQLError) => error.message) || [];

      _inviteErrors.length > 0 ? setInviteErrors(_inviteErrors) : resolveError(e, {}, showAlert);
    },
    [showAlert]
  );

  /* API */
  const inviteUser = useCallback(
    async (data: InviteUserData) => {
      try {
        await invite({
          variables: {
            input: {
              organization: data.organization,
              email: data.email,
              role: data.role,
              description: data.description
            }
          }
        });
        await handleSuccess();
      } catch (e) {
        handleErrors(e);
      }
    },
    [invite, handleSuccess, handleErrors]
  );

  /* Handlers - Modal */
  const handleOk = useCallback(async () => {
    form
      .validateFields()
      .then(async values => {
        await inviteUser({
          organization: organizationId,
          email: values[InviteUserFormFields.email],
          role: values[InviteUserFormFields.role],
          description: values[InviteUserFormFields.comment]
        });
      })
      .catch(info => {
        console.log("Validate Failed:", info);
      });
  }, [organizationId, form, inviteUser]);

  const handleCancel = useCallback(() => setVisible(false), []);

  const handleChange = useCallback(() => {
    const isError: boolean = form.getFieldsError().filter(field => field.errors && field.errors.length > 0).length > 0;
    const isEmailFilled = !!form.getFieldsValue()[InviteUserFormFields.email];

    setCanSend(!isError && isEmailFilled);

    setCanShowCreditInfo(form.getFieldValue(InviteUserFormFields.role) === Role.Manager);
  }, [form, setCanShowCreditInfo]);

  const handleAfterClose = useCallback(() => {
    form.resetFields();
    setInviteErrors([]);
  }, [form, setInviteErrors]);

  /** EFFECTS */
  // validate default values
  useEffect(() => {
    if (!visible) return;
    setCanShowCreditInfo(defaultValues[InviteUserFormFields.role] === Role.Manager);
    setCanSend(!!defaultValues[InviteUserFormFields.email]);
  }, [visible, defaultValues]);

  return {
    loading,
    sending,
    disabled,
    form,

    state: {
      visible,
      existInviteErrors,
      canSend,
      canShowCreditInfo
    },
    data: {
      allowedRoles,
      defaultValues,
      inviteErrors,
      invitePrice
    },

    open,

    handlers: {
      handleOk,
      handleCancel,
      handleChange,
      handleAfterClose
    }
  };
};

const getDefaultRole = (roles: Role[], userRole?: Maybe<Role>): Role => {
  return roles.find(role => role === userRole) || roles[0];
};
