import { LayoutSimple } from "components/LayoutSimple";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { useTitle } from "react-use";
import { useConfig } from "hooks";
import { Illustration } from "components/Illustration";

export const AccountBlocked = () => {
  const { t } = useTranslation();
  const { config, loading } = useConfig();

  useTitle(t(lg.errorPage.accessError.accountBlocked.heading));

  return (
    <LayoutSimple
      loading={loading}
      childLeft={
        <Illustration
          type="ill-fail2"
          className="max-w-full h-64 mx-auto md:h-auto"
          title={t(lg.errorPage.accessError.accountBlocked.heading)}
        />
      }
      childRight={
        <div>
          <div className="text-lg md:text-2xl text-purple-200 text-center md:text-left md:leading-snug md:mb-10">
            {t(lg.errorPage.accessError.accountBlocked.heading)}
          </div>

          <div className="text-center md:text-left mt-5 md:mt-0 md:mb-20">
            <span className="text-purple-200">{t(lg.errorPage.accessError.accountBlocked.descriptionText)}</span>
            <span>&nbsp;</span>
            <a
              href={config.helpDeskUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="c-link-light"
              title={t(lg.errorPage.accessError.accountBlocked.descriptionLinkText)}
            >
              {t(lg.errorPage.accessError.accountBlocked.descriptionLinkText)}
            </a>
          </div>
        </div>
      }
    />
  );
};
