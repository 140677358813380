import { CSSProperties, PropsWithChildren } from "react";
import { ReactComponent as IconCaretUpTwo } from "assets/icons/caret_up_two.svg";
import { ReactComponent as IconCaretDownTwo } from "assets/icons/caret_down_two.svg";

import { ReactComponent as IconFlagCs } from "assets/icons/flag_cs.svg";
import { ReactComponent as IconFlagEn } from "assets/icons/flag_en.svg";
import { ReactComponent as IconFlagHu } from "assets/icons/flag_hu.svg";
import { ReactComponent as IconFlagUk } from "assets/icons/flag_uk.svg";
import { ReactComponent as IconFlagDe } from "assets/icons/flag_de.svg";

import { ReactComponent as IconReadNothing } from "assets/icons/read_nothing.svg";
import { ReactComponent as IconReadNothingGray } from "assets/icons/read_nothing_gray.svg";
import { ReactComponent as IconReadNothingGreen } from "assets/icons/read_nothing_green.svg";
import { ReactComponent as IconReadNothingOrange } from "assets/icons/read_nothing_orange.svg";
import { ReactComponent as IconReadNothingRed } from "assets/icons/read_nothing_red.svg";
import { ReactComponent as IconReadNothingWhite } from "assets/icons/read_nothing_white.svg";

import { ReactComponent as IconReadQuestionMark } from "assets/icons/read_question_mark.svg";
import { ReactComponent as IconReadQuestionMarkGray } from "assets/icons/read_question_mark_gray.svg";
import { ReactComponent as IconReadQuestionMarkGreen } from "assets/icons/read_question_mark_green.svg";
import { ReactComponent as IconReadQuestionMarkOrange } from "assets/icons/read_question_mark_orange.svg";
import { ReactComponent as IconReadQuestionMarkRed } from "assets/icons/read_question_mark_red.svg";
import { ReactComponent as IconReadQuestionMarkWhite } from "assets/icons/read_question_mark_white.svg";

import { ReactComponent as IconReadNoGeo } from "assets/icons/read_no_geo.svg";
import { ReactComponent as IconReadNoGeoGray } from "assets/icons/read_no_geo_gray.svg";
import { ReactComponent as IconReadNoGeoGreen } from "assets/icons/read_no_geo_green.svg";
import { ReactComponent as IconReadNoGeoOrange } from "assets/icons/read_no_geo_orange.svg";
import { ReactComponent as IconReadNoGeoRed } from "assets/icons/read_no_geo_red.svg";
import { ReactComponent as IconReadNoGeoWhite } from "assets/icons/read_no_geo_white.svg";

import { ReactComponent as IconReadGeo } from "assets/icons/read_geo.svg";
import { ReactComponent as IconReadGeoGray } from "assets/icons/read_geo_gray.svg";
import { ReactComponent as IconReadGeoGreen } from "assets/icons/read_geo_green.svg";
import { ReactComponent as IconReadGeoOrange } from "assets/icons/read_geo_orange.svg";
import { ReactComponent as IconReadGeoRed } from "assets/icons/read_geo_red.svg";
import { ReactComponent as IconReadGeoWhite } from "assets/icons/read_geo_white.svg";

import { ReactComponent as IconCameraNothing } from "assets/icons/camera_nothing.svg";
import { ReactComponent as IconCameraNothingWhite } from "assets/icons/camera_nothing_white.svg";
import { ReactComponent as IconCameraQuestionMark } from "assets/icons/camera_question_mark.svg";
import { ReactComponent as IconCameraQuestionMarkWhite } from "assets/icons/camera_question_mark_white.svg";
import { ReactComponent as IconCameraRuler } from "assets/icons/camera_ruler.svg";
import { ReactComponent as IconCameraRulerWhite } from "assets/icons/camera_ruler_white.svg";
import { ReactComponent as IconCameraQr } from "assets/icons/camera_qr.svg";
import { ReactComponent as IconCameraQrWhite } from "assets/icons/camera_qr_white.svg";
import { ReactComponent as IconCameraSpray } from "assets/icons/camera_spray.svg";
import { ReactComponent as IconCameraSprayWhite } from "assets/icons/camera_spray_white.svg";
import { ReactComponent as IconCameraMap } from "assets/icons/camera_map.svg";
import { ReactComponent as IconCameraMapWhite } from "assets/icons/camera_map_white.svg";

import { ReactComponent as IconModelGeo } from "assets/icons/model_geo.svg";
import { ReactComponent as IconModelNoGeo } from "assets/icons/model_no_geo.svg";
import { ReactComponent as IconModelRuler } from "assets/icons/model_ruler.svg";
import { ReactComponent as IconModelSimple } from "assets/icons/model_simple.svg";
import { ReactComponent as IconModelScale } from "assets/icons/model_scale.svg";
import { ReactComponent as IconModelMap } from "assets/icons/model_map.svg";
import { ReactComponent as IconModelSpray } from "assets/icons/model_spray.svg";
import { ReactComponent as IconModelQr } from "assets/icons/model_qr.svg";

import { ReactComponent as IconSend } from "assets/icons/send.svg";

import { ReactComponent as IconChecklist } from "assets/icons/homepage/checklist.svg";
import { ReactComponent as IconDashboard } from "assets/icons/homepage/dashboard.svg";
import { ReactComponent as IconData } from "assets/icons/homepage/data.svg";
import { ReactComponent as IconDevice } from "assets/icons/homepage/device.svg";
import { ReactComponent as IconPriceTag } from "assets/icons/homepage/price-tag.svg";
import { ReactComponent as IconSmartphone } from "assets/icons/homepage/smartphone.svg";
import { ReactComponent as IconTeam } from "assets/icons/homepage/team.svg";
import { ReactComponent as IconWebProgramming } from "assets/icons/homepage/web-programming.svg";
import { ReactComponent as IconPhone } from "assets/icons/homepage/phone.svg";
import { ReactComponent as IconEmail } from "assets/icons/homepage/e-mail.svg";
import { ReactComponent as IconXing } from "assets/icons/homepage/xing.svg";
import { ReactComponent as Eshop } from "assets/icons/homepage/eshop.svg";

import clsx from "clsx";

const icons = {
  "camera-nothing": IconCameraNothing,
  "camera-nothing-white": IconCameraNothingWhite,
  "camera-qr": IconCameraQr,
  "camera-qr-white": IconCameraQrWhite,
  "camera-question-mark": IconCameraQuestionMark,
  "camera-question-mark-white": IconCameraQuestionMarkWhite,
  "camera-ruler": IconCameraRuler,
  "camera-ruler-white": IconCameraRulerWhite,
  "camera-spray": IconCameraSpray,
  "camera-spray-white": IconCameraSprayWhite,
  "camera-map": IconCameraMap,
  "camera-map-white": IconCameraMapWhite,
  "caret-down-two": IconCaretDownTwo,
  "caret-up-two": IconCaretUpTwo,
  "flag-cs": IconFlagCs,
  "flag-en": IconFlagEn,
  "flag-hu": IconFlagHu,
  "flag-uk": IconFlagUk,
  "flag-de": IconFlagDe,
  "read-geo": IconReadGeo,
  "read-geo-gray": IconReadGeoGray,
  "read-geo-green": IconReadGeoGreen,
  "read-geo-orange": IconReadGeoOrange,
  "read-geo-red": IconReadGeoRed,
  "read-geo-white": IconReadGeoWhite,
  "read-no-geo": IconReadNoGeo,
  "read-no-geo-gray": IconReadNoGeoGray,
  "read-no-geo-green": IconReadNoGeoGreen,
  "read-no-geo-orange": IconReadNoGeoOrange,
  "read-no-geo-red": IconReadNoGeoRed,
  "read-no-geo-white": IconReadNoGeoWhite,
  "read-nothing": IconReadNothing,
  "read-nothing-gray": IconReadNothingGray,
  "read-nothing-green": IconReadNothingGreen,
  "read-nothing-orange": IconReadNothingOrange,
  "read-nothing-red": IconReadNothingRed,
  "read-nothing-white": IconReadNothingWhite,
  "read-question-mark": IconReadQuestionMark,
  "read-question-mark-gray": IconReadQuestionMarkGray,
  "read-question-mark-green": IconReadQuestionMarkGreen,
  "read-question-mark-orange": IconReadQuestionMarkOrange,
  "read-question-mark-red": IconReadQuestionMarkRed,
  "read-question-mark-white": IconReadQuestionMarkWhite,
  "model-geo": IconModelGeo,
  "model-no-geo": IconModelNoGeo,
  "model-ruler": IconModelRuler,
  "model-simple": IconModelSimple,
  "model-scale": IconModelScale,
  "model-map": IconModelMap,
  "model-spray": IconModelSpray,
  "model-qr": IconModelQr,
  send: IconSend,

  /* Homepage icons */
  checklist: IconChecklist,
  dashboard: IconDashboard,
  data: IconData,
  device: IconDevice,
  tag: IconPriceTag,
  smartphone: IconSmartphone,
  team: IconTeam,
  "web-programming": IconWebProgramming,
  phone: IconPhone,
  "e-mail": IconEmail,
  xing: IconXing,
  eshop: Eshop
};

export type CustomIconType = keyof typeof icons;

type Props = {
  type: CustomIconType;
  className?: string;
  style?: CSSProperties;
};

export const CustomIcon = ({ type = "read-nothing-green", className, style }: PropsWithChildren<Props>) => {
  const Icon = icons[type];
  return (
    <span role="img" className={clsx("anticon", className)} style={style}>
      <Icon />
    </span>
  );
};
