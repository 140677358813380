import { ChangeEvent, PropsWithChildren, useCallback, useMemo, useRef, useState } from "react";
import GoogleMapReact, { Coords, Props as GoogleMapReactProps } from "google-map-react";
import { useMap } from "components/Map/useMap";
import { GroundControlPointMarker } from "components/Map/GroundControlPointMarker";
import { coordsEqual, coordsToString, createMapOptions, parseCoords, roundCoord } from "components/Map/helpers";
import { lg } from "assets/translations";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import { Link } from "components/Link";
import { getEnv } from "helpers";
import { FormInstance } from "antd/es/form";
import { ValidatorRule } from "rc-field-form/lib/interface";

type GroundControlPoint = {
  id: string;
  order: number;
  coords: Coords;
};

type GroundControlPointChangeHandler = (valid: boolean, coords?: Coords, description?: string) => any;

type Props = {
  constructionSite: Coords[];
  constructionObject: Coords[];
  groundControlPoints?: GroundControlPoint[];
  insertMode?: boolean;
  order?: number;
  defaultValue?: Coords;
  defaultDescription?: string;
  disabled?: boolean;
  onChange?: GroundControlPointChangeHandler;
};

export const GroundControlPointsMap = ({
  constructionSite,
  constructionObject,
  groundControlPoints = [],
  insertMode = false,
  order = 1,
  defaultValue,
  defaultDescription = "",
  disabled = false,
  onChange = () => {}
}: PropsWithChildren<Props>) => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const formRef = useRef<FormInstance>(null);
  const { current: form } = formRef;

  const { mapContainerRef, mapHeight, handleGoogleApiLoaded } = useMap(
    constructionSite,
    constructionObject,
    "groundControlPoints"
  );

  const [coordsParsed, setCoordsParsed] = useState<Coords | undefined>(defaultValue);
  const [coordsParsedHistory, setCoordsParsedHistory] = useState<Coords[]>([]);
  const [isMapDraggable, setIsMapDraggable] = useState(true);

  const coordsValueFieldName = useMemo(() => `coordsValue${order}`, [order]);
  const descriptionFieldName = useMemo(() => `description${order}`, [order]);

  useDebounce(
    () => {
      if (coordsParsed && coordsEqual(coordsParsed, coordsParsedHistory.slice(-1)[0])) return;

      if (coordsParsed) setCoordsParsedHistory([...coordsParsedHistory, coordsParsed]);
      onChange(coordsParsed !== undefined, coordsParsed, form?.getFieldsValue()[descriptionFieldName]);
    },
    500,
    [coordsParsedHistory, coordsParsed, descriptionFieldName, onChange]
  );

  /** Handle coords input change */
  const handleChangeCoordsValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setCoordsParsed(parseCoords(e.currentTarget.value)),
    []
  );

  /** Handle description textarea change */
  const handleChangeDescription = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => onChange(coordsParsed !== undefined, coordsParsed, e.currentTarget.value),
    [coordsParsed, onChange]
  );

  const validateCoords: ValidatorRule["validator"] = useCallback(
    (rule, value) => {
      // @ts-expect-error: Validator type is missing the field key
      const { field } = rule;
      return (field === coordsValueFieldName && (!value || !value.length)) || parseCoords(value)
        ? Promise.resolve()
        : Promise.reject(t(lg.map.validations.invalidCoordinates));
    },
    [coordsValueFieldName, t]
  );

  const handleClickAbort = useCallback(() => {
    form?.setFieldsValue({ [coordsValueFieldName]: "" });
    setCoordsParsed(undefined);
    setCoordsParsedHistory([]);
  }, [form, coordsValueFieldName]);

  const handleClickBack = useCallback(() => {
    const coordsParsedHistoryCopy = [...coordsParsedHistory];
    coordsParsedHistoryCopy.pop();
    const lastCoords = coordsParsedHistoryCopy.slice(-1)[0];

    form?.setFieldsValue({ [coordsValueFieldName]: !lastCoords ? "" : `${lastCoords.lat}, ${lastCoords.lng}` });
    setCoordsParsed(lastCoords);
    setCoordsParsedHistory(coordsParsedHistoryCopy);
  }, [form, coordsParsedHistory, coordsValueFieldName]);

  const updateMarkerPosition = useCallback(
    ({ lat, lng }: { lat: number; lng: number }) => {
      if (!lat || !lng) return;

      const coords = { lat: roundCoord(lat), lng: roundCoord(lng) };

      setCoordsParsed(coords);
      form?.setFieldsValue({ [coordsValueFieldName]: coordsToString(coords) });
    },
    [form, coordsValueFieldName]
  );

  const handleChildMouseMove: Required<GoogleMapReactProps>["onChildMouseMove"] = useCallback(
    (key, { value }, { lat, lng }: { lat: number; lng: number }) => updateMarkerPosition({ lat, lng }),
    [updateMarkerPosition]
  );
  const handleChildMouseDown = useCallback(() => setIsMapDraggable(false), []);
  const handleChildMouseUp = useCallback(() => setIsMapDraggable(true), []);

  const handleClickPointLink = useCallback(
    (id: string) => document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start" }),
    []
  );

  return (
    <div className="c-grid">
      {/** left - map */}
      <div className="c-grid-column">
        <div ref={mapContainerRef} className="w-full" style={{ height: mapHeight }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: getEnv("REACT_APP_MAP_API_KEY"), language: language }}
            options={createMapOptions}
            defaultCenter={{ lat: 49.8175, lng: 15.473 }} // Czech Republic
            defaultZoom={7}
            draggable={isMapDraggable}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={handleGoogleApiLoaded}
            {...(!insertMode
              ? {}
              : {
                  onClick: updateMarkerPosition,
                  onChildMouseMove: handleChildMouseMove,
                  onChildMouseDown: handleChildMouseDown,
                  onChildMouseUp: handleChildMouseUp
                })}
          >
            {coordsParsed && <GroundControlPointMarker lat={coordsParsed.lat} lng={coordsParsed.lng} value={order} />}
            {!insertMode &&
              groundControlPoints.map(({ id, order, coords }) => (
                <GroundControlPointMarker key={id} lat={coords.lat} lng={coords.lng} value={order} />
              ))}
          </GoogleMapReact>
        </div>

        {insertMode && (
          <div className="flex mt-5">
            <Button disabled={!coordsParsed || disabled} className="mr-5" onClick={handleClickAbort}>
              {t(lg.map.buttons.cancel)}
            </Button>
            <div className="flex">
              <Button disabled={!coordsParsedHistory.length || disabled} onClick={handleClickBack}>
                {t(lg.map.buttons.back)}
              </Button>
            </div>
          </div>
        )}
      </div>

      {/** right - coordinates */}
      <div className="c-grid-column">
        {insertMode && (
          <Form
            ref={formRef}
            layout="vertical"
            initialValues={{
              [coordsValueFieldName]: !defaultValue ? "" : `${defaultValue.lat}, ${defaultValue.lng}`,
              [descriptionFieldName]: defaultDescription
            }}
          >
            <Form.Item
              name={coordsValueFieldName}
              label={t(lg.map.form.gps.label)}
              rules={[{ validator: validateCoords, validateTrigger: "onChange" }]}
            >
              <Input type="text" placeholder={t(lg.map.markPlaceholder)} onChange={handleChangeCoordsValue} />
            </Form.Item>
            <Form.Item name={descriptionFieldName} label={t(lg.map.form.groundPoint.label)}>
              <Input.TextArea
                placeholder={t(lg.map.form.groundPoint.placeholder)}
                rows={3}
                onChange={handleChangeDescription}
              />
            </Form.Item>
          </Form>
        )}

        {!insertMode &&
          groundControlPoints.map(({ id, order, coords }, i) => (
            <div key={id} className={i > 0 ? "mt-5" : ""}>
              <div>
                <Link onClick={() => handleClickPointLink(`point-${order}`)}>
                  {t(lg.map.markName)} {order}
                </Link>
              </div>
              {/* disable Edge formatting: x-ms-format-detection="none"*/}
              <div className="text-secondary" x-ms-format-detection="none">
                {coordsToString(coords)}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
