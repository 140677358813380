import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import {
  DocumentationRecordOrderByEnum,
  DocumentationRecordsTableAtConstructionObjectDetailPageQuery,
  DocumentationRecordState,
  DocumentationRecordStateFilterEnum,
  DocumentationRecordType,
  DocumentationRecordTypeFilterEnum,
  DocumentationRecordVideoType,
  DocumentationRecordVideoTypeFilterEnum,
  Maybe,
  resolveError,
  UseCaseEvent,
  useDocumentationRecordsTableAtConstructionObjectDetailPageQuery
} from "api";
import { PlusOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import { Link } from "../Link";
import { routes, setRouteParams } from "routes";
import { navigate } from "@reach/router";
import { StateIndicatorDocumentationRecord } from "components/Indicators/State/StateIndicatorDocumentationRecord";
import { useFormat } from "hooks/useFormat";
import { TypeIndicatorDocumentationRecord, TypeIndicatorDocumentationVideo } from "components/Indicators/Type";
import { enumToValues, openInNewTab, TableProps, usePagination } from "helpers";
import { useAlert, useLogEvent, useTableDataStatus } from "hooks";
import {
  documentationRecordStateFilters,
  documentationRecordTypeFilters,
  documentationRecordVideoTypesFilter
} from "api/enums";
import { SimpleLoader } from "components/SimpleLoader";

type TableItem = NonNullable<
  DocumentationRecordsTableAtConstructionObjectDetailPageQuery["constructionObject"]
>["documentationRecords"]["edges"][0]["node"] & {
  constructionPhaseOrder: number;
  videoType: Maybe<DocumentationRecordVideoType>;
  threeDModelId: Maybe<string>;
};
type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  name: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  constructionPhaseOrder: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionPhaseOrder],
    descend: [DocumentationRecordOrderByEnum.ConstructionPhaseOrderDesc]
  },
  createdAt: {
    ascend: [DocumentationRecordOrderByEnum.CreatedAt],
    descend: [DocumentationRecordOrderByEnum.CreatedAtDesc]
  }
};

type Props = {
  constructionObjectId: string;
  constructionSiteId: string;
  hasCreateButton?: boolean;
};

export const DocumentationRecordsTable = ({
  constructionObjectId,
  constructionSiteId,
  hasCreateButton = true
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { logEvent } = useLogEvent();
  const { formatDate } = useFormat();

  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const [orderBy, setOrderBy] = useState<DocumentationRecordOrderByEnum[]>(orderByMap.createdAt.descend);
  const [typesFilter, setTypesFilter] = useState<DocumentationRecordTypeFilterEnum[]>([]);
  const [videoTypesFilter, setVideoTypesFilter] = useState<DocumentationRecordVideoTypeFilterEnum[]>([]);
  const [statesFilter, setStatesFilter] = useState<DocumentationRecordStateFilterEnum[]>([]);

  const { data, loading } = useDocumentationRecordsTableAtConstructionObjectDetailPageQuery({
    variables: {
      constructionObjectId,
      skip: skip,
      orderBy,
      types: typesFilter,
      videoTypes: videoTypesFilter,
      states: statesFilter
    },
    fetchPolicy: "cache-and-network",
    skip: !constructionSiteId || !constructionObjectId,
    onError: e => resolveError(e, undefined, showAlert)
  });

  const documentationRecords = useMemo(() => data?.constructionObject?.documentationRecords, [data]);

  useEffect(() => setTotal(documentationRecords?.totalCount), [documentationRecords]);

  const tableData = useMemo<TableItem[] | undefined>(
    () =>
      documentationRecords?.edges?.flatMap(({ node }) =>
        (node ? [node] : []).map(item => ({
          ...item,
          constructionPhaseOrder: item.constructionPhase.order,
          videoType: item.documentationRecordVideo?.type || null,
          threeDModelId: item.threeDModel?.id || null
        }))
      ),
    [documentationRecords]
  );

  const handleViewThreeDModel = useCallback(
    async (id: string) => {
      await logEvent(UseCaseEvent.ThreeDModelView);
      openInNewTab(setRouteParams(routes.threeDViewer, { id }));
    },
    [logEvent]
  );

  const columns = useMemo<TablePropType["columns"]>(
    () => [
      {
        title: t(lg.constructionObject.pageDetail.documentation.table.header.documentation),
        dataIndex: "name",
        key: "name",
        render: (name: any, { id }) => (
          <Link href={setRouteParams(routes.documentationRecordDetail, { id })}>
            <span className="text-purple-600 font-medium">{name}</span>
          </Link>
        ),
        sorter: true
      },
      {
        title: t(lg.constructionObject.pageDetail.documentation.table.header.phase),
        dataIndex: "constructionPhaseOrder",
        key: "constructionPhaseOrder",
        render: (constructionPhaseOrder: number, { constructionPhase }) => (
          <Tooltip title={constructionPhase.name}>
            <span className="text-secondary cursor-default">{constructionPhaseOrder}.</span>
          </Tooltip>
        ),
        sorter: true
      },
      {
        title: t(lg.constructionObject.pageDetail.documentation.table.header.createdAt),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt: string) => <span className="text-secondary">{formatDate(createdAt, "machine", true)}</span>,
        className: "whitespace-nowrap",
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        title: t(lg.constructionObject.pageDetail.documentation.table.header.state),
        dataIndex: "state",
        key: "state",
        render: (state: DocumentationRecordState) => <StateIndicatorDocumentationRecord state={state} />,
        filters: enumToValues<DocumentationRecordStateFilterEnum>(DocumentationRecordStateFilterEnum).map(state => ({
          text: t(documentationRecordStateFilters[state].nameI18nKey),
          value: state
        }))
      },
      {
        title: t(lg.constructionObject.pageDetail.documentation.table.header.documentationType),
        dataIndex: "type",
        key: "type",
        render: (type: DocumentationRecordType) => <TypeIndicatorDocumentationRecord type={type} />,
        filters: enumToValues<DocumentationRecordTypeFilterEnum>(DocumentationRecordTypeFilterEnum).map(type => ({
          text: t(documentationRecordTypeFilters[type].nameI18nKey),
          value: type
        }))
      },
      {
        title: t(lg.constructionObject.pageDetail.documentation.table.header.videoType),
        dataIndex: "videoType",
        key: "videoType",
        render: videoType =>
          !videoType ? (
            <span className="text-secondary">{t(lg.constructionObject.pageDetail.documentation.table.noVideos)}</span>
          ) : (
            <TypeIndicatorDocumentationVideo type={videoType} />
          ),
        filters: enumToValues<DocumentationRecordVideoTypeFilterEnum>(DocumentationRecordVideoTypeFilterEnum).map(
          type => ({
            text: t(documentationRecordVideoTypesFilter[type].nameI18nKey),
            value: type
          })
        )
      },
      {
        title: t(lg.constructionObject.pageDetail.documentation.table.header.threeDModel),
        dataIndex: "threeDModelId",
        key: "threeDModelId",
        render: (threeDModelId, record) => {
          const { threeDModel } = record;
          const hasModel: boolean = !!threeDModel?.modelFile?.url;
          if (!threeDModelId || !hasModel) {
            return (
              <span className="text-secondary">
                {t(lg.constructionObject.pageDetail.documentation.table.noThreeDModels)}
              </span>
            );
          }

          return (
            <Link
              onClick={() => handleViewThreeDModel(threeDModelId)}
              disabled={record.state !== DocumentationRecordState.Finished}
            >
              {t(lg.table.actions.show)}
            </Link>
          );
        }
      }
    ],
    [formatDate, handleViewThreeDModel, t]
  );

  const handleClickCreate = useCallback(
    async () =>
      await navigate(
        setRouteParams(routes.documentationRecordCreateType, { constructionSiteId, constructionObjectId })
      ),
    [constructionSiteId, constructionObjectId]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setTypesFilter(filter.type || []);
      setVideoTypesFilter(filter.videoType || []);
      setStatesFilter(filter.state || []);
    },
    [setPage]
  );

  const { hasData, hasFilters } = useTableDataStatus(tableData?.length, [typesFilter, videoTypesFilter, statesFilter]);

  if (loading && !hasFilters && !hasData)
    return (
      <div className="flex justify-center w-full p-10">
        <SimpleLoader />
      </div>
    );

  /* No table data */
  if (!hasFilters && !hasData) {
    return (
      <div className="flex flex-col items-center mt-10">
        <div className="text-xs text-gray-700 text-center whitespace-pre-line">
          {t(lg.constructionObject.pageDetail.documentation.table.noDataText)}
        </div>
        {hasCreateButton && (
          <Button type="dashed" icon={<PlusOutlined />} className="mt-5" onClick={handleClickCreate}>
            {t(lg.constructionObject.pageDetail.documentation.table.buttonObjectCreateText)}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={hasCreateButton ? "mt-5" : "mt-10"}>
      {hasCreateButton && (
        <div className="flex justify-end">
          <Button icon={<PlusOutlined />} onClick={handleClickCreate}>
            {t(lg.constructionObject.pageDetail.documentation.table.buttonObjectCreateText)}
          </Button>
        </div>
      )}

      <div className="mt-5">
        <Table<TableItem>
          dataSource={tableData}
          columns={columns}
          rowKey={"id"}
          loading={{ delay: hasData ? 300 : 0, spinning: loading }}
          showSorterTooltip={false}
          onChange={handleOnChange}
          pagination={pagination}
          scroll={{ x: true }}
        />
      </div>
    </div>
  );
};
