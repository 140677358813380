import { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/Section";
import { lg } from "assets/translations";
import { SimpleGroundControlPointsOverview } from "./SimpleGroundControlPointsOverview";
import { StandardGroundControlPointsOverview } from "./StandardGroundControlPointsOverview";

type SimpleGroundControlPointsOverviewProps = ComponentPropsWithoutRef<typeof SimpleGroundControlPointsOverview>;
type StandardGroundControlPointsOverviewProps = ComponentPropsWithoutRef<typeof StandardGroundControlPointsOverview>;

type Props = SimpleGroundControlPointsOverviewProps & StandardGroundControlPointsOverviewProps;

export const GroundControlPointsOverview = ({
  constructionSiteCoords,
  constructionObjectCoords,
  groundControlPoints,
  simpleGroundControlPointCount,
  simpleGroundControlPoint,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const hasSimpleGroundControlPoint = !!simpleGroundControlPointCount;
  const hasStandardGroundControlPoints = groundControlPoints && groundControlPoints.length > 0;
  const hasAnyPoint = hasSimpleGroundControlPoint || hasStandardGroundControlPoints;

  const title = t(lg.taskOperator.groundControlPoints.title);
  const titleSuffix = t(lg.documentationRecord.pageDetail.simplifiedGroundControlPoints.titleSuffix);
  const fullTitle =
    hasSimpleGroundControlPoint && !hasStandardGroundControlPoints ? `${title} (${titleSuffix})` : title;

  return (
    <Section title={fullTitle} className={className}>
      {!hasAnyPoint ? (
        <div className="text-xs text-gray-700 text-center whitespace-pre-line mt-10 print_mt-2">
          {t(lg.documentationRecord.pageDetail.groundControlPoints.noData)}
        </div>
      ) : (
        <>
          {hasSimpleGroundControlPoint ? (
            <SimpleGroundControlPointsOverview
              simpleGroundControlPointCount={simpleGroundControlPointCount}
              simpleGroundControlPoint={simpleGroundControlPoint}
              className="mt-10 print_mt-2"
            />
          ) : hasStandardGroundControlPoints ? (
            <StandardGroundControlPointsOverview
              constructionSiteCoords={constructionSiteCoords}
              constructionObjectCoords={constructionObjectCoords}
              groundControlPoints={groundControlPoints}
              className="mt-10 print_mt-2"
            />
          ) : null}
        </>
      )}
    </Section>
  );
};
