import { cloneElement, PropsWithChildren, ReactElement, useMemo } from "react";
import { Link as LinkRouter } from "@reach/router";
import clsx from "clsx";
import { Maybe } from "api";

type Props = {
  href?: Maybe<string>;
  onClick?: () => any;
  icon?: ReactElement;
  className?: string;
  type?: "primary" | "light";
  children?: any;
  disabled?: boolean;
};

export const Link = ({
  href = "",
  onClick = () => {},
  icon,
  className,
  type = "primary",
  disabled = false,
  children,
  ...res
}: PropsWithChildren<Props>) => {
  const style = useMemo<string>(
    () => clsx("inline-flex items-center", type === "light" ? "c-link-light" : "c-link", className),
    [className, type]
  );

  if (!href || disabled)
    return (
      <button
        onClick={onClick}
        className={clsx(style, disabled ? "text-gray-600 cursor-not-allowed" : "")}
        disabled={disabled}
        {...res}
      >
        <Content icon={icon} children={children} />
      </button>
    );

  return (
    <LinkRouter onClick={onClick} to={href} className={style} {...res}>
      <Content icon={icon} children={children} />
    </LinkRouter>
  );
};

type ContentProps = Pick<Props, "icon" | "children">;

const Content = (props: ContentProps) => (
  <>
    {props.icon
      ? cloneElement(props.icon, {
          className: clsx([props.icon.props.className, props.children ? "mr-2" : ""])
        })
      : null}
    {props.children}
  </>
);
