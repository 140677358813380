import Xarrow from "react-xarrows";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

import { ComponentPropsWithoutRef, useCallback, useRef } from "react";
import { getFromLeftToCenterOffset } from "helpers";
import { DiagramCard } from "components/Homepage/DiagramCard";
import { lg } from "assets/translations";
import { DiagramItem } from "components/Homepage/DiagramItem";

const arrowAnchorSpacing: number = 5;
const xArrowConfig: Omit<ComponentPropsWithoutRef<typeof Xarrow>, "start" | "end"> = {
  path: "grid",
  strokeWidth: 1,
  color: "#C3D1E6", // blue-400
  endAnchor: [
    { position: "left", offset: { x: -arrowAnchorSpacing, y: 0 } },
    { position: "right", offset: { x: arrowAnchorSpacing, y: 0 } },
    { position: "top", offset: { x: 0, y: -arrowAnchorSpacing } },
    { position: "bottom", offset: { x: 0, y: arrowAnchorSpacing } }
  ]
};
export const DiagramSection = () => {
  const { t } = useTranslation();
  /*
   * useWindowSize re-render component on the window resize
   * Re-render on the window resize is need by re-render Diagram arrows
   * */
  useWindowSize();

  const videoRecordRef = useRef<HTMLDivElement>(null);
  const photoDocumentationRef = useRef<HTMLDivElement>(null);
  const authorizationRef = useRef<HTMLDivElement>(null);
  const constructionSiteInitRef = useRef<HTMLDivElement>(null);
  const documentationRecordCreateRef = useRef<HTMLDivElement>(null);
  const dataInCloudsRef = useRef<HTMLDivElement>(null);
  const outputsRef = useRef<HTMLDivElement>(null);

  /*
   * Compute value used at Xarrow component prop endAnchor
   * Arrows are drawn from the center of the start element to the center of the end element
   * but for connections between dataInCloudsRef and outputsRef is required straight arrow
   * */
  const getDataInCloudsArrowOffset = useCallback((): number => {
    // elements does not exist
    if (!outputsRef.current || !dataInCloudsRef.current) return 0;
    // target element is not under start element
    if (dataInCloudsRef.current.offsetTop > outputsRef.current.offsetTop) return 0;

    const outputCenter = getFromLeftToCenterOffset(outputsRef.current);
    const itemCenter = getFromLeftToCenterOffset(dataInCloudsRef.current);
    return itemCenter - outputCenter;
  }, []);

  return (
    <div className="flex flex-wrap justify-between -m-1 md:-m-4 md:-m-1">
      <div className="w-1/2 md:w-1/3 p-1 md:p-4 md:p-1 md:max-w-xs">
        <DiagramCard
          title={t(lg.homepage.howItWorks.diagram.titles.terrain)}
          customIconType={"smartphone"}
          className="w-full h-full"
        >
          <DiagramItem ref={videoRecordRef} label={t(lg.homepage.howItWorks.diagram.blocks.videoRecord)} />
          <DiagramItem
            ref={photoDocumentationRef}
            label={t(lg.homepage.howItWorks.diagram.blocks.photoDocumentation)}
          />
        </DiagramCard>
      </div>
      <div className="w-1/2 md:w-1/3 p-1 md:p-4 md:p-1 md:max-w-xs">
        <DiagramCard
          title={t(lg.homepage.howItWorks.diagram.titles.office)}
          customIconType={"web-programming"}
          className="w-full h-full"
        >
          <DiagramItem ref={authorizationRef} label={t(lg.homepage.howItWorks.diagram.blocks.authorization)} />
          <DiagramItem
            ref={constructionSiteInitRef}
            label={t(lg.homepage.howItWorks.diagram.blocks.constructionSiteInit)}
          />
          <DiagramItem
            ref={documentationRecordCreateRef}
            label={t(lg.homepage.howItWorks.diagram.blocks.documentationRecordCreate)}
          />
          <DiagramItem ref={dataInCloudsRef} label={t(lg.homepage.howItWorks.diagram.blocks.dataInClouds)} />
        </DiagramCard>
      </div>
      <div className="w-full md:w-1/3 p-1 mt-2 md:mt-0 md:p-4 md:p-1 md:max-w-xs">
        <DiagramCard
          active
          ref={outputsRef}
          title={t(lg.homepage.howItWorks.diagram.titles.outputs)}
          customIconType={"data"}
          className="w-full h-full"
        >
          <DiagramItem label={t(lg.homepage.howItWorks.diagram.blocks.threeDModel)} />
          <DiagramItem label={t(lg.homepage.howItWorks.diagram.blocks.orthophoto)} />
          <DiagramItem label={t(lg.homepage.howItWorks.diagram.blocks.texture)} />
          <DiagramItem label={t(lg.homepage.howItWorks.diagram.blocks.points)} />
        </DiagramCard>
      </div>

      {/* Arrows */}
      <Xarrow start={videoRecordRef} end={photoDocumentationRef} {...xArrowConfig} />
      <Xarrow start={photoDocumentationRef} end={dataInCloudsRef} {...xArrowConfig} />

      <Xarrow start={authorizationRef} end={constructionSiteInitRef} {...xArrowConfig} />
      <Xarrow start={constructionSiteInitRef} end={documentationRecordCreateRef} {...xArrowConfig} />
      <Xarrow start={documentationRecordCreateRef} end={videoRecordRef} {...xArrowConfig} />
      <Xarrow
        start={dataInCloudsRef}
        end={outputsRef}
        {...xArrowConfig}
        // override xArrowConfig.endAnchor
        // on the small screen is end element below start element.
        // Is required had a straight arrow so spacing is counted by custom method getDataInCloudsArrowOffset
        endAnchor={[
          {
            position: "left",
            offset: { x: -arrowAnchorSpacing, y: 0 }
          },
          {
            position: "top",
            offset: { x: getDataInCloudsArrowOffset(), y: -arrowAnchorSpacing }
          }
        ]}
      />
    </div>
  );
};
