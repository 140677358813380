import { PropsWithChildren } from "react";
import { Modal } from "antd";
import { InfoCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { useFormat } from "hooks/useFormat";
import { getHasEnoughCredits } from "helpers";
import { usePricing } from "hooks";

type Props = {
  visible: boolean;
  title: string;
  buttonOk: string;
  cost: number;
  resources: number;
  onOk: () => any;
  onCancel: () => any;
};

export const ModalCost = ({ visible, title, buttonOk, cost, resources, onOk, onCancel }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { creditCost, creditCostGenitive } = useFormat();
  const { loading, shopUrl } = usePricing();

  const hasEnoughCredits = getHasEnoughCredits(resources, cost);
  const isSubmitDisabled = !hasEnoughCredits;
  const isWarningVisible = !loading && !hasEnoughCredits;

  return (
    <Modal
      title={
        <div className="flex items-center">
          <QuestionCircleOutlined className="text-lg text-gold-600" />
          <span className="ml-4">{title}</span>
        </div>
      }
      open={visible}
      okText={buttonOk}
      cancelText={t(lg.documentationRecord.pageCreate.modals.createCost.buttonCancelText)}
      okButtonProps={{ type: "primary", disabled: isSubmitDisabled }}
      onOk={onOk}
      onCancel={onCancel}
      closable={false}
    >
      <p className="text-base mb-5">
        {t(lg.documentationRecord.pageCreate.modals.createCost.description, {
          credits: creditCostGenitive(cost, false)
        })}
      </p>

      <div className="flex text-right">
        <div className="mr-1">
          <div>{t(lg.documentationRecord.pageCreate.modals.createCost.credits.actualState)}:</div>
          <div className="font-medium mt-2">
            {t(lg.documentationRecord.pageCreate.modals.createCost.credits.price)}:
          </div>
        </div>
        <div className="text-purple-600">
          <div>{creditCost(resources)}</div>
          <div className="font-medium mt-2">{creditCost(cost)}</div>
        </div>
      </div>

      {isWarningVisible ? (
        <div className="flex items-center gap-4 mt-5">
          <div className="flex items-center gap-2">
            <InfoCircleOutlined className="text-red-600 text-lg" />
            <span className="font-medium">
              {t(lg.warning)}: {t(lg.errors.notEnoughCredits)}
            </span>
          </div>

          <a href={shopUrl} target="_blank" rel="noopener noreferrer" className={"c-link"}>
            {t(lg.documentationRecord.pageCreate.modals.createCost.topUpCredits.label)} {">"}
          </a>
        </div>
      ) : null}
    </Modal>
  );
};
