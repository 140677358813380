import { useTranslation } from "react-i18next";
import { useAlert, useConfig } from "hooks";
import { useCopyToClipboard } from "react-use";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TimeLimit, timeLimits } from "api/enums";
import { enumToValues, getThreeDViewerModelUrl } from "helpers";
import { resolveError, useThreeDModelShareMutation } from "api";
import { Button, message, Select, Spin } from "antd";
import { lg } from "assets/translations";
import { CopyOutlined } from "@ant-design/icons";
import { SendShareModelForm } from "./SendShareModelForm";

type ShareModelFormProps = {
  modelId: string;
  onAfterShare: (code?: string) => void;
  disabled?: boolean;
  className?: string;
};

export const ShareModelForm = ({ modelId, onAfterShare, disabled, className }: ShareModelFormProps) => {
  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const [copyState, copyToClipboard] = useCopyToClipboard();

  const [timeLimit, setTimeLimit] = useState<TimeLimit>(TimeLimit.unlimited);
  const timeLimitValues = useMemo<TimeLimit[]>(() => enumToValues<TimeLimit>(TimeLimit), []);

  const [modelShareCode, setModelShareCode] = useState<string>();

  const [generateCode, { loading: generatingCode, called: calledGenerateCode }] = useThreeDModelShareMutation({
    variables: {
      input: {
        threeDModel: modelId,
        expiresAt: timeLimits[timeLimit].value(),
        public: true
      }
    },
    onCompleted: result => {
      const code = result?.threeDModel?.share?.code;
      setModelShareCode(code);
      onAfterShare(code);
    },
    onError: e => resolveError(e, {}, showAlert)
  });

  /* model share code */
  const { config, loading: loadingConfig } = useConfig();

  /* external URL to the 3D Viewer with Model */
  const viewerUrl = useMemo(() => config.threeDViewerUrl, [config.threeDViewerUrl]);
  const generatedUrl = useMemo<string | undefined>(
    () => viewerUrl && modelShareCode && getThreeDViewerModelUrl(viewerUrl, modelShareCode, i18n.language),
    [modelShareCode, viewerUrl, i18n]
  );

  const handleChangeTimeLimit = useCallback((value: TimeLimit) => setTimeLimit(value), []);
  const handleClickGenerateUrl = useCallback(() => generateCode(), [generateCode]);
  const handleClickCopyToClipboard = useCallback(
    () => generatedUrl && copyToClipboard(generatedUrl),
    [copyToClipboard, generatedUrl]
  );

  /* handle successful action CopyToClipboard */
  useEffect(() => {
    if (!copyState.value || copyState.error) return;
    message.success(t(lg.copyToClipboardSuccess, { value: copyState.value }), 1);
  }, [copyState, t]);

  if (loadingConfig) return <Spin />;

  return (
    <div className={className}>
      {/*
       *  Time Limit
       */}
      <div>
        <div className="text-xs">{t(lg.threeDViewer.modals.shareThreeDModel.shareModel.timeLimit.label)}</div>
        <div className="flex">
          <Select<TimeLimit>
            value={timeLimit}
            size={"small"}
            className="flex-grow"
            onChange={handleChangeTimeLimit}
            disabled={disabled || generatingCode}
            data-cy={"select-timeLimit"}
          >
            {timeLimitValues.map(value => (
              <Select.Option value={value} key={value} data-cy={"select-timeLimit-option"}>
                {t(timeLimits[value].labelI18nKey)}
              </Select.Option>
            ))}
          </Select>
          <Button
            type={"primary"}
            size={"small"}
            className="ml-3"
            onClick={handleClickGenerateUrl}
            disabled={disabled || generatingCode}
            data-cy={"action-generateShareCode"}
          >
            {t(lg.threeDViewer.modals.shareThreeDModel.shareModel.actions.buttonGenerateUrl)}
          </Button>
        </div>
      </div>

      {/*
       * Generating URL
       */}
      {generatingCode && <Spin className="block mx-auto mt-3" data-cy={"indicator-generating"} />}

      {/*
       * Generated URL
       */}
      {!disabled && !generatingCode && generatedUrl && (
        <div className="flex items-center mt-3">
          <a
            href={generatedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="c-link font-normal truncate"
            data-cy={"shareUrl"}
          >
            {generatedUrl}
          </a>
          <Button type={"link"} onClick={handleClickCopyToClipboard} icon={<CopyOutlined />} />
        </div>
      )}

      {/*
       * Send Share
       */}
      {!disabled && calledGenerateCode && modelShareCode && (
        <>
          <SendShareModelForm modelShareCode={modelShareCode} className="mt-2" />
          <div className="text-right text-xs mt-3">{t(lg.threeDViewer.modals.shareThreeDModel.shareModel.notice)}</div>
        </>
      )}
    </div>
  );
};
