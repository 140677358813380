import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations/lg";
import { Section } from "components/Section";
import { Link } from "components/Link";
import { StateIndicator, StateIndicatorType } from "components/Indicators/State";
import { TaskItem, useFormat, useTableDataStatus } from "hooks";
import { Illustration } from "components/Illustration";
import { DocumentationRecordOrderByEnum, TasksQueryVariables, useTasksQuery } from "api";
import { useColumnSearchProps, usePagination } from "helpers/serverSideTableFilter";
import { Table } from "antd";
import { TableProps } from "helpers";
import { SimpleLoader } from "components/SimpleLoader";

type TableItem = TaskItem & {
  organizationName: string;
  constructionSiteName: string;
  constructionObjectName: string;
  constructionPhaseOrder: string;
  lastChange: string;
  surveyUploaded: "true" | "false";
};
type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: DocumentationRecordOrderByEnum[] } } = {
  name: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  organizationName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteOrganizationName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteOrganizationNameDesc]
  },
  constructionSiteName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteNameDesc]
  },
  constructionObjectName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectNameDesc]
  },
  lastChange: {
    ascend: [DocumentationRecordOrderByEnum.LastModified],
    descend: [DocumentationRecordOrderByEnum.LastModifiedDesc]
  }
};
export const TodoSurveyor: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState<TasksQueryVariables["orderBy"]>([]);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const [nameFilter, setNameFilter] = useState("");
  const [organizationNameFilter, setOrganizationNameFilter] = useState("");
  const [siteNameFilter, setSiteNameFilter] = useState("");
  const [objectNameFilter, setObjectNameFilter] = useState("");
  const [surveyUploadedFilter, setSurveyUploadedFilter] = useState<boolean | undefined | null>(undefined);
  const nameSearchProps = useColumnSearchProps();
  const organizationNameSearchProps = useColumnSearchProps();
  const siteNameSearchProps = useColumnSearchProps();
  const objectNameSearchProps = useColumnSearchProps();
  const { getFromNow } = useFormat();

  const { data, loading } = useTasksQuery({
    variables: {
      skip: skip,
      orderBy: orderBy,
      name: nameFilter,
      organizationName: organizationNameFilter,
      constructionSiteName: siteNameFilter,
      constructionObjectName: objectNameFilter,
      surveyUploaded: surveyUploadedFilter,
      toProcess: true
    },
    fetchPolicy: "cache-and-network"
  });

  const tableData = useMemo<TableItem[]>(
    () =>
      data?.me?.tasks.edges?.map(({ node: item }) => ({
        ...item,
        organizationName: item.constructionObject.constructionSite.organization.name,
        constructionSiteName: item.constructionObject.constructionSite.name,
        constructionObjectName: item.constructionObject.name,
        constructionPhaseOrder: `${item.constructionPhase.order}.`,
        lastChange: item.lastStateChange?.createdAt || item.createdAt,
        surveyUploaded: !!item.geodeticSurvey?.txtSurvey?.url ? "true" : "false"
      })) || [],
    [data]
  );
  useEffect(() => setTotal(data?.me?.tasks.totalCount), [data]);

  const columns = useMemo(
    (): TablePropType["columns"] => [
      {
        dataIndex: "name",
        key: "name",
        title: t(lg.todo.tableSurveyor.header.documentationRecord),
        render: (name, { id }) => <Link href={setRouteParams(routes.taskSurveyor, { id })}>{name}</Link>,
        sorter: true,
        ...nameSearchProps,
        ellipsis: true
      },
      {
        dataIndex: "organizationName",
        key: "organizationName",
        title: t(lg.todo.tableSurveyor.header.organization),
        sorter: true,
        ellipsis: true,
        ...organizationNameSearchProps,
        render: organizationName => <span className="text-secondary">{organizationName || "-"}</span>
      },
      {
        dataIndex: "constructionSiteName",
        key: "constructionSiteName",
        title: t(lg.todo.table.header.constructionSite),
        sorter: true,
        ...siteNameSearchProps,
        ellipsis: true,
        render: constructionSiteName => <span className="text-secondary">{constructionSiteName || "-"}</span>
      },
      {
        dataIndex: "constructionObjectName",
        key: "constructionObjectName",
        title: t(lg.todo.table.header.constructionObject),
        sorter: true,
        ellipsis: true,
        ...objectNameSearchProps,
        render: name => <span className="text-secondary">{name || "-"}</span>
      },
      {
        dataIndex: "lastChange",
        key: "lastChange",
        title: t(lg.todo.tableSurveyor.header.createdBefore),
        sorter: true,
        render: lastChange => <span className="text-secondary">{getFromNow(lastChange) || "-"}</span>
      },
      {
        dataIndex: "groundControlPointDocumentations",
        key: "ground_points",
        title: t(lg.todo.tableSurveyor.header.groundControlPoints),
        render: points => <span className="text-secondary">{points.totalCount || 0}</span>
      },
      {
        dataIndex: "surveyUploaded",
        key: "surveyUploaded",
        title: t(lg.todo.tableSurveyor.header.surveyFiles),
        // sorter: true,
        filters: [
          { text: t(lg.state.geodeticSurveyUploaded.true), value: "true" },
          { text: t(lg.state.geodeticSurveyUploaded.false), value: "false" }
        ],
        render: surveyUploaded => {
          const uploaded = surveyUploaded === "true";
          return (
            <StateIndicator type={uploaded ? StateIndicatorType.success : StateIndicatorType.warning}>
              {t(lg.state.geodeticSurveyUploaded[uploaded ? "true" : "false"])}
            </StateIndicator>
          );
        }
      }
    ],
    [t, getFromNow, nameSearchProps, organizationNameSearchProps, siteNameSearchProps, objectNameSearchProps]
  );
  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setNameFilter(filter.name ? filter.name[0] : "");
      setOrganizationNameFilter(filter.organizationName ? filter.organizationName[0] : "");
      setSiteNameFilter(filter.constructionSiteName ? filter.constructionSiteName[0] : "");
      setObjectNameFilter(filter.constructionObjectName ? filter.constructionObjectName[0] : "");
      if (filter.surveyUploaded) {
        if (filter.surveyUploaded.length === 2) {
          // both == none
          setSurveyUploadedFilter(null);
        } else {
          setSurveyUploadedFilter(filter.surveyUploaded[0] === "true");
        }
      } else {
        setSurveyUploadedFilter(null);
      }
    },
    [setPage]
  );

  const { hasData, hasFilters } = useTableDataStatus(tableData.length, [
    nameFilter,
    organizationNameFilter,
    siteNameFilter,
    objectNameFilter,
    typeof surveyUploadedFilter !== "undefined"
  ]);

  if (loading && !hasFilters && !hasData)
    return (
      <div className="flex justify-center w-full p-10">
        <SimpleLoader />
      </div>
    );

  if (!hasFilters && !hasData) {
    return (
      <div className="flex flex-col items-center min-h-full">
        <p className="text-center whitespace-pre-line text-base text-blue-700">{t(lg.todo.emptySurveyor)}</p>

        <div className="flex-grow flex flex-col w-full p-5 md:p-20">
          <Illustration type="ill-03" className="flex-grow h-full md:h-64" title={t(lg.todo.emptySurveyor)} />
        </div>
      </div>
    );
  }

  return (
    <Section title={t(lg.todo.header.title)}>
      <div className="mt-5">
        <Table<TableItem>
          dataSource={tableData}
          columns={columns}
          rowKey={"id"}
          loading={loading}
          showSorterTooltip={false}
          onChange={handleOnChange}
          pagination={pagination}
          scroll={{ x: true }}
        />
      </div>
    </Section>
  );
};
