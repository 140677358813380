import { PropsWithChildren, useCallback } from "react";
import * as Actions from "components/DocumentationRecord/Actions";
import { getTrigger } from "helpers";
import { TriggerFragment, Triggers } from "api";

type PageType = "taskOperator" | "taskSurveyor" | "taskThreeDOperator" | "manager";

type Props = {
  type: PageType;
  documentationRecordId: string;
  triggers: TriggerFragment[];
  onAfterTrigger: Actions.AfterTriggerHandler;
  onTriggerError?: Actions.TriggerErrorHandler;
};

export const MainAction = ({
  type,
  documentationRecordId,
  triggers,
  onAfterTrigger,
  onTriggerError
}: PropsWithChildren<Props>) => {
  const available = useCallback((trigger: Triggers) => getTrigger(trigger, triggers).available, [triggers]);

  const actionProps = { documentationRecordId, triggers, onAfterTrigger, onTriggerError };

  if (type === "taskOperator") {
    if (available(Triggers.SubmitSurveyor)) return <Actions.SubmitSurveyor {...actionProps} />;
    if (available(Triggers.SubmitManagerApproval)) return <Actions.SubmitManagerApproval {...actionProps} />;
    if (available(Triggers.SubmitThreeDOperator)) return <Actions.SubmitThreeDOperator {...actionProps} />;
    if (available(Triggers.SubmitFinished)) return <Actions.SubmitFinished {...actionProps} />;
  }

  if (type === "taskSurveyor") {
    if (available(Triggers.SubmitManagerApproval)) return <Actions.SubmitManagerApproval {...actionProps} />;
    if (available(Triggers.SubmitThreeDOperator)) return <Actions.SubmitThreeDOperator {...actionProps} />;
  }

  if (type === "manager") {
    if (available(Triggers.SubmitOperator)) return <Actions.SubmitOperator {...actionProps} />;
    if (available(Triggers.SubmitThreeDOperator)) return <Actions.SubmitThreeDOperator {...actionProps} />;
    if (available(Triggers.SubmitFinished)) return <Actions.SubmitFinished {...actionProps} />;
  }

  if (type === "taskThreeDOperator") {
    if (available(Triggers.SubmitFinished)) return <Actions.SubmitFinished {...actionProps} />;
  }

  return null;
};
