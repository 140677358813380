import { PropsWithChildren, useEffect, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import { Content } from "components/LayoutDashboard";
import { Map } from "components/Map";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { routes, setRouteParams } from "routes";
import { resolveError, useConstructionSiteForConstructionObjectCreatePageQuery } from "api";
import { Section } from "components/Section";
import { GeneralInfoForm } from "components/ConstructionObject/GeneralInfoForm";
import { ModalUnsavedMap } from "components/ConstructionSites/ModalUnsavedMap";
import { lg } from "assets/translations/lg";
import { useAlert, useConstructionObject, useFormat } from "hooks";
import { StateToggle } from "components/ConstructionSiteEdit/StateToggle";

export const ConstructionObjectCreate = ({
  constructionSiteId
}: PropsWithChildren<RouteComponentProps<{ constructionSiteId: string }>>) => {
  if (!constructionSiteId) {
    throw new Error("Missing construction site ID.");
  }

  const { t } = useTranslation();
  const { arrayToCoords } = useFormat();
  const { showAlert } = useAlert();

  const {
    form,
    loading: loadingConstructionObject,
    sending,
    disabled,
    state,
    data,
    handlers
  } = useConstructionObject(constructionSiteId, "create");

  const { loading: loadingConstructionSite, data: constructionSiteData } =
    useConstructionSiteForConstructionObjectCreatePageQuery({
      variables: { id: constructionSiteId },
      onCompleted: data => handlers.setDefaultCoords(arrayToCoords(data.constructionSite?.location || [])),
      onError: e => resolveError(e, undefined, showAlert)
    });

  const loading = useMemo(
    () => loadingConstructionObject || loadingConstructionSite,
    [loadingConstructionObject, loadingConstructionSite]
  );

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: t(lg.constructionObject.pageCreate.header.title),
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        {
          value: constructionSiteData?.constructionSite?.name || "...",
          route: setRouteParams(routes.constructionSiteDetail, { id: constructionSiteId })
        },
        { value: t(lg.constructionObject.pageCreate.header.title) }
      ],
      buttons: [
        <Button key="abort" type={"default"} size={"large"} disabled={loading} onClick={handlers.handleClickAbort}>
          {t(lg.constructionObject.pageCreate.header.buttonSecondaryText)}
        </Button>,
        <Button
          key="save"
          type={"primary"}
          size={"large"}
          icon={!sending ? <CheckOutlined /> : <LoadingOutlined />}
          disabled={!state.isFormValid || disabled || !data.hasValidCoords}
          onClick={handlers.handleClickSave}
        >
          {t(!sending ? lg.constructionObject.pageCreate.header.buttonPrimaryText : lg.saving)}
        </Button>
      ]
    }),
    [
      constructionSiteId,
      constructionSiteData,
      state.isFormValid,
      handlers.handleClickAbort,
      handlers.handleClickSave,
      loading,
      sending,
      disabled,
      data.hasValidCoords,
      t
    ]
  );
  useSubheader(subheaderOptions);

  /*
   * Init default values to the form and validate by fields validation rules
   * */
  useEffect(() => {
    if (!form || form.isFieldsTouched()) return;
    if (!constructionSiteData || !data.organization) return;

    form.setFieldsValue({
      name: "",
      description: "",
      accountableManagerId:
        constructionSiteData.constructionSite?.accountableManager?.id ||
        data.organization.users.filter(({ id }) => id === data.me.id)[0].id ||
        "",
      accountableOperatorId: constructionSiteData.constructionSite?.accountableOperator?.id || "",
      accountableSurveyorId: constructionSiteData.constructionSite?.accountableSurveyor?.id || ""
    });
  }, [form, data.me.id, data.organization, constructionSiteData]);

  return (
    <Content loading={loading}>
      {/** State toggle */}
      <div className="flex items-center justify-end mb-10">
        <StateToggle
          isActive={data.isObjectActive}
          disabled={disabled}
          onChange={handlers.handleChangeState}
          description={
            data.isObjectActive
              ? t(lg.constructionObject.pageCreate.actions.statusActive)
              : t(lg.constructionObject.pageCreate.actions.statusInactive)
          }
        />
      </div>

      {/** Form section */}
      <Section title={t(lg.constructionObject.pageCreate.baseInformation.title)}>
        <div className="mt-10">
          <GeneralInfoForm
            formRef={form}
            disabled={disabled}
            onChange={handlers.handleFormChange}
            onFinish={handlers.handleSubmit}
            users={data.organization.users}
          />
        </div>
      </Section>

      {/** Map section */}
      <Section title={t(lg.constructionObject.pageCreate.map.title)} className="mt-16)">
        <div className="mt-10">
          <Map
            editable={true}
            editType="object"
            constructionSite={arrayToCoords(constructionSiteData?.constructionSite?.location || [])}
            constructionObject={arrayToCoords(constructionSiteData?.constructionSite?.location || [])}
            saveDisabled={disabled || state.hasSavedCoords}
            hasValidCoords={data.hasValidCoords}
            onChange={handlers.handleMapChange}
            onSave={handlers.handleMapSave}
          />
        </div>
      </Section>

      {/* warning before save - unsaved map */}
      <ModalUnsavedMap
        visible={state.isVisibleModalCoords}
        onOk={handlers.handleClickCoordsModalOk}
        onCancel={handlers.handleClickCoordsModalCancel}
      />
    </Content>
  );
};
