import { PropsWithChildren, useCallback, useState } from "react";
import { Checkbox, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";

export enum FormRoleChangeItems {
  updateDescendantConstructionObjects = "updateDescendantConstructionObjects",
  updateDescendantDocumentationRecords = "updateDescendantDocumentationRecords"
}

type Props = {
  visible: boolean;
  onOk: (data: FormRoleChangeItems[]) => any;
  onCancel: () => any;
  values: FormRoleChangeItems[];
  options: {
    documentationRecords: boolean;
    constructionObjects: boolean;
  };
};

export const RoleModalChange = ({ visible, onOk, onCancel, values, options }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<FormRoleChangeItems[]>(values);

  const handleOk = useCallback(() => {
    onOk(value);
  }, [onOk, value]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleChange = useCallback((formData: any[]) => {
    setValue(formData);
  }, []);

  return (
    <Modal
      title={
        <div className="flex items-center">
          <InfoCircleOutlined className="text-lg text-gold-600" />
          <span className="ml-4">{t(lg.constructionSite.pageEdit.modals.roleChange.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.constructionSite.pageEdit.modals.roleChange.buttonOkText)}
      cancelText={t(lg.constructionSite.pageEdit.modals.roleChange.buttonCancelText)}
      okButtonProps={{ type: "primary", disabled: !value || value.length < 1 }}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose
      forceRender
    >
      <div className="text-base">{t(lg.constructionSite.pageEdit.modals.roleChange.description)}</div>

      <Checkbox.Group className="flex flex-col mt-4" onChange={handleChange} value={value}>
        <Checkbox
          className="text-secondary"
          value={FormRoleChangeItems.updateDescendantConstructionObjects}
          disabled={!options.constructionObjects}
        >
          {t(lg.constructionSite.pageEdit.modals.roleChange.optionChangeForIndividualParts.label)}
        </Checkbox>

        <Checkbox
          className="text-secondary ml-0 mt-2"
          value={FormRoleChangeItems.updateDescendantDocumentationRecords}
          disabled={!options.documentationRecords}
        >
          {t(lg.constructionSite.pageEdit.modals.roleChange.optionRewriteCurrentDocumentation.label)}
        </Checkbox>
      </Checkbox.Group>
    </Modal>
  );
};
