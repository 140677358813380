import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { Coords } from "google-map-react";
import { GroundControlPointsMap } from "components/Map/GroundControlPointsMap";
import { Photos } from "components/Task/GroundControlPoints/Photos";
import { usePoint } from "components/Task/GroundControlPoints/usePoint";

import { lg } from "assets/translations";

export type PointItem = {
  id: string;
  order: number;
  coords?: Coords;
  photos: string[]; // Media ID []
  description: string;
  draft: boolean; // if (draft) - call create on save
  saved: boolean; // if (!saved && !draft) - call update on save
};

export type PointSaveHandler = (id: string, point: PointItem) => any;
export type PointChangeHandler = (id: string) => any;
export type PointRemoveHandler = (id: string) => any;

type Props = {
  point: PointItem;
  organizationId: string;
  documentationRecordId: string;
  constructionSiteCoords: Coords[];
  constructionObjectCoords: Coords[];
  onSave: PointSaveHandler;
  onChange: PointChangeHandler;
  onRemove: PointRemoveHandler;
};

export const Point = ({
  point,
  organizationId,
  documentationRecordId,
  constructionSiteCoords,
  constructionObjectCoords,
  onSave,
  onChange,
  onRemove
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const { data, state, handlers } = usePoint(point, documentationRecordId, onSave, onChange, onRemove);

  return (
    <div className="mt-5">
      <div className="text-blue-700 text-base font-medium mb-10">
        {t(lg.taskOperator.groundControlPoints.point.title, { order: point.order })}
      </div>

      <GroundControlPointsMap
        constructionSite={constructionSiteCoords}
        constructionObject={constructionObjectCoords}
        insertMode={true}
        order={point.order}
        defaultValue={state.coords}
        defaultDescription={state.description}
        onChange={handlers.handleChangeMap}
      />

      <div className={"c-grid"}>
        <div className={"c-grid-column"}>
          <Photos
            organizationId={organizationId}
            photos={state.photos}
            className="mt-16"
            onAdd={handlers.handleAddPhoto}
            onRemove={handlers.handleRemovePhoto}
          />
        </div>
      </div>

      <div className="flex justify-end mt-5">
        <Button size="large" type="ghost" disabled={data.loading} className="mr-5" onClick={handlers.handleClickRemove}>
          {t(lg.taskOperator.groundControlPoints.point.buttonRemove)}
        </Button>
        <Button
          size="large"
          type="primary"
          disabled={data.saveDisabled || data.loading || point.saved}
          loading={data.loading}
          onClick={handlers.handleClickSave}
        >
          {t(lg.taskOperator.groundControlPoints.point.buttonSave)}
        </Button>
      </div>

      <div className="text-right mt-2">
        {point.saved && <span className="text-green-600">{t(lg.taskOperator.groundControlPoints.point.saved)}</span>}
        {!point.saved && <span className="text-red-600">{t(lg.taskOperator.groundControlPoints.point.unsaved)}</span>}
      </div>
    </div>
  );
};
