import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { Content } from "components/LayoutDashboard";
import { Section } from "components/Section";
import { SubheaderOptions, useAlert, useFormat, useMe, useSubheader } from "hooks";
import {
  InquiryOrderByEnum,
  InquiryStatus,
  InquiryStatusFilterEnum,
  resolveError,
  useInquiriesForAdditionalServicesPageQuery
} from "api";
import { InquiryForm } from "components/InquiryForm/InquiryForm";
import { Table } from "antd";
import { getInquiryName, getInquiryStatusFilter, isManager, isReader, TableProps, usePagination } from "helpers";
import { StateIndicatorInquiry } from "components/Indicators/State/StateIndicatorInquiry";
import { Link } from "components/Link";
import {
  getConstructionObjectDetailRoute,
  getConstructionSiteDetailRoute,
  getDocumentationRecordDetailRoute,
  routes,
  setRouteParams
} from "routes";
import { inquiryStateFilters } from "api/enums";

type TableItem = {
  id: string;
  inquiryName: string;
  nodesInfo?: { node: { id: string; name: string; href: string }; totalCount: number };
  createdAt: string;
  status: InquiryStatus;
};

type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: InquiryOrderByEnum[] } } = {
  createdAt: {
    ascend: [InquiryOrderByEnum.CreatedAt],
    descend: [InquiryOrderByEnum.CreatedAtDesc]
  }
};

export const AdditionalServices = ({ navigate }: PropsWithChildren<RouteComponentProps>) => {
  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const { formatDate } = useFormat();

  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const [orderBy, setOrderBy] = useState<InquiryOrderByEnum[]>(orderByMap.createdAt.descend);
  const [statesFilter, setStatesFilter] = useState<InquiryStatusFilterEnum[]>([]);

  const { me, organization, loading: loadingMe } = useMe();
  const isAuthorizedToView = useMemo<boolean>(() => isManager(me) || isReader(me), [me]);
  const isAuthorizedToViewForm = useMemo<boolean>(() => isManager(me), [me]);

  /* redirect a user to the default route if the user is not authorized to render this page */
  useEffect(() => {
    if (!me.role || isAuthorizedToView) return;
    navigate && navigate(routes.todo);
  }, [isAuthorizedToView, me, navigate]);

  const {
    data: dataInquiries,
    loading: loadingInquiries,
    refetch: refetchInquiries
  } = useInquiriesForAdditionalServicesPageQuery({
    variables: {
      organizationId: organization.id,
      skip,
      orderBy: orderBy,
      status: statesFilter
    },
    fetchPolicy: "cache-and-network",
    skip: !isAuthorizedToView || !organization.id,
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => setTotal(dataInquiries?.organization?.inquiries.totalCount), [dataInquiries]);

  const subheaderOptions = useMemo<SubheaderOptions>(() => ({ heading: t(lg.additionalServices.header.title) }), [t]);
  useSubheader(subheaderOptions);

  const loading = useMemo(() => loadingMe || loadingInquiries, [loadingMe, loadingInquiries]);

  const inquiries = useMemo(
    () => dataInquiries?.organization?.inquiries.edges.flatMap(({ node }) => (node ? [node] : [])) || [],
    [dataInquiries]
  );

  const tableData = useMemo<TableItem[]>(
    () =>
      inquiries.map(inquiry => {
        const requests = inquiry.requests.edges.flatMap(({ node }) => (node ? [node] : []));
        const inquiryName = getInquiryName(inquiry, i18n.language);

        let totalCount: Required<TableItem>["nodesInfo"]["totalCount"] = 0;
        let node: Omit<Required<TableItem>["nodesInfo"]["node"], "href"> | undefined = undefined;
        let nodeHref: Required<TableItem>["nodesInfo"]["node"]["href"] = "";

        for (const request of requests) {
          /* get count of the all nodes */
          totalCount += request.constructionSites.totalCount || 0;
          totalCount += request.constructionObjects.totalCount || 0;
          totalCount += request.documentationRecords.totalCount || 0;

          if (node) continue;

          /* find first connected site, object or record */
          const site = request.constructionSites.edges.flatMap(({ node }) => (node ? [node] : []))?.[0];
          if (site) {
            node = site;
            nodeHref = getConstructionSiteDetailRoute(site.id);
            continue;
          }

          const object = request.constructionObjects.edges.flatMap(({ node }) => (node ? [node] : []))?.[0];
          if (object) {
            node = object;
            nodeHref = getConstructionObjectDetailRoute(object.id, object.constructionSite.id);
            continue;
          }

          const record = request.documentationRecords.edges.flatMap(({ node }) => (node ? [node] : []))?.[0];
          if (record) {
            node = record;
            nodeHref = getDocumentationRecordDetailRoute(record.id);
          }
        }

        const nodesInfo = node
          ? { node: { id: node.id, name: node.name, href: nodeHref }, totalCount: totalCount }
          : undefined;

        return {
          id: inquiry.id,
          inquiryName: inquiryName,
          nodesInfo,
          createdAt: inquiry.createdAt,
          status: inquiry.status
        };
      }),
    [i18n.language, inquiries]
  );
  const tableColumns = useMemo<TablePropType["columns"]>(
    (): TablePropType["columns"] => [
      {
        dataIndex: "inquiryName",
        key: "inquiryName",
        title: t(lg.additionalServices.activeInquiries.table.inquiry),
        render: (inquiryName, { id }) => <Link href={setRouteParams(routes.inquiry, { id })}>{inquiryName}</Link>,
        width: "37%"
      },
      {
        dataIndex: "createdAt",
        key: "createdAt",
        title: t(lg.additionalServices.activeInquiries.table.created),
        render: createdAt => (
          <span className="text-secondary">{createdAt ? formatDate(createdAt, "machine", true) : "-"}</span>
        ),
        sorter: true,
        className: "whitespace-nowrap",
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"], // don't allow turn off sorting
        width: 140
      },
      {
        dataIndex: "nodesInfo",
        key: "nodesInfo",
        title: t(lg.additionalServices.activeInquiries.table.nodesInfo),
        render: nodesInfo => {
          if (!nodesInfo) return <span className={"text-gray-700"}>-</span>;

          const { node, totalCount } = nodesInfo;
          const countOfOthers = totalCount > 1 ? totalCount - 1 : 0;

          return (
            <div className={"space-x-2 flex"}>
              <Link href={node.href} className={"truncate"}>
                <span className={"truncate"}>{node.name}</span>
              </Link>

              <div className={"text-gray-700 whitespace-nowrap"}>
                {countOfOthers > 0 ? `+ ${countOfOthers} ${t(lg.next)}` : null}
              </div>
            </div>
          );
        }
      },
      {
        dataIndex: "status",
        key: "status",
        title: t(lg.additionalServices.activeInquiries.table.state),
        render: (status: TableItem["status"]) => <StateIndicatorInquiry state={status} />,
        filters: getInquiryStatusFilter().map(status => ({
          text: t(inquiryStateFilters[status].nameI18nKey),
          value: status
        })),
        className: "whitespace-nowrap",
        width: 1 // final width will be defined by largest content
      }
    ],
    [t, formatDate]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter) => {
      pagination.current && setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);

      setStatesFilter(filter.status || []);
    },
    [setPage]
  );

  const hasFilters = statesFilter.length > 0;

  return (
    <Content loading={loading && !dataInquiries}>
      {/*  Active Inquiries  */}
      <Section title={t(lg.additionalServices.activeInquiries.title)}>
        <div className="mt-10">
          {inquiries.length === 0 && !hasFilters ? (
            <p className="text-center whitespace-pre-line text-base text-blue-700">
              {isAuthorizedToViewForm
                ? t(lg.additionalServices.activeInquiries.noData.default)
                : t(lg.additionalServices.activeInquiries.noData.readOnly)}
            </p>
          ) : (
            <Table<TableItem>
              dataSource={tableData}
              columns={tableColumns}
              loading={loading}
              rowKey={"id"}
              onChange={handleOnChange}
              pagination={pagination}
              scroll={{ x: true }}
            />
          )}
        </div>
      </Section>

      {/* Form */}
      {isAuthorizedToViewForm && (
        <InquiryForm className="mt-16" organizationId={organization.id} onSubmitSuccess={refetchInquiries} />
      )}
    </Content>
  );
};
