import { Section } from "components/Section";
import { lg } from "assets/translations";
import { Trans, useTranslation } from "react-i18next";
import { DocumentationRecordType } from "api";
import { Link } from "components/Link";
import { AboutPageTabKeys } from "pages";
import { ParseMD } from "helpers";
import { routes, setRouteParams } from "routes";
import { documentationRecordTypes } from "api/enums";

export const Documentation = () => {
  const [t] = useTranslation();
  return (
    <>
      <Section title={t(lg.aboutPage.tabs.documentation.about.title)}>
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="font-medium text-secondary whitespace-pre-line">
              <Trans i18nKey={lg.aboutPage.tabs.documentation.about.description1}>
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.ConstructionSites })} />
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.MyTasks })} />
              </Trans>
            </div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.documentation.about.description2)}
              <div className="font-medium">
                {Object.values(DocumentationRecordType)
                  .map(type => t(documentationRecordTypes[type].nameI18nKey))
                  .join(", ")}
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.documentation.noModel.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.documentation.noModel.description)}
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.documentation.yetNoModel.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.documentation.yetNoModel.description1)}
            </div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.documentation.yetNoModel.description2)}
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.documentation.modelNoScale.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.documentation.modelNoScale.description1)}
            </div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              <ParseMD>{t(lg.aboutPage.tabs.documentation.modelNoScale.description2)}</ParseMD>
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.documentation.modelScale.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.documentation.modelScale.description1)}
            </div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              <ParseMD>{t(lg.aboutPage.tabs.documentation.modelScale.description2)}</ParseMD>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
