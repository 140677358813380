import { useMemo } from "react";
import { useMe } from "hooks";
import { isManager } from "helpers";

// for permissions overview see https://docs.google.com/spreadsheets/d/1nx4EzWMzgkdA7tZpPL5t18XP6yoR7MSA2Hgc7mqp9VI/edit#gid=0

type AccountableUsers = {
  managerId?: string;
  operatorId?: string;
  surveyorId?: string;
};

type Config = {
  constructionSite?: AccountableUsers;
  constructionObject?: AccountableUsers;
  documentationRecord?: AccountableUsers;
};

export const usePermissions = (config: Config) => {
  const { me } = useMe();

  const isSiteManager = useMemo(() => config?.constructionSite?.managerId === me.id, [config, me]);
  const isObjectManager = useMemo(() => config?.constructionObject?.managerId === me.id, [config, me]);
  const isDocumentationManager = useMemo(() => config?.documentationRecord?.managerId === me.id, [config, me]);
  const isDocumentationOperator = useMemo(() => config?.documentationRecord?.operatorId === me.id, [config, me]);

  const isOrganizationManager = useMemo(() => isManager(me), [me]);

  return useMemo(
    () => ({
      /** ConstructionSite permissions */
      canEditConstructionSite: isSiteManager,
      canDeleteConstructionSite: isSiteManager,
      canCreateConstructionSite: isOrganizationManager,
      canEditConstructionPhases: isSiteManager,
      canCreateConstructionObject: isSiteManager,
      /** ConstructionObject permissions */
      canEditConstructionObject: isObjectManager,
      canDeleteConstructionObject: isObjectManager,
      canCreateDocumentationRecord: isObjectManager,
      /** DocumentationRecord permissions */
      canEditDocumentationRecord: isDocumentationManager || isDocumentationOperator, // TODO can edit only some properties - account documentation state - https://hrdlicka.atlassian.net/browse/HRD003-443
      canDeleteDocumentationRecord: isDocumentationManager,
      /** 3D model permissions */
      canShareThreeDModel: isOrganizationManager,
      canDeleteThreeDModel: isOrganizationManager
    }),
    [isSiteManager, isObjectManager, isDocumentationManager, isDocumentationOperator, isOrganizationManager]
  );
};
