import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import * as Actions from "components/DocumentationRecord/Actions";
import { Section } from "components/Section";
import { Content } from "components/LayoutDashboard";
import { TriggerFragment, Triggers } from "api";
import { lg } from "assets/translations";
import { getTrigger } from "helpers";
import { documentationRecordTriggers } from "api/enums";

type Props = {
  documentationRecordId: string;
  triggers: TriggerFragment[];
  threeDOperator?: boolean;
  cost?: number;
  onAfterTrigger: Actions.AfterTriggerHandler;
  className?: string;
};

export const ActionsSection = ({ threeDOperator = false, cost = 0, className, ...props }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  if (!props.documentationRecordId || !props.triggers.length) return null;

  const noActionAvailable: boolean = !props.triggers.find(trigger => trigger.available);
  if (noActionAvailable) return null;

  return (
    <Content className={className}>
      <Section title={t(lg.documentationRecordActions.sectionTitle)}>
        <div className="c-grid mt-10">
          {/* Back triggers */}
          <div className="c-grid-column -my-10">
            <Action trigger={Triggers.BackCreated} {...props}>
              <Actions.BackCreated {...props} />
            </Action>
            <Action trigger={Triggers.BackOperator} {...props}>
              <Actions.BackOperator {...props} threeDOperator={threeDOperator} cost={cost} />
            </Action>
            <Action trigger={Triggers.ChangeNoScale} {...props}>
              <Actions.ChangeNoScale {...props} />
            </Action>
            <Action trigger={Triggers.BackSurveyor} {...props}>
              <Actions.BackSurveyor {...props} threeDOperator={threeDOperator} cost={cost} />
            </Action>
            <Action trigger={Triggers.ChangeToThreeD} {...props}>
              <Actions.ChangeToThreeD {...props} />
            </Action>
          </div>

          {/* Submit triggers */}
          <div className="c-grid-column -my-10">
            <Action trigger={Triggers.SubmitOperator} {...props}>
              <Actions.SubmitOperator {...props} />
            </Action>
            <Action trigger={Triggers.SubmitSurveyor} {...props}>
              <Actions.SubmitSurveyor {...props} />
            </Action>
            <Action trigger={Triggers.SubmitManagerApproval} {...props}>
              <Actions.SubmitManagerApproval {...props} />
            </Action>
            <Action trigger={Triggers.SubmitThreeDOperator} {...props}>
              <Actions.SubmitThreeDOperator {...props} />
            </Action>
            <Action trigger={Triggers.SubmitFinished} {...props}>
              <Actions.SubmitFinished {...props} />
            </Action>
            <Action trigger={Triggers.Reject} {...props}>
              <Actions.Reject {...props} cost={cost} />
            </Action>
          </div>
        </div>
      </Section>
    </Content>
  );
};

const Action = (props: PropsWithChildren<Props & { trigger: Triggers }>) => {
  const { t } = useTranslation();
  const { available } = getTrigger(props.trigger, props.triggers);

  if (!available) return null;

  return (
    <div className="my-10">
      <div className="font-medium">{t(documentationRecordTriggers[props.trigger].labelI18nKey)}</div>
      <div className="text-secondary mb-5">{t(documentationRecordTriggers[props.trigger].descriptionI18nKey)}</div>
      {props.children}
    </div>
  );
};
