import { resolveError, UseCaseEvent, useOrganizationLogEventMutation } from "api";
import { useCallback, useMemo } from "react";
import { getEnv } from "helpers";

export const useLogEvent = () => {
  const [log, { loading }] = useOrganizationLogEventMutation({ onError: e => resolveError(e, {}, () => {}) });

  const canLog = useMemo(() => getEnv("NODE_ENV") === "production", []);

  const logEvent = useCallback(
    async (event: UseCaseEvent) => {
      if (!canLog) return;

      try {
        await log({
          variables: {
            input: {
              event
            }
          }
        });
      } catch (e) {
        console.log("Log error", event, "error:", e);
      }
    },
    [log, canLog]
  );

  return {
    loading,
    logEvent
  };
};
