import { useCallback, useEffect, useMemo, useState } from "react";
import { useCoordsModal } from "hooks/useCoordsModal";
import { Coords } from "google-map-react";
import { navigate } from "@reach/router";
import { routes, setRouteParams } from "routes";
import { GeneralInfoFormData } from "components/ConstructionObject/GeneralInfoForm";
import { useMe } from "hooks/useMe";
import {
  ConstructionObjectState,
  resolveError,
  useConstructionObjectCreateMutation,
  useConstructionObjectCreatePageQuery,
  useConstructionObjectUpdateMutation
} from "api";
import { Form, message } from "antd";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";
import { FieldData } from "rc-field-form/es/interface";
import { useAlert } from "hooks/useAlert";
import { useFormat } from "hooks/useFormat";
import { useMountedState } from "react-use";

type DefaultValues = {
  objectState: ConstructionObjectState;
  coords: Coords[];
};

export const useConstructionObject = (constructionSiteId: string, type: "create" | "edit", id: string = "") => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { arrayToCoords, coordsToArray } = useFormat();
  const [form] = Form.useForm();
  const isMounted = useMountedState();

  const [create, { loading: loadingCreateMutation }] = useConstructionObjectCreateMutation();
  const [update, { loading: loadingUpdateMutation }] = useConstructionObjectUpdateMutation();

  /** STATES */
  const [isFormValid, setIsFormValid] = useState(false);
  const [savedCoords, setSavedCoords] = useState<Coords[]>();
  const [updatedObjectState, setUpdatedObjectState] = useState<ConstructionObjectState>();
  const [updatedCoords, setUpdatedCoords] = useState<Coords[]>();
  const [hasChangedAccountableUser, setHasChangedAccountableUser] = useState(false);
  const [roleChangeModalVisible, setRoleChangeModalVisible] = useState<boolean>(false);
  const [changeDescendantUsers, setChangeDescendantUsers] = useState(false);
  const [isRefetch, setIsRefetch] = useState<boolean>(false);

  /** DATA */
  const { loading: loadingData, data } = useConstructionObjectCreatePageQuery({
    variables: { constructionObjectId: id },
    onError: e => resolveError(e, undefined, showAlert)
  });
  const { me, organization, refetch, loading: loadingMe } = useMe();

  const constructionObject = useMemo(() => data?.constructionObject, [data]);
  const hasDocumentationRecords = useMemo<boolean>(
    () =>
      !!constructionObject?.createdRecords ||
      !!constructionObject?.workInProgressRecords ||
      !!constructionObject?.completedRecords,
    [constructionObject]
  );

  const defaultObjectValues = useMemo<DefaultValues>(
    () => ({
      objectState: constructionObject?.state || ConstructionObjectState.Active,
      coords: arrayToCoords(constructionObject?.location || [])
    }),
    [constructionObject, arrayToCoords]
  );

  const defaultFormValuesEdit = useMemo<GeneralInfoFormData | undefined>(() => {
    /* wait for all data before init values */
    if (!form || !constructionObject || !me.id) return;

    return {
      name: constructionObject?.name || "",
      description: constructionObject?.description || "",
      accountableManagerId: constructionObject?.accountableManager?.id || "",
      accountableOperatorId: constructionObject?.accountableOperator?.id || "",
      accountableSurveyorId: constructionObject?.accountableSurveyor?.id || ""
    };
  }, [form, me.id, constructionObject]);

  const isObjectActive = useMemo<boolean>(
    () =>
      updatedObjectState
        ? updatedObjectState === ConstructionObjectState.Active
        : defaultObjectValues.objectState === ConstructionObjectState.Active,
    [updatedObjectState, defaultObjectValues.objectState]
  );

  const { hasSavedCoords, setHasSavedCoords, isVisibleModalCoords, setIsVisibleModalCoords } = useCoordsModal();

  const loading = useMemo(() => loadingMe || loadingData, [loadingMe, loadingData]);
  const sending = useMemo(
    () => loadingCreateMutation || loadingUpdateMutation || isRefetch,
    [loadingCreateMutation, loadingUpdateMutation, isRefetch]
  );
  const disabled = useMemo(() => loading || sending, [loading, sending]);
  const canSend = useMemo(() => loading || sending || isFormValid, [loading, sending, isFormValid]);
  const hasValidCoords = useMemo(() => {
    const hasSavedCoords = savedCoords !== undefined;
    const hasCoords = updatedCoords !== undefined;

    if (!hasSavedCoords && !hasCoords && (constructionObject?.location?.length || 0) > 2) return true;
    if (!hasSavedCoords && hasCoords && (updatedCoords?.length || 0) > 2) return true;
    return hasSavedCoords && hasCoords && (updatedCoords?.length || 0) > 2;
  }, [updatedCoords, savedCoords, constructionObject]);

  /** HANDLERS */
  /** Header handlers */
  const handleClickAbort = useCallback(async () => {
    if (type === "create") await navigate(setRouteParams(routes.constructionSiteDetail, { id: constructionSiteId }));
    if (type === "edit") await navigate(setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id }));
  }, [type, constructionSiteId, id]);
  const handleClickSave = useCallback(() => form.submit(), [form]);

  /** Form handlers + submit */
  const handleFormChange = useCallback(
    (changedFields: FieldData[], allFields: FieldData[]) => {
      // validate
      setIsFormValid(!allFields.filter(field => field.errors && field.errors.length > 0).length);

      // Check for changed accountable users
      const wasAccountableUserChanges = changedFields.find(({ name }) =>
        (Array.isArray(name) ? name[0] : name).toString().includes("accountable")
      );

      return wasAccountableUserChanges ? setHasChangedAccountableUser(true) : null;
    },
    [] // TODO validate coords too? - https://hrdlicka.atlassian.net/browse/HRD003-443
  );

  const handleSubmit = useCallback(
    async (values: GeneralInfoFormData) => {
      if (!hasSavedCoords) {
        setIsVisibleModalCoords(true);
        return;
      }

      if (type === "edit" && hasChangedAccountableUser && hasDocumentationRecords) {
        return setRoleChangeModalVisible(true);
      }

      const input = {
        accountableManager: values.accountableManagerId,
        accountableOperator: values.accountableOperatorId,
        accountableSurveyor: values.accountableSurveyorId,
        description: values.description || "",
        location: savedCoords ? coordsToArray(savedCoords) : undefined,
        name: values.name || "",
        state: updatedObjectState === undefined ? defaultObjectValues.objectState : updatedObjectState
      };

      try {
        if (type === "create") {
          await create({ variables: { input: { ...input, constructionSite: constructionSiteId } } });
          setIsRefetch(true);
          await refetch();
          await navigate(setRouteParams(routes.constructionSiteDetail, { id: constructionSiteId }));
        }

        if (type === "edit") {
          await update({
            variables: {
              input: {
                ...input,
                constructionObject: id,
                updateDescendantDocumentationRecords: changeDescendantUsers
              }
            }
          });

          message.success(t(lg.constructionObject.pageEdit.messages.objectWasSaved));
          await navigate(setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id }));
        }
      } catch (e) {
        resolveError(e, {}, showAlert);
      } finally {
        if (isMounted()) setIsRefetch(false);
      }
    },
    [
      constructionSiteId,
      id,
      type,
      showAlert,
      coordsToArray,
      defaultObjectValues,
      savedCoords,
      hasSavedCoords,
      setIsVisibleModalCoords,
      updatedObjectState,
      hasChangedAccountableUser,
      hasDocumentationRecords,
      changeDescendantUsers,
      t,
      create,
      update,
      refetch,
      isMounted
    ]
  );

  /** Map handlers */
  const setDefaultCoords = useCallback(
    (coords: Coords[]) => {
      setUpdatedCoords(coords);
      setSavedCoords(coords);
      setHasSavedCoords(true);
    },
    [setHasSavedCoords]
  );

  const handleMapChange = useCallback(
    (coords: Coords[]) => {
      setUpdatedCoords(coords);
      setHasSavedCoords(false);
    },
    [setHasSavedCoords]
  );

  const handleMapSave = useCallback(() => {
    setSavedCoords(updatedCoords);
    setHasSavedCoords(true);
  }, [updatedCoords, setHasSavedCoords]);

  /** Modal Unsaved Map handlers */
  const handleClickCoordsModalCancel = useCallback(() => {
    setIsVisibleModalCoords(false);
    setHasSavedCoords(true);
    handleClickSave();
  }, [handleClickSave, setIsVisibleModalCoords, setHasSavedCoords]);

  const handleClickCoordsModalOk = useCallback(() => {
    setSavedCoords(updatedCoords);
    handleMapSave();
    setIsVisibleModalCoords(false);
    handleClickSave();
  }, [updatedCoords, handleMapSave, setIsVisibleModalCoords, handleClickSave]);

  /** Modal - role change **/
  const handleOkModalRoleChange = useCallback(() => {
    setHasChangedAccountableUser(false);
    setChangeDescendantUsers(true);
    setRoleChangeModalVisible(false);
    handleClickSave();
  }, [handleClickSave]);

  const handleCancelModalRoleChange = useCallback(() => {
    setHasChangedAccountableUser(false);
    setChangeDescendantUsers(false);
    setRoleChangeModalVisible(false);
    handleClickSave();
  }, [handleClickSave]);

  /** State toggle handler */
  const handleChangeState = useCallback(
    (isActive: boolean) =>
      setUpdatedObjectState(isActive ? ConstructionObjectState.Active : ConstructionObjectState.Inactive),
    []
  );

  /** EFFECTS */
  /*
   * Init default values to the form and validate by fields validation rules
   * */
  useEffect(() => {
    if (!form || form.isFieldsTouched()) return;

    /* note: default create data are sets on ConstructionObjectCreate page */
    if (type !== "edit" || !defaultFormValuesEdit) return;

    form.setFieldsValue(defaultFormValuesEdit);

    /* validate */
    form
      .validateFields()
      .then(async () => {
        setIsFormValid(true);
      })
      .catch(info => {
        setIsFormValid(false);
      });
  }, [form, type, me.id, organization, defaultFormValuesEdit]);

  return {
    form,
    loading,
    sending,
    disabled,

    state: {
      isFormValid,
      hasSavedCoords,

      isVisibleModalCoords,
      roleChangeModalVisible
    },
    data: {
      me,
      organization,
      constructionObject,
      defaultObjectValues,
      isObjectActive,
      canSend,
      hasValidCoords
    },

    handlers: {
      handleClickAbort,
      handleClickSave,
      handleChangeState,
      handleFormChange,
      handleSubmit,
      setDefaultCoords,
      handleMapChange,
      handleMapSave,

      handleClickCoordsModalCancel,
      handleClickCoordsModalOk,

      handleOkModalRoleChange,
      handleCancelModalRoleChange
    }
  };
};
