import { ComponentPropsWithoutRef } from "react";
import { MediaCard } from "components/MediaCard";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";
import { DocumentationRecordVideo } from "api";

type VideoMediaCardProps = ComponentPropsWithoutRef<typeof MediaCard> & {
  captureLocation?: DocumentationRecordVideo["captureLocation"];
  className?: string;
};

export const VideoMediaCard = ({ captureLocation, className, ...rest }: VideoMediaCardProps): JSX.Element => {
  const { t } = useTranslation();
  const captureLocationMapped = captureLocation?.flatMap(location => (location ? [location] : [])) || [];
  return (
    <div className={className}>
      <MediaCard {...rest} />

      {captureLocationMapped.length ? (
        <div className={"mt-5"}>
          <div className="font-medium print_font-normal print_inline-block">
            {t(lg.documentationRecord.pageDetail.videoImport.videoDocumentation.location.label)}
          </div>
          <div className="text-secondary print_inline-block print_align-middle whitespace-pre-line">
            {captureLocationMapped.join(", ")}
          </div>
        </div>
      ) : null}
    </div>
  );
};
