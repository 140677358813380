import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { lg } from "assets/translations";
import { ParseMD } from "helpers";

type Props = {
  visible: boolean;
  organizationName: string;
  onClose: () => any;
};

export const ModalOrganizationHasNotEnoughCreditToRequestThreeDModel = ({
  visible,
  organizationName,
  onClose
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        <div className="flex items-center">
          <StopOutlined className="text-lg text-red-600" />
          <span className="ml-2">
            {t(lg.documentationRecordActions.submitThreeDOperator.modalNotEnoughCredits.title)}
          </span>
        </div>
      }
      open={visible}
      okText={t(lg.documentationRecordActions.submitThreeDOperator.modalNotEnoughCredits.buttonOk)}
      cancelButtonProps={{ className: "hidden" }}
      onOk={onClose}
      onCancel={onClose}
      closable={true}
      destroyOnClose
    >
      <p className="text-base whitespace-pre-line">
        <ParseMD>
          {t(lg.documentationRecordActions.submitThreeDOperator.modalNotEnoughCredits.description, {
            organization: organizationName
          })}
        </ParseMD>
      </p>
    </Modal>
  );
};
