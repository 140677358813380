import { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { LayoutSimple } from "components/LayoutSimple";
import { Button } from "antd";
import { useTitle } from "react-use";
import { useFormat, useInvitation } from "hooks";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";
import { Illustration } from "components/Illustration";
import { roles } from "api/enums";

export const Invitation = ({ id }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing invitation ID.");

  const [t] = useTranslation();
  const { formatDate } = useFormat();
  const { loading, sendingAccept, sendingReject, disabled, data, handlers } = useInvitation(id);

  const title = useMemo<string>(
    () =>
      t(lg.invitations.pageDetail.heading, {
        invitedBy: `${data.invitation?.invitedBy.firstName} ${data.invitation?.invitedBy.lastName}`,
        organization: data.invitation?.organization.name
      }),
    [t, data.invitation]
  );

  useTitle(title);

  return (
    <LayoutSimple
      loading={loading}
      childLeft={<Illustration type="ill-01" className="max-w-full h-64 mx-auto md:h-auto" title={title} />}
      childRight={
        <div className="flex flex-col items-center md:items-start text-purple-200">
          <div className="max-w-full">
            <div className="text-lg md:text-2xl text-center md:text-left md:leading-snug font-medium">
              {title}
              <br />
              <br />
              {t(lg.invitations.pageDetail.role.label)}:{" "}
              {data.invitation?.role ? t(roles[data.invitation.role].nameI18nKey) : "-"}
            </div>

            <div className="mt-10">{t(lg.invitations.pageDetail.description.accept)}</div>

            <div className="mt-16">
              <Button
                onClick={handlers.handleClickAcceptInvite}
                type={"primary"}
                size={"large"}
                loading={sendingAccept}
                disabled={disabled}
              >
                {t(lg.invitations.pageDetail.buttonAcceptText)}
              </Button>
              <Button
                onClick={handlers.handleClickRejectInvite}
                type={"default"}
                size={"large"}
                className="ml-5"
                ghost
                loading={sendingReject}
                disabled={disabled}
              >
                {t(lg.invitations.pageDetail.buttonRejectText)}
              </Button>
              <Button
                onClick={handlers.handleClickNotNow}
                type={"default"}
                size={"large"}
                className="ml-5"
                ghost
                disabled={disabled}
              >
                {t(lg.invitations.pageDetail.buttonNotNowText)}
              </Button>
            </div>

            <div className="mt-5">{t(lg.invitations.pageDetail.description.notNow)}</div>
            <div className="text-xs mt-5">
              {t(lg.invitations.pageDetail.description.expiration, {
                expiration: formatDate(data.invitation?.expiresAt, "human")
              })}
            </div>
          </div>
        </div>
      }
    />
  );
};
