import { useCallback, useMemo, useState } from "react";
import { ConstructionObjectDeleteMutation, resolveError, useConstructionObjectDeleteMutation } from "api";
import { useAlert, useMe } from "hooks";

export const useConstructionObjectDelete = (id: string = "") => {
  const { showAlert } = useAlert();
  const { refetch: refetchMe } = useMe();
  const [deleteObject, { loading: loadingDeleteMutation, called }] = useConstructionObjectDeleteMutation();

  /** STATE */
  const [isRefetch, setIsRefetch] = useState<boolean>(false);
  const [mutationResult, setMutationResult] = useState<ConstructionObjectDeleteMutation>();

  /** DATA */
  const deleteResultData = useMemo(() => mutationResult?.constructionObject?.delete, [mutationResult]);
  const failedRecords = useMemo(() => sanitizeArray(deleteResultData?.failedRecords), [deleteResultData]);

  const failedData = useMemo(() => {
    if (failedRecords.length === 0) return;

    return {
      failedRecords
    };
  }, [failedRecords]);

  const sending = useMemo(() => loadingDeleteMutation || isRefetch, [loadingDeleteMutation, isRefetch]);
  const wasCompletelyDeleted = useMemo(() => called && deleteResultData?.archived, [called, deleteResultData]);

  /** HANDLERS */
  const handleDelete = useCallback(async () => {
    try {
      const result = await deleteObject({ variables: { input: { constructionObject: id } } });
      setIsRefetch(true);
      await refetchMe();
      setMutationResult(result.data);
    } catch (e) {
      resolveError(e, {}, showAlert);
    } finally {
      setIsRefetch(false);
    }
  }, [deleteObject, refetchMe, id, showAlert]);

  const handleClear = useCallback(() => setMutationResult(undefined), []);

  return {
    data: {
      sending,
      called,
      wasCompletelyDeleted,
      failedData
    },
    handlers: {
      handleDelete,
      handleClear
    }
  };
};

const sanitizeArray = (data?: any[]) => data?.flatMap(item => (item ? [item] : [])) || [];
