import { useState } from "react";

export const useCoordsModal = (isVisible: boolean = false, isSaved: boolean = true) => {
  const [isVisibleModalCoords, setIsVisibleModalCoords] = useState<boolean>(isVisible);
  const [hasSavedCoords, setHasSavedCoords] = useState<boolean>(isSaved);

  return {
    isVisibleModalCoords,
    setIsVisibleModalCoords,
    hasSavedCoords,
    setHasSavedCoords
  };
};
