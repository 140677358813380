import { Select, Spin } from "antd";
import { lg, pluralize } from "assets/translations";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useConstructionPhasesForSelectQuery } from "api";

const fetchStep: number = 8; // count of the phases to load during one fetch

type SelectConstructionPhaseProps = {
  constructionSiteId: string;
  value: string | undefined; // constructionPhaseId
  onChange: (constructionPhaseId?: string) => void;
  disabled?: boolean;
  allowClear?: boolean;
};

export const SelectConstructionPhase = ({
  constructionSiteId,
  value,
  onChange,
  disabled = false,
  allowClear = false
}: SelectConstructionPhaseProps) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>();
  /* debounced search */
  useDebounce(() => setDebouncedSearchValue(searchValue), 500, [searchValue]);

  const fetchSkip: boolean = !constructionSiteId || disabled;

  const { data: dataSearch, loading } = useConstructionPhasesForSelectQuery({
    variables: { constructionSiteId: constructionSiteId || "", name: debouncedSearchValue, first: fetchStep },
    fetchPolicy: "cache-and-network",
    skip: fetchSkip
  });

  const phases = useMemo(() => dataSearch?.constructionSite?.constructionPhases, [dataSearch]);
  const phasesMapped = useMemo(() => phases?.edges.flatMap(({ node }) => (node ? [node] : [])) || [], [phases]);
  const totalCountOfPhases = useMemo(() => phases?.totalCount, [phases]);

  // no objects for parsed ConstructionSiteId
  const noData = useMemo(
    () => !disabled && !loading && !debouncedSearchValue && phasesMapped.length <= 0,
    [disabled, loading, debouncedSearchValue, phasesMapped]
  );
  const _disabled = useMemo(() => noData || disabled, [noData, disabled]);

  const options = useMemo(
    () =>
      phasesMapped.map(result => ({
        value: result.id,
        label: result.name
      })),
    [phasesMapped]
  );

  const resetSearch = useCallback(() => setSearchValue(undefined), []);

  const handleSearch = useCallback((value: string) => setSearchValue(value), []);
  const handleChange = useCallback(
    (value: string) => {
      onChange(value);
      resetSearch();
    },
    [onChange, resetSearch]
  );

  return (
    <>
      <Select
        loading={loading}
        value={value}
        notFoundContent={loading ? <Spin size="small" className="w-full" /> : t(lg.searchSelect.common.noResult)}
        placeholder={t(lg.searchSelect.constructionPhase.placeholder)}
        dropdownRender={menu => {
          if (options.length >= fetchStep) {
            return (
              <div>
                {menu}
                <div className="text-gray-700 px-3">
                  {t(
                    pluralize(
                      totalCountOfPhases || 0,
                      lg.searchSelect.common.resultInfo.singularNominative,
                      lg.searchSelect.common.resultInfo.nominativePlural,
                      lg.searchSelect.common.resultInfo.genitivePlural,
                      lg.searchSelect.common.resultInfo.zero
                    ),
                    {
                      currentCount: options.length,
                      totalCount: totalCountOfPhases
                    }
                  )}
                </div>
              </div>
            );
          }

          return menu;
        }}
        filterOption={false}
        style={{ width: "100%" }}
        options={options}
        disabled={_disabled}
        showSearch
        allowClear={allowClear}
        onSearch={handleSearch}
        onChange={handleChange}
      />
      {noData ? <div className="text-red-600 mt-1">{t(lg.searchSelect.constructionPhase.noData)}</div> : null}
    </>
  );
};
