import { cloneElement, PropsWithChildren, ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { MediaCard } from "components/MediaCard";
import { File } from "types/media";
import { useZip } from "hooks";
import { lg } from "assets/translations";
import { GroundControlPoint, SimpleGroundControlPoint } from "components/Task/GroundControlPoints";

type Props = {
  children: ReactElement;
  documentationRecordName: string;
  points: GroundControlPoint[];
  simplePoint?: SimpleGroundControlPoint;
  video?: File;
  geodeticSurvey?: File;
  geodeticSurveyAdditional?: File[];
};

export const DownloadThreeDOperator = ({
  children,
  documentationRecordName,
  points,
  simplePoint,
  video,
  geodeticSurvey,
  geodeticSurveyAdditional
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const { downloadFiles, loading: loadingZip } = useZip();

  const [visible, setVisible] = useState(false);

  const handleClick = useCallback(() => setVisible(true), []);
  const handleOk = useCallback(() => setVisible(false), []);

  const handleClickDownload = useCallback(async () => {
    if (loadingZip) return;
    await downloadFiles(documentationRecordName, points, simplePoint, geodeticSurvey, geodeticSurveyAdditional);
  }, [
    documentationRecordName,
    points,
    simplePoint,
    geodeticSurvey,
    geodeticSurveyAdditional,
    downloadFiles,
    loadingZip
  ]);

  return (
    <>
      {cloneElement(children, { onClick: handleClick })}

      <Modal
        title={
          <div className="flex items-center">
            <DownloadOutlined className="text-lg text-purple-600" />
            <span className="ml-4">{t(lg.zipDownload.modal.title)}</span>
          </div>
        }
        open={visible}
        okText={loadingZip ? t(lg.zipDownload.processing) : t(lg.zipDownload.modal.buttonOkText)}
        okButtonProps={{ type: "primary" }}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        closable={false}
        confirmLoading={loadingZip}
      >
        <MediaCard fileName={`${documentationRecordName}.zip`} onDownload={handleClickDownload} />

        {!!video && <MediaCard fileName={video.fileName} fileUrl={video.url} fileId={video.id} className="mt-5" />}
      </Modal>
    </>
  );
};
