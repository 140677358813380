import { PropsWithChildren, useMemo } from "react";
import clsx from "clsx";
import { ExternalState } from "api";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";

type Props = {
  state: ExternalState;
  className?: string;
};

export const BadgeUserExternalStatus = ({ state, className }: PropsWithChildren<Props>) => {
  const [t] = useTranslation();

  const bgColor = useMemo(() => {
    if (state === ExternalState.Blocked_1 || state === ExternalState.Blocked_2) return "bg-red-600";
  }, [state]);

  const text = useMemo(() => {
    if (state === ExternalState.Blocked_1 || state === ExternalState.Blocked_2)
      return t(lg.state.userExternalState.blocked);
  }, [t, state]);

  if (!bgColor || !text) return null;

  return (
    <span
      className={clsx(
        "inline-block text-white text-sm leading-none rounded-full px-2 py-1 whitespace-nowrap",
        bgColor,
        className
      )}
    >
      {text}
    </span>
  );
};
