import { useCallback, useMemo } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Button } from "antd";
import { DocumentationRecordType, useDocumentationRecordCreateTypePageQuery } from "api";
import { TypeCard } from "components/DocumentationRecord/TypeCard";
import { Content } from "components/LayoutDashboard";
import { Section } from "components/Section";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations/lg";
import { useTranslation } from "react-i18next";
import { useCatalog } from "hooks";

export const DocumentationRecordCreateType = ({
  constructionSiteId,
  constructionObjectId
}: RouteComponentProps<{
  constructionSiteId: string;
  constructionObjectId: string;
}>) => {
  if (!constructionSiteId || !constructionObjectId) {
    throw new Error("Missing construction site or construction object ID.");
  }

  const { t } = useTranslation();
  const { data, loading } = useDocumentationRecordCreateTypePageQuery({
    variables: { constructionObjectId, constructionSiteId }
  });
  const { catalog } = useCatalog();

  const handleClickAbort = useCallback(
    () => navigate(setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id: constructionObjectId })),
    [constructionSiteId, constructionObjectId]
  );

  const constructionSite = useMemo(() => data?.constructionSite, [data]);
  const constructionObject = useMemo(() => data?.constructionObject, [data]);

  /** Subheader init and rerender */

  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const siteName = constructionSite?.name || "...";
    const objectName = constructionObject?.name || "...";

    return {
      heading: t(lg.documentationRecord.pageCreateType.header.title),
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        { value: siteName, route: setRouteParams(routes.constructionSiteDetail, { id: constructionSiteId }) },
        {
          value: objectName,
          route: setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id: constructionObjectId })
        },
        { value: t(lg.documentationRecord.pageCreateType.header.title) }
      ],
      buttons: [
        <Button key="abort" type={"default"} size={"large"} onClick={handleClickAbort}>
          {t(lg.documentationRecord.pageCreateType.header.buttonSecondaryText)}
        </Button>
      ]
    };
  }, [constructionSite, constructionObject, constructionSiteId, constructionObjectId, handleClickAbort, t]);
  useSubheader(subheaderOptions);

  const handleClickCardButton = useCallback(
    async (type: DocumentationRecordType) => {
      await navigate(
        setRouteParams(routes.documentationRecordCreate, { constructionSiteId, constructionObjectId, type })
      );
    },
    [constructionSiteId, constructionObjectId]
  );

  const cost = useMemo(() => catalog.createDocumentationRecord || 0, [catalog]);

  return (
    <Content loading={loading}>
      <Section title={t(lg.documentationRecord.pageCreateType.documentationType.title)}>
        <div className="flex flex-wrap mt-5 -mx-3 -my-5">
          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3 py-5">
            <TypeCard
              title={t(lg.documentationRecord.formSelectType.cardNoTheeDModel.title)}
              icon={"read-nothing"}
              cost={cost}
              onButtonClick={() => handleClickCardButton(DocumentationRecordType.NoThreeDNoVideo)}
              className="h-full"
            >
              <p className="whitespace-pre-line">
                {t(lg.documentationRecord.formSelectType.cardNoTheeDModel.description)}
              </p>
            </TypeCard>
          </div>

          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3 py-5">
            <TypeCard
              title={t(lg.documentationRecord.formSelectType.cardNoTheeDModelYet.title)}
              icon={"read-question-mark"}
              cost={cost}
              onButtonClick={() => handleClickCardButton(DocumentationRecordType.NoThreeDVideo)}
              className="h-full"
            >
              <p className="whitespace-pre-line">
                {t(lg.documentationRecord.formSelectType.cardNoTheeDModelYet.description)}
              </p>
            </TypeCard>
          </div>

          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3 py-5">
            <TypeCard
              title={t(lg.documentationRecord.formSelectType.cardNoGeoreferencedModel.title)}
              icon={"read-no-geo"}
              cost={cost}
              onButtonClick={() => handleClickCardButton(DocumentationRecordType.ThreeDNoScale)}
              className="h-full"
            >
              <p className="whitespace-pre-line">
                {t(lg.documentationRecord.formSelectType.cardNoGeoreferencedModel.description)}
              </p>
            </TypeCard>
          </div>

          <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3 py-5">
            <TypeCard
              title={t(lg.documentationRecord.formSelectType.cardGeoreferencedModel.title)}
              icon={"read-geo"}
              cost={cost}
              onButtonClick={() => handleClickCardButton(DocumentationRecordType.ThreeDScale)}
              className="h-full"
            >
              <p className="whitespace-pre-line">
                {t(lg.documentationRecord.formSelectType.cardGeoreferencedModel.description)}
              </p>
            </TypeCard>
          </div>
        </div>
      </Section>
    </Content>
  );
};
