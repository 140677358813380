import { Component, ReactNode } from "react";
import * as Sentry from "@sentry/browser";
import { lg } from "assets/translations";
import { Button } from "antd";
import { LayoutSimple } from "components/LayoutSimple";
import { WithTranslation, withTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { routes } from "routes";
import { Illustration } from "components/Illustration";

type Props = WithTranslation & { children: ReactNode };

type State = {
  hasError?: boolean;
  eventId?: any;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: { [key: string]: any }) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  handleClickReload = () => window.location.reload();
  handleClickRedirect = () => navigate(routes.login, { replace: true });

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      //render fallback UI
      return (
        <LayoutSimple
          childLeft={<Illustration type="ill-fail2" className="max-w-full h-64 mx-auto md:h-auto" />}
          childRight={
            <div>
              <div className="text-lg md:text-2xl text-purple-200 text-center md:text-left md:leading-snug">
                {t(lg.errorBoundary.title)}
              </div>

              <div className="text-purple-200 text-center leading-loose mt-10 md:text-left">
                {t(lg.errorBoundary.description)}
              </div>

              <div className="flex mt-16 justify-center md:justify-start">
                <Button type={"primary"} size={"large"} onClick={this.handleClickReload}>
                  {t(lg.errorBoundary.buttonReload.text)}
                </Button>
                <Button type={"primary"} size={"large"} className="ml-5" onClick={this.handleClickRedirect}>
                  {t(lg.errorBoundary.buttonRedirect.text)}
                </Button>
              </div>
            </div>
          }
        />
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
