import { useCallback, useEffect, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useAlert, useMe, useSubheader } from "hooks";
import { InquiryStatus, resolveError, useInquiryForReviewDetailPageQuery } from "api";
import { getInquiryName, isThreeDOperator } from "helpers";
import { routes, setRouteParams } from "routes";
import { ResolvedInquiryReview } from "pages/InquiryReview/ResolvedInquiryReview";
import { PendingInquiryReview } from "pages/InquiryReview/PendingInquiryReview";
import { lg } from "assets/translations";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { AdditionalServicesReviewTabKeys } from "pages/AdditionalServicesReview";

export const InquiryReview = ({ id, navigate }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing inquiry ID.");

  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();

  const { me, loading: loadingMe } = useMe();
  const isAuthorizedToView = useMemo<boolean>(() => isThreeDOperator(me), [me]);

  /* redirect a user to the default route if the user is not authorized to render this page */
  useEffect(() => {
    if (!me.role || isAuthorizedToView) return;
    navigate && navigate(routes.todo);
  }, [isAuthorizedToView, me, navigate]);

  const { data, loading: loadingInquiry } = useInquiryForReviewDetailPageQuery({
    variables: { id },
    fetchPolicy: "cache-and-network",
    skip: !isAuthorizedToView || !id,
    onError: e => resolveError(e, undefined, showAlert)
  });

  const loading = useMemo(() => loadingMe || loadingInquiry, [loadingMe, loadingInquiry]);

  const inquiry = useMemo(() => data?.inquiry, [data]);
  const inquiryName = useMemo(() => getInquiryName(inquiry, i18n.language), [inquiry, i18n.language]);
  const isInquiryPending = useMemo<boolean>(() => inquiry?.status === InquiryStatus.Created, [inquiry]);
  const isInquiryResolved = useMemo<boolean>(
    () => inquiry?.status === InquiryStatus.Completed || inquiry?.status === InquiryStatus.Rejected,
    [inquiry]
  );

  const parentPage = useMemo(() => {
    const tabKey = isInquiryResolved
      ? AdditionalServicesReviewTabKeys.ResolvedInquiries
      : AdditionalServicesReviewTabKeys.NewInquiries;
    const route = setRouteParams(routes.additionalServicesReviewActiveTab, { activeTab: tabKey });

    const i18n = isInquiryResolved
      ? lg.additionalServicesReview.tabs.resolvedInquiries
      : lg.additionalServicesReview.tabs.pendingInquiries;

    return {
      route,
      i18n
    };
  }, [isInquiryResolved]);

  const handleClickBack = useCallback(() => navigate && navigate(parentPage.route), [navigate, parentPage.route]);

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: `${t(lg.additionalServices.header.title)}: ${inquiryName}`,
      breadcrumb: [
        { value: t(t(lg.additionalServices.header.title)), route: routes.additionalServices },
        { value: t(parentPage.i18n), route: parentPage.route },
        {
          value: inquiryName
        }
      ]
    }),
    [t, inquiryName, parentPage]
  );
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      {/*
       Actions
       */}
      <section className="flex flex-wrap">
        <Button size="large" icon={<ArrowLeftOutlined />} onClick={handleClickBack}>
          {t(lg.inquiryReview.actions.backButton)}
        </Button>
      </section>

      {isInquiryPending && <PendingInquiryReview id={id} />}
      {isInquiryResolved && <ResolvedInquiryReview id={id} />}
    </Content>
  );
};
