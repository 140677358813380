import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Coords } from "google-map-react";
import { Map } from "components/Map";
import { Section } from "components/Section";
import { lg } from "assets/translations";

type Props = {
  constructionSiteCoords: Coords[];
  constructionObjectCoords: Coords[];
  className?: string;
};

export const MapOverview = ({
  constructionSiteCoords,
  constructionObjectCoords,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Section title={t(lg.constructionObject.pageDetail.map.title)} className={className}>
      <div className="flex flex-col md:flex-row mt-10 print_mt-2">
        <div className="w-full">
          <Map
            editable={false}
            editType={"object"}
            constructionSite={constructionSiteCoords}
            constructionObject={constructionObjectCoords}
          />
        </div>
      </div>
    </Section>
  );
};
