import { CustomIcon, CustomIconType } from "components/icons/CustomIcon";
import clsx from "clsx";
import { classes } from "components/Homepage/classes";

type CarouselItemProps = {
  title: string;
  customIconType: CustomIconType;
  content: string;
};
export const CarouselItem = ({ title, customIconType, content }: CarouselItemProps) => {
  return (
    <div className="inline-flex flex-col items-center w-full md:w-1/3 mt-2 md:mt-0 md:py-8 md:px-5">
      <CustomIcon type={customIconType} className="block w-12 text-blue-500" />
      <h3 className={clsx(classes.h3, "text-blue-800 md:text-center mt-2")}>{title}</h3>
      <div className="text-gray-700 text-xs mt-2.5">{content}</div>
    </div>
  );
};
