import { PropsWithChildren, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { LoadingOutlined, RollbackOutlined } from "@ant-design/icons";
import { ActionProps } from "components/DocumentationRecord/Actions/index";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import {
  BackOperatorHandler,
  ModalBackOperator
} from "components/DocumentationRecord/Actions/modals/ModalBackOperator";
import { Triggers } from "api";
import { lg } from "assets/translations";
import { documentationRecordTriggers } from "api/enums";

/** For Trigger BACK_OPERATOR */
export const BackOperator = ({
  documentationRecordId,
  triggers,
  disabled = false,
  threeDOperator,
  cost,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {}
}: PropsWithChildren<ActionProps & { threeDOperator: boolean; cost: number }>) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.BackOperator,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    onAfterTrigger
  );

  const handleClick = useCallback(() => setModalVisible(true), []);
  const handleCancel = useCallback(() => setModalVisible(false), []);
  const handleSubmit = useCallback<BackOperatorHandler>(
    (comment, reason, refund) => callTrigger(comment, reason, refund),
    [callTrigger]
  );

  if (!available) return null;

  return (
    <>
      <Button
        type="default"
        onClick={handleClick}
        size="large"
        icon={!loading ? <RollbackOutlined /> : <LoadingOutlined />}
        disabled={disabled || !conditions || loading}
      >
        {t(!loading ? documentationRecordTriggers[Triggers.BackOperator].buttonI18nKey : lg.saving)}
      </Button>
      <ModalBackOperator
        visible={modalVisible}
        loading={loading}
        threeDOperator={threeDOperator}
        cost={cost}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};
