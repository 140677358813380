import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { Button, Tooltip } from "antd";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { routes, setRouteParams } from "routes";
import { ConstructionSiteState, resolveError, useConstructionSiteDetailPageQuery } from "api";
import { PhasesTable } from "components/ConstructionSiteDetail/PhasesTable";
import { SummaryTable } from "components/ConstructionSiteDetail/SummaryTable";
import { ObjectsTable } from "components/ConstructionSiteDetail/ObjectsTable";
import { DocumentationRecordsTable } from "components/ConstructionSiteDetail/DocumentationRecordsTable";
import { useTranslation } from "react-i18next";
import { EditOutlined, InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import { Section } from "components/Section";
import { Map } from "components/Map";
import { useAlert, useFormat, useMe, usePermissions } from "hooks";
import { StateIndicatorConstructionSite } from "components/Indicators/State/StateIndicatorConstructionSite";
import { isManager } from "helpers";

export const ConstructionSiteDetail = ({ id }: PropsWithChildren<RouteComponentProps<{ id: string }>>) => {
  if (!id) {
    throw new Error("Missing construction site ID.");
  }

  const { t } = useTranslation();
  const { arrayToCoords, formatPhoneNumber, formatDate } = useFormat();
  const { showAlert } = useAlert();

  const [totalPhases, setTotalPhases] = useState<number>();
  const handleCompleteFetchPhasesTable = useCallback((totalPhases: number) => setTotalPhases(totalPhases), []);

  const { loading, data } = useConstructionSiteDetailPageQuery({
    variables: { id },
    onError: e => resolveError(e, undefined, showAlert),
    fetchPolicy: "cache-and-network"
  });

  const { canEditConstructionSite, canEditConstructionPhases, canCreateConstructionObject } = usePermissions({
    constructionSite: { managerId: data?.constructionSite?.accountableManager?.id }
  });

  const { me } = useMe();
  const canCreateDocumentationBySimplifiedWay = isManager(me);

  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const name = data?.constructionSite?.name || "...";

    return {
      heading: `${t(lg.constructionSite.pageDetail.header.title)}: ${name}`,
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        { value: `${name}` }
      ],
      buttons: [
        canEditConstructionSite ? (
          <Button
            key="edit-site"
            icon={<EditOutlined />}
            type={canCreateDocumentationBySimplifiedWay ? "default" : "primary"}
            size={"large"}
            onClick={() => navigate(setRouteParams(routes.constructionSiteEdit, { id }))}
          >
            {t(lg.constructionSite.pageDetail.header.buttonPrimaryText)}
          </Button>
        ) : null,
        canCreateDocumentationBySimplifiedWay ? (
          <Button
            key="create-new-documentation-record"
            type={"primary"}
            size={"large"}
            onClick={() =>
              navigate(setRouteParams(routes.documentationRecordCreateSimplifiedWithSiteId, { constructionSiteId: id }))
            }
          >
            <PlusOutlined />
            {t(lg.documentationRecords.header.buttonPrimaryText)}
          </Button>
        ) : null
      ]
    };
  }, [data?.constructionSite?.name, t, canCreateDocumentationBySimplifiedWay, canEditConstructionSite, id]);
  useSubheader(subheaderOptions);

  return (
    <Content loading={!data && loading}>
      {/*
      Base information
      */}
      <section>
        <Section title={t(lg.constructionSite.pageDetail.baseInformation.title)} />

        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div>
              <div className="font-medium">
                {t(lg.constructionSite.pageDetail.baseInformation.constructionDescriptionLabel)}
              </div>
              <span className="text-secondary whitespace-pre-line">{data?.constructionSite?.description || "-"}</span>
            </div>

            <div className="mt-10">
              <div className="font-medium">
                {t(lg.constructionSite.pageDetail.baseInformation.constructionManagerLabel)}
              </div>
              <span className="text-secondary">
                {data?.constructionSite?.accountableManager ? (
                  <>
                    {data.constructionSite.accountableManager.firstName}{" "}
                    {data.constructionSite.accountableManager.lastName} (
                    {data.constructionSite.accountableManager.email})
                    <br />
                    {formatPhoneNumber(data.constructionSite.accountableManager.phoneNumber || "")}
                  </>
                ) : (
                  <>{t(lg.constructionSite.pageDetail.baseInformation.authorPlaceholder)}</>
                )}
              </span>

              <div className="mt-5">
                <div className="font-medium">
                  {t(lg.constructionSite.pageDetail.baseInformation.authorDocumentationLabel)}
                </div>
                <span className="text-secondary">
                  {data?.constructionSite?.accountableOperator
                    ? `${data.constructionSite.accountableOperator.firstName} ${data.constructionSite.accountableOperator.lastName} (${data.constructionSite.accountableOperator.email})`
                    : t(lg.constructionSite.pageDetail.baseInformation.authorPlaceholder)}
                </span>
              </div>

              <div className="mt-5">
                <div className="font-medium">
                  {t(lg.constructionSite.pageDetail.baseInformation.authorGeodeticSurveyLabel)}
                </div>
                <span className="text-secondary">
                  {data?.constructionSite?.accountableSurveyor
                    ? `${data.constructionSite.accountableSurveyor.firstName} ${data.constructionSite.accountableSurveyor.lastName} (${data.constructionSite.accountableSurveyor.email})`
                    : t(lg.constructionSite.pageDetail.baseInformation.authorPlaceholder)}
                </span>
              </div>
            </div>
          </div>
          <div className="c-grid-column">
            <div>
              <div className="font-medium">
                {t(lg.constructionSite.pageDetail.baseInformation.constructionStateLabel)}
              </div>
              <StateIndicatorConstructionSite state={data?.constructionSite?.state || ConstructionSiteState.Inactive} />
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.constructionSite.pageDetail.baseInformation.checkControlLabel)}</div>
              <div className="flex items-center">
                <span className="text-secondary">
                  {data?.constructionSite?.autoRequestThreeD
                    ? t(lg.constructionSite.formControlSettings.cardAutomatically.title)
                    : t(lg.constructionSite.formControlSettings.cardCheckBeforePayment.title)}
                </span>
                <Tooltip
                  title={
                    data?.constructionSite?.autoRequestThreeD
                      ? t(lg.constructionSite.formControlSettings.cardAutomatically.description)
                      : t(lg.constructionSite.formControlSettings.cardCheckBeforePayment.description)
                  }
                  className="ml-2"
                >
                  <InfoCircleOutlined className="text-xl text-purple-600 cursor-pointer" />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
      Summary
      */}
      <Section title={t(lg.constructionSite.pageDetail.summaryView.title)} className="mt-16">
        <div className="mt-10">
          <SummaryTable
            constructionSiteId={id}
            hasCreateButton={canCreateConstructionObject}
            totalPhases={totalPhases}
          />
        </div>
      </Section>

      {/*
      Construction Objects
      */}
      <Section title={t(lg.constructionSite.pageDetail.objects.title)} className="mt-16">
        <ObjectsTable constructionSiteId={id} hasCreateButton={canCreateConstructionObject} />
      </Section>

      {/*
      Construction Phases
      */}
      <Section title={t(lg.constructionSite.pageDetail.phases.title)} className="mt-16">
        <PhasesTable
          constructionSiteId={id}
          editable={canEditConstructionPhases}
          onCompleteFetch={handleCompleteFetchPhasesTable}
        />
      </Section>

      {/*
      Documentation and 3D models
      */}
      <Section title={t(lg.constructionSite.pageDetail.documentation.title)} className="mt-16">
        <DocumentationRecordsTable
          organizationId={data?.constructionSite?.organization.id || ""}
          constructionSiteId={id}
        />
      </Section>

      {/*
      Construction on the map
      */}
      <Section title={t(lg.constructionSite.pageDetail.map.title)} className="mt-16">
        <div className="flex flex-col md:flex-row mt-10">
          {data?.constructionSite?.location?.length ? (
            <div className="w-full">
              <Map editable={false} constructionSite={arrayToCoords(data.constructionSite.location)} />
            </div>
          ) : (
            <div className="text-xs text-gray-700 text-center whitespace-pre-line w-full pb-5">
              {t(lg.constructionSite.pageDetail.map.noData)}
            </div>
          )}
        </div>
      </Section>

      {/*
      Details
      */}
      <Section title={t(lg.constructionSite.pageDetail.detailInformation.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div>
              <div className="text-gray-700">{t(lg.constructionSite.pageDetail.detailInformation.createdAtLabel)}</div>
              <span className="text-secondary">
                {formatDate(data?.constructionSite?.firstVersion.edges[0]?.node.createdAt, "human", true)}
              </span>
            </div>

            <div className="mt-5">
              <div className="text-gray-700">
                {t(lg.constructionSite.pageDetail.detailInformation.lastEditedAtLabel)}
              </div>
              <span className="text-secondary">
                {formatDate(data?.constructionSite?.currentVersion.edges[0]?.node.createdAt, "human", true)}
              </span>
            </div>
          </div>
          <div className="c-grid-column">
            <div>
              <div className="text-gray-700">{t(lg.constructionSite.pageDetail.detailInformation.createdByLabel)}</div>
              <span className="text-secondary">
                {data?.constructionSite?.firstVersion.edges[0]?.node.user?.firstName || ""}{" "}
                {data?.constructionSite?.firstVersion.edges[0]?.node.user?.lastName || ""} (
                {data?.constructionSite?.firstVersion.edges[0]?.node.user?.email || ""})
              </span>
            </div>

            <div className="mt-5">
              <div className="text-gray-700">
                {t(lg.constructionSite.pageDetail.detailInformation.lastEditedByLabel)}
              </div>
              <span className="text-secondary">
                {data?.constructionSite?.currentVersion.edges[0]?.node.user?.firstName || ""}{" "}
                {data?.constructionSite?.currentVersion.edges[0]?.node.user?.lastName || ""} (
                {data?.constructionSite?.currentVersion.edges[0]?.node.user?.email || ""})
              </span>
            </div>
          </div>
        </div>
      </Section>
    </Content>
  );
};
