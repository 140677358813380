import { lg } from "assets/translations";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "components/Link";
import { AboutPageTabKeys } from "pages";
import { routes, setRouteParams } from "routes";

export const AboutApp = () => {
  const [t] = useTranslation();

  return (
    <>
      <div className="c-grid">
        <div className="c-grid-column">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vyoTBX96AlE"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="max-w-full"
            title={t(lg.aboutPage.tabs.about.title)}
          />
        </div>
        <div className="c-grid-column">
          <div className="font-medium text-secondary whitespace-pre-line">{t(lg.aboutPage.tabs.about.annotation)}</div>

          <div className="text-secondary whitespace-pre-line mt-5">
            <Trans
              i18nKey={lg.aboutPage.tabs.about.content}
              components={[
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.Documentation })} />,
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.ThreeDModels })} />,
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.MyTasks })} />
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
