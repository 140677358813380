import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Section } from "components/Section";
import { Comment } from "components/DocumentationRecordDetail/Comment";
import { UserFragment } from "api";
import { lg } from "assets/translations";

export type AccountableUsers = {
  managerId?: string;
  operatorId?: string;
  surveyorId?: string;
  threeDOperatorId?: string;
};

type Props = {
  items?: {
    id: string;
    user: UserFragment | null;
    comment: string | null;
    createdAt: string;
  }[];
  accountableUsers: AccountableUsers;
  className?: string;
};

export const Comments = ({ items = [], accountableUsers, className }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const comments = useMemo(
    () =>
      items.map(({ id, user, comment, createdAt }) => ({
        key: id,
        author: resolveAuthor(t, user, accountableUsers),
        datetime: createdAt,
        content: comment || ""
      })),
    [items, accountableUsers, t]
  );

  if (!comments.length) return null;

  return (
    <Section title={t(lg.documentationRecord.pageDetail.comments.title)} className={className}>
      <div className="mt-10 print_mt-2">
        {comments.map((props, index) => (
          <Comment {...props} className={index > 0 ? "mt-10" : ""} />
        ))}
      </div>
    </Section>
  );
};

export const resolveAuthor = (t: TFunction, user: UserFragment | null, accountableUsers: AccountableUsers): string => {
  const roles = [];
  const roleTranslations = lg.documentationRecord.pageDetail.comments.role;
  if (user?.id === accountableUsers.managerId) roles.push(t(roleTranslations.manager));
  if (user?.id === accountableUsers.operatorId) roles.push(t(roleTranslations.operator));
  if (user?.id === accountableUsers.surveyorId) roles.push(t(roleTranslations.surveyor));
  if (user?.id === accountableUsers.threeDOperatorId) roles.push(t(roleTranslations.threeDOperator));

  return `${user?.firstName} ${user?.lastName}${!roles.length ? "" : ` (${roles.join(", ")})`}`;
};
