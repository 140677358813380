import { cloneElement, ReactElement, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Radio, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { resolveError, useRequestThreeDModelDownloadLinkMutation, useThreeDModelForDownloadQuery } from "api";
import { useAlert, useDownloadThreeDModel, useFormat, useMe } from "hooks";
import { lg } from "assets/translations/lg";
import { openInNewTab } from "helpers";

type ModalDownloadThreeDModelProps = {
  modelId: string;
  children: ReactElement;
};

/*
 * ModalDownloadThreeDModel
 *
 * pending but not required improves:
 * - show "no data" content for model without files
 * */
export const ModalDownloadThreeDModel = ({ modelId, children }: ModalDownloadThreeDModelProps) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);

  const { organization, refetch: refetchMe } = useMe(); // get credits
  const { creditCost } = useFormat();
  const currentCredits = useMemo(() => organization.credits, [organization]);

  const { price, isDisabled: isDownloadDisabled, isPaid: isDownloadPaid } = useDownloadThreeDModel();
  const visiblePrice = useMemo(() => price || 0, [price]); // price visible for the user

  const [fileId, setFileId] = useState<string>(); // file ID for download link request
  const downloadLinkRef = useRef<HTMLAnchorElement>(null); // helper element

  const hasRequiredCredit = useMemo(() => currentCredits >= visiblePrice, [currentCredits, visiblePrice]);
  const canDownloadModel = useMemo(() => hasRequiredCredit && !!fileId, [hasRequiredCredit, fileId]);

  const { showAlert } = useAlert();
  const skipQuery = useMemo(() => !visible || !modelId, [visible, modelId]);
  const { data: dataThreeDModel, loading } = useThreeDModelForDownloadQuery({
    skip: skipQuery,
    variables: { id: modelId },
    onCompleted: data => {
      // init first file as file for download
      if (!data || fileId) return;
      setFileId(data.threeDModel?.files?.find(item => item?.id)?.id);
    },
    onError: e => resolveError(e, undefined, showAlert),
    fetchPolicy: "cache-and-network"
  });

  const files = useMemo(() => dataThreeDModel?.threeDModel?.files, [dataThreeDModel]);
  const filesMapped = useMemo(() => files?.flatMap(file => (file ? [file] : [])) || [], [files]);

  const downloadFile = useCallback((url: string) => {
    if (!downloadLinkRef.current) return openInNewTab(url);
    downloadLinkRef.current.href = url; // set URL
    downloadLinkRef.current.click(); // force download
  }, []);

  const [requestLinkMutation, { loading: requesting }] = useRequestThreeDModelDownloadLinkMutation();
  const download = useCallback(
    async (media: string) => {
      try {
        const { data } = await requestLinkMutation({ variables: { input: { media } } });

        const url = data?.threeDModel?.requestDownloadLink?.url;
        if (!url) return;

        downloadFile(url);
        await refetchMe(); // update credits value at the apollo cache
      } catch (e) {
        resolveError(e, undefined, showAlert);
      } finally {
        close();
      }
    },
    [requestLinkMutation, downloadFile, showAlert, close, refetchMe]
  );

  const disabledOkButton = useMemo(
    () => loading || requesting || !canDownloadModel,
    [loading, requesting, canDownloadModel]
  );

  const handleOk = useCallback(
    async () => !isDownloadDisabled && fileId && download(fileId),
    [isDownloadDisabled, fileId, download]
  );
  const handleCancel = useCallback(() => {
    setFileId(undefined); // reset
    close();
  }, [close]);

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center">
            <DownloadOutlined className="text-lg text-purple-600" />
            <span className="ml-4">{t(lg.threeDViewer.modals.downloadThreeDModel.title)}</span>
          </div>
        }
        open={visible}
        footer={
          /* custom footer 'cause Cancel button is not need and can't be disable by prop*/
          <Button
            type={"primary"}
            disabled={disabledOkButton}
            loading={requesting}
            onClick={handleOk}
            data-cy={"action-modal-ok"}
          >
            {t(lg.threeDViewer.modals.downloadThreeDModel.buttonOkText)}
          </Button>
        }
        onCancel={handleCancel} // sets for the close cross
        closable
        destroyOnClose
      >
        {loading ? (
          <div className="flex justify-center">
            <Spin data-cy={"loading-options-sharing3DModel"} />
          </div>
        ) : (
          <>
            <div className="flex flex-col" data-cy={"options-sharing3DModel"}>
              {/* one file available */}
              {filesMapped.length === 1 ? <div>{filesMapped[0].fileName}</div> : null}

              {/* multiple files available */}
              {filesMapped.length > 1
                ? filesMapped.map((file, index) => (
                    <Radio
                      key={file.id}
                      className={index > 0 ? "mt-5" : ""}
                      name={file.id}
                      value={file.id}
                      checked={fileId === file.id}
                      onChange={() => setFileId(file.id)}
                    >
                      {file.fileName}
                    </Radio>
                  ))
                : null}

              {/* credit info */}
              {isDownloadPaid ? (
                <div className="flex mt-5">
                  <div>
                    <div>{t(lg.threeDViewer.modals.downloadThreeDModel.credits.actualState)}:</div>
                    <div className="font-medium">
                      {t(lg.threeDViewer.modals.downloadThreeDModel.credits.downloadPrice)}:
                    </div>
                  </div>
                  <div className="text-right ml-1">
                    <div className={hasRequiredCredit ? "text-purple-600" : "text-red-600"}>
                      {creditCost(currentCredits, false)}
                    </div>
                    <div className="text-purple-600">{creditCost(visiblePrice, false)}</div>
                  </div>
                </div>
              ) : null}

              <p className=" text-secondary mt-5">{t(lg.threeDViewer.modals.downloadThreeDModel.notice)}</p>
            </div>

            {/* Hidden anchor which force download file. Controlled by ref */}
            {/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
            <a
              ref={downloadLinkRef}
              target="_blank"
              rel="noopener noreferrer"
              className="hidden"
              aria-hidden
              download
            />
            {/* eslint-enable */}
          </>
        )}
      </Modal>
    </>
  );
};
