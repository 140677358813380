export function includes<T, U extends T>(arr: readonly U[], elem: T): elem is U {
  return arr.includes(elem as any);
}

export type Modify<T, R> = Omit<T, keyof R> & R;

/*
 * ObjectKeys
 *
 * example:
 * type Song = {
 *    id: string
 *    name: string
 *    played: number
 * }
 *
 * type ObjectKeysSong = ObjectKeys<Song> // "id" | "name" | "played"
 * */
export type ObjectKeys<T> = Extract<keyof T, string>;

export const enumToValues = <T>(e: { [key: string]: string }): T[] =>
  Object.keys(e).map((key: string | number) => e[key]) as unknown as T[];
