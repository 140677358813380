import { ComponentPropsWithoutRef, useCallback, useMemo, useState } from "react";
import { Coords } from "google-map-react";
import {
  PointChangeHandler,
  PointItem,
  PointRemoveHandler,
  PointSaveHandler
} from "components/Task/GroundControlPoints/Point";
import { useAlert } from "hooks";
import {
  resolveError,
  useGroundControlPointDocumentationCreateMutation,
  useGroundControlPointDocumentationDeleteMutation,
  useGroundControlPointDocumentationUpdateMutation
} from "api";
import { coordsEqual } from "components/Map/helpers";
import { getCoords } from "helpers";
import { GroundControlPointsMap } from "components/Map/GroundControlPointsMap";

export const usePoint = (
  point: PointItem,
  documentationRecordId: string,
  onSave: PointSaveHandler,
  onChange: PointChangeHandler,
  onRemove: PointRemoveHandler
) => {
  const { showAlert } = useAlert();

  const [coords, setCoords] = useState<Coords | undefined>(point.coords);
  const [coordsValid, setCoordsValid] = useState<boolean>(point.coords !== undefined);
  const [description, setDescription] = useState(point.description);
  const [photos, setPhotos] = useState<string[]>(point.photos);

  const [createPoint, { loading: creating }] = useGroundControlPointDocumentationCreateMutation();
  const [updatePoint, { loading: updating }] = useGroundControlPointDocumentationUpdateMutation();
  const [deletePoint, { loading: deleting }] = useGroundControlPointDocumentationDeleteMutation();

  const saveDisabled = useMemo(() => !coordsValid || !photos.length, [coordsValid, photos]);
  const loading = useMemo(() => creating || updating || deleting, [creating, updating, deleting]);

  const create = useCallback(async () => {
    try {
      const response = await createPoint({
        variables: {
          input: {
            documentationRecord: documentationRecordId,
            order: point.order,
            latitude: coords?.lat || 0,
            longitude: coords?.lng || 0,
            files: photos,
            description: description
          }
        }
      });

      const data = response.data?.groundControlPointDocumentation?.create?.groundControlPointDocumentation;
      if (!data) return;

      onSave(point.id, {
        id: data.id,
        order: data.order,
        coords: getCoords(data.location || []),
        description: data.description || "",
        photos: data.files.edges.map(({ node }) => node.id),
        draft: false,
        saved: true
      });
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [coords, photos, description, point, documentationRecordId, createPoint, showAlert, onSave]);

  const update = useCallback(
    async (id: string) => {
      try {
        const response = await updatePoint({
          variables: {
            input: {
              groundControlPointDocumentation: id,
              latitude: coords?.lat || 0,
              longitude: coords?.lng || 0,
              files: photos,
              description: description
            }
          }
        });

        const data = response.data?.groundControlPointDocumentation?.update?.groundControlPointDocumentation;
        if (!data) return;

        onSave(point.id, {
          id: data.id,
          order: data.order,
          coords: getCoords(data.location || []),
          description: data.description || "",
          photos: data.files.edges.map(({ node }) => node.id),
          draft: false,
          saved: true
        });
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [coords, photos, description, point, updatePoint, showAlert, onSave]
  );

  const handleChangeMap: Required<ComponentPropsWithoutRef<typeof GroundControlPointsMap>>["onChange"] = useCallback(
    (valid, coords, description) => {
      setCoords(coords);
      setCoordsValid(valid);
      description && setDescription(description);

      if (!coordsEqual(coords, point.coords) || description !== point.description) onChange(point.id);
    },
    [point.id, point.coords, point.description, onChange]
  );

  const handleAddPhoto = useCallback(
    (id: string) => {
      setPhotos(prevPhotos => [...prevPhotos, id]);
      onChange(point.id);
    },
    [point.id, onChange]
  );

  const handleRemovePhoto = useCallback(
    (id: string) => {
      setPhotos(prevPhotos => prevPhotos.filter(photo => photo !== id));
      onChange(point.id);
    },
    [point.id, onChange]
  );

  const handleClickSave = useCallback(async () => {
    if (point.draft) return await create();
    await update(point.id);
  }, [point, create, update]);

  const handleClickRemove = useCallback(async () => {
    if (point.draft) return onRemove(point.id);

    try {
      await deletePoint({ variables: { input: { groundControlPointDocumentation: point.id } } });
      onRemove(point.id);
    } catch (e) {
      resolveError(e, {}, showAlert);
    }
  }, [point.draft, point.id, deletePoint, showAlert, onRemove]);

  return {
    data: {
      saveDisabled,
      loading
    },
    state: {
      coords,
      coordsValid,
      description,
      photos
    },
    handlers: {
      handleChangeMap,
      handleAddPhoto,
      handleRemovePhoto,
      handleClickSave,
      handleClickRemove
    }
  };
};
