import { lg } from "assets/translations";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "components/Link";
import { AboutPageTabKeys } from "pages";
import { Section } from "components/Section";
import { routes, setRouteParams } from "routes";

export const ThreeDModels = () => {
  const [t] = useTranslation();
  return (
    <>
      <Section title={t(lg.aboutPage.tabs.threeDModels.about.title)}>
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary font-medium whitespace-pre-line">
              {t(lg.aboutPage.tabs.threeDModels.about.description)}
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.threeDModels.whatToDo.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              <Trans i18nKey={lg.aboutPage.tabs.threeDModels.whatToDo.description}>
                <Link href={setRouteParams(routes.aboutActiveTab, { activeTab: AboutPageTabKeys.Documentation })} />
              </Trans>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
