import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "antd";
import { AccountableUsers, resolveAuthor } from "components/DocumentationRecordDetail/Comments";
import { DocumentationRecordStateChangeFragment, Triggers } from "api";
import { lg } from "assets/translations";
import { useFormat } from "hooks";

type Props = {
  lastStateChange?: DocumentationRecordStateChangeFragment;
  accountableUsers: AccountableUsers;
  className?: string;
};

export const ReturnInfo = ({ lastStateChange, accountableUsers, className }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { formatDate } = useFormat();

  const hasReason = useMemo(
    () => typeof lastStateChange?.returnReason === "string" && lastStateChange.returnReason.length > 0,
    [lastStateChange]
  );

  // TODO only surveyor for now - https://hrdlicka.atlassian.net/browse/HRD003-443
  if (!lastStateChange || !lastStateChange.user || lastStateChange.trigger !== Triggers.BackSurveyor) return null;

  return (
    <Alert
      message={t(lg.taskSurveyor.alerts.returned.title)}
      description={
        <div>
          <div className="text-secondary">
            <span className="font-medium">{resolveAuthor(t, lastStateChange.user, accountableUsers)}</span>{" "}
            {formatDate(lastStateChange.createdAt, "human", true)}
          </div>
          {hasReason && <div className="text-secondary whitespace-pre-line">{lastStateChange.returnReason}</div>}
          <div className="text-secondary whitespace-pre-line">{lastStateChange.comment}</div>
        </div>
      }
      type="warning"
      className={className}
    />
  );
};
