import { useCallback, useMemo } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { lg } from "assets/translations/lg";
import { Badge, Button, Tabs } from "antd";
import { ReactComponent as DownloadButtonGoogle } from "assets/images/button_download_google_CZ.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useMe } from "hooks";
import { DocumentationRecordStateFilterEnum, useTaskCountsQuery } from "api";
import { Illustration } from "components/Illustration";
import { enumToValues, isManager, isReader } from "helpers";
import { TableTodo } from "components/Todo/TableTodo";
import { SimpleLoader } from "components/SimpleLoader";

export const TodoManager = ({ location }: RouteComponentProps) => {
  const { t } = useTranslation();

  const { me, loading: loadingMe } = useMe();
  const { data, loading: loadingCounts } = useTaskCountsQuery({
    fetchPolicy: "cache-and-network"
  });
  const loading = useMemo(() => loadingMe || loadingCounts, [loadingMe, loadingCounts]);

  const handleClickConstructionSites = useCallback(() => navigate(routes.constructionSites), []);

  const processingStates = enumToValues<DocumentationRecordStateFilterEnum>(DocumentationRecordStateFilterEnum).filter(
    s => s !== DocumentationRecordStateFilterEnum.RequiresOperator
  );
  const mobileStates = enumToValues<DocumentationRecordStateFilterEnum>(DocumentationRecordStateFilterEnum).filter(
    s => s === DocumentationRecordStateFilterEnum.RequiresOperator
  );

  const totalTasks = useMemo<number>(() => data?.me?.allTasks?.totalCount || 0, [data]);
  const mobileTasks = useMemo<number>(() => data?.me?.mobileTasks?.totalCount || 0, [data]);
  const processingTasks = useMemo<number>(() => totalTasks - mobileTasks, [totalTasks, mobileTasks]);

  const canShowProcessingTable = useMemo<boolean>(() => processingTasks > 0, [processingTasks]);
  const canShowMobileTable = useMemo<boolean>(() => mobileTasks > 0, [mobileTasks]);

  if (loading) return <SimpleLoader />;

  return (
    <>
      <Tabs
        defaultActiveKey={location?.hash !== "#mobile" ? "1" : "2"}
        items={[
          /*
          Tab - Pending Tasks
           */
          {
            label: <div className="text-base flex items-center">{t(lg.todo.tabs.pending)}</div>,
            key: "1",
            className: "pt-8",
            children: canShowProcessingTable ? (
              <TableTodo userRole={me.role || undefined} defaultStateFilter={processingStates} />
            ) : (
              <div className="flex flex-col items-center min-h-full">
                <p className="text-center whitespace-pre-line text-base text-blue-700">
                  {isReader(me) ? t(lg.todo.tabPending.noData.readOnly) : t(lg.todo.tabPending.noData.default)}
                </p>

                {isManager(me) && (
                  <>
                    <p className="text-center whitespace-pre-line text-base text-blue-700 mt-6">
                      {t(lg.todo.tabPending.noData.extendedInstruction)}
                    </p>
                    <Button ghost type="primary" onClick={handleClickConstructionSites} className="mt-10">
                      {t(lg.todo.tabPending.noData.buttonToConstructionSitesText)} <ArrowRightOutlined />
                    </Button>
                  </>
                )}

                <div className="flex-grow flex flex-col w-full p-5 md:p-20">
                  <Illustration
                    type="ill-03"
                    className="flex-grow h-full md:h-64"
                    title={isReader(me) ? t(lg.todo.tabPending.noData.readOnly) : t(lg.todo.tabPending.noData.default)}
                  />
                </div>
              </div>
            )
          },
          /*
          Tab - Tasks for Mobile App
           */
          {
            label: (
              <div className="text-base flex items-center">
                {t(lg.todo.tabs.forMobile)}
                {data?.me?.mobileTasks.totalCount && data?.me?.mobileTasks.totalCount > 0 ? (
                  <Badge count={data?.me?.mobileTasks.totalCount} className="ml-2" />
                ) : null}
              </div>
            ),
            key: "2",
            className: "pt-8",
            children: (
              <div className="flex flex-col items-center">
                <p className="text-center whitespace-pre-line text-base text-blue-700">
                  {t(lg.todo.tabForMobile.description)}
                </p>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  // TODO: add link to the mobile app, uncomment target attribute, remove eslint-disable-next-line
                  // issue: https://hrdlicka.atlassian.net/browse/HRD003-484
                  href={"#"}
                  // target="_blank"
                  rel="noopener noreferrer"
                  className="mt-5"
                >
                  <DownloadButtonGoogle className="w-32" />
                </a>

                <div className="w-full mt-10">
                  {canShowMobileTable ? (
                    <TableTodo userRole={me.role || undefined} defaultStateFilter={mobileStates} />
                  ) : (
                    <div className="flex flex-col items-center min-h-full">
                      <div className="text-base text-blue-700 text-center whitespace-pre-line">
                        {!isReader(me) ? (
                          <p>
                            {t(lg.todo.tabForMobile.noData.default)}
                            <br />
                            {t(lg.todo.tabForMobile.noData.extendedInstruction)}
                          </p>
                        ) : (
                          t(lg.todo.tabForMobile.noData.readOnly)
                        )}
                      </div>

                      <div className="flex-grow flex flex-col w-full p-5 md:p-20">
                        <Illustration
                          type="ill-03"
                          className="flex-grow h-full md:h-64"
                          title={t(lg.support.contacts.title)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          }
          //
        ]}
        animated={false}
      />
    </>
  );
};
