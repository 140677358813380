import { PropsWithChildren, useMemo } from "react";
import { Link } from "@reach/router";
import { Breadcrumb } from "antd";
import { useSubheader } from "hooks/useSubheader";
import clsx from "clsx";
import { useTitle } from "react-use";

type Props = {
  className?: string;
};

export const Subheader = ({ className }: PropsWithChildren<Props>) => {
  const { heading, breadcrumb, buttons, title } = useSubheader();
  useTitle(title);

  const buttonsMapped = useMemo(() => buttons?.flatMap(button => (button ? [button] : [])), [buttons]);

  return (
    <div className={clsx("flex items-center justify-between bg-white shadow-decent-down px-6 py-3", className)}>
      <div>
        <Breadcrumb>
          {breadcrumb.map(({ value, route }) => (
            <Breadcrumb.Item key={`bc-${route}-${value}`}>
              {!route ? value : <Link to={route}>{value}</Link>}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>

        <h1 className="text-2xl mt-2 mb-0">{heading}</h1>
      </div>

      {buttonsMapped.length > 0 ? (
        <div className="flex -mx-2">
          {buttonsMapped.map((button, index) => (
            <div key={button?.key || `subheader-button-${index}`} className="flex-grow px-2">
              {button}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
