import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useNavigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { Section } from "components/Section";
import { Media, useAlert, useFormat } from "hooks";
import { InquiryStatus, resolveError, useInquiryForReviewDetailPageQuery, useInquiryResolveMutation } from "api";
import { decodeRelayId, getLocaleInquiryTopic } from "helpers";
import { routes } from "routes";
import { Button, Input, message } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { ConstructionBreadcrumb } from "components/ConstructionBreadcrumb";
import { Uploader } from "components/Uploader";
import { CustomIcon } from "components/icons/CustomIcon";

type PendingInquiryReviewProps = { id: string };

export const PendingInquiryReview = ({ id }: PendingInquiryReviewProps) => {
  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const { formatDate } = useFormat();
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState<Media[]>([]);
  const [commentValue, setCommentValue] = useState<string>();

  const { data } = useInquiryForReviewDetailPageQuery({
    variables: { id },
    fetchPolicy: "cache-first", // fetched at parent component InquiryReview.tsx
    skip: !id,
    onError: e => resolveError(e, undefined, showAlert)
  });

  const [resolve, { loading: loadingResolve }] = useInquiryResolveMutation();
  const [decline, { loading: loadingDecline }] = useInquiryResolveMutation();

  const sending = useMemo(() => loadingResolve || loadingDecline, [loadingResolve, loadingDecline]);

  const decodedId = useMemo(() => decodeRelayId(id)?.id || "", [id]);
  const inquiry = useMemo(() => data?.inquiry, [data]);
  const requests = useMemo(() => inquiry?.requests.edges.flatMap(({ node }) => (node ? [node] : [])) || [], [inquiry]);

  const inquiryOrganizationId = useMemo<string>(() => inquiry?.organization.id || "", [inquiry]);

  const handleChangeUploader = useCallback((data: Media[]) => setUploadedFiles(data), []);
  const handleChangeComment = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => setCommentValue(event.target.value),
    []
  );

  const redirect = useCallback(() => navigate(routes.additionalServicesReview), [navigate]);

  const handleInquiryResolve = useCallback(async () => {
    try {
      await resolve({
        variables: {
          input: {
            comment: commentValue,
            files: uploadedFiles.map(media => media.id),
            inquiry: id,
            status: InquiryStatus.Completed
          }
        }
      });
      message.success(t(lg.inquiryReview.messages.inquiryResolved));
      redirect();
    } catch (e) {
      resolveError(e, undefined, showAlert);
    }
  }, [commentValue, id, resolve, showAlert, uploadedFiles, t, redirect]);

  const handleInquiryDecline = useCallback(async () => {
    try {
      await decline({
        variables: {
          input: {
            comment: commentValue,
            files: uploadedFiles.map(media => media.id),
            inquiry: id,
            status: InquiryStatus.Rejected
          }
        }
      });

      message.success(t(lg.inquiryReview.messages.inquiryDeclined));
      redirect();
    } catch (e) {
      resolveError(e, undefined, showAlert);
    }
  }, [commentValue, id, decline, showAlert, uploadedFiles, t, redirect]);

  return (
    <div>
      {/*
      Details
      */}
      <Section title={t(lg.inquiryReview.inquiryDetails.title)} className="mt-10">
        <div className="c-grid mt-5 md:mt-10">
          {/* left */}
          <div className="c-grid-column">
            <div>
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.inquiryNumber.label)}</div>
              <div className="text-secondary whitespace-pre-line">{decodedId}</div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.createdBy.label)}</div>
              <div className="text-secondary whitespace-pre-line">
                {`${inquiry?.user.firstName} ${inquiry?.user.lastName}` || "-"}
              </div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.userComment.label)}</div>
              <div className="text-secondary whitespace-pre-line">{inquiry?.userComment || "-"}</div>
            </div>
          </div>

          {/* right */}
          <div className="c-grid-column">
            <div>
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.createdAt.label)}</div>
              <div className="text-secondary whitespace-pre-line">
                {inquiry?.createdAt ? formatDate(inquiry.createdAt, "machine", true) : "-"}
              </div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.organization.label)}</div>
              <div className="text-secondary whitespace-pre-line">{inquiry ? inquiry?.organization.name : "-"}</div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiryReview.inquiryDetails.body.requestType.label)}</div>
              {requests.length > 0
                ? requests.map((request, index) => {
                    const documentationRecords =
                      request.documentationRecords.edges.flatMap(({ node }) => (node ? [node] : [])) || [];
                    const constructionSites =
                      request.constructionSites.edges.flatMap(({ node }) => (node ? [node] : [])) || [];
                    const constructionObjects =
                      request.constructionObjects.edges.flatMap(({ node }) => (node ? [node] : [])) || [];

                    const topic = getLocaleInquiryTopic(request.inquiryTopic, i18n.language).topic;

                    return (
                      <div key={request.id} className={index === 0 ? "" : "mt-5"}>
                        <div className="text-secondary">{topic}</div>

                        {documentationRecords.map(record => (
                          <ConstructionBreadcrumb
                            key={record.id}
                            constructionSite={record.constructionObject.constructionSite}
                            constructionObject={record.constructionObject}
                            documentationRecord={record}
                            disabled
                          />
                        ))}

                        {constructionSites.map(site => (
                          <ConstructionBreadcrumb key={site.id} constructionSite={site} disabled />
                        ))}

                        {constructionObjects.map(object => (
                          <ConstructionBreadcrumb
                            key={object.id}
                            constructionSite={object.constructionSite}
                            constructionObject={object}
                            disabled
                          />
                        ))}
                      </div>
                    );
                  })
                : "-"}
            </div>
          </div>
        </div>
      </Section>

      {/*
      Form
      */}
      <Section title={t(lg.inquiryReview.progressPending.title)} className="mt-10">
        <div className="c-grid mt-5 md:mt-10">
          {/* upload */}
          <div className="c-grid-column">
            <div className="text-base text-blue-700 font-medium">
              {t(lg.inquiryReview.progressPending.body.attachmentUpload.title)}
            </div>
            <div className="mt-5 mb-1">{t(lg.inquiryReview.progressPending.body.attachmentUpload.label)}</div>
            <Uploader organizationId={inquiryOrganizationId} files={uploadedFiles} onChange={handleChangeUploader} />
          </div>

          {/* comment */}
          <div className="c-grid-column">
            <div className="text-base text-blue-700 font-medium">
              {t(lg.inquiryReview.progressPending.body.comment.title)}
            </div>
            <div className="mt-5 mb-1">{t(lg.inquiryReview.progressPending.body.comment.label)}</div>
            <Input.TextArea
              placeholder={t(lg.inquiryReview.progressPending.body.comment.placeholder)}
              rows={8}
              onChange={handleChangeComment}
            />
          </div>
        </div>
      </Section>

      {/*
       Actions
       */}
      <section className="flex flex-wrap mt-10 justify-end">
        <Button
          size="large"
          type={"primary"}
          ghost
          icon={<StopOutlined />}
          disabled={sending}
          loading={loadingDecline}
          onClick={handleInquiryDecline}
        >
          {t(lg.inquiryReview.actions.declineInquiry)}
        </Button>

        <Button
          size="large"
          type={"primary"}
          icon={<CustomIcon type="send" className="text-white" />}
          className="flex align-center ml-5"
          disabled={sending}
          loading={loadingResolve}
          onClick={handleInquiryResolve}
        >
          <span className="ml-2">{t(lg.inquiryReview.actions.sendInquiry)}</span>
        </Button>
      </section>
    </div>
  );
};
