import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { lg } from "assets/translations/lg";
import { useTranslation } from "react-i18next";
import { useAlert, useFormat } from "hooks";
import {
  enumToValues,
  getThreeDModelType,
  hasFilesForDownload,
  isFinished,
  modelTypeToVideoTypeFilterEnum,
  TableProps
} from "helpers";
import { TypeIndicatorThreeDModel } from "components/Indicators/Type";
import {
  ConstructionObject,
  ConstructionPhase,
  ConstructionSite,
  DocumentationRecord,
  DocumentationRecordOrderByEnum,
  DocumentationRecordState,
  OrganizationTasksQueryVariables,
  resolveError,
  ThreeDModelFragment,
  useOrganizationTasksQuery
} from "api";
import { useColumnSearchProps, usePagination } from "helpers/serverSideTableFilter";
import { Table } from "antd";
import { ThreeDModelType, threeDModelTypes } from "api/enums";
import { ThreeDModelTableActions } from "components/ThreeDModelTableActions";

type Props = {
  organizationId: string;
};

type TableItem = {
  modelSize: DocumentationRecord["modelSize"] | null;
  modelId: ThreeDModelFragment["id"];
  modelType?: ThreeDModelType;
  isModelShared: boolean;
  hasModelFilesForDownload: boolean;
  modelCreatedAt: ThreeDModelFragment["createdAt"];
  constructionSiteName: ConstructionSite["name"];
  constructionObjectName: ConstructionObject["name"];
  documentationRecordName: DocumentationRecord["name"];
  documentationRecordState: DocumentationRecord["state"];
  constructionPhaseName: ConstructionPhase["name"];
};
type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: DocumentationRecordOrderByEnum[] } } = {
  constructionSiteName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteNameDesc]
  },
  modelSize: {
    ascend: [DocumentationRecordOrderByEnum.ModelSize],
    descend: [DocumentationRecordOrderByEnum.ModelSizeDesc]
  },
  modelCreatedAt: {
    ascend: [DocumentationRecordOrderByEnum.CreatedAt],
    descend: [DocumentationRecordOrderByEnum.CreatedAtDesc]
  }
};

export const ThreeDModelsTable = ({ organizationId }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { formatBytes, formatDate } = useFormat();
  const [orderBy, setOrderBy] = useState<OrganizationTasksQueryVariables["orderBy"]>(orderByMap.modelCreatedAt.descend);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });

  const [constructionSiteNameFilter, setConstructionSiteNameFilter] = useState("");
  const constructionSiteNameSearchProps = useColumnSearchProps();
  const [videoTypesFilter, setVideoTypesFilter] = useState<OrganizationTasksQueryVariables["videoTypes"]>([]);

  const { data, loading: loadingOrganizationTasks } = useOrganizationTasksQuery({
    variables: {
      organizationId: organizationId,
      skip,
      orderBy,
      constructionSiteName: constructionSiteNameFilter,
      withAccountableManager: false,
      withTotalSize: false,
      withModelSize: true,
      threeDModelUploaded: true,
      videoTypes: videoTypesFilter
    },
    fetchPolicy: "cache-and-network",
    skip: !organizationId,
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => {
    setTotal(data?.organization?.tasks.totalCount);
  }, [data]);

  const tasks = useMemo(() => data?.organization?.tasks?.edges, [data]);

  const tableData = useMemo<TableItem[]>(
    () =>
      tasks?.flatMap(({ node }): TableItem[] => {
        if (
          node.state === DocumentationRecordState.Archived ||
          node.threeDModel === null ||
          node.threeDModel.archivedAt
        ) {
          return [];
        }

        return [
          {
            modelSize: node.modelSize,
            modelCreatedAt: node.threeDModel.createdAt,
            modelId: node.threeDModel.id,
            modelType: getThreeDModelType(node),
            hasModelFilesForDownload: hasFilesForDownload(node.threeDModel),
            isModelShared: node.threeDModel.isShared,
            constructionSiteName: node.constructionObject.constructionSite.name,
            constructionObjectName: node.constructionObject.name,
            documentationRecordName: node.name,
            documentationRecordState: node.state,
            constructionPhaseName: node.constructionPhase.name
          }
        ];
      }) || [],
    [tasks]
  );

  const columns = useMemo<TablePropType["columns"]>(
    (): TablePropType["columns"] => [
      {
        title: t(lg.organizationResources.threeDModels.table.header.type),
        dataIndex: "modelType",
        key: "modelType",
        filters: enumToValues<ThreeDModelType>(ThreeDModelType).map(type => ({
          text: t(threeDModelTypes[type].nameI18nKey),
          value: type
        })),
        render: type => <TypeIndicatorThreeDModel type={type} />
      },
      {
        title: t(lg.organizationResources.threeDModels.table.header.construction),
        dataIndex: "constructionSiteName",
        key: "constructionSiteName",
        ...constructionSiteNameSearchProps,
        ellipsis: true,
        render: name => <span className="text-secondary">{name}</span>,
        sorter: true
      },
      {
        title: t(lg.organizationResources.threeDModels.table.header.createdAt),
        dataIndex: "modelCreatedAt",
        key: "createdAt",
        render: (createdAt?: string) => (
          <span className="text-secondary">{createdAt ? formatDate(createdAt, "machine", true) : "-"}</span>
        ),
        className: "whitespace-nowrap",
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        title: t(lg.organizationResources.threeDModels.table.header.fileSize),
        dataIndex: "modelSize",
        key: "modelSize",
        render: modelSize => <span className="text-secondary">{modelSize ? formatBytes(modelSize) : "-"}</span>,
        sorter: true
      },
      {
        title: t(lg.organizationResources.threeDModels.table.header.action),
        dataIndex: "modelId",
        key: "actions",
        render: (id, record) => (
          <ThreeDModelTableActions
            modelId={id}
            isShared={record.isModelShared}
            hasFilesForDownload={record.hasModelFilesForDownload}
            disabled={!isFinished(record.documentationRecordState)}
          />
        )
      }
    ],
    [t, constructionSiteNameSearchProps, formatDate, formatBytes]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter, extra) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setVideoTypesFilter(filter.modelType?.map(modelTypeToVideoTypeFilterEnum) || []);
      setConstructionSiteNameFilter(filter.constructionSiteName ? filter.constructionSiteName[0] : "");
    },
    [setPage]
  );
  const hasFilters = constructionSiteNameFilter || videoTypesFilter?.length;
  return (
    <div>
      {tableData.length === 0 && !hasFilters && !loadingOrganizationTasks ? (
        <div className="flex flex-col items-center">
          <div className="text-xs text-gray-700 text-center whitespace-pre-line">
            {t(lg.organizationResources.threeDModels.table.noData)}
          </div>
        </div>
      ) : (
        <Table<TableItem>
          dataSource={tableData}
          columns={columns}
          rowKey={"modelId"}
          loading={loadingOrganizationTasks}
          showSorterTooltip={false}
          onChange={handleOnChange}
          pagination={pagination}
          scroll={{ x: true }}
        />
      )}
    </div>
  );
};
