import { useTranslation } from "react-i18next";
import { Button, Form, Input, message } from "antd";
import { useCallback, useMemo, useState } from "react";
import { resolveError, useThreeDModelSendShareMutation } from "api";
import { lg } from "assets/translations";
import { isEmail } from "helpers";

enum FormItem {
  Recipients = "recipients",
  Comment = "comment"
}

type SendShareFormProps = {
  modelShareCode: string;
  className?: string;
};

export const SendShareModelForm = ({ modelShareCode, className }: SendShareFormProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [sendFailed, setSendFailed] = useState<boolean>(false);

  const validateForm = useCallback(() => {
    const hasError: boolean = form.getFieldsError().filter(field => field.errors && field.errors.length > 0).length > 0;
    const areRecipientsFilled = !!form.getFieldsValue()[FormItem.Recipients];
    setIsFormValid(!hasError && areRecipientsFilled);
  }, [form]);

  const getVariableRecipients = useCallback((): string[] => {
    const value = form.getFieldValue(FormItem.Recipients);
    if (typeof value === "string") return value.replace(/\s+/g, "").split(",");
    return [];
  }, [form]);

  const [sendShare, { loading: sendingSendShare }] = useThreeDModelSendShareMutation({
    variables: {
      input: {
        code: modelShareCode || "",
        recipients: getVariableRecipients(),
        comment: form.getFieldValue(FormItem.Comment)
      }
    },
    onCompleted: () => {
      setSendFailed(false);
      message.success(t(lg.threeDViewer.modals.shareThreeDModel.messages.successfullySent));
      form.resetFields();
    },
    onError: e => {
      setSendFailed(true);
      resolveError(e, {}, () => {});
    }
  });

  const stringContainEmailsOnly = useCallback((value: string) => {
    const sanitizedValues = value.replace(/\s+/g, "").split(",");
    for (let sanitizedValue of sanitizedValues) {
      if (!isEmail(sanitizedValue)) return false;
    }
    return true;
  }, []);

  const disabledSubmit = useMemo(() => !isFormValid || sendingSendShare, [isFormValid, sendingSendShare]);

  return (
    <Form
      form={form}
      className={className}
      size={"small"}
      layout={"vertical"}
      onFinish={sendShare}
      onFieldsChange={validateForm}
      data-cy={"form-send-share3DModel"}
    >
      {/* Recipients */}
      <Form.Item
        label={t(lg.threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.recipients.label)}
        name={FormItem.Recipients}
        rules={[
          () => ({
            validator(rule, value) {
              if (stringContainEmailsOnly(value)) return Promise.resolve();
              return Promise.reject(
                t(lg.threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.validations.invalid)
              );
            }
          })
        ]}
      >
        <Input
          placeholder={t(lg.threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.recipients.placeholder)}
          disabled={sendingSendShare}
          data-cy={"input-emails"}
        />
      </Form.Item>

      {/* Comment */}
      <Form.Item
        label={t(lg.threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.comment.label)}
        name={FormItem.Comment}
      >
        <Input.TextArea
          rows={2}
          placeholder={t(lg.threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.comment.placeholder)}
          disabled={sendingSendShare}
          data-cy={"textarea-comment"}
        />
      </Form.Item>

      {/* Submit Button*/}
      <Form.Item noStyle>
        <Button
          className="block ml-auto mt-3"
          type={"primary"}
          htmlType="submit"
          loading={sendingSendShare}
          disabled={disabledSubmit}
          data-cy={"actions-send"}
        >
          {t(lg.threeDViewer.modals.shareThreeDModel.shareModel.actions.buttonSend)}
        </Button>

        {sendFailed && (
          <div className="text-red-600 text-right ml-auto" style={{ maxWidth: 304 }}>
            {t(lg.threeDViewer.modals.shareThreeDModel.shareModel.sendShareForm.validations.send)}
          </div>
        )}
      </Form.Item>
    </Form>
  );
};
