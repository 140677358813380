import { PropsWithChildren, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Button } from "antd";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useSubheader } from "hooks/useSubheader";
import { routes, setRouteParams } from "routes";
import { resolveError, useConstructionObjectDetailPageQuery } from "api";
import { useTranslation } from "react-i18next";
import { DocumentationRecordsTable } from "components/ConstructionObject/DocumentationRecordsTable";
import { MapOverview } from "components/ConstructionObject/MapOverview";
import { lg } from "assets/translations/lg";
import { EditOutlined } from "@ant-design/icons";
import { Section } from "components/Section";
import { useAlert, useFormat, usePermissions } from "hooks";
import { StateIndicatorConstructionObject } from "components/Indicators/State/StateIndicatorConstructionObject";

export const ConstructionObjectDetail = ({
  constructionSiteId,
  id,
  navigate
}: PropsWithChildren<
  RouteComponentProps<{
    constructionSiteId: string;
    id: string;
  }>
>) => {
  if (!constructionSiteId || !id) throw new Error("Missing construction site or construction object ID.");

  const { t } = useTranslation();
  const { arrayToCoords, formatPhoneNumber, formatDate } = useFormat();
  const { showAlert } = useAlert();
  const { data, loading } = useConstructionObjectDetailPageQuery({
    variables: { constructionObjectId: id },
    onError: e => resolveError(e, undefined, showAlert),
    fetchPolicy: "cache-and-network"
  });

  const constructionObject = useMemo(() => data?.constructionObject, [data]);

  const { canEditConstructionObject, canCreateDocumentationRecord } = usePermissions({
    constructionObject: { managerId: constructionObject?.accountableManager?.id }
  });

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: `${t(lg.constructionObject.pageDetail.header.title)}: ${constructionObject?.name || "..."}`,
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        {
          value: constructionObject?.constructionSite?.name || "...",
          route: setRouteParams(routes.constructionSiteDetail, { id: constructionSiteId })
        },
        { value: constructionObject?.name || "..." }
      ],
      buttons: canEditConstructionObject
        ? [
            <Button
              onClick={() =>
                navigate && navigate(setRouteParams(routes.constructionObjectEdit, { constructionSiteId, id }))
              }
              key="edit-construction-object"
              icon={<EditOutlined />}
              type={"primary"}
              size={"large"}
            >
              {t(lg.constructionObject.pageDetail.header.buttonPrimaryText)}
            </Button>
          ]
        : undefined
    }),
    [constructionSiteId, id, constructionObject, canEditConstructionObject, navigate, t]
  );
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      {constructionObject && (
        <>
          {/** Base information */}
          <Section title={t(lg.constructionObject.pageDetail.baseInformation.title)}>
            <div className="c-grid mt-5 md:mt-10">
              <div className="c-grid-column">
                {/** Construction Object */}
                <div>
                  <div className="text-base text-blue-700 font-medium">
                    {t(lg.constructionObject.pageDetail.baseInformation.blockConstructionObject.title)}
                  </div>

                  <div className="mt-5">
                    <div className="font-medium">
                      {t(lg.constructionObject.pageDetail.baseInformation.blockConstructionObject.nameLabel)}
                    </div>
                    <span className="text-secondary">{constructionObject.name}</span>
                  </div>

                  <div className="mt-5">
                    <div className="font-medium">
                      {t(lg.constructionObject.pageDetail.baseInformation.blockConstructionObject.descriptionLabel)}
                    </div>
                    <span className="text-secondary whitespace-pre-line">{constructionObject.description || "-"}</span>
                  </div>

                  <div className="mt-5">
                    <div className="font-medium">
                      {t(lg.constructionObject.pageDetail.baseInformation.blockConstructionObject.stateLabel)}
                    </div>

                    <StateIndicatorConstructionObject state={constructionObject.state} />
                  </div>
                </div>

                {/** construction site info*/}
                <div className="mt-5 md:mt-10">
                  <div className="text-base text-blue-700 font-medium">
                    {t(lg.constructionObject.pageDetail.baseInformation.blockConstructionSite.title)}
                  </div>

                  <div className="mt-5">
                    <div className="font-medium">
                      {t(lg.constructionObject.pageDetail.baseInformation.blockConstructionSite.nameLabel)}
                    </div>
                    <span className="text-secondary">{constructionObject.constructionSite.name}</span>
                  </div>

                  <div className="mt-5">
                    <div className="font-medium">
                      {t(lg.constructionObject.pageDetail.baseInformation.blockConstructionSite.descriptionLabel)}
                    </div>
                    <span className="text-secondary whitespace-pre-line">
                      {constructionObject.constructionSite.description || "-"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="c-grid-column">
                {/** Accountable persons */}
                <div>
                  <div className="text-base text-blue-700 font-medium">
                    {t(lg.constructionObject.pageDetail.baseInformation.blockResponsibility.title)}
                  </div>

                  <div className="mt-5">
                    <div className="font-medium">
                      {t(lg.constructionObject.pageDetail.baseInformation.blockResponsibility.managerLabel)}
                    </div>
                    {constructionObject.accountableManager && (
                      <span className="text-secondary">
                        {constructionObject.accountableManager.firstName}{" "}
                        {constructionObject.accountableManager.lastName} ({constructionObject.accountableManager.email})
                        <br />
                        {formatPhoneNumber(constructionObject.accountableManager.phoneNumber || "")}
                      </span>
                    )}

                    <div className="mt-5">
                      <div className="font-medium">
                        {t(lg.constructionObject.pageDetail.baseInformation.blockResponsibility.operatorLabel)}
                      </div>
                      <span className="text-secondary">
                        {constructionObject.accountableOperator
                          ? `${constructionObject.accountableOperator.firstName} ${constructionObject.accountableOperator.lastName} (${constructionObject.accountableOperator.email})`
                          : t(lg.constructionObject.pageDetail.baseInformation.blockResponsibility.noUser)}
                      </span>
                    </div>

                    <div className="mt-5">
                      <div className="font-medium">
                        {t(lg.constructionObject.pageDetail.baseInformation.blockResponsibility.surveyorLabel)}
                      </div>
                      <span className="text-secondary">
                        {constructionObject.accountableSurveyor
                          ? `${constructionObject.accountableSurveyor.firstName} ${constructionObject.accountableSurveyor.lastName} (${constructionObject.accountableSurveyor.email})`
                          : t(lg.constructionObject.pageDetail.baseInformation.blockResponsibility.noUser)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          {/** Documentation and 3D models */}
          <Section title={t(lg.constructionObject.pageDetail.documentation.title)} className="mt-16">
            <DocumentationRecordsTable
              constructionObjectId={id}
              constructionSiteId={constructionSiteId}
              hasCreateButton={canCreateDocumentationRecord}
            />
          </Section>

          <MapOverview
            constructionSiteCoords={arrayToCoords(constructionObject.constructionSite?.location || [])}
            constructionObjectCoords={arrayToCoords(constructionObject?.location || [])}
            className="mt-16"
          />

          {/** Versions info */}
          <Section title={t(lg.constructionObject.pageDetail.detailInformation.title)} className="mt-16">
            <div className="c-grid mt-10">
              <div className="c-grid-column">
                <div>
                  <div className="text-gray-700">
                    {t(lg.constructionObject.pageDetail.detailInformation.createdAtLabel)}
                  </div>
                  <span className="text-secondary">
                    {formatDate(constructionObject.firstVersion.edges[0]?.node.createdAt, "human", true)}
                  </span>
                </div>

                <div className="mt-5">
                  <div className="text-gray-700">
                    {t(lg.constructionObject.pageDetail.detailInformation.lastEditedAtLabel)}
                  </div>
                  <span className="text-secondary">
                    {formatDate(constructionObject.currentVersion.edges[0]?.node.createdAt, "human", true)}
                  </span>
                </div>
              </div>
              <div className="c-grid-column">
                <div>
                  <div className="text-gray-700">
                    {t(lg.constructionObject.pageDetail.detailInformation.createdByLabel)}
                  </div>
                  <span className="text-secondary">
                    {constructionObject.firstVersion.edges[0]?.node.user?.firstName || ""}{" "}
                    {constructionObject.firstVersion.edges[0]?.node.user?.lastName || ""} (
                    {constructionObject.firstVersion.edges[0]?.node.user?.email || ""})
                  </span>
                </div>

                <div className="mt-5">
                  <div className="text-gray-700">
                    {t(lg.constructionObject.pageDetail.detailInformation.lastEditedByLabel)}
                  </div>
                  <span className="text-secondary">
                    {constructionObject.currentVersion.edges[0]?.node.user?.firstName || ""}{" "}
                    {constructionObject.currentVersion.edges[0]?.node.user?.lastName || ""} (
                    {constructionObject.currentVersion.edges[0]?.node.user?.email || ""})
                  </span>
                </div>
              </div>
            </div>
          </Section>
        </>
      )}
    </Content>
  );
};
