import { enumToValues, getInquiryName, TableProps, useColumnSearchProps, usePagination } from "helpers";
import { useTranslation } from "react-i18next";
import { useAlert, useFormat } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  InquiryOrderByEnum,
  InquiryStatus,
  InquiryStatusFilterEnum,
  resolveError,
  useInquiriesForAdditionalServicesReviewPageQuery
} from "api";
import { lg } from "assets/translations";
import { Link } from "components/Link";
import { routes, setRouteParams } from "routes";
import { Table } from "antd";
import { SimpleLoader } from "components/SimpleLoader";
import { StateIndicatorInquiry } from "components/Indicators/State/StateIndicatorInquiry";
import { inquiryStateFilters } from "api/enums";

type ResolvedInquiresInquiriesTableItem = {
  id: string;
  inquiryName: string;
  createdAt: string;
  requestedBy: string;
  organization: string;

  status: InquiryStatus;
};

type ResolvedInquiresInquiriesTablePropType = TableProps<ResolvedInquiresInquiriesTableItem>;
const orderByMap: { [key: string]: { [key: string]: InquiryOrderByEnum[] } } = {
  createdAt: {
    ascend: [InquiryOrderByEnum.CreatedAt],
    descend: [InquiryOrderByEnum.CreatedAtDesc]
  }
};

export const ResolvedInquiresTab = () => {
  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const { formatDate } = useFormat();

  const organizationSearchProps = useColumnSearchProps();
  const createdBySearchProps = useColumnSearchProps();
  const [organizationFilter, setOrganizationFilter] = useState<string>("");
  const [requestedByFilter, setRequestedByFilter] = useState<string>("");

  const [orderBy, setOrderBy] = useState<InquiryOrderByEnum[]>(orderByMap.createdAt.descend);
  const defaultStateFilter = useMemo(() => [InquiryStatusFilterEnum.Completed], []);
  const [statesFilter, setStatesFilter] = useState<InquiryStatusFilterEnum[]>(defaultStateFilter);

  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });

  const { data: dataInquiries, loading } = useInquiriesForAdditionalServicesReviewPageQuery({
    variables: {
      skip,
      orderBy: orderBy,
      status: statesFilter,
      organizationName: organizationFilter,
      requestedByName: requestedByFilter
    },
    fetchPolicy: "cache-and-network",
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => setTotal(dataInquiries?.inquiries?.totalCount), [dataInquiries]);

  const inquiries = useMemo(
    () => dataInquiries?.inquiries.edges.flatMap(({ node }) => (node ? [node] : [])) || [],
    [dataInquiries]
  );

  type TableItem = ResolvedInquiresInquiriesTableItem;
  type TableColumns = ResolvedInquiresInquiriesTablePropType["columns"];
  type TableOnChange = ResolvedInquiresInquiriesTablePropType["onChange"];

  const tableData = useMemo<TableItem[]>(
    () =>
      inquiries.map(inquiry => ({
        id: inquiry.id,
        inquiryName: getInquiryName(inquiry, i18n.language),
        createdAt: inquiry.createdAt,
        requestedBy: `${inquiry.user.firstName} ${inquiry.user.lastName}`,
        organization: inquiry.organization.name,
        status: inquiry.status
      })),
    [inquiries, i18n.language]
  );

  const tableColumns = useMemo<TableColumns>(
    () => [
      {
        dataIndex: "inquiryName",
        key: "inquiryName",
        title: t(lg.additionalServicesReview.resolvedInquiries.table.inquiryName),
        render: (inquiryName, { id }) => <Link href={setRouteParams(routes.inquiryReview, { id })}>{inquiryName}</Link>,
        ellipsis: true
      },
      {
        dataIndex: "createdAt",
        key: "createdAt",
        title: t(lg.additionalServicesReview.resolvedInquiries.table.createdAt),
        render: createdAt => (
          <span className="text-secondary">{createdAt ? formatDate(createdAt, "machine", true) : "-"}</span>
        ),
        sorter: true,
        ellipsis: true,
        className: "whitespace-nowrap",
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        dataIndex: "requestedBy",
        key: "requestedBy",
        title: t(lg.additionalServicesReview.resolvedInquiries.table.requestedBy),
        render: requestedBy => <span className="text-secondary">{requestedBy}</span>,
        ...createdBySearchProps,
        ellipsis: true
      },
      {
        dataIndex: "organization",
        key: "organization",
        title: t(lg.additionalServicesReview.resolvedInquiries.table.organization),
        render: (organization?) => <span className="text-secondary">{organization}</span>,
        ...organizationSearchProps,
        ellipsis: true
      },
      {
        dataIndex: "status",
        key: "status",
        title: t(lg.additionalServicesReview.resolvedInquiries.table.status),
        render: status => <StateIndicatorInquiry state={status} />,
        filters: enumToValues<InquiryStatusFilterEnum>(InquiryStatusFilterEnum).map(status => ({
          text: t(inquiryStateFilters[status].nameI18nKey),
          value: status
        })),
        defaultFilteredValue: defaultStateFilter
      }
    ],
    [defaultStateFilter, t, createdBySearchProps, organizationSearchProps, formatDate]
  );

  const handleOnChange: TableOnChange = useCallback(
    (pagination, filter, sorter, extra) => {
      pagination.current && setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);

      setOrganizationFilter(filter.organization ? filter.organization[0].toString() : "");
      setRequestedByFilter(filter.requestedBy ? filter.requestedBy[0].toString() : "");
      setStatesFilter(filter.status || []);
    },
    [setPage]
  );

  const hasFilters = organizationFilter || requestedByFilter || statesFilter;

  /* init */
  if (loading && !dataInquiries) return <SimpleLoader />;
  /* no data */
  if (tableData.length === 0 && !hasFilters)
    return (
      <p className="text-center whitespace-pre-line text-base text-blue-700">
        {/* TODO: remove "archive" info from the message */}
        {t(lg.additionalServicesReview.resolvedInquiries.noData)}
      </p>
    );

  return (
    <Table<TableItem>
      dataSource={tableData}
      columns={tableColumns}
      loading={loading}
      rowKey={"id"}
      onChange={handleOnChange}
      pagination={pagination}
    />
  );
};
