import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/Section";
import { MediaCard } from "components/MediaCard";
import { lg } from "assets/translations";

import { GeodeticSurvey } from "components/Task/GeodeticSurveyUpload";
import { GeodeticSurveyAdditional } from "components/Task/GeodeticSurveyAdditionalUpload";

type Props = {
  geodeticSurvey?: GeodeticSurvey;
  geodeticSurveyAdditional: GeodeticSurveyAdditional[];
  className?: string;
};

export const GeodeticSurveyUploadOverview = ({
  geodeticSurvey,
  geodeticSurveyAdditional,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        title={t(lg.documentationRecordDetail.geodeticSurveyUploadOverview.geodeticSurvey.title)}
        className={className}
      >
        {!geodeticSurvey ? (
          <div className="text-xs text-gray-700 text-center whitespace-pre-line mt-10">
            {t(lg.documentationRecord.pageDetail.videoImport.geodeticSurvey.noData)}
          </div>
        ) : (
          <>
            <div className="c-grid mt-10">
              <div key={geodeticSurvey.id} className="c-grid-column">
                <MediaCard fileName={geodeticSurvey.fileName} fileUrl={geodeticSurvey.url} fileId={geodeticSurvey.id} />
              </div>
            </div>
          </>
        )}
      </Section>

      {geodeticSurveyAdditional.length > 0 && (
        <Section
          title={t(lg.documentationRecordDetail.geodeticSurveyUploadOverview.geodeticSurveyAdditional.title)}
          className="mt-16"
        >
          <div className="c-grid mt-10">
            <div className="c-grid-column">
              {geodeticSurveyAdditional.map(({ id, url, fileName }, i) => (
                <div key={id} className={i > 0 ? "mt-5" : ""}>
                  <MediaCard fileName={fileName} fileUrl={url} fileId={id} />
                </div>
              ))}
            </div>
          </div>
        </Section>
      )}
    </>
  );
};
