import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import * as serviceWorker from "serviceWorkerRegistration";
import { Maybe } from "api";

type Value = {
  isUpdateAvailable: boolean;
  updateAssets: () => void;
};

const defaultValue: Value = {
  isUpdateAvailable: false,
  updateAssets: () => {}
};

const ServiceWorkerContext = createContext<Value>(defaultValue);

/*
 * source: https://medium.com/@FezVrasta/service-worker-updates-and-error-handling-with-react-1a3730800e6a
 * */
export const ServiceWorkerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState<Maybe<ServiceWorker>>(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(defaultValue.isUpdateAvailable);
  const updateInterval = useRef(0);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: registration => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);

        updateInterval.current = window.setInterval(() => registration.update(), 1000 * 60 * 5);
      },
      onWaiting: waiting => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      }
    });
    // clear interval on component unmount
    return window.clearInterval(updateInterval.current);
  }, []);

  useEffect(() => {
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an inconsistent state
    waitingServiceWorker?.addEventListener("statechange", event => {
      // @ts-ignore
      if (event.target?.state === "activated") {
        window.location.reload();
      }
    });
  }, [waitingServiceWorker]);

  const value = useMemo<Value>(
    () => ({
      isUpdateAvailable,
      updateAssets: () => {
        if (waitingServiceWorker) {
          // We send the SKIP_WAITING message to tell the Service Worker
          // to update its cache and flush the old one
          waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
      }
    }),
    [isUpdateAvailable, waitingServiceWorker]
  );

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

// With this React Hook we'll be able to access `isUpdateAvailable` and `updateAssets`
export const useServiceWorker = () => {
  return useContext(ServiceWorkerContext);
};
