import { useCallback, useEffect, useMemo, useState } from "react";
import { resolveError, useOrganizationLazyQuery, useOrganizationLeaveMutation } from "api";
import { Form } from "antd";
import { useAlert, useMe } from "hooks";
import { navigate } from "@reach/router";
import { routes } from "routes";
import { isManager } from "helpers";

export enum LeaveOrganizationActionType {
  leaveAndDelete = "leave_and_delete",
  leave = "leave",
  assignAndLeave = "assign_and_leave"
}

export enum LeaveOrganizationFormFields {
  userId = "userId"
}

export const useOrganizationLeave = (id: string = "") => {
  const { showAlert } = useAlert();
  const [leave, { loading: loadingLeaveMutation }] = useOrganizationLeaveMutation();

  const [form] = Form.useForm();

  /** STATE */
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [canSend, setCanSend] = useState<boolean>(false);

  const open = useCallback(() => setVisible(true), [setVisible]);
  /** DATA */
  const { me, refetch, loading: loadingMe } = useMe();
  const [load, { called, data, loading: loadingOrganization }] = useOrganizationLazyQuery({
    variables: { id },
    onError: e => resolveError(e, undefined, showAlert),
    fetchPolicy: "cache-and-network"
  });

  /* all Organization managers without logged user */
  const availableAccountableManagers = useMemo(
    () =>
      data?.organization?.organizationMemberships.edges
        .map(({ node }) => node.user)
        .filter(user => user.id !== me.id && isManager(user)) || [],
    [data, me]
  );

  const availableConstructionSites = data?.organization?.constructionSites.totalCount;

  const loading = useMemo(() => loadingMe || loadingOrganization, [loadingMe, loadingOrganization]);
  const sending = useMemo(() => loadingLeaveMutation, [loadingLeaveMutation]);
  const disabled = useMemo<boolean>(() => sending, [sending]);

  const actionType = useMemo<LeaveOrganizationActionType>(() => {
    if (isManager(me) && availableAccountableManagers.length === 0) return LeaveOrganizationActionType.leaveAndDelete;
    if (!isManager(me) && availableConstructionSites === 0) return LeaveOrganizationActionType.leave;
    return LeaveOrganizationActionType.assignAndLeave;
  }, [me, availableAccountableManagers.length, availableConstructionSites]);

  /** EFFECT */
  useEffect(() => {
    if (visible && !called) load();
  }, [visible, called, load]);

  useEffect(() => {
    setCanSend(actionType !== LeaveOrganizationActionType.assignAndLeave);
  }, [actionType]);

  /** HANDLERS */
  const handleSuccess = useCallback(async () => {
    setVisible(false);
    form.resetFields();
    await navigate(routes.organizations);
  }, [form, setVisible]);

  /* API */
  const leaveOrganization = useCallback(
    async (newManagerId?: string) => {
      if (!id) return console.log("missing organization ID");

      try {
        await leave({ variables: { input: { organization: id, newManager: newManagerId } } });
        await refetch();
        window.setTimeout(handleSuccess, 300);
      } catch (e) {
        resolveError(e, undefined, () => setVisibleAlert(true));
      }
    },
    [leave, refetch, id, handleSuccess]
  );

  /* Handlers - Modal */
  const handleOk = useCallback(() => {
    actionType === LeaveOrganizationActionType.assignAndLeave
      ? form
          .validateFields()
          .then(async values => {
            if (!values[LeaveOrganizationFormFields.userId]) return console.log("no new user ID");

            await leaveOrganization(values[LeaveOrganizationFormFields.userId]);
          })
          .catch(info => {
            console.log("Validation failed:", info);
          })
      : leaveOrganization();
  }, [actionType, form, leaveOrganization]);

  const handleCancel = useCallback(() => setVisible(false), []);

  const handleChange = useCallback(
    (changedFields: any[], allFields: any[]) => {
      const isError: boolean = allFields.filter(field => field.errors && field.errors.length > 0).length > 0;
      setCanSend(!isError);
    },
    [setCanSend]
  );

  const handleAfterClose = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleOkAlertLeaveModal = useCallback(() => {
    setVisibleAlert(false);
  }, []);

  return {
    loading,
    sending,
    disabled,
    form,

    open,

    data: {
      canSend,
      visible,
      visibleAlert,
      me,
      actionType,
      organization: data?.organization,
      availableAccountableManagers
    },

    handlers: {
      handleOk,
      handleCancel,
      handleChange,
      handleAfterClose,

      handleOkAlertLeaveModal
    }
  };
};
