import { PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { Coords } from "google-map-react";
import { useCopyToClipboard } from "react-use";
import { GroundControlPointsMap } from "components/Map/GroundControlPointsMap";
import { coordsToString } from "components/Map/helpers";
import { Link } from "components/Link";
import { MediaCard } from "components/MediaCard";
import { lg } from "assets/translations";
import clsx from "clsx";

export type GroundControlPoint = {
  id: string;
  order: number;
  coords: Coords;
  description: string;
  photos: { id: string; url: string; name: string }[];
};

type Props = {
  constructionSiteCoords: Coords[];
  constructionObjectCoords: Coords[];
  groundControlPoints: GroundControlPoint[];
  hasDownloadAndPrint?: boolean;
  className?: string;
};

export const StandardGroundControlPointsOverview = ({
  constructionSiteCoords,
  constructionObjectCoords,
  groundControlPoints,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const [copyState, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (copyState.value && !copyState.error) {
      message.success(t(lg.copyToClipboardSuccess, { value: copyState.value }), 1);
    }
  }, [copyState, t]);

  return (
    <div className={className}>
      <div className="print_no-break">
        <GroundControlPointsMap
          constructionSite={constructionSiteCoords}
          constructionObject={constructionObjectCoords}
          groundControlPoints={groundControlPoints}
          insertMode={false}
        />
      </div>

      <div className="mt-10">
        {groundControlPoints.map(({ id, order, coords, description, photos }, index) => (
          <div
            key={id}
            id={`point-${order}`}
            className={clsx(index !== groundControlPoints.length - 1 ? "mb-10" : "", "print_no-break")}
          >
            <div className="text-blue-700 text-base">{`${t(lg.groundPoint.prefix)} ${order}`}</div>
            <div className="c-grid mt-5 print_hidden">
              <div className="c-grid-column">
                {photos.map((file, index) => (
                  <MediaCard
                    key={file.id}
                    fileName={file.name}
                    fileUrl={file.url}
                    className={index > 0 ? "mt-5" : ""}
                  />
                ))}
              </div>
              <div className="c-grid-column">
                <PointLocation coords={coords} copyToClipboard={copyToClipboard} />
                <PointDescription description={description} className="mt-5" />
              </div>
            </div>

            {/* Print */}
            <div className="hidden print_block">
              <div className="c-grid">
                <div className="c-grid-column">
                  <PointLocation coords={coords} copyToClipboard={copyToClipboard} />
                </div>
                <div className="c-grid-column">
                  <PointDescription description={description} />
                </div>
              </div>
              <div className="c-grid mt-2">
                {photos.map(file => (
                  <div className="c-grid-column" key={`photo-print-${file.id}`}>
                    <img src={file.url} alt={file.name} style={{ maxHeight: 250 }} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PointLocation = ({ coords, copyToClipboard }: { coords: Coords; copyToClipboard: (coords: string) => any }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="font-medium">{t(lg.groundPoint.location.label)}</div>
      {/* disable Edge formatting: x-ms-format-detection="none"*/}
      <div className="text-secondary float-left" x-ms-format-detection="none">
        {coordsToString(coords)}
      </div>
      <Link className="ml-2 print_hidden" onClick={() => copyToClipboard(coordsToString(coords))}>
        {t(lg.copyToClipboard)}
      </Link>
    </div>
  );
};

const PointDescription = ({ description, className }: { description: string; className?: string }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="font-medium">{t(lg.groundPoint.description.label)}</div>
      <div className="text-secondary whitespace-pre-line">{description || "-"}</div>
    </div>
  );
};
