import { useCallback, useMemo, useState } from "react";
import { useConfig, useMe, usePurchaseClaim } from "hooks";
import { navigate } from "@reach/router";
import { routes } from "routes";
import { getSkippedPurchases, setSkippedPurchases } from "api/storage";

export enum PurchaseDetailContentType {
  Base = "BASE",
  MoreOrganizations = "MORE_ORGANIZATIONS",
  NoOrganization = "NO_ORGANIZATION"
}

export const usePurchase = (id: string) => {
  const { claimPurchase, loading: LoadingPurchaseMutation } = usePurchaseClaim();

  const redirect = useCallback(() => {
    navigate(routes.todo, { replace: true });
  }, []);

  /** STATE */
  const [claimedOrganization, setClaimedOrganization] = useState<string>();

  /** DATA */
  const { purchases, organizations, defaultOrganization, loading: loadingMe, refetch: refetchMe } = useMe();
  const { config, loading: configLoading } = useConfig();

  const purchase = useMemo(() => purchases.find(invite => invite.id === id), [id, purchases]);

  const loading = useMemo<boolean>(() => loadingMe || configLoading, [loadingMe, configLoading]);
  const sending = useMemo(() => LoadingPurchaseMutation, [LoadingPurchaseMutation]);
  const disabled = useMemo(() => loading || sending, [loading, sending]);

  const contentType = useMemo(() => {
    if (organizations.length === 1) return PurchaseDetailContentType.Base;
    if (organizations.length > 1) return PurchaseDetailContentType.MoreOrganizations;
    if (organizations.length === 0) return PurchaseDetailContentType.NoOrganization;
  }, [organizations]);

  const claimToOrganization = useMemo<string | undefined>(() => {
    if (contentType === PurchaseDetailContentType.MoreOrganizations) return claimedOrganization;
    return organizations[0]?.id || defaultOrganization?.id;
  }, [contentType, claimedOrganization, organizations, defaultOrganization]);

  /** HANDLERS */
  const handleChangeSelectOrganization = useCallback((value: string) => setClaimedOrganization(value), []);

  const handleClickClaimPurchase = useCallback(async () => {
    if (!claimToOrganization) return;

    const wasResolved = await claimPurchase({ purchase: id, organization: claimToOrganization });
    await refetchMe();
    wasResolved && redirect();
  }, [claimPurchase, id, claimToOrganization, redirect, refetchMe]);

  const handleClickNotNow = useCallback(() => {
    const previous = getSkippedPurchases() || [];
    const updatedSkippedIds = Array.from(new Set([...previous, id]));
    setSkippedPurchases(updatedSkippedIds);

    redirect();
  }, [redirect, id]);

  return {
    loading,
    sending,
    disabled,
    redirect,
    data: {
      purchase,
      contentType,
      organizations,
      defaultOrganization,
      claimedOrganization,

      helpDeskUrl: config.helpDeskUrl
    },
    handlers: {
      handleChangeSelectOrganization,
      handleClickClaimPurchase,
      handleClickNotNow
    }
  };
};
