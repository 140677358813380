import { PropsWithChildren, useCallback, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Button, message } from "antd";
import { Content } from "components/LayoutDashboard";
import { useSubheader } from "hooks/useSubheader";
import { routes, setRouteParams } from "routes";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import { RoleModalChange } from "components/ConstructionSiteEdit/RoleModalChange";
import { ModalDelete } from "components/ConstructionSiteEdit/ModalDelete";
import { Map } from "components/Map";
import { GeneralInfoForm } from "components/ConstructionSites/GeneralInfoForm";
import { ControlSettingsRadioCard } from "components/ConstructionSites/ControlSettingsRadioCards";
import { ModalUnsavedMap } from "components/ConstructionSites/ModalUnsavedMap";
import { Section } from "components/Section";
import { useConstructionSite, useFormat, usePermissions } from "hooks";
import { StateToggle } from "components/ConstructionSiteEdit/StateToggle";
import { StateModalChange } from "components/ConstructionSiteEdit/StateModalChange";

export const ConstructionSiteEdit = ({ id, navigate }: PropsWithChildren<RouteComponentProps<{ id: string }>>) => {
  if (!id) {
    throw new Error("Missing construction site ID.");
  }

  const { t } = useTranslation();
  const { arrayToCoords } = useFormat();

  const { form, loading, sending, disabled, state, data, handlers } = useConstructionSite("edit", id);

  const { canDeleteConstructionSite } = usePermissions({
    constructionSite: { managerId: data.constructionSite?.accountableManager?.id }
  });

  const handleDeleteSiteSuccess = useCallback(() => {
    message.success(t(lg.constructionSite.pageEdit.messages.objectWasDeleted));
    navigate && navigate(routes.constructionSites);
  }, [t, navigate]);

  const subheaderOptions = useMemo(() => {
    const constructionSiteName = data.constructionSite?.name || "...";

    return {
      title: `${t(lg.constructionSite.pageEdit.header.title)}: ${constructionSiteName}`,
      heading: `${t(lg.constructionSite.pageEdit.header.title)}: ${constructionSiteName}`,
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        {
          value: `${data.constructionSite?.name}`,
          route: setRouteParams(routes.constructionSiteDetail, { id: data.constructionSite?.id || "" })
        },
        { value: t(lg.constructionSite.pageEdit.header.title) }
      ],
      buttons: [
        <Button
          key="edit-construction-object-cancel"
          size={"large"}
          disabled={disabled}
          onClick={handlers.handleClickAbort}
        >
          {t(lg.constructionSite.pageEdit.header.buttonSecondaryText)}
        </Button>,
        <Button
          onClick={handlers.handleClickSave}
          disabled={disabled || !data.canSend || !data.hasValidCoords}
          key="edit-construction-object-save"
          icon={!loading ? <EditOutlined /> : <LoadingOutlined />}
          type={"primary"}
          size={"large"}
          loading={sending}
        >
          {!sending ? t(lg.constructionSite.pageEdit.header.buttonPrimaryText) : t(lg.saving)}
        </Button>
      ]
    };
  }, [
    t,
    disabled,
    loading,
    sending,
    data.canSend,
    data.hasValidCoords,
    data.constructionSite,
    handlers.handleClickAbort,
    handlers.handleClickSave
  ]);
  useSubheader(subheaderOptions);

  return (
    <Content loading={loading}>
      {/*
      Actions
      */}
      <div className="flex flex-wrap md:justify-between">
        <div>
          {canDeleteConstructionSite && (
            <ModalDelete id={id} name={data.constructionSite?.name || ""} onSuccess={handleDeleteSiteSuccess}>
              <Button icon={<DeleteOutlined />} danger type="primary" size="large">
                {t(lg.constructionSite.pageEdit.actions.delete)}
              </Button>
            </ModalDelete>
          )}
        </div>

        <StateToggle
          isActive={data.isSiteActive}
          disabled={loading}
          onChange={handlers.handleChangeState}
          description={
            data.isSiteActive
              ? t(lg.constructionSite.pageEdit.actions.statusActive)
              : t(lg.constructionSite.pageEdit.actions.statusInactive)
          }
        />
      </div>

      {/*
      Base information
      */}
      <Section title={t(lg.constructionSite.pageEdit.baseInformation.title)} className="mt-10">
        <div className="mt-10">
          <GeneralInfoForm
            formRef={form}
            disabled={loading}
            onChange={handlers.handleFormChange}
            onFinish={handlers.handleSubmit}
            users={data.myOrganization.users}
          />
        </div>
      </Section>

      {/*
      Construction on the map
      */}
      <Section title={t(lg.constructionSite.pageEdit.map.title)} className="mt-16">
        <div className="mt-10">
          <Map
            editable={true}
            saveDisabled={loading || data.hasSavedCoords}
            editType={"site"}
            constructionSite={arrayToCoords(data.constructionSite?.location || [])}
            hasSearch={true}
            hasValidCoords={data.hasValidCoords}
            onChange={handlers.handleMapChange}
            onSave={handlers.handleMapSave}
          />
        </div>
      </Section>

      {/*
      Control Settings
      */}
      <Section title={t(lg.constructionSite.pageEdit.controlSettings.title)} className="mt-16">
        <div className="flex flex-col mt-10">
          <div>{t(lg.constructionSite.pageEdit.controlSettings.description)}</div>

          <div className="mt-10">
            <ControlSettingsRadioCard
              defaultValue={data.defaultOrganizationValues.autoRequestThreeD ? "automatically" : "manually"}
              disabled={loading}
              onChange={handlers.handleChangeControlSettings}
            />
          </div>
        </div>
      </Section>

      {/*
      Modals
      */}
      <RoleModalChange
        values={state.descendantValues}
        visible={state.roleChangeModalVisible}
        onOk={handlers.handleOkModalRoleChange}
        onCancel={handlers.handleCancelModalRoleChange}
        options={{
          documentationRecords: data.hasDocumentationRecords,
          constructionObjects: data.hasConstructionObjects
        }}
      />
      {/* warning before save - unsaved map */}
      <ModalUnsavedMap
        visible={data.isVisibleModalCoords}
        onOk={handlers.handleClickCoordsModalOk}
        onCancel={handlers.handleClickCoordsModalCancel}
      />
      {/* confirm update construction object states after change construction site*/}
      <StateModalChange
        visible={state.stateChangeModalVisible}
        onChange={handlers.handleModalStateChange}
        constructionSiteState={data.updatedSiteState}
      />
    </Content>
  );
};
