import { PropsWithChildren } from "react";
import { ConstructionObjectState, ConstructionSiteState } from "api";
import { useTranslation } from "react-i18next";
import { StateIndicator, StateIndicatorType } from "components/Indicators/State/StateIndicator";
import { constructionObjectStates } from "api/enums";

type Props = {
  state: ConstructionObjectState;
};

const stateToTypeMap: { [key in ConstructionSiteState]: StateIndicatorType } = {
  [ConstructionObjectState.Active]: StateIndicatorType.success,
  [ConstructionObjectState.Inactive]: StateIndicatorType.error
};

export const StateIndicatorConstructionObject = ({ state }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <StateIndicator type={stateToTypeMap[state] || StateIndicatorType.default}>
      {t(constructionObjectStates[state].nameI18nKey)}
    </StateIndicator>
  );
};
