import { Section } from "components/Section";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";

export const Organization = () => {
  const [t] = useTranslation();
  return (
    <Section title={t(lg.aboutPage.tabs.organization.about.title)}>
      <div className="c-grid mt-10">
        <div className="c-grid-column">
          <div className="text-secondary whitespace-pre-line">
            {t(lg.aboutPage.tabs.organization.about.description)}
          </div>
        </div>
      </div>
    </Section>
  );
};
