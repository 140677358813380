import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { navigate } from "@reach/router";
import {
  ConstructionPhase,
  DocumentationRecordsForSummaryTableQuery,
  resolveError,
  useConstructionObjectsAndPhasesForSummaryTableQuery,
  useDocumentationRecordsForSummaryTableQuery
} from "api";
import { Button, Table, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { routes, setRouteParams } from "routes";
import { TypeIndicatorDocumentationRecord } from "components/Indicators/Type";
import { Link } from "components/Link";
import { useAlert } from "hooks";
import { SimpleLoader } from "components/SimpleLoader";
import { TableProps } from "helpers";
import { WatchQueryFetchPolicy } from "apollo-client/core/watchQueryOptions";

type Phase = Pick<ConstructionPhase, "id" | "name" | "order">;

type Props = {
  constructionSiteId: string;
  hasCreateButton: boolean;
  totalPhases?: number;
};

export const SummaryTable = ({ constructionSiteId, hasCreateButton, totalPhases }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [activeObjectId, setActiveObjectId] = useState<string>();
  const { data, loading, refetch } = useConstructionObjectsAndPhasesForSummaryTableQuery({
    skip: !constructionSiteId,
    variables: { constructionSiteId },
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (!data || activeObjectId) return;
      // init active Construction Object
      const objects = data?.constructionSite?.constructionObjects.edges;
      if (objects && objects.length > 0) return setActiveObjectId(objects[0].node.id);
    },
    onError: e => resolveError(e, undefined, showAlert)
  });

  const constructionObjects = useMemo(() => data?.constructionSite?.constructionObjects, [data]);
  const constructionObjectsMapped = useMemo(
    () => constructionObjects?.edges?.flatMap(({ node }) => (node ? [node] : [])) || [],
    [constructionObjects]
  );

  const constructionPhases = useMemo(() => data?.constructionSite?.constructionPhases, [data]);
  const constructionPhasesMapped = useMemo<Phase[]>(
    () => constructionPhases?.edges?.flatMap(({ node }) => (node ? [node] : [])) || [],
    [constructionPhases]
  );

  /*
   * Handle change of the total phases count outside this component
   * Refetch data when totalPhases count from the prop is not same as the count of the loaded phases by this component
   * */
  useEffect(() => {
    if (typeof totalPhases !== "number" || !constructionPhases) return;
    if (totalPhases === constructionPhases.totalCount) return;
    (async function run() {
      refetch && (await refetch());
    })();
  }, [refetch, totalPhases, constructionPhases]);

  const handleClickCreate = useCallback(
    () => navigate(setRouteParams(routes.constructionObjectCreate, { constructionSiteId })),
    [constructionSiteId]
  );

  const hasData = useMemo(
    () => constructionObjectsMapped && constructionObjectsMapped.length > 0,
    [constructionObjectsMapped]
  );

  if (loading && !hasData)
    return (
      <div className="flex justify-center w-full">
        <SimpleLoader />
      </div>
    );

  if (!hasData)
    return (
      <div className="flex flex-col items-center">
        <div className="text-xs text-gray-700 text-center">
          {t(lg.constructionSite.pageDetail.summaryView.table.noDataText)}
        </div>
        {hasCreateButton && (
          <Button type="dashed" icon={<PlusOutlined />} className="mt-5" onClick={handleClickCreate}>
            {t(lg.constructionSite.pageDetail.summaryView.table.buttonObjectCreateText)}
          </Button>
        )}
      </div>
    );

  return (
    <div className="flex flex-col">
      <div className="font-medium">{t(lg.typenames.constructionObject)}</div>
      <Tabs
        tabPosition="left"
        activeKey={activeObjectId}
        items={constructionObjectsMapped.map(object => ({
          label: object.name,
          key: object.id,
          children: (
            <>
              <div className="font-medium mb-8">{object.name}</div>
              {activeObjectId && (
                <SummaryTableTabContent phases={constructionPhasesMapped} constructionObjectId={activeObjectId} />
              )}
            </>
          )
        }))}
        className="flex-grow mt-5"
        style={{ maxHeight: 435 }}
        onChange={setActiveObjectId}
      />
    </div>
  );
};

type DocumentationRecord = NonNullable<
  DocumentationRecordsForSummaryTableQuery["constructionObject"]
>["documentationRecords"]["edges"][0]["node"];
type TableItem = Phase & {
  documentationRecords?: DocumentationRecord[];
};
type TablePropType = TableProps<TableItem>;

type SummaryTableTabContentProps = {
  constructionObjectId: string;
  phases: Phase[];
};

const SummaryTableTabContent = ({ constructionObjectId, phases }: SummaryTableTabContentProps) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const [fetchPolicy, setFetchPolicy] = useState<WatchQueryFetchPolicy>("cache-and-network");

  const { data, loading } = useDocumentationRecordsForSummaryTableQuery({
    skip: !constructionObjectId,
    variables: { constructionObjectId },
    fetchPolicy: fetchPolicy,
    onCompleted: data => {
      if (!data?.constructionObject) return;
      setFetchPolicy("cache-first");
    },
    onError: e => resolveError(e, undefined, showAlert)
  });

  const records = useMemo(() => data?.constructionObject?.documentationRecords, [data]);
  const recordsMapped = useMemo(() => records?.edges?.flatMap(({ node }) => (node ? [node] : [])), [records]);

  /*
   * Pair DocumentationRecords with Phases
   * */
  const tableData = useMemo<TableItem[]>(
    () =>
      phases
        .map(phase => {
          const phaseRecords = recordsMapped?.filter(record => record.constructionPhaseId === phase.id);
          return {
            ...phase,
            documentationRecords: phaseRecords
          };
        })
        // sort phases by order
        .sort((a, b) => (a.order > b.order ? 1 : -1)),
    [phases, recordsMapped]
  );

  const columns = useMemo<TablePropType["columns"]>(
    () => [
      {
        title: t(lg.constructionSite.pageDetail.summaryView.table.header.phase),
        dataIndex: "name",
        key: "name",
        className: "border-b-1",
        width: "25%",
        render: (name, { order }) => `${order}. ${name}`
      },
      {
        title: t(lg.typenames.documentationRecord),
        dataIndex: "documentationRecords",
        key: "documentationRecords",
        className: "border-b-1",
        render: (records, { id: phaseId }) => {
          /* no data */
          if (!records || records.length <= 0)
            return (
              <div className="text-xs text-gray-700">
                {t(lg.constructionSite.pageDetail.summaryView.table.noDocumentation)}
              </div>
            );

          return (
            <div className="flex flex-wrap -m-2">
              {records.map(({ id, name, type, state }, i) => (
                <div
                  className="flex flex-grow p-2"
                  style={{ minWidth: "50%" }} // align items to the grid by 2 cells/one cell on row
                  key={`summary-table-record-p${phaseId}-r${id}-${i}`}
                >
                  <TypeIndicatorDocumentationRecord
                    type={type}
                    state={state}
                    label={<Link href={setRouteParams(routes.documentationRecordDetail, { id })}>{name}</Link>}
                  />
                </div>
              ))}
            </div>
          );
        }
      }
    ],
    [t]
  );

  if (loading)
    return (
      <div className="flex justify-center items-center w-full h-full">
        <SimpleLoader />
      </div>
    );

  return (
    <Table<TableItem>
      dataSource={tableData}
      columns={columns}
      rowKey={"id"}
      loading={{ delay: tableData.length === 0 ? 0 : 300, spinning: loading }}
      pagination={false}
    />
  );
};
