import { PropsWithChildren } from "react";
import { Button } from "antd";
import { lg } from "assets/translations";
import { UploadOutlined } from "@ant-design/icons";
import { ButtonProps } from "antd/lib/button/button";
import { useTranslation } from "react-i18next";

type Props = Pick<ButtonProps, "children" | "className" | "disabled" | "onClick" | "size"> & {
  uploading?: boolean;
  progress: number;
};

export const ButtonUpload = ({
  children,
  className,
  disabled,
  onClick,
  size,

  uploading,
  progress
}: PropsWithChildren<Props>) => {
  const [t] = useTranslation();
  return (
    <Button
      className={className}
      disabled={disabled}
      icon={!uploading ? <UploadOutlined /> : undefined}
      loading={uploading}
      onClick={onClick}
      size={size}
    >
      {uploading ? t(lg.uploadButton.states.inProgress, { percents: progress }) : children}
    </Button>
  );
};
