import { PropsWithChildren, useCallback } from "react";
import { Slider } from "antd";

type Props = {
  onChange?: (percent: number) => any;
  className?: string;
};
const marks = {
  0: "0%",
  25: "25%",
  50: "50%",
  75: "75%",
  100: "100%"
};
export const CreditSlider = ({ onChange = () => {}, className }: PropsWithChildren<Props>) => {
  const handleChangeRefundSlider = useCallback(
    (value: number | [number, number]) => {
      if (typeof value === "number") onChange(value);
    },
    [onChange]
  );

  return (
    <Slider
      marks={marks}
      step={1}
      onChange={handleChangeRefundSlider}
      className={className}
      tipFormatter={value => <span>{value ? `${value} %` : "-"} </span>}
    />
  );
};
