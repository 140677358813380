import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "@reach/router";
import { Button } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined, SaveOutlined, WalletOutlined } from "@ant-design/icons";
import { Content } from "components/LayoutDashboard";
import { Section } from "components/Section";
import { GeneralInfoForm } from "components/DocumentationRecord/GeneralInfoForm";
import { ModalCost } from "components/DocumentationRecord/ModalCost";
import { VideoTypeRadioCards } from "components/DocumentationRecord/VideoTypeRadioCards";
import { ProgressSteps } from "components/DocumentationRecord/ProgressSteps";
import { SubheaderOptions, useDocumentationRecordCreate, useSubheader } from "hooks";
import { DocumentationRecordType } from "api";
import { routes, setRouteParams } from "routes";
import { lg } from "assets/translations/lg";
import { useFormat } from "hooks/useFormat";
import { ModalSubmitOperator } from "components/DocumentationRecord/Actions/modals/ModalSubmitOperator";

type Props = RouteComponentProps<{
  constructionSiteId: string;
  constructionObjectId: string;
  type: DocumentationRecordType;
}>;

export const DocumentationRecordCreate = ({
  constructionSiteId,
  constructionObjectId,
  type = DocumentationRecordType.NoThreeDNoVideo
}: Props) => {
  if (!constructionSiteId || !constructionObjectId) {
    throw new Error("Missing construction site or construction object ID.");
  }

  const { t } = useTranslation();
  const { creditCost } = useFormat();

  const { loading, disabled, inProgressCreate, inProgressSendToOperator, data, state, handlers, form } =
    useDocumentationRecordCreate(constructionSiteId, constructionObjectId, type);

  /** Subheader init and rerender */
  const subheaderOptions = useMemo<SubheaderOptions>(() => {
    const siteName = data.site?.name || "...";
    const objectName = data.object?.name || "...";

    return {
      heading: t(lg.documentationRecord.pageCreate.header.title),
      breadcrumb: [
        { value: t(lg.constructionSites.header.title), route: routes.constructionSites },
        { value: siteName, route: setRouteParams(routes.constructionSiteDetail, { id: constructionSiteId }) },
        {
          value: objectName,
          route: setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id: constructionObjectId })
        },
        { value: t(lg.documentationRecord.pageCreate.header.title) }
      ],
      buttons: [
        <Button key="abort" type={"default"} size={"large"} disabled={disabled} onClick={handlers.handleClickAbort}>
          {t(lg.documentationRecord.pageCreate.header.buttonSecondaryText)}
        </Button>
      ]
    };
  }, [constructionSiteId, constructionObjectId, data.object, data.site, disabled, handlers.handleClickAbort, t]);
  useSubheader(subheaderOptions);

  return (
    <>
      <Content loading={loading} flexGrow={false}>
        <ProgressSteps documentationRecordType={type} autoRequestThreeD={data?.site?.autoRequestThreeD || false} />
      </Content>

      <Content loading={loading}>
        <Button size={"large"} disabled={disabled} onClick={handlers.handleClickBack}>
          <ArrowLeftOutlined />
          {t(lg.documentationRecord.pageCreate.actions1.buttonBackToTheTypeText)}
        </Button>

        <Section title={t(lg.documentationRecord.pageCreate.baseInformation.title)} className="mt-10 mb-10">
          <div className="mt-10">
            <GeneralInfoForm
              form={form.ref}
              constructionSiteId={constructionSiteId}
              documentationRecordType={type}
              users={data.organization.users}
              disabled={disabled}
              onChange={handlers.handleChange}
              onSubmit={handlers.handleSubmit}
              showPhaseSelect
            />
          </div>
        </Section>

        {type !== DocumentationRecordType.NoThreeDNoVideo && (
          <Section title={t(lg.documentationRecord.pageCreate.videoType.title)} className="mt-10 mb-20">
            <VideoTypeRadioCards
              documentationRecordType={type}
              videoType={state.videoType}
              disabled={disabled}
              onSelect={handlers.handleChangeVideoType}
            />
          </Section>
        )}

        {data.createCost > 0 && (
          <div className="flex justify-end mb-5">
            <div className="flex flex-col items-end">
              <p className="text-xs">{t(lg.documentationRecord.pageCreate.priceInfo)}</p>
              <div className="flex items-center mt-2">
                <WalletOutlined className="text-lg" />
                <span className="font-medium ml-2">{creditCost(data.createCost)}</span>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end gap-5">
          <Button
            size={"large"}
            icon={<SaveOutlined />}
            disabled={!data.canSave || disabled}
            onClick={handlers.handleClickSave}
            loading={inProgressCreate}
          >
            {t(lg.documentationRecord.pageCreate.actions2.buttonCreateAndSaveText)}
          </Button>
          <Button
            size={"large"}
            type={"primary"}
            disabled={!(data.canSave && data.canSendToOperator) || disabled}
            onClick={handlers.handleClickSaveAndSend}
            loading={inProgressSendToOperator}
          >
            {t(lg.documentationRecord.pageCreate.actions2.buttonCreateAndSendText)}
            <ArrowRightOutlined />
          </Button>
        </div>

        <ModalSubmitOperator
          loading={inProgressSendToOperator}
          visible={data.isModalSubmitToOperatorVisible}
          onSubmit={handlers.handleModalSubmitToOperatorOk}
          onCancel={handlers.handleModalSubmitToOperatorCancel}
        />

        <ModalCost
          visible={data.isModalCreateCostVisible}
          title={
            state.sendToOperator
              ? t(lg.documentationRecord.pageCreate.modals.createCost.title.createAndSend)
              : t(lg.documentationRecord.pageCreate.modals.createCost.title.createAndSave)
          }
          buttonOk={
            state.sendToOperator
              ? t(lg.documentationRecord.pageCreate.modals.createCost.buttonOkText.createAndSendText)
              : t(lg.documentationRecord.pageCreate.modals.createCost.buttonOkText.createAndSaveText)
          }
          cost={data.createCost}
          resources={data.organization.credits}
          onOk={handlers.handleModalCreateCostOk}
          onCancel={handlers.handleModalCreateCostCancel}
        />
      </Content>
    </>
  );
};
