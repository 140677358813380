import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { ActionProps } from "components/DocumentationRecord/Actions/index";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import {
  ChangeToThreeDHandler,
  ModalChangeToThreeD
} from "components/DocumentationRecord/Actions/modals/ModalChangeToThreeD";
import { Triggers, useDocumentationRecordQuery } from "api";
import { lg } from "assets/translations";
import { useCatalog, useMe } from "hooks";
import { documentationRecordTriggers } from "api/enums";
import { getCost } from "helpers";

/** For Trigger CHANGE_TO_THREE_D */
export const ChangeToThreeD = ({
  documentationRecordId,
  triggers,
  disabled = false,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {}
}: PropsWithChildren<ActionProps>) => {
  const { t } = useTranslation();

  const { organization } = useMe();
  const { catalog } = useCatalog();
  const { data } = useDocumentationRecordQuery({ variables: { id: documentationRecordId } });

  const videoType = data?.documentationRecord?.documentationRecordVideo?.type || null;
  const cost = useMemo(() => getCost(videoType, catalog), [videoType, catalog]);

  const [modalVisible, setModalVisible] = useState(false);

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.ChangeToThreeD,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    onAfterTrigger
  );

  const handleClick = useCallback(() => setModalVisible(true), []);
  const handleCancel = useCallback(() => setModalVisible(false), []);
  const handleSubmit = useCallback<ChangeToThreeDHandler>(comment => callTrigger(comment), [callTrigger]);

  if (!available) return null;

  return (
    <>
      <Button type="primary" onClick={handleClick} size="large" disabled={disabled || !conditions || loading}>
        {t(!loading ? documentationRecordTriggers[Triggers.ChangeToThreeD].buttonI18nKey : lg.saving)}
        {!loading ? <ArrowRightOutlined /> : <LoadingOutlined />}
      </Button>
      <ModalChangeToThreeD
        visible={modalVisible}
        loading={loading}
        organizationCredits={organization.credits}
        cost={cost}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};
