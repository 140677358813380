import { useCallback, useEffect, useMemo, useState } from "react";
import { useAlert, useCatalog, useMe } from "hooks";
import {
  DocumentationRecord,
  DocumentationRecordCreateInput,
  DocumentationRecordType,
  DocumentationRecordVideoType,
  resolveError,
  Triggers,
  useDocumentationRecordCreateMutation,
  useDocumentationRecordCreatePageQuery,
  useDocumentationRecordTriggerMutation
} from "api";
import { navigate } from "@reach/router";
import { routes, setRouteParams } from "routes";
import { VideoTypeValue } from "components/DocumentationRecord/VideoTypeRadioCards";
import { GeneralInfoFormData, GeneralInfoFormItemName } from "components/DocumentationRecord/GeneralInfoForm";
import { Form, message } from "antd";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";
import { FieldData } from "rc-field-form/es/interface";

export const useDocumentationRecordCreate = (
  constructionSiteId: string,
  constructionObjectId: string,
  documentationRecordType: DocumentationRecordType
) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const [create, { loading: loadingCreateMutation }] = useDocumentationRecordCreateMutation();
  const [callTrigger, { loading: loadingTriggerMutation }] = useDocumentationRecordTriggerMutation();

  const redirect = useCallback(
    () => navigate(setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id: constructionObjectId })),
    [constructionObjectId, constructionSiteId]
  );

  /** STATE */
  const [isFormValid, setIsFormValid] = useState(true);
  const [sendToOperator, setSendToOperator] = useState(false);
  const [operatorNote, setOperatorNote] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<VideoTypeValue>(null);
  const [isModalSubmitToOperatorVisible, setIsModalSubmitToOperatorVisible] = useState(false);
  const [isModalCreateCostVisible, setIsModalCreateCostVisible] = useState(false);
  const [canSendToOperator, setCanSendToOperator] = useState(false);

  /** REFS */
  const [form] = Form.useForm();

  /** DATA */
  const { me, organization, loading: loadingMe } = useMe();
  const { catalog, loading: loadingCatalog } = useCatalog();
  const { data, loading: loadingConstructionObject } = useDocumentationRecordCreatePageQuery({
    variables: { constructionSiteId, constructionObjectId },
    fetchPolicy: "cache-and-network"
  });

  const createCost = catalog.createDocumentationRecord;
  const object = useMemo(() => data?.constructionObject, [data]);
  const site = useMemo(() => data?.constructionSite, [data]);
  const defaultPhase = useMemo(() => site?.constructionPhases.edges.find(({ node }) => node.id)?.node, [site]);

  const formDefaultValues = useMemo<GeneralInfoFormData>(
    () => ({
      name: object?.suggestedDocumentationName || "",
      phaseId: defaultPhase?.id,
      accountableManagerId:
        object?.accountableManager?.id || organization.users.find(({ id }) => id === me.id)?.id || "",
      accountableOperatorId: object?.accountableOperator?.id || "",
      accountableSurveyorId: object?.accountableSurveyor?.id || ""
    }),
    [defaultPhase, me.id, object, organization.users]
  );

  /* init default Form values */
  useEffect(() => form.setFieldsValue(formDefaultValues), [form, formDefaultValues]);

  const loading = loadingMe || loadingCatalog || loadingConstructionObject;

  const inProgressCreate: boolean = loadingCreateMutation;
  const inProgressSendToOperator: boolean = loadingTriggerMutation;
  const disabled: boolean = inProgressCreate || inProgressSendToOperator;

  const canSave = useMemo(() => isFormValid, [isFormValid]);

  /** HANDLERS */
  const checkIfCanSendToOperator = useCallback(() => {
    const hasSelectedUserPermissions =
      form.getFieldValue(GeneralInfoFormItemName.accountableManagerId) === object?.accountableManager?.id;
    const isOperatorSelected = !!form.getFieldValue(GeneralInfoFormItemName.accountableOperatorId);

    setCanSendToOperator(hasSelectedUserPermissions && isOperatorSelected);
  }, [object, form]);

  const createRecord = useCallback(
    async (values: GeneralInfoFormData): Promise<Pick<DocumentationRecord, "id"> | undefined | null> => {
      if (!values.phaseId || !values.name) return;

      const input: DocumentationRecordCreateInput = {
        constructionObject: constructionObjectId,
        constructionPhase: values.phaseId,
        name: values.name,
        type: documentationRecordType,
        videoType: videoType,
        description: values.description,
        accountableManager: values.accountableManagerId,
        accountableOperator: values.accountableOperatorId,
        accountableSurveyor: values.accountableSurveyorId
      };

      try {
        const { data } = await create({
          variables: { input: { ...input } }
        });

        return data?.documentationRecord?.create?.documentationRecord;
      } catch (e) {
        resolveError(e, {}, showAlert);
      }
    },
    [create, videoType, constructionObjectId, documentationRecordType, showAlert]
  );
  const handleClickAbort = useCallback(
    () => navigate(setRouteParams(routes.constructionObjectDetail, { constructionSiteId, id: constructionObjectId })),
    [constructionSiteId, constructionObjectId]
  );

  const handleClickBack = useCallback(
    () => navigate(setRouteParams(routes.documentationRecordCreateType, { constructionSiteId, constructionObjectId })),
    [constructionSiteId, constructionObjectId]
  );

  const handleChange = useCallback(
    (changedFields: FieldData[], allFields: FieldData[]) => {
      // check if form is valid to enable external submit button
      setIsFormValid(!allFields.filter(field => field.errors && field.errors.length > 0).length);

      checkIfCanSendToOperator();
    },
    [checkIfCanSendToOperator]
  );
  const handleChangeVideoType = useCallback((type: DocumentationRecordVideoType | null) => setVideoType(type), []);

  const handleSave = useCallback(
    async (values: GeneralInfoFormData) => {
      await createRecord(values);

      message.success(t(lg.documentationRecord.pageCreate.messages.documentationCreatedAndSaved), 5);
      redirect();
    },
    [t, createRecord, redirect]
  );

  const handleSaveAndSend = useCallback(
    async (values: GeneralInfoFormData) => {
      const record = await createRecord(values);
      if (!record) return;

      try {
        await callTrigger({
          variables: {
            input: { documentationRecord: record.id, trigger: Triggers.SubmitOperator, comment: operatorNote }
          }
        });

        message.success(t(lg.documentationRecord.pageCreate.messages.documentationCreatedAndSent), 5);
        redirect();
      } catch (e) {
        resolveError(e, {}, showAlert);
        redirect(); // because new Documentation Record was created
      }
    },
    [t, callTrigger, createRecord, operatorNote, redirect, showAlert]
  );

  const handleSubmit = useCallback(
    async (values: GeneralInfoFormData) => {
      sendToOperator ? await handleSaveAndSend(values) : await handleSave(values);
    },
    [sendToOperator, handleSave, handleSaveAndSend]
  );

  const handleClickSave = useCallback(() => {
    setSendToOperator(false);
    if (createCost > 0) {
      setIsModalCreateCostVisible(true);
      return;
    }

    form.submit();
  }, [form, createCost]);

  const handleClickSaveAndSend = useCallback(() => {
    setSendToOperator(true);
    if (createCost > 0) {
      setIsModalCreateCostVisible(true);
      return;
    }

    setIsModalSubmitToOperatorVisible(true);
  }, [createCost]);

  const handleModalSubmitToOperatorOk = useCallback(
    (comment?: string) => {
      setIsModalSubmitToOperatorVisible(false);
      setOperatorNote(comment);
      setSendToOperator(true);
      form.submit();
    },
    [form]
  );

  const handleModalSubmitToOperatorCancel = useCallback(() => setIsModalSubmitToOperatorVisible(false), []);

  const handleModalCreateCostOk = useCallback(() => {
    setIsModalCreateCostVisible(false);
    if (sendToOperator) {
      setIsModalSubmitToOperatorVisible(true);
      return;
    }

    form.submit();
  }, [form, sendToOperator]);

  const handleModalCreateCostCancel = useCallback(() => setIsModalCreateCostVisible(false), []);

  /** EFFECTS */
  useEffect(() => {
    if (loading || !formDefaultValues) return;
    checkIfCanSendToOperator();
  }, [checkIfCanSendToOperator, formDefaultValues, loading]);

  return {
    loading,
    disabled,
    inProgressCreate,
    inProgressSendToOperator,
    data: {
      me,
      organization,
      createCost,
      site,
      object,
      formDefaultValues,
      canSave,
      canSendToOperator,
      isModalSubmitToOperatorVisible,
      isModalCreateCostVisible
    },
    state: {
      sendToOperator,
      operatorNote,
      videoType
    },
    handlers: {
      handleClickAbort,
      handleClickBack,
      handleChange,
      handleChangeVideoType,
      handleSubmit,
      handleClickSave,
      handleClickSaveAndSend,
      handleModalSubmitToOperatorOk,
      handleModalSubmitToOperatorCancel,
      handleModalCreateCostOk,
      handleModalCreateCostCancel
    },
    form: { ref: form }
  };
};
