import { useCallback, useMemo, useState } from "react";
import {
  AfterTriggerHandler,
  BeforeTriggerHandler,
  TriggerErrorHandler
} from "components/DocumentationRecord/Actions/index";
import { resolveError, TriggerFragment, Triggers, useDocumentationRecordTriggerMutation } from "api";
import { getTrigger } from "helpers";
import { useMountedState } from "react-use";
import { useAlert } from "hooks";

export const useTrigger = (
  trigger: Triggers,
  triggers: TriggerFragment[],
  documentationRecord: string,
  onBeforeTrigger: BeforeTriggerHandler,
  onAfterTrigger: AfterTriggerHandler,
  onTriggerError?: TriggerErrorHandler
) => {
  const [loading, setLoading] = useState(false);
  const isMounted = useMountedState();
  const { showAlert } = useAlert();

  const [mutate] = useDocumentationRecordTriggerMutation();

  const callTrigger = useCallback(
    async (comment?: string, reason?: string, refund?: number) => {
      setLoading(true);

      await onBeforeTrigger(trigger);

      try {
        await mutate({ variables: { input: { documentationRecord, trigger, comment, reason, refund } } });

        await onAfterTrigger(trigger);
      } catch (e) {
        if (onTriggerError) {
          onTriggerError(e, trigger);
          return;
        }

        // default error handler
        resolveError(e, {}, showAlert);
      } finally {
        if (isMounted()) setLoading(false);
      }
    },
    [mutate, trigger, documentationRecord, onBeforeTrigger, onAfterTrigger, onTriggerError, isMounted, showAlert]
  );

  const { available, conditions } = useMemo(() => getTrigger(trigger, triggers), [trigger, triggers]);

  return { callTrigger, loading, available, conditions };
};
