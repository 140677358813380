import { useCallback, useMemo } from "react";
import {
  ExternalState,
  InviteState,
  OrganizationInviteSimpleFragment,
  resolveError,
  useOrganizationQuery,
  User
} from "api";
import { useAlert } from "hooks/useAlert";
import moment from "moment";
import { navigate } from "@reach/router";
import { routes, setRouteParams } from "routes";
import { useStorageClass } from "hooks/useStorageClass";

export type OrganizationUser = Pick<User, "id" | "firstName" | "lastName" | "email" | "role"> & {
  membershipId?: string;
  inviteState?: InviteState;
  inviteId?: string;
  externalState?: ExternalState;
};

export const useOrganization = (id: string = "") => {
  const { showAlert } = useAlert();

  /** DATA */
  const { data, loading: loadingOrganization } = useOrganizationQuery({
    variables: { id },
    onError: e => resolveError(e, undefined, showAlert)
  });
  const organization = useMemo(() => data?.organization, [data]);
  const {
    storageName,
    diskUsageStats,
    storageClass,
    loading: loadingStorageClass
  } = useStorageClass(organization?.diskUsageStats);

  const _latestInvitations = useMemo(() => {
    const latestInvitations: Map<string, OrganizationInviteSimpleFragment> = new Map();

    organization?.organizationInvites.edges
      // set the latest user invitation to the Map
      .map(({ node }) => {
        const usersLatestInvitation = latestInvitations.get(node.user.id);
        if (!usersLatestInvitation || moment(node.createdAt).isAfter(usersLatestInvitation.createdAt)) {
          latestInvitations.set(node.user.id, node);
        }
        return node;
      });

    return Array.from(latestInvitations.values());
  }, [organization]);

  const _invitationsToShow = useMemo(
    () =>
      _latestInvitations
        // remove accepted invitations
        .filter(invitation => invitation.state !== InviteState.Accepted)
        // remove canceled invitations
        .filter(invitation => invitation.state !== InviteState.Cancelled),
    [_latestInvitations]
  );

  const _memberships = useMemo<OrganizationUser[]>(
    () =>
      organization?.organizationMemberships.edges.map(({ node }) => {
        return {
          ...node.user,
          membershipId: node.id,
          externalState: node.user.externalState || undefined
        };
      }) || [],
    [organization]
  );
  const _invitedUsersToShow = useMemo<OrganizationUser[]>(
    () =>
      _invitationsToShow
        // remap invitation to the OrganizationUser
        .map(invitation => ({
          ...invitation.user,
          role: invitation.role,
          inviteState: invitation.state,
          inviteId: invitation.id,
          externalState: invitation.user.externalState || undefined
        })),

    [_invitationsToShow]
  );
  const users: OrganizationUser[] = useMemo(
    () => [..._invitedUsersToShow, ..._memberships],
    [_memberships, _invitedUsersToShow]
  );

  const loading = useMemo(() => loadingOrganization || loadingStorageClass, [loadingOrganization, loadingStorageClass]);

  /** HANDLERS */
  const handleClickManageSpace = useCallback(async () => {
    await navigate(setRouteParams(routes.organizationResources, { id }));
  }, [id]);

  return {
    loading,
    data: {
      organization,
      constructionSites: data?.organization?.constructionSites,
      users,
      diskUsageStats,
      storageClass,
      storageName
    },
    handlers: {
      handleClickManageSpace
    }
  };
};
