import { setContext } from "apollo-link-context";
import { getAuthToken } from "api/storage";

const ANONYMOUS_QUERIES = ["config"];

export const authLink = setContext((request, { headers }) => {
  const token = getAuthToken();

  const query = request.operationName;
  const isAnonymousQuery = query && ANONYMOUS_QUERIES.includes(query);

  return {
    headers: {
      ...headers,
      authorization: !isAnonymousQuery && token ? `JWT ${token}` : ""
    }
  };
});
