import { PropsWithChildren, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { Spin } from "antd";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { MenuMain } from "./MenuMain";
import { Subheader } from "./Subheader";
import { ApiPrefetch } from "components/ApiPrefetch";
import clsx from "clsx";
import { useLanguageResolver, useScrollToTop } from "hooks";
import { AccessResolver } from "components/AccessResolver";
import { AppVersion } from "components/AppVersion";
import { useAuth } from "api/auth";
import { routes } from "routes";

export const LayoutDashboard = ({ children, navigate }: PropsWithChildren<RouteComponentProps>) => {
  const { isLoggedIn } = useAuth();
  /*
   * Handle anonymous users
   * */
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate && navigate(routes.login);
    }
  }, [isLoggedIn, navigate]);

  useLanguageResolver();
  const { refScrollToTop } = useScrollToTop();

  return (
    <AccessResolver>
      <ApiPrefetch>
        <div className="flex flex-col flex-grow">
          {/* App Header*/}
          <Header className="print_hidden print-old_hidden" />
          <div className="flex flex-grow overflow-auto print_overflow-visible">
            {/* Sidebar menu*/}
            <div className="flex flex-col w-52 relative overflow-y-auto print_hidden print-old_hidden bg-blue-300">
              <MenuMain />

              <AppVersion className="mb-6" />
            </div>
            <div className="flex flex-col flex-grow bg-blue-100">
              {/* Subheader*/}
              <div className="flex flex-col z-10 print_hidden print-old_hidden">
                <Subheader />
              </div>
              {/* Content */}
              <div
                ref={refScrollToTop}
                className="flex flex-col flex-grow overflow-auto relative print_overflow-visible"
              >
                <div className="flex flex-col absolute top-0 left-0 right-0 min-h-full print_relative">
                  <div className="flex flex-grow router-fix ie-h-full">{children}</div>

                  <Footer className="print_hidden print-old_hidden" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ApiPrefetch>
    </AccessResolver>
  );
};

type ContentProps = PropsWithChildren<{ loading?: boolean; flexGrow?: boolean; className?: string }>;
export const Content = ({ children, loading = false, flexGrow = true, className, ...res }: ContentProps) => (
  <div
    className={clsx(
      className,
      "mt-6 mx-6 p-10 bg-white rounded shadow-decent print_mt-0 print_mx-0",
      flexGrow ? "flex-grow" : null
    )}
    {...res}
  >
    {loading ? (
      <>
        <div className="flex justify-center w-full" data-cy={"page-loading"}>
          <Spin />
        </div>
        <div className="hidden">{children}</div>
      </>
    ) : (
      children
    )}
  </div>
);
