import { onError } from "apollo-link-error";
import { fromPromise } from "apollo-boost";
import { getRefreshToken, setAuthToken, setTokenErrorNotify } from "api/storage";
import { RefreshPayloadDocument, RefreshPayloadMutation, RefreshPayloadMutationVariables } from "api/generated";
import { navigate } from "@reach/router";
import { routes } from "routes";
import { client } from "api";
import { logoutUser } from "api/auth";

const ERROR_EXPIRED_TOKEN = "Signature has expired";
const ERROR_DECODING_SIGNATURE = "Error decoding signature";

/**
 * errorLink is responsible for JWT token refreshing.
 * Whenever a query fails because of an expired token, this link tries to call refreshToken mutation to get a new token.
 * Failed query is then re-tried with the new token.
 * If there are multiple queries that fail during a short time, this link puts the 2nd+ queries into a queue,
 * which is retried when the 1st failed query refreshes the token.
 *
 * source: https://able.bio/AnasT/apollo-graphql-async-access-token-refresh--470t1c8
 *
 * */

let refreshedAuthToken: string; // temporary variable used to allow synchronous access to a new token from pending queries

let pendingRequests: { resolve: (value?: any) => void; reject: () => void }[] = [];
const resolvePendingRequests = () => {
  pendingRequests.map(e => e.resolve());
  pendingRequests = [];
};
const rejectPendingRequests = () => {
  pendingRequests.map(e => e.reject());
  pendingRequests = [];
};
let lastRefreshAttempt: Date | null = null;

let isRefreshing = false;
const startRefreshing = () => (isRefreshing = true);
const finishRefreshing = () => (isRefreshing = false);

export const refreshTokenLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.message !== ERROR_EXPIRED_TOKEN && err.message !== ERROR_DECODING_SIGNATURE) continue;
      // avoid infinite loop
      if (lastRefreshAttempt && lastRefreshAttempt > new Date(Date.now() - 10 * 1000)) continue;

      let forward$;

      if (!isRefreshing) {
        startRefreshing();
        forward$ = fromPromise(
          fetchNewToken()
            // eslint-disable-next-line no-loop-func
            .then(authToken => {
              resolvePendingRequests();
              refreshedAuthToken = authToken; // store the token in a temporary variable, so we can directly access it below
              return authToken;
            })
            // eslint-disable-next-line no-loop-func
            .catch(async () => {
              rejectPendingRequests();
              lastRefreshAttempt = new Date();
              await refreshTokenExpiredLogout();
            })

            .finally(finishRefreshing)
        ).filter(value => Boolean(value));
      } else {
        // Will only emit once the Promise is resolved
        forward$ = fromPromise(
          // eslint-disable-next-line no-loop-func
          new Promise((resolve, reject) => {
            pendingRequests.push({ resolve, reject });
          })
        );
      }

      // eslint-disable-next-line no-loop-func
      return forward$.flatMap(() => {
        if (refreshedAuthToken) {
          operation.setContext(({ headers = {} }: { headers: any }) => {
            return { headers: { ...headers, authorization: `JWT ${refreshedAuthToken}` } };
          });
        }
        return forward(operation);
      });
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

const fetchNewToken = async (): Promise<string> => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) throw new Error("no_refresh_token");

  const result = await client.mutate<RefreshPayloadMutation, RefreshPayloadMutationVariables>({
    mutation: RefreshPayloadDocument,
    variables: {
      input: {
        refreshToken
      }
    }
  });

  const newAuthToken = result.data?.refreshToken?.token || "";
  setAuthToken(newAuthToken);
  return newAuthToken;
};

const refreshTokenExpiredLogout = async () => {
  await client.stop();
  await client.clearStore();

  await setTokenErrorNotify(true);
  await logoutUser();

  await navigate(routes.login, { replace: true });
};
