import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Button, Table } from "antd";
import { Content } from "components/LayoutDashboard";
import { SubheaderOptions, useDocumentationRecords, useFormat, useMe, useSubheader, useTableDataStatus } from "hooks";
import { getConstructionSiteDetailRoute, routes, setRouteParams } from "routes";
import { ArrowRightOutlined, PlusOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import {
  DocumentationRecordOrderByEnum,
  DocumentationRecordStateFilterEnum,
  DocumentationRecordTypeFilterEnum,
  DocumentationRecordVideoType,
  DocumentationRecordVideoTypeFilterEnum,
  Maybe,
  TaskFragment
} from "api";
import { Section } from "components/Section";
import { Link } from "components/Link";
import { StateIndicatorDocumentationRecord } from "components/Indicators/State/StateIndicatorDocumentationRecord";
import { TypeIndicatorDocumentationRecord, TypeIndicatorDocumentationVideo } from "components/Indicators/Type";
import {
  enumToValues,
  hasFilesForDownload,
  isFinished,
  isManager,
  isReader,
  isSurveyor,
  isThreeDOperator,
  TableProps
} from "helpers";
import { Illustration } from "components/Illustration";
import { useColumnSearchProps, usePagination } from "helpers/serverSideTableFilter";
import {
  documentationRecordStateFilters,
  documentationRecordTypeFilters,
  documentationRecordVideoTypesFilter
} from "api/enums";
import { ThreeDModelTableActions } from "components/ThreeDModelTableActions";

type TableItem = Pick<
  TaskFragment,
  "id" | "name" | "createdAt" | "state" | "type" | "threeDModel" | "constructionObject"
> & {
  videoType?: Maybe<DocumentationRecordVideoType>;
  organizationName: string;
  constructionSiteName: string;
};

type TablePropType = TableProps<TableItem>;

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  name: {
    ascend: [DocumentationRecordOrderByEnum.Name],
    descend: [DocumentationRecordOrderByEnum.NameDesc]
  },
  constructionSiteName: {
    ascend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteName],
    descend: [DocumentationRecordOrderByEnum.ConstructionObjectConstructionSiteNameDesc]
  },
  createdAt: {
    ascend: [DocumentationRecordOrderByEnum.CreatedAt],
    descend: [DocumentationRecordOrderByEnum.CreatedAtDesc]
  }
};

export const DocumentationRecords: FunctionComponent<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { formatDate } = useFormat();
  const { me, loading: loadingMe } = useMe();
  const [nameFilter, setNameFilter] = useState("");
  const nameSearchProps = useColumnSearchProps();
  const [constructionSiteNameFilter, setConstructionSiteNameFilter] = useState("");
  const constructionSiteNameSearchProps = useColumnSearchProps();
  const [typesFilter, setTypesFilter] = useState<DocumentationRecordTypeFilterEnum[]>([]);
  const [videoTypesFilter, setVideoTypesFilter] = useState<DocumentationRecordVideoTypeFilterEnum[]>([]);
  const [statesFilter, setStatesFilter] = useState<DocumentationRecordStateFilterEnum[]>([]);
  const [orderBy, setOrderBy] = useState<DocumentationRecordOrderByEnum[]>(orderByMap.createdAt.descend);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const { loading: loadingDocumentationRecords, data } = useDocumentationRecords({
    skip: skip,
    orderBy: orderBy,
    name: nameFilter,
    constructionSiteName: constructionSiteNameFilter,
    types: typesFilter,
    videoTypes: videoTypesFilter,
    states: statesFilter
  });

  const loading = useMemo(() => loadingMe || loadingDocumentationRecords, [loadingDocumentationRecords, loadingMe]);

  useEffect(() => setTotal(data?.totalCount), [data]);

  const canCreateDocumentationBySimplifiedWay = isManager(me);

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: t(lg.documentationRecords.header.title),
      breadcrumb: [{ value: t(lg.documentationRecords.header.title) }],
      buttons: canCreateDocumentationBySimplifiedWay
        ? [
            <Button
              key="create-new-documentation-record"
              type={"primary"}
              size={"large"}
              onClick={() => navigate(routes.documentationRecordCreateSimplified)}
            >
              <PlusOutlined />
              {t(lg.documentationRecords.header.buttonPrimaryText)}
            </Button>
          ]
        : undefined
    }),
    [t, canCreateDocumentationBySimplifiedWay]
  );
  useSubheader(subheaderOptions);

  const canShowDetailLink = useMemo(() => isManager(me) || isReader(me), [me]);
  const canShowThreeDModelColumn = useMemo(() => !isSurveyor(me), [me]);

  const tableData = useMemo<TableItem[]>(
    () =>
      data.documentationRecords?.edges.map(({ node: item }) => ({
        ...item,
        organizationName: item.constructionObject.constructionSite.organization.name,
        videoType: item.documentationRecordVideo?.type || null,
        constructionSiteName: item.constructionObject?.constructionSite.name
      })) || [],
    [data.documentationRecords]
  );

  const columns: TablePropType["columns"] = useMemo(
    () => [
      {
        key: "name",
        dataIndex: "name",
        title: t(lg.documentationRecords.allDocumentation.table.header.documentationRecord),
        render: (name, { id }) =>
          canShowDetailLink ? (
            <Link href={setRouteParams(routes.documentationRecordDetail, { id })}>{name}</Link>
          ) : (
            name
          ),
        ...nameSearchProps,
        ellipsis: true,
        sorter: true
      },
      {
        key: "createdAt",
        dataIndex: "createdAt",
        title: t(lg.documentationRecords.allDocumentation.table.header.created),
        render: createdAt => <span className="text-secondary">{formatDate(createdAt, "machine", true)}</span>,
        ellipsis: true,
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        dataIndex: "constructionSiteName",
        key: "constructionSiteName",
        title: t(lg.documentationRecords.allDocumentation.table.header.constructionSite),
        render: (constructionSiteName, { constructionObject }) =>
          canShowDetailLink ? (
            <Link href={getConstructionSiteDetailRoute(constructionObject.constructionSite.id)}>
              {constructionSiteName}
            </Link>
          ) : (
            constructionSiteName
          ),
        ...constructionSiteNameSearchProps,
        ellipsis: true,
        sorter: true
      },
      {
        key: "state",
        dataIndex: "state",
        title: t(lg.documentationRecords.allDocumentation.table.header.state),
        render: state => <StateIndicatorDocumentationRecord state={state} />,
        filters: enumToValues<DocumentationRecordStateFilterEnum>(DocumentationRecordStateFilterEnum)
          .filter(s => s !== DocumentationRecordStateFilterEnum.Archived)
          .map(type => ({
            text: t(documentationRecordStateFilters[type].nameI18nKey),
            value: type
          })),
        ellipsis: true
      },
      {
        key: "type",
        dataIndex: "type",
        title: t(lg.documentationRecords.allDocumentation.table.header.documentationRecordType),
        render: type => <TypeIndicatorDocumentationRecord type={type} />,
        filters: enumToValues<DocumentationRecordTypeFilterEnum>(DocumentationRecordTypeFilterEnum).map(type => ({
          text: t(documentationRecordTypeFilters[type].nameI18nKey),
          value: type
        }))
      },
      {
        key: "videoType",
        dataIndex: "videoType",
        title: t(lg.documentationRecords.allDocumentation.table.header.videoType),
        render: type => (type !== undefined ? <TypeIndicatorDocumentationVideo type={type} /> : "-"),
        filters: enumToValues<DocumentationRecordVideoTypeFilterEnum>(DocumentationRecordVideoTypeFilterEnum).map(
          type => ({
            text: t(documentationRecordVideoTypesFilter[type].nameI18nKey),
            value: type
          })
        )
      },
      ...(canShowThreeDModelColumn
        ? ([
            {
              key: "threeDModel",
              dataIndex: "threeDModel",
              title: t(lg.documentationRecords.allDocumentation.table.header.threeDModel),
              render: (threeDModel, record) => {
                if (!threeDModel || !threeDModel?.id || !threeDModel?.modelFile?.url) return "-";
                return (
                  <ThreeDModelTableActions
                    variant={"icon"}
                    modelId={threeDModel.id}
                    isShared={threeDModel.isShared}
                    hasFilesForDownload={hasFilesForDownload(threeDModel)}
                    disabled={!isFinished(record.state)}
                  />
                );
              }
            }
          ] as TablePropType["columns"])
        : [])
    ],
    [canShowDetailLink, constructionSiteNameSearchProps, formatDate, canShowThreeDModelColumn, nameSearchProps, t]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setNameFilter(filter.name ? filter.name[0] : "");
      setTypesFilter(filter.type || []);
      setVideoTypesFilter(filter.videoType || []);
      setStatesFilter(filter.state || []);
      setConstructionSiteNameFilter(filter.constructionSiteName ? filter.constructionSiteName[0] : "");
    },
    [setPage]
  );

  const handleClickConstructionSites = useCallback(() => navigate(routes.constructionSites), []);

  const { hasData, hasFilters } = useTableDataStatus(tableData.length, [
    nameFilter,
    typesFilter,
    videoTypesFilter,
    statesFilter,
    constructionSiteNameFilter
  ]);

  return (
    <Content loading={loading && !hasFilters && !hasData}>
      {!hasFilters && !hasData ? (
        <div className="flex flex-col items-center min-h-full">
          {isThreeDOperator(me) ? (
            <p className="text-center whitespace-pre-line text-base text-blue-700">
              {t(lg.documentationRecords.noDataThreeDOperator)}
            </p>
          ) : (
            <p className="text-center whitespace-pre-line text-base text-blue-700">
              {isReader(me) ? t(lg.documentationRecords.noData.readOnly) : t(lg.documentationRecords.noData.default)}
            </p>
          )}

          {isManager(me) && (
            <>
              <p className="text-center whitespace-pre-line text-base text-blue-700 mt-6">
                {t(lg.documentationRecords.noData.extendedInstruction)}
              </p>
              <Button ghost type="primary" onClick={handleClickConstructionSites} className="mt-12">
                {t(lg.documentationRecords.noData.buttonToConstructionSitesText)} <ArrowRightOutlined />
              </Button>
            </>
          )}

          <div className="flex-grow flex flex-col w-full p-5 md:p-20">
            <Illustration type="ill-03" className="flex-grow h-0" />
          </div>
        </div>
      ) : (
        <Section title={t(lg.documentationRecords.allDocumentation.title)}>
          <div className="mt-5">
            <Table<(typeof tableData)[0]>
              dataSource={tableData}
              columns={columns}
              rowKey={"id"}
              loading={{ delay: hasData ? 300 : 0, spinning: loadingDocumentationRecords }}
              onChange={handleOnChange}
              showSorterTooltip={false}
              pagination={pagination}
              scroll={{ x: true }}
            />
          </div>
        </Section>
      )}
    </Content>
  );
};
