import { useCallback, useEffect, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import { Content } from "components/LayoutDashboard";
import { Section } from "components/Section";
import { SubheaderOptions, useAlert, useFormat, useMe, useSubheader } from "hooks";
import { InquiryStatus, MediaState, resolveError, useInquiryForDetailPageQuery } from "api";
import { decodeRelayId, getInquiryName, getLocaleInquiryTopic, isManager, isReader } from "helpers";
import { routes } from "routes";
import { StateIndicatorInquiry } from "components/Indicators/State/StateIndicatorInquiry";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { MediaCard } from "components/MediaCard";
import { ConstructionBreadcrumb } from "components/ConstructionBreadcrumb";

export const Inquiry = ({ id, navigate }: RouteComponentProps<{ id: string }>) => {
  if (!id) throw new Error("Missing inquiry ID.");

  const { t, i18n } = useTranslation();
  const { showAlert } = useAlert();
  const { formatDate } = useFormat();

  const { me, loading: loadingMe } = useMe();
  const isAuthorizedToView = useMemo<boolean>(() => isManager(me) || isReader(me), [me]);

  /* redirect a user to the default route if the user is not authorized to render this page */
  useEffect(() => {
    if (!me.role || isAuthorizedToView) return;
    navigate && navigate(routes.todo);
  }, [isAuthorizedToView, me, navigate]);

  const { data, loading: loadingInquiry } = useInquiryForDetailPageQuery({
    variables: { id },
    fetchPolicy: "cache-and-network",
    skip: !isAuthorizedToView || !id,
    onError: e => resolveError(e, undefined, showAlert)
  });

  const decodedId = useMemo(() => decodeRelayId(id)?.id || "", [id]);
  const inquiry = useMemo(() => data?.inquiry, [data]);
  const requests = useMemo(() => inquiry?.requests.edges.flatMap(({ node }) => (node ? [node] : [])) || [], [inquiry]);
  const inquiryName = useMemo(() => getInquiryName(inquiry, i18n.language), [i18n.language, inquiry]);
  const attachments = useMemo(
    () => inquiry?.files.edges.flatMap(({ node }) => (node && node.state === MediaState.Uploaded ? [node] : [])) || [],
    [inquiry]
  );

  const isInquiryInProgress = useMemo(() => inquiry?.status === InquiryStatus.Created, [inquiry]);
  const isInquiryCompleted = useMemo(
    () => inquiry?.status === InquiryStatus.Completed || inquiry?.status === InquiryStatus.Rejected,
    [inquiry]
  );
  const loading = useMemo(() => loadingMe || loadingInquiry, [loadingMe, loadingInquiry]);

  const subheaderOptions = useMemo<SubheaderOptions>(
    () => ({
      heading: `${t(lg.additionalServices.header.title)}: ${inquiryName}`,
      breadcrumb: [
        { value: t(t(lg.additionalServices.header.title)), route: routes.additionalServices },
        {
          value: inquiryName
        }
      ]
    }),
    [t, inquiryName]
  );
  useSubheader(subheaderOptions);

  const handleClickBack = useCallback(() => navigate && navigate(routes.additionalServices), [navigate]);

  return (
    <Content loading={loading}>
      {/*
       Actions
       */}
      <section className="flex flex-wrap">
        <Button size="large" icon={<ArrowLeftOutlined />} onClick={handleClickBack}>
          {t(lg.inquiry.actions.backButton)}
        </Button>
      </section>

      {/*
      Progress
      */}
      <Section title={t(lg.inquiry.progress.title)} className="mt-10">
        {isInquiryInProgress && (
          <p className="text-center whitespace-pre-line text-base text-blue-700 p-5 md:p-10">
            {t(lg.inquiry.progress.body.inProgress)}
          </p>
        )}

        {isInquiryCompleted && (
          <div className="c-grid mt-5 md:mt-10">
            {/* left */}
            <div className="c-grid-column">
              <div className="font-medium">{t(lg.inquiry.progress.body.resolverComment.label)}</div>
              <div className="text-secondary whitespace-pre-line">{inquiry?.resolverComment || "-"}</div>
            </div>

            {/* right */}
            <div className="c-grid-column">
              {attachments.length > 0 && (
                <>
                  <div className="font-medium">{t(lg.inquiry.progress.body.attachments.label)}</div>
                  {attachments.map((file, index) => {
                    return (
                      <MediaCard
                        key={file.id}
                        fileUrl={file.url || undefined}
                        fileName={file.fileName || "-"}
                        className="mt-5"
                      />
                    );
                  })}
                </>
              )}
            </div>
          </div>
        )}
      </Section>

      {/*
      Details
      */}
      <Section title={t(lg.inquiry.inquiryDetails.title)} className="mt-10">
        <div className="c-grid mt-5 md:mt-10">
          {/* left */}
          <div className="c-grid-column">
            <div>
              <div className="font-medium">{t(lg.inquiry.inquiryDetails.body.inquiryNumber.label)}</div>
              <div className="text-secondary whitespace-pre-line">{decodedId}</div>
            </div>

            {isInquiryCompleted && inquiry?.resolvedBy && (
              <div className="mt-5">
                <div className="font-medium">{t(lg.inquiry.inquiryDetails.body.resolverName.label)}</div>
                <div className="text-secondary whitespace-pre-line">{`${inquiry.resolvedBy.firstName} ${inquiry.resolvedBy.lastName}`}</div>
              </div>
            )}

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiry.inquiryDetails.body.createdAt.label)}</div>
              <div className="text-secondary whitespace-pre-line">
                {inquiry?.createdAt ? formatDate(inquiry.createdAt, "machine", true) : "-"}
              </div>
            </div>

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiry.inquiryDetails.body.userComment.label)}</div>
              <div className="text-secondary whitespace-pre-line">{inquiry?.userComment || "-"}</div>
            </div>
          </div>

          {/* right */}
          <div className="c-grid-column">
            <div className="font-medium">{t(lg.inquiry.inquiryDetails.body.status.label)}</div>
            <div className="text-secondary">{inquiry ? <StateIndicatorInquiry state={inquiry.status} /> : "-"}</div>

            {isInquiryCompleted && inquiry && (
              <div className="mt-5">
                <div className="font-medium">{t(lg.inquiry.inquiryDetails.body.resolvedAt.label)}</div>
                <div className="text-secondary">
                  {inquiry.resolvedAt ? formatDate(inquiry.resolvedAt, "machine", true) : "-"}
                </div>
              </div>
            )}

            <div className="mt-5">
              <div className="font-medium">{t(lg.inquiry.inquiryDetails.body.requestType.label)}</div>
              {requests.length > 0
                ? requests.map((request, index) => {
                    const documentationRecords =
                      request.documentationRecords.edges.flatMap(({ node }) => (node ? [node] : [])) || [];
                    const constructionSites =
                      request.constructionSites.edges.flatMap(({ node }) => (node ? [node] : [])) || [];
                    const constructionObjects =
                      request.constructionObjects.edges.flatMap(({ node }) => (node ? [node] : [])) || [];

                    const topic = getLocaleInquiryTopic(request.inquiryTopic, i18n.language).topic;

                    return (
                      <div key={request.id} className={index === 0 ? "" : "mt-5"}>
                        <div className="text-secondary">{topic}</div>

                        {documentationRecords.map(record => (
                          <ConstructionBreadcrumb
                            key={record.id}
                            constructionSite={record.constructionObject.constructionSite}
                            constructionObject={record.constructionObject}
                            documentationRecord={record}
                          />
                        ))}

                        {constructionSites.map(site => (
                          <ConstructionBreadcrumb key={site.id} constructionSite={site} />
                        ))}

                        {constructionObjects.map(object => (
                          <ConstructionBreadcrumb
                            key={object.id}
                            constructionSite={object.constructionSite}
                            constructionObject={object}
                          />
                        ))}
                      </div>
                    );
                  })
                : "-"}
            </div>
          </div>
        </div>
      </Section>
    </Content>
  );
};
