import { PropsWithChildren, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { ApiPrefetch } from "components/ApiPrefetch";
import { useLanguageResolver, useServiceWorker } from "hooks";
import { AccessResolver } from "components/AccessResolver";
import { lg } from "assets/translations";
import { useTranslation } from "react-i18next";

export const LayoutThreeDViewer = ({ children }: PropsWithChildren<RouteComponentProps>) => {
  useLanguageResolver();

  const [t] = useTranslation();
  const { isUpdateAvailable } = useServiceWorker();

  // add update info to the document title
  useEffect(() => {
    if (!isUpdateAvailable) return;

    document.title = `${t(lg.version.update.description)}! ${document.title}`;
  }, [isUpdateAvailable, t]);

  return (
    <AccessResolver>
      <ApiPrefetch>{children}</ApiPrefetch>
    </AccessResolver>
  );
};
