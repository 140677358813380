import {
  cloneElement,
  ComponentPropsWithoutRef,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState
} from "react";
import { Input, Modal, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { useAlert, useConfig } from "hooks";
import { resolveError, useConstructionPhaseCreateMutation } from "api";
import { lg } from "assets/translations/lg";

type RadioGroupProps = ComponentPropsWithoutRef<typeof Radio.Group>;
type InputProps = ComponentPropsWithoutRef<typeof Input>;

type Props = {
  constructionSiteId: string;
  children: ReactElement;
  onSuccess?: (name: string) => any;
  onError?: (name: string) => any;
};

export const PhaseCreate = ({
  constructionSiteId,
  children,
  onSuccess = () => {},
  onError = () => {}
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();

  const { config } = useConfig();
  const [create, { loading: loadingPhaseCreateMutation }] = useConstructionPhaseCreateMutation();

  const [visible, setVisible] = useState(false);
  const [name, setName] = useState<string>("");
  const [customName, setCustomName] = useState<string>("");

  const sending = useMemo<boolean>(() => loadingPhaseCreateMutation, [loadingPhaseCreateMutation]);
  const disabled = useMemo<boolean>(() => sending, [sending]);
  const open = useCallback(() => {
    setName("");
    setCustomName("");
    setVisible(true);
  }, []);

  const handleRadioChangePhase: Required<RadioGroupProps>["onChange"] = useCallback(({ target: { value } }) => {
    setName(value);
  }, []);

  const handleChangeInput: Required<InputProps>["onChange"] = useCallback(({ target: { value } }) => {
    setName(value);
    setCustomName(value);
  }, []);

  const handleOk = useCallback(async () => {
    if (!name) return;

    try {
      await create({ variables: { input: { constructionSite: constructionSiteId, name } } });
      onSuccess(name);
    } catch (e) {
      resolveError(e, {}, showAlert);
      onError(name);
    }

    setVisible(false);
  }, [name, constructionSiteId, create, onSuccess, onError, showAlert]);

  const handleCancel = useCallback(() => setVisible(false), []);

  return (
    <>
      {cloneElement(children, { onClick: open })}

      <Modal
        title={
          <div className="flex items-center">
            <DeleteOutlined className="text-lg text-red-600" />
            <span className="ml-2">{t(lg.constructionSite.pageDetail.phases.modalUpdate.titleCreate)}</span>
          </div>
        }
        open={visible}
        okText={t(lg.constructionSite.pageDetail.phases.modalUpdate.buttonOkText)}
        cancelText={t(lg.constructionSite.pageDetail.phases.modalUpdate.buttonCancelText)}
        okButtonProps={{ type: "primary", icon: <SaveOutlined />, disabled: !name || disabled }}
        cancelButtonProps={{ disabled }}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        confirmLoading={sending}
      >
        <div className="mb-2">{t(lg.constructionSite.pageDetail.phases.modalUpdate.form.phaseNameLabel)}</div>
        <Radio.Group onChange={handleRadioChangePhase} value={name} disabled={disabled}>
          {config.defaultConstructionPhases.map((phaseName, index) => (
            <Radio className="flex items-center h-8" value={phaseName} key={`${phaseName}${index}`}>
              <span className="text-secondary leading-none">{phaseName}</span>
            </Radio>
          ))}

          <Radio className="flex items-center h-8" value={customName}>
            <Input
              style={{ width: 260 }}
              placeholder={t(lg.constructionSite.pageDetail.phases.modalUpdate.form.phaseNameCustomInputPlaceholder)}
              value={customName}
              onChange={handleChangeInput}
            />
          </Radio>
        </Radio.Group>
      </Modal>
    </>
  );
};
