import { Catalog } from "hooks";
import { DocumentationRecordState, DocumentationRecordVideoType, Role, TriggerFragment, Triggers } from "api";
import { routes, setRouteParams } from "routes";

export const getTrigger = (trigger: Triggers, triggers: TriggerFragment[]): TriggerFragment => {
  return (
    triggers.filter(({ trigger: _trigger }) => _trigger === trigger)[0] || {
      trigger,
      available: false,
      conditions: false
    }
  );
};

/**
 * Task route resolver for Tasks page tables.
 * TaskOperator page is accessible via DocumentationRecordDetail page only.
 * @param id - DocumentationRecord ID
 * @param role - User role (not accountable role)
 * @param state - DocumentationRecord State
 */
export const getTaskUrl = (id: string, role: Role, state: DocumentationRecordState): string => {
  if (state === DocumentationRecordState.RequiresSurveyor) {
    return setRouteParams(routes.taskSurveyor, { id });
  }

  if (role === Role.ThreeDOperator && state === DocumentationRecordState.RequiresThreeDOperator) {
    return setRouteParams(routes.taskThreeDOperator, { id });
  }

  if (role === Role.Manager) {
    return setRouteParams(routes.documentationRecordDetail, { id });
  }

  return "";
};

export const getCost = (type: DocumentationRecordVideoType | null, catalog: Catalog): number => {
  switch (type) {
    case null:
      return 0;
    case DocumentationRecordVideoType.NoScale:
      return catalog.requestThreeDModelNoScale;
    case DocumentationRecordVideoType.Scale:
      return catalog.requestThreeDModelScale;
    case DocumentationRecordVideoType.Maps:
      return catalog.requestThreeDModelMaps;
    case DocumentationRecordVideoType.Spray:
      return catalog.requestThreeDModelSpray;
    case DocumentationRecordVideoType.QrCodes:
      return catalog.requestThreeDModelQrCodes;
  }
};

export const getHasEnoughCredits = (credits: number, price: number): boolean => credits >= price;

export const isFinished = (state: DocumentationRecordState) => state === DocumentationRecordState.Finished;
