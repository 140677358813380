import { PropsWithChildren, useMemo } from "react";
import { lg } from "assets/translations";
import { TypeCard } from "components/DocumentationRecord/TypeCard";
import { DocumentationRecordType } from "api";
import { Section } from "components/Section";
import { useTranslation } from "react-i18next";
import { CustomIconType } from "components/icons/CustomIcon";

type Props = {
  type: DocumentationRecordType;
  disabled?: boolean;
  onSelect?: (type: DocumentationRecordType) => any;
};

export const TypeSelect = ({ type, disabled = false, onSelect = () => {} }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const options = useMemo<DocumentationRecordType[]>(
    () => [
      DocumentationRecordType.NoThreeDNoVideo,
      DocumentationRecordType.NoThreeDVideo,
      DocumentationRecordType.ThreeDNoScale,
      DocumentationRecordType.ThreeDScale
    ],
    []
  );

  return (
    <Section title={t(lg.typenames.documentationType)}>
      <div className="flex flex-wrap mt-5 -mx-3 -my-5">
        {options.map(option => (
          <div key={option} className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-3 py-5">
            <TypeCard
              title={t(getTitle(option))}
              icon={getIcon(option, type === option)}
              selected={type === option}
              disabled={type === option || disabled}
              onButtonClick={() => onSelect(option)}
              className="h-full"
            >
              <p className="whitespace-pre-line">{t(getDescription(option))}</p>
            </TypeCard>
          </div>
        ))}
      </div>
    </Section>
  );
};

const getTitle = (type: DocumentationRecordType): string => {
  switch (type) {
    case DocumentationRecordType.NoThreeDNoVideo:
      return lg.documentationRecord.formSelectType.cardNoTheeDModel.title;
    case DocumentationRecordType.NoThreeDVideo:
      return lg.documentationRecord.formSelectType.cardNoTheeDModelYet.title;
    case DocumentationRecordType.ThreeDNoScale:
      return lg.documentationRecord.formSelectType.cardNoGeoreferencedModel.title;
    case DocumentationRecordType.ThreeDScale:
      return lg.documentationRecord.formSelectType.cardGeoreferencedModel.title;
  }
};

const getIcon = (type: DocumentationRecordType, selected: boolean): CustomIconType => {
  switch (type) {
    case DocumentationRecordType.NoThreeDNoVideo:
      return selected ? "read-nothing-white" : "read-nothing";
    case DocumentationRecordType.NoThreeDVideo:
      return selected ? "read-question-mark-white" : "read-question-mark";
    case DocumentationRecordType.ThreeDNoScale:
      return selected ? "read-no-geo-white" : "read-no-geo";
    case DocumentationRecordType.ThreeDScale:
      return selected ? "read-geo-white" : "read-geo";
  }
};

const getDescription = (type: DocumentationRecordType): string => {
  switch (type) {
    case DocumentationRecordType.NoThreeDNoVideo:
      return lg.documentationRecord.formSelectType.cardNoTheeDModel.description;
    case DocumentationRecordType.NoThreeDVideo:
      return lg.documentationRecord.formSelectType.cardNoTheeDModelYet.description;
    case DocumentationRecordType.ThreeDNoScale:
      return lg.documentationRecord.formSelectType.cardNoGeoreferencedModel.description;
    case DocumentationRecordType.ThreeDScale:
      return lg.documentationRecord.formSelectType.cardGeoreferencedModel.description;
  }
};
