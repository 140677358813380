import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { LoadingOutlined, StopOutlined } from "@ant-design/icons";
import { ActionProps } from "components/DocumentationRecord/Actions/index";
import { useTrigger } from "components/DocumentationRecord/Actions/useTrigger";
import { ModalReject, RejectHandler } from "components/DocumentationRecord/Actions/modals/ModalReject";
import { Triggers } from "api";
import { lg } from "assets/translations";
import { documentationRecordTriggers } from "api/enums";

/** For Trigger REJECT */
export const Reject = ({
  documentationRecordId,
  triggers,
  disabled = false,
  cost,
  onBeforeTrigger = () => {},
  onAfterTrigger = () => {}
}: ActionProps & { cost: number }) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const { callTrigger, loading, available, conditions } = useTrigger(
    Triggers.Reject,
    triggers,
    documentationRecordId,
    onBeforeTrigger,
    onAfterTrigger
  );

  const handleClick = useCallback(() => setModalVisible(true), []);
  const handleCancel = useCallback(() => setModalVisible(false), []);
  const handleSubmit = useCallback<RejectHandler>(
    (comment, reason, refund) => callTrigger(comment, reason, refund),
    [callTrigger]
  );

  if (!available) return null;

  return (
    <>
      <Button
        type="default"
        onClick={handleClick}
        size="large"
        icon={!loading ? <StopOutlined /> : <LoadingOutlined />}
        disabled={disabled || !conditions || loading}
      >
        {t(!loading ? documentationRecordTriggers[Triggers.Reject].buttonI18nKey : lg.saving)}
      </Button>
      <ModalReject
        visible={modalVisible}
        loading={loading}
        cost={cost}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    </>
  );
};
