import { PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { lg } from "assets/translations/lg";
import { useConfig } from "hooks";

type Props = {
  visible: boolean;
  onOk: () => void;
};

export const AlertLeave = ({ visible, onOk }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { config, loading } = useConfig();

  return (
    <Modal
      title={
        <div className="flex items-center">
          <ExclamationCircleOutlined className="text-lg text-red-600" />
          <span className="ml-4">{t(lg.organization.modals.leaveAlert.title)}</span>
        </div>
      }
      open={visible}
      okText={t(lg.organization.modals.leaveAlert.buttonOkText)}
      okButtonProps={{ type: "primary", style: { width: 100 }, disabled: loading }}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={onOk}
      closable={false}
      confirmLoading={loading}
    >
      <Trans i18nKey={lg.organization.modals.leaveAlert.description}>
        {/* Trans component add content to the a element */}
        {/* eslint-disable jsx-a11y/anchor-has-content */}
        <a href={config.helpDeskUrl} target="_blank" rel="noopener noreferrer" className="c-link" />
        {/* eslint-enable jsx-a11y/anchor-has-content */}
      </Trans>
    </Modal>
  );
};
