import { PropsWithChildren } from "react";
import { Avatar } from "antd";
import clsx from "clsx";
import { useFormat } from "hooks";

type Props = {
  author: string;
  datetime: string;
  content: string;
  className?: string;
};

export const Comment = ({ author, datetime, content, className }: PropsWithChildren<Props>) => {
  const { getFromNow } = useFormat();
  return (
    <div className={clsx("flex", className)}>
      <Avatar size="small" className="text-purple-900 bg-purple-600 print_hidden">
        {author.split("")[0]}
      </Avatar>
      <div className="ml-2 print_ml-0">
        <div>
          <span className="font-medium">{author}</span>
          <span className="ml-1 text-gray-700">{getFromNow(datetime)}</span>
        </div>
        <div className="text-base text-secondary whitespace-pre-line">{content}</div>
      </div>
    </div>
  );
};
