import { onError } from "apollo-link-error";
import { navigate } from "@reach/router";
import { routes, setRouteParams } from "routes";
import { AccessErrors } from "api/enums";
import { client } from "api/index";
import { logoutUser } from "api/auth";

const ERROR_USER_IS_BLOCKED = "User is blocked"; // current full message is "User is blocked by mawis.eu"

export const blockedUserLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let error of graphQLErrors) {
      if (!error.message.startsWith(ERROR_USER_IS_BLOCKED)) continue;

      (async function resolveError() {
        logoutUser();
        await client.clearStore();

        await navigate(setRouteParams(routes.error, { error: AccessErrors.AccountBlocked }), { replace: true });
      })();
    }
  }
});
