import { PropsWithChildren } from "react";
import { ChildComponentProps } from "google-map-react";

type Props = ChildComponentProps & { value: string | number; secondary?: boolean };

export const CustomMapDot = ({ value, secondary }: PropsWithChildren<Props>) => (
  <div
    className={`relative flex items-center justify-center rounded-full text-white cursor-pointer ${
      !secondary ? "bg-purple-600" : "bg-blue-600"
    }`}
    style={{ width: 14, height: 14, top: -7, left: -7 }}
  >
    <span style={{ fontSize: 11, lineHeight: "14px" }}>{value}</span>
  </div>
);
