import { PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations/lg";
import {
  ConstructionSiteOrderByEnum,
  ConstructionSitesQuery,
  ConstructionSitesQueryVariables,
  ConstructionSiteStateFilterEnum,
  resolveError,
  useConstructionSitesQuery
} from "api";
import { Link } from "components/Link";
import { routes, setRouteParams } from "routes";
import { StateIndicatorConstructionSite } from "components/Indicators/State/StateIndicatorConstructionSite";
import { enumToValues, TableProps } from "helpers";
import { Table } from "antd";
import { useColumnSearchProps, usePagination } from "helpers/serverSideTableFilter";
import { constructionSiteStateFilters } from "api/enums";
import { useAlert, useFormat } from "hooks";

type Props = {
  organizationId: string;
  className?: string;
  noDataContent?: ReactNode | any;
};

type TableItem = NonNullable<ConstructionSitesQuery["organization"]>["constructionSites"]["edges"][0]["node"] & {
  objectsCount: number;
  phasesCount: number;
  accountableManagerFullName?: string;
};

type TablePropType = TableProps<TableItem>;

// TODO highlight added/edited row (example design UC0007 BF 7A 01) - https://hrdlicka.atlassian.net/browse/HRD003-459

const orderByMap: { [key: string]: { [key: string]: any[] } } = {
  name: {
    ascend: [ConstructionSiteOrderByEnum.Name],
    descend: [ConstructionSiteOrderByEnum.NameDesc]
  },
  accountableManagerFullName: {
    ascend: [
      ConstructionSiteOrderByEnum.AccountableManagerFirstName,
      ConstructionSiteOrderByEnum.AccountableManagerLastName
    ],
    descend: [
      ConstructionSiteOrderByEnum.AccountableManagerFirstNameDesc,
      ConstructionSiteOrderByEnum.AccountableManagerLastNameDesc
    ]
  },
  createdAt: {
    ascend: [ConstructionSiteOrderByEnum.CreatedAt],
    descend: [ConstructionSiteOrderByEnum.CreatedAtDesc]
  }
};
export const ConstructionSitesTable = ({ organizationId, className, noDataContent }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const [orderBy, setOrderBy] = useState<ConstructionSitesQueryVariables["orderBy"]>(orderByMap.createdAt.descend);
  const [nameFilter, setNameFilter] = useState("");
  const [accountableManagerNameFilter, setAccountableManagerNameFilter] = useState("");
  const [statesFilter, setStatesFilter] = useState<ConstructionSitesQueryVariables["states"]>([]);
  const [total, setTotal] = useState<number | undefined | null>();
  const { pagination, setPage, skip } = usePagination({ total: total });
  const { formatDate } = useFormat();

  const { data, loading } = useConstructionSitesQuery({
    variables: {
      organizationId,
      skip: skip,
      orderBy,
      name: nameFilter,
      accountableManagerName: accountableManagerNameFilter,
      states: statesFilter
    },
    fetchPolicy: "cache-and-network",
    skip: !organizationId,
    onError: e => resolveError(e, undefined, showAlert)
  });

  useEffect(() => {
    setTotal(data?.organization?.constructionSites.totalCount);
  }, [data]);

  const constructionSites = useMemo(
    () => data?.organization?.constructionSites.edges.map(({ node }) => node) || [],
    [data]
  );

  const tableData = useMemo<TableItem[]>(
    () =>
      constructionSites.map(item => ({
        ...item,
        objectsCount: item.constructionObjects.totalCount || 0,
        phasesCount: item.constructionPhases.totalCount || 0,
        accountableManagerFullName: item.accountableManager
          ? `${item.accountableManager?.firstName} ${item.accountableManager?.lastName}`
          : undefined
      })),
    [constructionSites]
  );

  const nameSearchProps = useColumnSearchProps();
  const accountableManagerNameSearchProps = useColumnSearchProps();

  const columns: TablePropType["columns"] = useMemo(
    () => [
      {
        dataIndex: "name",
        key: "name",
        title: t(lg.constructionSites.table.header.name),
        render: (name: string, { id }) => (
          <Link href={setRouteParams(routes.constructionSiteDetail, { id })}>{name}</Link>
        ),
        sorter: true,
        ...nameSearchProps,
        ellipsis: true
      },
      {
        title: t(lg.constructionSites.table.header.createdAt),
        dataIndex: "createdAt",
        key: "createdAt",
        render: createdAt => (
          <span className="text-secondary whitespace-nowrap">
            {createdAt ? formatDate(createdAt, "machine", true) : "-"}
          </span>
        ),
        className: "whitespace-nowrap",
        sorter: true,
        defaultSortOrder: "descend",
        sortDirections: ["ascend", "descend", "ascend"] // don't allow turn off sorting
      },
      {
        dataIndex: "state",
        key: "state",
        title: t(lg.constructionSites.table.header.state),
        render: state => <StateIndicatorConstructionSite state={state} />,
        filters: enumToValues<ConstructionSiteStateFilterEnum>(ConstructionSiteStateFilterEnum).map(state => ({
          text: t(constructionSiteStateFilters[state].nameI18nKey),
          value: state
        }))
      },
      {
        dataIndex: "accountableManagerFullName",
        key: "accountableManagerFullName",
        title: t(lg.constructionSites.table.header.accountableManager),
        render: fullName => <span className="text-secondary">{fullName || "-"}</span>,
        sorter: true,
        ...accountableManagerNameSearchProps,
        ellipsis: true
      },
      {
        dataIndex: "objectsCount",
        key: "objectsCount",
        title: t(lg.constructionSites.table.header.objectCount),
        render: count => <span className="text-secondary">{count}</span>
      },
      {
        dataIndex: "phasesCount",
        key: "phasesCount",
        title: t(lg.constructionSites.table.header.phaseCount),
        render: count => <span className="text-secondary">{count}</span>
      },
      {
        dataIndex: "createdRecords",
        key: "createdRecords",
        title: t(lg.constructionSites.table.header.documentationRecord),
        className: "text-secondary space-x-1",
        render: (createdRecords, site) => (
          <>
            <span>
              {t(lg.constructionSites.table.columns.documentationRecord.created)} {createdRecords}
            </span>
            <span className={"text-gray-600"}>|</span>
            <span>
              {t(lg.constructionSites.table.columns.documentationRecord.inProgress)} {site.workInProgressRecords}
            </span>
            <span className={"text-gray-600"}>|</span>
            <span>
              {t(lg.constructionSites.table.columns.documentationRecord.finished)} {site.completedRecords}
            </span>
          </>
        )
      }
    ],
    [accountableManagerNameSearchProps, formatDate, nameSearchProps, t]
  );

  const handleOnChange: TablePropType["onChange"] = useCallback(
    (pagination, filter, sorter, extra) => {
      setPage(pagination.current);
      setOrderBy(orderByMap[sorter.field] ? orderByMap[sorter.field][sorter.order] || [] : []);
      setNameFilter(filter.name ? filter.name[0] : "");
      setAccountableManagerNameFilter(filter.accountableManagerFullName ? filter.accountableManagerFullName[0] : "");
      setStatesFilter(filter.state || []);
    },
    [setPage]
  );

  const hasFilters = nameFilter || accountableManagerNameFilter.length || statesFilter?.length;
  if (tableData.length === 0 && !hasFilters && noDataContent && !loading) {
    return noDataContent || null;
  }

  return (
    <div className={className}>
      <Table<TableItem>
        dataSource={tableData}
        columns={columns}
        rowKey={"id"}
        loading={{ delay: tableData.length === 0 ? 0 : 300, spinning: loading }}
        showSorterTooltip={false}
        onChange={handleOnChange}
        pagination={pagination}
        scroll={{ x: true }}
      />
    </div>
  );
};
