export const removeQueriesFromUrl = (url: string): string => url.split(/[?#]/)[0];

// source: https://stackoverflow.com/a/63627688
export const openInNewTab = (
  url: string,
  target: string = "_blank",
  features: string = "noopener,noreferrer"
): void => {
  const newWindow = window.open(url, target, features);
  if (newWindow) newWindow.opener = null;
};
