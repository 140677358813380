import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { Section } from "components/Section";
import { DocumentationRecordVideoType } from "api";
import { lg } from "assets/translations";
import { TypeIndicatorDocumentationVideo } from "components/Indicators/Type";
import { documentationRecordVideoTypes } from "api/enums";

type Props = {
  type: DocumentationRecordVideoType | null; // null = video type hasn't been selected yet
  className?: string;
};

/**
 * Type of Video
 */
export const VideoTypeInfo = ({ type, className }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <Section title={t(lg.typenames.videoType)} className={className}>
      <div className="c-grid mt-10">
        <div className="c-grid-column">
          <TypeIndicatorDocumentationVideo type={type} labelStyle="font-medium">
            <div className="whitespace-pre-line">
              {t(
                type
                  ? documentationRecordVideoTypes[type].descriptionI18nKey
                  : documentationRecordVideoTypes.default.descriptionI18nKey
              )}
            </div>
          </TypeIndicatorDocumentationVideo>
        </div>
      </div>
    </Section>
  );
};
