import { Section } from "components/Section";
import { lg } from "assets/translations";
import { Trans, useTranslation } from "react-i18next";
import { useConfig } from "hooks";

export const Sample = () => {
  const [t] = useTranslation();
  const { config } = useConfig();
  return (
    <>
      <Section title={t(lg.aboutPage.tabs.sample.intro.title)}>
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="font-medium text-secondary whitespace-pre-line">
              <Trans i18nKey={lg.aboutPage.tabs.sample.intro.description}>
                {/* Trans component add content to the a element */}
                {/* eslint-disable jsx-a11y/anchor-has-content */}
                <a href={config.externalBaseUrl} target="_blank" rel="noopener noreferrer" className="c-link" />
                {/* eslint-enable */}
              </Trans>
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.sample.manager.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">{t(lg.aboutPage.tabs.sample.manager.description1)}</div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">{t(lg.aboutPage.tabs.sample.manager.description2)}</div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.sample.operator.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.sample.operator.description1)}
            </div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.sample.operator.description2)}
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.sample.surveyor.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.sample.surveyor.description1)}
            </div>
          </div>
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.sample.surveyor.description2)}
            </div>
          </div>
        </div>
      </Section>

      <Section title={t(lg.aboutPage.tabs.sample.threeDModel.title)} className="mt-16">
        <div className="c-grid mt-10">
          <div className="c-grid-column">
            <div className="text-secondary whitespace-pre-line">
              {t(lg.aboutPage.tabs.sample.threeDModel.description)}
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
