import { PropsWithChildren, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import { Photo } from "components/Task/GroundControlPoints/Photo";
import { MediaUploadSuccessHandler, useConfig, useFormat, useMedia } from "hooks";
import { lg } from "assets/translations";
import { RcFile } from "antd/es/upload";
import { ButtonUpload } from "components/ButtonUpload";

type Props = {
  organizationId: string;
  photos: string[]; // Media IDs
  className?: string;
  onAdd: (id: string) => any;
  onRemove: (id: string) => any;
};

export const Photos = ({ organizationId, photos, className, onAdd, onRemove }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { config } = useConfig();
  const { allowedFileTypesToString, allowedFileTypesToReadableString } = useFormat();

  const allowedFileTypes = useMemo(() => config.allowedImageFileTypes, [config.allowedImageFileTypes]);
  const uploadAccept = useMemo(
    () => allowedFileTypesToString(allowedFileTypes),
    [allowedFileTypesToString, allowedFileTypes]
  );

  // handler for TUS upload success and Media state is Uploaded
  const handleMediaUploadSuccess = useCallback<MediaUploadSuccessHandler>(
    async media => {
      onAdd(media.id);
    },
    [onAdd]
  );

  const { upload, uploading, uploadedPercents } = useMedia(organizationId, handleMediaUploadSuccess);

  const handleBeforeUpload = useCallback(
    async (file: RcFile): Promise<void> => upload(file, allowedFileTypes),
    [upload, allowedFileTypes]
  );

  return (
    <div className={className}>
      <p>
        {t(lg.taskOperator.groundControlPoints.point.photos.description, {
          allowed: allowedFileTypesToReadableString(allowedFileTypes)
        })}
      </p>

      {photos.length > 0 && (
        <div className="mt-10 space-y-5">
          {photos.map(id => (
            <div key={id}>
              <Photo id={id} onRemove={onRemove} />
            </div>
          ))}
        </div>
      )}

      <div className="w-full mt-5">
        <Upload type={"select"} fileList={[]} accept={uploadAccept} beforeUpload={handleBeforeUpload}>
          <ButtonUpload
            size="large"
            className="mt-5"
            disabled={uploading}
            progress={uploadedPercents}
            uploading={uploading}
          >
            {t(lg.uploadButton.description.photos)}
          </ButtonUpload>
        </Upload>
      </div>
    </div>
  );
};
