import { PropsWithChildren, ReactNode, useMemo } from "react";
import { DocumentationRecordState, DocumentationRecordVideoType } from "api";
import { useTranslation } from "react-i18next";
import { CustomIconType } from "components/icons/CustomIcon";
import { TypeIndicator } from "components/Indicators/Type/TypeIndicator";
import { TypeIndicatorProps } from "components/Indicators/Type/index";
import { documentationRecordStates, documentationRecordVideoTypes } from "api/enums";

type Props = {
  type: DocumentationRecordVideoType | null;
  // color Type icon by state
  state?: DocumentationRecordState | null;
} & TypeIndicatorProps;

export const TypeIndicatorDocumentationVideo = (props: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const iconType = useMemo<CustomIconType>(() => {
    if (props.type === DocumentationRecordVideoType.NoScale) return "camera-nothing";
    if (props.type === DocumentationRecordVideoType.Scale) return "camera-ruler";
    if (props.type === DocumentationRecordVideoType.Maps) return "camera-map";
    if (props.type === DocumentationRecordVideoType.Spray) return "camera-spray";
    if (props.type === DocumentationRecordVideoType.QrCodes) return "camera-qr";

    return "camera-question-mark";
  }, [props.type]);

  const typeName = useMemo<string>(
    () =>
      t(
        props.type
          ? documentationRecordVideoTypes[props.type].nameI18nKey
          : documentationRecordVideoTypes.default.nameI18nKey
      ),
    [t, props.type]
  );

  const label = useMemo<ReactNode>(() => (props.label !== undefined ? props.label : typeName), [props.label, typeName]);

  const tooltipTitle = useMemo<string>(
    () => `${typeName}${props.state ? ` - ${t(documentationRecordStates[props.state].nameI18nKey)}` : ""}`,
    [t, props.state, typeName]
  );

  return <TypeIndicator {...props} icon={iconType} label={label} tooltipTitle={tooltipTitle} />;
};
