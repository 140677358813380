import { Role } from "api";

export const isManager = ({ role }: { role?: Role | null }): boolean => role === Role.Manager;
export const isOperator = ({ role }: { role?: Role | null }): boolean => role === Role.Operator;
export const isSurveyor = ({ role }: { role?: Role | null }): boolean => role === Role.Surveyor;
export const isThreeDOperator = ({ role }: { role?: Role | null }): boolean => role === Role.ThreeDOperator;
export const isReader = ({ role }: { role?: Role | null }): boolean => role === Role.Reader;
export const isAdministrator = ({ role }: { role?: Role | null }): boolean => role === Role.Administrator;

export const getUserFullName = ({
  firstName,
  lastName
}: {
  firstName?: string | null;
  lastName?: string | null;
}): string => `${firstName} ${lastName}`.trim();
