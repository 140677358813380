import { ComponentPropsWithoutRef, Fragment } from "react";
import { Redirect, Router } from "@reach/router";
import { ApolloProvider } from "@apollo/react-hooks";
import { client, Language } from "api";
import { availableLanguages, initTranslations } from "assets/translations";
import * as Page from "pages";
import { LayoutDashboard } from "components/LayoutDashboard";
import { Alert } from "components/Alert";
import { routes, setRouteParams } from "routes";
import ErrorBoundary from "components/ErrorBoundary";
import { HTTPErrors } from "api/enums";
import loadable from "@loadable/component";
import { isReactSnap } from "helpers";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import csCz from "antd/lib/locale/cs_CZ";
import ukUa from "antd/lib/locale/uk_UA";
import enGb from "antd/lib/locale/en_GB";
import deDe from "antd/lib/locale/de_DE";

type ConfigProviderProps = ComponentPropsWithoutRef<typeof ConfigProvider>;

const appLocaleToAntdLocale: Record<Language, ConfigProviderProps["locale"]> = {
  [Language.Cs]: csCz,
  [Language.En]: enGb,
  [Language.Uk]: ukUa,
  [Language.De]: deDe
};

const AntDConfigProvider = (props: Omit<ConfigProviderProps, "locale">) => {
  const {
    i18n: { language }
  } = useTranslation();

  const locale = appLocaleToAntdLocale[language as Language];

  return <ConfigProvider {...props} locale={locale} />;
};

/*
 * Loadable components works better with react-snap which pre-render pages
 * */
const LoadableHomePage = loadable(() => import("./pages/Homepage"));
const LoadableAlertUnsupportedBrowser = loadable(() => import("./components/AlertUnsupportedBrowser"));
const LoadableAlertApiCompatibility = loadable(() => import("./components/AlertApiCompatibility"));

const App = () => {
  initTranslations();

  return (
    <ErrorBoundary>
      <ApolloProvider client={client}>
        <AntDConfigProvider>
          {isReactSnap ? null : <LoadableAlertUnsupportedBrowser />}
          {isReactSnap ? null : <LoadableAlertApiCompatibility />}

          <Router className="router" primary={false}>
            {availableLanguages.map(lng => (
              <Fragment key={lng}>
                <LoadableHomePage path={`/${lng}`} />
                <Page.Login path={`/${lng}${routes.login}`} />
              </Fragment>
            ))}

            <LoadableHomePage path={routes.root} />

            <Page.Error path={routes.error} />

            <Page.Login path={routes.login} />

            <Page.Invitation path={routes.invitation} />
            <Page.Purchase path={routes.purchase} />

            <LayoutDashboard path={"/"}>
              <Redirect
                from={"/"}
                to={setRouteParams(routes.error, { error: HTTPErrors.NotFound })}
                replace
                noThrow
                default
              />
              <Page.Todo path={routes.todo} />
              <Page.TodoAll path={routes.todoAll} />

              <Page.TaskOperator path={routes.taskOperator} />
              <Page.TaskSurveyor path={routes.taskSurveyor} />
              <Page.TaskThreeDOperator path={routes.taskThreeDOperator} />
              <Page.TaskThreeDOperator path={routes.taskThreeDOperatorFromAllTodoPage} />

              <Page.Organizations path={routes.organizations} />
              <Page.Organization path={routes.organization} />
              <Page.OrganizationResources path={routes.organizationResources} />

              <Page.ConstructionSites path={routes.constructionSites} />
              <Page.ConstructionSiteDetail path={routes.constructionSiteDetail} />
              <Page.ConstructionSiteCreate path={routes.constructionSiteCreate} />
              <Page.ConstructionSiteEdit path={routes.constructionSiteEdit} />

              <Page.ConstructionObjectDetail path={routes.constructionObjectDetail} />
              <Page.ConstructionObjectCreate path={routes.constructionObjectCreate} />
              <Page.ConstructionObjectEdit path={routes.constructionObjectEdit} />

              <Page.ConstructionPhaseDetail path={routes.constructionPhase} />

              <Page.DocumentationRecords path={routes.documentationRecords} />
              <Page.DocumentationRecordCreateSimplified path={routes.documentationRecordCreateSimplified} />
              <Page.DocumentationRecordCreateSimplified path={routes.documentationRecordCreateSimplifiedWithSiteId} />
              <Page.DocumentationRecordDetail path={routes.documentationRecordDetail} />
              <Page.DocumentationRecordCreateType path={routes.documentationRecordCreateType} />
              <Page.DocumentationRecordCreate path={routes.documentationRecordCreate} />
              <Page.DocumentationRecordEdit path={routes.documentationRecordEdit} />

              <Page.ThreeDModels path={routes.threeDModels} />
              <Page.ThreeDModelsCompleted path={routes.threeDModelsCompleted} />

              <Page.Support path={routes.support} />
              <Page.Pricing path={routes.pricing} />

              <Page.AdditionalServices path={routes.additionalServices} />

              <Page.AdditionalServicesReview path={routes.additionalServicesReview} />
              {/* default tab is resolved by page */}
              <Page.AdditionalServicesReview path={routes.additionalServicesReviewActiveTab} />

              <Page.Inquiry path={routes.inquiry} />
              <Page.InquiryReview path={routes.inquiryReview} />

              <Page.About path={routes.about} />
              {/* default tab is resolved by page */}
              <Page.About path={routes.aboutActiveTab} />
            </LayoutDashboard>

            {/* LayoutThreeDViewer is part of the ThreeDViewer page cause there issue with more layout routes */}
            <Page.ThreeDViewer path={routes.threeDViewer} />
          </Router>

          <Alert />
        </AntDConfigProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
