import { PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StateIndicatorDocumentationRecord } from "components/Indicators/State/StateIndicatorDocumentationRecord";
import { TypeIndicatorDocumentationRecord, TypeIndicatorDocumentationVideo } from "components/Indicators/Type";
import { Section } from "components/Section";
import { lg } from "assets/translations";
import clsx from "clsx";
import { useDocumentationRecord } from "hooks";

type Props = {
  documentationRecordId: string;
  hasDocumentationState?: boolean;
  hasDocumentationType?: boolean;
  hasVideoType?: boolean;
  className?: string;
};

export const GeneralInfo = ({
  documentationRecordId: id,
  hasDocumentationState = true,
  hasDocumentationType = false,
  hasVideoType = false,
  className
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  const { data } = useDocumentationRecord(id);

  const documentation = useMemo(() => data?.record, [data]);

  if (!documentation || !data) return null;

  return (
    <Section title={t(lg.taskGeneralInfo.heading)} className={className}>
      <div className="c-grid">
        <div className="c-grid-column">
          <Subheading>{t(lg.taskGeneralInfo.documentation.heading)}</Subheading>
          <Item label={t(lg.taskGeneralInfo.documentation.name)} hasMargin={false}>
            {documentation.name}
          </Item>
          <Item label={t(lg.taskGeneralInfo.documentation.description)}>{documentation.description}</Item>
          <Item
            label={t(lg.taskGeneralInfo.documentation.phase)}
          >{`${data?.phase?.name} (${data?.phase?.order}.)`}</Item>
          {hasDocumentationState && (
            <Item label={t(lg.taskGeneralInfo.documentation.state)}>
              <StateIndicatorDocumentationRecord state={documentation.state} />
            </Item>
          )}
          {hasDocumentationType && (
            <Item label={t(lg.taskGeneralInfo.documentation.documentationType)}>
              <TypeIndicatorDocumentationRecord type={documentation.type} />
            </Item>
          )}
          {hasVideoType && documentation.documentationRecordVideo?.type !== undefined && (
            <Item label={t(lg.taskGeneralInfo.documentation.videoType)}>
              <TypeIndicatorDocumentationVideo type={documentation.documentationRecordVideo?.type} />
            </Item>
          )}

          <Subheading>{t(lg.taskGeneralInfo.object.heading)}</Subheading>
          <Item label={t(lg.taskGeneralInfo.object.name)} hasMargin={false}>
            {data?.object?.name}
          </Item>
          <Item label={t(lg.taskGeneralInfo.object.description)}>{data?.object?.description}</Item>
        </div>

        <div className="c-grid-column">
          <Subheading>{t(lg.taskGeneralInfo.site.heading)}</Subheading>
          <Item label={t(lg.taskGeneralInfo.site.name)} hasMargin={false}>
            {data?.site?.name}
          </Item>
          <Item label={t(lg.taskGeneralInfo.site.description)}>{data?.site?.description}</Item>
          <Item label={t(lg.taskGeneralInfo.site.organization)}>{data?.organization?.name}</Item>

          <Subheading>{t(lg.taskGeneralInfo.contacts.heading)}</Subheading>
          <Item label={t(lg.taskGeneralInfo.contacts.accountableManager)} hasMargin={false}>
            {`${documentation?.accountableManager?.firstName} ${documentation?.accountableManager?.lastName} (${documentation?.accountableManager?.email})`}
          </Item>
          <Item label={t(lg.taskGeneralInfo.contacts.accountableOperator)}>
            {`${documentation?.accountableOperator?.firstName} ${documentation?.accountableOperator?.lastName} (${documentation?.accountableOperator?.email})`}
          </Item>
          {documentation?.accountableSurveyor && (
            <Item label={t(lg.taskGeneralInfo.contacts.accountableSurveyor)}>
              {`${documentation?.accountableSurveyor?.firstName} ${documentation?.accountableSurveyor?.lastName} (${documentation?.accountableSurveyor?.email})`}
            </Item>
          )}
        </div>
      </div>
    </Section>
  );
};

const Subheading = ({ children }: PropsWithChildren<{}>) => (
  <div className="text-blue-700 text-base font-medium mt-10 mb-2 print_text-black print_text-sm print_mt-1 print_mb-0">
    {children}
  </div>
);

const Item = ({ label, hasMargin = true, children }: PropsWithChildren<{ label: string; hasMargin?: boolean }>) => (
  <div className={clsx(hasMargin ? "mt-5" : "", "print_mt-0")}>
    <div className="font-medium print_font-normal print_inline-block">
      {label}
      <span className="hidden print_inline print_mr-1">:</span>
    </div>
    <div className="text-secondary print_inline-block print_align-middle whitespace-pre-line">{children || "-"}</div>
  </div>
);
