import { useCallback, useState } from "react";
import { RcFile } from "antd/es/upload";
import { Maybe, MediaState, useMediaCreateMutation, useMediaLazyQuery, useMediaStateQuery } from "api";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { lg } from "assets/translations";
import { useConfig } from "hooks/useConfig";
import { isAllowedFileType } from "helpers";
import * as Sentry from "@sentry/browser";
import { useCounter } from "react-use";
import { useFormat } from "hooks/useFormat";
import { DetailedError, Upload } from "tus-js-client";

type TusJsClientError = Error | DetailedError;

export type Media = {
  id: string;
  state?: MediaState;
  url?: Maybe<string>;
  fileName: Maybe<string>;
};

export type MediaUploadSuccessHandler = (media: Media) => void;
export type MediaUploadErrorHandler = (error: TusJsClientError) => void;

export const useMedia = (
  organizationId: string,
  onUploadSuccess: MediaUploadSuccessHandler = () => {},
  onUploadError: MediaUploadErrorHandler = () => {}
) => {
  const [t] = useTranslation();
  const [lastMediaId, setLastMediaId] = useState<string | undefined>(undefined);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadedPercents, { set: setPercents, reset: resetPercents }] = useCounter(0, 100, 0);
  const { getNumberRangeArray } = useFormat();

  const { config } = useConfig();

  const [createMedia] = useMediaCreateMutation();

  const [fetchMedia] = useMediaLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: data => {
      setUploading(false);
      data.media && onUploadSuccess(data.media);
    }
  });

  const { startPolling, stopPolling } = useMediaStateQuery({
    variables: { id: lastMediaId || "" },
    skip: !lastMediaId,
    notifyOnNetworkStatusChange: true, // call onCompleted after poll
    onCompleted: data => {
      if (!lastMediaId || data.media?.state !== MediaState.Uploaded) return;
      stopPolling();
      fetchMedia({ variables: { id: lastMediaId } }); // get full media
      setLastMediaId(undefined); // reset state
    }
  });

  const handleUploadError = useCallback(
    (error: TusJsClientError) => {
      setUploading(false);
      resetPercents();

      Sentry.withScope(() => {
        Sentry.captureException(error);
      });

      message.error(t(lg.uploader.messages.uploadFailed));
      console.error("File upload has failed with error: ", error);

      onUploadError(error);
    },
    [t, resetPercents, onUploadError]
  );

  const handleUploadSuccess = useCallback(
    async (mediaId: string) => {
      console.log("Upload completed. Media ID:", mediaId);

      setLastMediaId(mediaId);
      startPolling(1000);
    },
    [startPolling]
  );

  const handleUploadProgress = useCallback(
    (bytesSent: number, bytesTotal: number) => setPercents(((bytesSent / bytesTotal) * 100) | 0),
    [setPercents]
  );

  const upload = useCallback(
    async (file: RcFile, allowedFileTypes?: string[], customType?: string) => {
      /** check file type */
      if (allowedFileTypes && allowedFileTypes.length > 0 && !isAllowedFileType(file.name, allowedFileTypes)) {
        message.error(t(lg.uploader.messages.notAllowedFile));
        return;
      }

      // set values to the default state
      resetPercents();
      setUploading(true);

      // init
      let mediaId = "";

      /*
       * Create new Media on the server
       * */
      try {
        const { data } = await createMedia({ variables: { input: { organization: organizationId } } });
        mediaId = data?.media?.create?.media.id || "";
      } catch (e) {
        message.error(t(lg.uploader.messages.uploadFailed));
        console.error("Creating media has failed: ", e);
        return;
      }

      /*
       * how often tus-js-client will attempt a retry when the upload has been unintentionally interrupted
       * */
      const retryDelays: number[] = [
        0,
        1000,
        1000 * 3,
        1000 * 5,
        1000 * 10,
        1000 * 20,
        ...getNumberRangeArray(1000 * 15, 1000 * 60 * 5, 1000 * 30)
      ];

      // config upload
      const upload: Upload = new Upload(file, {
        endpoint: config.fileUploadUrl,
        metadata: {
          media: mediaId,
          filename: file.name,
          filetype: customType || file.type
        },
        chunkSize: 5 * 1000 * 1000,
        retryDelays,
        removeFingerprintOnSuccess: true,
        onError: handleUploadError,
        onSuccess: () => handleUploadSuccess(mediaId),
        onProgress: handleUploadProgress
      });

      upload.start();
    },
    [
      organizationId,
      createMedia,
      handleUploadError,
      handleUploadSuccess,
      handleUploadProgress,
      t,
      config.fileUploadUrl,
      resetPercents,
      getNumberRangeArray
    ]
  );

  return { upload, uploading, uploadedPercents };
};
